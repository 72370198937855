/** @jsx jsx */

import { CSSProperties, ReactNode, forwardRef, useMemo } from 'react';
import { buildDataAttributes, jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

import { siftAriaAttributes } from '../utils';

export type TableGroupHeaderProps = UseGroupHeaderStylesProps & {
  'aria-colspan'?: number;
  children: ReactNode;
};

export const TableGroupHeader = forwardRef<
  HTMLDivElement,
  TableGroupHeaderProps
>(({ children, ...props }, ref) => {
  const { css, dataAttributes, style } = useGroupHeaderStyles(props);
  const ariaAttributes = siftAriaAttributes(props);

  return (
    <div role="row" ref={ref}>
      <div
        role="columnheader"
        css={css}
        style={style}
        {...ariaAttributes}
        {...dataAttributes}
      >
        {children}
      </div>
    </div>
  );
});

// Styles
// ------------------------------

type UseGroupHeaderStylesProps = {
  /**
   * Sets inline [style](https://reactjs.org/docs/dom-elements.html#style) for
   * the element. Only use as a **last resort**.
   */
  UNSAFE_style?: CSSProperties;
};

export function useGroupHeaderStyles(props: UseGroupHeaderStylesProps) {
  const { borderWidth, palette, spacing } = useTheme();
  const { UNSAFE_style } = props;

  // dynamic properties
  const style = useMemo(() => UNSAFE_style, [UNSAFE_style]);

  // static-ish properties
  const dataAttributes = useMemo(
    () => buildDataAttributes({ type: 'group' }, 'rowgroup'),
    []
  );

  // declarations
  const css = useMemo(
    () => [
      {
        backgroundColor: palette.background.muted,
        borderBottom: `${borderWidth.standard} solid ${palette.border.standard}`,
        minWidth: 0, // resolve flex issues with text trucation
        padding: spacing.medium,
      } as const,
    ],
    [
      borderWidth.standard,
      palette.background.muted,
      palette.border.standard,
      spacing.medium,
    ]
  );

  return { dataAttributes, css, style };
}
