import React from 'react';
import type { PropsWithChildren, ReactNode } from 'react';
import { useRouter } from 'next/router';

import { useAuth } from './useAuth';

export type SecureRouteProtectionManagerProps = PropsWithChildren<{
  enabled?: boolean;
  secureRouteMatcher: (url: string) => boolean;
  loader?: ReactNode;
}>;

export function SecureRouteProtectionManager({
  enabled,
  children,
  secureRouteMatcher,
  loader,
}: SecureRouteProtectionManagerProps) {
  const router = useRouter();

  const { isAuthenticated, isLoading, setIsLoading, logout } = useAuth();
  const isSecure = secureRouteMatcher(router.asPath);

  React.useEffect(() => {
    if (!enabled) return;

    /**
     * User is trying to access a page that is not secure, so we
     * don't want to store the url or block them.
     */
    if (!isSecure) {
      setIsLoading(false);
      return;
    }

    /**
     * User tried to access a secure route but was not authenticated
     * so lets store the path and all the params
     * then let the page stop loading so the "logout" behaviour takes over
     * and kicks them to the login url
     */
    if (!isLoading && !isAuthenticated && isSecure) {
      logout();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSecure, isAuthenticated, enabled]); // intentionally missing some deps as they end up causing infinite rerenders

  // Block child render to prevent secure route component flashing in and out before redirect to login page
  if (enabled && isSecure && !isAuthenticated) return <>{loader || null}</>;

  return <>{children}</>;
}
