/** @jsx jsx */

import type { ComponentProps } from 'react';
import { Fragment } from 'react';
// @ts-ignore
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { Heading } from '@balance-web/heading';
import { Strong } from '@balance-web/strong';
import { TextLink } from '@balance-web/text-link';
import { useTheme } from '@balance-web/theme';
import { TextList } from '@balance-web/text-list';
import { DefaultTextPropsProvider, Text } from '@balance-web/text';

import { CodeBlock } from './code-block/CodeBlock';
import { DocsTitle } from './DocsTitle';
import { scope } from './scope';

const verticalRhythm = { marginBottom: '1em', marginTop: '1em' };

const components = {
  // INLINE
  strong: (props: any) => {
    return <Strong {...props} />;
  },
  em: (props: any) => {
    return <em css={{ fontStyle: 'italic' }} {...props} />;
  },
  // eslint-disable-next-line jsx-a11y/alt-text
  img: (props: any) => {
    return <img css={{ maxWidth: '100%' }} {...props} />;
  },
  a: (props: any) => {
    return <TextLink {...props} />;
  },
  inlineCode: (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { palette, typography } = useTheme();

    return (
      <code
        css={{
          backgroundColor: palette.background.accent,
          borderRadius: 2,
          color: palette.text.accent,
          display: 'inline-block',
          fontFamily: typography.fontFamily.monospace,
          fontSize: '0.85em',
          paddingLeft: '0.2em',
          paddingRight: '0.2em',
          // the ligatures look wrong & aren't monospace
          fontFeatureSettings: 'initial',
          MozFontFeatureSettings: 'initial',
        }}
        {...props}
      />
    );
  },

  // BLOCK
  h1: (props: any) => {
    return <DocsTitle {...props} />;
  },
  h2: (props: any) => {
    return <AnchoredHeading level="3" {...props} />;
  },
  h3: (props: any) => {
    return <AnchoredHeading level="4" {...props} />;
  },
  h4: (props: any) => {
    return <AnchoredHeading level="5" {...props} />;
  },
  h5: (props: any) => {
    return (
      <Box marginTop="xlarge">
        <Heading level="6" {...props} />
      </Box>
    );
  },
  h6: (props: any) => {
    return (
      <Box marginTop="xlarge">
        <Heading level="7" {...props} />
      </Box>
    );
  },
  blockquote: (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { palette, spacing } = useTheme();

    return (
      <DefaultTextPropsProvider size="small">
        <blockquote
          css={{
            backgroundColor: palette.background.informative,
            boxShadow: `inset 2px 0 0 ${palette.text.informative}`,
            margin: `${spacing.large} 0`,
            padding: `${spacing.large} ${spacing.xlarge}`,
            position: 'relative',

            '& > p:first-of-type': {
              marginTop: 0,
            },
            '& > p:last-of-type': {
              marginBottom: 0,
            },
          }}
          {...props}
        />
      </DefaultTextPropsProvider>
    );
  },
  p: (props: any) => {
    return <Text as="p" css={verticalRhythm} {...props} />;
  },
  li: (props: any) => {
    return <Text {...props} />;
  },
  ul: (props: any) => {
    return (
      <Box marginY="large" paddingLeft="large">
        <TextList type="bullet" css={verticalRhythm} {...props} />
      </Box>
    );
  },
  ol: (props: any) => {
    return (
      <Box marginY="large" paddingLeft="large">
        <TextList type="number" css={verticalRhythm} {...props} />
      </Box>
    );
  },
  // avoid wrapping live examples in pre tag
  pre: (props: any) => {
    return <Fragment {...props} />;
  },
  code: ({ children, ...props }: any) => {
    return <CodeBlock code={children.trim()} scope={scope} {...props} />;
  },
  table: (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { palette, spacing, typography } = useTheme();
    return (
      <table
        css={{
          color: palette.text.base,
          fontSize: typography.fontSize.small,
          width: '100%',

          th: { fontWeight: typography.fontWeight.bold, textAlign: 'left' },
          'td,th': {
            borderBottom: `1px solid ${palette.global.border}`,
            lineHeight: typography.leading.base,
            padding: `${spacing.small} ${spacing.medium}`,
            verticalAlign: 'middle',
            '&:first-of-type': { paddingLeft: 0 },
            '&:last-of-type': { paddingRight: 0 },
          },
        }}
        {...props}
      />
    );
  },
};

export const MdxWithTitle = ({ children }: { children: string }) => {
  return (
    <MDXProvider components={components}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  );
};

const componentsWhereH1RendersNull = {
  ...components,
  h1: () => {
    return null;
  },
};

export const Mdx = ({ children }: { children: string }) => {
  return (
    <MDXProvider components={componentsWhereH1RendersNull}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  );
};

const AnchoredHeading = ({
  children,
  id,
  ...props
}: ComponentProps<typeof Heading>) => {
  const { palette } = useTheme();
  const offset = [undefined, undefined, 120, 110, 100] as const;

  return (
    <Heading
      id={id}
      css={{
        ':not(:first-child)::before': {
          content: '" "',
          display: 'block',
          marginTop: -80, // height of the header
          paddingTop: offset[parseInt(props.level, 10) - 1],
          pointerEvents: 'none',
        },
      }}
      {...props}
    >
      {children}
      <a
        aria-label="link"
        href={`#${id}`}
        css={{
          alignItems: 'center',
          display: 'inline-flex',
          color: `${palette.text.muted} !important`,
          fontSize: 12,
          fontWeight: 'normal',
          marginLeft: 13,
          opacity: 0,
          textDecoration: 'none',
          transition: 'opacity 150ms',

          '*:hover > &': {
            opacity: 1,
          },
        }}
      >
        <svg
          aria-hidden="true"
          height="16"
          fill="currentColor"
          version="1.1"
          viewBox="0 0 16 16"
          width="16"
          css={{ marginRight: 6 }}
        >
          <path
            fillRule="evenodd"
            d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          />
        </svg>
      </a>
    </Heading>
  );
};
