export function usePreventableClickHandler(props: any, disabled: boolean) {
  return function handleClick(event: any) {
    if (disabled) {
      event.preventDefault();
    } else if ('onClick' in props) {
      // @ts-ignore
      props.onClick(event);
    }
  };
}
