import { useRawTheme, useTheme } from '@balance-web/theme';

export const useItemHighlightingStyles = () => {
  const { palette, radii } = useTheme();

  return {
    borderRadius: radii.small,
    textDecoration: 'none',
    cursor: 'pointer',

    ':hover': {
      backgroundColor: palette.listItem.backgroundFocused,
      boxShadow: `0 0 0 1px ${palette.listItem.backgroundFocused}`,
      position: 'relative',
    },
    ':active': {
      backgroundColor: palette.listItem.backgroundPressed,
      boxShadow: `0 0 0 1px ${palette.listItem.backgroundPressed}`,
      position: 'relative',
    },
    '&[data-selected=true]': {
      backgroundColor: palette.listItem.backgroundSelected,
      boxShadow: `0 0 0 1px ${palette.listItem.backgroundSelected}`,
      position: 'relative',
    },
  } as const;
};

export const useGutters = () => {
  const rawTheme = useRawTheme();

  const outterGutter = rawTheme.spacing.large;
  const innerGutter = rawTheme.spacing.large;
  const navigationGroupGutter =
    rawTheme.spacing.xxlarge + rawTheme.spacing.small;

  return {
    outterGutter,
    innerGutter,
    navigationGroupGutter,
  };
};

export const useSeparatorStyles = (
  {
    isInsideNavigationGroup,
  }: {
    isInsideNavigationGroup?: boolean;
  } = { isInsideNavigationGroup: false }
) => {
  const theme = useTheme();
  const gutters = useGutters();

  const navigationItemBorderLeft = gutters.outterGutter + gutters.innerGutter;
  const navigationGroupItemBorderLeft =
    gutters.outterGutter + gutters.innerGutter + gutters.navigationGroupGutter;

  const borderLeft = isInsideNavigationGroup
    ? navigationGroupItemBorderLeft
    : navigationItemBorderLeft;

  const navigationItemBorderWidth = `calc(100% - ${borderLeft * 2})`;
  const navigationGroupItemBorderWidth = `calc(100% - ${
    borderLeft + theme.spacing.xxlarge
  })`;

  const borderWidth = isInsideNavigationGroup
    ? navigationGroupItemBorderWidth
    : navigationItemBorderWidth;

  return {
    position: 'relative',
    '&:not(:last-child)::after': {
      background: theme.palette.global.border,
      content: '" "',
      display: 'block',
      height: theme.borderWidth.standard,
      width: borderWidth,
      position: 'absolute',
      left: borderLeft,
      bottom: 0,
    },
    ':hover': {
      '::after': {
        display: 'none',
      },
    },
  } as const;
};

export const useGroupSeparatorStyles = () => {
  const theme = useTheme();
  const gutters = useGutters();

  const navigationItemBorderLeft = gutters.outterGutter + gutters.innerGutter;

  const borderLeft = navigationItemBorderLeft;

  const navigationItemBorderWidth = `calc(100% - ${borderLeft * 2})`;

  const borderWidth = navigationItemBorderWidth;

  return {
    ':not([data-last-child=true]) .sidebarNavigationGroup': {
      position: 'relative',
      '::after': {
        background: theme.palette.global.border,
        content: '" "',
        display: 'block',
        height: theme.borderWidth.standard,
        width: borderWidth,
        position: 'absolute',
        left: borderLeft,
        bottom: 0,
      },

      '&:not([data-expanded=true]):hover': {
        '::after': {
          display: 'none',
        },
      },
    },
  } as const;
};
