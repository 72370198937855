import React from 'react';

export const BriefcaseIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      style={{
        verticalAlign: 'text-bottom', // removes whitespace inside buttons
        stroke: '#52535C',
        fill: 'none',
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
        strokeWidth: 2,
        height: 24,
        width: 24,
      }}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <React.Fragment>
        <rect x={2} y={7} width={20} height={14} rx={2} ry={2} />
        <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
      </React.Fragment>
    </svg>
  );
};
