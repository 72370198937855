import React from 'react';
import { Stack } from '@balance-web/stack';
import { Text } from '@balance-web/text';

export type ItemTextProps = {
  superText?: string;
  title: string;
  subText?: string;
  tone?: 'cautious' | 'critical';
  disabled?: boolean;
};
export const ItemText = ({
  superText,
  title,
  subText,
  tone,
  disabled,
}: ItemTextProps) => {
  return (
    <Stack align="left">
      {superText && (
        <Text color="muted" size="xsmall">
          {superText}
        </Text>
      )}
      <Text size="small" weight="semibold" color={disabled ? 'muted' : 'base'}>
        {title}
      </Text>
      {subText && (
        <Text color={tone || 'muted'} size="xsmall">
          {subText}
        </Text>
      )}
    </Stack>
  );
};
