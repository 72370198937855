import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const RAHLogoName = 'RAHLogo';
export const RAHAppName = 'Reckon Accounts Hosted';
export const RAHAppLongName = 'Reckon Accounts Hosted';
export const RAHLogoDescription = 'Reckon Accounts Hosted Logo';

export const RAHLogo = createIllustration(
  { name: RAHLogoName, description: RAHLogoDescription, width: 32, height: 32 },
  <React.Fragment>
    <path
      d="M1.27148 30.5395V8.13827C1.27148 4.44541 4.27527 1.44238 7.96976 1.44238H23.6803C27.3748 1.44238 30.3786 4.44541 30.3786 8.13827V23.8436C30.3786 27.5364 27.3748 30.5395 23.6803 30.5395H1.27148Z"
      fill="white"
      stroke="#FF005D"
    />
    <path
      d="M9.74023 7.93835H16.9099C18.938 7.93835 20.5091 8.53801 21.5374 9.53744C22.3944 10.4226 22.88 11.5934 22.88 12.9926V13.0497C22.88 15.6768 21.3089 17.2759 19.0809 17.9041L23.4227 23.9863H20.0521L16.1101 18.3895H12.5681V23.9863H9.74023V7.93835ZM16.71 15.9052C18.7381 15.9052 20.0235 14.8487 20.0235 13.221V13.1639C20.0235 11.4506 18.7952 10.5083 16.6814 10.5083H12.5681V15.9052H16.71Z"
      fill="#FF005D"
    />
  </React.Fragment>
);
