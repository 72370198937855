/** @jsx jsx */

import { InputHTMLAttributes, forwardRef, useEffect, useState } from 'react';
import { jsx } from '@balance-web/core';
import { useFieldContext } from '@balance-web/field';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { InputSizeType, useInputStyles } from '@balance-web/text-input';
import { wrapHandlers } from '@balance-web/utils';

import { ISODate } from './types';
import { formatValue, getExternalValue, validateShorthand } from './utils';

type InvalidProps = 'onChange' | 'placeholder' | 'size' | 'type' | 'value';
export type DateInputProps = WithDataAttributeProp<
  {
    onChange: (value: ISODate | undefined) => void;
    size?: InputSizeType;
    value: ISODate | undefined;
  } & Omit<InputHTMLAttributes<HTMLInputElement>, InvalidProps>
>;

// we're using a regular input instead of an input with type=date
// because type=date doesn't work in Safari for Mac
// and type=date in browsers that do support it is hard to use
// because it masks the value while typing

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  (
    {
      onBlur,
      onChange,
      size = 'medium',
      value,
      data,
      ...props
    }: DateInputProps,
    ref
  ) => {
    const { invalid, ...a11yProps } = useFieldContext();
    const [internalValue, setInternalValue] = useState(() =>
      formatValue(value)
    );
    const styles = useInputStyles({ size, shape: 'square' });

    // sync the internal string value with the consumer's ISO date
    // clears the value if a valid date isn't found
    useEffect(() => {
      const externalValue = getExternalValue(internalValue);
      if (externalValue !== value) {
        onChange(externalValue);
      }
    }, [internalValue, onChange, value]);

    // support 2 digit years e.g. "31/12/21" --> "31/12/2021"
    // clears the input if a valid date isn't found
    const handleBlur = wrapHandlers(onBlur, () => {
      if (value) {
        setInternalValue(validateShorthand);
      } else {
        setInternalValue('');
      }
    });

    const dataAttributes = buildDataAttributes(data);

    return (
      <input
        aria-invalid={invalid}
        ref={ref}
        css={styles}
        value={internalValue}
        onChange={(event) => {
          setInternalValue(event.target.value);
        }}
        onBlur={handleBlur}
        placeholder="dd/mm/yyyy"
        {...dataAttributes}
        {...props}
        {...a11yProps}
      />
    );
  }
);
