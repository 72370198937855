import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonTimesheetsLogoName = 'ReckonTimesheetsLogo';
export const ReckonTimesheetsAppName = 'Timesheets';
export const ReckonTimesheetsAppLongName = 'Reckon Timesheets';
export const ReckonTimesheetsLogoDescription =
  'Reckon Timesheets Application Logo';

export const ReckonTimesheetsLogo = createIllustration(
  {
    name: ReckonTimesheetsLogoName,
    description: ReckonTimesheetsLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none">
      <g clip-path="url(#a)">
        <g clip-path="url(#b)">
          <path
            fill="#FF5447"
            d="M15.264 0h29.457c8.41 0 15.211 6.853 15.265 15.26v29.447c0 8.405-6.856 15.259-15.264 15.259H0V15.259C0 6.853 6.856 0 15.264 0Z"
          />
          <path
            fill="#fff"
            d="M18.264 14.884h13.443c3.803 0 6.748 1.125 8.677 2.999 1.606 1.66 2.517 3.855 2.517 6.478v.107c0 4.926-2.946 7.924-7.123 9.102l8.14 11.404h-6.32l-7.39-10.493h-6.642v10.493h-5.302v-30.09Zm13.068 14.938c3.803 0 6.213-1.98 6.213-5.032v-.107c0-3.213-2.303-4.98-6.266-4.98h-7.713v10.12h7.766Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h60v60H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M0 0h60v60H0z" />
        </clipPath>
      </defs>
    </svg>
  </React.Fragment>
);
