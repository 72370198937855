import { formatISO, parse } from 'date-fns';

export type ISODate = string & { ___ISODateString: true };

export type DateRange = {
  start?: Date;
  end?: Date;
};

export type DateRangeStrict = {
  start: Date;
  end: Date;
};

export type ISODateRange = {
  start?: ISODate;
  end?: ISODate;
};

export type ISODateRangeStrict = {
  start: ISODate;
  end: ISODate;
};

export function asIsoDate(value: string): ISODate {
  return value as ISODate;
}

const ISO_PRESENTATION = 'yyyy-MM-dd';

export function formatIsoDate(value: Date): ISODate {
  return asIsoDate(formatISO(value, { representation: 'date' }));
}

export function parseIsoDate(str: string, pattern = ISO_PRESENTATION): Date {
  return parse(str, pattern, new Date());
}
