import { Field, FormState, FormValue } from '@magical-forms/react-next';

export function formStateToFormValue<TField extends Field>(
  field: TField,
  state: FormState<TField>
): FormValue<TField> {
  if (field.kind === 'object') {
    let obj: any = {};
    for (let key in (field as any).fields) {
      obj[key] = formStateToFormValue(
        (field as any).fields[key],
        (state as any)[key]
      );
    }
    return obj;
  }
  if (field.kind === 'array') {
    return ((state as any) as any[]).map((inner) =>
      formStateToFormValue((field as any).element, inner)
    ) as any;
  }
  return (state as any).value;
}
