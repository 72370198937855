/** @jsx jsx */

import { ReactNode, forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { BalanceTheme, useTheme } from '@balance-web/theme';

export type ContainerProps = {
  /** The content of the container. */
  children: ReactNode;
  /** The maximum width of the container. */
  size: keyof BalanceTheme['contentWidth'];
};

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ size, ...props }, forwardedRef) => {
    const { contentWidth } = useTheme();
    const maxWidth = contentWidth[size];

    return (
      <div
        ref={forwardedRef}
        css={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth,
          width: '100%',
        }}
        {...props}
      />
    );
  }
);
