/** @jsx jsx */
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { MenuContentProps } from '@radix-ui/react-dropdown-menu';
import type { ReactNode } from 'react';
import type { WithDataAttributeProp } from '@balance-web/core';
import { getTestIdFromDataAttributes, jsx } from '@balance-web/core';
import { flattenElements } from '@balance-web/utils';

import { Trigger } from './radix-styled-components';
import { Content, Divider, SubMenuTrigger } from './radix-styled-components';
import { Item } from './Item';
import { RadioGroup } from './RadioGroup';
import { RadioItem } from './RadioItem';
import { GroupTitle } from './GroupTitle';
import { MenuTitle } from './MenuTitle';
import { SubMenu } from './SubMenu';
import { TriggerButton } from './TriggerButton';
import { TestIdContextProvider } from './context';
import { DropdownContext } from './context';
import { DEFAULT_ITEM_HEIGHT } from './constants';

export type DropdownProps = WithDataAttributeProp<
  {
    children: ReactNode;
    /** Vertical offset of the dropdown menu relative to the trigger */
    verticalOffset?: MenuContentProps['sideOffset'];
    /** Number of items to render a scroll bar appears */
    viewportItemCount?: number;
    itemHeight?: number;
  } & Pick<MenuContentProps, 'align'>
>;

export const Dropdown = ({
  children,
  verticalOffset,
  align,
  data,
  ...props
}: DropdownProps) => {
  const childArray = flattenElements(children);

  /**
   * We render like this to reduce the number of primitives consumers have to deal with.
   * If, at some point doing this is costing us flexibility we might have to let consumers opt into
   * the Content primitive.
   */
  const triggerElement = childArray.find((child) => {
    return child.type === Trigger;
  });
  const nonTriggerElements = childArray.filter((child) => {
    return child.type !== Trigger;
  });

  const testId = getTestIdFromDataAttributes(data);

  return (
    <DropdownContext.Provider
      value={{ itemHeight: props.itemHeight || DEFAULT_ITEM_HEIGHT }}
    >
      <TestIdContextProvider value={testId}>
        <DropdownMenuPrimitive.Root {...props}>
          {triggerElement}
          <Content
            sideOffset={verticalOffset}
            align={align}
            viewportItemCount={props.viewportItemCount}
            data={data}
          >
            {nonTriggerElements}
          </Content>
        </DropdownMenuPrimitive.Root>
      </TestIdContextProvider>
    </DropdownContext.Provider>
  );
};
Dropdown.Trigger = Trigger;
Dropdown.TriggerButton = TriggerButton;
Dropdown.SubMenu = SubMenu;
Dropdown.SubMenuTrigger = SubMenuTrigger;
Dropdown.Item = Item;
Dropdown.RadioGroup = RadioGroup;
Dropdown.RadioItem = RadioItem;
Dropdown.Divider = Divider;
Dropdown.GroupTitle = GroupTitle;
Dropdown.MenuTitle = MenuTitle;
