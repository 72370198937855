import * as React from 'react';

import { LoadHeapIoService } from './LoadHeapIoService';
import type { LoadHeapIoServiceProps } from './LoadHeapIoService';

export function HeapIoService({ appId }: LoadHeapIoServiceProps) {
  LoadHeapIoService({ appId });

  return <></>;
}
