import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonBankingLogoName = 'ReckonBankingLogo';
export const ReckonBankingAppName = 'Banking';
export const ReckonBankingAppLongName = 'Reckon Banking';
export const ReckonBankingLogoDescription = 'Reckon Banking Application Logo';

export const ReckonBankingLogo = createIllustration(
  {
    name: ReckonBankingLogoName,
    description: ReckonBankingLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#a)"
      />
    </mask>
    <mask
      id="d"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#c)"
      />
    </mask>
    <g mask="url(#d)">
      <path fill="url(#e)" d="M0 0h60v60H0z" />
      <g clipPath="url(#f)">
        <path
          d="M17.677 18.54c5.485-5.262 13.855-6.522 20.717-3.245l-1.398 1.82 6.83.739-2.748-6.042-1.465 1.905c-7.694-3.84-17.18-2.485-23.37 3.452-5.882 5.644-7.52 14.01-4.17 21.314.168.365.539.58.93.58.135 0 .273-.026.408-.083a.958.958 0 0 0 .52-1.283c-3.012-6.566-1.54-14.086 3.746-19.157Zm31.038 4.463c-.225-.491-.82-.715-1.338-.499a.958.958 0 0 0-.521 1.282c3.01 6.564 1.539 14.082-3.748 19.155-5.485 5.263-13.854 6.524-20.718 3.245l1.4-1.82-6.831-.743 2.747 6.045 1.462-1.9a20.653 20.653 0 0 0 9.2 2.166c5.186 0 10.32-1.931 14.173-5.624 5.886-5.637 7.523-14.004 4.174-21.307Zm-18.068 1.494c.662 0 1.169.24 1.518.716.355.473.532 1.158.532 2.043H37.2c0-1.617-.465-2.958-1.392-4.01-.928-1.052-2.21-1.691-3.83-1.924v-2.748h-2.125v2.696c-1.68.154-3.03.697-4.042 1.63-1.013.934-1.518 2.122-1.518 3.559 0 .862.14 1.594.426 2.2.284.61.69 1.142 1.226 1.601.531.46 1.168.865 1.904 1.212.737.348 1.54.69 2.41 1.037.87.338 1.48.703 1.819 1.086.352.384.521.912.521 1.584 0 .606-.173 1.08-.521 1.42-.346.346-.821.523-1.422.523-.831 0-1.463-.257-1.912-.766-.449-.502-.672-1.246-.672-2.216h-4.49c0 1.768.518 3.184 1.551 4.262 1.034 1.076 2.52 1.716 4.457 1.908v2.58h2.118v-2.568c1.66-.154 2.971-.69 3.937-1.608.962-.921 1.444-2.11 1.444-3.564 0-.86-.15-1.604-.453-2.212a5.051 5.051 0 0 0-1.252-1.61 8.84 8.84 0 0 0-1.9-1.23 30.993 30.993 0 0 0-2.339-1.047c-.825-.338-1.422-.69-1.797-1.062-.373-.37-.553-.875-.553-1.501 0-.621.155-1.109.484-1.463.327-.35.782-.528 1.368-.528Z"
          fill="#fff"
        />
      </g>
      <g filter="url(#g)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.374 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.374 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <clipPath id="f">
        <path
          fill="#fff"
          transform="translate(10 11.162)"
          d="M0 0h40v38.377H0z"
        />
      </clipPath>
      <filter
        id="g"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_14549_1181"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_14549_1181"
          result="shape"
        />
      </filter>
    </defs>
  </React.Fragment>
);
