import React, { ReactNode } from 'react';
import { Text } from '@balance-web/text';
import { VisuallyHidden } from '@balance-web/a11y';

export type LegendAppearance = 'label' | 'legend' | 'hidden';
type LegendProps = {
  appearance?: LegendAppearance;
  children: ReactNode;
};

/**
 * @private Styled component for internal use only.
 *
 * The `<legend/>` element isn't valid outside of a `<fieldset/>`.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/legend
 */
export const Legend = ({ appearance = 'legend', children }: LegendProps) => {
  if (appearance === 'hidden') {
    return <VisuallyHidden as="legend">{children}</VisuallyHidden>;
  }

  const fontSize = appearanceToFontSize[appearance];

  return (
    <Text as="legend" size={fontSize} weight="medium">
      {children}
    </Text>
  );
};

const appearanceToFontSize = {
  label: 'small',
  legend: 'medium',
} as const;
