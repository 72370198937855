import React from 'react';

import {
  LoadBranchIoService,
  LoadBranchIoServiceProps,
} from './LoadBranchIoService';

export const BranchIOService = (props: LoadBranchIoServiceProps) => {
  LoadBranchIoService(props);
  return <></>;
};
