/** @jsx jsx */

import { jsx } from '@balance-web/core';
import { TabItem, TabList, useTabList } from '@balance-web/tabs';
import { useTheme } from '@balance-web/theme';
import { IndicatorDot } from '@balance-web/badge';
import { IndicatorDotProps } from '@balance-web/badge';

type SiteNavigationItemBase = {
  id: string;
  label: string;
  isSelected?: boolean;
  notification?: IndicatorDotProps['tone'];
};
type SiteNavigationItemWithHref = SiteNavigationItemBase & {
  href: string;
};

type SiteNavigationItemWithClick = SiteNavigationItemBase & {
  onClick: () => void;
};

type SiteNavigationItem =
  | SiteNavigationItemWithHref
  | SiteNavigationItemWithClick;

export type SiteNavigationProps = {
  id: string;
  items?: SiteNavigationItem[];
};
export const SiteNavigation = ({ id, items = [] }: SiteNavigationProps) => {
  const { listProps } = useTabList();
  const { spacing } = useTheme();

  return (
    <TabList {...listProps}>
      {items.map((item) => {
        return (
          <TabItem
            as={'a'}
            key={item.label}
            // https://www.digitala11y.com/aria-current-state/
            {...((item.isSelected && {
              'aria-current': 'location',
              'data-testid': `navigation-${id}-item-current`,
            }) || {
              'data-testid': `navigation-${id}-item`,
            })}
            isSelected={item.isSelected}
            {...item}
            css={{
              paddingTop: spacing.xlarge,
              paddingBottom: 20,
            }}
          >
            {item.label}
            {item.notification && <IndicatorDot tone={item.notification} />}
          </TabItem>
        );
      })}
    </TabList>
  );
};
