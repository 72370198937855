import React from 'react';
import type { PropsWithChildren } from 'react';

import type { OpenStateContext } from './types';
import { useOpenState } from './useOpenState';

/**
 * Creates a provider that can be used to provide state to a `useOpenState` hook
 * @example
 *    const MyThingsToTrack = ['a', 'b', 'c'] as const;
 *    export const MyOpenStateContext = createOpenStateContext(MyThingsToTrack);
 *    export const MyOpenStateProvider = createOpenStateProvider(MyThingsToTrack, MyOpenStateContext);
 *
 * @param names
 * @param Context
 * @returns
 */

export function createOpenStateProvider<TName extends string>(
  names: readonly TName[],
  Context: OpenStateContext<TName[]>
) {
  return ({ children }: PropsWithChildren<{}>) => {
    const { isOpen, open, close, toggle } = useOpenState([...names]);

    return (
      <Context.Provider
        value={{
          isOpen,
          open,
          close,
          toggle,
        }}
      >
        {children}
      </Context.Provider>
    );
  };
}
