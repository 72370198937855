import React, { ReactNode } from 'react';
import { Inline } from '@balance-web/inline';
import { Stack } from '@balance-web/stack';

export type FilesProps = {
  orientation?: 'horizontal' | 'vertical';
  children?: ReactNode;
};

export const Files = ({ orientation = 'horizontal', children }: FilesProps) => {
  const Layout = orientation === 'horizontal' ? Inline : Stack;

  return <Layout gap="small">{children}</Layout>;
};
