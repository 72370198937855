/** @jsx jsx */
import { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';

import { ItemBase, ItemBaseProps } from './ItemBase';
import { RadioItemContainer } from './radix-styled-components';

export type RadioItemProps = WithDataAttributeProp<
  {
    value: string;
    children: ReactNode;
  } & ItemBaseProps
>;

export const RadioItem = ({
  value,
  children,
  data,
  ...props
}: RadioItemProps) => {
  return (
    <RadioItemContainer value={value} disabled={props.disabled} data={data}>
      <ItemBase {...props}>{children}</ItemBase>
    </RadioItemContainer>
  );
};
