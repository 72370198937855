import * as React from 'react';
import { UserAvatar } from '@balance-web/avatar';
import { ChevronDownIcon, ChevronUpIcon } from '@balance-web/icon';
import { Text } from '@balance-web/text';
import { Flex } from '@balance-web/flex';
import { Box } from '@balance-web/box';
import { Inline } from '@balance-web/inline';

import { Hidden } from '../../../../../design-system/packages/media-query';
import { ActionMenu } from '../ActionMenu';
import type { ActionMenuProps } from '../ActionMenu';

export type UserMenuProps = {
  username: string;
  version?: string;
} & Omit<ActionMenuProps, 'trigger'>;

export const UserMenu = ({ username, version, menuItems }: UserMenuProps) => {
  return (
    <Flex flexGrow={0} alignItems="center">
      <ActionMenu
        trigger={({ triggerProps, isOpen }) => {
          const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon;
          return (
            <Flex
              as="button"
              type="button"
              alignItems="center"
              userSelect="none"
              cursor="pointer"
              {...triggerProps}
            >
              <UserAvatar size="small" name={username} />
              <Hidden below="small">
                <Box marginX="small">
                  <Text
                    align="left"
                    overflowStrategy="nowrap"
                    size="small"
                    weight="medium"
                  >
                    {username}
                  </Text>
                </Box>
              </Hidden>
              <Icon size="small" color="muted" />
            </Flex>
          );
        }}
        menuItems={menuItems}
        footer={
          (!!version && (
            <Inline paddingX="large" paddingTop="small" alignY="center">
              <Text color="muted" size="xsmall">
                Version: {version}
              </Text>
            </Inline>
          )) ||
          null
        }
      />
    </Flex>
  );
};
