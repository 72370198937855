/** @jsx jsx */
import { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { useTextLink } from '@balance-web/text-link';
import { Text, TextProps } from '@balance-web/text';

import { useTileContext } from './context';
export type InteractiveTextProps = TextProps & {
  children: ReactNode;
};

export const InteractiveText = (props: InteractiveTextProps) => {
  const { hover } = useTileContext();
  const { styles } = useTextLink({ color: 'base', hover });

  return (
    <Text
      {...props}
      css={{
        ...styles,
      }}
    />
  );
};
