/** @jsx jsx */
import React, { HTMLAttributes } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

import { RectType } from './types';

type HandleProps = { rect?: RectType } & HTMLAttributes<HTMLDivElement>;
export const Handle = ({ rect, ...props }: HandleProps) => {
  const { palette, radii, shadow } = useTheme();
  const easing = 'cubic-bezier(0.2, 0, 0, 1)';

  // NOTE: `transform: translate` would be more performant than animating the
  // `left` property, however it has a tendency to fall on "half pixels", which
  // will not cover our dividers properly
  return (
    <div
      css={{
        backgroundColor: palette.segmentedControl.backgroundSelected,
        borderRadius: radii.xsmall,
        boxShadow: shadow.xsmall,
        pointerEvents: 'none',
        position: 'absolute',
        transitionDuration: '250ms',
        transitionProperty: 'left, width',
        transitionTimingFunction: easing,
        zIndex: 1,
      }}
      style={rect}
      {...props}
    />
  );
};
Handle.displayName = 'SegmentedControlHandle';
