/** @jsx jsx */

import { buildDataAttributes, jsx } from '@balance-web/core';
import { makeId, useId } from '@balance-web/utils';
import { VisuallyHidden } from '@balance-web/a11y';
import { Inline } from '@balance-web/inline';

import { FilterChipCheckbox } from './FilterChipCheckbox';
import {
  checkboxItemProps,
  useCheckboxGroupState,
} from './useCheckboxGroupState';
import type { FilterChipCheckboxGroupProps } from './types';

export const FilterChipCheckboxGroup = ({
  variant = 'filled',
  size = 'medium',
  name: consumerName,
  options,
  legend,
  data,
  ...props
}: FilterChipCheckboxGroupProps) => {
  const instanceId = useId(consumerName);
  const legendId = makeId(instanceId, 'filter-radio-group-legend');

  const dataAttributes = buildDataAttributes(data);

  const checkboxGroupState = useCheckboxGroupState({ ...props, options });

  return (
    <Inline gap="small">
      <fieldset
        aria-labelledby={legendId}
        style={{ display: 'contents' }}
        {...dataAttributes}
      >
        {options.map((opt) => {
          const inputProps = checkboxItemProps(
            {
              ...opt,
              checked: props.value.includes(opt.value),
            },
            checkboxGroupState
          );
          return (
            <FilterChipCheckbox
              key={opt.value}
              variant={variant}
              size={size}
              label={opt.label}
              {...inputProps}
            />
          );
        })}
      </fieldset>
      <VisuallyHidden id={legendId}>{legend}</VisuallyHidden>
    </Inline>
  );
};
