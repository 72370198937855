/** @jsx jsx */
import { ReactNode } from 'react';
import { Inline } from '@balance-web/inline';
import { jsx } from '@balance-web/core';
import { Text } from '@balance-web/text';
import { useRawTheme, useTheme } from '@balance-web/theme';
import { ExternalLinkIcon } from '@balance-web/icon';
import { CheckIcon } from '@balance-web/icon';
import { Link } from '@balance-web/link';

import { useRadioGroupContext } from './context';
import { AnchorProps } from './types';
import { RadioItemIndicator } from './radix-styled-components';

export type ItemSelectionProps =
  | {
      selected: boolean;
      selectedLabel: string;
    }
  | {
      selected?: never;
      selectedLabel?: never;
    };

export type ItemBaseProps = AnchorProps & {
  tone?: 'passive' | 'critical';
  startElement?: ReactNode;
  endElement?: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
} & ItemSelectionProps;

export const ItemBase = ({
  children,
  startElement,
  endElement,
  disabled,
  selected,
  selectedLabel,
  tone,
  ...props
}: ItemBaseProps) => {
  const isInRadioGroup = useRadioGroupContext();
  const { spacing } = useTheme();
  const rawTheme = useRawTheme();
  const styles = useStyles({
    disabled,
    tone,
  });

  const disabledOrBaseColor = disabled ? 'dim' : 'base';
  const iconSize = 'small';

  /** Padding to make space for absolutely position radio item indicator */
  const radioItemLeftPadding =
    isInRadioGroup || selected ? rawTheme.spacing.large * 2 : spacing.small;
  const radioItemRightPadding = isInRadioGroup || selected ? 0 : spacing.small;

  return (
    <Inline
      as={props.href ? Link : 'div'}
      width="100%"
      height="100%"
      flex="1"
      gap="small"
      alignY="center"
      align="left"
      paddingRight="small"
      {...props}
      aria-label={selected ? selectedLabel : undefined}
      css={{
        position: 'relative',
        paddingLeft: radioItemLeftPadding,
        paddingRight: radioItemRightPadding,
      }}
    >
      {/* The indicator is conditionally rendered by radix */}
      <RadioItemIndicator>
        <CheckIcon size={iconSize} color={disabledOrBaseColor} />
      </RadioItemIndicator>

      {/* This check icon is for sub menus being selected.  */}
      {selected && (
        <div css={{ position: 'absolute', left: spacing.small }}>
          <CheckIcon size={iconSize} color={disabledOrBaseColor} />
        </div>
      )}

      {startElement}
      <Text
        size="small"
        weight="medium"
        css={{ flex: '1', color: styles.textColor }}
      >
        {children}
      </Text>
      {props.target === '_blank' ? (
        <ExternalLinkIcon size={iconSize} color={disabledOrBaseColor} />
      ) : (
        endElement
      )}
    </Inline>
  );
};

const useStyles = ({
  disabled,
  tone,
}: Pick<ItemBaseProps, 'disabled' | 'tone'>) => {
  const { palette } = useTheme();

  const textColor = disabled
    ? palette.menuItem.textDisabled
    : tone === 'critical'
    ? palette.text.critical
    : palette.menuItem.text;

  return {
    textColor,
  };
};
