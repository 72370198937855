import type { ChangeEvent } from 'react';
import { scalar } from '@magical-forms/react-next';
import { array, object } from '@magical-forms/react-next';

function validationToFieldProps<
  Obj extends {
    validity: 'valid' | 'invalid';
    error?: string;
    touched: boolean;
  }
>({
  error,
  validity,
  touched,
  ...obj
}: Obj): Omit<Obj, 'error' | 'validity' | 'touched'> & {
  invalidMessage?: string;
} {
  if (touched) {
    return {
      invalidMessage: error,
      ...obj,
    };
  }
  return obj;
}

export const multiselect = scalar({
  props: (field) => {
    return {
      ...validationToFieldProps(field),
      blur: () => {
        field.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: string[] | undefined) => {
    return input || [];
  },
});

export const text = scalar({
  props: ({ onChange, ...field }) => {
    return {
      ...validationToFieldProps(field),
      onChange(event: ChangeEvent<HTMLInputElement>) {
        onChange(event.target.value);
      },
      blur: () => {
        field.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: string | undefined | null) => {
    return input || '';
  },
});

export const multilineText = scalar({
  props: ({ onChange, ...field }) => {
    return {
      ...validationToFieldProps(field),
      onChange(event: ChangeEvent<HTMLTextAreaElement>) {
        onChange(event.target.value);
      },
      blur: () => {
        field.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: string | undefined | null) => {
    return input || '';
  },
});

export const email = scalar({
  props: ({ onChange, ...field }) => {
    return {
      ...validationToFieldProps(field),
      inputMode: 'email' as const,
      onChange(event: ChangeEvent<HTMLInputElement>) {
        onChange(event.target.value);
      },
      blur: () => {
        field.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: string | undefined | null) => {
    return input || '';
  },
});

// export const number = scalar({
//   props: ({ onChange, validity, error, value, ...field }) => ({
//     ...field,
//     value: value === undefined ? '' : '' + value,
//     invalidMessage: error,
//     onChange(event: ChangeEvent<HTMLInputElement>) {
//       onChange(
//         event.target.value === '' ? undefined : Number(event.target.value)
//       );
//     },
//   }),
//   initialValue: (input: number | undefined) => input,
// });

export const currency = scalar({
  props: (field) => {
    return {
      ...validationToFieldProps(field),
      blur: () => {
        field.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: string | number | undefined | null) => {
    return input === null || input === undefined ? '' : input;
  },
});

export const duration = scalar({
  props: ({ onChange, ...field }) => {
    return {
      ...validationToFieldProps(field),
      onChange(value?: string | number) {
        onChange(value !== undefined ? value : '');
      },
      blur: () => {
        field.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: string | number | undefined | null) => {
    return input || '';
  },
});

export const boolean = scalar({
  props: (field) => {
    return {
      ...validationToFieldProps(field),
      blur: () => {
        field.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: boolean | undefined | null) => {
    return input || false;
  },
});

export const string = scalar({
  props: (field) => {
    return {
      ...validationToFieldProps(field),
      blur: () => {
        field.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: string | undefined | null) => {
    return input ?? undefined;
  },
});

// TODO: remove these dates from here and import from a non jsx rendering date orientated package
type ISODate = string & { ___ISODateString: true };

type ISODateRange = { start?: ISODate; end?: ISODate };

export const date = scalar({
  props: (input) => {
    return {
      ...validationToFieldProps(input),
      onClear() {
        input.onChange(undefined);
      },
      blur: () => {
        input.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: ISODate | undefined | null) => {
    return input ?? undefined;
  },
});

export const dateRange = scalar({
  props: (field) => {
    return {
      ...validationToFieldProps(field),
      blur: () => {
        field.onBlur();
      },
      onBlur: undefined,
    };
  },
  initialValue: (input: ISODateRange | undefined | null) => {
    return input ?? undefined;
  },
});

export const empty = scalar({
  props: () => {
    return undefined;
  },
  initialValue: (input: undefined) => {
    return input;
  },
});

export const select = <Value extends string>() => {
  return scalar({
    props: (field) => {
      return {
        ...validationToFieldProps(field),
        blur: () => {
          field.onBlur();
        },
        onBlur: undefined,
      };
    },
    initialValue: (input: Value | undefined | null) => {
      return input ?? undefined;
    },
  });
};

export const autocomplete = <
  Option extends { label: string; value: string }
>() => {
  return scalar({
    props: (field) => {
      return {
        ...validationToFieldProps(field),
        blur: () => {
          field.onBlur();
        },
        onBlur: undefined,
      };
    },
    initialValue: (input: Option | undefined | null) => {
      return input ?? null;
    },
  });
};

const field = {
  object,
  array,
  autocomplete,
  select,
  empty,
  dateRange,
  date,
  string,
  boolean,
  duration,
  currency,
  email,
  text,
  multilineText,
  multiselect,
};

// export as composites
export { scalar, object, array, field };
