import Router from 'next/router';
import { useCallback, useEffect } from 'react';

/**
 * Trigger a confirmation dialog if a condition is truthy when then
 * user tries to close the page or navigate away.
 */
export const useNextBeforeUnload = (
  /**
   * Should the confirmation show when closing or leaving page?
   *
   * Can be a function or a boolean, for best results use a function.
   */
  enabled: boolean | (() => boolean) = true,
  /**
   * Message to show in the confirmation dialog, only plain text is supported.
   */
  message?: string
) => {
  const shouldConfirm = useCallback(() => {
    return typeof enabled === 'function' ? enabled() : enabled;
  }, [enabled]);

  const handleWindowClose = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!shouldConfirm()) {
        return;
      }

      if (event instanceof BeforeUnloadEvent) event.preventDefault();
      if (message && event instanceof BeforeUnloadEvent) {
        return ((event || window.event).returnValue = message);
      }
      window.history.pushState('', '', Router.asPath);
      // throw Error('Navigation cancelled');
      return (event.returnValue = message);
    },
    [shouldConfirm, message]
  );

  const handleBrowseAway = useCallback(() => {
    if (!shouldConfirm()) {
      return;
    }

    if (window.confirm(message)) {
      return;
    }

    // Throw `string` here instead of Error constructor is intentional
    // this prevent's next.js built-in error reporting to trigger which might distracting when in dev mode
    // by throwing string it instead just log the error message to the console
    /*eslint no-throw-literal: */
    Router.events.emit('routeChangeError');
    throw 'Navigation cancelled';
  }, [shouldConfirm, message]);

  // https://github.com/vercel/next.js/discussions/9662#discussioncomment-511835
  // https://github.com/vercel/next.js/issues/2694#issuecomment-732990201
  useEffect(() => {
    if (!shouldConfirm()) {
      Router.events.off('routeChangeStart', handleBrowseAway);
      return;
    }
    Router.events.on('routeChangeStart', handleBrowseAway);
    window.addEventListener('beforeunload', handleWindowClose, {
      capture: true,
    });
    return () => {
      Router.events.off('routeChangeStart', handleBrowseAway);
      window.removeEventListener('beforeunload', handleWindowClose, {
        capture: true,
      });
    };
  }, [shouldConfirm, handleWindowClose, handleBrowseAway]);
};
