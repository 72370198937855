import { createContext } from 'react';
import { BalanceTheme } from '@balance-web/theme';
import { ResponsiveProp } from '@balance-web/core';

export type PageBlockContextShape = {
  size?: ResponsiveProp<keyof BalanceTheme['breakpoints']>;
  fluid?: boolean;
};
export const PageBlockContext = createContext<PageBlockContextShape>({
  size: 'large',
});
