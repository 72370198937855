/** @jsx jsx */

import type { HTMLAttributes } from 'react';
import { forwardRef, useMemo } from 'react';
import { jsx } from '@balance-web/core';
import type { FlexProps } from '@balance-web/flex';
import { Flex } from '@balance-web/flex';
import { XIcon } from '@balance-web/icon/icons/XIcon';
import { Text } from '@balance-web/text';
import { useRawTheme, useTheme } from '@balance-web/theme';
import type { DisallowStyleProps } from '@balance-web/utils';
import { getContrastText } from '@balance-web/utils';

type ValidBoxProps = Pick<FlexProps, 'data' | 'id'>;
export type TagProps = {
  /** The label of the Tag. */
  label: string;
  /** Callback invoked when the removal icon is clicked. */
  onRemove?: () => void;
  /** The variant of the Tag. */
  variant?: number;
} & DisallowStyleProps &
  ValidBoxProps;

export const Tag = forwardRef<HTMLDivElement, TagProps>(
  // NOTE: we unfortunately must spread props because designs assign tooltips to non-interactive elements like this
  ({ data, id, label, onRemove, variant, ...tooltipProps }, forwardedRef) => {
    const { palette, sizing } = useTheme();
    const rawTheme = useRawTheme();

    const backgroundColor =
      typeof variant === 'number'
        ? palette.decorative[variant]
        : palette.background.shade;

    const rawBackgroundColor =
      typeof variant === 'number'
        ? rawTheme.palette.decorative[variant]
        : rawTheme.palette.background.shade;

    const color = useMemo(() => {
      return getContrastText(rawBackgroundColor);
    }, [rawBackgroundColor]);

    return (
      <Flex
        ref={forwardedRef}
        data={data}
        id={id}
        // styles
        alignItems="center"
        inline
        borderRadius="xsmall"
        height={sizing.xsmall} // FIXME: should be resolved by the `Box` component
        minWidth={0} // fix flex overflow bug that prevents text truncation
        paddingX="small"
        css={{ backgroundColor, color }}
        {...tooltipProps}
      >
        <Text
          inline
          color="inherit"
          leading="tight"
          size="small"
          weight="medium"
          overflowStrategy="truncate"
        >
          {label}
        </Text>

        {onRemove && (
          <RemoveButton onClick={onRemove} aria-label={`Remove ${label}`} />
        )}
      </Flex>
    );
  }
);

const RemoveButton = (props: HTMLAttributes<HTMLButtonElement>) => {
  const { spacing } = useTheme();
  return (
    <button
      type="button"
      css={{
        color: 'inherit',
        cursor: 'pointer',
        left: spacing.xsmall,
        opacity: 0.6,
        position: 'relative',

        ':hover, :focus': {
          opacity: 1,
        },
        ':active': {
          opacity: 0.3,
        },
      }}
      {...props}
    >
      <XIcon size="small" />
    </button>
  );
};
