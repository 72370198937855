/** @jsx jsx */
import React, { HTMLAttributes } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

export const Divider = (props: HTMLAttributes<HTMLDivElement>) => {
  const width = 1;
  const { palette } = useTheme();

  return (
    <div
      css={{
        alignSelf: 'center',
        backgroundColor: palette.segmentedControl.divider,
        height: '1em',
        marginLeft: -width,
        marginRight: -width,
        width: width,
      }}
      {...props}
    />
  );
};
Divider.displayName = 'SegmentedControlDivider';
