/** @jsx jsx */

import { ReactNode } from 'react';
import { jsx } from '@balance-web/core';

import { InteractiveText } from './InteractiveText';
import { TileBase } from './TileBase';
import { AnchorProps, AnchorPropsNever, Density } from './types';
export type TileProps = {
  density?: Density;
  children: ReactNode;
} & (
  | /** We can either have onClick or anchor props such as href, not both */
  ({
      onClick?: () => void;
      label?: string;
    } & AnchorPropsNever)
  | ({
      onClick?: () => never;
    } & AnchorProps)
);

export const Tile = ({
  density = 'regular',
  onClick,
  children,
  href,
  ...anchorProps
}: TileProps) => {
  const interactive = !!onClick || !!href;

  const tileBase = (
    <TileBase density={density} interactive={interactive}>
      {children}
    </TileBase>
  );

  if (href) {
    return (
      <a href={href} {...anchorProps}>
        {tileBase}
      </a>
    );
  }

  if (onClick) {
    return (
      <button
        onClick={onClick}
        css={{
          width: '100%',
          textAlign: 'left',
        }}
      >
        {tileBase}
      </button>
    );
  }

  return tileBase;
};

Tile.InteractiveText = InteractiveText;
