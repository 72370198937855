import { useEffect, useState } from 'react';

type Orientation = 'vertical' | 'horizontal';

/**
 * Keyboard and mouse event handling and state management for tab lists. Expects tab
 * elements to have the role of "tab".
 * @param tabListElement The element with the role of "tablist".
 * @param initialIndex The initial selected index, defaults to 0.
 */
export const useTabListState = (
  tabListElement: HTMLElement | null,
  orientation?: Orientation,
  initialIndex = 0
) => {
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  useTabListEventListeners({
    tabListElement,
    selectedIndex,
    onSelectedIndexChange: setSelectedIndex,
    orientation,
  });
  return selectedIndex;
};

type Options = {
  /** The element with the role of "tablist". */
  tabListElement: HTMLElement | null;
  /** The selected tab index. */
  selectedIndex: number;
  /** A callback for when the tab index should change */
  onSelectedIndexChange: (index: number) => void;
  /** The orientation of the tabs(used to decide which arrow keys to use) */
  orientation?: Orientation;
};

export const useTabListEventListeners = ({
  tabListElement,
  onSelectedIndexChange,
  orientation = 'horizontal',
  selectedIndex,
}: Options) => {
  // bind event handlers
  useEffect(() => {
    if (tabListElement) {
      const selectTabByIndex = (index: number) => {
        let tabElements = tabListElement.querySelectorAll(
          '[role=tab]'
        ) as NodeListOf<HTMLElement>;

        if (index === tabElements.length) {
          index = 0;
        }
        if (index === -1) {
          index = tabElements.length - 1;
        }

        if (index !== selectedIndex) {
          tabElements[index].focus();
          onSelectedIndexChange(index);
        }
      };

      // prepare handlers
      function clickEventListener(event: MouseEvent) {
        if ((event.target as HTMLElement).getAttribute('role') !== 'tab')
          return;

        let tabElements = [
          ...tabListElement!.querySelectorAll('[role=tab]'),
        ] as Array<HTMLElement>;
        let index = tabElements.indexOf(event.target as HTMLElement);
        selectTabByIndex(index);
      }
      function keydownEventListener(event: KeyboardEvent) {
        if ((event.target as HTMLElement).getAttribute('role') !== 'tab')
          return;
        let previousKey = orientation === 'vertical' ? 'ArrowUp' : 'ArrowLeft';
        let nextKey = orientation === 'vertical' ? 'ArrowDown' : 'ArrowRight';

        switch (event.key) {
          case 'End':
            event.preventDefault();
            selectTabByIndex(-1); // Set as last tab
            break;
          case 'Home':
            event.preventDefault();
            selectTabByIndex(0); // Set as first tab
            break;
          // I'm always including arrow left and right since I would assume the verticalness is not read by screenreaders?(How would it be?)
          case previousKey:
          case 'ArrowLeft':
            event.preventDefault();
            selectTabByIndex(selectedIndex - 1); // Set as previous tab, unless first
            break;
          case nextKey:
          case 'ArrowRight':
            event.preventDefault();
            selectTabByIndex(selectedIndex + 1); // Set as next tab, unless last
            break;
        }
      }

      // add listeners
      tabListElement.addEventListener('click', clickEventListener);
      tabListElement.addEventListener('keydown', keydownEventListener);

      return () => {
        // remove listeners
        tabListElement.removeEventListener('click', clickEventListener);
        tabListElement.removeEventListener('keydown', keydownEventListener);
      };
    }
  }, [tabListElement, selectedIndex, orientation, onSelectedIndexChange]);
};
