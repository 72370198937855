import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  UseComboboxProps,
  UseComboboxStateChange,
  useCombobox,
} from 'downshift';
import { useVirtual } from 'react-virtual';
import { useFieldContext } from '@balance-web/field';
import { PopoverDialog } from '@balance-web/popover';
import { DefaultTextPropsProvider, Text } from '@balance-web/text';
import { useForkedRef, wrapHandlers } from '@balance-web/utils';
import { Divider } from '@balance-web/divider';

import { ComboboxContext } from './context';
import {
  defaultGetMessageText,
  defaultItemToDisabled,
  defaultItemToLabel,
  defaultItemToValue,
  makeDefaultItemRenderer,
  makeDefaultStateReducer,
} from './defaults';
import {
  Input,
  InputClearButton,
  InputContainer,
  InputDivider,
  InputLoadingIndicator,
  InputToggleButton,
  Menu,
  MenuItem,
  MenuItemSlot,
} from './styled-components';
import { ComboboxProps } from './types';
import {
  getItemsStatus,
  useComboboxPopper,
  useMaxMenuHeight,
  useVirtualConfig,
} from './utils';
import { useComboboxState } from './useComboboxState';
import { useItemMap } from './useItemMap';

const noop = () => {};

/**
 * @param props.environment This prop is only useful if you're rendering the combobox within a different window context from where your JavaScript is running; for example, an iframe or a shadow-root.
 */
export function Combobox<Item>(props: ComboboxProps<Item>) {
  const {
    canLoadMore,
    disabled = false,
    getMessageText = defaultGetMessageText,
    inputValue,
    itemRenderer: consumerItemRenderer,
    itemHeight: consumerItemHeight,
    items,
    footerItem,
    itemToDisabled = defaultItemToDisabled,
    itemToLabel = defaultItemToLabel,
    itemToValue = defaultItemToValue,
    loadingState = 'idle',
    onChange,
    onClear: consumerClearHandler,
    onInputChange,
    onLoadMore,
    placeholder,
    size = 'medium',
    menuWidth,
    stateReducer,
    value: selectedItem,
    environment,
  } = props;

  const itemsWithOptionalFooter = footerItem ? [...items, footerItem] : items;

  // INTERNAL VALUES
  // ------------------------------

  const fieldContext = useFieldContext();
  const [isFocused, setFocused] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [containerWidth, setContainerWidth] = useState(menuWidth ?? 0);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const itemCount = items.length;

  // INITIALISE CONTEXT
  // ------------------------------

  const context = useComboboxState({
    disabled,
    isFocused,
    isOpen,
    itemHeight: consumerItemHeight,
    size,
  });

  // PREPARE TRANSFORMERS
  // ------------------------------

  // handle downshift nullable arg type, simplifies consumer API
  const itemToString = useCallback(
    (item: Item | null) => (item ? itemToLabel(item) : ''),
    [itemToLabel]
  );

  // the default state reducer adjusts downshift behaviours. not possible in
  // destructuring because we need the `itemToLabel` supporting prop
  const defaultStateReducer = useMemo(
    () => makeDefaultStateReducer(itemToLabel),
    [itemToLabel]
  );

  // create the default component. not possible in destructuring because we need
  // the `itemTo*` supporting props
  const defaultItemRenderer = useMemo(
    () => makeDefaultItemRenderer(itemToLabel, itemToDisabled),
    [itemToDisabled, itemToLabel]
  );
  const itemRenderer = consumerItemRenderer || defaultItemRenderer;

  // PREPARE VIRTUALIZER
  // ------------------------------
  const themedVirtualConfig = useVirtualConfig(context.itemHeight);
  const rowVirtualizer = useVirtual({
    ...themedVirtualConfig,
    size: canLoadMore ? itemCount + 1 : itemCount,
    parentRef: menuRef,
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= itemCount - 1 &&
      canLoadMore &&
      onLoadMore &&
      loadingState !== 'loadingMore'
    ) {
      onLoadMore();
    }
  }, [
    canLoadMore,
    onLoadMore,
    itemCount,
    loadingState,
    rowVirtualizer.virtualItems,
  ]);

  // PREPARE DOWNSHIFT
  // ------------------------------

  // defer "changes" to the second argument: consumers may need access to it,
  // but only for advanced cases
  const handleInputValueChange = useCallback(
    (changes: UseComboboxStateChange<Item>) => {
      // coercion removes `undefined` — deviate from Downshift `inputValue` to
      // simplify the Balance API
      onInputChange(changes.inputValue || '', changes);
    },
    [onInputChange]
  );
  // defer "changes" to the second argument: consumers may need access to it,
  // but only for advanced cases
  const handleSelectedItemChange = useCallback(
    (changes: UseComboboxStateChange<Item>) => {
      // coercion removes `undefined` — deviate from Downshift `selectedItem` to
      // simplify the Balance API
      onChange(changes.selectedItem || null, changes);
    },
    [onChange]
  );
  // replace default handler with virtualizer
  const scrollToIndex = rowVirtualizer.scrollToIndex;
  const handleHighlightedIndexChange = useCallback(
    ({ highlightedIndex }: UseComboboxStateChange<Item>) => {
      if (
        highlightedIndex !== undefined &&
        highlightedIndex > -1 &&
        highlightedIndex < items.length
      ) {
        scrollToIndex(highlightedIndex);
      }
    },
    [items.length, scrollToIndex]
  );
  // replace default handler with virtualizer
  const handleIsOpenChange = useCallback(
    (changes: UseComboboxStateChange<Item>) => {
      if (typeof changes.isOpen === 'boolean') {
        setOpen(changes.isOpen);
      }
    },
    []
  );
  // selection/navigation handling
  const downshiftConfig: UseComboboxProps<Item> = {
    inputId: fieldContext.id,
    inputValue,
    isOpen,
    items: itemsWithOptionalFooter,
    itemToString,
    selectedItem,
    stateReducer: stateReducer || defaultStateReducer,
    scrollIntoView: noop, // noop prevents conflicts between downshift and the virtualizer
    onInputValueChange: handleInputValueChange,
    onIsOpenChange: handleIsOpenChange,
    onSelectedItemChange: handleSelectedItemChange,
    onHighlightedIndexChange: handleHighlightedIndexChange,
  };

  // when working with a different different window object such as from inside an iframe https://github.com/downshift-js/downshift#environment
  if (environment) downshiftConfig.environment = environment;

  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    openMenu,
  } = useCombobox(downshiftConfig);

  // INTERNAL HANDLERS
  // ------------------------------

  // track the container width, which is passed onto the dialog
  useEffect(() => {
    if (menuWidth) {
      return;
    }

    const element = inputContainerRef.current;
    if (element) {
      setContainerWidth((value) => {
        // set initial value + get latest value when the menu opens
        if (!value || isOpen) {
          return element.offsetWidth;
        }
        // react won't rerender unless the value changes
        return value;
      });
    }
  }, [isOpen, menuWidth]);

  const onFocus = wrapHandlers(props.onFocus, () => {
    setFocused(true);
    // open the menu on focus
    if (!isOpen) {
      openMenu();
    }
  });
  const onBlur = wrapHandlers(props.onBlur, () => {
    setFocused(false);
  });

  // setup popper (position handling)
  const {
    popperAnchorRef,
    popperPopoverRef,
    styles,
    attributes,
  } = useComboboxPopper(isOpen);

  // augment the consumer's clear handler to focus the input. we use the
  // nullable type of `onClear` for conditional rendering
  const onClear = (() => {
    if (!selectedItem || !consumerClearHandler) {
      return null;
    }

    return () => {
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.focus();
      }
      consumerClearHandler();
    };
  })();

  // RESOLVE INTERFACE
  // ------------------------------

  // prepare common map for default items and regular items
  const itemMap = useItemMap({
    getItemProps,
    itemRenderer,
    itemToDisabled,
    itemToValue,
    items,
    selectedItem,
  });

  // prepare menu content for each state
  const menuContent = (() => {
    // we need a reference to the menu element to prepare accessibility
    // handlers, but we don't want the menu content to be focusable until the
    // menu is open
    if (!isOpen) {
      return null;
    }

    // items aren't available for some reason
    const status = getItemsStatus({
      inputValue,
      loadingState,
      itemCount: footerItem ? itemCount + 1 : itemCount,
    });
    if (status) {
      return (
        <MenuItemSlot style={{ height: context.itemHeight }}>
          <Text color="dim">{getMessageText(status, inputValue)}</Text>
        </MenuItemSlot>
      );
    }

    // render items when available
    if (itemCount || footerItem) {
      return (
        <Fragment>
          <div key="total-size" style={{ height: rowVirtualizer.totalSize }} />
          {rowVirtualizer.virtualItems.map(itemMap)}
        </Fragment>
      );
    }

    // if we reach this point something's gone wrong...
    return null;
  })();

  // the element that sits beside the disclosure arrow
  const inputAccessoryElement = (() => {
    if (loadingState === 'loading') {
      return <InputLoadingIndicator />;
    }
    if (onClear) {
      return (
        <InputClearButton aria-label="clear selection" onClick={onClear} />
      );
    }
    return null;
  })();

  // GETTER CONFIGS
  // ------------------------------

  const inputGetterConfig = {
    'aria-describedby': fieldContext['aria-describedby'],
    'aria-invalid': fieldContext.invalid,
    'aria-labelledby': fieldContext.id,
    disabled,
    onBlur,
    onFocus,
    placeholder,
    ref: inputRef,
  };

  const containerRef = useForkedRef(inputContainerRef, popperAnchorRef);
  const comboboxGetterConfig = {
    'data-invalid': fieldContext.invalid,
    ref: containerRef,
  };

  const maxMenuHeight = useMaxMenuHeight(context.itemHeight);
  const menuGetterConfig = {
    ref: menuRef,
    style: { maxHeight: maxMenuHeight },
  };

  // COMPOSITION
  // ------------------------------

  return (
    <ComboboxContext.Provider value={context}>
      <Fragment>
        <InputContainer {...getComboboxProps(comboboxGetterConfig)}>
          <Input {...getInputProps(inputGetterConfig)} />
          {inputAccessoryElement}
          <InputDivider />
          <InputToggleButton
            aria-label="toggle menu"
            {...getToggleButtonProps()}
          />
        </InputContainer>
        <PopoverDialog
          ref={popperPopoverRef}
          isVisible={isOpen}
          size={size}
          style={{ ...styles.popper, width: containerWidth }}
          {...attributes.popper}
        >
          <DefaultTextPropsProvider size="small" weight="medium">
            <Menu {...getMenuProps(menuGetterConfig)}>{menuContent}</Menu>
            {footerItem && isOpen && (
              <>
                <Divider />
                <MenuItem
                  key={itemToValue(footerItem)}
                  style={{
                    height: context.itemHeight,
                    position: 'sticky',
                    bottom: 0,
                  }}
                  {...getItemProps({
                    disabled: false,
                    index: items.length, // Last item points to the footer
                    isSelected: Boolean(
                      selectedItem &&
                        itemToValue(selectedItem) === itemToValue(footerItem)
                    ),
                    item: footerItem,
                    // Since the 'footerItem' sits outside of 'Downshift Menu', usual 'onSelectedItemChange'
                    // doesn't work and hence we need to rely on 'onMouseDown' event to update the selected item.
                    // https://github.com/downshift-js/downshift/issues/287#issuecomment-512855962
                    onMouseDown: () => {
                      onChange(footerItem || null, {
                        // @ts-ignore
                        type: '__item_click__', // TODO: Use UseComboboxStateChangeTypes.itemClick when the export is fixed in downshift
                        highlightedIndex: items.length, // Last item points to the footer
                        inputValue: '',
                        selectedItem: footerItem,
                      });
                    },
                  })}
                  isMemoized={false} // Footer MenuItem should rerender when children change
                >
                  {itemRenderer(footerItem)}
                </MenuItem>
              </>
            )}
          </DefaultTextPropsProvider>
        </PopoverDialog>
      </Fragment>
    </ComboboxContext.Provider>
  );
}
