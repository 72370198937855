import { ISODate } from '@balance-web/date-input';

import { formatIsoDate, parseIsoDate } from '@reckon-web/use-dates';

const ISO_PRESENTATION = 'yyyy-MM-dd';

export const UseDate = () => {
  const formattedISODate = (date: Date): ISODate => formatIsoDate(date);

  const parsedISODate = (str: string, pattern = ISO_PRESENTATION): Date =>
    parseIsoDate(str, pattern);

  return {
    formattedISODate,
    parsedISODate,
  };
};
