import { LoadScript } from '@reckon-web/load-script';

export type LoadAppcuesServiceProps = {
  appId: string;
};

/**
 *
 * @description Returns a promise that resolves if the `domNode` hasn't had any mutations for `wait` milliseconds.
 *
 * May be we need a `maxWait` just to be safe?
 */
const checkIsDOMStable = (options: { wait: number; domNode: Element }) => {
  return new Promise((res) => {
    const startWait = () => {
      return setTimeout(() => {
        observer.disconnect();
        clearTimeout(waitTimeout);
        res(null);
      }, options.wait);
    };

    let waitTimeout = startWait();

    const observer = new MutationObserver(() => {
      clearTimeout(waitTimeout);
      waitTimeout = startWait();
    });

    observer.observe(options.domNode, {
      subtree: true,
      childList: true,
    });
  });
};

export async function LoadAppcuesService({ appId }: LoadAppcuesServiceProps) {
  if (typeof window === 'undefined') {
    return;
  }
  window.AppcuesReadyQueue = window.AppcuesReadyQueue || [];

  const nextRootNode = document.querySelector('#__next');
  if (!nextRootNode) {
    throw Error('Could not find next root node by id __next');
  }

  const checkIsDOMStablePromise = checkIsDOMStable({
    // Seems to be a good value for balance between guaranteeed dom stability vs making user wait. Increase this value if you see flaky behaviour.
    wait: 3000,
    domNode: nextRootNode,
  });

  window.onAppCuesReady = async function (callback) {
    if (!!callback && Array.isArray(window.AppcuesReadyQueue)) {
      window.AppcuesReadyQueue.push(callback);
    }

    await checkIsDOMStablePromise;
    const hasQueue =
      Array.isArray(window.AppcuesReadyQueue) &&
      window.AppcuesReadyQueue.length > 0;

    if (hasQueue) {
      while (window.AppcuesReadyQueue.length) {
        const cb = window.AppcuesReadyQueue.shift();
        if (typeof cb === 'function') {
          cb(window.Appcues);
        }
      }
    }
  };

  const event = await LoadScript({
    id: 'appcues-service',
    src: `//fast.appcues.com/${appId}.js`,
    async: true,
    defer: true,
    parent: 'head',
  });

  const exists = typeof window.Appcues !== 'undefined';
  const ready = window.Appcues.invoked === true;
  if (exists && ready) {
    window.onAppCuesReady();
  }

  return event;
}
