/** @jsx jsx */
import React, { ReactNode } from 'react';
import { ResponsiveProp, jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import { useMediaQuery } from '@balance-web/media-query';
import { BalanceTheme, useTheme } from '@balance-web/theme';

import { useDefaultPageBlockSize } from './useDefaultPageBlockSize';
export type PageBlockProps = {
  children: ReactNode;
  size?: ResponsiveProp<keyof BalanceTheme['breakpoints']>;
  fluid?: boolean;
  expand?: boolean;
} & Parameters<typeof Flex>[0];

export const PageBlock = ({
  children,
  size,
  fluid,
  direction = 'column',
  expand,
  ...props
}: PageBlockProps) => {
  const { mq, mapResponsiveProp } = useMediaQuery();
  const { spacing, breakpoints } = useTheme();
  const { size: contextSize, fluid: contextFluid } = useDefaultPageBlockSize();
  const sizeDirectiveToUse = size || contextSize || 'large';
  const resolvedFluid = typeof fluid === 'boolean' ? fluid : contextFluid;

  const resolvedSize = resolvedFluid
    ? '100%'
    : typeof sizeDirectiveToUse === 'number'
    ? sizeDirectiveToUse
    : mapResponsiveProp(sizeDirectiveToUse, breakpoints);

  return (
    <Flex width="100%" flexGrow={expand ? 1 : 0} justifyContent="center">
      <Flex
        direction={direction}
        css={mq({
          width: '100%',
          marginRight: mapResponsiveProp(sizeDirectiveToUse, spacing),
          marginLeft: mapResponsiveProp(sizeDirectiveToUse, spacing),
          maxWidth: resolvedSize,
        })}
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
};
