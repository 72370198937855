import { setContext } from '@apollo/client/link/context';

type AnnotateRequestHeadersLinkProps = {
  requestHeaders?: Record<string, string>;
};

export function AnnotateRequestHeadersLink({
  requestHeaders,
}: AnnotateRequestHeadersLinkProps) {
  return setContext(async (_, previousContext) => {
    // stored as separate variable to make debugging easier
    const output = {
      ...(previousContext || {}),
      headers: {
        ...(previousContext?.headers || {}),
        ...(requestHeaders || {}),
      },
    };

    return output;
  });
}
