import React from 'react';
import Head from 'next/head';

import { urlWithPublicPrefix } from '@reckon-web/next-config/client';

export function ManifestMeta({
  itunesAppId,
  appleTouchIcon = '/public/apple-touch-icon.png',
  favicon32 = '/public/favicon-32x32.png',
  favicon16 = '/public/favicon-16x16.png',
  maskIcon = '/public/safari-pinned-tab.svg',
  manifestUrl,
  themeColor = '#FFFFFF',
  tileColor = '#FFFFFF',
}: {
  itunesAppId?: string;
  appleTouchIcon?: string;
  favicon32?: string;
  favicon16?: string;
  manifestUrl?: string;
  maskIcon?: string;
  tileColor?: string;
  themeColor?: string;
}) {
  return (
    <Head>
      {itunesAppId && (
        <meta name="apple-itunes-app" content="app-id=1475871177" />
      )}
      {appleTouchIcon && (
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={urlWithPublicPrefix(appleTouchIcon)}
        />
      )}
      {favicon32 && (
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={urlWithPublicPrefix(favicon32)}
        />
      )}
      {favicon16 && (
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={urlWithPublicPrefix(favicon16)}
        />
      )}
      {manifestUrl && (
        <link rel="manifest" href={urlWithPublicPrefix(manifestUrl)} />
      )}
      {maskIcon && (
        <link
          rel="mask-icon"
          href={urlWithPublicPrefix('/public/safari-pinned-tab.svg')}
          color="#F84184"
        />
      )}
      {tileColor && <meta name="msapplication-TileColor" content={tileColor} />}
      {themeColor && <meta name="theme-color" content={themeColor} />}
    </Head>
  );
}
