/** @jsx jsx */
import { forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@balance-web/icon';
import { useTheme } from '@balance-web/theme';
import { Box } from '@balance-web/box';
import { Flex } from '@balance-web/flex';

export const AccordionItemHeader = forwardRef<HTMLButtonElement, any>(
  ({ children, ...props }, forwardedRef) => {
    const theme = useTheme();

    return (
      <Accordion.Header asChild>
        <Accordion.Trigger
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing.large,
            width: '100%',
            paddingBlock: theme.spacing.large,
            paddingInline: theme.spacing.small,
            '&[data-state=open]': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              ' .accordion-trigger-svg-container > svg': {
                transform: 'rotate(180deg)',
              },
            },
            ':hover': {
              // tokenise when new theme is ready.
              background: '#E5F0FF',
            },
          }}
          {...props}
          ref={forwardedRef}
        >
          <Flex flexGrow="1" padding="small" alignItems="center">
            <Box flexGrow="1">{children}</Box>
            <div className="accordion-trigger-svg-container">
              <ChevronDownIcon color="active" aria-hidden size="medium" />
            </div>
          </Flex>
        </Accordion.Trigger>
      </Accordion.Header>
    );
  }
);
