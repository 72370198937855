import { createTypedEnum } from '@reckon-web/formschema-enums';

export const BookStatusEnum = createTypedEnum({
  ACTIVE: 'Active',
  CANCELLED: 'Inactive', // Here Cancelled means Inactive.
  SHARED: 'Shared',
  DELETED: 'Deleted',
  EXPIRED: 'Expired',
  PENDING: 'Pending',
} as const);

export type BookStatusEnumValueType = typeof BookStatusEnum.values[number];

export const BookSortEnum = createTypedEnum({
  ASC: 'ASC',
  DESC: 'DESC',
} as const);

export type BookSortEnumValueType = typeof BookSortEnum.values[number];

export const EXPIRY_WARNING_WINDOW_DAYS = 7;
