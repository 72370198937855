import React from 'react';

export type Props = {
  // area of the app to help zero in on the source of the exception from Sentry
  appZone: string;
  Fallback: (P: {
    error?: Error;
    appZone?: string;
    resetError?: () => void;
  }) => JSX.Element;
  // for running something specific to the appZone this boundary is protecting
  onError?: ({ error, appZone }: { error?: Error; appZone: string }) => void;
  /*  
    for preventing the onError in ErrorBoundaryProvider running,
    for cases when you want to add specific error info instead of a generic log at the root
  */
  preventRootOnError?: boolean;
  children: React.ReactNode;
};

type State = {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { appZone, onError, preventRootOnError } = this.props;

    if (typeof this.context.onError === 'function' && !preventRootOnError)
      this.context.onError({ error, appZone });

    if (typeof onError === 'function') onError({ error, appZone });

    this.setState({
      error,
      errorInfo,
    });
  }
  render() {
    const { Fallback } = this.props;
    const { error } = this.state;

    if (error) {
      if (typeof Fallback === 'undefined') {
        return null;
      }
      // Render custom fallback UI if supplied
      return (
        <Fallback
          appZone={this.props.appZone}
          error={error}
          resetError={() => {
            this.setState({ error: null, errorInfo: null });
          }}
        />
      );
    }

    return this.props.children || null;
  }
}
