import { useContext } from 'react';

import { WizardType } from './types';
import { WizardContext } from './context';

/**
 * @summary : Returns an instance of the wizard from context based on the id provided. Must be used in children of Wizard component as that's where the context is generated.
 * @param id : id of the wizard passed to the useCreateWizard hook.
 */
export function useWizard<
  TSteps,
  TAdditionalState extends object | void = void
>(id: string) {
  const wizardContext = useContext<WizardType<TSteps, TAdditionalState>>(
    WizardContext
  );

  if (!wizardContext || wizardContext.id !== id) return undefined;

  return wizardContext;
}
