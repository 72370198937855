/** @jsx jsx */

import { jsx } from '@balance-web/core';
import { VisuallyHidden } from '@balance-web/a11y';
import { AlertCircleIcon, FileTextIcon, XIcon } from '@balance-web/icon';
import { Inline } from '@balance-web/inline';
import { Stack } from '@balance-web/stack';
import { LoadingDots } from '@balance-web/loading';
import { TextLinkButton } from '@balance-web/text-link';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';

import { truncateFileName } from './utils';

export type FileProps = {
  name: string;
  onOpenClick?: () => void;
  onRemoveClick?: () => void;
  loading?: boolean;
  invalidMessage?: string;
};

export const File = ({
  name,
  invalidMessage,
  loading,
  onRemoveClick,
  onOpenClick,
}: FileProps) => {
  const { palette } = useTheme();

  const formattedFileName = truncateFileName(name);

  return (
    <Stack gap="small">
      <Inline
        padding="small"
        borderRadius="small"
        background="muted"
        alignY="center"
        gap="large"
        border="standard"
        css={{
          borderColor: invalidMessage
            ? palette.text.critical
            : palette.border.standard,
        }}
      >
        <Inline flex="1" gap="small" alignY="center">
          <FileTextIcon size="small" color="dim" />
          {onOpenClick && !loading ? (
            <TextLinkButton color="base" onClick={onOpenClick}>
              <Text size="small">{formattedFileName.name}</Text>
            </TextLinkButton>
          ) : (
            <Text size="small">{formattedFileName.name}</Text>
          )}
        </Inline>
        {loading ? (
          <LoadingDots label="Uploading file" size="small" color="dim" />
        ) : (
          <Inline gap="small">
            {invalidMessage && (
              <AlertCircleIcon size="small" color="critical" />
            )}
            {onRemoveClick && (
              <button
                onClick={onRemoveClick}
                css={{
                  ':hover > svg': {
                    stroke: palette.button.passive.backgroundFocused,
                  },
                  ':active > svg': {
                    stroke: palette.button.passive.backgroundPressed,
                  },
                }}
              >
                <VisuallyHidden>Delete {name}</VisuallyHidden>
                <XIcon size="small" color="dim" />
              </button>
            )}
          </Inline>
        )}
      </Inline>
      {invalidMessage && (
        <Text color="critical" size="xsmall">
          {invalidMessage}
        </Text>
      )}
    </Stack>
  );
};
