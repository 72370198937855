/** @jsx jsx */
import { ReactNode } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

export type RadioItemIndicatorProps = {
  children: ReactNode;
};

export const RadioItemIndicator = ({ children }: RadioItemIndicatorProps) => {
  const { spacing } = useTheme();

  return (
    <DropdownMenuPrimitive.ItemIndicator
      css={{ position: 'absolute', left: spacing.small }}
    >
      {children}
    </DropdownMenuPrimitive.ItemIndicator>
  );
};
