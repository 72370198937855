/** @jsx jsx */

import ts, { transpile } from 'typescript';
import { jsx } from '@balance-web/core';
import { Stack } from '@balance-web/stack';
import { useTheme } from '@balance-web/theme';

import { Highlight } from '../Highlight';

import { CodeResolution, CodeResolutionProps } from './CodeResolution';
import { Pre } from './Pre';

type CodeBlockProps = {
  className: string;
  intro?: boolean;
} & Omit<CodeResolutionProps, 'language'>;

export const CodeBlock = ({
  className,
  code,
  demo,
  intro,
  example,
  typescript,
  metastring,
  scope,
}: CodeBlockProps) => {
  const { palette, radii } = useTheme();

  // syntax highlighting and formatting for the code string
  const language = className?.replace(/language-/, '');

  const transformedCode = typescript
    ? transformCode(code, ts.ScriptTarget.ES2015)
    : code;

  const preformattedUI = (
    <Pre>
      <Highlight code={code} language={language} metastring={metastring} />
    </Pre>
  );

  // early exit to avoid invalid code resolution
  if (!demo && !example) {
    return (
      <Stack
        css={{
          borderRadius: radii.xsmall,
          boxShadow: `0 0 0 1px ${palette.global.border}`,
          overflow: 'hidden',
        }}
      >
        {preformattedUI}
      </Stack>
    );
  }

  // example & demo resolution: pass the pre element through as children since
  // we have to resolve it anyway for standard snippets
  return (
    <Stack
      css={{
        borderRadius: radii.xsmall,
        boxShadow: intro ? undefined : `0 0 0 1px ${palette.global.border}`,
        overflow: 'hidden',
      }}
    >
      <CodeResolution
        originalCode={code}
        code={transformedCode}
        demo={demo}
        intro={intro}
        language={language}
        metastring={metastring}
        scope={scope}
      />
    </Stack>
  );
};

const transformCode = (snippet: string, target: ts.ScriptTarget) =>
  transpile(snippet, {
    jsx: ts.JsxEmit.Preserve,
    target,
  });
