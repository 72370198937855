import React from 'react';
import { PropsWithChildren } from 'react';
import { Container } from '@balance-web/container';
import { Flex, FlexProps } from '@balance-web/flex';

type BookSwitcherFrameProps = FlexProps & {};
export function BookSwitcherFrame({
  children,
  ...props
}: PropsWithChildren<BookSwitcherFrameProps>) {
  return (
    <Container size="small">
      <Flex
        direction="column"
        borderRadius="large"
        padding="large"
        background="base"
        flexGrow={1}
        {...props}
      >
        {children}
      </Flex>
    </Container>
  );
}
