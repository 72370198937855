import React from 'react';
import type { ReactNode } from 'react';
import { render as _render } from '@testing-library/react';

import { ReckonBalanceCore } from '@reckon-web/reckon-balance-core';

const TestingWrapper = ({ children }: { children?: ReactNode }) => {
  return <ReckonBalanceCore themeMode="light">{children}</ReckonBalanceCore>;
};

type RenderArgs = Parameters<typeof _render>;

/**
 * A custom render that wraps all tests in balance theme and providers as suggested here https://testing-library.com/docs/react-testing-library/setup/
 */
export const render = (ui: RenderArgs[0], options?: RenderArgs[1]) =>
  _render(ui, {
    ...options,
    wrapper: ({ children }) => {
      const ConsumerWrapper = options?.wrapper;

      return (
        <TestingWrapper>
          {/* Include consumer wrapper if provided. */}
          {ConsumerWrapper ? (
            <div>
              <ConsumerWrapper>{children}</ConsumerWrapper>
            </div>
          ) : (
            children
          )}
        </TestingWrapper>
      );
    },
  });
