/** @jsx jsx */
import type { KeyboardEvent, MutableRefObject, ReactNode } from 'react';
import { Fragment, useCallback, useMemo, useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { makeId, useId } from '@balance-web/utils';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { Blanket } from '@balance-web/blanket';
import { Portal } from '@balance-web/portal';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { Elevate } from '@balance-web/elevate';

import { Form } from './Form';
import { Title } from './Title';
import { Actions } from './Actions';
import { Header } from './Header';
import { Body } from './Body';
import {
  BottomDrawerContextProvider,
  useBottomDrawerControllerContext,
} from './context';

const easing = 'cubic-bezier(0.2, 0, 0, 1)';

const blanketTransition = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const dialogTransition = {
  entering: { transform: 'translateY(100%)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(100%)' },
  exited: { transform: 'translateY(100%)' },
};

export type BottomDrawerProps = WithDataAttributeProp<{
  /**
   * The height of drawer.
   * */
  height: 'full' | 'half' | number;
  /**
   * Optionally provide aria-labelledby when opting out of the title primitive
   * in favor of custom implementation.
   */
  'aria-labelledby'?: string;
  /**
   * Optionally provide aria-label for when the title hasn't rendered yet, for
   * example during loading.
   */
  'aria-label'?: string;
  /**
   * By default the outter most element of the drawer will receive focus. This
   * behaviour can be overriden by providing this ref which will be focused
   * on load.
   */
  initialFocusRef?: MutableRefObject<any>;
  /**
   * Content of the drawer
   */
  children: ReactNode;
}>;

export const BottomDrawer = ({
  height,
  initialFocusRef,
  children,
  data,
  ...props
}: BottomDrawerProps) => {
  const instanceId = useId();
  const containerRef = useRef<any>(null);
  const theme = useTheme();
  const { onClose, transitionState } = useBottomDrawerControllerContext();

  const drawerHeight = useMemo(() => {
    if (height === 'full') {
      return `calc(100vh - ${theme.spacing.small} * 2)`;
    }
    if (height === 'half') {
      return `50vh`;
    }

    return height;
  }, [height, theme.spacing.small]);

  /** Only create aria-labelledby if aria-label is not provided. */
  const headingId = props['aria-label']
    ? undefined
    : props['aria-labelledby'] || makeId(instanceId, 'bottom-drawer-heading');

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && !event.defaultPrevented) {
        event.preventDefault();
        onClose();
      }
    },
    [onClose]
  );

  const activateFocusLock = useCallback(() => {
    setTimeout(() => {
      if (initialFocusRef && initialFocusRef.current) {
        initialFocusRef.current.focus();
      } else if (containerRef.current) {
        containerRef.current.focus();
      }
    }, 320);
  }, [initialFocusRef]);

  const ariaProps = useMemo(() => {
    return {
      'aria-modal': 'true',
      role: 'dialog',
      'aria-label': props['aria-label'],
      'aria-labelledby': headingId,
    } as const;
  }, [headingId, props]);

  return (
    <BottomDrawerContextProvider value={{ headingId }}>
      <Portal>
        <Fragment>
          <Blanket
            onClick={onClose}
            css={{ transition: `opacity 150ms linear` }}
            style={blanketTransition[transitionState]}
          />
          <FocusLock
            autoFocus
            returnFocus
            onActivation={activateFocusLock}
            lockProps={{ style: { display: 'contents' } }}
          >
            <RemoveScroll enabled forwardProps>
              <div
                id={instanceId}
                onKeyDown={onKeyDown}
                ref={containerRef}
                tabIndex={0}
                {...ariaProps}
                style={dialogTransition[transitionState]}
                css={{
                  backgroundColor: theme.palette.background.base,
                  borderTopLeftRadius: theme.radii.large,
                  borderTopRightRadius: theme.radii.large,
                  bottom: 0,
                  boxShadow: theme.shadow.large,
                  left: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  height: drawerHeight,
                  position: 'fixed',
                  right: 0,
                  transition: `transform 150ms ${easing}`,
                  width: '100vw',
                  zIndex: theme.elevation.modal,
                  display: 'flex',
                  flexDirection: 'column',
                }}
                {...(data ? buildDataAttributes(data) : undefined)}
              >
                <Elevate>{children}</Elevate>
              </div>
            </RemoveScroll>
          </FocusLock>
        </Fragment>
      </Portal>
    </BottomDrawerContextProvider>
  );
};

// Primitives
BottomDrawer.Header = Header;
BottomDrawer.Title = Title;
BottomDrawer.Actions = Actions;
BottomDrawer.Body = Body;
BottomDrawer.Form = Form;
