import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ElementType, PropsWithChildren, ReactNode } from 'react';
import { BlankDialog } from '@balance-web/modal-dialog';
import { Elevate } from '@balance-web/elevate';
import { Container } from '@balance-web/container';
import { LoadingDots } from '@balance-web/loading';
import { Flex } from '@balance-web/flex';
import debug from 'debug';

import { gtmEvent } from '@reckon-web/google-tag-manager';

import { BookSwitcherFrame } from '../BookSwitcherFrame';
import { BookSwitcherCard } from '../BookSwitcherCard';
import type { UnauthorisedAccessStateProps } from '../UnauthorisedAccessState';
import { UnauthorisedAccessState } from '../UnauthorisedAccessState';
import { BookSwitcher } from '../BookSwitcher';
import type { BookSwitcherProps } from '../BookSwitcher';
import { BookSwitcherPage } from '../BookSwitcherPage/BookSwitcherPage';
import { useBookId } from '../BookIdProvider';
import type { BaseBook } from '../types';
import { useBookSwitcherMeta } from '../BookSwitcherMeta/useBookSwitcherMeta';

import type {
  BookSwitcherContext,
  SelectionState,
} from './createBookSwitcherContext';

export type BookSwitcherGateProps<TBook extends BaseBook> = PropsWithChildren<
  BookSwitcherProps<TBook> &
    UnauthorisedAccessStateProps & {
      isOpen?: boolean;
      isOpeningLabel?: string;
      isSettingUpBook?: boolean;
      appName?: string;
      loader: ReactNode;
      layout?: ElementType;
    }
>;

function createDisplayName(name: string) {
  const [first, ...rest] = name.toString();
  return (
    [first.toUpperCase(), rest.join('').toLowerCase()].join('') +
    'BookSwitcherProvider'
  );
}

export function createBookSwitcherProvider<TBook extends BaseBook>(
  name: string,
  Context: BookSwitcherContext<TBook>
) {
  const displayName = createDisplayName(name);

  const log = debug(displayName);

  function Provider({
    children,
    appName,
    books,
    layout,
    isOpeningLabel,
    isSettingUpBook,
    openOtherBookLabel,
    noAccessBookHelpURL,
    loader,
    onSignOutClick,
    onOpenOtherBookClick,
    onCompleteSetupBookClick,
    onOpenBookClick,
    ...props
  }: BookSwitcherGateProps<TBook>) {
    const bookMeta = useBookSwitcherMeta<TBook>();
    const { bookId, setBookId } = useBookId();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpeningBook, setIsOpeningBook] = useState<boolean>(false);
    const currentBook = bookMeta.getBook(bookId) as TBook;
    const isLoading = !!props.isLoading;

    const toggleIsOpen = useCallback(() => {
      setIsOpen((isOpen) => {
        gtmEvent('app.bookswitcher.isOpen', {
          bookId,
          isOpen,
        });
        return !!isOpen;
      });
    }, [bookId]);

    const handleSelectedBookClick = useCallback(
      (bookId: string) => {
        gtmEvent('app.bookswitcher.selectBook', {
          bookId,
        });
        setIsOpen(false);
        setIsOpeningBook(true);
        setBookId(bookId);
        onOpenBookClick(bookId);
      },
      [onOpenBookClick, setBookId]
    );

    const handleCompleteBookSetup = useCallback(
      (bookId: string) => {
        gtmEvent('app.bookswitcher.setupBook', {
          bookId,
        });
        setBookId(bookId);
        setIsOpen(false);
        setIsOpeningBook(true);
        onCompleteSetupBookClick(bookId);
      },
      [onCompleteSetupBookClick, setBookId]
    );

    const selectionState = useMemo((): SelectionState => {
      if (isOpeningBook) {
        return 'IS_OPENING_BOOK';
      }

      if (
        isSettingUpBook &&
        bookMeta.hasBook(bookId) &&
        bookMeta.isSubscriptionABook(bookId) &&
        !bookMeta.isIncompleteBook(bookId)
      ) {
        return 'ATTEMPTING_TO_SETUP_COMPLETED_BOOK';
      }

      if (isSettingUpBook) {
        return 'IS_CREATING_BOOK';
      }

      if (!bookId && bookMeta.hasOneBook) {
        return 'AUTOSELECTING_BOOK';
      }

      if (!bookId && bookMeta.hasMultipleBooks) {
        return 'NEEDS_TO_SELECT_BOOK';
      }

      if (!bookId && !bookMeta.hasBooks) {
        return 'HAS_NO_BOOKS';
      }

      if (
        !isSettingUpBook &&
        bookMeta.hasBook(bookId) &&
        !bookMeta.isSubscriptionABook(bookId)
      ) {
        return 'ATTEMPTING_TO_USE_APP_WITH_INCOMPLETE_BOOK';
      }

      if (
        !isSettingUpBook &&
        bookMeta.hasBook(bookId) &&
        bookMeta.isIncompleteBook(bookId)
      ) {
        return 'ATTEMPTING_TO_USE_APP_WITH_INCOMPLETE_BOOK';
      }

      if (!!bookId && bookMeta.hasBook(bookId)) {
        return 'IS_AUTHORISED_TO_VIEW';
      }

      return 'IS_NOT_AUTHORISED_TO_VIEW';
    }, [isOpeningBook, isSettingUpBook, bookId, bookMeta]);

    const Layout = useMemo(() => {
      if (layout) {
        return layout;
      }
      return BookSwitcherPage;
    }, [layout]);

    const BookSelectorAsPage = useCallback(() => {
      return (
        <Layout>
          <Container size="small">
            <BookSwitcherFrame>
              <BookSwitcher<TBook>
                {...props}
                appName={appName}
                books={books}
                onOpenBookClick={handleSelectedBookClick}
                onCompleteSetupBookClick={handleCompleteBookSetup}
              />
            </BookSwitcherFrame>
          </Container>
        </Layout>
      );
    }, [
      Layout,
      appName,
      books,
      handleCompleteBookSetup,
      handleSelectedBookClick,
      props,
    ]);

    /**
     * Should we visually block rendering while loading?
     */
    const showLoader =
      isLoading ||
      selectionState === 'IS_OPENING_BOOK' ||
      selectionState === 'AUTOSELECTING_BOOK';

    useEffect(
      function ActOnSelectionState() {
        if (isLoading) {
          return;
        }

        if (selectionState === 'ATTEMPTING_TO_SETUP_COMPLETED_BOOK') {
          gtmEvent('app.bookswitcher.autoSelectBook', {
            bookId,
            reason: 'ATTEMPTING_TO_SETUP_COMPLETED_BOOKID',
          });
          handleSelectedBookClick(bookId);
          return;
        }

        if (selectionState === 'AUTOSELECTING_BOOK' && bookMeta.firstBookId) {
          gtmEvent('app.bookswitcher.autoSelectBook', {
            bookId,
            reason: 'AUTOSELECTING_BOOK',
          });
          handleSelectedBookClick(bookMeta.firstBookId);
          return;
        }

        if (selectionState === 'ATTEMPTING_TO_USE_APP_WITH_INCOMPLETE_BOOK') {
          gtmEvent('app.bookswitcher.autoSelectBook', {
            bookId,
            reason: 'ATTEMPTING_TO_USE_APP_WITH_INCOMPLETE_BOOKID',
          });
          onCompleteSetupBookClick(bookId);
          return;
        }

        /**
         * This state is handled visually by the jsx below
         */
        if (selectionState === 'IS_CREATING_BOOK') {
          gtmEvent('app.bookswitcher.autoSelectBook', {
            bookId,
            reason: 'USER_IS_SETTING_UP_BOOK',
          });
          return;
        }

        /**
         * This state is handled visually by the jsx below
         */
        if (selectionState === 'NEEDS_TO_SELECT_BOOK') {
          gtmEvent('app.bookswitcher.autoSelectBook', {
            bookId,
            reason: 'NEEDS_TO_SELECT_BOOK',
          });
          return;
        }

        /**
         * This state is handled visually by the jsx below
         */
        if (selectionState === 'HAS_NO_BOOKS') {
          gtmEvent('app.bookswitcher.autoSelectBook', {
            bookId,
            reason: 'NO_SELECTED_BOOK_USER_DOES_NOT_HAVE_A_BOOK',
          });
          return;
        }

        return;
      },
      [
        bookId,
        bookMeta.firstBookId,
        isLoading,
        selectionState,
        showLoader,
        onCompleteSetupBookClick,
        onOpenBookClick,
        handleSelectedBookClick,
      ]
    );

    if (showLoader && !!loader) {
      return <Elevate>{loader}</Elevate>;
    }

    if (showLoader && !loader) {
      <Elevate>
        <Layout>
          <Flex
            flexGrow={1}
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <LoadingDots label="Loading available books" size="medium" />
          </Flex>
        </Layout>
      </Elevate>;
    }

    return (
      <Context.Provider
        value={{
          ...bookMeta,
          selectionState,
          isLoading,
          books,
          currentBook,
          isOpen,
          setIsOpen,
          toggleIsOpen,
        }}
      >
        <Elevate>
          {/**
           *  If there's a bookid already selected in the app
           *  but the user wants the book switcher open, then
           *  we open the bookswitcher in modal mode
           */}
          <BlankDialog
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
          >
            <Container size="small">
              <BookSwitcherFrame
                justifyContent="center"
                alignItems="center"
                maxHeight="80vh"
              >
                <BookSwitcher
                  {...props}
                  appName={appName}
                  books={books}
                  onOpenBookClick={handleSelectedBookClick}
                  onCompleteSetupBookClick={handleCompleteBookSetup}
                />
              </BookSwitcherFrame>
            </Container>
          </BlankDialog>

          {/**
           * User has a bookid but is not authorised to view it
           */}
          {selectionState === 'IS_NOT_AUTHORISED_TO_VIEW' && (
            <Layout>
              <BookSwitcherCard>
                <UnauthorisedAccessState
                  onOpenOtherBookClick={() => {
                    setIsOpen(true);
                  }}
                  onSignOutClick={onSignOutClick}
                  openOtherBookLabel={openOtherBookLabel}
                  noAccessBookHelpURL={noAccessBookHelpURL}
                />
              </BookSwitcherCard>
            </Layout>
          )}

          {/**
           * User needs to select a book
           */}
          {(selectionState === 'NEEDS_TO_SELECT_BOOK' && (
            <BookSelectorAsPage />
          )) ||
            null}

          {/**
           * User needs to create a book
           */}
          {(selectionState === 'HAS_NO_BOOKS' && <BookSelectorAsPage />) ||
            null}
        </Elevate>

        {/**
         * Only render children if we have a bookid or we are setting up a book
         */}
        {(selectionState === 'IS_CREATING_BOOK' && children) || null}
        {(selectionState === 'IS_AUTHORISED_TO_VIEW' && children) || null}
      </Context.Provider>
    );
  }

  log(`provider.create`, displayName);
  Provider.displayName = displayName;

  return Provider;
}
