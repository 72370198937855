/** @jsx jsx */
import React, { ReactElement, forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { Box } from '@balance-web/box';
import { flattenElements } from '@balance-web/utils';

import { SidebarNavigationItemProps } from './SidebarNavigationItem';
import { SidebarNavigationGroupProps } from './SidebarNavigationGroup';
import { SidebarNavigationContext } from './sidebarNavigationContext';
import { useGroupSeparatorStyles, useSeparatorStyles } from './styleHooks';
import { SidebarNavigationGroup } from './SidebarNavigationGroup';

export type VerticalNavigationProps = {
  /** Optional key that matches SidebarNavigationItem.navigationKey to mark the selected route.*/
  selectedNavigationKey?: string;
  /** Optional key that matches SidebarNavigationGroup.groupKey to set a group as expanded on load.*/
  defaultExpandedGroupKey?: string;
  /** SidebarNavigationItem and SidebarNavigationGroup rendered as children.  */
  children:
    | ReactElement<SidebarNavigationItemProps>[]
    | ReactElement<SidebarNavigationGroupProps>[];
};

export const SidebarNavigation = forwardRef<
  HTMLAnchorElement,
  VerticalNavigationProps
>(({ children, selectedNavigationKey, defaultExpandedGroupKey }, ref) => {
  const childArray = flattenElements(children);

  const separatorStyles = useSeparatorStyles();
  const groupSeparatorStyles = useGroupSeparatorStyles();

  return (
    <SidebarNavigationContext.Provider
      value={{ selectedNavigationKey, defaultExpandedGroupKey }}
    >
      <Box as="nav" paddingY="medium">
        <ul>
          {childArray.map((child, index) => {
            const isNavigationGroup = child.type === SidebarNavigationGroup;
            const lastNavigationItemAttribute =
              index === childArray.length - 1
                ? { 'data-last-child': true }
                : {};

            return (
              <li
                key={child.props['label'] || child['key'] || index}
                css={isNavigationGroup ? groupSeparatorStyles : separatorStyles}
                {...lastNavigationItemAttribute}
              >
                {child}
              </li>
            );
          })}
        </ul>
      </Box>
    </SidebarNavigationContext.Provider>
  );
});
