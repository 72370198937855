import { PropsWithChildren } from 'react';
import React from 'react';
import { Stack } from '@balance-web/stack';
import { Container } from '@balance-web/container';

export function BookSwitcherCard({ children }: PropsWithChildren<{}>) {
  return (
    <Container size="xsmall">
      <Stack borderRadius="large" padding="large" background="base">
        {children}
      </Stack>
    </Container>
  );
}
