import type { ComponentType } from 'react';
import React, { useEffect, useMemo } from 'react';
import { useRef } from 'react';

import { ExternalLinkIcon } from '../icons/ExternalLinkIcon';
import { CaretRightIcon } from '../icons/CaretRightIcon';
import {
  buildDataAttributes,
  isLeftNavigatorOpen,
  toggleHTMLAttribute,
} from '../common';
import type { AppLeftNavigatorItem } from '../types';
import { useAnimatedExpandCollapseStyles } from '../useAnimatedExpandCollapseStyles';
import { HoverOverlay } from '../HoverOverlay';
import {
  HOVER_BG,
  HOVER_TARGET_HEIGHT,
  PRESSED_BG,
  TRANSITION_CURVE,
} from '../constants';
import { PressedOverlay } from '../PressedOverlay';

import { ChildNavigationItem } from './ChildNavigationItem';

type RootNavigationItemProps = {
  item: AppLeftNavigatorItem;
  activeRouteId?: string;
  linkComponent?: ComponentType<any>;
};
export const RootNavigationItem = (props: RootNavigationItemProps) => {
  const rootItemRef = useRef<HTMLLIElement | null>(null);
  const rootItemHeaderRef = useRef<HTMLButtonElement | null>(null);
  const animatedExpandCollapseStyles = useAnimatedExpandCollapseStyles();

  const tabToggleTimeoutRef = useRef<number | null>(null);

  useEffect(
    function rootItemPopoverMenuEvents() {
      if (props.item.type !== 'root' || !rootItemHeaderRef.current) {
        return;
      }

      const itemTargetEl = rootItemHeaderRef.current;
      const menuContainerEl = document.querySelector(
        `[data-popovermenuof='${props.item.routeId}'].root-item-popover-menu`
      );

      if (!menuContainerEl) {
        // Root items that don't have children only need to make sure any open popover menus are closed.
        const onMouseEnterChildlessRootItemTarget = () => {
          document
            .querySelectorAll('.root-item-popover-menu')
            .forEach((element) => {
              element.setAttribute('data-openfromroot', '0');
              element.setAttribute('data-openfrommenu', '0');
            });
        };

        itemTargetEl.addEventListener(
          'mouseenter',
          onMouseEnterChildlessRootItemTarget
        );
        return () => {
          itemTargetEl.removeEventListener(
            'mouseenter',
            onMouseEnterChildlessRootItemTarget
          );
        };
      }

      const onMouseEnterRootItemTarget = () => {
        if (isLeftNavigatorOpen()) {
          return;
        }

        document
          .querySelectorAll('.root-item-popover-menu')
          .forEach((element) => {
            element.setAttribute('data-openfromroot', '0');
            element.setAttribute('data-openfrommenu', '0');
          });

        const menuContainerEl = document.querySelector(
          `[data-popovermenuof='${props.item.routeId}'].root-item-popover-menu`
        );
        if (!menuContainerEl) {
          return;
        }

        menuContainerEl.setAttribute('data-openfromroot', '1');
        menuContainerEl.setAttribute('data-openfrommenu', '1');
      };

      const onMouseLeaveRootItemTarget = () => {
        if (isLeftNavigatorOpen()) {
          return;
        }

        const menuContainerEl = document.querySelector(
          `[data-popovermenuof='${props.item.routeId}'].root-item-popover-menu`
        );

        if (!menuContainerEl) {
          return;
        }

        menuContainerEl.setAttribute('data-openfromroot', '0');
      };

      const onMouseEnterMenuContainer = () => {
        // if (isLeftNavigatorOpen()) {
        //   return;
        // }
        // const menuContainerEl = document.querySelector(
        //   `[data-popovermenuof='${props.item.routeId}'].root-item-popover-menu`
        // );
        // if (!menuContainerEl) {
        //   return;
        // }
        // console.log(
        //   'menuContainerEl',
        //   menuContainerEl,
        //   menuContainerEl.getAttribute('data-openfromroot')
        // );
        // menuContainerEl.setAttribute('data-openfrommenu', '1');
      };

      const onMouseLeaveMenuContainer = () => {
        if (isLeftNavigatorOpen()) {
          return;
        }

        const menuContainerEl = document.querySelector(
          `[data-popovermenuof='${props.item.routeId}'].root-item-popover-menu`
        );

        if (!menuContainerEl) {
          return;
        }

        menuContainerEl.setAttribute('data-openfrommenu', '0');
      };

      // We manage expanded classes on both the item and the panel to avoid panel disappearing
      //  in the tiny space between the item and the panel.
      itemTargetEl.addEventListener('mouseenter', onMouseEnterRootItemTarget);
      itemTargetEl.addEventListener('mouseleave', onMouseLeaveRootItemTarget);

      menuContainerEl.addEventListener('mouseenter', onMouseEnterMenuContainer);
      menuContainerEl.addEventListener('mouseleave', onMouseLeaveMenuContainer);

      return () => {
        itemTargetEl.removeEventListener(
          'mouseenter',
          onMouseEnterRootItemTarget
        );
        itemTargetEl.removeEventListener(
          'mouseleave',
          onMouseLeaveRootItemTarget
        );

        menuContainerEl.removeEventListener(
          'mouseenter',
          onMouseEnterMenuContainer
        );
        menuContainerEl.removeEventListener(
          'mouseleave',
          onMouseLeaveMenuContainer
        );
      };
    },
    [props.item]
  );

  if (props.item.type !== 'root') {
    throw Error('Item must be of type root');
  }

  const dataAttributes = buildDataAttributes({
    routeid: props.item.routeId,
    haschildren: props.item.items?.length ? '1' : '0',
    activeroute: props.item.routeId === props.activeRouteId ? '1' : '0',
  });

  const childrenContainerId = `root-item-children-${props.item.routeId}`;

  const menuArriaAttributes = useMemo(() => {
    if (!props.item.items?.length) {
      return null;
    }

    return {
      'aria-controls': childrenContainerId,
      'aria-expanded': false,
    };
  }, [childrenContainerId, props.item.items?.length]);

  const Icon = props.item.icon;

  const Tag = props.item.url ? props.linkComponent || 'a' : 'button';

  return (
    <li
      ref={rootItemRef}
      className="leftnav-root-item"
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      {...dataAttributes}
      {...props.item.attributes}
    >
      {props.item.showSeparatorAbove ? (
        <div style={{ paddingBlock: 4 }}>
          <div
            className="root-item-separator"
            role="separator"
            style={{
              height: 1,
              backgroundColor: '#e4e5e6',
            }}
          />
        </div>
      ) : null}
      <Tag
        // @ts-ignore Types probably un-reconcilable
        ref={rootItemHeaderRef}
        {...(props.item.url
          ? { href: props.item.url, target: props.item.target }
          : {})}
        className="root-item-header"
        style={{
          minHeight: HOVER_TARGET_HEIGHT,
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={() => {
          if (tabToggleTimeoutRef.current !== null) {
            clearTimeout(tabToggleTimeoutRef.current);
          }

          const childrentListEl = rootItemRef.current?.querySelector(
            `ul#root-item-children-${props.item.routeId}`
          ) as HTMLUListElement;
          const wasOpen =
            rootItemRef.current?.getAttribute('data-open') === '1';

          if (!wasOpen) {
            childrentListEl.style.display = 'flex';
          }

          setTimeout(() => {
            toggleHTMLAttribute(rootItemRef.current, 'data-open', '1', '0');
            toggleHTMLAttribute(
              rootItemHeaderRef.current,
              'aria-expanded',
              'true',
              'false'
            );
          }, 0);
        }}
        {...menuArriaAttributes}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            className="root-item-chevron-container"
            style={{
              overflow: 'hidden',
              transition: `width 300ms ${TRANSITION_CURVE}, opacity 300ms ${TRANSITION_CURVE}`,
            }}
          >
            {props.item.items?.length ? (
              <div
                className="root-item-chevron"
                style={{
                  transition: `transform 300ms ${TRANSITION_CURVE}`,
                  transformOrigin: 'center',
                }}
              >
                <CaretRightIcon />
              </div>
            ) : null}
          </div>
          <div
            className="root-item-icon interactive-overlay"
            style={{
              padding: 8,
              borderRadius: 999,
              transition: `background 750ms ${TRANSITION_CURVE}`,
            }}
          >
            <HoverOverlay
              styles={{
                borderRadius: 999,
              }}
            />
            <PressedOverlay
              styles={{
                borderRadius: 999,
              }}
            />
            <Icon size="medium" color="muted" />
          </div>
        </div>

        <div
          style={{
            height: HOVER_TARGET_HEIGHT,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            paddingInline: 16,
            borderRadius: 999,
            transition: `opacity 300ms ${TRANSITION_CURVE}`,
          }}
          className="root-item-text interactive-overlay"
        >
          <HoverOverlay
            styles={{
              borderRadius: 999,
              background: HOVER_BG,
            }}
          />
          <PressedOverlay
            styles={{
              borderRadius: 999,
              background: PRESSED_BG,
            }}
          />
          <div
            style={{
              userSelect: 'none',
              fontSize: '0.875rem',
              color: '#181A20',
              fontWeight: 400,
              lineHeight: 1.4,
            }}
          >
            {props.item.label}
          </div>
          {props.item.target === '_blank' ? (
            <div style={{ marginTop: -4, paddingLeft: 4 }}>
              <ExternalLinkIcon />
            </div>
          ) : null}
        </div>
      </Tag>
      <div
        className="root-item-header-separator"
        style={{
          height: 8,
          transition: `height 300ms ${TRANSITION_CURVE}`,
        }}
      />
      <ul
        className="root-item-children-container"
        id={childrenContainerId}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          flexGrow: 1,
        }}
      >
        {props.item.items?.map((item) => {
          return (
            <li
              key={item.routeId}
              className="root-item-children"
              style={{ paddingLeft: item.items?.length ? 56 : 60 }}
            >
              <div
                className="animation-inner-wrapper"
                style={animatedExpandCollapseStyles.innerWrapper}
              >
                <ChildNavigationItem
                  item={item}
                  activeRouteId={props.activeRouteId}
                  linkComponent={props.linkComponent}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </li>
  );
};
