/** @jsx jsx */
import { forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import type { MenuChipProps } from '@balance-web/chip';
import { MenuChip } from '@balance-web/chip';

export const TriggerButton = forwardRef<
  any,
  Omit<MenuChipProps, 'aria-expanded' | 'hasValue'>
>((props, ref) => {
  // @ts-ignore aria-expanded is handled by Trigger component
  return <MenuChip ref={ref} {...props} hasValue={false} />;
});
