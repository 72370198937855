/** @jsx jsx */

import type {
  HTMLAttributes,
  HTMLProps,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { forwardRef, memo } from 'react';
import type { ButtonProps } from '@balance-web/button';
import { jsx } from '@balance-web/core';
import { CheckIcon } from '@balance-web/icon/icons/CheckIcon';
import { XIcon } from '@balance-web/icon/icons/XIcon';
import { SearchIcon } from '@balance-web/icon/icons/SearchIcon';
import { LoadingDots } from '@balance-web/loading';
import { useTheme } from '@balance-web/theme';
import { MenuChip } from '@balance-web/chip';

const SIZE_KEY = 'base';

// Buttons
// ------------------------------

type SelectMenuButtonProps = {
  hasValue: boolean;
  selectionCount: number;
  onClear?: MouseEventHandler;
} & Omit<ButtonProps, 'variat' | 'size'> & {
    variant: 'filled' | 'outline';
    size: 'small' | 'medium';
  };
export const SelectMenuButton = forwardRef<
  HTMLButtonElement,
  SelectMenuButtonProps
>(({ hasValue, onClear, disabled, selectionCount, ...props }, ref) => {
  // @ts-ignore data-expanded is acceptable internal API
  const isExpanded = props['aria-expanded'] || props['data-expanded'];

  // The absolute relative/positioning is to avoid invalid DOM nesting, where
  // a button is the descendent of another button.
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'inline-flex',
        position: 'relative',
      }}
    >
      <MenuChip
        ref={ref}
        hasValue={hasValue}
        selectionCount={selectionCount}
        aria-expanded={isExpanded}
        disabled={disabled}
        onClear={
          onClear
            ? () => {
                onClear({} as any);
              }
            : undefined
        }
        {...props}
      />
    </div>
  );
});

SelectMenuButton.displayName = 'SelectMenuButton';

export const ClearButton = forwardRef<
  HTMLButtonElement,
  Omit<HTMLProps<HTMLButtonElement>, 'type'>
>(({ children, ...props }, ref) => {
  const { palette, radii, sizing, spacing } = useTheme();

  return (
    <button
      aria-label="Clear all values"
      css={{
        alignItems: 'center',
        background: 0,
        border: 0,
        borderRadius: radii.xsmall,
        color: palette.actionButton.text,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        marginRight: spacing.xsmall,
        padding: 0,
        height: sizing.xsmall,
        width: sizing.xsmall,

        '&[disabled]': {
          cursor: 'default',
        },

        ':not(&[disabled])': {
          ':hover, &.focus-visible': {
            background: palette.actionButton.backgroundFocused,
            color: palette.actionButton.textFocused,
            outline: 0,
          },
          ':active': {
            background: palette.actionButton.backgroundPressed,
            color: palette.actionButton.textPressed,
          },
        },
      }}
      ref={ref}
      type="button"
      {...props}
    >
      <XIcon size="small" />
    </button>
  );
});

ClearButton.displayName = 'ClearButton';

// Menu Provider
// ------------------------------

type MenuProps = {
  children: ReactNode;
};

export const Menu = forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
  const { spacing, sizing } = useTheme();

  return (
    <div
      ref={ref}
      css={{
        position: 'relative',
        maxHeight: `calc(${sizing[SIZE_KEY]} * 6.5)`, // show six and half items, creating affordance that there's more to scroll
        minWidth: 220,
        maxWidth: 360,
        outline: 0,
        overflowY: 'auto',
        paddingBottom: spacing.xsmall,
        paddingTop: spacing.xsmall,
        WebkitOverflowScrolling: 'touch',

        ':empty': {
          padding: 0,
        },
      }}
      {...props}
    />
  );
});

Menu.displayName = 'Menu';

// Menu Item
// ------------------------------

type MenuItemProps = {
  /** Item content */
  children: ReactNode;
  /** Determines if item is disabled */
  disabled?: boolean;
  /** Give the item a selected appearance */
  isSelected?: boolean;
  /** An event handler for when the item is clicked */
  onClick?: (event: MouseEvent) => void;
  /** The role of the item */
  role?: 'menuitemradio' | 'menuitemcheckbox' | 'option';
} & HTMLAttributes<HTMLDivElement>;

export const MenuItem = memo(
  forwardRef<HTMLDivElement, MenuItemProps>((props, ref) => {
    const {
      children,
      disabled,
      isSelected,
      role = 'menuitem',
      ...consumerProps
    } = props;
    const { palette, sizing, spacing, typography } = useTheme();

    // NOTE: must be a button element so click events extend to "Enter/Space" keypress
    return (
      <div
        ref={ref}
        aria-disabled={disabled}
        aria-checked={isSelected}
        role={role}
        tabIndex={0}
        css={{
          alignItems: 'center',
          background: 0,
          border: 0,
          color: palette.menuItem.text,
          cursor: 'pointer',
          display: 'flex',
          fontSize: typography.fontSize.small,
          fontWeight: typography.fontWeight.medium,
          height: sizing[SIZE_KEY],
          outline: 0, // clicked items will be "real focused". we don't want to show the outline
          paddingBottom: 0,
          paddingLeft: spacing.medium,
          paddingRight: spacing.medium,
          paddingTop: 0,
          position: 'relative',
          textAlign: 'left',

          ':active': {
            backgroundColor: palette.menuItem.backgroundPressed,
          },

          // the highlighted styles for hover and faux-focus
          '&[aria-selected=true]': {
            background: palette.menuItem.backgroundFocused,
            outline: 'none !important',
          },
          // the currently selected item(s)
          '&[aria-checked=true]': {
            color: palette.text.active,
          },
          '&[aria-disabled=true]': {
            color: palette.text.dim,
            pointerEvents: 'none',
          },
        }}
        {...consumerProps}
      >
        <SelectionIndicator isSelected={isSelected} />
        <div
          css={{
            flex: 1,
            minWidth: 0,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {children}
        </div>
      </div>
    );
  })
);

MenuItem.displayName = 'MenuItem';

const ICON_SIZE = 16;

// Selection Indicator
// ------------------------------

type IndicatorProps = {
  isSelected?: boolean;
};
const SelectionIndicator = ({ isSelected }: IndicatorProps) => {
  const theme = useTheme();

  let selectedColor = theme.palette.text.link;
  let gutter = theme.spacing.small;

  // always render the wrapper so siblings don't move around when items are
  // selected vs not-selected. instead, we toggle the icon color for show/hide
  return (
    <span css={{ flexShrink: 0, marginRight: gutter }}>
      <span css={{ color: isSelected ? selectedColor : 'transparent' }}>
        <CheckIcon size={ICON_SIZE} />
      </span>
    </span>
  );
};

// Combo box
// ------------------------------

export const ComboBox = forwardRef<
  HTMLDivElement,
  { showDivider: boolean } & HTMLAttributes<HTMLDivElement>
>(({ children, showDivider, ...props }, ref) => {
  const { palette } = useTheme();
  return (
    <div ref={ref} {...props}>
      {children}
      {showDivider && (
        <div
          css={{
            height: 1,
            width: '100%',
            backgroundColor: palette.global.border,
          }}
        />
      )}
    </div>
  );
});

ComboBox.displayName = 'ComboBox';

// Search Input
// ------------------------------

export const SearchInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ disabled, ...props }, ref) => {
  const { palette, spacing, typography } = useTheme();

  return (
    <div css={{ alignItems: 'center', display: 'flex', position: 'relative' }}>
      <span
        css={{
          display: 'block',
          left: spacing.medium,
          lineHeight: 1,
          marginTop: -1,
          pointerEvents: 'none',
          position: 'absolute',
        }}
      >
        <SearchIcon color="dim" size={ICON_SIZE} />
      </span>
      <input
        tabIndex={disabled ? -1 : 0} // don't actually disable the input, that makes it impossible to focus, even programmatically
        ref={ref}
        css={{
          background: 0,
          border: 0,
          boxSizing: 'border-box',
          color: palette.formInput.text,
          fontWeight: typography.fontWeight.medium,
          outline: 0,
          padding: spacing.medium,
          paddingLeft: `calc(${spacing.medium} + ${spacing.small} + ${ICON_SIZE}px)`,
          paddingRight: spacing.large,
          fontSize: typography.fontSize.small,
          width: '100%',

          '&::placeholder': {
            color: palette.formInput.textPlaceholder,
          },
        }}
        {...props}
      />
    </div>
  );
});

SearchInput.displayName = 'SearchInput';

// Empty State
// ------------------------------

export const EmptyState = (props: { children?: ReactNode }) => {
  const { palette, sizing, typography } = useTheme();

  return (
    <div
      css={{
        alignItems: 'center',
        color: palette.text.dim,
        display: 'flex',
        flexDirection: 'column',
        fontSize: typography.fontSize.small,
        fontWeight: typography.fontWeight.medium,
        height: sizing[SIZE_KEY],
        justifyContent: 'center',
      }}
      {...props}
    />
  );
};

// Loading Indicator
// ------------------------------

export const LoadingIndicator = () => {
  const { sizing } = useTheme();

  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: sizing[SIZE_KEY],
        justifyContent: 'center',
      }}
    >
      <LoadingDots label="Filtering options" />
    </div>
  );
};

/**
 * Component to fill the space where items would typically live within the menu.
 * Used for things like "no results" messages etc.
 */
export const MenuItemSlot = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      {...props}
    />
  );
};

/**
 * Component shown at the bottom of a paginated list, revealed when more items
 * are loading.
 */
export const MenuItemLoadingIndicator = (
  props: HTMLAttributes<HTMLDivElement>
) => {
  return (
    <MenuItemSlot {...props}>
      <LoadingDots label="loading more" color="dim" />
    </MenuItemSlot>
  );
};
