import React, { Fragment, useMemo } from 'react';
import { useRawTheme } from '@balance-web/theme';
import { Stack } from '@balance-web/stack';
import { Flex } from '@balance-web/flex';

import {
  CHILD_ROPE_ITEM_HEIGHT,
  PROGRESS_ROPE_ZINDEX,
  ROPE_ITEM_HEIGHT,
  SMALL_ROPE_MARKER_DIMENSIONS,
} from './common';
import { StepItem } from './WizardProgressRopeStepItem';
import { GroupItem } from './WizardProgressRopeGroupItem';

type Step = {
  id: number;
  title: string;
};

type Group = {
  title: string;
  steps: Array<Step>;
};

type WizardSidebarProps = {
  items: Array<Step | Group>;
  currentStepId: number;
};

type ProgressRopeItem = Pick<Step, 'title'> & {
  ropeIndex: number;
  stepId: number;
} & (
    | {
        type: 'step';
      }
    | {
        type: 'child-step';
        parentRopeItemIndex: number;
        parentMinStepId: number;
        parentMaxStepId: number;
      }
    | { type: 'group'; minStepId: number; maxStepId: number }
  );

export const WizardProgressRope = (props: WizardSidebarProps) => {
  const rawTheme = useRawTheme();

  // Flatten items to make renderring and rope calculations easier
  const flatProgressRopeItems = useMemo(() => {
    let runningStepId = 0;
    return props.items.reduce((flatItems, item) => {
      const newItems: Array<ProgressRopeItem> = [];

      const progressItem: ProgressRopeItem =
        'steps' in item
          ? {
              type: 'group',
              title: item.title,
              // Rope index always goes up
              ropeIndex: flatItems.length + 1,
              stepId: -1,
              minStepId: item.steps[0].id,
              maxStepId: item.steps[item.steps.length - 1].id,
            }
          : {
              type: 'step',
              title: item.title,
              // Rope index always goes up
              ropeIndex: flatItems.length + 1,
              stepId: runningStepId + 1,
            };
      flatItems.push(progressItem);
      if (progressItem.type === 'step') {
        runningStepId++;
      }
      if ('steps' in item) {
        const parentRopeItemIndex = flatItems.length;
        item.steps.forEach((step, i) => {
          newItems.push({
            type: 'child-step',
            title: step.title,
            // Rope index always goes up by +1 because these are child items
            ropeIndex: flatItems.length + 1 + i,
            stepId: runningStepId + 1,
            parentRopeItemIndex,
            parentMinStepId: item.steps[0].id,
            parentMaxStepId: item.steps[item.steps.length - 1].id,
          });
          runningStepId++;
        });
      }
      flatItems.push(...newItems);
      return flatItems;
    }, [] as Array<ProgressRopeItem>);
  }, [props.items]);

  const progressRopeHeight = useMemo(() => {
    const currentRopeItem = flatProgressRopeItems.find((item) => {
      return item.stepId === props.currentStepId;
    });

    if (!currentRopeItem) {
      throw Error('Invalid step Id');
    }

    const progressedRopeItems: Array<ProgressRopeItem> = [];
    const flatRopeItemCutoffIndex = flatProgressRopeItems.find((item) => {
      return item.ropeIndex === currentRopeItem.ropeIndex;
    })?.ropeIndex!;

    for (const ropeItem of flatProgressRopeItems) {
      if (ropeItem.ropeIndex <= flatRopeItemCutoffIndex) {
        if (ropeItem.type === 'child-step') {
          const parentRopeItem = flatProgressRopeItems.find((item) => {
            return item.ropeIndex === ropeItem.parentRopeItemIndex;
          });

          if (parentRopeItem?.type === 'group') {
            if (
              currentRopeItem.stepId >= parentRopeItem.minStepId &&
              currentRopeItem.stepId <= parentRopeItem.maxStepId
            ) {
              progressedRopeItems.push(ropeItem);
            }
          } else {
            progressedRopeItems.push(ropeItem);
          }
        } else {
          if (
            ropeItem.type === 'step' &&
            ropeItem.stepId <= currentRopeItem.stepId
          ) {
            progressedRopeItems.push(ropeItem);
          } else {
            progressedRopeItems.push(ropeItem);
          }
        }
      }
    }

    let height = 0;
    progressedRopeItems.forEach((item, i) => {
      const ropeItemGap = i > 0 ? rawTheme.spacing.xlarge : 0;
      height +=
        (item.type === 'group' || item.type === 'step'
          ? ROPE_ITEM_HEIGHT
          : CHILD_ROPE_ITEM_HEIGHT -
            (i === progressedRopeItems.length - 1
              ? SMALL_ROPE_MARKER_DIMENSIONS
              : 0)) + ropeItemGap;
    });

    return height;
  }, [flatProgressRopeItems, props.currentStepId, rawTheme.spacing.xlarge]);

  return (
    <Flex alignItems="stretch">
      <Stack flex="1" gap="xlarge">
        {flatProgressRopeItems.map((item) => {
          if (item.type === 'group') {
            const isGroupActive =
              props.currentStepId >= item.minStepId &&
              props.currentStepId <= item.maxStepId;

            const isGroupComplete = props.currentStepId > item.maxStepId;

            return (
              <Fragment key={item.title}>
                <GroupItem
                  active={isGroupActive}
                  complete={isGroupComplete}
                  title={item.title}
                />
              </Fragment>
            );
          }

          if (item.type === 'step') {
            return (
              <StepItem
                key={item.stepId}
                title={item.title}
                status={
                  item.stepId === props.currentStepId
                    ? 'active'
                    : props.currentStepId > item.stepId
                    ? 'complete'
                    : 'inactive'
                }
                child={false}
              />
            );
          }

          if (item.type === 'child-step') {
            const isGroupActive =
              props.currentStepId >= item.parentMinStepId &&
              props.currentStepId <= item.parentMaxStepId;

            const isGroupComplete = props.currentStepId > item.parentMaxStepId;

            if (isGroupComplete || !isGroupActive) {
              return null;
            }

            return (
              <StepItem
                key={item.stepId}
                title={item.title}
                status={
                  item.stepId === props.currentStepId
                    ? 'active'
                    : props.currentStepId > item.stepId
                    ? 'complete'
                    : 'inactive'
                }
                child={true}
              />
            );
          }

          throw Error('Invalid item type');
        })}
      </Stack>

      <div style={{ position: 'relative' }}>
        {/* Dashed rope path */}
        <svg
          style={{
            height: '100%',
            width: 2,
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        >
          <line
            fill="none"
            stroke="#DDDEE9"
            strokeWidth="4px"
            stroke-miterlimit="10"
            x1="0"
            y1="0"
            x2="0"
            y2="99%"
            stroke-dasharray="5,5"
          />
        </svg>

        {/* Solid progress path */}
        <div
          style={{
            height: progressRopeHeight,
            position: 'absolute',
            left: 0,
            top: 0,
            background: '#5A3FFF',
            width: 2,
            zIndex: PROGRESS_ROPE_ZINDEX,
            transition: 'height 300ms ease-out',
          }}
        />
      </div>
    </Flex>
  );
};
