import React from 'react';

type HoverOverlayProps = {
  backgroundColor?: string;
  styles: Record<string, any>;
};
export const HoverOverlay = (props: HoverOverlayProps) => {
  return (
    <div
      className="hover-overlay"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: props.backgroundColor,
        ...props.styles,
      }}
    />
  );
};
