/** @jsx jsx */

import type { ChangeEvent } from 'react';
import { buildDataAttributes, jsx } from '@balance-web/core';
import { makeId, useId } from '@balance-web/utils';
import { VisuallyHidden } from '@balance-web/a11y';
import { Inline } from '@balance-web/inline';
import type { WithDataAttributeProp } from '@balance-web/core';

import { FilterChipRadio } from './FilterChipRadio';
import type { ChipSizes, ChipVariants } from './types';

export type FilterChipRadioGroupProps<
  Value extends string | number | undefined
> = WithDataAttributeProp<{
  /**  Caption for the radio group for accessibility. Not visible. */
  legend: string;
  /** Function called when the value changes. */
  onChange: (value: Value) => void;
  /** The options for the radios of the radio button group. */
  options: Readonly<{ label: string; value: Value; disabled?: boolean }[]>;
  /** The name of the radio group. */
  name?: string;
  /** The current value of the radio button group. */
  value?: Readonly<Value>;
  variant?: ChipVariants;
  size?: ChipSizes;
}>;

export const FilterChipRadioGroup = <
  Value extends string | number | undefined
>({
  variant = 'filled',
  size = 'medium',
  name: consumerName,
  onChange,
  options,
  value,
  legend,
  data,
  ...props
}: FilterChipRadioGroupProps<Value>) => {
  const instanceId = useId(consumerName);
  const legendId = makeId(instanceId, 'filter-radio-group-legend');

  const dataAttributes = buildDataAttributes(data);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let stringValue = event.target.value;
    let parsedValue = Number(stringValue);
    let resolvedValue = (Number.isNaN(parsedValue)
      ? stringValue
      : parsedValue) as Value;

    onChange(resolvedValue);
  };

  return (
    <Inline gap="small" {...props}>
      <fieldset
        aria-labelledby={legendId}
        style={{ display: 'contents' }}
        {...dataAttributes}
      >
        {options.map((opt) => {
          const checked = opt.value === value;

          return (
            <FilterChipRadio
              key={opt.value}
              variant={variant}
              size={size}
              label={opt.label}
              checked={checked}
              onChange={handleChange}
              name={instanceId}
              disabled={opt.disabled}
              value={opt.value}
            />
          );
        })}
      </fieldset>
      <VisuallyHidden id={legendId}>{legend}</VisuallyHidden>
    </Inline>
  );
};
