/* eslint-disable jsx-a11y/heading-has-content */
/** @jsx jsx */

import { HTMLAttributes } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { Badge } from '@balance-web/badge';
import { Inline } from '@balance-web/inline';
// A chunky page title to give the docs site some flavour

export const DocsTitle = (
  props: HTMLAttributes<HTMLHeadingElement> & {
    isDeprecated?: boolean;
    isDiscouraged?: boolean;
  }
) => {
  const { palette } = useTheme();
  return (
    <Inline gap="large" marginTop="xxlarge" alignY="center">
      <h1
        css={{
          color: palette.text.base,
          fontSize: 48,
          fontWeight: 800,
        }}
        {...props}
      />
      {props.isDeprecated && <Badge label="Deprecated" tone="cautious" />}
      {props.isDiscouraged && <Badge label="Discouraged" tone="neutral" />}
    </Inline>
  );
};
