/** @jsx jsx */

import { ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { BalanceThemeRaw, useRawTheme, useTheme } from '@balance-web/theme';
import { getContrastText } from '@balance-web/utils';

import { useAvatarStackContext } from './context';
import { ShapeType } from './types';

export type SizeType = keyof ReturnType<typeof getSizes>;

type Size = {
  fontSizeDisplay: string;
  fontSizeText: string;
  borderRadius: number;
  boxSize: number;
  gap: number;
  paddingX: number;
  paddingY: number;
  stackMask: number;
};

export type BaseProps = {
  /** Does not support css. */
  css?: never;
  /** The color of the avatar. */
  color: string;
  /** The contents of the avatar or entity */
  children: ReactNode;
  /** When true, the avatar will be a dim neutral color. */
  inactive?: boolean;
  /** When true, the avatar will have a white background.  */
  guest?: boolean;
  /** The shape of the avatar. */
  shape: ShapeType;
  /** The size of the avatar. */
  size: SizeType;
};

export type avatarStylesProps = {
  background: string;
  foreground: string;
  border?: string;
  shape: ShapeType;
  size: Size;
};

export const getBaseStyles = ({
  background,
  foreground,
  border,
  shape,
  size,
}: avatarStylesProps) => {
  const borderRadius = shape === 'round' ? '50%' : size.borderRadius;

  const styles = {
    alignItems: 'center',
    backgroundColor: background,
    boxShadow: border ? `0 0 0 1px ${border}` : undefined,
    borderRadius: borderRadius,
    color: foreground,
    display: 'inline-flex',
    flexShrink: 0,
    fontSize: size.fontSizeDisplay,
    fontWeight: 500,
    height: size.boxSize,
    justifyContent: 'center',
    lineHeight: 1,
    textAlign: 'center',
    width: size.boxSize,
  } as const;

  return styles;
};

export const Base = ({
  children,
  color,
  inactive,
  guest,
  shape,
  size,
  ...props
}: BaseProps) => {
  const theme = useRawTheme();
  const stackContext = useAvatarStackContext();

  const resolvedSize: SizeType = stackContext?.size || size;

  const { background, foreground, border } = useBaseColors({
    background: color,
    inactive,
    guest,
  });

  const sizes = getSizes(theme);

  const avatarStyles = getBaseStyles({
    background,
    foreground,
    border,
    shape,
    size: sizes[resolvedSize],
  });

  return (
    <Box css={avatarStyles} {...props}>
      {children}
    </Box>
  );
};

// Utils
// ------------------------------

const useBaseColors = ({
  background: _background,
  inactive,
  guest,
}: {
  background: string;
  inactive?: boolean;
  guest?: boolean;
}) => {
  const { palette, colors } = useTheme();

  let border = guest ? palette.global.border : palette.background.base;
  let background = _background;
  let foreground = inactive ? palette.text.base : getContrastText(_background);

  if (guest && !inactive) {
    background = colors.background;
  } else if (guest && inactive) {
    background = palette.background.muted;
    foreground = palette.text.dim;
  } else if (inactive && !guest) {
    background = palette.background.dim;
    foreground = palette.text.dim;
  }

  return {
    background,
    foreground,
    border,
  };
};

export const getSizes = (theme: BalanceThemeRaw) => {
  const sizes = {
    xsmall: {
      fontSizeDisplay: theme.typography.fontSize.small, // larger because only a single character is displayed at this size
      fontSizeText: theme.typography.fontSize.xsmall,
      borderRadius: theme.radii.xsmall,
      boxSize: theme.sizing.xsmall, // 24
      gap: theme.spacing.xsmall,
      paddingX: theme.spacing.small,
      paddingY: theme.spacing.xsmall,
      stackMask: 1,
    },
    small: {
      fontSizeDisplay: theme.typography.fontSize.xsmall,
      fontSizeText: theme.typography.fontSize.small,
      borderRadius: theme.radii.small,
      boxSize: theme.sizing.small, // 32
      gap: theme.spacing.small,
      paddingX: theme.spacing.medium,
      paddingY: theme.spacing.xsmall,
      stackMask: 2,
    },
    medium: {
      fontSizeDisplay: theme.typography.fontSize.large,
      fontSizeText: theme.typography.fontSize.medium,
      borderRadius: theme.radii.medium,
      boxSize: theme.sizing.medium, // 48
      gap: theme.spacing.small,
      paddingX: theme.spacing.medium,
      paddingY: theme.spacing.small,
      stackMask: 3,
    },
    large: {
      fontSizeDisplay: theme.typography.fontSize.xlarge,
      fontSizeText: theme.typography.fontSize.large,
      borderRadius: theme.radii.medium,
      boxSize: theme.sizing.large, // 56
      gap: theme.spacing.medium,
      paddingX: theme.spacing.xlarge,
      paddingY: theme.spacing.medium,
      stackMask: 4,
    },
    xlarge: {
      fontSizeDisplay: theme.typography.fontSize.xxlarge,
      fontSizeText: theme.typography.fontSize.xlarge,
      borderRadius: theme.radii.large,
      boxSize: theme.sizing.xlarge, // 72
      gap: theme.spacing.large,
      paddingX: theme.spacing.xxlarge,
      paddingY: theme.spacing.large,
      stackMask: 5,
    },
  } as const;
  return sizes;
};
