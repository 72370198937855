/** @jsx jsx */
import { forwardRef, useCallback, useEffect, useState } from 'react';
import type { DayModifiers } from 'react-day-picker';
import { jsx } from '@balance-web/core';
import formatISO from 'date-fns/formatISO';
import { PopoverDialog, usePopover } from '@balance-web/popover';
import type { InputSizeType } from '@balance-web/text-input';
import { CalendarIcon } from '@balance-web/icon';
import { MenuChip } from '@balance-web/chip';
import { useForkedRef } from '@balance-web/utils';

import type { CalendarProps } from './Calendar';
import { Calendar } from './Calendar';
import type { ISODate } from './types';

export type CalendarPickerProps = {
  /** Label of the trigger button */
  label: string;
  /** When true, the trigger will be disabled and the clear button is removed. */
  disabled?: boolean;
  /** Indicate that the value does not conform to the format expected by the application. */
  invalid?: boolean;
  /** Called when the value changes. */
  onChange: (value: ISODate | undefined) => void;
  /** Called when the value is cleared. */
  onClear: () => void;
  /** The value of the calendar, displayed in the trigger. */
  value: ISODate | undefined;
  size?: InputSizeType;
} & Pick<
  CalendarProps,
  'disabledDays' | 'fromMonth' | 'initialMonth' | 'modifiers' | 'toMonth'
>;

/**
 * We want all the changes in the textInput and calendar to be temporary.
 * The changes are committed to the consumer onChange only when user clicks a calendar date, hits Enter or Tab in the text input.
 * All other interactions (escape, blur etc) are considered a bailout and will cause the text input and calendar to revert to the consumer value.
 */
export const CalendarPicker = forwardRef<HTMLInputElement, CalendarPickerProps>(
  (
    {
      onChange,
      onClear,
      value,
      label,
      // calendar props
      disabledDays,
      fromMonth,
      initialMonth,
      modifiers,
      toMonth,
      size = 'medium',
      disabled,
    },
    ref
  ) => {
    // NOTE: calendarDate doesn't always have to be the same as the incoming value.
    const [calendarDate, setCalendarDate] = useState<Date>(
      value ? new Date(value) : new Date()
    );

    const { isOpen, setOpen, dialog, trigger } = usePopover({
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });

    const composedTextInputRef = useForkedRef(trigger.ref, ref);

    const calendarProps = {
      disabledDays,
      fromMonth,
      initialMonth,
      modifiers,
      toMonth,
    };

    const onDateChange = useCallback(
      (date: Date) => {
        onChange(formatISO(date, { representation: 'date' }) as ISODate);
      },
      [onChange]
    );

    const handleDayClick = useCallback(
      (day: Date, { disabled }: DayModifiers) => {
        if (disabled) {
          return;
        }

        onDateChange(day);

        // wait a moment so the user has time to see the day become selected
        setTimeout(() => {
          setOpen(false);
        }, 300);
      },
      [onDateChange, setOpen]
    );

    const selectedDay = new Date(value as string);

    // We always want to bind isOpen and value in this effect so that we always sync to the correct value on open/close. Separating isOpen out would mean a lot of rework.
    useEffect(
      function syncToValue() {
        if (!isOpen) {
          return;
        }

        if (!value) {
          setCalendarDate(new Date());
        } else {
          const selectedDay = new Date(value as string);
          setCalendarDate(selectedDay);
        }
      },
      [isOpen, value]
    );

    return (
      <div style={{ display: 'inline-flex' }}>
        <MenuChip
          size={size}
          ref={composedTextInputRef}
          label={label}
          hasValue={!!value}
          disabled={disabled}
          iconBefore={CalendarIcon}
          onClear={onClear}
          onClick={() => {
            setOpen((v) => {
              return !v;
            });
          }}
          {...trigger.props}
        />

        <PopoverDialog isVisible={isOpen} ref={dialog.ref} {...dialog.props}>
          <Calendar
            onDayClick={handleDayClick}
            selectedDays={selectedDay}
            month={calendarDate}
            {...calendarProps}
          />
        </PopoverDialog>
      </div>
    );
  }
);
