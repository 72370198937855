import { serializeWizardState } from './serializeWizardState';
import type { WizardState, WizardType } from './types';

export function useResumedWizardState<
  T extends object,
  A extends object | void = void
>(wizardId: string) {
  const storageKey = `${wizardId}_state`;
  const localStorageString = localStorage.getItem(storageKey);

  const resumedState = localStorageString?.length
    ? JSON.parse(localStorageString)
    : (undefined as WizardState<T> | undefined);

  const setStateToResume = (wizard: WizardType<T, A>) => {
    localStorage.setItem(storageKey, serializeWizardState({ wizard }));
  };

  return {
    resumedState,
    setStateToResume,
  };
}
