/** @jsx jsx */

import type {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DropResult,
} from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { jsx } from '@balance-web/core';
import { makeId, useId } from '@balance-web/utils';
import { Toggle } from '@balance-web/toggle';

import { DragHandle, Group, MenuItem } from './styled-components';
import type { ConfigMenuGroupProps } from './types';

export const ConfigMenuGroup = <Value extends string | number>({
  onChange,
  onReorder,
  options,
  title,
  value,
}: ConfigMenuGroupProps<Value>) => {
  const groupId = useId();

  function onDragEnd(result: DropResult) {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // sort the items
    const reorderedOptions = Array.from(options);
    const [removed] = reorderedOptions.splice(result.source.index, 1);
    reorderedOptions.splice(result.destination.index, 0, removed);

    if (onReorder) {
      onReorder(reorderedOptions);
    }
  }

  // help consumers handle multi value change
  function handleChange(selected: Value) {
    if (value.includes(selected)) {
      onChange(
        value.filter((v) => {
          return v !== selected;
        })
      );
    } else {
      onChange(value.concat(selected));
    }
  }

  // temp hack until we upgrade to React v18, which exports `React.useId()`
  // @see https://reactjs.org/docs/hooks-reference.html#useid
  const droppableId = groupId || 'group-id';

  // Fork render to support drag-n-drop
  if (onReorder) {
    return (
      <Group title={title} id={groupId}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId={droppableId}
            renderClone={renderCloneFromProps({ options, value })}
          >
            {(droppableProvided) => {
              return (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {options.map((item, index) => {
                    const checked = value.includes(item.value);
                    const id = item.value.toString();
                    const labelId = makeId(groupId, id);

                    return (
                      <Draggable draggableId={id} key={id} index={index}>
                        {(draggableProvided) => {
                          return (
                            <MenuItem
                              {...draggableProvided.draggableProps}
                              ref={draggableProvided.innerRef}
                              disabled={item.disabled}
                              beforeElement={
                                <DragHandle
                                  aria-label={`${item.label} drag handle`}
                                  disabled={item.disabled}
                                  {...draggableProvided.dragHandleProps}
                                />
                              }
                              afterElement={
                                <Toggle
                                  aria-labelledby={labelId}
                                  checked={checked}
                                  disabled={item.disabled}
                                  onChange={() => {
                                    return handleChange(item.value);
                                  }}
                                  size="small"
                                />
                              }
                            >
                              <span id={labelId}>{item.label}</span>
                            </MenuItem>
                          );
                        }}
                      </Draggable>
                    );
                  })}

                  {droppableProvided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Group>
    );
  }

  // Standard items with toggle only
  return (
    <Group title={title} id={groupId}>
      {options.map((item) => {
        const checked = value.includes(item.value);
        const id = item.value.toString();
        const labelId = makeId(groupId, id);

        return (
          <MenuItem
            key={id}
            disabled={item.disabled}
            afterElement={
              <Toggle
                aria-labelledby={labelId}
                checked={checked}
                disabled={item.disabled}
                onChange={() => {
                  return handleChange(item.value);
                }}
                size="small"
              />
            }
          >
            <span id={labelId}>{item.label}</span>
          </MenuItem>
        );
      })}
    </Group>
  );
};

// Utils
// ------------------------------

type RenderCloneOptions<Value> = Pick<
  ConfigMenuGroupProps<Value>,
  'options' | 'value'
>;

/**
 * Create a "clone" of the menu item for react-beautiful-dnd. Necessary due to
 * the transform/translate positioning of popper.js dialog
 */
function renderCloneFromProps<Value>({
  options,
  value,
}: RenderCloneOptions<Value>) {
  return (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
  ) => {
    const item = options[rubric.source.index];
    const checked = value.includes(item.value);

    return (
      <MenuItem
        {...provided.draggableProps}
        ref={provided.innerRef}
        disabled={item.disabled}
        beforeElement={
          <DragHandle
            aria-label={`${item.label} drag handle`}
            disabled={item.disabled}
            {...provided.dragHandleProps}
          />
        }
        afterElement={
          <Toggle disabled={item.disabled} checked={checked} size="small" />
        }
      >
        {item.label}
      </MenuItem>
    );
  };
}
