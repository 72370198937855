import { makeId, useId } from '@balance-web/utils';

/**
 * Returns getters for applying aria attributes to your tabs and tab panels.
 * @param selectedIndex The index of the currently selected tab.
 */
export const useTabList = (selectedIndex = 0) => {
  const idPrefix = useId();
  const listProps = {
    'aria-multiselectable': 'false' as const,
    role: 'tablist',
  };

  const getTabAttributes = (index: number) => {
    const id = makeId(idPrefix, index);
    const isSelected = selectedIndex === index;

    return {
      'aria-controls': `tab-panel-${id}`,
      'aria-selected': isSelected,
      id: `tab-label-${id}`,
      role: 'tab',
      tabIndex: isSelected ? 0 : -1,
    };
  };

  const getPanelAttributes = (index: number) => {
    const id = makeId(idPrefix, index);
    const isSelected = selectedIndex === index;

    return {
      'aria-hidden': !isSelected,
      'aria-labelledby': `tab-label-${id}`,
      hidden: !isSelected,
      id: `tab-panel-${id}`,
      role: 'tabpanel',
    };
  };

  return {
    listProps,
    getTabAttributes,
    getPanelAttributes,
  };
};
