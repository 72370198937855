/** @jsx jsx */

import { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { buildDataAttributes } from '@balance-web/core';
import { WithDataAttributeProp } from '@balance-web/core';

export type StrongProps = WithDataAttributeProp<{
  children: ReactNode;
  id?: string;
}>;

export const Strong = ({ children, id, data }: StrongProps) => {
  const { typography } = useTheme();

  const dataAttributes = buildDataAttributes(data);

  return (
    <strong
      css={{ fontWeight: typography.fontWeight.bold }}
      id={id}
      {...dataAttributes}
    >
      {children}
    </strong>
  );
};
