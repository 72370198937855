import { createContext, useContext } from 'react';

const RadioGroupContext = createContext(false);

export const RadioGroupContextProvider = RadioGroupContext.Provider;

export const useRadioGroupContext = () => {
  return useContext(RadioGroupContext);
};

const TestIdContext = createContext<string | undefined>(undefined);

export const TestIdContextProvider = TestIdContext.Provider;

export const useTestId = () => {
  return useContext(TestIdContext);
};

export type DropdownContextType = {
  itemHeight: number;
};

export const DropdownContext = createContext<DropdownContextType | null>(null);

export const useDropdownContext = () => {
  const context = useContext(DropdownContext);

  if (!context) {
    throw Error('useDropdownContext can only be used inside dropdown-v2');
  }

  return context;
};
