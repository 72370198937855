/** @jsx jsx */

import { VisuallyHidden } from '@balance-web/a11y';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';

export type WizardProgressItemProps = {
  number: number;
  title: string;
  subtitle?: string;
  action?: {
    label: string;
    action: () => void;
    disabled?: boolean;
  };
  active: boolean;
  complete: boolean;
};

export const WizardProgressItem = (props: WizardProgressItemProps) => {
  const { number, title, subtitle, action, active, complete } = props;
  const { palette, sizing } = useTheme();

  return (
    <Flex
      alignItems="center"
      gap="small"
      flex={1}
      minHeight={sizing.small}
      minWidth={0}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        height={24}
        width={24}
        borderRadius="large"
        flexShrink={0}
        css={{
          background: active ? palette.text.active : palette.text.dim,
        }}
      >
        <Text
          size="xsmall"
          weight="semibold"
          css={{
            color: '#fff',
          }}
        >
          {number}
        </Text>
      </Flex>
      <Stack flex={1} minWidth={0}>
        <Text weight="semibold" size="xsmall" overflowStrategy="nowrap">
          {title}
        </Text>
        {!active && (
          <VisuallyHidden>
            {complete ? 'Complete' : 'Incomplete'}
          </VisuallyHidden>
        )}
        <Flex gap="small" flex={1} minWidth={0}>
          {subtitle && (
            <Text
              color="dim"
              size="xsmall"
              overflowStrategy="truncate"
              // ensure that the user can see the subtitle when it's truncated
              title={subtitle}
            >
              {subtitle}
            </Text>
          )}
          {action && (
            <Text
              as="button"
              type="button"
              onClick={action.disabled ? undefined : action.action}
              aria-disabled={action.disabled}
              size="xsmall"
              color={action.disabled ? 'muted' : 'active'}
              cursor="pointer"
              userSelect="none"
            >
              {action.label}
            </Text>
          )}
        </Flex>
      </Stack>
    </Flex>
  );
};
