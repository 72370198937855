import { createContext, useContext } from 'react';

import { useComboboxState } from './useComboboxState';

type ComboboxContextType = ReturnType<typeof useComboboxState>;

export const ComboboxContext = createContext<ComboboxContextType>({
  disabled: false,
  isFocused: false,
  isOpen: false,
  itemHeight: 38,
  size: 'medium',
});

export const useComboboxContext = () => useContext(ComboboxContext);
