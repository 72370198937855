import React from 'react';
import { Text } from '@balance-web/text';

import { useProductMenuBase } from './context';

export const Title = ({ children }: { children: string }) => {
  const { headingId } = useProductMenuBase();

  return (
    <Text id={headingId} weight="semibold">
      {children}
    </Text>
  );
};
