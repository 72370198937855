import React, { Fragment, ReactNode, ReactText } from 'react';
import { Text, TextProps } from '@balance-web/text';

import { useTableCellContext } from '../cellContext';

type CellContentProps = TextProps & { children: ReactNode };
export function CellContent({ children, ...textProps }: CellContentProps) {
  const { overflowStrategy } = useTableCellContext();

  if (children == null) {
    return null;
  }

  if (isText(children)) {
    return (
      <Text overflowStrategy={overflowStrategy} tabularNumbers {...textProps}>
        {children}
      </Text>
    );
  }

  return <Fragment>{children}</Fragment>;
}

function isText(value: ReactNode): value is ReactText {
  return typeof value === 'string' || typeof value === 'number';
}
