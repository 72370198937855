import { createContext, useContext } from 'react';

export type ProductMenuBaseContextType = {
  headingId?: string;
};

export const ProductMenuBaseContext = createContext<ProductMenuBaseContextType | null>(
  null
);

export const useProductMenuBase = () => {
  const context = useContext(ProductMenuBaseContext);

  if (!context)
    throw Error('useProductMenuBase must be used inside ProductMenu');

  return context;
};
