import React from 'react';
import { Root } from '@radix-ui/react-collapsible';
import type { ReactNode } from 'react';

type CollapsableRootProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: ReactNode;
};

export const CollapsableRoot = ({
  isOpen,
  setIsOpen,
  children,
}: CollapsableRootProps) => {
  return (
    <Root open={isOpen} onOpenChange={setIsOpen}>
      {children}
    </Root>
  );
};
