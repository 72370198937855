/** @jsx jsx */

import FocusLock from 'react-focus-lock';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { PopoverDialog, usePopover } from '@balance-web/popover';
import { useId } from '@balance-web/utils';
import { MenuChip } from '@balance-web/chip';

import { Menu } from './styled-components';
import { MENU_ALIGNMENT } from './constants';
import type { ConfigMenuProps } from './types';

export const ConfigMenu = ({
  align = 'left',
  children,
  trigger,
  hasValue,
  selectionCount,
  ...props
}: ConfigMenuProps) => {
  // setup internal
  const menuId = useId();

  const { isOpen, setOpen, dialog, trigger: popoverTrigger } = usePopover({
    placement: MENU_ALIGNMENT[align],
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  // trigger UI
  const triggerProps = {
    ref: popoverTrigger.ref,
    onClick: () => {
      return setOpen((s) => {
        return !s;
      });
    },
    ...popoverTrigger.props,
    'aria-controls': menuId,
  } as const;
  const triggerElement =
    typeof trigger === 'function' ? (
      trigger({ isOpen, triggerProps })
    ) : (
      <MenuChip
        label={trigger}
        hasValue={hasValue === true}
        selectionCount={selectionCount}
        size="small"
        {...triggerProps}
      />
    );

  // We need a reference to the menu element to prepare keyboard navigation, but
  // we don't want the menu items to be focusable until the menu is open
  const menuContent = isOpen ? (
    <FocusLock autoFocus returnFocus lockProps={{ role: 'presentation' }}>
      {children}
    </FocusLock>
  ) : null;

  return (
    <Box {...props}>
      {triggerElement}
      <PopoverDialog ref={dialog.ref} isVisible={isOpen} {...dialog.props}>
        <Menu id={menuId}>{menuContent}</Menu>
      </PopoverDialog>
    </Box>
  );
};
