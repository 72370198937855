import { createContext, useContext } from 'react';

import {
  BottomDrawerContextType,
  BottomDrawerControllerContextType,
} from './types';

export const BottomDrawerContext = createContext<BottomDrawerContextType | null>(
  null
);

export const BottomDrawerContextProvider = BottomDrawerContext.Provider;

export const useBottomDrawer = () => {
  const context = useContext(BottomDrawerContext);

  if (!context) throw Error('useBottomDrawer must be used inside BottomDrawer');

  return context;
};

const BottomDrawerControllerContext = createContext<null | BottomDrawerControllerContextType>(
  {
    onClose: () => {},
    transitionState: 'exited',
  }
);

export const BottomDrawerControllerContextProvider =
  BottomDrawerControllerContext.Provider;

export const useBottomDrawerControllerContext = () => {
  let context = useContext(BottomDrawerControllerContext);
  if (!context) {
    throw new Error(
      'Bottom drawer must be wrapped in a <BottomDrawerController>. You should generally do this outside of the component that renders the <BottomBottomDrawer>. See https://reckon-web-design-system.netlify.app/package/drawer#drawercontroller for how to use it and why.'
    );
  }

  return context;
};
