/**
 * Generates request headers for api requests based on available credentials
 */
export function createJwtTokenAuthenticationRequestHeaders(
  accessToken?: string
): Record<string, string> {
  if (!accessToken) {
    return {};
  }

  return {
    authorization: `Bearer ${accessToken}`,
  };
}

export function createMfaAuthenticationRequestHeaders(
  mfaToken?: string
): Record<string, string> {
  if (!mfaToken) {
    return {};
  }

  return { 'x-api-mfaToken': mfaToken };
}
