import type { ComponentType } from 'react';
import React, { useLayoutEffect, useRef } from 'react';

import { buildDataAttributes } from '../common';
import type { AppLeftNavigatorItem } from '../types';
import { TRANSITION_CURVE } from '../constants';

import { ChildNavigationItem } from './ChildNavigationItem';

type PopoverMenuProps = {
  item: AppLeftNavigatorItem;
  index: number;
  activeRouteId?: string;
  linkComponent?: ComponentType<any>;
};

export const PopoverMenu = (props: PopoverMenuProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const dataAttributes = buildDataAttributes({
    popoverMenuOf: props.item.routeId,
  });

  useLayoutEffect(() => {
    // This might need a bit more refinement
    const setPopoverMenuAbsolutePositions = () => {
      if (!props.item.items?.length || !containerRef.current) {
        return;
      }

      const rootItemHeight = 45;
      const topOffset = rootItemHeight * -1 + props.index * rootItemHeight;

      containerRef.current.style.top = `${topOffset}px`;
      // Fixed distance from the left edge of the screen, doesn't change
      containerRef.current.style.left = '52px';
    };

    setPopoverMenuAbsolutePositions();
  }, [props.index, props.item]);

  if (!props.item.items?.length) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className="root-item-popover-menu"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        transition: `opacity 500ms ${TRANSITION_CURVE}`,
        paddingLeft: 18,
        paddingTop: 10,
      }}
      {...dataAttributes}
    >
      <div
        style={{
          width: 300,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          borderRadius: 12,
          background: '#FFFFFF',
          gap: 8,
          paddingBottom: 8,
          border: '1px solid #DDDEE9',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
        }}
      >
        <div style={{ paddingInline: 16 }}>
          <div
            style={{
              paddingLeft: 16,
              paddingBottom: 16,
              paddingTop: 24,
            }}
          >
            <h4
              style={{
                textTransform: 'none',
                color: '#0a004a',
                fontWeight: 700,
                lineHeight: 1.4,
                fontSize: '1.25rem',
              }}
            >
              {props.item.label}
            </h4>
          </div>
          <div
            role="separator"
            style={{
              height: 1,
              minWidth: '100%',
              backgroundColor: '#e4e5e6',
            }}
          />
        </div>
        <div
          style={{
            paddingInline: 8,
          }}
        >
          {props.item.items?.map((item) => {
            return (
              <div
                style={{
                  paddingLeft: item.items?.length ? 8 : 16,
                }}
                key={item.routeId}
              >
                <ChildNavigationItem
                  item={item}
                  defaultExpanded={true}
                  activeRouteId={props.activeRouteId}
                  linkComponent={props.linkComponent}
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* Left triangle thing */}
      <div
        style={{
          width: 15,
          height: 15,
          background: '#fff',
          position: 'absolute',
          top: 82,
          left: 11,
          borderLeft: `1px solid ${'#DDDEE9'}`,
          borderBottom: `1px solid ${'#DDDEE9'}`,
          transform: 'rotate(45deg)',
          zIndex: 1,
        }}
      />
    </div>
  );
};
