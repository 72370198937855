import * as React from 'react';
import type { ReactNode } from 'react';

import { useAuth } from './useAuth';

export type AuthProviderIsLoadingProps = React.PropsWithChildren<{
  /** Optional custom UI to show when we are loading */
  loader?: ReactNode;
}>;

export const AuthProviderIsLoading = ({
  loader,
  children,
}: AuthProviderIsLoadingProps) => {
  const { isLoading } = useAuth();

  if (!!loader && isLoading) return <>{loader}</>;

  return <>{!isLoading && children}</>;
};
