import React, { ReactNode } from 'react';
import { Flex } from '@balance-web/flex';

export type BookSwitcherPageProps = {
  children: ReactNode;
};

export const BookSwitcherPage = ({ children }: BookSwitcherPageProps) => {
  return (
    <Flex
      padding="xlarge"
      background="muted"
      alignItems="center"
      direction="column"
      flexGrow={1}
    >
      {children}
    </Flex>
  );
};
