import React, { MutableRefObject, forwardRef, useEffect } from 'react';
import type { ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { RadioPrimitive, useRadioGroupItem } from '@balance-web/radio';
import { Inline } from '@balance-web/inline';
import { Flex } from '@balance-web/flex';

import { ListItemLayout } from './ListItemLayout';
import { useListGroupContext } from './radioListContext';

export type RadioItemProps = {
  children: ReactNode;
  value?: string;
  name?: string;
  disabled?: boolean;
  onSelectFocusRef?: MutableRefObject<HTMLInputElement | null>;
  startElement?: ReactNode;
};

export const RadioItem = forwardRef<HTMLInputElement, RadioItemProps>(
  ({ children, startElement, onSelectFocusRef, ...radioProps }, ref) => {
    const radioListContext = useListGroupContext();
    const inputProps = useRadioGroupItem({ ...radioProps }, radioListContext);

    useEffect(() => {
      if (
        onSelectFocusRef?.current &&
        radioListContext.value === radioProps.value
      ) {
        onSelectFocusRef.current.focus();
      }
    }, [onSelectFocusRef, radioListContext.value, radioProps.value]);

    return (
      <label>
        <ListItemLayout listType="radio" selected={inputProps.checked}>
          <Inline flex="1" alignY="center">
            {startElement}
            <Box paddingX="large">{children}</Box>
            <Flex flex="1" justifyContent="end">
              <RadioPrimitive ref={ref} {...inputProps} />
            </Flex>
          </Inline>
        </ListItemLayout>
      </label>
    );
  }
);
