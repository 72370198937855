import * as React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

type MockedGqlApiClientProviderProps = {
  children: React.ReactNode;
};

export const MockedGqlApiClientProvider = ({
  children,
}: MockedGqlApiClientProviderProps) => {
  const mockedClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      new HttpLink({
        uri: '//fake.gql.server',
      }),
    ]),
    // Needed otherwise MSW fails to return results.
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });
  return <ApolloProvider client={mockedClient}>{children}</ApolloProvider>;
};
