/** @jsx jsx */
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { DropdownMenuProps } from '@radix-ui/react-dropdown-menu';
import { getTestIdFromDataAttributes, jsx } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { flattenElements } from '@balance-web/utils';

import { TestIdContextProvider, useTestId } from './context';
import { SubMenuContent, SubMenuTrigger } from './radix-styled-components';

type SubMenuProps = WithDataAttributeProp<DropdownMenuProps>;

export const SubMenu = ({ children, data, ...props }: SubMenuProps) => {
  const childArray = flattenElements(children);

  const parentTestId = useTestId();

  let subMenuTestId = getTestIdFromDataAttributes(data);
  const prefixedsubMenuTestId =
    parentTestId && subMenuTestId
      ? `${parentTestId}-${subMenuTestId}`
      : subMenuTestId;

  /**
   * We render like this to reduce the number of primitives consumers have to deal with.
   * If, at some point doing this is costing us flexibility we might have to let consumers opt into
   * the SubMenuContent primitive.
   */
  const trigger = childArray.find((child) => child.type === SubMenuTrigger);

  const nonTriggers = childArray.filter(
    (child) => child.type !== SubMenuTrigger
  );

  return (
    <TestIdContextProvider value={prefixedsubMenuTestId}>
      <DropdownMenuPrimitive.Sub {...props}>
        {trigger}
        <SubMenuContent data={{ ...data, testid: prefixedsubMenuTestId }}>
          {nonTriggers}
        </SubMenuContent>
      </DropdownMenuPrimitive.Sub>
    </TestIdContextProvider>
  );
};
