import React, { ReactNode } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { Text } from '@balance-web/text';
import { Box } from '@balance-web/box';
export type LabelProps = {
  children: ReactNode;
};

export const MenuTitle = ({ children }: LabelProps) => {
  return (
    <DropdownMenuPrimitive.Label>
      <Box paddingX="small" paddingTop="small" paddingBottom="medium">
        <Text size="xsmall" weight="medium">
          {children}
        </Text>
      </Box>
    </DropdownMenuPrimitive.Label>
  );
};
