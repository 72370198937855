/** @jsx jsx */

import hashSum from 'hash-sum';
import type { HTMLAttributes, ReactElement, ReactNode } from 'react';
import { Fragment } from 'react';
import { jsx } from '@balance-web/core';
import { ScrollMask } from '@balance-web/scroll-mask';
import { makeId, useId } from '@balance-web/utils';

import type { GapType, SeparationType } from './styled';
import {
  EmptyState,
  TableBody,
  TableCell,
  TableColGroup,
  TableElement,
  TableFoot,
  TableHead,
  TableHeadColumn,
  TableRow,
} from './styled';

export type TableDataRow = { [key: string]: any };

export type SortType = {
  asc: boolean;
  key: string;
};

export type Column = {
  align?: 'left' | 'center' | 'right';
  allowSort?: boolean;
  label: string | ((sort?: SortType) => ReactNode);
  render: (index: number) => ReactNode;
  width?: number | string;
};

type DataTableProps = {
  /** Allow the table to overflow the container and be scrollable. */
  allowOverflow?: boolean;
  /** Useful for applying styles, custom `data-attributes`, etc. */
  cellAttributes?: (options: {
    key: string;
    colIndex: number;
    rowIndex: number;
  }) => React.HTMLAttributes<HTMLTableCellElement>;
  /** Define the header labels and data keys. */
  columns: { [key: string]: Column };
  /** What to render when there's no data */
  emptyState?: ReactElement;
  /** The spacing around each cell's content */
  gap?: GapType;
  /** When true, the table will not render head cells */
  headless?: boolean;
  /** Optionally render a table footer for each column */
  renderFooter?: (key: string) => ReactNode;
  /** Data to populate the body cells. */
  rowCount: number;
  /** The appearance of row separation */
  separation?: SeparationType;
  /** Orderable options */
  sort?: SortType;
  /** Callback function for when the sort order has changed  */
  onSortChange?: (sort: SortType | undefined) => void;
} & HTMLAttributes<HTMLDivElement>;

/** @deprecated `DataTable` has been deprecated. Use [Table](https://balance.reckon.com/package/table) instead. */
export const DataTable = ({
  allowOverflow = false,
  cellAttributes = () => ({}),
  columns,
  emptyState,
  gap = 'medium',
  headless = false,
  id,
  onSortChange,
  renderFooter,
  rowCount,
  separation = 'stripes',
  sort,
  ...divProps
}: DataTableProps) => {
  const tableId = useId(id);
  const Wrapper = allowOverflow ? ScrollableTable : TableElement;
  const isEmpty = rowCount === 0;

  return (
    <div id={tableId} {...divProps}>
      <Wrapper>
        <TableColGroup cols={columns} />

        {!headless && (
          <TableHead css={{ padding: 0 }}>
            <TableRow>
              {Object.keys(columns).map((key: string) => (
                <TableHeadColumn
                  columnKey={key}
                  columnMeta={columns[key]}
                  gap={gap}
                  key={key}
                  onSortChange={onSortChange}
                  sort={sort}
                  id={makeId(tableId, key, 'header-cell')}
                />
              ))}
            </TableRow>
          </TableHead>
        )}

        {!isEmpty && (
          <Fragment>
            <TableBody>
              {Array.from({ length: rowCount }).map((_, rowIndex) => {
                // we need a unique key for each row that reacts to sort changes;
                // this is especially handy for columns that render non-unique
                // elements, like <input /> where the `value` changes, but React
                // doesn't know to re-render
                const rowKey = hashSum(sort) + rowIndex;

                return (
                  <TableRow key={rowKey}>
                    {Object.keys(columns).map((key, colIndex) => (
                      <TableCell
                        key={key}
                        gap={gap}
                        id={makeId(tableId, key, 'body-cell', rowIndex)}
                        align={columns[key].align}
                        separation={separation}
                        {...cellAttributes({ key, colIndex, rowIndex })}
                      >
                        {columns[key].render(rowIndex)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>

            {renderFooter && (
              <TableFoot>
                <TableRow>
                  {Object.keys(columns).map((key: string) => (
                    <TableCell
                      id={makeId(tableId, key, 'footer-cell')}
                      isFoot
                      key={key}
                      gap={gap}
                      align={columns[key].align}
                    >
                      {renderFooter(key)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFoot>
            )}
          </Fragment>
        )}
      </Wrapper>
      {isEmpty && emptyState && <EmptyState>{emptyState}</EmptyState>}
    </div>
  );
};

// Helpers
// ------------------------------

type ScrollableTableProps = HTMLAttributes<HTMLTableElement>;
const ScrollableTable = (props: ScrollableTableProps) => (
  <ScrollMask>
    <TableElement {...props} />
  </ScrollMask>
);
