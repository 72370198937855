import { createContext, useContext } from 'react';

import { CellAlignY, CellDensity, CellOverflowStrategy } from './types';

export type TableCellContextType = {
  /**
   * Sets the vertical alignment for content within each cell.
   * @default 'center'
   */
  alignY?: CellAlignY;
  /**
   * Sets the amount of vertical padding within each cell.
   * @default 'regular'
   */
  density?: CellDensity;
  /**
   * Sets the overflow behavior for the text content of each cell.
   * @default 'truncate'
   */
  overflowStrategy?: CellOverflowStrategy;
};

export const TableCellContext = createContext<TableCellContextType>({
  alignY: 'center',
  density: 'regular',
  overflowStrategy: 'truncate',
});

export function useTableCellContext() {
  const ctx = useContext(TableCellContext);

  if (!ctx) {
    throw new Error(
      'Table cells must be rendered within a `Table` ancestor to access context.'
    );
  }

  return useContext(TableCellContext);
}
