/** @jsx jsx */

import type { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';

/*
  TabList
*/

type ListProps = {
  /** The tab items. */
  children: ReactNode;
};

export const TabList = forwardRefWithAs<'div', ListProps>(
  ({ as: Tag = 'div', ...props }, ref) => {
    return <Tag ref={ref} css={{ display: 'flex' }} {...props} />;
  }
);

TabList.displayName = 'TabList';

/*
  TabItem
*/

type ItemProps = {
  /** The label, and supporting content, of the tab. */
  children: ReactNode;
  /** When true, the tab will have a selected appearance. */
  isSelected?: boolean;
};

export const TabItem = forwardRefWithAs<'button', ItemProps>(
  ({ as: Tag = 'button', isSelected = false, children, ...props }, ref) => {
    const { palette, spacing, typography } = useTheme();

    return (
      <Tag
        ref={ref}
        type={Tag === 'button' ? 'button' : undefined}
        css={{
          alignItems: 'center',
          background: 0,
          border: 0,
          cursor: 'pointer',
          color: isSelected ? palette.text.base : palette.text.dim,
          display: 'flex',
          gap: spacing.medium,
          flexShrink: 0,
          fontFamily: typography.fontFamily.body,
          fontSize: typography.fontSize.medium,
          fontWeight: 600,
          lineHeight: typography.leading.base,
          marginRight: spacing.medium,
          paddingBottom: spacing.large,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: spacing.large,
          position: 'relative',
          textDecoration: 'none',

          ':hover, :focus': {
            color: isSelected ? palette.text.base : palette.text.muted,
          },

          // selected indicator
          ':after': isSelected
            ? {
                background: palette.global.accent,
                borderRadius: 4,
                bottom: 0,
                content: '" "',
                height: 4,
                left: 0,
                position: 'absolute',
                width: '100%',
              }
            : {},
        }}
        {...props}
      >
        {children}
      </Tag>
    );
  }
);

TabItem.displayName = 'TabItem';
