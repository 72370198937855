/** @jsx jsx */
import type { HTMLAttributes } from 'react';
import React, { forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { Box } from '@balance-web/box';
import { useTheme } from '@balance-web/theme';
import { useFieldContext } from '@balance-web/field';

type RadioButtonGroupWrapperProps = {
  optionCount: number;
} & HTMLAttributes<HTMLDivElement>;
export const Wrapper = forwardRef<HTMLDivElement, RadioButtonGroupWrapperProps>(
  ({ optionCount, ...props }, ref) => {
    const { colors, typography, radii } = useTheme();

    const { invalid } = useFieldContext();

    return (
      <Box
        ref={ref}
        css={{
          backgroundClip: 'padding-box',
          borderRadius: radii.small,
          boxShadow: `0 0 0 1px ${colors[invalid ? 'danger' : 'accent']}`,
          boxSizing: 'border-box',
          cursor: 'pointer',
          display: 'grid',
          gridTemplateColumns: `repeat(${optionCount - 1}, 1fr 1px) 1fr`,
          fontSize: typography.fontSize.small,
          fontWeight: typography.fontWeight.medium,
          // Fixed height as per designs.
          height: 38,
          outline: 0,
          position: 'relative',
        }}
        {...props}
      />
    );
  }
);
Wrapper.displayName = 'RadioButtonGroupWrapper';
