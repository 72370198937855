import { Field, Form } from '@magical-forms/react-next';

export function countVisibleValidationErrors(form?: Form<Field>) {
  if (!form) return 0;
  if (form._field.kind === 'object') {
    let num = 0;
    Object.keys((form as any).fields).forEach((key) => {
      num += countVisibleValidationErrors((form as any).fields[key]);
    });
    return num;
  }
  if (form._field.kind === 'array') {
    let num = 0;
    (form as any).elements.forEach((element: any) => {
      num += countVisibleValidationErrors(element);
    });
    return num;
  }
  return form.validity === 'invalid' && (form.state as any).touched ? 1 : 0;
}
