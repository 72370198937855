/** @jsx jsx */

import { jsx } from '@balance-web/core';
import { GrabIcon } from '@balance-web/icon';

import { TableCellProps, useCellStyles } from './TableCell';

export type TableDragCellProps = Pick<
  TableCellProps,
  'aria-colindex' | 'id' | 'stickyOffset'
>;

export function TableDragCell({ id, ...props }: TableDragCellProps) {
  const { dataAttributes, css, style } = useCellStyles(props);
  const {
    'aria-colindex': ariaColIndex,
    stickyOffset: UNUSED_stickyOffset,
    ...draggableProps
  } = props;

  return (
    <div
      id={id}
      role="gridcell"
      css={css}
      style={style}
      aria-colindex={ariaColIndex}
      // NOTE: special data-attribute avoids confusing consumer props on `TableCell`
      data-interaction="drag"
      {...dataAttributes}
    >
      <div
        css={{
          ':before': {
            content: '""',
            position: 'absolute',
            inset: 0,
          },
        }}
        {...draggableProps}
      >
        <GrabIcon size="small" color="base" />
      </div>
    </div>
  );
}
