/** @jsx jsx */

import { MutableRefObject, ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { Elevate } from '@balance-web/elevate';
import { useId } from '@balance-web/utils';

import { ProductMenuBase } from './ProductMenuBase';
import { useProductMenuControllerContext } from './ProductMenuController';
import { Title } from './Title';
import { Header } from './Header';
import { Body } from './Body';

const easing = 'cubic-bezier(0.2, 0, 0, 1)';

export type ProductMenuProps = {
  children: ReactNode;
  id?: string;
  'aria-label'?: string;
  'aria-labelledby'?: string;
  initialFocusRef?: MutableRefObject<any>;
  background?: 'base' | 'muted';
};

export const ProductMenu = ({
  id,
  children,
  initialFocusRef,
  ...props
}: ProductMenuProps) => {
  const { transitionState } = useProductMenuControllerContext();

  const theme = useTheme();
  const instanceId = useId(id);

  return (
    <ProductMenuBase
      id={instanceId}
      transitionState={transitionState}
      initialFocusRef={initialFocusRef}
      {...props}
    >
      {({ Tag, css, ...props }) => (
        <Tag
          {...props}
          style={dialogTransition[transitionState]}
          css={{
            ...css,
            boxShadow: theme.shadow.large,
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            transition: `transform 150ms ${easing}`,
            width: 320,
            zIndex: theme.elevation.modal,

            // flex layout must be applied here so content will grow/shrink properly
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Elevate>{children}</Elevate>
        </Tag>
      )}
    </ProductMenuBase>
  );
};

const dialogTransition = {
  entering: { transform: 'translateX(-100%)' },
  entered: { transform: 'translateX(0)' },
  exiting: { transform: 'translateX(-100%)' },
  exited: { transform: 'translateX(-100%)' },
};

// Primitives
ProductMenu.Header = Header;
ProductMenu.Title = Title;
ProductMenu.Body = Body;
