import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

type PageLayoutContextType = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export const PageLayoutContext = createContext<PageLayoutContextType | null>(
  null
);

export const usePageLayout = () => {
  const context = useContext(PageLayoutContext);

  if (!context) {
    throw Error('usePageLayout can only be used inside PageLayout.');
  }

  return context;
};
