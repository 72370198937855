/** @jsx jsx */

import type { ReactNode } from 'react';
import { Fragment, useState } from 'react';
import type {
  UseComboboxPropGetters,
  UseComboboxState,
  UseComboboxStateChange,
  UseComboboxStateChangeOptions,
} from 'downshift';
import { useCombobox } from 'downshift';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { PopoverDialog } from '@balance-web/popover';

import type { AnchorElementType, SelectMenuProps } from './SelectMenu';
import { useSelectPopper } from './SelectMenu';
import {
  ComboBox,
  EmptyState,
  LoadingIndicator,
  Menu,
  MenuItem,
  SearchInput,
  SelectMenuButton,
} from './styled-components';

export const stateChangeTypes = useCombobox.stateChangeTypes;

// Main export
// ------------------------------

export type FilterMenuProps<Option> = {
  /** The value of the input. */
  inputValue?: string;
  /** Called whenever the input value changes. Use to filter the options. */
  onInputChange: (
    inputValue: string | undefined,
    restChanges: Omit<UseComboboxStateChange<Option>, 'inputValue'>
  ) => void;
  /** The input's placeholder text. */
  placeholder?: string;
  /** Use the `isLoading` property when fetching options asynchronously. */
  isLoading?: boolean;
  /** Called whenever the dropdown opens or closes. */
  onIsOpenChange?: (changes: UseComboboxStateChange<Option>) => void;
  /** Called whenever internal state changes. Use for fine-grained control of component internals. */
  stateReducer?: (
    state: UseComboboxState<Option>,
    actionAndChanges: UseComboboxStateChangeOptions<Option>
  ) => Partial<UseComboboxState<any>>;
} & SelectMenuProps<Option>;

export function FilterMenu<Option>({
  align = 'left',
  itemRenderer: consumerRenderer,
  itemToDisabled = (item) => {
    // @ts-ignore
    return Boolean(item.disabled);
  },
  itemToLabel = (item) => {
    // @ts-ignore
    return item.label;
  },
  itemToValue = (item) => {
    // @ts-ignore
    return item.value;
  },
  isLoading,
  onChange,
  onClear,
  inputValue,
  onInputChange,
  onIsOpenChange,
  stateReducer: consumerStateReducer,
  options,
  placeholder = 'Filter...',
  trigger,
  value,
  // @ts-ignore
  ...props
}: FilterMenuProps<Option>) {
  // create the defaults (can't happen in destructuring because we need supporting props)
  const itemRenderer =
    consumerRenderer ||
    ((item: Option) => {
      return itemToLabel(item);
    });
  const stateReducer =
    consumerStateReducer ||
    ((
      state: UseComboboxState<Option>,
      actionAndChanges: UseComboboxStateChangeOptions<Option>
    ) => {
      const { changes, type } = actionAndChanges;

      if (Array.isArray(value)) {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep the menu open after selection
              highlightedIndex: state.highlightedIndex, // maintain the currently highlighted item
            };
        }
      }

      return changes;
    });

  // handle downshift type requirement here rather than passing on to consumers
  const itemToString = (item: Option | null) => {
    return item ? itemToLabel(item) : '';
  };

  // whether there's input determines what we render in the menu
  const [hasInput, setHasInput] = useState(false);

  // setup downshift (selection/navigation handling)
  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    isOpen,
  } = useCombobox({
    items: options,
    itemToString,
    inputValue,
    onInputValueChange: ({ inputValue, ...restChanges }) => {
      setHasInput(Boolean(inputValue));
      onInputChange(inputValue, restChanges);
    },
    onIsOpenChange,
    selectedItem: null, // we don't want the input to be populated with the selected value
    stateReducer,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onChange(selectedItem);
      }
    },
  });

  // setup popper (position handling)
  const {
    setAnchorElement,
    setPopoverElement,
    styles,
    attributes,
  } = useSelectPopper({ align, isOpen });

  // setup trigger (the button that invokes the menu)
  const hasValue = Boolean(
    Array.isArray(value) ? value?.length : value !== undefined
  );
  const triggerProps = getToggleButtonProps({
    ref: (element: AnchorElementType | null) => {
      return setAnchorElement(element);
    },
    // @ts-ignore data-expanded is acceptable internal API
    // the `aria-expanded` attribute is actually applied to the element with `role="combobox"` (the input wrapper)
    // https://www.w3.org/TR/wai-aria-practices-1.1/examples/combobox/aria1.1pattern/listbox-combo.html
    'data-expanded': isOpen,
    /**
     * This is a workaround for a bug in downshift that has been logged. Revise after guidance from downshift.
     * https://github.com/downshift-js/downshift/issues/1400
     */
    tabIndex: undefined,
  });

  const triggerElement =
    typeof trigger === 'function' ? (
      trigger({ hasValue, isOpen, triggerProps })
    ) : (
      <SelectMenuButton
        hasValue={hasValue}
        onClear={onClear}
        {...triggerProps}
        label={trigger}
      />
    );

  // prepare common map for default items and regular items
  const itemMap = makeItemMap({
    getItemProps,
    itemRenderer,
    itemToDisabled,
    itemToValue,
    value,
  });

  // prepare menu content for each state
  // use Fragment to reduce return types
  const menuUI = (() => {
    // we need a reference to the menu element to prepare accessibility
    // handlers, but we don't want the menu content to be focusable until the
    // menu is open
    if (!isOpen) {
      return null;
    }

    // replace the menu items with a loading indicator
    // NOTE: this might be too disruptive, consider moving to the input area
    if (isLoading) {
      return <LoadingIndicator />;
    }

    // no matches
    if (hasInput && !options.length) {
      return <EmptyState>No matching results</EmptyState>;
    }

    // show options when available
    if (options.length) {
      return <Fragment>{options.map(itemMap)}</Fragment>;
    }

    // unused or unaccounted state, render nothing
    return null;
  })();

  // we need a reference to the input to prepare accessibility handlers, but
  // don't want it to be focusable until the menu is open
  const inputConfig = { placeholder, disabled: !isOpen };
  const showDivider = Boolean(menuUI);

  return (
    <Box {...props} css={{ display: 'inline-block' }}>
      {triggerElement}
      <PopoverDialog
        ref={setPopoverElement}
        isVisible={isOpen}
        style={{ ...styles.popper, overflow: 'hidden' }} // crop highlighted options so they don't spill over the rounded corners
        {...attributes.popper}
      >
        <ComboBox showDivider={showDivider} {...getComboboxProps()}>
          <SearchInput {...getInputProps(inputConfig)} />
        </ComboBox>
        <Menu {...getMenuProps()}>{menuUI}</Menu>
      </PopoverDialog>
    </Box>
  );
}

// Utils
// ------------------------------

type MakeItemMapProps<Option> = {
  getItemProps: UseComboboxPropGetters<Option>['getItemProps'];
  itemRenderer: (option: Option) => ReactNode;
  itemToDisabled: (option: Option) => boolean;
  itemToValue: (option: Option) => string | number;
  value: Option | Array<Option> | undefined;
};

function makeItemMap<Option>({
  getItemProps,
  itemRenderer,
  itemToDisabled,
  itemToValue,
  value,
}: MakeItemMapProps<Option>) {
  return function itemMap(item: Option, index: number) {
    const disabled = itemToDisabled(item);
    const itemValue = itemToValue(item);
    const isSelected = Array.isArray(value)
      ? value.map(itemToValue).includes(itemValue)
      : value && itemToValue(value) === itemValue;

    return (
      <MenuItem
        key={itemValue}
        isSelected={isSelected}
        {...getItemProps({
          disabled, // NOTE: this **should** be working, but isn't... (https://github.com/downshift-js/downshift/issues/728#issuecomment-569912286)
          item,
          index,
        })}
      >
        {itemRenderer(item)}
      </MenuItem>
    );
  };
}
