/** @jsx jsx */

import { ComponentType, HTMLAttributes, ReactNode, forwardRef } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { IconProps } from '@balance-web/icon';
import { Flex } from '@balance-web/flex';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';
import { useId } from '@balance-web/utils';

// 1. Avoid focused items intersecting with the menu's border-radius
// 2. Provide consistient spacing between interior elements
const VERTICAL_RHYTHM = 'medium';

// Menu Provider
// ------------------------------

type MenuProps = HTMLAttributes<HTMLDivElement>;

export const Menu = forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
  const theme = useTheme();

  return (
    <div
      role="menu"
      ref={ref}
      css={{
        maxHeight: 320,
        minWidth: 220,
        maxWidth: 360,
        overflowY: 'auto',
        paddingBottom: theme.spacing.small,
        paddingTop: theme.spacing.small,
        WebkitOverflowScrolling: 'touch',
      }}
      {...props}
    />
  );
});

// Menu Groups & Divider
// ------------------------------

type GroupProps = {
  children: ReactNode;
  title?: string;
};
export const MenuGroup = ({ children, title, ...props }: GroupProps) => {
  const { spacing } = useTheme();
  const titleId = useId();

  return (
    <div
      aria-describedby={titleId}
      role="group"
      css={{
        '&:not(:first-of-type)': {
          marginTop: spacing[VERTICAL_RHYTHM],
        },
      }}
      {...props}
    >
      {title && (
        <Box paddingX="large" paddingBottom="xsmall">
          <Text
            as="h6"
            color="dim"
            size="xsmall"
            weight="medium"
            transform="uppercase"
            id={titleId}
          >
            {title}
          </Text>
        </Box>
      )}
      {children}
    </div>
  );
};

export const MenuDivider = () => {
  const { palette, spacing } = useTheme();

  return (
    <div
      role="separator"
      css={{
        backgroundColor: palette.global.border,
        height: 1,
        marginBottom: spacing[VERTICAL_RHYTHM],
        marginTop: spacing[VERTICAL_RHYTHM],
      }}
    />
  );
};

// Menu Item
// ------------------------------

type MenuItemProps = {
  /** Determines if the menu item trigger is disabled */
  disabled?: boolean;
  /** An optional icon placed to the left of the label */
  icon?: ComponentType<IconProps>;
  /** Describe the action of the item with the label */
  label: string;
  /** An event handler for when the item is clicked */
  onClick?: (event: MouseEvent) => void;
  /** The tone of the action this item represents */
  tone?: 'active' | 'passive' | 'critical';
} & HTMLAttributes<HTMLButtonElement>;

export const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  (props, ref) => {
    const { label, icon: Icon, tone = 'passive', ...consumerProps } = props;
    const { palette, sizing } = useTheme();
    const height = sizing.base; // FIXME: should be resolved by the `Box` component
    const highlightedStyles = {
      background: palette.menuItem.backgroundFocused,
      outline: 'none !important',
    };

    const toneMap = {
      active: 'active',
      passive: 'base',
      critical: 'critical',
    } as const;
    const colorKey = toneMap[tone];

    // NOTE: must be a button element so click events extend to "Enter/Space" keypress
    return (
      <Flex
        as="button"
        ref={ref}
        role="menuitem"
        tabIndex={0}
        type="button"
        // flex
        alignItems="center"
        gap="small"
        // style
        cursor="pointer"
        height={height}
        paddingX="large"
        width="100%"
        css={{
          ':hover,:focus': highlightedStyles,
          ':active': {
            backgroundColor: palette.menuItem.backgroundPressed,
          },
          ':disabled': {
            pointerEvents: 'none',
          },
        }}
        {...consumerProps}
      >
        {Icon && (
          <Icon color={props.disabled ? 'dim' : colorKey} size="small" />
        )}
        <Box flex={1} minWidth={0} maxWidth="100%">
          <Text
            align="left"
            color={props.disabled ? 'dim' : colorKey}
            leading="tight" // TODO: remove (after capsize implementation)
            size="small"
            weight="medium"
            overflowStrategy="truncate"
          >
            {label}
          </Text>
        </Box>
      </Flex>
    );
  }
);
