/** @jsx jsx */

import type { HTMLAttributes } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import type { DayModifiers } from 'react-day-picker';
import {
  addDays,
  formatISO,
  isAfter,
  isBefore,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isValid,
  subDays,
} from 'date-fns';
import type { CSSObject } from '@balance-web/core';
import { jsx } from '@balance-web/core';
import { formatDateObj } from '@balance-web/date-input';
import { PopoverDialog, usePopover } from '@balance-web/popover';
import type { InputSizeType } from '@balance-web/text-input';
import { Adornment } from '@balance-web/text-input';
import { useTheme } from '@balance-web/theme';
import { Box } from '@balance-web/box';
import { Flex } from '@balance-web/flex';
import { Text } from '@balance-web/text';
import { CalendarIcon } from '@balance-web/icon';
import { useFieldContext } from '@balance-web/field';

import type { CalendarProps } from './Calendar';
import { Calendar } from './Calendar';
import { ClearButton, InputButton } from './styled';
import type { ISODate, ISODateRange } from './types';
import { dateToString, stringToDate } from './utils';

type InternalDateState = {
  start?: Date;
  end?: Date;
  textStart: string;
  textEnd: string;
};

type SelectedInput = 'start' | 'end' | 'none';

export type DateRangePickerProps = {
  /** When true, the triggers will be disabled. */
  disabled?: boolean;
  /** Indicate that the value does not conform to the format expected by the application. */
  invalid?: boolean;
  /** Called when the value changes. */
  onChange: (value: ISODateRange | undefined) => void;
  /** Called when the value is cleared. */
  onClear?: () => void;
  /** The value of the calendar, displayed in the trigger. */
  value: ISODateRange | undefined;
  size?: InputSizeType;
} & Pick<
  CalendarProps,
  'disabledDays' | 'fromMonth' | 'initialMonth' | 'modifiers' | 'toMonth'
>;

export const DateRangePicker = ({
  disabled = false,
  invalid: invalidProp = false,
  onChange,
  value,
  // calendar props
  disabledDays,
  fromMonth,
  initialMonth,
  modifiers: consumerModifiers,
  toMonth,
  size = 'medium',
  onClear,
  ...inputProps
}: DateRangePickerProps) => {
  const theme = useTheme();

  const fieldContext = useFieldContext();

  const isInvalid = invalidProp || fieldContext.invalid;

  const startTextInputRef = useRef<HTMLInputElement>(null);
  const endTextInputRef = useRef<HTMLInputElement>(null);

  const [selectedInput, setSelectedInput] = useState<SelectedInput>('none');

  /**
   * We need dervied state to support temporary valid/invalid text entry
   * and date selection within calendar.
   *
   * Don't use effects to sync internal/external state. Use events.
   */
  const [internalDateState, _setInternalDateState] = useState<
    InternalDateState
  >({
    start: value?.start ? new Date(value?.start) : undefined,
    textStart: value?.start ? dateToString(value?.start) : '',
    end: value?.end ? new Date(value?.end) : undefined,
    textEnd: value?.end ? dateToString(value?.end) : '',
  });

  /**
   * Function that accepts partial internal state inputs and appends them to current internal state.
   * Don't use _setInternalDate directly, it will cause confusion.
   * */
  function setInternalDate(
    update:
      | ((
          prevInternalState: InternalDateState
        ) => {
          start?: Date | ISODate | null;
          end?: Date | ISODate | null;
          textStart?: string;
          textEnd?: string;
        })
      | {
          start?: Date | ISODate | null;
          end?: Date | ISODate | null;
          textStart?: string;
          textEnd?: string;
        }
      | null
  ) {
    _setInternalDateState((prevState) => {
      if (update === null) {
        return {
          start: undefined,
          end: undefined,
          textStart: '',
          textEnd: '',
        };
      }

      const stateUpdate =
        typeof update === 'function' ? update(prevState) : update;

      const newState = {
        ...prevState,
      };

      if (stateUpdate.start === null) {
        newState.start = undefined;
      } else if (stateUpdate?.start) {
        newState.start = new Date(stateUpdate.start);
      }

      if (stateUpdate.end === null) {
        newState.end = undefined;
      } else if (stateUpdate?.end) {
        newState.end = new Date(stateUpdate.end);
      }

      if (stateUpdate.textStart !== undefined) {
        newState.textStart = stateUpdate.textStart;
      }

      if (stateUpdate.textEnd !== undefined) {
        newState.textEnd = stateUpdate.textEnd;
      }

      return newState;
    });
  }

  // sync state with the popover dialog and the "focused input"
  const { isOpen, setOpen, dialog, trigger } = usePopover({
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const textInputStyles = useMemo(() => {
    const styles: CSSObject = {
      border: 'none',
      outline: 'none',
      fontSize:
        size === 'small'
          ? theme.typography.fontSize.small
          : theme.typography.fontSize.medium,
      background: 'none',
      width: 85,
    };

    return styles;
  }, [size, theme.typography.fontSize.medium, theme.typography.fontSize.small]);

  const handleOpen = (selectedInput?: SelectedInput) => {
    return () => {
      const _selectedInput: SelectedInput =
        selectedInput ||
        (value?.start && value?.end
          ? 'start'
          : !value?.start?.length
          ? 'start'
          : 'end');
      setSelectedInput(_selectedInput);
      setOpen(true);

      if (!isOpen) {
        if (_selectedInput === 'start' || _selectedInput === 'none') {
          startTextInputRef.current?.select();
        } else if (_selectedInput === 'end') {
          endTextInputRef.current?.select();
        }
      }
    };
  };

  const onDateRangeChange = useCallback(
    (args: { startDate?: Date; endDate?: Date }) => {
      onChange({
        start: args.startDate
          ? (formatISO(args.startDate, { representation: 'date' }) as ISODate)
          : internalDateState.start
          ? (formatISO(internalDateState.start, {
              representation: 'date',
            }) as ISODate)
          : undefined,
        end: args.endDate
          ? (formatISO(args.endDate, { representation: 'date' }) as ISODate)
          : internalDateState.end
          ? (formatISO(internalDateState.end, {
              representation: 'date',
            }) as ISODate)
          : undefined,
      });
    },
    [internalDateState.end, internalDateState.start, onChange]
  );

  /** ============= Text input things start */

  // Don't update state inside this function. It's supposed to be a state processor.
  const calculateNextState = (args: {
    textValue: string;
    calendarDate?: Date;
  }) => {
    const stateUpdate: {
      date?: Date;
      text: string;
    } = {
      text: args.textValue,
    };

    const date = stringToDate(args.textValue);

    if (isValid(date) && date.getFullYear() > 999) {
      stateUpdate.date = date;
    }

    return stateUpdate;
  };

  const isStartDateValid = (startDate: Date, endDate?: Date) => {
    if (!isValid(startDate) || startDate.getFullYear() < 1000) {
      return false;
    }

    if (!endDate) {
      return true;
    }

    return (
      new Date(startDate).setHours(0, 0, 0, 0) <
      new Date(endDate).setHours(0, 0, 0, 0)
    );
  };

  const isEndDateValid = (endDate: Date, startDate?: Date) => {
    if (!isValid(endDate) || endDate.getFullYear() < 1000) {
      return false;
    }

    if (!startDate) {
      return true;
    }

    return (
      new Date(endDate).setHours(0, 0, 0, 0) >
      new Date(startDate).setHours(0, 0, 0, 0)
    );
  };

  /**
   * Emit change event if start date is valid.
   * If it's invalid, fall back to last valid end date or no date if no last valid end date found.
   */
  const commitValidStartTextValue = () => {
    const textValueAsDate = stringToDate(internalDateState.textStart);
    if (isStartDateValid(textValueAsDate, internalDateState.end)) {
      // Set text value again as formatted value
      setInternalDate({
        textStart: dateToString(textValueAsDate),
        start: textValueAsDate,
      });
      onDateRangeChange({
        startDate: textValueAsDate,
      });
      return true;
    } else {
      setInternalDate((prevDate) => {
        onDateRangeChange({
          startDate: prevDate.start,
        });

        return {
          start: prevDate.start,
          textStart: prevDate.start ? dateToString(prevDate.start) : '',
        };
      });
      return false;
    }
  };

  /**
   * Emit change event if end date is valid.
   * If it's invalid, fall back to last valid end date or no date if no last valid end date found.
   */
  const commitValidEndTextValue = () => {
    const textValueAsDate = stringToDate(internalDateState.textEnd);
    if (isEndDateValid(textValueAsDate, internalDateState.start)) {
      // Set text value again as formatted value
      setInternalDate({
        textEnd: dateToString(textValueAsDate),
        end: textValueAsDate,
      });
      onDateRangeChange({
        endDate: textValueAsDate,
      });
      return true;
    } else {
      setInternalDate((prevDate) => {
        onDateRangeChange({
          endDate: prevDate.end,
        });

        return {
          end: prevDate.end,
          textEnd: prevDate.end ? dateToString(prevDate.end) : '',
        };
      });
      return false;
    }
  };

  /** Text input things end =============  */

  // We always want to bind isOpen and value in this effect so that we always sync to the correct value on open/close. Separating isOpen out would mean a lot of rework.
  useEffect(
    function syncToValue() {
      if (!isOpen) {
        setSelectedInput('none');
      }

      setInternalDate(
        value
          ? {
              ...value,
              textStart: value.start ? dateToString(value.start) : '',
              textEnd: value.end ? dateToString(value.end) : '',
            }
          : null
      );
    },
    [isOpen, value]
  );

  const handleCalendarDayClick = useCallback(
    (day: Date, { disabled }: DayModifiers) => {
      if (disabled) {
        return;
      }

      setInternalDate((prevInternalState) => {
        let resolvedDate: {
          start?: Date | null;
          end?: Date | null;
        };

        if (selectedInput === 'start') {
          resolvedDate = {
            start: day,
            end:
              prevInternalState.end && day > prevInternalState.end
                ? null
                : prevInternalState.end,
          };

          // we're done with the start date, move "focus" to the end date
          setSelectedInput('end');
          endTextInputRef.current?.select();
        } else {
          // NOTE: invert behaviour (day is outside the valid range):
          // selected end day is before the start date
          if (
            !prevInternalState.start ||
            (prevInternalState.start && day < prevInternalState.start)
          ) {
            resolvedDate = {
              start: day,
              end: undefined,
            };

            // selectedInput === "end" behaviour
          } else {
            resolvedDate = {
              start: prevInternalState.start,
              end: day,
            };
            onDateRangeChange({
              startDate: resolvedDate.start || undefined,
              endDate: resolvedDate.end || undefined,
            });
            setTimeout(() => {
              setOpen(false);
            }, 300);
          }
        }

        return {
          start: resolvedDate.start,
          textStart: resolvedDate.start ? dateToString(resolvedDate.start) : '',
          end: resolvedDate.end,
          textEnd: resolvedDate.end ? dateToString(resolvedDate.end) : '',
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedInput, setOpen]
  );

  // without an end date we only want the calendar to know about the start date
  // so the appropriate modifiers are applied
  const selectedDays = !internalDateState.end
    ? internalDateState.start
    : {
        from: internalDateState.start as Date,
        to: internalDateState.end as Date,
      };

  // stringify the internal date object for button labels
  const formattedStart = internalDateState.start
    ? formatDateObj(internalDateState.start)
    : undefined;

  // modifiers allow us to style the calendar for range input
  const modifiers = {
    ...consumerModifiers,
    lastOfMonth: (day: Date) => {
      return isLastDayOfMonth(day);
    },
    firstOfMonth: (day: Date) => {
      return isFirstDayOfMonth(day);
    },
    rangeStart: (day: Date) => {
      return Boolean(
        internalDateState.start && isSameDay(day, internalDateState.start)
      );
    },
    rangeEnd: (day: Date) => {
      return Boolean(
        internalDateState.end && isSameDay(day, internalDateState.end)
      );
    },
    rangeBetween: (day: Date) => {
      if (!internalDateState.start || !internalDateState.end) {
        return false;
      }
      if (
        isSameDay(day, internalDateState.start) ||
        isSameDay(day, internalDateState.end)
      ) {
        return false;
      }

      return (
        isAfter(day, internalDateState.start) &&
        isBefore(day, internalDateState.end)
      );
    },
  };
  const calendarProps = {
    disabledDays,
    fromMonth,
    initialMonth,
    modifiers,
    toMonth,
  };

  return (
    <Box position="relative">
      <ButtonWrapper ref={trigger.ref}>
        <InputButton
          disabled={disabled}
          invalid={isInvalid}
          aria-label={ariaLabel('Choose start date', formattedStart)}
          onClick={handleOpen()}
          isSelected={isOpen}
          size={size}
          {...inputProps}
          {...trigger.props}
        >
          <Flex flex="1" alignItems="center">
            <input
              type="text"
              css={textInputStyles}
              ref={startTextInputRef}
              value={internalDateState.textStart}
              onFocus={() => {
                setOpen(true);
              }}
              onChange={(e) => {
                const update = calculateNextState({
                  textValue: e.target.value,
                  calendarDate: internalDateState.start,
                });

                // Bail out of updating the start calendar date if start date text is after end date
                if (
                  update.date &&
                  internalDateState.end &&
                  update.date > internalDateState.end
                ) {
                  update.date = undefined;
                }
                setInternalDate({
                  start: update.date,
                  textStart: update.text,
                });
              }}
              placeholder="dd/mm/yyyy"
              onBlur={commitValidStartTextValue}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setOpen(false);
                  return;
                }

                if (e.key === 'Enter' || e.key === 'Tab') {
                  e.preventDefault();
                  if (commitValidStartTextValue()) {
                    endTextInputRef.current?.select();
                    setSelectedInput('end');
                  }
                  return;
                }

                // Shortcut to select todays date
                if (e.key === 't') {
                  e.preventDefault();
                  const now = new Date();

                  if (!isStartDateValid(now, internalDateState.end)) {
                    return;
                  }

                  setInternalDate({
                    start: now,
                    textStart: dateToString(now),
                  });
                  onDateRangeChange({
                    startDate: new Date(),
                  });
                }

                if (e.key === 'ArrowUp') {
                  e.preventDefault();

                  const date = stringToDate(internalDateState.textStart);

                  if (!isStartDateValid(date, internalDateState.end)) {
                    return;
                  }

                  const datePlus1Day = addDays(date, 1);

                  setInternalDate({
                    start: datePlus1Day,
                    textStart: dateToString(datePlus1Day),
                  });
                  onDateRangeChange({
                    startDate: datePlus1Day,
                  });
                }

                if (e.key === 'ArrowDown') {
                  e.preventDefault();

                  const date = stringToDate(internalDateState.textStart);

                  if (!isValid(date)) {
                    return;
                  }

                  const dateMinus1Day = subDays(date, 1);

                  setInternalDate({
                    start: dateMinus1Day,
                    textStart: dateToString(dateMinus1Day),
                  });
                  onDateRangeChange({
                    startDate: dateMinus1Day,
                  });
                }
              }}
            />
            <span
              css={{
                paddingInline: theme.spacing.small,
              }}
            >
              <Text size="small">to</Text>
            </span>
            <input
              css={textInputStyles}
              type="text"
              ref={endTextInputRef}
              value={internalDateState.textEnd}
              onFocus={() => {
                setOpen(true);
              }}
              onChange={(e) => {
                const update = calculateNextState({
                  textValue: e.target.value,
                  calendarDate: internalDateState.end,
                });

                // Bail out of updating the end calendar date if end date text is before end date
                if (
                  update.date &&
                  internalDateState.start &&
                  update.date < internalDateState.start
                ) {
                  update.date = undefined;
                }

                setInternalDate({ end: update.date, textEnd: update.text });
              }}
              placeholder="dd/mm/yyyy"
              onBlur={commitValidEndTextValue}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setOpen(false);
                  return;
                }

                if (e.key === 'Enter' || e.key === 'Tab') {
                  setOpen(false);
                  if (commitValidEndTextValue()) {
                    endTextInputRef.current?.blur();
                  }
                  return;
                }

                // Shortcut to select todays date
                if (e.key === 't') {
                  e.preventDefault();

                  const now = new Date();

                  if (!isEndDateValid(now, internalDateState.start)) {
                    return;
                  }

                  setInternalDate({
                    end: now,
                    textEnd: dateToString(now),
                  });
                  onDateRangeChange({
                    endDate: new Date(),
                  });
                }

                if (e.key === 'ArrowUp') {
                  e.preventDefault();

                  const date = stringToDate(internalDateState.textEnd);

                  if (!isValid(date)) {
                    return;
                  }

                  const datePlus1Day = addDays(date, 1);

                  setInternalDate({
                    end: datePlus1Day,
                    textEnd: dateToString(datePlus1Day),
                  });
                  onDateRangeChange({
                    endDate: datePlus1Day,
                  });
                }

                if (e.key === 'ArrowDown') {
                  e.preventDefault();

                  const date = stringToDate(internalDateState.textEnd);

                  if (!isEndDateValid(date, internalDateState.start)) {
                    return;
                  }

                  const dateMinus1Day = subDays(date, 1);

                  setInternalDate({
                    end: dateMinus1Day,
                    textEnd: dateToString(dateMinus1Day),
                  });
                  onDateRangeChange({
                    endDate: dateMinus1Day,
                  });
                }
              }}
            />
          </Flex>
        </InputButton>
      </ButtonWrapper>

      {onClear &&
      !disabled &&
      (value?.start?.length ||
        value?.end?.length ||
        internalDateState.start ||
        internalDateState.textStart.length ||
        internalDateState.end ||
        internalDateState.textEnd.length) ? (
        <ClearButton
          size={size}
          onClick={() => {
            setInternalDate(null);
            onClear();
            handleOpen('start')();
          }}
        />
      ) : null}

      <Adornment
        align="right"
        css={{
          paddingRight:
            size === 'medium' ? theme.spacing.small : theme.spacing.xsmall,
          pointerEvents: 'none',
          position: 'absolute',
          zIndex: 3,
        }}
      >
        <CalendarIcon
          size={size}
          color={disabled ? 'inputDisabled' : isInvalid ? 'critical' : 'dim'}
        />
      </Adornment>

      <PopoverDialog
        isVisible={isOpen}
        ref={dialog.ref}
        key="range-dialog"
        {...dialog.props}
      >
        <Calendar
          onDayClick={handleCalendarDayClick}
          selectedDays={selectedDays}
          month={internalDateState.start || internalDateState.end}
          numberOfMonths={2}
          enableOutsideDaysClick
          {...calendarProps}
        />
      </PopoverDialog>
    </Box>
  );
};

// Utils
// ------------------------------

function ariaLabel(prefix: string, day?: string) {
  return prefix + (day ? `, selected date is ${day}` : '');
}

// Styled Components
// ------------------------------

const ButtonWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return (
    <div
      ref={ref}
      css={{
        display: 'flex',
      }}
      {...props}
    />
  );
});
