import type { UseRequestProps } from './useRequest';
import type { RequestResult } from './types';
import { useBlobRequest } from './useBlobRequest';
import { isErrorResponse } from './isErrorResponse';
import { downloadBlob } from './downloadBlob';

export function useDownloadBlob({
  mimeType,
  url,
  headers,
}: UseRequestProps & { mimeType: string }) {
  const { request, ...output } = useBlobRequest({ url, headers });

  const handleRequest = async (
    props: Parameters<typeof request>[0]
  ): Promise<RequestResult<{ file: Blob; fileUrl: string }>> => {
    const response = await request(props);
    if (!isErrorResponse(response)) {
      return downloadBlob({ blob: response, mimeType });
    }
    return response;
  };

  return {
    ...output,
    request: handleRequest,
  };
}
