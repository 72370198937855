import React from 'react';
import { useMemo } from 'react';
import Head from 'next/head';

export type BasePageTitleMetaProps = {
  title?: string;
  appName: string;
};

export function BasePageTitleMeta({ title, appName }: BasePageTitleMetaProps) {
  const pageTitle = useMemo(() => {
    return [title, appName].filter(Boolean).join(' - ');
  }, [title, appName]);
  return (
    <Head>
      <title>{pageTitle}</title>
    </Head>
  );
}
