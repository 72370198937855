import React, { useMemo } from 'react';
import { Root } from '@radix-ui/react-accordion';
import type {
  AccordionMultipleProps,
  AccordionSingleProps,
} from '@radix-ui/react-accordion';
import { Stack } from '@balance-web/stack';

export type AccordionRootProps =
  | Pick<
      AccordionSingleProps,
      'type' | 'children' | 'defaultValue' | 'value' | 'onValueChange'
    >
  | Pick<
      AccordionMultipleProps,
      'type' | 'children' | 'defaultValue' | 'value' | 'onValueChange'
    >;

export const AccordioRoot = ({ children, ...props }: AccordionRootProps) => {
  /** All this ceremony is to handle the type:single | multiple discriminated union. */
  const singleProps: AccordionSingleProps | undefined = useMemo(() => {
    if (props.type === 'single') {
      return { ...props, collapsible: true };
    }
  }, [props]);

  const multipleProps: AccordionMultipleProps | undefined = useMemo(() => {
    if (props.type === 'multiple') {
      return { ...props };
    }
  }, [props]);

  return (
    <Root type={props.type} {...(singleProps || multipleProps)} asChild>
      <Stack dividers>{children}</Stack>
    </Root>
  );
};
