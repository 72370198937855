import { createContext, useContext } from 'react';
import { useRadioGroupState } from '@balance-web/radio';

export type RadioListContextType = ReturnType<typeof useRadioGroupState>;
export const RadioListContext = createContext<RadioListContextType | null>(
  null
);
export const RadioListContextProvider = RadioListContext.Provider;

export const useListGroupContext = () => {
  const context = useContext(RadioListContext);
  if (!context) throw Error('RadioList.Item can only be used inside RadioList');
  return context;
};
