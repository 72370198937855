/** @jsx jsx */

import React from 'react';
import { forwardRef } from 'react';
import { PropsWithChildren } from 'react';
import { jsx } from '@balance-web/core';
import { Box } from '@balance-web/box';
import type { BoxProps } from '@balance-web/box';
import { hexToRgb } from '@balance-web/utils';
import { useTheme } from '@balance-web/theme';
import { useMediaQuery } from '@balance-web/media-query';
type VideoBoxprops = PropsWithChildren<BoxProps>;

export const VideoBlock = forwardRef<HTMLDivElement, VideoBoxprops>(
  ({ borderRadius, children, background, ...props }, boxRef) => {
    const { radii } = useTheme();
    const { mq, mapResponsiveProp } = useMediaQuery();
    const maskTone = hexToRgb('#000000', 0.85);
    const resolvedBorderRadius = borderRadius
      ? mapResponsiveProp(borderRadius, radii)
      : 0;

    return (
      <Box
        ref={boxRef}
        background={background || 'muted'}
        borderRadius={borderRadius}
        maxWidth={props.maxWidth || 720}
        width={props.width || '100%'}
        height={props.height || 400}
        {...props}
        css={mq({
          overflow: 'hidden',
          '.react-player__preview': {
            borderRadius: resolvedBorderRadius,
            outline: `2px solid ${maskTone}`,
          },
        })}
      >
        {children}
      </Box>
    );
  }
);
