import * as React from 'react';

/**
 * @deprecated Use `isUnsupportedBrowser` instead.
 * [Read More](../README.md)
 */
export function useEnsureSupportedBrowser({
  errorPagePath = '/unsupported-browser',
  gotoErrorPage,
  currentPath,
}: {
  errorPagePath?: string;
  currentPath: string;
  gotoErrorPage: (path: string) => void;
}) {
  /**
   * This useEffect will ensure this test only runs when in an actual browser
   */
  React.useEffect(() => {
    if (currentPath.includes(errorPagePath)) {
      return;
    }

    const userAgent = window.navigator.userAgent;
    /**
     * Internet Explorer Edge
     */
    const isEdge = userAgent.indexOf('Edge') !== -1;

    /**
     * Internet Explorer <= 11
     */
    const isIE = userAgent.indexOf('Trident') !== -1 && !isEdge;

    /**
     * Build this up with more potential truthy cases, leave the last one as `false`
     */
    const isUnsupported = !!isIE || false;

    if (isUnsupported) {
      gotoErrorPage(errorPagePath);
    }
  }, [currentPath, errorPagePath, gotoErrorPage]);
}
