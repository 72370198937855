import * as React from 'react';

import { OpenCollegesMetaContext } from './OpenCollegesMetaContext';

export function useOpenCollegesMeta() {
  const context = React.useContext(OpenCollegesMetaContext);
  if (!context) {
    throw new Error(
      'useOpenCollegesMeta can only be used within <OpenCollegesMetaProvider />'
    );
  }
  return context;
}
