import { ChangeEvent } from 'react';
import { useId } from '@balance-web/utils';

import { RadioGroupProps, RadioProps } from './types';

type StateHookProps<T extends string> = Omit<RadioGroupProps<T>, 'children'>;

export function useRadioGroupState<T extends string>(props: StateHookProps<T>) {
  const name = useId(props.name);

  const state = {
    name,
    disabled: props.disabled,
    size: props.size,
    value: props.value,
    onChange(event: ChangeEvent<HTMLInputElement>) {
      if (props.disabled) {
        return;
      }

      const inputValue = event.target.value as T;

      props.onChange(inputValue);
    },
  };

  return state;
}

type RadioGroupState = ReturnType<typeof useRadioGroupState>;
type ItemHookProps = Omit<RadioProps, 'children'>;

export function useRadioGroupItem(
  props: ItemHookProps,
  state: RadioGroupState
) {
  const radioValue = props.value;

  if (radioValue == null) {
    throw new Error(
      'Each <Radio> within a <RadioGroup> requires a `value` property.'
    );
  }

  const augmentedProps = {
    checked: state.value === radioValue,
    disabled: state.disabled,
    name: state.name,
    onChange: state.onChange,
    size: state.size,
    value: radioValue,
  };

  return augmentedProps;
}
