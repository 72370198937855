import React from 'react';
import { Button } from '@balance-web/button';
import { PlusIcon } from '@balance-web/icon';

export const ArrayFieldAddButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick: () => void;
}) => {
  return (
    <div>
      <Button
        label={label}
        colorScheme="secondary"
        size="small"
        iconBefore={PlusIcon}
        onClick={onClick}
      />
    </div>
  );
};
