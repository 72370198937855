import type { PropsWithChildren } from 'react';
import React from 'react';
import { LoadingDots } from '@balance-web/loading';
import type { FlexProps } from '@balance-web/flex';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
type LoadingDotsProps = Parameters<typeof LoadingDots>[0];
export type LoaderProps = PropsWithChildren<LoadingDotsProps> & FlexProps;

export function Loader({
  label,
  color,
  size,
  gap,
  children,
  ...props
}: LoaderProps) {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      {...props}
    >
      <Stack gap={gap} align="center">
        {children}
        <LoadingDots label={label} color={color} size={size} />
      </Stack>
    </Flex>
  );
}
