/** @jsx jsx */
import type { ReactNode } from 'react';
import React, { memo } from 'react';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import { IconButton } from '@balance-web/button';
import { ChevronLeftIcon, ChevronRightIcon } from '@balance-web/icon';
import { Box } from '@balance-web/box';
import { useRawTheme, useTheme } from '@balance-web/theme';
import { makeId, useId } from '@balance-web/utils';

import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from './constants';
import { usePageLayout } from './context';

export type PageSidebarProps = {
  children: ReactNode;
};

const PageSidebarUnmemoised = ({ children }: PageSidebarProps) => {
  const { palette, spacing, borderWidth } = useTheme();
  const rawTheme = useRawTheme();

  const { isSidebarOpen, setIsSidebarOpen } = usePageLayout();

  const instanceId = useId();
  const pageSidebarId = makeId(instanceId, 'balance-page-sidebar');

  const toggleSidebar = () => {
    setIsSidebarOpen((isOpen) => {
      return !isOpen;
    });
  };

  const iconButtonSizeKey = 'small';
  const rightBorderWidth = borderWidth.standard;
  const iconOffset = {
    top: spacing.xxlarge,
    right: -(rawTheme.sizing[iconButtonSizeKey] / 2),
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        transition: 'width 300ms cubic-bezier(0.2, 0, 0, 1) 0s',
        position: 'relative',
        boxShadow: `${rightBorderWidth} 0px 0px 0px ${palette.global.border}`,
      }}
      style={{
        width: isSidebarOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH,
      }}
    >
      <Box position="absolute" {...iconOffset}>
        <IconButton
          aria-expanded={isSidebarOpen}
          aria-controls={pageSidebarId}
          icon={isSidebarOpen ? ChevronLeftIcon : ChevronRightIcon}
          label="Expand sidebar navigation"
          size={iconButtonSizeKey}
          onClick={toggleSidebar}
        />
      </Box>
      <Flex as="section" id={pageSidebarId} flex={1} overflowY="auto">
        <Flex flex={1} direction="column" minWidth={SIDEBAR_OPEN_WIDTH}>
          {children}
        </Flex>
      </Flex>
    </div>
  );
};

export const PageSidebar = memo(PageSidebarUnmemoised);
