import { SortType, TableDataRow } from './DataTable';

/** Prefer `Intl.Collator` over `localeCompare` for improved performance. */
const collator = new Intl.Collator(undefined, { sensitivity: 'base' });

export const sortTableData = (data: TableDataRow[], sort: SortType) => {
  return [...data].sort((a, b) => {
    const valueA = a[sort.key];
    const valueB = b[sort.key];
    const modifier = sort.asc ? 1 : -1;

    // NOTE: always push `null` and `undefined` to the bottom
    if (valueA == null) return 1;
    if (valueB == null) return -1;

    // NOTE: the collator is only appropriate for strings, it fails in subtle
    // ways for floats, dates, etc.
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return collator.compare(valueA, valueB) * modifier;
    }

    return compare(valueA, valueB) * modifier;
  });
};

function compare(a: any, b: any) {
  if (a < b) return -1;
  if (a > b) return 1;

  return 0;
}
