import type { CSSObject } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { useMemo } from 'react';

import type { ChipSizes, ChipVariants } from './types';

type Props = {
  variant: ChipVariants;
  size: ChipSizes;
  active?: boolean;
  disabled?: boolean;
};

export const buttonResetStyles: CSSObject = {
  appearance: 'none',
  border: 0,
  outline: 0,
  boxSizing: 'border-box',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
};

export const useChipVariantStyles = ({
  variant,
  size,
  active,
  disabled,
}: Props) => {
  const theme = useTheme();

  const variantStyles = useMemo((): CSSObject => {
    if (variant === 'filled') {
      return {
        fontSize: theme.typography.fontSize[size],
        backgroundColor: active
          ? theme.components.chip.filled.active.surfaceColor
          : theme.components.chip.filled.surfaceColor,
        color: active
          ? theme.components.chip.filled.active.textColor
          : theme.components.chip.filled.textColor,
        height: theme.components.chip[size].height,
        boxShadow: `inset 0 0 0 1px ${
          disabled
            ? theme.components.chip.disabled.surfaceColor
            : active
            ? theme.components.chip.filled.active.borderColor
            : theme.components.chip.filled.borderColor
        }`,
        paddingInline: theme.components.chip[size].paddingInline,
        '&[aria-disabled=true]': {
          cursor: 'default',
          color: theme.components.chip.disabled.textColor,
          backgroundColor: theme.components.chip.disabled.surfaceColor,
        },
        '&:not([aria-disabled=true])': {
          ':hover, &[aria-expanded=true]': {
            ' .interactionOverlay': {
              backgroundColor: active
                ? theme.components.chip.filled.active.interactions.hover
                    .surfaceColor
                : theme.components.chip.filled.interactions.hover.surfaceColor,
            },
          },
          ':active': {
            ' .interactionOverlay': {
              backgroundColor: active
                ? theme.components.chip.filled.active.interactions.pressed
                    .surfaceColor
                : theme.components.chip.filled.interactions.pressed
                    .surfaceColor,
            },
          },
        },
      };
    }

    return {
      fontSize: theme.typography.fontSize[size],
      backgroundColor: disabled
        ? undefined
        : active
        ? theme.components.chip.outline.active.surfaceColor
        : theme.components.chip.outline.surfaceColor,
      color: active
        ? theme.components.chip.outline.active.textColor
        : theme.components.chip.outline.textColor,
      height: theme.components.chip[size].height,
      boxShadow: `inset 0 0 0 1px ${
        disabled
          ? theme.components.chip.disabled.surfaceColor
          : active
          ? theme.components.chip.outline.active.borderColor
          : theme.components.chip.outline.borderColor
      }`,
      paddingInline: theme.components.chip[size].paddingInline,
      '&[aria-disabled=true]': {
        cursor: 'default',
        color: theme.components.chip.disabled.textColor,
        // backgroundColor: theme.components.chip.disabled.surfaceColor,
      },
      '&:not([aria-disabled=true])': {
        ':hover, &[aria-expanded=true]': {
          ' .interactionOverlay': {
            backgroundColor: active
              ? theme.components.chip.outline.active.interactions.hover
                  .surfaceColor
              : theme.components.chip.outline.interactions.hover.surfaceColor,
          },
        },
        ':active': {
          ' .interactionOverlay': {
            backgroundColor: active
              ? theme.components.chip.outline.active.interactions.pressed
                  .surfaceColor
              : theme.components.chip.outline.interactions.pressed.surfaceColor,
          },
        },
      },
    };
  }, [variant, disabled, active, theme.components.chip, size]);

  return variantStyles;
};
