import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@balance-web/box';
import {
  DropdownMenu,
  DropdownMenuProps,
  MenuItem,
} from '@balance-web/dropdown';

export type ActionMenuProps = {
  trigger: DropdownMenuProps['trigger'];
  footer?: ReactNode;
  menuItems: Parameters<typeof MenuItem>[0][];
} & BoxProps;

export const ActionMenu = ({
  trigger,
  menuItems,
  footer,
  ...boxProps
}: ActionMenuProps) => {
  return (
    <Box {...boxProps}>
      <DropdownMenu trigger={trigger}>
        {(menuItems || []).map((item) => (
          <MenuItem key={item.label} tone={item.tone || 'active'} {...item} />
        ))}
        {footer}
      </DropdownMenu>
    </Box>
  );
};
