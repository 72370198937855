import { createContext } from 'react';

import type { OpenState, OpenStateContextShape } from './types';

/**
 * Creates a context that can be used to provide state to a `useOpenState` hook
 *
 * @example
 *    const MyThingsToTrack = ['a', 'b', 'c'] as const;
 *    export const MyOpenStateContext = createOpenStateContext(MyThingsToTrack);
 *
 * @param names
 * @returns
 */

export function createOpenStateContext<TName extends string>(
  names: readonly TName[]
) {
  return createContext<OpenStateContextShape<TName[]>>({
    isOpen: names.reduce<OpenState<TName>>(
      (results, name) =>
        !!name && typeof name === 'string'
          ? {
              ...results,
              [name]: false,
            }
          : results,
      {} as OpenState<TName>
    ),
    open: () => {},
    close: () => {},
    toggle: () => {},
  });
}
