/** @jsx jsx */

import { Children, ReactElement, useMemo } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { Text } from '@balance-web/text';
import { useRawTheme } from '@balance-web/theme';

import { getSizes } from './Base';
import { UserAvatarProps } from './UserAvatar';
import { AvatarStackContext, AvatarStackContextType } from './context';

export type AvatarStackProps = {
  /** Each avatar in the stack. */
  children: ReactElement<UserAvatarProps>[];
  /** When true, the overflow text will be hidden. */
  hideOverflowText?: boolean;
  /** Optionally set the maximum number of avatars allowed in the stack. */
  max?: number;
} & AvatarStackContextType;

export const AvatarStack = ({
  children,
  hideOverflowText,
  max = 6,
  size = 'medium',
  ...props
}: AvatarStackProps) => {
  const rawTheme = useRawTheme();
  const sizes = getSizes(rawTheme);
  const resolvedSize = sizes[size];
  const context = useMemo(() => ({ size }), [size]);

  const childArray = Children.toArray(children) as ReactElement<
    UserAvatarProps
  >[];
  const maxArray = childArray.slice(0, max);
  const total = childArray.length;
  const overflow = Math.max(total - max, 0);
  const maskSize = resolvedSize.stackMask;
  const offset = (resolvedSize.boxSize + maskSize * 2) / 5; // we want to "pull" the next avatar over by 20% of its width

  return (
    <AvatarStackContext.Provider value={context}>
      <div css={{ alignItems: 'center', display: 'flex' }} {...props}>
        {maxArray.map((child, index) => {
          let zIndex = total - index; // the first avatar should be on top, with subsequent avatars underneath
          let isLast = maxArray.length === index + 1;

          return (
            <div
              key={child.props.name}
              css={{
                borderRadius: '50%',
                marginRight: isLast ? undefined : -offset,
                position: 'relative',
                zIndex,

                // gap/mask thing between avatars
                backgroundColor: rawTheme.colors.background,
                padding: maskSize,
              }}
            >
              {child}
            </div>
          );
        })}

        {overflow && !hideOverflowText ? (
          <Box marginLeft="xsmall">
            <Text
              color="dim"
              size={size}
              weight="medium"
              overflowStrategy="nowrap"
            >
              +{overflow} more
            </Text>
          </Box>
        ) : null}
      </div>
    </AvatarStackContext.Provider>
  );
};
