import type { ChangeEvent } from 'react';
import { useId } from '@balance-web/utils';

import type { FilterChipCheckboxGroupProps } from './types';

type CheckboxGroupStateHookProps = Omit<
  FilterChipCheckboxGroupProps,
  'children' | 'legend'
>;
export function useCheckboxGroupState(props: CheckboxGroupStateHookProps) {
  const name = useId(props.name);
  const hasValue = (value: string) => {
    return props.value.includes(value);
  };

  const state = {
    name,
    disabled: props.disabled,
    size: props.size,
    value: props.value,
    hasValue,
    onChange(event: ChangeEvent<HTMLInputElement>) {
      if (props.disabled) {
        return;
      }

      const inputValue = event.target.value;
      const newValue = hasValue(inputValue)
        ? props.value.filter((existingValue: string) => {
            return existingValue !== inputValue;
          })
        : props.value.concat(inputValue);

      props.onChange(newValue);
    },
  };

  return state;
}

type CheckboxPrimitiveProps = {
  checked?: boolean;
  disabled?: boolean;
  value?: string;
};

export function checkboxItemProps(
  props: CheckboxPrimitiveProps,
  state: ReturnType<typeof useCheckboxGroupState>
) {
  const checkboxValue = props.value;

  if (checkboxValue == null) {
    throw new Error(
      'Each <Checkbox> within a <CheckboxGroup> requires a `value` property.'
    );
  }

  const augmentedProps = {
    name: state.name,
    disabled: props.disabled,
    checked: state.hasValue(checkboxValue),
    onChange: state.onChange,
    value: props.value,
  };

  return augmentedProps;
}
