/** @jsx jsx */

import React, { forwardRef } from 'react';
import type { ReactNode } from 'react';
import ReactPlayer from 'react-player/lazy';
import { jsx } from '@balance-web/core';
import type { BoxProps } from '@balance-web/box';
import { Text } from '@balance-web/text';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
import { MehIcon, PlayIcon } from '@balance-web/icon';
import { TextLink } from '@balance-web/text-link';
import { useTheme } from '@balance-web/theme';

import { VideoBlock } from './VideoBlock';

export type VideoPlayerProps = {
  url: string;
  poster?: ReactNode;
} & Pick<BoxProps, 'width' | 'height'>;

export const VideoPlayer = forwardRef<HTMLDivElement, VideoPlayerProps>(
  ({ url, poster, width = '100%', height = 400 }, boxRef) => {
    const { palette } = useTheme();

    const bgTone = 'muted';
    const canPlay = !!url && ReactPlayer.canPlay(url);

    if (!canPlay) {
      return (
        <VideoBlock
          width={width}
          height={height}
          background={bgTone}
          borderRadius="small"
          ref={boxRef}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            direction="column"
            height="100%"
            width="100%"
            gap="large"
          >
            <MehIcon size="large" color={bgTone} />
            <Stack width="75%" align="center" gap="xxlarge">
              <Text align="center">
                We were going to show you a video here, but something prevented
                us doing that.
              </Text>
              <Text align="center" size="small">
                <TextLink color="link" href={url}>
                  {url}
                </TextLink>
              </Text>
            </Stack>
          </Flex>
        </VideoBlock>
      );
    }

    return (
      <ReactPlayer
        controls
        url={url}
        light
        config={{
          youtube: {
            playerVars: {
              modestbranding: true,
            },
          },
        }}
        wrapper={({ children }) => (
          <VideoBlock
            width={width}
            height={height}
            background={bgTone}
            borderRadius="small"
            ref={boxRef}
          >
            {children}
          </VideoBlock>
        )}
        playIcon={
          <Flex
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            css={{ position: 'relative' }}
          >
            {poster}
            <Flex
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: '#00000033',
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Flex
                width={88}
                height={88}
                css={{
                  borderRadius: 1000,
                  paddingLeft: 28,
                  paddingRight: 20,
                  background: palette.background.dim,
                  svg: { stroke: palette.text.dim },
                  '&:hover': { background: palette.background.base },
                  '&:hover svg': { stroke: palette.text.link },
                  '&:active, &:focus': { background: palette.background.shade },
                  '&:active svg, &:focus svg': { stroke: palette.text.dim },
                }}
                background="base"
                justifyContent="center"
                alignItems="center"
              >
                <PlayIcon size={40} />
              </Flex>
            </Flex>
          </Flex>
        }
      />
    );
  }
);
