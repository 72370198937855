import React, { useMemo, useState } from 'react';

import { OpenCollegesMetaContext } from './OpenCollegesMetaContext';

export function OpenCollegesMetaProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [isNonStpPosting, setIsNonStpPosting] = useState(false);

  const value = useMemo(() => {
    return {
      isNonStpPosting,
      setIsNonStpPosting,
    };
  }, [isNonStpPosting, setIsNonStpPosting]);

  return (
    <OpenCollegesMetaContext.Provider value={value}>
      {children}
    </OpenCollegesMetaContext.Provider>
  );
}
