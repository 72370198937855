/** @jsx jsx */

import type { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import { ternary } from '@balance-web/utils';

import type { ListItemProps } from './ListItem';
import type { ListType } from './types';
import { useGutters, useItemHighlightingStyles } from './styles';

export type SidebarNavigationItemLayoutProps = {
  as?: 'button';
  selected?: boolean;
  listType: ListType;
  children?: ReactNode;
} & Pick<ListItemProps, 'onClick' | 'href'>; // We need these props to figure out item interactivity

/**
 * @private Common layout component for ListItem, CheckItem, RadioItem
 * */
export const ListItemLayout = ({
  as,
  listType,
  selected,
  onClick,
  href,
  children,
}: SidebarNavigationItemLayoutProps) => {
  const highlightingStyles = useItemHighlightingStyles(
    listType === 'other' && selected
  );

  // Controls the highlighting of the list item.
  const isOtherInteractive = listType === 'other' ? !!href || !!onClick : true;

  const { innerGutter } = useGutters();

  return (
    <Flex width="100%">
      <Flex flex="1">
        <Flex
          as={as}
          data-selected={selected}
          flex={1}
          css={{
            height: '100%',
            position: 'relative',
            padding: innerGutter,
            ...ternary(isOtherInteractive, highlightingStyles, null),
          }}
        >
          <Flex flex={1}>{children}</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
