import { useLayoutEffect, useState } from 'react';

export function useUnsupportedBrowser({
  skip,
  pattern,
  onUnsupported,
}: {
  skip?: boolean;
  pattern?: RegExp;
  onUnsupported?: () => void;
} = {}) {
  const [loading, setLoading] = useState(true);
  const [supported, setSupported] = useState(false);

  /**
   * This useEffect will ensure this test only runs when in an actual browser
   */
  useLayoutEffect(() => {
    if (skip) {
      return;
    }

    const isUnsupported = !pattern
      ? isUnsupportedBrowser__old()
      : !pattern.test(navigator.userAgent);

    setLoading(false);
    setSupported(!isUnsupported);

    if (isUnsupported && typeof onUnsupported === 'function') {
      onUnsupported();
    }
  }, [onUnsupported, pattern, skip]);

  return {
    loading,
    supported,
  };
}

/**
 * @deprecated generate a browserlist regexp pattern instead
 */
export function isUnsupportedBrowser__old() {
  if (typeof window === 'undefined') {
    return true;
  }

  const userAgent = window.navigator.userAgent;
  /**
   * Internet Explorer Edge
   */
  const isEdge = userAgent.indexOf('Edge') !== -1;

  /**
   * Internet Explorer <= 11
   */
  const isIE = userAgent.indexOf('Trident') !== -1 && !isEdge;

  /**
   * Build this up with more potential truthy cases, leave the last one as `false`
   */
  const isUnsupported = !!isIE || false;

  return isUnsupported;
}
