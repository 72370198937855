import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonInsightsLogoName = 'ReckonInsightsLogo';
export const ReckonInsightsAppName = 'Insights';
export const ReckonInsightsAppLongName = 'Reckon Insights';
export const ReckonInsightsLogoDescription = 'Reckon Insights Application Logo';

export const ReckonInsightsLogo = createIllustration(
  {
    name: ReckonInsightsLogoName,
    description: ReckonInsightsLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#a)"
      />
    </mask>
    <g mask="url(#b)">
      <path fill="url(#c)" d="M0 0h60v60H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.667 18.4a1.333 1.333 0 1 0 0 2.667h4.78L32 30.514l-5.724-5.723a1.333 1.333 0 0 0-1.886 0l-10 10a1.333 1.333 0 1 0 1.886 1.885l9.057-9.057 5.724 5.724c.52.52 1.365.52 1.886 0l10.39-10.39v4.78a1.333 1.333 0 0 0 2.667 0v-8a1.327 1.327 0 0 0-.8-1.222 1.328 1.328 0 0 0-.533-.111h-8Z"
        fill="#fff"
      />
      <g filter="url(#d)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.374 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.374 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <mask
      id="f"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#e)"
      />
    </mask>
    <g mask="url(#f)">
      <path fill="url(#g)" d="M0 0h60v60H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.333 18.838c-.92 0-1.666.716-1.666 1.599s.746 1.599 1.666 1.599h5.977L32.5 33.366l-7.155-6.864a1.717 1.717 0 0 0-2.357 0l-12.5 11.993a1.554 1.554 0 0 0 0 2.261c.651.625 1.706.625 2.357 0l11.322-10.862 7.154 6.865c.651.624 1.707.624 2.357 0l12.989-12.462v5.734c0 .883.746 1.6 1.666 1.6.92 0 1.667-.717 1.667-1.6v-9.594a1.54 1.54 0 0 0-.405-1.045 1.646 1.646 0 0 0-.596-.421 1.721 1.721 0 0 0-.666-.133h-10Z"
        fill="#fff"
      />
      <g filter="url(#h)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.374 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.374 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <filter
        id="d"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_13664_451"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_13664_451"
          result="shape"
        />
      </filter>
      <filter
        id="h"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_13664_451"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_13664_451"
          result="shape"
        />
      </filter>
    </defs>
  </React.Fragment>
);
