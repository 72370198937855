import React from 'react';

export const ArchiveIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      style={{
        verticalAlign: 'text-bottom', // removes whitespace inside buttons
        stroke: 'none',
        fill: '#52535C',
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
        strokeWidth: 2,
        height: 24,
        width: 24,
      }}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <React.Fragment>
        <path
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 3C0 2.44772 0.447715 2 1 2H23C23.5523 2 24 2.44772 24 3V8C24 8.55228 23.5523 9 23 9H22V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V9H1C0.447715 9 0 8.55228 0 8V3ZM20 20V9H4V20H20ZM22 7H2V4H22V7ZM10 11C9.44771 11 9 11.4477 9 12C9 12.5523 9.44771 13 10 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11H10Z"
        />
      </React.Fragment>
    </svg>
  );
};
