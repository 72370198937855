/** @jsx jsx */
import { ComponentType, Fragment, ReactElement, useState } from 'react';
import { jsx } from '@balance-web/core';
import { Text } from '@balance-web/text';
import { ChevronDownIcon, ChevronUpIcon, IconProps } from '@balance-web/icon';
import { Inline } from '@balance-web/inline';
import { Flex } from '@balance-web/flex';
import { Box } from '@balance-web/box';
import { EntityAvatarProps, UserAvatarProps } from '@balance-web/avatar';
import { useTheme } from '@balance-web/theme';
import { makeId, useId } from '@balance-web/utils';
import { flattenElements } from '@balance-web/utils';

import { SidebarNavigationGroupContext } from './sidebarNavigationGroupContext';
import { SidebarNavigationItemProps } from './SidebarNavigationItem';
import { useSidebarNavigationContext } from './sidebarNavigationContext';
import { SidebarNavigationItemLayout } from './SidebarNavigationItemLayout';
import { useSeparatorStyles } from './styleHooks';
export type SidebarNavigationGroupProps = {
  /** Unique key for the group inside a particular SidebarNavigation. */
  groupKey: string;
  /** Label for the group that the user sees. */
  label: string;
  /** Optional custom element to the left of the label. */
  startElement?:
    | ReactElement<EntityAvatarProps>
    | ReactElement<UserAvatarProps>;
  /** Optional icon to the left of the label. Only renders icon if startElement is not provided.  */
  icon?: ComponentType<IconProps>;
  /** SidebarNavigationItem or SidebarNavigationGroup rendered as children. */
  children:
    | Array<ReactElement<SidebarNavigationItemProps>>
    | ReactElement<SidebarNavigationItemProps>;
};

export const SidebarNavigationGroup = ({
  groupKey,
  label,
  icon: Icon,
  startElement,
  children,
}: SidebarNavigationGroupProps) => {
  const childArray = flattenElements(children);

  const { defaultExpandedGroupKey } = useSidebarNavigationContext();

  const [isExpanded, setIsExpanded] = useState(
    defaultExpandedGroupKey === groupKey
  );

  const { sizing } = useTheme();

  const itemSeparatorStyles = useSeparatorStyles({
    isInsideNavigationGroup: true,
  });

  const rootId = useId();
  const regionId = makeId(rootId, 'region');
  const toggleId = makeId(rootId, 'toggle');

  const ExpandedToggleIndicator = isExpanded ? ChevronUpIcon : ChevronDownIcon;

  const groupChildrenInset = 2;

  return (
    <Fragment>
      <Box
        data-expanded={isExpanded}
        minHeight={sizing.large}
        className="sidebarNavigationGroup"
      >
        <SidebarNavigationItemLayout
          as="button"
          onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
          hasIcon={!!Icon}
        >
          <Inline gap="medium" alignY="center" flex={1}>
            {startElement ? (
              startElement
            ) : Icon ? (
              <Box padding="xsmall">
                <Icon />
              </Box>
            ) : null}
            <Text weight="semibold" size="small">
              {label}
            </Text>
            <Flex flex={1} justifyContent="flex-end">
              <ExpandedToggleIndicator color="dim" />
            </Flex>
          </Inline>
        </SidebarNavigationItemLayout>

        {isExpanded && (
          <div
            role="region"
            id={regionId}
            aria-labelledby={toggleId}
            css={{
              paddingTop: groupChildrenInset,
              paddingBottom: groupChildrenInset,
            }}
          >
            <Box as="ul" flex={1} paddingLeft="xsmall">
              {childArray.map((child, index) => {
                return (
                  <SidebarNavigationGroupContext.Provider
                    key={child.key || index}
                    value={true}
                  >
                    <li css={itemSeparatorStyles}>{child}</li>
                  </SidebarNavigationGroupContext.Provider>
                );
              })}
            </Box>
          </div>
        )}
      </Box>
    </Fragment>
  );
};
