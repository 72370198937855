import React from 'react';
import type { PropsWithChildren } from 'react';
import { isObject } from 'lodash';
import useHashParam from 'use-hash-param';

import { buildQuerystring } from './buildQuerystring';
import { useAuth } from './useAuth';
type OauthStateWithNextUrl = {
  next: string;
};

type EncodedOauthState = Record<string, unknown> & OauthStateWithNextUrl;

export type SecureRouteProtectionManagerProps = PropsWithChildren<{
  enabled?: boolean;
  onProcessState?: (
    state: EncodedOauthState | null,
    redirectToNext?: () => void
  ) => void;
}>;

/**
 * Will funnel off users who land on an app with a '?state=...'
 *
 * state should be an encoded stringified JSON object
 */
export function OauthCallbackLoginManager({
  enabled,
  onProcessState,
  children,
}: SecureRouteProtectionManagerProps) {
  const { isAuthenticated, isLoading, setCredentials } = useAuth();

  const [oAuthState] = useHashParam('state', '');
  const [accessToken] = useHashParam('accessToken', '');

  React.useEffect(() => {
    if (!enabled) return;

    /**
     * Don't make any decsisions until we've resolved authstate
     */
    if (isLoading) return;

    /**
     * If there's no OAuthState do nothing
     */
    if (!oAuthState) return;

    setCredentials({ accessToken });

    // We have some oauth state that needs unpacking
    // it could have our next param
    const state = parseOauthState(oAuthState);
    const redirectToNext = () => {
      if (!state?.next) return;
      window.location.href = state.next;
    };

    if (typeof onProcessState === 'function') {
      onProcessState(state, redirectToNext);
    } else {
      redirectToNext();
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, enabled, oAuthState, accessToken]);

  /** Integrate this with the blocking logic that exists on master. */
  if (oAuthState === undefined || accessToken === undefined) return null;

  return <>{children}</>;
}

/**
 * Verify that state is OauthStateWithNextUrl
 * @see {@link OauthStateWithNextUrl}
 */
export function isOauthStateWithNextUrl(
  state: unknown
): state is OauthStateWithNextUrl {
  if (!isObject(state)) return false; // is it an object
  return state.hasOwnProperty('next'); // does it have the next property
}

/**
 * Enforce that state is OauthStateWithNextUrl
 * @see {@link OauthStateWithNextUrl}
 */
export function assertIsOauthStateWithNextUrl(
  state: unknown
): asserts state is OauthStateWithNextUrl {
  if (!isOauthStateWithNextUrl(state)) {
    throw new Error(
      "provided oauth state doesn't look correct. must be an object with a 'next' property"
    );
  }
  return;
}

/**
 * Parses OAUTH state and returns object with key/value string pairs.
 * OAUTH state will already be decoded by useHashParam.
 * Should contain at least a 'next' url, but possibly other params, too.
 * We should still return the other params in case onProcessState needs them.
 */
export function parseOauthState(oAuthState: string) {
  try {
    const parsed = JSON.parse(oAuthState);

    assertIsOauthStateWithNextUrl(parsed);
    return parsed;
  } catch (error) {
    // we dont really care about what the error is,
    // just that we either get null or the json object in the encoded string.
  }
  return null;
}

/**
 * Prepare next parameter for returning user
 *
 * This is the apps internal redirect, not the oauth spec'd redirect_url
 */
export function encodeOauthNextAsState(
  next: string,
  existingParams: Record<string, any> = {}
) {
  return encodeOauthState({
    next,
    ...existingParams,
  });
}

/**
 * Business logic wrapper that idiomizes how we store
 * regurtitation values for returning users
 */
export function encodeOauthState(params: Record<string, any>) {
  return encodeURIComponent(JSON.stringify(params));
}

/**
 * pulls the current domain as a url from the URI
 */
export function deriveDomainFromLocation(url: string) {
  const urlObject = new URL(url);
  return urlObject.origin;
}
type BuildOauthLoginUrlOptions = {
  oauthProviderUrl: string;
  clientId: string;
  callbackUrl: string;
  returnTo: string;
};

export function buildOauthLoginUrl({
  oauthProviderUrl,
  clientId,
  callbackUrl,
  returnTo,
}: BuildOauthLoginUrlOptions) {
  return [
    oauthProviderUrl,
    buildQuerystring({
      client_id: clientId,
      redirect_url: encodeURIComponent(callbackUrl),
      state: encodeURIComponent(JSON.stringify({ next: returnTo })),
    }),
  ].join('?');
}
