import React from 'react';
import { SpotAttention } from '@balance-web/illustration';
import { Text } from '@balance-web/text';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
import { Heading } from '@balance-web/heading';
import { Button } from '@balance-web/button';
import { TextLink } from '@balance-web/text-link';
import { BookOpenIcon, PlusIcon } from '@balance-web/icon';

import type { BaseBook } from '../types';
export type CreateBookEmptyStateProps = {
  demoBooks?: BaseBook[];
  missingBookHelpURL: string;
  appName?: string;
  onCreateNewBookClick?: () => void;
  onOpenDemoBookClick?: () => void;
};

export const CreateBookEmptyState = ({
  onOpenDemoBookClick,
  onCreateNewBookClick,
  appName,
  missingBookHelpURL,
}: CreateBookEmptyStateProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      padding="large"
    >
      <Stack gap="large" align="center">
        <Heading level="6">Select a book</Heading>
        <SpotAttention />

        <Stack align="center" gap="xlarge">
          <Flex direction="column" gap="medium" alignItems="center">
            <Heading level="4">You haven't created a book</Heading>
            <Text color="muted">
              Create a new book to start using {appName}
            </Text>
          </Flex>

          <Flex gap="large">
            {!!onCreateNewBookClick && (
              <Button
                iconBefore={PlusIcon}
                variant="outline"
                label="Create a new book"
                onClick={onCreateNewBookClick}
              />
            )}

            {!!onOpenDemoBookClick && (
              <Button
                iconBefore={BookOpenIcon}
                variant="text"
                label="Open demo book"
                onClick={onOpenDemoBookClick}
              />
            )}
          </Flex>

          <Text size="small">
            <TextLink href={missingBookHelpURL} target="_blank">
              Don't see your book here
            </TextLink>
          </Text>
        </Stack>
      </Stack>
    </Flex>
  );
};
