/** @jsx jsx */
import React, {
  ComponentType,
  MouseEventHandler,
  ReactNode,
  forwardRef,
} from 'react';
import { jsx } from '@balance-web/core';
import { IconProps } from '@balance-web/icon';
import { Text } from '@balance-web/text';
import { Flex } from '@balance-web/flex';
import { Box } from '@balance-web/box';
import { Inline } from '@balance-web/inline';

import { SidebarNavigationItemLayout } from './SidebarNavigationItemLayout';

export type SidebarNavigationItemButtonProps = {
  /** Label for the button that the user sees. */
  label: string;
  /** Optional icon to the left of the label. */
  icon?: ComponentType<IconProps>;
  /** Optional custom element to the right of the label. */
  endElement?: ReactNode;
  /** Click handler for the entire component. */
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const SidebarNavigationItemButton = forwardRef<
  HTMLAnchorElement,
  SidebarNavigationItemButtonProps
>(({ label, icon: Icon, endElement, onClick }, ref) => {
  return (
    <SidebarNavigationItemLayout as="button" hasIcon={!!Icon} onClick={onClick}>
      <Inline gap="medium" alignY="center">
        {Icon && (
          <Box paddingX="xsmall">
            <Icon color="base" />
          </Box>
        )}
        <Text weight="semibold" size="small">
          {label}
        </Text>
        {endElement && (
          <Flex direction="row" flex={1} justifyContent="flex-end">
            {endElement}
          </Flex>
        )}
      </Inline>
    </SidebarNavigationItemLayout>
  );
});
