import { ErrorResponse, onError } from '@apollo/client/link/error';

import { promiseToObservable } from './promiseToObservable';

const DEFAULT_AUTH_ERROR_CODES = ['UNAUTHENTICATED', 'FORBIDDEN'];
function defaultErrorFilter(error?: string) {
  return !!error && DEFAULT_AUTH_ERROR_CODES.includes(error);
}

export const HandleAuthErrorResponseLink = (
  onRefresh: (code?: string) => Promise<void>,
  isAuthError?: (code?: string) => boolean
) => {
  const errorFilter =
    typeof isAuthError === 'function' ? isAuthError : defaultErrorFilter;

  return onError(
    ({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
      if (networkError) {
        console.error(
          `[🌏 Network error]: Operation: ${operation.operationName}, Message: ${networkError}`
        );
      }

      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          const { message, extensions } = err;
          const errorCode = extensions?.code;

          console.error(
            `[⚠️ GraphQL error]: Operation: ${operation.operationName}, Message: ${message}, Code: ${errorCode}`
          );

          if (!errorFilter(errorCode)) continue;
          return promiseToObservable(onRefresh(errorCode)).flatMap(() => {
            return forward(operation);
          });
        }
      }
    }
  );
};
