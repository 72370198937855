/** @jsx jsx */
import { ReactNode, cloneElement } from 'react';
import { jsx } from '@balance-web/core';
import { RadioGroupProps, useRadioGroupState } from '@balance-web/radio';
import { Stack } from '@balance-web/stack';
import { VisuallyHidden } from '@balance-web/a11y';
import { Box } from '@balance-web/box';
import { makeId, useId } from '@balance-web/utils';
import { flattenElements } from '@balance-web/utils';

import { Density } from './types';
import { RadioGroupContextProvider } from './context';
import { DENSITY_PADDING } from './constants';
import { RadioCard } from './RadioCard';

export const RadioCardGroup = <Value extends string>({
  label,
  legend,
  density = 'regular',
  children,
  ...props
}: RadioGroupProps<Value> & {
  density?: Density;
  legend: string;
  label?: ReactNode;
}) => {
  const childArray = flattenElements(children);
  const context = useRadioGroupState(props);

  const instanceId = useId();
  const legendId = makeId(instanceId, 'card-radio-group-legend');

  return (
    <RadioGroupContextProvider value={context}>
      <Stack border="muted" borderRadius="large" borderWidth="large">
        {label ? <Box padding={DENSITY_PADDING[density]}>{label}</Box> : null}
        <fieldset role="radiogroup" aria-labelledby={legendId}>
          <Stack dividers>
            {/* Force all cards to have the same density */}
            {childArray.map((child) => cloneElement(child, { density }))}
          </Stack>
        </fieldset>
      </Stack>
      <VisuallyHidden id={legendId}>{legend}</VisuallyHidden>
    </RadioGroupContextProvider>
  );
};

RadioCardGroup.Item = RadioCard;
