/**
 * Takes a Blob and downloads it by opening the url in a new window
 *
 *
 * @returns
 */
export function downloadBlob({
  blob,
  mimeType,
}: {
  blob: Blob;
  mimeType: string;
}) {
  const file = new Blob([blob], { type: mimeType });
  const fileUrl = URL.createObjectURL(file);
  window.open(fileUrl);
  return { file, fileUrl };
}
