import * as React from 'react';

import { SidebarContext } from './SidebarContext';

type SidebarProviderProps = {
  children: React.ReactNode;
};
export const SidebarProvider = ({ children }: SidebarProviderProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);

  return (
    <SidebarContext.Provider value={[isSidebarOpen, setIsSidebarOpen]}>
      {children}
    </SidebarContext.Provider>
  );
};
