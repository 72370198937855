import { createContext, useContext } from 'react';
import { useRadioGroupState } from '@balance-web/radio';

export type RadioGroupContextType = ReturnType<typeof useRadioGroupState>;
export const RadioGroupContext = createContext<RadioGroupContextType | null>(
  null
);
export const RadioGroupContextProvider = RadioGroupContext.Provider;
export const useRadioGroupContext = () => {
  const context = useContext(RadioGroupContext);
  if (!context)
    throw Error('RadioCardGroup.Item can only be used inside RadioCardGroup');
  return context;
};
