/** @jsx jsx */
import { Stack } from '@balance-web/stack';
import type { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

import { CollapsableContextProvider } from './context';
import { CollapsableContent } from './radix-styled-components/CollapsableContent';
import { CollapsableRoot } from './radix-styled-components/CollapsableRoot';
import { CollapsableHeader } from './CollapsableHeader';

export type CollapsableProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children?: ReactNode;
};

export const Collapsable = ({
  isOpen,
  setIsOpen,
  children,
}: CollapsableProps) => {
  const theme = useTheme();

  return (
    <CollapsableContextProvider value={{ isOpen }}>
      <CollapsableRoot isOpen={isOpen} setIsOpen={setIsOpen}>
        <Stack
          gap="xlarge"
          paddingX="xlarge"
          paddingY="large"
          borderRadius="large"
          css={{
            border: `${theme.borderWidth.standard} solid ${theme.palette.border.standard}`,
          }}
        >
          {children}
        </Stack>
      </CollapsableRoot>
    </CollapsableContextProvider>
  );
};

Collapsable.Header = CollapsableHeader;
Collapsable.Content = CollapsableContent;
