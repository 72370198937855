import { useState } from 'react';

// @ts-ignore
import { mods } from './generated-scope';
import { getData } from './getData';

const additionalPackages = require('./additionalPackages');

export const modules: Record<string, Record<string, any>> = mods;

export let scope: { [key: string]: any } = {
  getData,
  useState,
  wait: (ms: number) => new Promise((resolve) => setTimeout(resolve, ms)),
  icons: require('@balance-web/icon'),
  Render: ({ children }: any) => children(),
};

Object.keys(modules).forEach((pkg: string) => {
  let mod = modules[pkg];
  // Why not Object.assign/spread?
  // I want to check that we don't have conflicting keys
  Object.keys(mod).forEach((key) => {
    const fromAdditionalPackages = additionalPackages.packages.find(
      (addPkg: any) => typeof addPkg !== 'string' && addPkg.package === pkg
    );

    // Default export packages are only allowed if they have an import name in additionalPackages.js
    if (key === 'default') {
      if (!fromAdditionalPackages)
        throw new Error(
          `${pkg} has a default export, these are not allowed on design system packages because they must share the same namespace for the design system website code editor`
        );

      scope[fromAdditionalPackages.importName] = mod[key];
      return;
    }

    if (fromAdditionalPackages?.prefixImports) {
      const prefixedImport = `${fromAdditionalPackages.prefixImports}_${key}`;
      scope[prefixedImport] = mod[key];
      return;
    }

    if (scope[key] !== undefined) {
      scope[key] = `${pkg}_${mod[key]}`;
      console.warn(
        `${pkg} has an export named ${key} but another package in the design system has an export with that name. We have patched this by prepending the package name to the export. Use ${scope[key]} to use this import.`
      );
    } else {
      scope[key] = mod[key];
    }
  });
});

delete scope['default'];

export let packagesByExportName: Record<string, string> = {
  useState: 'react',
};

Object.keys(modules).forEach((pkgName) => {
  let mod = modules[pkgName];
  Object.keys(mod).forEach((key) => {
    packagesByExportName[key] = pkgName;
  });
});
