import { z } from 'zod';

import { HumanisedBooleanLike } from './HumanisedBooleanLike';

export const GeneratedDeploymentConfigSchema = {
  /**
   * Environment Name is dev by default.
   */
  //'dev' | 'staging' | 'prod');
  STAGE: z.union([z.literal('dev'), z.literal('staging'), z.literal('prod')]),

  /**
   * 8 char git hash
   */
  GIT_HASH: z.string(),

  /**
   * Date of the last commit
   */
  GIT_COMMITDATE: z.string(),

  /**
   * Tag of the last commit
   */
  GIT_TAG: z.string(),

  /**
   * Application
   */
  APPLICATION_VERSION: z.string(),

  /**
   * the application name in the package.json
   */
  APPLICATION_PACKAGE_NAME: z.string(),

  /**
   * Used by our load balancer to route requests to the correct stack
   */
  APPLICATION_RKN_WEB_STACK_VERSION: z.string(),

  /**
   * LocalStorage prefix
   */
  APPLICATION_LOCALSTORAGE_PREFIX: z.string(),

  /**
   * Analaytics
   */
  APPLICATION_REPORT_WEB_VITALS: HumanisedBooleanLike.default(true),

  /**
   * Security
   */
  APPLICATION_AUTH_IDLE_FORCELOGOUT_ENABLED: HumanisedBooleanLike.default(
    false
  ).describe('Whether to force logout after a period of inactivity.'),
  APPLICATION_AUTH_IDLE_FORCELOGOUT_TIMEOUTMS: z
    .number()
    .default(1800000)
    .describe(
      'Amount of time in milliseconds before the user is logged out due to inactivity. Default is 30 minutes.'
    ),
  APPLICATION_AUTH_IDLE_FORCELOGOUT_WARNINGMS: z
    .number()
    .default(60000)
    .describe(
      'Amount of time in milliseconds before the timeout where the user will be warned about the forced logout. Default is 1 minute.'
    ),

  /**
   * Graphql: identify the client name to server logs
   */
  APPLICATION_APOLLO_CLIENT_NAME: z.string(),

  /**
   * Graphql: identify the operation prefix to server logs
   */
  APPLICATION_APOLLO_CLIENT_OPERATIONPREFIX_SHORTNAME: z.string(),
  /**
   * Graphql: refresh interval (in ms) for queries
   */
  APPLICATION_APOLLO_CLIENT_QUERY_REFRESH_INTERVAL: z.coerce
    .number()
    .default(1800000),
  /**
   * Features
   */
  APPLICATION_FEATURE_USE_MARKINGS: HumanisedBooleanLike.default(false),
};

const SchemaAsObject = z.object(GeneratedDeploymentConfigSchema);

export type GeneratedDeploymentConfig = z.infer<typeof SchemaAsObject>;

export function mockGeneratedDeploymentConfig(): GeneratedDeploymentConfig {
  return {
    STAGE: 'dev',
    GIT_HASH: 'mocked-git-hash',
    GIT_COMMITDATE: 'mocked-git-commitdate',
    GIT_TAG: 'mocked-git-tag',
    APPLICATION_VERSION: 'mocked-application-version',
    APPLICATION_PACKAGE_NAME: 'mocked-application-package-name',
    APPLICATION_RKN_WEB_STACK_VERSION:
      'mocked-application-rkn-web-stack-version',
    APPLICATION_LOCALSTORAGE_PREFIX: 'mocked-application-localstorage-prefix',
    APPLICATION_REPORT_WEB_VITALS: true,
    APPLICATION_AUTH_IDLE_FORCELOGOUT_ENABLED: true,
    APPLICATION_AUTH_IDLE_FORCELOGOUT_TIMEOUTMS: 1800000,
    APPLICATION_AUTH_IDLE_FORCELOGOUT_WARNINGMS: 1740000,
    APPLICATION_APOLLO_CLIENT_NAME: 'mocked-application-apollo-client-name',
    APPLICATION_APOLLO_CLIENT_OPERATIONPREFIX_SHORTNAME:
      'mocked-application-apollo-client-operationprefix-shortname',
    APPLICATION_APOLLO_CLIENT_QUERY_REFRESH_INTERVAL: 1800000,
    APPLICATION_FEATURE_USE_MARKINGS: false,
  };
}
