/** @jsx jsx */

import type { ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { flattenElements } from '@balance-web/utils';

import { CheckItem } from './CheckItem';
import { useListSeparatorStyles } from './styles';
import { useHideItemBorderOnHover } from './useHideItemBorderOnHover';

export type CheckListProps = {
  children: ReactNode;
};

export const CheckList = ({ children }: CheckListProps) => {
  const childArray = flattenElements(children);

  const childRefs = useHideItemBorderOnHover({ children });
  const listSeparatorStyles = useListSeparatorStyles();

  return (
    <Box paddingY="medium">
      <ul>
        {childArray.map((child, index) => {
          return (
            <li
              key={child.props['label'] || child['key'] || index}
              ref={(element) => (childRefs.current[index] = element)}
              css={listSeparatorStyles}
            >
              {child}
            </li>
          );
        })}
      </ul>
    </Box>
  );
};
CheckList.Item = CheckItem;
