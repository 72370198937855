/** @jsx jsx */

import type {
  ButtonHTMLAttributes,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from 'react';
import { Fragment } from 'react';
import { useRef } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelect } from 'downshift';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { PopoverDialog, usePopper } from '@balance-web/popover';
import { useRawTheme } from '@balance-web/theme';
import { useVirtual } from 'react-virtual';

import {
  Menu,
  MenuItemLoadingIndicator,
  SelectMenuButton,
} from './styled-components';
import { useItemMap } from './useItemMap';

// Prep
// ------------------------------

export const MENU_ALIGNMENT = {
  left: 'bottom-start',
  right: 'bottom-end',
} as const;

// Types
// ------------------------------

type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (
  x: infer R
) => any
  ? R
  : never;

type ItemToLabel<Option> = UnionToIntersection<
  Option extends { label: string }
    ? { itemToLabel?: (option: Option) => string }
    : { itemToLabel: (option: Option) => string }
>;

type ItemToValue<Option> = UnionToIntersection<
  Option extends { value: string | number }
    ? { itemToValue?: (option: Option) => string | number }
    : { itemToValue: (option: Option) => string | number }
>;

export type AnchorElementType =
  | HTMLAnchorElement
  | HTMLButtonElement
  | HTMLDivElement
  | HTMLSpanElement;

type AlignType = keyof typeof MENU_ALIGNMENT;

type TriggerRendererType = (options: {
  isOpen: boolean;
  hasValue: boolean;
  triggerProps: ButtonHTMLAttributes<HTMLButtonElement>;
}) => ReactElement;

export type ModeProps =
  | {
      onLoadMore: () => void;
      canLoadMore: boolean;
      loadingState?: SelectMenuLoadingState;
    }
  | {
      onLoadMore?: never;
      canLoadMore?: never;
      loadingState?: never;
    };

export type SelectMenuLoadingState =
  | 'loading'
  | 'loadingMore'
  | 'error'
  | 'idle';

export type SelectMenuProps<Option> = {
  /** Direction to align the menu dialog relative to the trigger element. */
  align?: AlignType;
  id?: string;
  /** Provide the height, in pixels, of each item in the list. */
  itemHeight?: number;
  /** Control each item is rendered within the menu. */
  itemRenderer?: (option: Option) => ReactElement;
  /** Determine if a given option should be disabled within the menu. */
  itemToDisabled?: (option: Option) => boolean;
  /** Map each option to its appropriate label. */
  itemToLabel?: (option: Option) => string;
  /** Map each option to its appropriate value. */
  itemToValue?: (option: Option) => string | number;
  /** Called when an option is selected. */
  onChange: (value: Option) => void;
  /** Support a "clear" action in the **default** trigger button. */
  onClear?: MouseEventHandler;
  /** Array of options for the user to select from. */
  options: Option[];
  /** The element to be rendered as the dialog trigger. */
  trigger: ReactNode | TriggerRendererType;
  /** The selected value. Must match the return value from `itemToValue`. */
  value: Option | Array<Option> | undefined;
  /** Show selected values count when value is greater than this value */
  selectedValueThreshold?: number;
} & ItemToLabel<Option> &
  ItemToValue<Option> &
  ModeProps;

// Main export
// ------------------------------

export function useVirtualConfig(itemHeight: number) {
  const rawTheme = useRawTheme();

  return {
    estimateSize: useCallback(() => {
      return itemHeight;
    }, [itemHeight]),
    paddingEnd: rawTheme.spacing.xsmall,
    paddingStart: rawTheme.spacing.xsmall,
    overscan: 4,
  };
}

export function SelectMenu<Option>({
  align = 'left',
  itemRenderer: consumerRenderer,
  itemHeight = 40,

  itemToDisabled = (item: Option) => {
    // @ts-ignore
    return Boolean(item.disabled);
  },
  itemToLabel = (item: Option) => {
    // @ts-ignore
    return item.label;
  },
  itemToValue = (item: Option) => {
    // @ts-ignore
    return item.value;
  },
  onChange,
  onClear,
  options,
  trigger,
  value,
  canLoadMore,
  onLoadMore,
  loadingState,
  selectedValueThreshold,
  // @ts-ignore
  ...props
}: SelectMenuProps<Option>) {
  // create the default renderer (can't happen in destructuring because we need `itemToLabel`)
  const itemRenderer =
    consumerRenderer ||
    ((item: Option) => {
      return <Fragment>{itemToLabel(item)}</Fragment>;
    });

  // handle downshift type requirement here rather than passing on to consumers
  const itemToString = (item: Option | null) => {
    return item ? itemToLabel(item) : '';
  };

  // setup downshift (selection/navigation handling)
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: options,
    itemToString,
    selectedItem: Array.isArray(value) ? null : value || null, // stop downshift from disallowing selection of an existing item when multi-select
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      if (Array.isArray(value)) {
        switch (type) {
          case useSelect.stateChangeTypes.MenuKeyDownEnter:
          case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
          case useSelect.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep the menu open after selection
              highlightedIndex: state.highlightedIndex, // maintain the currently highlighted item
            };
        }
      }

      return changes;
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onChange(selectedItem);
      }
    },
  });

  // setup popper (position handling)
  const {
    setAnchorElement,
    setPopoverElement,
    styles,
    attributes,
  } = useSelectPopper({ align, isOpen });

  const triggerProps = getToggleButtonProps({
    ref: (element: AnchorElementType | null) => {
      return setAnchorElement(element);
    },
  });

  // setup trigger (the button that invokes the menu)
  const hasValue = Boolean(
    Array.isArray(value) ? value?.length : value !== undefined
  );
  const triggerElement =
    typeof trigger === 'function' ? (
      trigger({ isOpen, hasValue, triggerProps })
    ) : (
      <SelectMenuButton
        hasValue={hasValue}
        onClear={onClear}
        selectionCount={
          selectedValueThreshold === undefined
            ? 0
            : Array.isArray(value)
            ? value.length > selectedValueThreshold
              ? value.length
              : 0
            : 0
        }
        {...triggerProps}
        label={trigger}
      />
    );

  const menuRef = useRef<HTMLDivElement>(null);

  const themedVirtualConfig = useVirtualConfig(itemHeight);
  const rowVirtualizer = useVirtual({
    ...themedVirtualConfig,
    size: canLoadMore ? options.length + 1 : options.length,
    parentRef: menuRef,
  });

  const itemMap = useItemMap({
    getItemProps,
    itemRenderer,
    itemToDisabled,
    itemToValue,
    items: options,
    selectedItem: value || null,
  });

  const menuContent = (() => {
    // we need a reference to the menu element to prepare accessibility
    // handlers, but we don't want the menu content to be focusable until the
    // menu is open
    if (!isOpen) {
      return null;
    }

    // render items when available
    if (options.length) {
      return (
        <Fragment>
          <div key="total-size" style={{ height: rowVirtualizer.totalSize }} />
          {rowVirtualizer.virtualItems.map(itemMap)}
        </Fragment>
      );
    }

    if (loadingState === 'loading' || loadingState === 'loadingMore') {
      return <MenuItemLoadingIndicator style={{ height: itemHeight }} />;
    }

    // if we reach this point something's gone wrong...
    return null;
  })();

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= options.length - 1 &&
      canLoadMore &&
      onLoadMore &&
      loadingState !== 'loadingMore'
    ) {
      onLoadMore();
    }
  }, [
    canLoadMore,
    onLoadMore,
    options.length,
    loadingState,
    rowVirtualizer.virtualItems,
  ]);

  return (
    <Box {...props}>
      {triggerElement}
      <PopoverDialog
        ref={setPopoverElement}
        isVisible={isOpen}
        style={{ ...styles.popper, overflow: 'hidden' }} // crop highlighted options so they don't spill over the rounded corners
        {...attributes.popper}
      >
        <Menu
          {...getMenuProps({
            role: 'menu',
            ref: menuRef,
            style: { maxHeight: 300 },
          })}
        >
          {menuContent}
        </Menu>
      </PopoverDialog>
    </Box>
  );
}

// Utils
// ------------------------------

export function useSelectPopper({
  isOpen,
  align,
}: {
  isOpen: boolean;
  align: AlignType;
}) {
  const { spacing } = useRawTheme();

  // setup popper (position handling)
  const [anchorElement, setAnchorElement] = useState<AnchorElementType | null>(
    null
  );
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>();

  const { styles, attributes, update } = usePopper(
    anchorElement,
    popoverElement,
    {
      placement: MENU_ALIGNMENT[align],
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, spacing.small],
          },
        },
      ],
    }
  );

  // update popper when it opens to get the latest placement
  // useful for prerendered popovers in modals etc.
  useEffect(() => {
    if (update && isOpen) {
      update();
    }
  }, [isOpen, update]);

  return {
    attributes,
    styles,
    setAnchorElement,
    setPopoverElement,
  };
}
