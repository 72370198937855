/** @jsx jsx */

import type { ButtonHTMLAttributes, HTMLAttributes } from 'react';
import { forwardRef, useMemo } from 'react';
import { jsx } from '@balance-web/core';
import { XCircleIcon } from '@balance-web/icon/icons/XCircleIcon';
import type { InputSizeType } from '@balance-web/text-input';
import {
  Adornment,
  AdornmentWrapper,
  useInputStyles,
} from '@balance-web/text-input';
import { useTheme } from '@balance-web/theme';

type DeprecatedButtonProps = {
  invalid?: boolean;
  isSelected?: boolean;
  size: InputSizeType;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const InputButton = forwardRef<HTMLButtonElement, DeprecatedButtonProps>(
  ({ invalid = false, isSelected, size, ...props }, ref) => {
    const inputStyles = useInputStyles({
      shape: 'square',
      size: size,
    });
    const focusStyles = isSelected
      ? {
          ...inputStyles[':focus'],
          ':hover': inputStyles[':focus'],
          ':focus': inputStyles[':focus'],
        }
      : null;
    const buttonStyles = {
      ...inputStyles,
      ...focusStyles,
      cursor: 'pointer',
      lineHeight: 'initial', // let the button vertically align its text; the have different native behaviour to inputs
      textAlign: 'left',
    } as const;

    return (
      <AdornmentWrapper shape="square" size={size} css={{ flex: 1 }}>
        <button
          ref={ref}
          css={buttonStyles}
          type="button"
          data-invalid={invalid}
          {...props}
        />
      </AdornmentWrapper>
    );
  }
);

InputButton.displayName = 'InputButton';

type ClearButtonProps = {
  size: InputSizeType;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ClearButton = ({ size, ...props }: ClearButtonProps) => {
  const { palette, sizing } = useTheme();

  const rightOffset = useMemo(() => {
    if (size === 'small') {
      return sizing.small;
    }
    return sizing.base;
  }, [size, sizing.base, sizing.small]);

  return (
    <Adornment
      as="button"
      type="button"
      align="right"
      tabIndex={-1}
      css={{
        alignItems: 'center',
        background: 0,
        border: 0,
        borderRadius: '50%',
        color: palette.text.dim,
        display: 'flex',
        justifyContent: 'center',
        outline: 0,
        padding: 0,
        right: rightOffset,

        // No focus styles because this button is not focusable
        ':hover': {
          color: palette.text.muted,
        },
      }}
      {...props}
    >
      <XCircleIcon size={size} />
    </Adornment>
  );
};

export const Placeholder = (props: HTMLAttributes<HTMLSpanElement>) => {
  const { palette } = useTheme();
  return (
    <span
      css={{ color: palette.formInput.textPlaceholder, pointerEvents: 'none' }}
      {...props}
    />
  );
};
