/** @jsx jsx */
import type { InputHTMLAttributes } from 'react';
import React, { forwardRef } from 'react';
import { Flex } from '@balance-web/flex';
import { buildDataAttributes, jsx } from '@balance-web/core';
import { CheckIcon } from '@balance-web/icon';
import { useTheme } from '@balance-web/theme';
import type { WithDataAttributeProp } from '@balance-web/core';
import { VisuallyHidden } from '@balance-web/a11y';

import { buttonResetStyles, useChipVariantStyles } from './shared-styles';
import { InteractionOverlay } from './InteractionOverlay';

export type FilterChipCheckboxProps = WithDataAttributeProp<{
  label: string;
  variant?: 'filled' | 'outline';
  size?: 'small' | 'medium';
}> &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

export const FilterChipCheckbox = forwardRef<
  HTMLLabelElement,
  FilterChipCheckboxProps
>(({ variant = 'filled', size = 'medium', label, data, ...props }, ref) => {
  const theme = useTheme();

  const dataAttributes = buildDataAttributes(data);

  const variantStyles = useChipVariantStyles({
    variant,
    size,
    active: props.checked,
    disabled: props.disabled,
  });

  return (
    <label
      ref={ref}
      aria-disabled={props.disabled}
      css={{
        /** Reset button styles start */
        ...buttonResetStyles,
        /** Reset button styles end */

        /** Immutable styles start */
        position: 'relative',
        fontWeight: theme.typography.fontWeight.semibold,
        borderRadius: theme.components.chip.borderRadius,

        '&:not([aria-disabled=true]).focus-visible': {
          boxShadow: `0 0 0 2px ${theme.palette.global.focusRing}`,
        },
        /** Immutable styles end */

        /** Variant styles start */
        ...variantStyles,
        /** Variant styles end */
      }}
      {...dataAttributes}
    >
      <InteractionOverlay />
      <Flex
        gap="small"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        {props.checked ? <CheckIcon size="small" /> : null}
        <span>{label}</span>
      </Flex>
      <VisuallyHidden as="input" type="checkbox" {...props} />
    </label>
  );
});
