import React from 'react';
import { Flex } from '@balance-web/flex';
import { TextLink } from '@balance-web/text-link';
import { Text } from '@balance-web/text';

export type BookSwitcherSearchFooterProps = {
  missingBookHelpURL: string;
  manageSubscriptionsURL: string;
};

export function BookSwitcherSearchFooter({
  manageSubscriptionsURL,
  missingBookHelpURL,
}: BookSwitcherSearchFooterProps) {
  return (
    <Flex direction="column" gap="large" alignItems="center" padding="large">
      <Text size="xsmall" weight="medium">
        <TextLink href={manageSubscriptionsURL} target="_blank">
          Manage your subscriptions in Portal
        </TextLink>
      </Text>
      <Text size="xsmall">
        <TextLink href={missingBookHelpURL} target="_blank">
          Don't see your book here
        </TextLink>
      </Text>
    </Flex>
  );
}
