import { createContext, useContext } from 'react';

import { SizeType } from './types';

export type AvatarStackContextType = {
  /** The size of each avatar in the stack. */
  size?: SizeType;
};

export const AvatarStackContext = createContext<AvatarStackContextType | null>(
  null
);
export const useAvatarStackContext = () => useContext(AvatarStackContext);
