/** @jsx jsx */
import type { HTMLAttributes } from 'react';
import React from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { useFieldContext } from '@balance-web/field';

export const Divider = (props: HTMLAttributes<HTMLDivElement>) => {
  const width = 1;
  const { colors } = useTheme();

  const { invalid } = useFieldContext();

  return (
    <div
      css={{
        alignSelf: 'center',
        backgroundColor: colors[invalid ? 'danger' : 'accent'],
        height: '100%',
        width,
      }}
      {...props}
    />
  );
};
Divider.displayName = 'RadioButtonGroupDivider';
