/** @jsx jsx */

import {
  ComponentProps,
  HTMLAttributes,
  MouseEventHandler,
  ReactElement,
  forwardRef,
  useCallback,
} from 'react';
import AccessibleTreeView from 'react-accessible-treeview';
import { CSSObject, jsx } from '@balance-web/core';
import { MinusSquareIcon } from '@balance-web/icon/icons/MinusSquareIcon';
import { PlusSquareIcon } from '@balance-web/icon/icons/PlusSquareIcon';
import { useTheme } from '@balance-web/theme';

type AccessibleTreeViewProps = ComponentProps<typeof AccessibleTreeView>;
type NodeRenderer = AccessibleTreeViewProps['nodeRenderer'];
type DefaultExpandedIds = AccessibleTreeViewProps['defaultExpandedIds'];

export type TreeViewProps<Node extends { children?: Node[] }> = {
  /** The leaf and branch nodes */
  data: Node[];
  /** Describe the tree content */
  label: string;
  /** Predicate to determine the selected node */
  isSelected?: (node: Node) => boolean;
  /** Callback that is called when a node is selected */
  onSelect?: (node: Node) => void;
  /** Render prop for each node */
  nodeRenderer: (node: Node) => ReactElement;
  /** Receives a list of ids and expand the tree */
  defaultExpandedIds?: DefaultExpandedIds;
};

/** The tree view presents a hierarchical list. */
export function TreeView<T>({
  data,
  label,
  isSelected,
  onSelect,
  nodeRenderer: consumerRenderer,
  defaultExpandedIds,
}: TreeViewProps<T>) {
  const treeStyles = useTreeStyles();

  const internalRenderer: NodeRenderer = useCallback(
    (renderProps) => {
      const {
        element,
        getNodeProps,
        handleSelect,
        handleExpand,
        isBranch,
        isExpanded,
        level,
      } = renderProps;

      // Remove unused class names. Accessibility props expected on wrapping
      // div, but we only want expand/collapse behaviour to be invoked by
      // the icon buton (mouse interaction only).
      const a11yProps = getNodeProps({
        onClick: handleSelect,
      });

      // Omit internal meta, only pass on consumer data as `node`
      const { children, id, parent, ...node } = element as any; // TODO: find time to sort out third-party types

      return (
        <Row
          isSelectable={Boolean(isSelected)}
          isSelected={isSelected && isSelected(node)}
          level={level}
          // Support interactive elements within a node
          onKeyDown={(e) => {
            if (e.target !== e.currentTarget) {
              e.stopPropagation();
            }
          }}
          {...a11yProps}
        >
          <Toggle
            isBranch={isBranch}
            isExpanded={isExpanded}
            onClick={(e) => {
              handleExpand(e);
              e.stopPropagation();
            }}
          />

          <div css={{ flex: 1 }}>{consumerRenderer(node)}</div>
        </Row>
      );
    },
    [isSelected, consumerRenderer]
  );

  return (
    <div css={treeStyles}>
      <AccessibleTreeView
        aria-label={label}
        data={data as any[]}
        onSelect={({ element, isSelected }) => {
          if (onSelect && isSelected) {
            const { children, id, parent, ...node } = element;
            onSelect(node);
          }
        }}
        nodeRenderer={internalRenderer}
        defaultExpandedIds={defaultExpandedIds}
      />
    </div>
  );
}

// Styled components
// ------------------------------

type ToggleProps = {
  isBranch: boolean;
  isExpanded: boolean;
  onClick: MouseEventHandler;
};
const Toggle = ({ isBranch, isExpanded, onClick }: ToggleProps) => {
  const theme = useTheme();
  const common = {
    height: theme.sizing.xsmall,
    width: theme.sizing.xsmall,
  };

  // Maintain even spacing/alignment for leaf nodes by always rendering an
  // element of the same dimensions
  if (!isBranch) {
    return <div css={common} />;
  }

  const Icon = isExpanded ? MinusSquareIcon : PlusSquareIcon;

  return (
    <button
      aria-label={isExpanded ? 'Collapse group' : 'Expand group'}
      onClick={onClick}
      type="button"
      tabIndex={-1} // keyboard navigation is handled by AccessibleTreeView
      css={{
        ...common,
        alignItems: 'center',
        background: 0,
        border: 0,
        color: theme.palette.text.dim,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
        position: 'relative',

        ':hover': {
          color: theme.palette.text.muted,
        },

        // Hitslop: increase the button's "clickable" area
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: -theme.spacing.small,
          left: -theme.spacing.small,
          right: -theme.spacing.small,
          top: -theme.spacing.small,
        },
      }}
    >
      <Icon size="small" />
    </button>
  );
};

type RowProps = {
  isSelectable?: boolean;
  isSelected?: boolean;
  level: number;
} & HTMLAttributes<HTMLDivElement>;
const Row = forwardRef<HTMLDivElement, RowProps>(
  ({ isSelectable, isSelected, level, ...props }, forwardedRef) => {
    const theme = useTheme();

    return (
      <div
        data-selectable={isSelectable}
        data-selected={isSelected}
        ref={forwardedRef}
        css={{
          alignItems: 'center',
          display: 'flex',
          paddingLeft: `calc(${theme.sizing.xsmall} * (${level} - 1))`,
          paddingBottom: theme.spacing.small,
          paddingTop: theme.spacing.small,

          '&[data-selectable="true"]': {
            cursor: 'pointer',

            ':hover, :focus': {
              backgroundColor: theme.palette.background.muted,
            },
            ':active': {
              backgroundColor: theme.palette.background.dim,
            },

            '&[data-selected="true"]': {
              backgroundColor: theme.palette.background.shade,
              cursor: 'pointer',
            },
          },
        }}
        {...props}
      />
    );
  }
);

// Styles
// ------------------------------

function useTreeStyles() {
  const theme = useTheme();
  const styles: CSSObject = {
    li: {
      borderTop: `1px solid ${theme.palette.global.border}`,
    },

    // Remove the "top" divider
    'ul[role="tree"] > li:first-child': {
      border: 0,
    },
  };

  return styles;
}
