import { ChangeEvent } from 'react';
import { useId } from '@balance-web/utils';

import { CheckboxGroupProps, CheckboxProps } from './types';

type StateHookProps = Omit<CheckboxGroupProps, 'children' | 'legend'>;

export function useCheckboxGroupState(props: StateHookProps) {
  const name = useId(props.name);
  const hasValue = (value: string) => props.value.includes(value);

  const state = {
    name,
    disabled: props.disabled,
    size: props.size,
    value: props.value,
    hasValue,
    onChange(event: ChangeEvent<HTMLInputElement>) {
      if (props.disabled) {
        return;
      }

      const inputValue = event.target.value;
      const newValue = hasValue(inputValue)
        ? props.value.filter(
            (existingValue: string) => existingValue !== inputValue
          )
        : props.value.concat(inputValue);

      props.onChange(newValue);
    },
  };

  return state;
}

type CheckboxGroupState = ReturnType<typeof useCheckboxGroupState>;
type ItemHookProps = Omit<CheckboxProps, 'children'>;

export function useCheckboxGroupItem(
  props: ItemHookProps,
  state: CheckboxGroupState
) {
  const checkboxValue = props.value;

  if (checkboxValue == null) {
    throw new Error(
      'Each <Checkbox> within a <CheckboxGroup> requires a `value` property.'
    );
  }

  const augmentedProps = {
    name: state.name,
    disabled: state.disabled,
    size: state.size,
    value: checkboxValue,
    checked: state.hasValue(checkboxValue),
    onChange: state.onChange,
  };

  return augmentedProps;
}
