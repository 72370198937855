import React, { forwardRef } from 'react';

import { TableHead, TableHeadProps } from './TableHead';
import { TableRow } from './TableRow';

export type TableHeaderProps = TableHeadProps;

export const TableHeader = forwardRef<HTMLDivElement, TableHeaderProps>(
  ({ children, ...props }, ref) => {
    return (
      <TableHead ref={ref} {...props}>
        <TableRow>{children}</TableRow>
      </TableHead>
    );
  }
);
