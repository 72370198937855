import { FormEnumLabelType, FormEnumType, FormEnumValueType } from './types';

type ValueArrayEnum<T extends { [P in keyof T]: T[P] } = any> = {
  [P in keyof T]: P;
};

type LabelArrayEnum<T extends { [P in keyof T]: T[P] } = any> = {
  [P in keyof T]: T[P];
};

export function typedEnumValues<T extends FormEnumType>(enumObject: T) {
  return Object.keys(enumObject) as Array<keyof T>;
}

export function typedEnumValuesAsEnum<T extends FormEnumType>(enumObject: T) {
  return typedEnumValues(enumObject).reduce(
    (results, key) => ({
      ...results,
      [key]: key,
    }),
    {}
  ) as ValueArrayEnum<T>;
}

export function typedEnumLabels<T extends FormEnumType>(enumObject: T) {
  return Object.values(enumObject) as Array<T[keyof T]>;
}

export function typedEnumLabelsAsEnum<T extends FormEnumType>(enumObject: T) {
  return typedEnumLabels(enumObject).reduce(
    (results, key) => ({
      ...results,
      [key]: key,
    }),
    {}
  ) as LabelArrayEnum<T>;
}
export function selectOptions<T extends FormEnumType>(enumObject: T) {
  return Object.keys(enumObject).map((eachKey: keyof T) => ({
    value: eachKey,
    label: enumObject[eachKey],
  }));
}

export function valueToLabel<T extends FormEnumType>(
  value: keyof T,
  enumObject: T
) {
  return enumObject[value];
}

export function labelToValue<T extends FormEnumType>(
  label: T[keyof T],
  enumObject: T
) {
  return Object.keys(enumObject).find((key) => enumObject[key] === label);
}

export function validateValue<T extends FormEnumType>(
  value: any,
  enumObject: T
): keyof T | undefined {
  return value in enumObject ? value : undefined;
}

export function isAValue<T extends FormEnumType>(
  value: any,
  enumObject: T
): value is FormEnumValueType<T> {
  return typedEnumValues(enumObject).includes(value);
}

export function isALabel<T extends FormEnumType>(
  label: any,
  enumObject: T
): label is FormEnumLabelType<T> {
  return typedEnumLabels(enumObject).includes(label);
}

export function createTypedEnum<T extends FormEnumType>(enumObject: T) {
  const labelsEnum = typedEnumLabelsAsEnum(enumObject);
  const valuesEnum = typedEnumValuesAsEnum(enumObject);
  const values = typedEnumValues(enumObject);
  const labels = typedEnumLabels(enumObject);

  return {
    formEnum: enumObject,
    values,
    valuesEnum,
    labels,
    labelsEnum,
    selectOptions: selectOptions(enumObject),
    valueToLabel: (value: FormEnumValueType<T>) =>
      valueToLabel(value, enumObject),
    labelToValue: (label: FormEnumLabelType<T>) =>
      labelToValue(label, enumObject),
    validateValue: (value: any): keyof T | undefined =>
      validateValue(value, enumObject),
    isAValue: (value: any): value is FormEnumValueType<T> =>
      isAValue(value, enumObject),
    isALabel: (label: any): label is FormEnumLabelType<T> =>
      isALabel(label, enumObject),
    assertIsValue: (value: any): asserts value is FormEnumValueType<T> => {
      if (!isAValue(value, enumObject))
        throw new Error(`${value} must be one of ${values.join(', ')}`);
    },
    assertIsLabel: (label: any): asserts label is FormEnumLabelType<T> => {
      if (!isALabel(label, enumObject))
        throw new Error(`${label} must be one of ${labels.join(', ')}`);
    },
  };
}
