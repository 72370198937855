import { useContext } from 'react';

import { PageBlockContext } from './PageBlockContext';

export const useDefaultPageBlockSize = () => {
  const context = useContext(PageBlockContext);
  if (!context)
    throw new Error(
      'useDefaultPageBlockSize can only be used within a <PageBlockProvider />'
    );

  return context;
};
