import { createContext, useContext } from 'react';

export type AlignmentType = 'left' | 'right';
export type TemplateType = [number, number];

type DataGroupContextType = { align: AlignmentType; template: TemplateType };

export const DataGroupContext = createContext<DataGroupContextType | null>(
  null
);

export const useDataGroupContext = () => {
  const ctx = useContext(DataGroupContext);

  if (!ctx) {
    throw Error('`DataRow` must be rendered inside a `DataGroup`.');
  }

  return ctx;
};
