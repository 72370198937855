import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePopper } from '@balance-web/popover';
import { useRawTheme } from '@balance-web/theme';

import { ComboboxLoadingState } from './types';

// Menu height
// ------------------------------

const MAX_MENU_HEIGHT = 360;

/** Ensure that x items always fill the menu. */
export function useMaxMenuHeight(itemHeight: number) {
  return useMemo(() => {
    let maxMenuHeight = 0;

    for (let index = 8; index > 0; index--) {
      let attempt = itemHeight * index;
      if (attempt < MAX_MENU_HEIGHT) {
        maxMenuHeight = attempt;
        break;
      }
    }

    return maxMenuHeight;
  }, [itemHeight]);
}

// Item status
// ------------------------------

type GetItemsStatusProps = {
  inputValue: string | null;
  itemCount: number;
  loadingState: ComboboxLoadingState;
};
export function getItemsStatus({
  inputValue,
  itemCount,
  loadingState,
}: GetItemsStatusProps) {
  if (loadingState === 'error') {
    return 'error';
  }
  if (inputValue?.length && !itemCount) {
    return 'noMatches';
  }

  return null;
}

// Hooks
// ------------------------------

/** Prepare config for the `useVirtual` hook. Needs themed defaults. */
export function useVirtualConfig(itemHeight: number) {
  const rawTheme = useRawTheme();

  return {
    estimateSize: useCallback(() => itemHeight, [itemHeight]),
    paddingEnd: rawTheme.spacing.xsmall,
    paddingStart: rawTheme.spacing.xsmall,
    overscan: 4,
  };
}

/** Tailor the popper handler for combobox. */
export function useComboboxPopper(isOpen: boolean) {
  const rawTheme = useRawTheme();

  // setup popper (position handling)
  const [anchorElement, popperAnchorRef] = useState<HTMLDivElement | null>(
    null
  );
  const [popoverElement, popperPopoverRef] = useState<HTMLDivElement | null>();

  const { styles, attributes, update } = usePopper(
    anchorElement,
    popoverElement,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, rawTheme.spacing.small],
          },
        },
      ],
    }
  );

  // update popper when it opens to get the latest placement.
  // necessary for prerendered popovers in modals etc.
  useEffect(() => {
    if (update && isOpen) {
      update();
    }
  }, [isOpen, update]);

  return {
    attributes,
    styles,
    popperAnchorRef,
    popperPopoverRef,
  };
}
