import { flattenTree } from 'react-accessible-treeview';
import cloneDeep from 'lodash.clonedeep';

// Thin abstraction over the lib's utility:
// - ensure a root node; irrelevant detail for consumers
// - clone data to avoid unexpected side-effects

export type FlattenedTreeNode = {
  children?: number[];
  id: number;
  parent: number | null;
};

export function flattenTreeData<T>(data: T[]): (T & FlattenedTreeNode)[] {
  return flattenTree({ name: '', children: cloneDeep(data) });
}
