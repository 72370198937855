/** @jsx jsx */
import React, { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { VisuallyHidden } from '@balance-web/a11y';
import { useTheme } from '@balance-web/theme';

import { GUTTER } from './constants';

type SegmentProps = {
  children: ReactNode;
  checked: boolean;
} & InputHTMLAttributes<HTMLInputElement>;
export const Segment = forwardRef<HTMLLabelElement, SegmentProps>(
  ({ children, ...props }, ref) => {
    const { palette, radii, spacing } = useTheme();

    return (
      <label
        ref={ref}
        data-checked={props.checked}
        css={{
          alignItems: 'center',
          justifyContent: 'center',
          appearance: 'none',
          backgroundClip: 'padding-box',
          borderRadius: `calc(${radii.small} - ${GUTTER})`,
          boxSizing: 'border-box',
          color: palette.segmentedControl.text,
          cursor: 'pointer',
          display: 'inline-flex',
          whiteSpace: 'nowrap',
          flex: 1,
          outline: 0,
          paddingBottom: 0,
          paddingLeft: spacing.medium,
          paddingRight: spacing.medium,
          paddingTop: 0,
          position: 'relative',
          userSelect: 'none',
          zIndex: 2,
          '&:hover': {
            color: palette.segmentedControl.textFocused,
          },
          '&:active': {
            color: palette.segmentedControl.textPressed,
          },
          '&[data-checked="true"]': {
            color: palette.segmentedControl.textSelected,
          },
        }}
      >
        <VisuallyHidden as="input" type="radio" {...props} />
        {children}
      </label>
    );
  }
);
Segment.displayName = 'SegmentedControlSegment';
