/** @jsx jsx */

import {
  ChangeEventHandler,
  ClipboardEventHandler,
  FocusEventHandler,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { jsx } from '@balance-web/core';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { useFieldContext } from '@balance-web/field';
import { InputBaseProps, useInputStyles } from '@balance-web/text-input';

import {
  durationStringToMinutes,
  isValidInput,
  minutesToDurationString,
} from './utils';

type ValidNativeInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'value' | 'onChange' | 'size'
>;
type ValidBalanceInputProps = Partial<Omit<InputBaseProps, 'shape'>>;

export type DurationInputProps = WithDataAttributeProp<
  {
    /** Called when duration input value changes */
    onChange: (minutes?: number) => void;
    /** Time duration value */
    value?: string | number;
    /** Display format for the input */
    display?: 'time' | 'duration';
  } & ValidNativeInputProps &
    ValidBalanceInputProps
>;

export const DurationInput = forwardRef<HTMLInputElement, DurationInputProps>(
  (
    {
      placeholder = '0:00',
      onChange,
      onBlur,
      value,
      display = 'time',
      size = 'medium',
      data,
      ...consumerProps
    },
    ref
  ) => {
    const _value = value?.toString().length ? Number(value) : undefined;

    const { invalid, ...a11yProps } = useFieldContext();
    const [internalValue, setInternalValue] = useState(
      minutesToDurationString(_value, display)
    );

    /** This is to detect value changes from the form rather than user input for
     *  eaxmple resetting form values or discarding previous state onClick. */
    const previousInternalValue = useRef<string | undefined>(
      minutesToDurationString(_value, display)
    );

    // sync internal value
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setInternalValue(e.target.value);
    };

    // format input
    const formatInput = (input: string) => {
      if (isValidInput(input)) {
        const minutes = durationStringToMinutes(input);
        setInternalValue(minutesToDurationString(minutes, display));
        // NOTE: invalid input --> reformat external (consumer) value
      } else if (input.length) {
        setInternalValue(minutesToDurationString(_value, display));
      }
    };
    const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      onBlur && onBlur(e);
      formatInput(e.target.value);
    };
    const handlePaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
      formatInput(e.clipboardData.getData('text'));
    };

    // sync external (consumer) value
    useEffect(() => {
      if (!internalValue.length && _value !== undefined) {
        onChange(undefined);
      }
      // when change comes from value
      else if (
        previousInternalValue.current !== undefined &&
        durationStringToMinutes(internalValue) ===
          durationStringToMinutes(previousInternalValue.current) &&
        durationStringToMinutes(internalValue) !== _value
      ) {
        onChange(_value);
        const newInternalValue = minutesToDurationString(_value, display);
        previousInternalValue.current = newInternalValue;
        setInternalValue(newInternalValue);
      }
      // when change comes from user input
      else if (isValidInput(internalValue)) {
        const minutes = durationStringToMinutes(internalValue);
        if (minutes !== _value) {
          previousInternalValue.current = internalValue;
          onChange(minutes);
        }
      }
    }, [internalValue, onChange, _value, display]);

    const inputStyles = useInputStyles({
      shape: 'square',
      size,
    });

    const dataAttributes = buildDataAttributes(data);

    return (
      <input
        aria-invalid={invalid}
        css={{
          ...inputStyles,
          fontVariantNumeric: 'tabular-nums', // ensure the numbers line up when stacked
        }}
        ref={ref}
        placeholder={placeholder}
        value={internalValue}
        onBlur={handleBlur}
        onChange={handleChange}
        onPaste={handlePaste}
        {...dataAttributes}
        {...consumerProps}
        {...a11yProps}
      />
    );
  }
);
