import type { ApolloError } from '@apollo/client';

import type { ForceTypeName } from './assertTypeName';
import { assertTypeName } from './assertTypeName';

/**
 * This function transforms the result of useQuery into discrete useable chunks instead of a mixed blob of undefined, null and concrete objects.
 * We do this to minimize typescript gymnastics when using the results of a query in our components.
 *
 * Here's a common scenario:
 *
 * ```tsx
 *   const { data, loading, error } = useQuery(someQuery)
 *   if(loading) return <Loading />
 *   return <SomeComponent data={data} />
 * ```

 * Above code has a few issues:
 * 1. If SomeComponent expects a concrete type i.e not null or undefined, the above code will result in a ts error.
 * 2. To deal with `1`, you either have to check that data is in fact a concrete type or let `SomeComponent` accept undefined and `null` and then filter inside.
 *
 * This results in a lot of variation when dealing with such scenarios and it's just a pain to write if statements all the time.
 *
 * This function transforms the results such that the original code does not result in a ts error if `SomeComponent` expects a concrete object.
 *
 * ```ts
 * const result = useQuery(someQuery)
 * const betterResult = queryResponse(result) // Usually you would use this inside your useFetchSomethingHook() so real life usage won't be this ugly.
 *
 * if(betterResult.error) {
 *  return <Error error={error}/> // error = concrete, loading = undefined, data = undefined
 * }
 *
 * if(betterResult.loading) {
 *  return <Loading /> // loading = true, data = undefined, error = undefined
 * }
 *
 * return <SomeComponent data={betterResult.data} /> // loading = false, error = undefined, data = concrete
 * ```
 * You can optionally pass in the skip parameter. If skip is a boolean, you'll have to check it as well for the typing to continue to work like above.
 *
 * if(betterResult.skip) {
 * // loading, error, data = undefined
 *  return null
 * }
 *
 * // rest of the typing should be exactly like above.
 *
 * Soooo what's the catch? You can't destructure query results because you lose ts union checks after destructuring.
 *
 * This is an OK trade off because most of the time you'll be prefixing the destructured objects anyway like `employeeData`, `employeeLoading`, `employeeError`.
 * Now you just go `employee.data`, `employee.loading`, `employee.error`.
 */
export const queryResponse = <TData, TError extends ApolloError, TSkip>(r: {
  data?: TData | null;
  error?: TError;
  skip?: TSkip | undefined;
}): QueryResponseType<TData, TError, TSkip> => {
  const shouldSkip = typeof r.skip === 'boolean' && r.skip === true;
  if (shouldSkip) {
    return ({
      skip: r.skip,
      error: undefined,
      loading: undefined,
      data: undefined,
    } as unknown) as QueryResponseType<TData, TError, TSkip>;
  }

  if (r.error !== undefined) {
    return ({
      skip: shouldSkip,
      error: r.error,
      loading: undefined,
      data: undefined,
    } as unknown) as QueryResponseType<TData, TError, TSkip>;
  }

  if (!r.data && !r.skip) {
    return ({
      skip: false,
      loading: true,
      data: undefined,
      error: undefined,
    } as unknown) as QueryResponseType<TData, TError, TSkip>;
  }

  return ({
    skip: shouldSkip,
    loading: false,
    data: r.data,
    error: undefined,
  } as unknown) as QueryResponseType<TData, TError, TSkip>;
};

/**
 * A version of queryResponse that is only meant to be used
 * with node queries. It helps assert the node accurately.
 */
export const nodeQueryResponse = <
  TData extends {
    __typename: 'Query';
    node: { __typename: string } | null | undefined;
  },
  TypeName extends NonNullable<TData['node']>['__typename'],
  TError extends ApolloError,
  TSkip
>(
  r: {
    data?: TData | null;
    error?: TError;
    skip?: TSkip | undefined;
  },
  typeName: TypeName
): QueryResponseType<
  NonNullable<ForceTypeName<TData['node'], TypeName>>,
  TError,
  TSkip
> => {
  if (typeof r.skip === 'boolean' && r.skip === true) {
    return ({
      skip: r.skip,
      error: undefined,
      loading: undefined,
      data: undefined,
    } as unknown) as QueryResponseType<
      NonNullable<ForceTypeName<TData['node'], TypeName>>,
      TError,
      TSkip
    >;
  }

  if (r.error !== undefined) {
    return ({
      skip: undefined,
      error: r.error,
      loading: undefined,
      data: undefined,
    } as unknown) as QueryResponseType<
      NonNullable<ForceTypeName<TData['node'], TypeName>>,
      TError,
      TSkip
    >;
  }

  if (!r.data) {
    return ({
      skip: undefined,
      loading: true,
      data: undefined,
      error: undefined,
    } as unknown) as QueryResponseType<
      NonNullable<ForceTypeName<TData['node'], TypeName>>,
      TError,
      TSkip
    >;
  }

  assertTypeName(r.data.node, typeName, !!r.skip);

  return ({
    skip: undefined,
    loading: false,
    error: undefined,
    data: r.data.node,
  } as unknown) as QueryResponseType<
    NonNullable<ForceTypeName<TData['node'], TypeName>>,
    TError,
    TSkip
  >;
};

// Manually type all possible responses
type QueryResponseType<
  // This will be propagated as undefined or concrete based on loading/error states
  TData,
  TError extends ApolloError,
  TSkip
> = TSkip extends boolean
  ?
      | {
          // skip = true, everything is undefined
          readonly skip: true;
          readonly error: undefined;
          readonly loading: undefined;
          readonly data: undefined;
          readonly __dataType: NonNullable<TData>;
        }
      | {
          // skip = false, loading state
          readonly skip: false;
          readonly error: undefined;
          readonly loading: true;
          readonly data: undefined;
          readonly __dataType: NonNullable<TData>;
        }
      | {
          // skip = false, error state
          readonly skip: false;
          readonly error: TError;
          readonly loading: undefined;
          readonly data: undefined;
          readonly __dataType: NonNullable<TData>;
        }
      | {
          // skip = false, data available state
          readonly skip: false;
          readonly error: undefined;
          readonly loading: false;
          readonly data: TData;
          readonly __dataType: NonNullable<TData>;
        }
  :
      | {
          // no skip, loading state
          readonly skip: undefined;
          readonly error: undefined;
          readonly loading: true;
          readonly data: undefined;
          readonly __dataType: NonNullable<TData>;
        }
      | {
          // no skip, error state
          readonly skip: undefined;
          readonly error: TError;
          readonly loading: undefined;
          readonly data: undefined;
          readonly __dataType: NonNullable<TData>;
        }
      | {
          // no skip, data available state
          readonly skip: undefined;
          readonly error: undefined;
          readonly loading: false;
          readonly data: TData;
          readonly __dataType: NonNullable<TData>;
        };
