/** @jsx jsx */

import { HTMLAttributes, ReactNode, forwardRef, memo } from 'react';
import { jsx } from '@balance-web/core';
import { CheckIcon } from '@balance-web/icon/icons/CheckIcon';
import { ChevronDownIcon } from '@balance-web/icon/icons/ChevronDownIcon';
import { XIcon } from '@balance-web/icon/icons/XIcon';
import { LoadingDots } from '@balance-web/loading';
import { useTheme } from '@balance-web/theme';
import { useInputStyles } from '@balance-web/text-input';

import { useComboboxContext } from './context';

// ==============================
// CONTROLS
// ==============================

// Container
// ------------------------------

export const InputContainer = forwardRef<HTMLDivElement>(
  (props, forwardedRef) => {
    const { disabled, isFocused, isOpen, size } = useComboboxContext();
    const {
      paddingLeft: _unused1,
      paddingRight: _unused2,

      ...inputStyles
    } = useInputStyles({ shape: 'square', size });

    // NOTE: returned input styles are expecting to be passed onto an `<input/>`
    // element, so we have to do a bit of weirdness that remaps pseudo-classes
    const focusStyles =
      isOpen || isFocused
        ? {
            ...inputStyles[':focus'],
            ':focus-within[data-invalid=true]':
              inputStyles['&[data-invalid=true], &[aria-invalid=true]'][
                ':focus'
              ],
            ':hover': inputStyles[':focus'],
          }
        : null;
    const disabledStyles = disabled
      ? {
          ...inputStyles[':disabled'],
          ':hover': inputStyles[':disabled'],
        }
      : null;

    return (
      <div
        ref={forwardedRef}
        css={{
          display: 'flex',
          ...inputStyles,
          ...focusStyles,
          ...disabledStyles,
        }}
        {...props}
      />
    );
  }
);

// Input
// ------------------------------

export const Input = forwardRef<HTMLInputElement>((props, forwardedRef) => {
  const { size } = useComboboxContext();
  const { paddingLeft } = useInputStyles({
    shape: 'square',
    size,
  });

  return (
    <input
      ref={forwardedRef}
      spellCheck={false}
      css={{
        background: 0,
        border: 0,
        color: 'inherit',
        flex: 1,
        font: 'inherit',
        outline: 0,
        paddingLeft,
        width: '100%',
      }}
      {...props}
    />
  );
});

// Divider
// ------------------------------

export const InputDivider = () => {
  return (
    <div
      role="presentation"
      css={{
        alignSelf: 'center',
        backgroundColor: 'currentColor',
        flexShrink: 0,
        height: '66%',
        opacity: 0.1,
        width: 1,
      }}
    />
  );
};

// Buttons
// ------------------------------

function useInputAccessoryStyles() {
  return {
    alignItems: 'center',
    aspectRatio: '1 / 1',
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
  } as const;
}

function useInputButtonStyles() {
  const accessoryStyles = useInputAccessoryStyles();
  return {
    ...accessoryStyles,
    color: 'inherit',
    opacity: 0.5,
    padding: 0,
    outline: 0,

    ':hover': { opacity: 1 },
  } as const;
}

type InputToggleButtonProps = HTMLAttributes<HTMLButtonElement>;
export const InputToggleButton = forwardRef<
  HTMLButtonElement,
  InputToggleButtonProps
>((props, forwardedRef) => {
  const buttonStyles = useInputButtonStyles();
  const iconSize = 20; // the chevron needs to be bumped up so it looks visually comparable with the clear button's "×" icon
  return (
    <button ref={forwardedRef} type="button" css={buttonStyles} {...props}>
      <ChevronDownIcon size={iconSize} />
    </button>
  );
});

type InputClearButtonProps = HTMLAttributes<HTMLButtonElement>;
export const InputClearButton = forwardRef<
  HTMLButtonElement,
  InputClearButtonProps
>((props, forwardedRef) => {
  const buttonStyles = useInputButtonStyles();
  return (
    <button
      ref={forwardedRef}
      type="button"
      css={buttonStyles}
      tabIndex={-1} // for users of assistive-tech, clearing is handled by pressing the `ESC` key when the input is focused
      {...props}
    >
      <XIcon size="small" />
    </button>
  );
});

// Loading Indicator
// ------------------------------

export const InputLoadingIndicator = () => {
  const accessoryStyles = useInputAccessoryStyles();
  return (
    <div css={[accessoryStyles, { opacity: 0.5 }]}>
      <LoadingDots label="fetching items" size="small" color="inherit" />
    </div>
  );
};

// ==============================
// DIALOG
// ==============================

// Menu
// ------------------------------

type MenuProps = {
  children: ReactNode;
};

export const Menu = forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
  return (
    <div
      ref={ref}
      css={{
        outline: 0,
        overflowY: 'auto',
        position: 'relative',
        WebkitOverflowScrolling: 'touch',
      }}
      {...props}
    />
  );
});

// Menu Item
// ------------------------------

type MenuItemProps = {
  /** Item content */
  children: ReactNode;
  /** Determines if item is disabled */
  disabled?: boolean;
  /** Give the item a selected appearance */
  isSelected?: boolean;
  /** Rerender menu item when children change */
  isMemoized?: boolean;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Container for the consumer's item component. Implements downshift's handlers
 * and Balance's default styles.
 */
export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  (props, ref) => {
    const {
      'aria-selected': ariaSelected,
      children,
      disabled,
      isSelected,
      isMemoized = true, // By default the item is memoized to avoid unnecessary re-renders
      ...nonMemoizableProps
    } = props;

    return (
      <div ref={ref} css={{ cursor: 'pointer' }} {...nonMemoizableProps}>
        {isMemoized && (
          <MenuItemInnerMemoized
            aria-selected={ariaSelected}
            aria-disabled={disabled}
            data-balance-selected={isSelected}
          >
            <SelectionIndicator isSelected={isSelected} />
            {children}
          </MenuItemInnerMemoized>
        )}

        {!isMemoized && (
          <MenuItemInner
            aria-selected={ariaSelected}
            aria-disabled={disabled}
            data-balance-selected={isSelected}
          >
            <SelectionIndicator isSelected={isSelected} />
            {children}
          </MenuItemInner>
        )}
      </div>
    );
  }
);

const MenuItemInner = (props: MenuItemProps) => {
  const { palette, spacing } = useTheme();
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        minWidth: 0, // resolve flex issues with text trucation
        paddingLeft: spacing.medium,
        paddingRight: spacing.medium,

        // NOTE: the `style` prop applies a pixel height to the parent,
        // necessary for virtualization.
        height: '100%',

        // NOTE: Safeguard against consumers trying to add interactive elements
        // within menu items. Handlers are applied to the parent.
        pointerEvents: 'none',

        ':active': {
          // backgroundColor: palette.menuItem.backgroundPressed,
          backgroundColor: 'red',
        },

        // the highlighted styles for hover and faux-focus
        '&[aria-selected=true]': {
          backgroundColor: palette.menuItem.backgroundFocused,
          outline: 'none !important',
        },
        // the currently selected item(s)
        '&[data-balance-selected=true]': {
          backgroundColor: palette.menuItem.backgroundSelected,
        },
        '&[aria-disabled=true]': {
          color: palette.text.dim,
          pointerEvents: 'none',
        },
      }}
      {...props}
    />
  );
};

/**
 * Memoized layout component, only receives props we control + know will be
 * safe. Unsafe props are deconstructed and applied to the parent.
 */
const MenuItemInnerMemoized = memo(MenuItemInner, equalWithoutChildren);

/**
 * This approach would be a little dangerous under different circumstances. In
 * this case we control the composition and implement safeguards.
 */
function equalWithoutChildren(prev: Readonly<any>, next: Readonly<any>) {
  for (let k in prev) {
    if (k === 'children') continue;
    if (prev[k] !== next[k]) return false;
  }
  return true;
}

// Selection Indicator
// ------------------------------

type IndicatorProps = { isSelected?: boolean };
const SelectionIndicator = memo(({ isSelected }: IndicatorProps) => {
  const theme = useTheme();

  let selectedColor = theme.palette.text.link;
  let gutter = theme.spacing.small;

  // always render the wrapper so siblings don't move around when items are
  // selected vs not-selected. instead, we toggle the icon color for show/hide
  return (
    <span
      css={{
        color: isSelected ? selectedColor : 'transparent',
        flexShrink: 0,
        marginRight: gutter,
      }}
    >
      <CheckIcon size="small" />
    </span>
  );
});

// Item Slot
// ------------------------------

/**
 * Component to fill the space where items would typically live within the menu.
 * Used for things like "no results" messages etc.
 */
export const MenuItemSlot = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      {...props}
    />
  );
};

// Loading Indicator
// ------------------------------

/**
 * Component shown at the bottom of a paginated list, revealed when more items
 * are loading.
 */
export const MenuItemLoadingIndicator = (
  props: HTMLAttributes<HTMLDivElement>
) => {
  return (
    <MenuItemSlot {...props}>
      <LoadingDots label="loading more" color="dim" />
    </MenuItemSlot>
  );
};
