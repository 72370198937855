import { useTheme } from '@balance-web/theme';
import { useMemo } from 'react';

export const useButtonBorderRadius = ({
  index,
  optionCount,
}: {
  index?: number;
  optionCount: number;
}) => {
  const { radii } = useTheme();

  const buttonBorderRadius = useMemo(() => {
    if (index !== undefined && optionCount !== undefined) {
      switch (optionCount) {
        case 2:
          switch (index) {
            case 0:
              return `${radii.small} 0 0 ${radii.small}`;
            case 1:
              return `0 ${radii.small} ${radii.small} 0`;
          }
          break;
        case 3:
          switch (index) {
            case 0:
              return `${radii.small} 0 0 ${radii.small}`;
            case 1:
              return `0`;
            case 2:
              return `0 ${radii.small} ${radii.small} 0`;
          }
          break;
        default:
          throw Error('RadioButtonGroup must have at 2 or 3 options');
      }
    }
  }, [index, optionCount, radii.small]);

  return buttonBorderRadius;
};
