import isObject from 'lodash/isObject';

import { WizardType } from './types';

export function assertIsWizard<
  TSteps,
  TAdditionalState extends object | void = void
>(wizard: any): asserts wizard is WizardType<TSteps, TAdditionalState> {
  const hasId = 'id' in wizard;
  const hasStepsState = 'stepsState' in wizard;
  const hasCurrentStep = 'currentStep' in wizard;
  const hasAdditionalState = 'additionalState' in wizard;
  if (!hasId || !hasStepsState || !hasCurrentStep)
    throw new Error('provided object is not a wizard schema');
  if (hasAdditionalState && !isObject(wizard.additionalState))
    throw new Error(
      'provided object has an invalid form of additional state. must be an object.'
    );
}
