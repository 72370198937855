/** @jsx jsx */
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { useMemo } from 'react';
import { Box } from '@balance-web/box';
import { Text } from '@balance-web/text';

import {
  LARGE_ROPE_MARKER_DIMENSIONS,
  PROGRESS_ROPE_MARKER_ZINDEX,
  SMALL_ROPE_MARKER_DIMENSIONS,
  useColors,
} from './common';

type StepItemProps = {
  title: string;
  status: 'active' | 'inactive' | 'complete';
  child?: boolean;
};

export const StepItem = (props: StepItemProps) => {
  const theme = useTheme();
  const colors = useColors();

  const itemTextStyles = useMemo(() => {
    const fontSize = props.child
      ? theme.typography.fontSize.xsmall
      : theme.typography.fontSize.small;
    const color =
      props.status === 'active'
        ? colors.active
        : props.status === 'complete'
        ? colors.complete
        : colors.inactive;

    return {
      fontSize,
      color,
      transition: 'color 300ms ease-out',
    };
  }, [
    props.child,
    props.status,
    theme.typography.fontSize.xsmall,
    theme.typography.fontSize.small,
    colors.active,
    colors.complete,
    colors.inactive,
  ]);

  return (
    <Box position="relative" paddingLeft={props.child ? 'large' : undefined}>
      <Text
        size={props.child ? 'xsmall' : 'small'}
        weight={props.status === 'active' ? 'bold' : 'regular'}
        css={itemTextStyles}
      >
        {props.title}
      </Text>

      {/* Progress markers for step and child-step */}
      <div
        style={{
          width: props.child
            ? SMALL_ROPE_MARKER_DIMENSIONS
            : LARGE_ROPE_MARKER_DIMENSIONS,
          height: props.child
            ? SMALL_ROPE_MARKER_DIMENSIONS
            : LARGE_ROPE_MARKER_DIMENSIONS,
          borderRadius: '100%',
          position: 'absolute',
          // Horizontally center marker on progress rope
          right: props.child ? -5 : -11,
          // Progress marker for child items is smaller than the item so it needs to be centered vertically by hand
          top: props.child ? 4 : 0,
          zIndex: PROGRESS_ROPE_MARKER_ZINDEX,
          background:
            props.status === 'active' || props.status === 'complete'
              ? colors.active
              : colors.light,
        }}
      />
    </Box>
  );
};
