/** @jsx jsx */

import { HTMLAttributes } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { Box } from '@balance-web/box';

type Tone = 'neutral' | 'critical';
function usePreStyles(tone: Tone) {
  const { palette, typography } = useTheme();
  const { background, color } = {
    neutral: {
      background: palette.background.muted,
      color: palette.text.base,
    },
    critical: {
      background: palette.background.critical,
      color: palette.text.critical,
    },
  }[tone];
  return {
    background,
    fontFamily: typography.fontFamily.monospace,
    fontSize: typography.fontSize.small,
    color,
    lineHeight: 1.4,
    maxWidth: '100%',
    overflow: 'auto',

    code: {
      fontFamily: 'inherit',
    },
  };
}
type PreProps = { tone?: Tone } & HTMLAttributes<HTMLPreElement>;
export const Pre = ({ tone = 'neutral', ...props }: PreProps) => {
  const styles = usePreStyles(tone);

  return (
    <Box as="pre" paddingX="large" paddingY="medium" css={styles} {...props} />
  );
};
