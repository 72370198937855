import * as React from 'react';

import { createIllustration } from './Illustration';

function describeWithOptions(name: string, description: string) {
  return {
    name,
    description,
    height: 240,
    width: 376,
  };
}

// ------------------------------
// Auth
// ------------------------------

export const HeroAuth = createIllustration(
  describeWithOptions(
    'HeroAuth',
    'Illustration of a man carrying an enormous padlock on his back.'
  ),
  <React.Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5 185.737c0-41.802 21.172-59.315 73.727-71.116 52.588-11.611 59.558-52.547 127.812-52.547 68.442 0 136.329 81.223 90.947 148.471-45.364 67.314-149.309-13.477-190.578 17.008-41.35 30.341-101.908-.131-101.908-41.816z"
      fill="#F2F3F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M230.375 88.016l6.501-31.143c4.626-22.164-9.58-43.878-31.731-48.499-22.151-4.621-43.858 9.6-48.485 31.764l-6.11 29.27 79.825 18.608zm-28.091-65.939c14.587 3.043 23.943 17.342 20.896 31.938l-6.433 30.819-52.566-12.254 6.175-29.586c3.047-14.595 17.342-23.96 31.928-20.917z"
      fill="#393E41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.699 159.094c28.637 5.979 56.698-12.398 62.674-41.045 5.976-28.647-12.395-56.715-41.033-62.693-28.638-5.978-56.698 12.398-62.674 41.045-5.976 28.647 12.395 56.715 41.033 62.693z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.578 101.053a12.562 12.562 0 01-1.692-9.32c1.421-6.81 8.091-11.179 14.9-9.758 6.808 1.422 11.175 8.095 9.755 14.905a12.554 12.554 0 01-5.278 7.864l-.382 16.12c-.159 6.711-5.727 12.023-12.436 11.864a12.253 12.253 0 01-2.194-.253c-6.57-1.372-10.784-7.811-9.413-14.382.151-.723.367-1.43.646-2.113l6.094-14.927z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M212.97 239.221c0 .43-16.741.779-37.393.779-20.651 0-37.392-.349-37.392-.779 0-.431 16.741-.78 37.392-.78 20.652 0 37.393.349 37.393.78z"
      fill="#E4E7EA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.26 160.43l-6.394-2.307s-5.176 3.247-6.621 3.171c0 0-4.49-4.586-5.408-8.844l2.708-6.245 7.67-2.832 3.9 6.334c-.134-1.323 2.748-4.368 4.766-2.184 2.603 2.815-1.423 6.205-1.423 6.205l3.412.776-2.61 5.926z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.253 147.161s2.476-3.271-3.579-5.603c-6.054-2.333-8.716 1.461-8.716 1.461s-3.01-3.783-5.867-2.496c-4.072 1.833-1.832 8.278-1.832 8.278s-2.579-2.899-3.525-1.899c-.946.999 3.166 12.611 16.872-.042 0 0-.41 4.161 3.599 3.13 0 0 .406-2.413 3.048-2.829z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.629 154.485l.154 3.117 1.404-.578-1.558-2.539z"
      fill="#F49E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M133.567 154.571a.78.78 0 101.448-.578.78.78 0 00-1.448.578zM130.45 155.35a.779.779 0 101.448-.576.779.779 0 00-1.448.576z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.301 158.172l3.116-3.11-1.416 3.896-1.7-.786z"
      fill="#CA9783"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M155.323 239.221c3.761-2.526 12.247-4.242 12.247-4.242l-.441-2.771h4.348l.984 7.013h-17.138z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.003 230.649l.458 3.744-4.943.152-.51-2.566 4.995-1.33z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M181.809 239.221c3.46-2.636 10.406-4.31 10.406-4.31l-1.219-2.703h4.749l3.203 7.013h-17.139z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M194.678 229.656l2.068 4.827-4.786.538-1.419-3.116 4.137-2.249z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.598 176.356l-11.307 22.459a4.811 4.811 0 00-.485 2.702l4.305 30.691 9.421-.02-2.537-29.519 15.056-22.484s4.41-10.417-5.38-16.551"
      fill="#121212"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.019 174.902l.135 25.493a9.35 9.35 0 001.104 4.361l14.033 28.231 8.099-3.11-11.858-27.33 2.742-20.894s2.511-14.528-6.337-18.019"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.399 154.335c-1.495.064-2.3 1.323-2.785 2.733-1.429 4.152.755 7.861 7.322 12.121 6.33 4.106 19.569 7.692 19.569 7.692l9.304-13.281s-16.489-7.421-23.575-8.727c-3.554-.656-7.443-.64-9.835-.538z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M187.263 158.714l-.593 4.14-18.874-3.929-11.44 3.008-5.707-8.925s11.679-1.68 14.776-1.84c3.097-.158 21.838 7.546 21.838 7.546z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M187.886 163.062s-.057-.009-1.51-.339c-1.799-.409.588-3.983.588-3.983l1.504.452s1.905-2.414 2.242-3.665c.337-1.252.588.42.588.42s-.658 5.287-3.412 7.115z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.934 157.554l-.779 1.558-.616-.308.779-1.559.616.309z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.754 155.897s2.642-5.211 12.881-4.696l.362 11.49-5.99 1.722-7.253-8.516z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.679 163.728l6.233-.779.171 1.369-6.233.779-.171-1.369z"
      fill="#000"
      fillOpacity={0.05}
    />
  </React.Fragment>
);

// ------------------------------
// Pending
// ------------------------------

export const HeroPending = createIllustration(
  describeWithOptions(
    'HeroPending',
    'Illustration of a man sitting at a desk, in front of his computer screen.'
  ),
  <React.Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M247.457 9.105c48.528 23.794 71.694 81.252 59.739 134.048-10.077 44.507-59.998 58.05-104.952 65.634-46.507 7.846-101.881 14.236-125.075-26.827-25.208-44.629-2.407-98.57 33.601-135.12 36.701-37.255 89.802-60.723 136.687-37.735z"
      fill="#F2F3F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.405 239.191c0 .447 49.868.809 111.384.809s111.385-.362 111.385-.809c0-.447-49.869-.809-111.385-.809-61.516 0-111.384.362-111.384.809z"
      fill="#DFE1E3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.619 239.274h4.055v-81.231h-4.055v81.231z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.564 239.274h4.055v-81.231h-4.055v81.231z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M197.466 239.274h4.055v-81.231h-4.055v81.231z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M193.411 239.274h4.055v-81.231h-4.055v81.231z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.619 192.172h31.847v-34.129h-31.847v34.129z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.703 158.043h106.43v-5.403h-106.43v5.403z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M249.189 158.043h42.857v-5.403h-42.857v5.403zm27.856 81.231h4.336v-81.231h-4.336v81.231z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M272.709 239.274h4.336v-81.231h-4.336v81.231z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M242.859 239.274h4.336v-81.231h-4.336v81.231z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M242.859 199.287h34.186v-41.22h-34.186v41.22z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M210.194 199.268h28.329v-41.22h-28.329v41.22zm28.329 40.006h4.336v-81.231h-4.336v81.231z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M238.523 165.266h42.858v-7.223h-42.858v7.223zm-76.959 0h39.956v-7.223h-39.956v7.223z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M214.77 176.259h19.177v-14.286H214.77v14.286zm0 18.108h19.177v-14.286H214.77v14.286z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.362 169.116c0 1.692-1.344 3.064-3.003 3.064s-3.004-1.372-3.004-3.064 1.345-3.064 3.004-3.064 3.003 1.372 3.003 3.064zm0 18.108c0 1.692-1.344 3.064-3.003 3.064s-3.004-1.372-3.004-3.064 1.345-3.064 3.004-3.064 3.003 1.372 3.003 3.064zm12.48-91.451l-9.229 34.068-2.191 8.09-.538 1.989-3.446 12.72h-1.444v.016h-13.817l.005-.016.349-1.285 3.096-11.435.539-1.989 2.191-8.09 9.229-34.068h15.256z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M237.497 95.773l-9.229 34.068-2.191 8.09-.538 1.989-3.097 11.435-.349 1.285-.005.016h-15.256l.004-.016.349-1.285 3.097-11.435.538-1.989.432-1.587.293-1.087.571-2.108.271-.999.624-2.309 9.229-34.068h15.257z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.574 152.656h30.075v-1.301h-30.075v1.301z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M212.422 152.656h10.572v-1.301h-10.572v1.301z"
      fill="#585C62"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.623 79.268h78.482c.777 0 1.338.78 1.126 1.565l-15.763 58.195c-.143.528-.603.892-1.127.892H173.86c-.777 0-1.339-.78-1.127-1.564l15.764-58.195c.143-.529.603-.893 1.126-.893z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M188.406 79.268h77.209c.962 0 1.656.965 1.394 1.935l-15.416 56.911c-.289 1.069-1.22 1.806-2.279 1.806h-75.99c-1.572 0-2.709-1.578-2.279-3.166l15.082-55.68c.29-1.068 1.22-1.806 2.279-1.806z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.096 81.992h74.627l-15.152 55.938h-74.627l15.152-55.938z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M253.835 129.84l-2.241 8.274c-.289 1.069-1.22 1.807-2.279 1.807h-75.991c-1.572 0-2.709-1.579-2.279-3.167l1.873-6.914h80.917z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.574 152.656h32.42v-.016h-32.42v.016z"
      fill="#353543"
    />
    <path
      opacity={0.3}
      d="M211.188 108.787c-.817 1.051-2.048 1.461-2.749.917-.701-.545-.606-1.839.211-2.89.818-1.052 2.049-1.462 2.749-.917.701.545.606 1.839-.211 2.89z"
      fill="#909396"
    />
    <path
      opacity={0.6}
      d="M218.416 108.813c-.817 1.051-2.048 1.462-2.749.917-.701-.545-.606-1.839.211-2.89.818-1.051 2.048-1.462 2.749-.917.701.545.606 1.839-.211 2.89z"
      fill="#909396"
    />
    <path
      d="M225.644 108.839c-.818 1.051-2.048 1.462-2.749.917-.701-.545-.606-1.839.211-2.89.818-1.051 2.048-1.462 2.749-.917.701.545.606 1.839-.211 2.89z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.56 153.913l-4.089-10.912.891-3.493 10.162-1.55s13.834 28.85-.279 25.641"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.829 185.462l7.953 24.18-3.768 1.234-7.952-24.179 3.767-1.235z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.894 208.903l20.723-24.112 3.01 2.577-20.723 24.112-3.01-2.577z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.598 211.465l-29.718-23.843 2.484-3.085 29.718 23.843-2.484 3.085z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.649 208.385l29.439-23.843 2.499 3.074-29.439 23.843-2.499-3.074z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.689 186.079h3.359l14.376 53.108h-3.358l-14.377-53.108zm-33.923 0h-3.358l-14.377 53.108h3.359l14.376-53.108zm17.836 0h3.359l14.377 53.108h-3.359l-14.377-53.108zm-3.746 0h-3.358l-14.377 53.108h3.359l14.376-53.108z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.244 208.349c-.188.675-.027 1.328.359 1.458.386.129.852-.314 1.04-.99.189-.676.028-1.329-.358-1.458s-.852.314-1.041.99z"
      fill="#121216"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.873 186.243h14.135c7.239 0 12.809-6.39 11.812-13.552l-5.298-38.054a6.688 6.688 0 00-6.627-5.762h-13.909a6.688 6.688 0 00-6.627 5.762l-5.298 38.054c-.997 7.162 4.572 13.552 11.812 13.552z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.56 186.243h44.04a5.189 5.189 0 005.192-5.186v-.459H112.56v5.645z"
      fill="#D8D8D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.051 187.641c-.189.676-.028 1.329.358 1.458.387.13.852-.314 1.041-.989.188-.677.027-1.329-.359-1.459-.386-.129-.852.314-1.04.99z"
      fill="#121216"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.439 225.994l-.064 5.934s12.461 2.095 16.686 7.175h-24.828l1.04-16.963 7.166 3.854zm-39.754-1.359l-.068 7.058s5.558.775 7.821 7.354h-17.996l1.8-7.744.704-7.06 7.739.392z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.274 223.695l-.578 7.864 7.679.369.065-5.934-7.166-2.299zm-40.178-1.495l-.854 9.103 8.375.39.068-7.058-7.589-2.435z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.683 163.58h-43.287l-2.074 18.11h41.402l-.612 46.809h13.277l6.27-48.013c1.123-8.978-5.9-16.906-14.976-16.906z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.624 163.58h-35.225s-7.829-45.636 21.252-45.636c21.495 0 27.087 21.546 27.087 21.546l-11.203 3.549-1.911 20.541z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.491 163.637c-5.935-.057-13.916-18.489-13.916-18.489l5.257-3.793 8.392 11.334"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.762 148.726a2.134 2.134 0 01-4.19-.809 2.133 2.133 0 014.19.809z"
      fill="#FFD68F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.203 146.255a.81.81 0 11-1.59-.31.81.81 0 011.59.31zm-1.996 1.2a.81.81 0 11-1.59-.308.81.81 0 011.59.308z"
      fill="#FFD68F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.57 144.13c.376.935.195 4.025.195 4.025s-.269 1.151-.755 2.384c-.487 1.234-.817 1.959-.857 4.326-.029 1.649-1.894-.243-2.107-1.336-.22-1.126 1.048-4.123-.257-4.317-1.305-.195-3.188 2.368-4.461 5.166-.2.44-.746.513-1.066.442-.52-.113-.193-2.189-.193-2.189s-.541.411-1.058-.316c-.184-.257-.243-1.88-.243-1.88s-.224-.944-.158-1.764c.117-1.446.847-3.746 1.276-4.262 1.04-1.249 2.948-3.281 4.362-4.031 1.4-.743 4.714 2.243 5.322 3.752z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M153.068 149.824a.81.81 0 11-1.59-.308.81.81 0 011.59.308z"
      fill="#FFD68F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.941 151.584a2.134 2.134 0 01-4.19-.809 2.134 2.134 0 014.19.809z"
      fill="#FFD161"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.229 150.581a2.133 2.133 0 01-2.5 1.687 2.13 2.13 0 11.81-4.184 2.13 2.13 0 011.69 2.497z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.275 118.779c1.176 3.447 7.267 5.218 11.664 4.737 4.677-.512 3.529-3.767 1.181-4.905-4.318-1.057-2.138-1.046-6.512-.887-1.619.059-4.761.694-6.333 1.055z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.822 88.856c.784.129 8.645 4.4 1.461 10.908l-1.461-10.908z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.533 105.497l.46 1.793c5.631-3.196 1.452-7.62 1.452-7.62 8.511-.148 13.971-1.865 17.376-4.235a12.99 12.99 0 00-.248-.415l-.538-.27-9.771-4.901-11.433 5.105 1.677 6.539 1.025 4.004zm-.925.778c.817.453 1.308.939 1.378 1.006-.275-.34-.768-.696-1.378-1.006z"
      fill="#FF77CA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.403 113.815l.23 4.86 10.595.887.08-4.877s6.6.154 8.784-1.123c0 0-.397-8.961-.695-10.524-.475-2.5-1.234-5.192-2.439-7.366-.045-.08-.091-.16-.137-.237-3.405 2.37-8.865 4.087-17.376 4.235 0 0 4.179 4.424-1.452 7.62 0 0-.003-.002-.007-.009-.069-.067-.561-.553-1.378-1.006a8.554 8.554 0 00-2.384-.777c-.004-.002-.008-.002-.013-.002-.791-.129-1.591-.136-2.295.057h-.003c-1 .272-1.806.938-2.104 2.2a.188.188 0 01-.006.031c-1.219 5.362 10.6 6.031 10.6 6.031z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.913 105.553h.003c.704-.193 2.081-.402 4.692.722.858.37 1.308.939 1.378 1.006.003.007.007.009.007.009 5.631-3.196 1.452-7.62 1.452-7.62 8.511-.148 13.971-1.865 17.376-4.235 8.413-5.853 4.287-15.702 2.637-15.786-2.173-.109-3.493 6.617-3.493 6.617s-4.693-9.795-11.29-7.448c-4.63 1.65-3.768 9.25-3.768 9.25s-7.211-1.426-11.371 7.839c-3.619 8.062 1.092 9.426 2.377 9.646z"
      fill="#0B096F"
    />
    <path
      opacity={0.2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.308 114.685l-4.786-.228 4.728 3.464.058-3.236z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.804 102.969a1.321 1.321 0 11-2.113 1.59 1.323 1.323 0 012.113-1.59z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.934 146.34a.81.81 0 11-1.59-.31.81.81 0 011.59.31zm1.743.336a.81.81 0 11-1.59-.308.81.81 0 011.59.308z"
      fill="#FFD68F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.094 148.785a2.134 2.134 0 01-4.19-.809 2.133 2.133 0 014.19.809z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.952 150.423a2.133 2.133 0 01-2.5 1.687 2.131 2.131 0 11.811-4.184 2.13 2.13 0 011.689 2.497z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.563 147.478a.81.81 0 11-1.59-.31.81.81 0 011.59.31zm-2.329-1.341a.81.81 0 11-1.59-.308.81.81 0 011.59.308z"
      fill="#FFD68F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.114 103.318a1.323 1.323 0 11-1.853-.261 1.324 1.324 0 011.853.261z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.879 206.985c-.189.676-.247 1.255-.131 1.294.116.039.362-.478.551-1.153.188-.676.247-1.256.131-1.295-.116-.038-.363.478-.551 1.154z"
      fill="#121216"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.557 151.432a2.133 2.133 0 01-2.5 1.687 2.13 2.13 0 11.81-4.184 2.13 2.13 0 011.69 2.497z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.232 187.767c-.189.676-.247 1.255-.131 1.294.115.039.362-.478.55-1.153.189-.676.247-1.256.132-1.295-.116-.038-.363.478-.551 1.154zm40.713-.122c.173.681.406 1.204.521 1.169.115-.035.068-.615-.105-1.296-.174-.681-.407-1.204-.522-1.169-.114.035-.067.615.106 1.296z"
      fill="#121216"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.734 148.987a2.133 2.133 0 01-4.19-.809 2.135 2.135 0 014.19.809z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M158.181 207.948c.173.681.406 1.204.521 1.169.115-.035.067-.615-.106-1.296s-.406-1.204-.521-1.169c-.115.035-.067.615.106 1.296z"
      fill="#121216"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.228 104.198l2.802 5.037-2.595.343-.207-5.38z"
      fill="#F49E7C"
    />
    <path
      opacity={0.2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.378 137.524c-.655-1.976-1.616-4.342-2.859-6.083l1.319-.938c1.385 1.941 2.406 4.492 3.076 6.514a42.541 42.541 0 01.953 3.332l.049.21.013.056.003.015.001.006-.791.171.791-.171.142.652-3.98 1.697-.636-1.486 2.738-1.167a41.498 41.498 0 00-.819-2.808z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.026 151.279l-4.297 11.318-1.652 4.35-9.21-1.779.091-4.652.238-12.101 14.83 2.864z"
      fill="#E30613"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.333 149.214l-3.138 16.211-1.328-.257.091-4.652 2.267-11.709 2.108.407zm1.194 17.047l-2.109-.407 3.139-16.211 2.108.407-3.138 16.211zm7.469-15.374l-2.267 11.709-1.652 4.351-1.327-.256 3.138-16.211 2.108.407z"
      fill="#EAD0D0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M133.406 159.502l5.309-3.336s1.169-.484 2.585-.853c1.416-.368 2.285-.551 4.271-1.939 1.383-.967 1.348 1.59.621 2.408-.75.843-4.26 1.672-3.353 2.784.907 1.112 4.562 1.012 7.912.306.527-.111 1.033.261 1.237.548.331.464-1.648 1.46-1.648 1.46s.781.166.605.996c-.063.294-1.353 1.313-1.353 1.313s-.597.737-1.327 1.177c-1.288.779-3.784 1.6-4.561 1.582-1.881-.044-5.119-.281-6.895-.91-1.757-.623-1.144-3.217-3.403-5.536z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.947 109.677l3.642 1.212-.383 1.15-3.642-1.212.383-1.15z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.399 163.58h28.496c5.887 0 10.49 5.015 9.912 10.801l-5.612 54.118h-13.053l2.209-46.809h-9.257c-7.011 0-12.695-5.613-12.695-12.538v-5.572z"
      fill="#364150"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.769 142.242l-11.123 3.567s1.813 12.247 5.947 15.978c4.134 3.732 20.586 1.765 20.586 1.765l2.459-6.523-16.017-2.449-1.852-12.338z"
      fill="#FCBDA4"
    />
    <path
      opacity={0.2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.769 142.242l-11.123 3.567.906 4.636 10.217-8.203z"
      fill="#000"
    />
  </React.Fragment>
);

// ------------------------------
// Project
// ------------------------------

export const HeroProject = createIllustration(
  describeWithOptions(
    'HeroProject',
    'Illustration of a woman assembling a project using colourful blocks.'
  ),
  <React.Fragment>
    <path
      d="M301.109 129.672c0 27.356 22.196 49.535 49.571 49.535v-49.535h-49.571zM143.618 96.344H83.34l30.139-49.677 30.139 49.677z"
      fill="#E6E7E8"
    />
    <path d="M243.85 49.677h-60.28L213.711 0l30.139 49.677z" fill="#F2F3F4" />
    <path
      d="M323.815 19.437L292.13 51.099l31.685 31.662L355.5 51.1l-31.685-31.662z"
      fill="#E6E7E8"
    />
    <path
      d="M248.968 205.413c15.482 0 28.034-12.542 28.034-28.014s-12.552-28.014-28.034-28.014c-15.483 0-28.035 12.542-28.035 28.014s12.552 28.014 28.035 28.014zM69.07 135.631c-27.377 0-49.57 22.177-49.57 49.535h49.57v-49.535zm103.102-45.963l-31.686 31.662 31.686 31.662 31.685-31.662-31.685-31.662zM51.012 62.043c15.483 0 28.034-12.543 28.034-28.014 0-15.472-12.551-28.014-28.034-28.014-15.483 0-28.035 12.542-28.035 28.014 0 15.471 12.552 28.014 28.035 28.014z"
      fill="#F2F3F4"
    />
    <path
      d="M192.442 240c79.111 0 143.244-.618 143.244-1.381 0-.762-64.133-1.38-143.244-1.38-79.112 0-143.244.618-143.244 1.38 0 .763 64.132 1.381 143.244 1.381z"
      fill="#E6E7E8"
    />
    <path
      d="M168.559 205.419a33.246 33.246 0 00-23.496 9.724 33.193 33.193 0 00-9.737 23.476h33.233v-33.2z"
      fill="#FC1C88"
    />
    <path d="M135.328 205.411h-33.233v33.208h33.233v-33.208z" fill="#FFB027" />
    <path
      d="M217.586 205.411h-33.232v33.208h33.232v-33.208zm-82.258-33.207h-33.233v33.209h33.233v-33.209z"
      fill="#3361C2"
    />
    <path
      d="M67.716 238.619c10.227 0 18.518-8.283 18.518-18.504 0-10.22-8.29-18.505-18.518-18.505-10.227 0-18.518 8.285-18.518 18.505 0 10.221 8.29 18.504 18.518 18.504zm125.874-33.208c10.228 0 18.519-8.285 18.519-18.505 0-10.22-8.291-18.505-18.519-18.505-10.227 0-18.518 8.285-18.518 18.505 0 10.22 8.291 18.505 18.518 18.505zm-67.607-33.207H83.216l21.383-35.245 21.384 35.245z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M241.349 129.917s-2.107 3.283-4.787 3.719c-.804.131-5.388-2.809-5.388-2.809l.761-3.847 2.743-1.565s3.081.274 4.044.379"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M287.468 226.621l1.324 5.666s-5.761.831-7.67 6.113h16.04l-1.834-6.364-1.645-5.523-6.215.108z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M293.318 225.13l2.013 6.906-6.537.254-1.3-5.461 5.824-1.699z"
      fill="#00BD9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.963 227.729l.589 4.453s-10.928 2.488-15.036 6.187l21.494.031-1.887-12.752-5.16 2.081z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M254.243 226.618l.841 5.39-5.532.177-.497-3.697 5.188-1.87z"
      fill="#00BD9D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M264.514 89.62c-8.732 0-15.812-7.161-15.812-15.996 0-8.834 7.08-15.996 15.812-15.996 8.733 0 15.812 7.162 15.812 15.996 0 8.835-7.079 15.996-15.812 15.996z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M262.113 105.904s-4.057 11.961-6.91 15.46c-4.31 4.04-16.668 10.443-16.668 10.443l-2.756-4.143 13.965-10.748 4.899-13.926 7.47 2.914z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M247.052 227.947l9.769-.031-2.383-46.173 12.495-38.149s-3.41 27.786-1.775 37.451c2.089 12.313 21.088 47.625 21.088 47.625h9.853l-16.496-48.361s3.687-13.785 4.457-27.993c1.076-19.878-1.072-24.662-1.072-24.662h-27.343s-5.3 7.952-8.127 18.949c-5.674 22.104-7.895 26.878-7.259 35.379.865 11.553 6.793 45.965 6.793 45.965z"
      fill="#364150"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M274.725 63.576c-4.689 0-8.489-3.845-8.489-8.588 0-4.743 3.8-8.588 8.489-8.588 4.688 0 8.489 3.845 8.489 8.588 0 4.743-3.801 8.588-8.489 8.588zm5.588 11.183l-31.62-.05-.033 21.052 31.621.05.032-21.052z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M249.286 112.484l8.96 4.425s1.53 0-.466 5.34c-1.314 3.515-2.135 5.41-2.135 5.41h27.343l-5.446-13.485 13.278-5.538s-3.694-10.984-11.047-17.212c-3.852-3.26-4.886-1.816-11.623-1.796-6.339.013-8.319 1.3-11.156 3.694-5.89 4.981-7.708 19.162-7.708 19.162z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M261.989 99.507c4.356 0 7.211-1.868 8.489-5.551.742-2.142 1.348-4.29 1.354-4.31l.187-.664H270.171c-.837 0-2.029 0-2.893-.062h-.279c-1.998 0-4.197 1.19-4.29 1.242a9.886 9.886 0 00-3.134 3.165c-1.083 1.688-1.326 3.358-.67 4.584.548 1.026 1.633 1.585 3.061 1.588l.023.008z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M268.221 81.579c-.86 1.393-.866 1.808-.374 2.878.165.37 1.601 4.874 1.758 5.01.611.54-6.868 3.847-6.868 3.847l-.508-6.23c-2.17.125-4.716 0-6.113-.93-4.163-2.803-4.412-12.52-4.343-13.004l3.494-3.953s5.266-2.46 6.067-1.416c1.466 1.916 4.896 10.483 7.381 9.506 0 0-.312-2.665 1.884-2.66 1.638 0 2.082 1.331 2.082 2.098-.008 2.589-3.304 2.97-4.46 4.854z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M256.758 78.5l-1.57 3.848 1.821-.036-.251-3.811z"
      fill="#F49E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M258.425 78.66c-.035-.769.351-1.412.863-1.436.512-.025.956.58.991 1.35.035.77-.351 1.413-.863 1.437-.512.024-.956-.58-.991-1.35zm-4.812-.12c-.035-.77.351-1.414.863-1.438.512-.024.956.581.991 1.35.035.77-.351 1.414-.863 1.438-.512.024-.956-.58-.991-1.35z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M256.469 69.15a9.415 9.415 0 01-.507 3.032c-.044.118-.087.242-.135.36a9.124 9.124 0 01-1.227 2.185 8.093 8.093 0 01-5.578 3.201c-.114 0-.228.02-.343.026l.001-3.235v-.302a17.576 17.576 0 013.476-10.553c.401-.54.834-1.054 1.298-1.54a8.825 8.825 0 012.416 3.52 9.283 9.283 0 01.599 3.306z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M268.738 68.963c0 2.309-2.074 4.256-4.901 4.85a7.863 7.863 0 01-.685.116 8.462 8.462 0 01-1.153.075c-3.717 0-6.727-2.27-6.722-5.061 0-.265.028-.53.084-.788.507-2.416 3.296-4.258 6.653-4.253 3.682 0 6.668 2.227 6.722 4.982.002.038.002.053.002.08z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.445 83.279l.158-.49.157-.49h-.006l-.007-.003-.021-.006a1.926 1.926 0 00-.268-.05 2.475 2.475 0 00-.677.015c-.56.089-1.261.384-1.935 1.143l.762.686c.515-.58 1.002-.76 1.332-.813a1.465 1.465 0 01.496.006.231.231 0 01.016.004l-.003-.001h-.002l-.001-.001h-.001z"
      fill="#0B096F"
    />
    <path
      opacity={0.401}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M262.24 87.08s2.741.083 3.909-.563l-3.765 2.393-.144-1.83z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M258.809 89.582s.717-.033 1.82.036c1.545.1 1.821.102 1.821.102l-.183-2.275-4.168.557.71 1.58z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M246.377 230.535l10.839.015.004-2.793-10.839-.015-.004 2.793zm50.584.013l-11.222-.015-.188-2.794 11.223.016.187 2.793z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M267.468 102.1h5.008c4.201 0 7.606-3.445 7.606-7.696v-11.3h-12.601V102.1h-.013z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M256.107 114.282l.592-.339.593-.338v-.001l-.003-.005-.014-.026a11.121 11.121 0 01-.27-.538 15.52 15.52 0 01-.624-1.537c-.44-1.279-.807-2.911-.618-4.461l-1.353-.168c-.226 1.849.213 3.712.683 5.078.237.69.488 1.268.68 1.676a12.057 12.057 0 00.306.608l.019.036.006.01.002.003v.001l.001.001z"
      fill="#CB8C1F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M272.478 136.178l-1.775-4.686 12.544-10.261-3.781-7.842 8.367-3.486s4.397 8.496 3.028 13.431c-1.729 6.187-18.383 12.844-18.383 12.844z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M258.944 141.278c1.63-.425 2.611-2.106 2.19-3.755l-.937-3.68c-.42-1.649-2.082-2.641-3.712-2.216-1.63.425-2.611 2.106-2.191 3.755l.938 3.68c.42 1.649 2.082 2.641 3.712 2.216z"
      fill="#D82B2B"
    />
    <path
      d="M272.434 136.807a28.369 28.369 0 00-7.924-7.731 28.195 28.195 0 00-10.263-4.092 28.113 28.113 0 00-11.038.171 28.198 28.198 0 00-10.132 4.407l16.077 23.422 23.28-16.177z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M271.913 130.958s-4.848.185-5.596.834c-.898.769-1.775 2.516-2.001 3.263-.225.746.88 1.431 1.347 1.416a1.687 1.687 0 001.389 1.569 1.782 1.782 0 001.674.752 1.426 1.426 0 001.759.087c.946-.654 2.812-2.652 4.265-4.225"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M273.597 107.244l3.329 7.226 1.237-.577-3.33-7.226-1.236.577z"
      fill="#CB8C1F"
    />
    <path
      opacity={0.401}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M287.823 109.896l-8.357 3.486 9.372-1.286-1.015-2.2z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M236.347 127.664c-1.798-.718-2.32.462-3.16 1.347-.839.885-3.623 1.624-1.975-.282l.713-1.734 2.282-1.296"
      fill="#FCBDA4"
    />
    <path
      d="M232.125 131.212a.828.828 0 01-1.167-.173.851.851 0 01.171-1.181l2.575-1.939a.829.829 0 011.168.173.852.852 0 01.144.316.808.808 0 011.238.305l.014-.02a.748.748 0 011.049-.179.766.766 0 01.234.965.875.875 0 01.443.11c.425.238.58.779.346 1.21l-1.344 2.466a.874.874 0 01-1.195.35.896.896 0 01-.419-1.035l-.046.066a.746.746 0 01-1.048.179.766.766 0 01-.177-1.06l.286-.408-.603.61a.809.809 0 01-1.153 0 .833.833 0 01-.06-1.098l-.456.343z"
      fill="#FCBDA4"
    />
  </React.Fragment>
);

// ------------------------------
// NotFound
// ------------------------------

export const HeroNotFound = createIllustration(
  describeWithOptions(
    'HeroNotFound',
    'Illustration of a man trying to free himself from stylised 3D text that reads "404". Surrounding the text are stars and planets.'
  ),
  <React.Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.287 16.318c0 5.034-4.064 9.115-9.077 9.115s-9.078-4.081-9.078-9.115 4.065-9.115 9.078-9.115c5.013 0 9.077 4.08 9.077 9.115z"
      fill="#E4E7EA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M364.23 16.32c0 4.241-3.419 7.68-7.637 7.68s-7.637-3.439-7.637-7.68c0-4.242 3.419-7.68 7.637-7.68s7.637 3.438 7.637 7.68zM22.655 106.548c0 5.034-4.064 9.115-9.078 9.115-5.013 0-9.077-4.081-9.077-9.115s4.064-9.115 9.078-9.115c5.013 0 9.077 4.081 9.077 9.115z"
      fill="#E4E5E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.051 166.589c0 .436-.03.865-.089 1.284-.569 4.06-3.805 7.262-7.873 7.763a9.312 9.312 0 01-1.115.067c-5.014 0-9.077-4.08-9.077-9.114 0-2.13.725-4.088 1.942-5.639a9.058 9.058 0 017.105-3.476h.03c5.014 0 9.077 4.08 9.077 9.115z"
      fill="#C3E4FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.962 167.873c-.569 4.059-3.805 7.261-7.873 7.763a6.372 6.372 0 01-.216-1.651c0-3.511 2.836-6.362 6.336-6.362.608 0 1.197.088 1.753.25zm-8.988-9.9c0 .736-.189 1.429-.519 2.03a4.207 4.207 0 01-1.808 1.733 4.18 4.18 0 01-4.808-.786 9.058 9.058 0 017.105-3.476c.02.162.03.329.03.499z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.874 161.613a1.62 1.62 0 01-1.616 1.623 1.62 1.62 0 01-1.616-1.623 1.62 1.62 0 011.616-1.622c.893 0 1.616.726 1.616 1.622zm27.96-57.059c-1.157 2.898-4.436 4.306-7.322 3.144-2.888-1.162-4.289-4.454-3.132-7.352a5.622 5.622 0 017.322-3.145c2.887 1.162 4.289 4.454 3.132 7.353zm-92.272-10.242c-1.297 0-2.349 3.204-2.349 7.156 0-3.952-1.051-7.156-2.349-7.156 1.298 0 2.35-3.204 2.35-7.155 0 3.951 1.051 7.155 2.348 7.155z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.195 109.638c0 .94-.758 1.701-1.694 1.701a1.697 1.697 0 01-1.694-1.701c0-.939.758-1.7 1.694-1.7.936 0 1.694.761 1.694 1.7zm76.569-3.932c0 .94-.759 1.702-1.694 1.702a1.698 1.698 0 01-1.694-1.702c0-.939.758-1.7 1.694-1.7.935 0 1.694.761 1.694 1.7z"
      fill="#C3E4FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M338.466 32.776c0 .94-.758 1.701-1.694 1.701a1.698 1.698 0 01-1.694-1.7c0-.94.759-1.701 1.694-1.701.936 0 1.694.761 1.694 1.7z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M250.64 56.11c0 .939-.758 1.7-1.694 1.7a1.698 1.698 0 01-1.694-1.7c0-.94.759-1.701 1.694-1.701.936 0 1.694.761 1.694 1.7z"
      fill="#F3F3F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.468 8.568c-1.553 0-2.812 3.836-2.812 8.567 0-4.731-1.259-8.567-2.813-8.567 1.554 0 2.813-3.837 2.813-8.568 0 4.731 1.259 8.568 2.812 8.568z"
      fill="#E4E5E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.497 112.816c-1.879 0-3.403 4.642-3.403 10.366 0-5.724-1.523-10.366-3.403-10.366 1.88 0 3.403-4.642 3.403-10.366 0 5.724 1.524 10.366 3.403 10.366z"
      fill="#E8F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.247 132.713c-1.728 0-3.129 4.267-3.129 9.53 0-5.263-1.4-9.53-3.128-9.53 1.728 0 3.128-4.268 3.128-9.531 0 5.263 1.401 9.531 3.129 9.531z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M359.064 82.047c-1.473 0-2.667 3.639-2.667 8.127 0-4.489-1.194-8.127-2.668-8.127 1.474 0 2.668-3.64 2.668-8.127 0 4.488 1.194 8.127 2.667 8.127z"
      fill="#E4E5E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M204.099 138.525c0 .804-.649 1.455-1.449 1.455s-1.449-.651-1.449-1.455.649-1.455 1.449-1.455 1.449.651 1.449 1.455z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M185.037 36.898c0 1.138-.919 2.06-2.052 2.06a2.057 2.057 0 01-2.053-2.06c0-1.138.919-2.06 2.053-2.06 1.133 0 2.052.922 2.052 2.06zm21.001-26.313a3.72 3.72 0 01-3.713 3.728 3.72 3.72 0 01-3.713-3.729 3.72 3.72 0 013.713-3.728 3.72 3.72 0 013.713 3.729z"
      fill="#E4E5E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M303.222 139.98a1.874 1.874 0 01-1.87 1.878 1.875 1.875 0 01-1.87-1.878c0-1.037.838-1.877 1.87-1.877 1.033 0 1.87.84 1.87 1.877zm-214.27-4.091c0 .652-.527 1.181-1.176 1.181-.65 0-1.176-.529-1.176-1.181s.527-1.181 1.176-1.181c.65 0 1.176.529 1.176 1.181zm126.405-73.932c0 .652-.526 1.18-1.176 1.18a1.179 1.179 0 01-1.176-1.18c0-.652.527-1.18 1.176-1.18.65 0 1.176.528 1.176 1.18z"
      fill="#AAD5FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.931 74.533c0 .652-.526 1.18-1.176 1.18a1.179 1.179 0 01-1.176-1.18c0-.652.527-1.18 1.176-1.18.65 0 1.176.528 1.176 1.18zm-46.416 0c0 .652-.527 1.18-1.176 1.18a1.179 1.179 0 01-1.176-1.18c0-.652.527-1.18 1.176-1.18.649 0 1.176.528 1.176 1.18z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.108 69.002c16.314 2.101 31.236-9.475 33.329-25.857 2.093-16.382-9.436-31.366-25.75-33.467-16.315-2.102-31.237 9.475-33.33 25.857-2.093 16.382 9.436 31.365 25.75 33.467z"
      fill="#F2F3F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.107 69.003c16.316 2.101 31.237-9.477 33.33-25.857a29.915 29.915 0 00-1.246-13.163c-.971 1.605-2.894 3.127-6.526 1.117-6.196-3.426-7.462 6.167-2.439 9.53 2.834 1.898 5.336 5.374 1.834 7.906-3.848 2.782-10.443-9.466-16.546-4.965-8.196 6.045 6.176 15.126.692 17.838-3.429 1.695-5.887-3.06-5.887-3.06s-7.492 7.005-14.277 1.163c-2.257-1.943-5.385-2.169-7.03.113a29.624 29.624 0 0018.095 9.378zm306.06-27.016c-2.799 16.35-18.267 27.325-34.549 24.514-16.282-2.81-27.213-18.343-24.413-34.692 2.799-16.35 18.267-27.324 34.549-24.514 16.282 2.811 27.212 18.343 24.413 34.692z"
      fill="#E4E5E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M341.596 36.2c-6.421 1.276-22.367 3.474-34.877-2.85-11.742-5.94-12.913-11.692-18.579-14.986A29.399 29.399 0 01291.212 15c5.942.288 15.77 1.976 25.685 8.96 8.419 5.931 17.466 7.054 24.08 6.809.368 1.769.578 3.584.619 5.43zm-39.398 2.256c-.439.175-8.574 3.294-20.431-1.383a30.358 30.358 0 01.437-5.265 30.234 30.234 0 011.632-5.891c4.225 4.57 13.834 14.196 18.362 12.539zM339.6 47.71a29.838 29.838 0 01-5.089 8.61c-7.471-2.779-21.654-7.104-32.294-4.657-5.972 1.373-11.478 1.464-15.952 1.068a30.075 30.075 0 01-3.397-7.749c6.414-.087 18.634-.648 28.818-3.413 10.448-2.837 20.537 3.311 27.914 6.14z"
      fill="#F2F3F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M329.39 61.11a29.856 29.856 0 01-4.117 2.55c-7.363-2.971-16.787-6.179-21.872-5.28.628-.136 11.221-2.276 25.989 2.73z"
      fill="#F8F8F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M338.95 24.529c-5.731-.392-17.551-2.283-27.087-10.886-5.25-4.735 4.232-3.806 17.488-.992a30.162 30.162 0 019.599 11.878z"
      fill="#F2F3F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M315.052 66.752a29.84 29.84 0 01-8.434-.25 29.716 29.716 0 01-16.259-8.542c5.18.337 15.595 1.908 24.693 8.792z"
      fill="#F8F8F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M305.23 12.69c-.951-.086-4.823-.525-7.794-2.201a29.814 29.814 0 014.821-2.103c1.08 2.078 2.264 4.048 2.973 4.304z"
      fill="#F2F3F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M305.449 12.707a.375.375 0 01-.215-.017c.138.012.215.017.215.017z"
      fill="#E8F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M256.152 29.496a4.6 4.6 0 01.191-.101c.824-.411 2.187-.827 4.174-1.16 3.938-.662 9.638-.894 16.663-.64a2.87 2.87 0 002.965-2.775 2.873 2.873 0 00-2.759-2.982c-7.186-.26-13.323-.039-17.812.715-2.226.374-4.224.906-5.779 1.683-1.469.734-3.202 2.029-3.579 4.233-.323 1.887.524 3.487 1.443 4.605.936 1.139 2.253 2.174 3.741 3.12 2.999 1.909 7.304 3.84 12.495 5.711 10.428 3.758 24.971 7.463 41.178 10.261 16.207 2.798 31.148 4.182 42.227 4.137 5.515-.023 10.215-.4 13.676-1.193 1.717-.393 3.303-.927 4.563-1.686 1.237-.746 2.567-1.968 2.89-3.855.368-2.148-.767-3.916-1.868-5.089-1.156-1.232-2.788-2.379-4.669-3.45-3.792-2.159-9.236-4.364-15.781-6.478a2.861 2.861 0 00-3.603 1.86 2.883 2.883 0 001.851 3.624c6.393 2.065 11.413 4.13 14.711 6.007 1.665.947 2.735 1.761 3.325 2.39.036.038.069.075.099.109-.538.3-1.436.642-2.79.952-2.861.655-7.077 1.027-12.428 1.049-10.655.044-25.251-1.295-41.234-4.054-15.982-2.759-30.186-6.392-40.214-10.006-5.037-1.816-8.887-3.58-11.365-5.156-1.172-.746-1.905-1.368-2.311-1.831zm-.428.3l.014-.016-.014.015zm111.074 19.173l-.008-.02c.007.013.009.02.008.02zm.115-.716a.094.094 0 01.012-.014l-.012.014zm-111.061-19.17l-.007-.017.007.017z"
      fill="#E4E5E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M334.816 239.413c0 .324-69.279.587-154.738.587-85.46 0-154.738-.263-154.738-.587 0-.323 69.278-.586 154.738-.586 85.459 0 154.738.263 154.738.586z"
      fill="#DDD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M167.67 182.433c-7.235-5.436-12.803-13.309-16.702-23.619-3.901-10.31-5.85-22.462-5.85-36.459 0-14.12 1.925-26.305 5.773-36.553 3.848-10.245 9.39-18.118 16.625-23.619 7.235-5.497 15.779-8.247 25.63-8.247 9.852 0 18.369 2.75 25.554 8.248 7.182 5.5 12.699 13.373 16.548 23.618 3.848 10.248 5.772 22.433 5.772 36.553 0 13.997-1.95 26.149-5.849 36.459-3.901 10.31-9.443 18.183-16.625 23.619-7.185 5.436-15.651 8.154-25.4 8.154-9.751 0-18.241-2.718-25.476-8.154z"
      fill="#C8CBD1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.951 136.789V90.676L77.242 136.79h27.709z"
      fill="#3888DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.564 158.721h-50.49v-17.246l51.414-84.727h23.091v80.041h15.855v21.932h-15.855v29.054h-24.015v-29.054zm195.251 0h-50.49v-17.246l51.414-84.727h23.091v80.041h15.855v21.932H320.83v29.054h-24.015v-29.054z"
      fill="#C8CBD1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M197.485 122.729c0 13-2.106 23.184-6.31 30.556-4.21 7.374-10.16 11.059-17.859 11.059-7.696 0-13.649-3.685-17.856-11.059-4.209-7.372-6.31-17.556-6.31-30.556 0-13.119 2.078-23.43 6.233-30.93 4.157-7.495 10.133-11.246 17.933-11.246 7.798 0 13.778 3.75 17.935 11.247 4.155 7.498 6.234 17.81 6.234 30.929z"
      fill="#909396"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.273 89.814c-.905 0-1.637 1.807-1.637 4.036 0-2.229-.733-4.036-1.637-4.036.904 0 1.637-1.807 1.637-4.035 0 2.228.732 4.035 1.637 4.035zm12.653 8.164c-1.486 0-2.692 2.971-2.692 6.637 0-3.666-1.205-6.637-2.692-6.637 1.487 0 2.692-2.971 2.692-6.636 0 3.665 1.206 6.636 2.692 6.636zm-16.619 16.761c-1.107 0-2.004 2.211-2.004 4.939 0-2.728-.897-4.939-2.003-4.939 1.106 0 2.003-2.211 2.003-4.938 0 2.727.897 4.938 2.004 4.938zm24.19.833c-.672 0-1.217 1.344-1.217 3.001 0-1.657-.545-3.001-1.217-3.001.672 0 1.217-1.343 1.217-3 0 1.657.545 3 1.217 3zm-12.536 29.301c-1.318 0-2.387 2.635-2.387 5.884 0-3.249-1.069-5.884-2.386-5.884 1.317 0 2.386-2.634 2.386-5.883 0 3.249 1.069 5.883 2.387 5.883zm-19.794-14.277c-1.01 0-1.829 2.019-1.829 4.508 0-2.489-.818-4.508-1.828-4.508 1.01 0 1.828-2.017 1.828-4.507 0 2.49.819 4.507 1.829 4.507z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.121 136.789V90.676L57.413 136.79h27.709zm-3.386 21.932H31.244v-17.246l51.415-84.727h23.09v80.041h15.855v21.932h-15.855v29.054H81.735v-29.054zm109.44-5.436c-4.21 7.374-10.16 11.059-17.859 11.059-7.696 0-13.649-3.685-17.856-11.059-4.209-7.372-6.31-17.556-6.31-30.556 0-13.119 2.078-23.43 6.233-30.93 4.158-7.495 10.133-11.246 17.933-11.246 7.798 0 13.778 3.75 17.936 11.247 4.154 7.498 6.233 17.81 6.233 30.929 0 13-2.106 23.184-6.31 30.556zm24.243-67.483c-3.847-10.244-9.365-18.119-16.547-23.618-7.185-5.498-15.703-8.249-25.555-8.249s-18.395 2.75-25.629 8.25c-7.234 5.498-12.778 13.373-16.624 23.617-2.641 7.028-4.374 14.965-5.203 23.81a136.74 136.74 0 00-.571 12.743c0 5.703.322 11.1.97 16.192.942 7.401 2.569 14.157 4.881 20.266 3.898 10.312 9.467 18.184 16.701 23.621 7.234 5.436 15.725 8.154 25.475 8.154 9.75 0 18.216-2.718 25.401-8.154 7.182-5.437 12.723-13.309 16.624-23.621 3.899-10.309 5.851-22.461 5.851-36.458 0-14.119-1.925-26.303-5.774-36.553zm64.955 50.987V90.676l-27.709 46.113h27.709zm-3.387 21.932h-50.491v-17.246l51.415-84.727H301v80.041h15.856v21.932H301v29.054h-24.014v-29.054z"
      fill="#E4E5E6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.637 22.82c0 .94-.759 1.702-1.694 1.702a1.697 1.697 0 01-1.694-1.701c0-.94.758-1.701 1.694-1.701.935 0 1.694.761 1.694 1.7z"
      fill="#F0F0F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.536 232.838l2.986-7.916 6.135 3.89-3.302 7.087-5.819-3.061z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.886 231.832l-2.602 7.524 26.445.058s-4.686-5.19-16.899-6.68l-6.944-.902z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.191 146.682l-2.778 41.207-17.197 42.295 11.585 1.397 18.973-37.677L139.821 151l-18.63-4.318z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.764 102.799l28.843-5.805s1.578.206 2.108 2.069-.302 2.938-.302 2.938l-29.688 12.243-.961-11.445z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.682 110.788l17.944 5.263 3.908-12.661 4.092 1.572-2.657 21.127-25.126-4.131 1.839-11.17z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.426 102.001l-19.34 5.09 23.217 5.789-3.877-10.879z"
      fill="#E69200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M129.53 78.306s8.006 6.065.58 10.328l-.58-10.328z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.035 93.95c-1.06-1.567-6.973-2.259-7.18 1.976-.267 5.461 9.941 5.455 9.941 5.455l1.672 7.397 9.084-1.185-1.759-6.508s8.602-1.683 10.106-3.088c0 0-.937-8.62-3.818-14.116l-8.642-4.349-10.931 3.925 1.527 10.494z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.07 92.983s-6.027-.709-1.522-9.02c4.505-8.31 11.067-6.47 11.067-6.47s-.254-7.122 4.142-8.322c6.263-1.711 9.903 7.726 9.903 7.726s1.698-6.154 3.699-5.897c2.001.256 6.88 17.774-20.364 16.144 0 0 3.472 4.24-1.96 6.806 0 0-1.563-1.787-4.965-.967z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M124.391 97.037c-.354-.8.696-2.028 2.272-2.22 2.335-.284 3.9 1.444 3.083 2.707-.783 1.21-2.324-.02-3.634.223-.713.132-1.451-.103-1.721-.71z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.793 101.084l-5.868.347 6.543 2.208-.675-2.555z"
      fill="#A47C6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.888 89.321l3.209 4.168-2.286.667-.923-4.835z"
      fill="#F49E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.736 111.766l-6.101-9.182 3.397 10.297 2.704-1.115zm.284 12.194l-1.901-10.697-.432 10.314 2.333.383z"
      fill="#A47C6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.974 89.915a1.21 1.21 0 01-.562 1.612 1.201 1.201 0 01-1.606-.564 1.211 1.211 0 01.562-1.613 1.2 1.2 0 011.606.565zm5.772-1.423a1.21 1.21 0 01-.562 1.612 1.2 1.2 0 01-1.605-.564 1.21 1.21 0 01.561-1.613 1.2 1.2 0 011.606.565z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.013 148.613s-19.045-28.234-4.926-41.522c3.288-3.094 24.111 4.292 24.111 4.292l-.696 16.619-4.075-.848 7.853 12.376 4.274 10.98s-10.942-2.479-23.168-1.802c-.842.046-3.373-.095-3.373-.095zm75.697 12.372l-4.632-5.039 5.407-5.625 3.731 6.535-4.506 4.129z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M198.311 148.811l-22.001-25.612a7.636 7.636 0 00-11.037-.229l-21.993 16.56s-3.119 8.604-20.753 9.319c0 0 6.688 15.48 22.198 9.994 6.986-2.471 26.757-19.465 26.757-19.465l22.24 21.657 4.589-12.224z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.71 160.985l4.506 4.751 4.799-25.724s-3.501 4.861-6.628 12.644l-2.677 8.329z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.427 127.154l-9.773-2.471 12.072 6.094-2.299-3.623z"
      fill="#E69200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M158.107 97.323c-.164.192-.365.794-.365.794s-2.344 1.833-4.011 4.718c-1.668 2.886 3.379 4.001 3.379 4.001s1.558.281 2.004-.104c.446-.386.996-.196 1.32-.403.325-.207.571-1.294 1.404-1.764.831-.47.267-2.137-.094-2.419-.361-.282-2.558-.985-2.039-2.738.466-1.571-.723-3.105-1.598-2.085z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.333 103.251l.001-.007.005-.058a2.21 2.21 0 01.047-.263c.056-.236.172-.59.413-1.025.479-.868 1.465-2.08 3.501-3.317l-.494-.821c-2.159 1.312-3.272 2.639-3.842 3.672a5.064 5.064 0 00-.506 1.268 3.063 3.063 0 00-.068.38l-.008.085-1.223 4.109.914.275 1.26-4.232v-.066zm3.767.741l-.256 2.266-.948-.109.256-2.266.948.109zm1.364-6.552c.263 0 .477.215.477.48 0 .301.023.808.092 1.329.07.534.181 1.027.335 1.336.163.328.385.582.666.904.071.081.146.167.224.259.377.441.779.976 1.045 1.78a.48.48 0 01-.302.607.476.476 0 01-.603-.303c-.211-.636-.525-1.061-.865-1.46l-.183-.21c-.289-.33-.612-.697-.836-1.147-.228-.459-.355-1.086-.428-1.64a11.987 11.987 0 01-.099-1.455c0-.265.213-.48.477-.48z"
      fill="#F49E7C"
    />
  </React.Fragment>
);

// ------------------------------
// Search
// ------------------------------

export const HeroSearch = createIllustration(
  describeWithOptions(
    'HeroSearch',
    'Illustration of a woman with a magnifying glass searching through a long document.'
  ),
  <React.Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.805 162.102C-2.87 123.278 26.628 89.627 90.73 75.207 154.873 61.02 163.374 11 246.623 11c83.48 0 166.282 99.246 110.931 181.415-55.332 82.251-166.34-10.851-232.451 20.782-61.237 20.517-115.727 0-124.298-51.095z"
      fill="#F2F3F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.726 120.78l-33.775-27.033-33.78 27.033v40.95h67.555v-40.95z"
      fill="#84888C"
    />
    <path fill="#FFC000" d="M106.856 66.624h44.865v14.917h-44.865z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.622 66.624h45.323a13.978 13.978 0 0113.978 13.98v66.188h-59.301V66.624z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.946 81.54h-46.547v-3.497h46.547v3.497zm0 7.554h-46.547v-3.498h46.547v3.498zm0 7.554h-46.547V93.15h46.547v3.498zm0 7.553h-46.547v-3.497h46.547v3.497zm0 7.554h-46.547v-3.497h46.547v3.497zm0 7.554h-46.547v-3.497h46.547v3.497zm0 7.553h-46.547v-3.497h46.547v3.497zm0 7.554h-46.547v-3.497h46.547v3.497z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.726 161.73h-67.555v-40.95l33.78 22.529 33.775-22.529v40.95z"
      fill="#DFE1E3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.951 136.659l30.566 18.098-1.485 2.507-29.081-17.218-29.086 17.218-1.485-2.508 30.571-18.097z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.185 160.116a3.457 3.457 0 100-6.914 3.457 3.457 0 000 6.914zm-18.298 0a3.457 3.457 0 10-.001-6.914 3.457 3.457 0 000 6.914zm0-18.302a3.457 3.457 0 10-.001-6.914 3.457 3.457 0 000 6.914zm18.298 0a3.457 3.457 0 100-6.914 3.457 3.457 0 000 6.914zm154.847-74.308a3.456 3.456 0 100-6.913 3.456 3.456 0 000 6.913zm-18.299 0a3.457 3.457 0 100-6.914 3.457 3.457 0 000 6.914zm0-18.302a3.457 3.457 0 100-6.914 3.457 3.457 0 000 6.914zm18.299 0a3.456 3.456 0 100-6.912 3.456 3.456 0 000 6.912zm-36.597 18.302a3.456 3.456 0 100-6.913 3.456 3.456 0 000 6.913zm0-18.302a3.456 3.456 0 100-6.912 3.456 3.456 0 000 6.912z"
      fill="#DFE1E3"
    />
    <path
      d="M284.156 227.239a160.297 160.297 0 01-9.692-.691l4.211-28.715-11.131-21.729 14.956-9.129s8.107 20.962 8.514 27.876c.427 7.32-3.603 21.954-6.858 32.388z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M287.037 171.266l-20.044 7.461-6.688-23.678 15.736-5.288 10.996 21.505z"
      fill="#DD036C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.834 149.465a2.33 2.33 0 012.57 2.065l2.291 21.06a2.33 2.33 0 11-4.635.504l-2.292-21.059a2.331 2.331 0 012.066-2.57z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.337 145.625c.8-.096 1.525.475 1.62 1.274l.741 6.211a1.456 1.456 0 11-2.893.345l-.742-6.211a1.457 1.457 0 011.274-1.619z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.913 146.093c5.674-.315 9.96-6.211 9.573-13.168-.386-6.957-5.299-12.341-10.972-12.026-5.674.315-9.959 6.211-9.573 13.168.386 6.957 5.299 12.341 10.972 12.026z"
      fill="#FFC000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.468 132.175h-9.59v-8.743h9.59v8.743zm0 12.418h-9.323v-8.743h9.323v8.743z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.613 134.196c-.432-7.776 4.391-15.215 11.772-15.625 7.38-.41 12.997 6.448 13.429 14.225.432 7.776-4.391 15.215-11.771 15.625-7.381.41-12.998-6.448-13.43-14.225zm12.03-10.969c-3.966.22-7.715 4.573-7.374 10.71.341 6.138 4.549 10.048 8.515 9.828 3.967-.22 7.716-4.573 7.375-10.711-.341-6.137-4.549-10.048-8.516-9.827z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M234.323 178.786s-20.307 21.903-29.875 19.065c-12.521-3.699-29.434-31.452-29.434-31.452l8.166-3.991 22.983 19.818 21.179-19.373 6.981 15.933z"
      fill="#FCBDA4"
    />
    <path
      d="M262.503 224.987c10.891 1.717 21.581 2.683 31.858 2.26-3.268-16.427-9.946-22.923-9.946-22.923l-12.625 1.018.006-.15-27.452 2.419s-1.866 3.723-3.544 13.193c7.281 1.598 14.535 3.053 21.703 4.183z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M211.344 171.995l10.165 22.407 12.284-11.608s.517 3.249 3.461 8.515a101.688 101.688 0 017.103 16.302l40.062-3.3s-3.042-12.286-3.563-21.183c-.588-10.006.877-35.862-10.487-39.742-9.827-3.356-12.508.084-26.186 2.753-12.881 2.521-32.839 25.856-32.839 25.856z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M275.27 115.869c.127 13.049-11.225 23.725-25.356 23.839-13.13.111-24.063-8.951-25.626-20.716a21.69 21.69 0 01-.19-2.695c-.068-6.876 3.049-13.09 8.086-17.446a26.68 26.68 0 0117.273-6.393c14.126-.114 25.69 10.367 25.813 23.411z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M265.201 101.512c7.833 0 14.182-6.35 14.182-14.184 0-7.833-6.349-14.184-14.182-14.184-7.832 0-14.181 6.35-14.181 14.184s6.349 14.184 14.181 14.184z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.677 125.656l-4.55 9.125 4.719.276-.169-9.401z"
      fill="#F49E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M243.684 145.792s-11.996-1.102-14.529-4.482c-7.124-9.524 2.232-35.062 2.232-35.062l27.656 16.612s4.439 22.991 4.362 23.012l-17.73 5.004-1.991-5.084z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M230.178 126.364c.226-1.105-.267-2.139-1.101-2.31-.834-.17-1.694.587-1.92 1.692-.226 1.105.267 2.139 1.102 2.31.834.17 1.693-.587 1.919-1.692zm30.736-1.225c-4.837 6.024-13.507 7.952-21.034 5.211a17.372 17.372 0 01-1.767-.737 19.012 19.012 0 01-2.723-1.559c-8.256-5.656-10.221-16.129-4.38-23.399a16.146 16.146 0 011.83-1.923c6.18-5.528 16.236-6.109 23.72-.995 8.179 5.6 10.166 15.929 4.541 23.19a2.22 2.22 0 00-.187.212z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M266.781 168.046l-26.71 1.814-3.702-53.499 38.786-2.64 2.809 40.612c.495 7.134-4.516 13.26-11.183 13.713z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M235.865 130.566s-2.199-4.685 1.491-5.766c2.749-.805 4.329 1.39 4.807 2.788 1.618 4.711-3.689 7.054-4.452 11.036"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.686 139.29l-.035-.874-.035-.873h-.008l-.008.001h-.011l-.005.001h.002c.005-.001.025-.004.057-.013.064-.017.175-.055.313-.14.265-.163.689-.534 1.048-1.396l1.615.672c-.489 1.172-1.144 1.843-1.747 2.214a2.954 2.954 0 01-.776.339 2.236 2.236 0 01-.386.068l-.013.001h-.011z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.726 163.912l-1.232-2.445s-2.118-4.346-3.389-6.003c-2.054-2.694-4.659-.746-4.659-.746s-4.969-2.707-6.205-2.143c-3.414 1.559-2.817 14.158.423 15.349 2.508.919 4.062 2.088 7.298 2.919 1.373.351 3.148-.318 4.177-.255"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M220.205 191.534l-7.095-15.654 3.88 17.925 3.215-2.271z"
      fill="#A47C6B"
    />
    <path fill="#FFB027" d="M91.942 38.15h59.326v43.386H91.942z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M145.004 47.69H98.461v-3.497h46.543v3.497zm0 6.897H98.461V51.09h46.543v3.497zm0 6.893H98.461v-3.497h46.543v3.497zm0 6.893H98.461v-3.497h46.543v3.497z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.95 66.624h1.783c7.743 0 14.02 6.279 14.02 14.023v.89h-29.81v-.89c0-7.74 6.269-14.016 14.007-14.023z"
      fill="#BDE7FF"
    />
  </React.Fragment>
);

// ------------------------------
// Welcome
// ------------------------------

export const HeroWelcome = createIllustration(
  describeWithOptions(
    'HeroWelcome',
    'Illustration of a man with a sack full of cash waving at a woman sitting in a chair.'
  ),
  <React.Fragment>
    <path
      clipRule="evenodd"
      d="M150.58 133.141c0 20.296-13.316 29.404-29.677 29.404-16.359 0-29.675-9.108-29.675-29.404 0-37.807 16.195-73.114 29.675-73.114 13.482 0 29.677 35.307 29.677 73.114z"
      fill="#e8f6ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M123.252 97.338v75.989h-4.946V97.338zM136.493 134.46l-9.053 9.055-2.226-2.226 9.054-9.054zM136.493 101.69l-9.053 9.054-2.226-2.226 9.054-9.054zM108.337 132.149l9.144 9.145-2.226 2.226-9.144-9.146zM107.851 115.975l9.145 9.145-2.226 2.226-9.145-9.146z"
      fill="#c3e4ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.1 164.239c.112.15 3.53 4.712 3.96 8.913h6.01c-2.838-5.638-9.815-8.843-9.97-8.913zM56.592 173.152h-6.366c-.038-4.574-2.385-10.528-2.385-10.528s6.985 5.704 8.751 10.528zM58.505 173.152c1.539-3.257 1.631-5.881 1.631-5.881s3.02 3.283 4.307 5.881z"
      fill="#d6eeff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M35.706 93.86c-1.126-3.37-7.3-2.799-7.3-12.195-.002-14.147 19.497-12.533 20.647-23.192 1.15-10.66-11.38-10.405-11.768-19.396C36.897 30.087 47.297 20 58.695 20s5.926 10.305 15.125 16.706 17.922-6.255 23.121 3.811c7.163 13.866-11.152 30.519-9.345 41.148 1.807 10.63 12.543 8.117 12.872 19.796.329 11.68-4.996 9.663-3.527 19.678.927 6.313 5.382 6.265 5.9 18.713.438 10.54-38.331 25.595-63.806 4.147-25.475-21.448.97-37.278-3.33-50.139z"
      fill="#c3e4ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M66.147 66.763v106.115h-4.496V66.763zM83.547 129.238l-9.499 9.499-2.67-2.671 9.499-9.499zM81.466 92.525l-9.499 9.499-2.67-2.67 9.498-9.5zM47.224 71.498l9.499 9.5-2.671 2.67-9.499-9.5z"
      fill="#e8f6ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M75.803 150.603l1.155.82c4.809 3.412 8.737 8.636 11.435 12.916a74.47 74.47 0 013.15 5.509 58.648 58.648 0 011.05 2.137l.057.124.015.034.004.009.002.004-1.19.53 1.19-.53.576 1.295-2.588 1.151-.576-1.294v-.001l-.003-.005-.011-.025a58.227 58.227 0 00-1.043-2.13 71.895 71.895 0 00-3.03-5.298c-2.63-4.171-6.32-9.024-10.677-12.116l-1.155-.82z"
      fill="#aad5ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M102.947 144.913l-.997 1.006c-1.656 1.67-2.914 4.143-3.844 7.042-.924 2.882-1.49 6.075-1.828 9.076a78.8 78.8 0 00-.443 10.831l.006.165.002.04v.011l.068 1.415-2.83.136-.067-1.415 1.3-.062-1.3.061v-.005l-.001-.013-.002-.049a32.58 32.58 0 01-.027-.872 81.592 81.592 0 01.478-10.561c.352-3.11.946-6.501 1.947-9.623.996-3.104 2.43-6.053 4.529-8.171l.997-1.006z"
      fill="#aad5ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M95.27 144.922a4.635 4.635 0 109.27 0 4.635 4.635 0 00-9.27 0zM73.245 154.549a4.635 4.635 0 109.27 0 4.635 4.635 0 00-9.27 0z"
      fill="#aad5ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M110.408 150.053c-.147.195-9.604 12.615-9.846 22.347h-7.637c1.023-3.169 5.457-14.761 17.483-22.347zM88.812 172.408h-6.085c-1.203-4.574-4.847-7.426-4.847-7.426s7.236 1.416 10.932 7.426z"
      fill="#c3e4ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M57.44 129.899c0 16.619-6.888 31.713-22.482 31.713s-22.482-15.094-22.482-31.713c0-19.495 11.784-38.88 22.482-38.88 10.698 0 22.482 19.385 22.482 38.88z"
      fill="#e8f6ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M37.37 122.518v50.36h-4.946v-50.36zM49.782 125.188l-8.175 9.179-2.35-2.094 8.174-9.179zM22.456 137.125l8.175 9.18-2.35 2.093-8.175-9.179zM22.456 123.375l8.175 9.179-2.35 2.093-8.175-9.179z"
      fill="#d6eeff"
      fillRule="evenodd"
    />
    <path
      d="M180.356 176.475H.5c.218-2.288 1.53-4.047 3.117-4.047h173.622c1.587 0 2.899 1.759 3.117 4.047z"
      fill="#d6eeff"
    />
    <path
      d="M97.463 67.35a7.17 7.17 0 019.862 2.44c2.053 3.403.967 7.816-2.435 9.875s-7.807.968-9.86-2.435-.97-7.822 2.433-9.88zm1.001 2.434l.332.55a2.172 2.172 0 00-.677 1.32c-.074.566.027 1.084.25 1.454.327.542.868.808 1.374.912.882.182 1.755-.183 2.59-.13.454.032.807.167.969.434.085.142.192.55.14.936-.051.385-.217.743-.616.985-.256.155-.603.223-.926.203-.324-.02-.617-.139-.765-.266a.418.418 0 00-.567.048.418.418 0 00.047.566c.33.282.774.418 1.238.447.338.02.693-.017 1.025-.14l.328.546.684-.414-.332-.55c.408-.373.615-.863.676-1.32.075-.566-.026-1.083-.25-1.454-.354-.587-1.014-.78-1.596-.821-.844-.089-1.704.302-2.49.15-.359-.073-.658-.232-.846-.545-.086-.142-.193-.55-.141-.935.05-.385.217-.744.616-.985.256-.155.599-.23.923-.21.324.02.617.14.765.267.161.136.43.114.566-.047a.417.417 0 00-.046-.567c-.331-.282-.774-.418-1.238-.446-.338-.021-.69.02-1.022.144l-.328-.545z"
      fill="#0078c2"
    />
    <path
      clipRule="evenodd"
      d="M90.328 77.343a7.178 7.178 0 01-8.69-5.259c-.952-3.858 1.4-7.755 5.249-8.707s7.742 1.404 8.692 5.26-1.401 7.754-5.251 8.706z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      d="M88.608 63.166c3.975 0 7.182 3.22 7.182 7.197a7.178 7.178 0 01-7.182 7.191 7.178 7.178 0 01-7.182-7.191c0-3.976 3.207-7.198 7.182-7.198zm-.4 2.602v.643c-.541.108-.971.42-1.26.78-.356.445-.537.94-.537 1.373 0 .633.326 1.141.705 1.492.661.613 1.597.753 2.284 1.23.373.263.605.561.605.874 0 .166-.12.57-.362.873-.243.304-.57.525-1.036.525-.3 0-.631-.121-.898-.306s-.456-.439-.518-.624a.417.417 0 00-.509-.253.418.418 0 00-.253.509c.138.413.448.759.83 1.024.278.192.601.344.949.412v.636h.798v-.643c.542-.107.972-.42 1.26-.78.357-.445.537-.94.537-1.373 0-.686-.466-1.193-.942-1.53-.677-.513-1.615-.624-2.21-1.161-.269-.249-.443-.539-.443-.905 0-.166.12-.57.363-.873.242-.304.57-.524 1.035-.524.3 0 .632.114.899.3.267.184.456.437.517.623.067.2.31.32.51.253a.418.418 0 00.252-.509c-.137-.413-.447-.759-.83-1.024a2.486 2.486 0 00-.948-.405v-.637z"
      fill="#0078c2"
    />
    <path
      clipRule="evenodd"
      d="M104.53 66.867a.402.402 0 00-.265-.062c-.434.052-1.744.239-2.447.682-2.524 1.592-7.803 5.722-10.187 6.44-2.44.736-7.847 1.077-9.726.95-.494-.033-.599.458-.539.776.313 1.657 1.824 3.512 1.824 3.512l.179 2.556-6.975 22.555c-.65 2.249-.397 4.721.692 6.726l2.974 5.478c.844 1.555 2.128 2.715 3.63 3.279l.032.011 19.25 5.798a.395.395 0 00.226.001l16.068-4.654a.442.442 0 00.308-.46l-3.24-45.794a.446.446 0 00-.201-.345z"
      fill="#0091ea"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M120.138 164.696l-.041 3.5s7.802 1.235 10.447 4.232h-15.543l.651-10.005zM100.659 164.401l-.04 3.931s3.286.432 4.623 4.096H94.604l1.064-4.313.416-3.932z"
      fill="#0b096f"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M150.139 69.361c-.462.548-1.897 1.465-3.025 2.596-.876.878-1.305 1.71-2.528 2.696-.516.418-.977.637-1.416.868l-7.228 12.59c-1.305 2.056-3.215 2.647-4.665 1.972l-12.778-7.12s.109-10.614 1.925-9.418c3.532 2.33 11.571 9.22 11.571 9.22l7.888-9.274-.072-1.248s.002-.868.113-1.805c.112-.937.758-2.046.075-3.7-.683-1.653.95-.511 1.312.275.301.655.142 2.033.512 2.538.313-.171.521-.394.521-.394l3.521-4.573a.384.384 0 01.491-.11l.189.104c.2.11.263.365.135.552-.687 1.01-2.817 3.974-2.76 4.004l.094.114 4.135-4.367a.383.383 0 01.505-.06l.248.172a.38.38 0 01.085.548c-.823 1.064-3.909 4.44-3.85 4.473l.124.12 3.849-3.406a.384.384 0 01.527-.041l.188.153a.383.383 0 01.054.54l-3.635 3.424.105.165 3.156-2.22a.384.384 0 01.509-.001l.088.079a.384.384 0 01.037.534z"
      fill="#fcbda4"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M124.749 54.002s4.101 5.352-1.467 6.772z"
      fill="#0b096f"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M97.08 79.195c-7.36.68-13.956-.03-13.956-.03l.179 2.556s9.715.835 13.778-.286c4.063-1.12 0-2.24 0-2.24z"
      fill="#0078c2"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M115.651 163.339l-.361 4.639 4.807.218.041-3.5zM96.173 163.045l-.505 5.07 4.951.217.04-3.931z"
      fill="#ffb027"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M123.261 97.475l-.667 68.974-8.413.123-1.43-69.533z"
      fill="#364150"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M116.559 97.213l-13.979 69.503h-8.072l9.792-70.148z"
      fill="#364150"
      fillRule="evenodd"
    />
    <path
      d="M103.492 83.278c-2.919.84-7.833 2.228-9.992 2.675-1.162.241-3.301.008-4.289-2.03-1.636-3.374.84-5.385.84-5.385l12.616-4.68c1.525-.799 3.393-1.506 5.477-1.735 2.143-.236 8.621-.004 8.621-.004.908.166 2.675.633 2.676.634.027.016.1.044.209.086.424.165 1.399.543 2.396 1.258 1.374.906 3.104 2.124 4.778 3.303 1.554 1.095 3.061 2.156 4.188 2.901a2.514 2.514 0 011.115 2.346l-2.412 6.809c-.662.893-1.639.599-2.352.106l-3.081-2.643 1.713 8.756A1.91 1.91 0 01124 97.507l-20.605-.856a1.674 1.674 0 01-1.582-1.893z"
      fill="#ffb027"
    />
    <path
      clipRule="evenodd"
      d="M110.631 60.965c-.407-1.202-4.113-2.71-5.002-.012-1.147 3.478 5.464 5.306 5.464 5.306l-.289 5.768h-.735s1.32 2.854 5.309 3.09c3.99.235 2.612-2.692 2.612-2.692l-.675-.184-.344-4.56s5.869.456 7.094-.18c0 0 .931-5.735.046-9.801l-4.819-4.358-7.779.573z"
      fill="#fcbda4"
      fillRule="evenodd"
    />
    <path
      d="M107.971 53.607c-4.018 4.362-.383 5.843-.383 5.843 2.349.08 3.043 1.515 3.043 1.515 3.975-.682 2.483-4.043 2.483-4.043 17.349 5.939 17.315-6.248 16.065-6.772-1.25-.525-3.447 3.144-3.447 3.144s-.673-6.746-5.034-6.765c-3.061-.013-4.166 4.63-4.166 4.63s-2.766-1.668-6.259.499l-.363-2.025-1.326.239.456 2.538-.242.116-1.767-1.803-.961.945z"
      fill="#0b096f"
    />
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="M121.117 65.282c-.091.583-.954.932-1.928.78-.973-.153-1.657-.968-1.566-1.552.091-.583 1.152-.077 2.125.076.974.153 1.46.112 1.369.696z"
        fill="#fff"
      />
      <path d="M116.971 67.68l-3.862-.828 3.843 2.6z" fill="#987263" />
      <path d="M124.282 86.918l-.957-3.805 2.583 5.2z" fill="#ffc100" />
      <path d="M120.229 60.58l1.335 3.267-1.6.02z" fill="#f49e7c" />
      <path
        d="M118.634 60.897a.809.809 0 11-1.591.29.809.809 0 011.591-.29zM122.831 60.897a.809.809 0 11-1.591.29.809.809 0 011.591-.29z"
        fill="#0b096f"
      />
      <path d="M113.137 113.985l1.816-8.098-1.367 24.55z" fill="#20262d" />
      <path
        d="M104.22 73.59l-2.002 2.043L98.731 73l2.917-2.592s.953-3.903 1.61-4.357c.79-.546 1.869-.835 2.52-.843.651-.009 1.313.827 1.223 1.202 0 0 1.258.535 1.277 1.718 0 0 .637.668.341 1.54 0 0 .475.837-.219 1.451-.695.615-2.646 1.662-4.18 2.471z"
        fill="#fcbda4"
      />
      <path
        d="M93.665 84.91c-1.523.62-3.61.62-4.597-1.13-1.843-3.267.983-5.242.983-5.242l11.597-8.13 2.493 3.264S96.87 83.609 93.665 84.91z"
        fill="#fcbda4"
      />
      <path
        d="M106.517 72.254l-1.784 10.83 5.999.47-2.596-12.268s-.219-.209-.833.094c-.557.275-.786.874-.786.874z"
        fill="#0091ea"
      />
      <path
        d="M106.517 72.254l-2.07 12.124 2.999.234v-1.316l-.642-.045.499-11.87s-.308.152-.466.338c-.25.295-.32.535-.32.535z"
        fill="#0078c2"
      />
      <path d="M331.535 100.239h14.564l1.967-17.933h-14.564z" fill="#dceeff" />
      <path
        d="M331.284 80.877h17.714l-2.281 20.792h-17.713zm2.836 2.858l-1.653 15.075h11.415l1.653-15.075z"
        fill="#c3e4ff"
      />
      <path d="M330.749 100.239h14.835l1.967-17.933h-14.835z" fill="#fff" />
      <path
        d="M332.432 82.02h15.465l-2.029 18.505h-15.465zm.567.572l-1.904 17.362h14.206l1.904-17.362z"
        fill="#c3e4ff"
      />
      <path d="M333.59 97.871h9.672l1.448-13.197h-9.672z" fill="#e8f6ff" />
      <path d="M370 87.64l-1.363 12.425H350.74l1.363-12.425z" fill="#dceeff" />
      <path
        d="M350.059 86.21h21.045l-1.677 15.284h-21.045zm2.835 2.86l-1.05 9.565h14.748l1.049-9.566z"
        fill="#c3e4ff"
      />
      <path
        d="M369.599 87.64l-1.363 12.425h-18.229l1.363-12.425z"
        fill="#fff"
      />
      <path
        d="M351.086 87.354h18.86l-1.426 12.996h-18.859zm.568.572l-1.301 11.853h17.6l1.3-11.853z"
        fill="#c3e4ff"
      />
      <path d="M365.756 90.077l-.829 7.55H353.85l.829-7.55z" fill="#e8f6ff" />
      <path
        d="M309.5 95.42c0 .952.845 1.723 1.887 1.723h16.977v-3.445h-16.977c-1.042 0-1.887.771-1.887 1.723z"
        fill="#c3e4ff"
      />
      <path
        d="M320.919 95.42c0 .551.489.998 1.092.998h6.307c.026 0 .046-.019.046-.042v-1.911c0-.023-.02-.041-.046-.041h-6.307c-.603 0-1.092.446-1.092.997z"
        fill="#e8f6ff"
      />
      <path
        d="M307.131 97.143v3.444h18.53c1.042 0 1.887-.77 1.887-1.722 0-.951-.845-1.722-1.887-1.722z"
        fill="#aad5ff"
      />
      <path
        d="M307.131 97.878v1.975h7.619c.597 0 1.082-.442 1.082-.988s-.485-.987-1.082-.987z"
        fill="#e8f6ff"
      />
      <path d="M301.115 102.876h63.877v-2.45h-63.877z" fill="#c3e4ff" />
      <path
        d="M364.992 102.876H375.5v-2.45h-10.508zM316.684 102.876l4.988 7.143v-7.143z"
        fill="#aad5ff"
      />
      <path
        d="M313.923 102.876l4.988 7.143h2.761l-4.988-7.143z"
        fill="#c3e4ff"
      />
      <path d="M353.269 102.876l4.988 7.143v-7.143z" fill="#aad5ff" />
      <path
        d="M350.509 102.876l4.988 7.143h2.76l-4.988-7.143z"
        fill="#c3e4ff"
      />
    </g>
    <path
      d="M206.637 82.067a1.75 1.75 0 01-1.75-1.75V65.329c0-.967.783-1.75 1.75-1.75h79.255c.966 0 1.75.783 1.75 1.75v14.988a1.75 1.75 0 01-1.75 1.75z"
      fill="#fff"
      stroke="#e4e5e6"
      strokeWidth={0.5}
    />
    <path
      d="M216.778 67.021c3.52 0 6.36 2.598 6.36 5.804s-2.84 5.8-6.36 5.8c-3.519 0-6.36-2.593-6.36-5.8s2.841-5.804 6.36-5.804zm-.354 2.099v.518a1.95 1.95 0 00-1.116.63c-.315.358-.475.758-.475 1.107 0 .51.288.92.624 1.203.586.495 1.414.607 2.023.992.329.212.535.452.535.705 0 .133-.105.46-.32.704s-.505.423-.918.423c-.265 0-.559-.098-.795-.247-.237-.149-.404-.353-.458-.503-.06-.161-.274-.258-.451-.204-.176.054-.283.25-.224.41.122.334.396.612.735.826.246.155.532.278.84.332v.513h.707v-.518c.479-.087.86-.338 1.116-.629.315-.359.475-.758.475-1.107 0-.553-.413-.962-.834-1.234-.6-.414-1.431-.503-1.957-.936-.238-.201-.392-.435-.392-.73 0-.134.105-.46.32-.705s.505-.422.918-.422c.265 0 .559.092.795.241.237.15.404.354.459.503.059.161.273.258.45.204.176-.054.284-.25.224-.41-.121-.333-.396-.612-.735-.826a2.319 2.319 0 00-.839-.327v-.513z"
      fill="#1a69fe"
    />
    <path
      d="M227.763 68.076h52.034v3.165h-52.034zM227.763 74.603h52.034v3.165h-52.034z"
      fill="#e4e7ea"
    />
    <path
      d="M206.637 105.274a1.75 1.75 0 01-1.75-1.75V88.536c0-.966.783-1.75 1.75-1.75h79.255c.966 0 1.75.784 1.75 1.75v14.988a1.75 1.75 0 01-1.75 1.75z"
      fill="#fff"
      stroke="#e4e5e6"
      strokeWidth={0.5}
    />
    <path
      d="M216.778 90.228c3.52 0 6.36 2.598 6.36 5.805 0 3.206-2.84 5.799-6.36 5.799-3.519 0-6.36-2.593-6.36-5.8 0-3.206 2.841-5.804 6.36-5.804zm-.354 2.1v.518c-.48.086-.86.338-1.116.628-.315.36-.475.759-.475 1.108 0 .51.288.92.624 1.203.586.495 1.414.607 2.023.992.329.212.535.452.535.705 0 .134-.105.46-.32.704s-.505.423-.918.423c-.265 0-.559-.098-.795-.247-.237-.149-.404-.353-.458-.503-.06-.16-.274-.258-.451-.204-.176.054-.283.25-.224.41.122.334.396.612.735.826.246.156.532.278.84.332v.514h.707v-.519c.479-.086.86-.338 1.116-.629.315-.359.475-.758.475-1.107 0-.553-.413-.962-.834-1.234-.6-.414-1.431-.503-1.957-.936-.238-.2-.392-.435-.392-.73 0-.134.105-.46.32-.704a1.17 1.17 0 01.918-.423c.265 0 .559.092.795.241.237.15.404.354.459.504.059.16.273.258.45.203.176-.054.284-.25.224-.41-.121-.333-.396-.612-.735-.826a2.319 2.319 0 00-.839-.327v-.513z"
      fill="#1a69fe"
    />
    <path
      d="M227.763 91.283h52.034v3.165h-52.034zM227.763 97.81h52.034v3.165h-52.034z"
      fill="#e4e7ea"
    />
    <path
      d="M206.637 128.481a1.75 1.75 0 01-1.75-1.75v-14.988c0-.966.783-1.75 1.75-1.75h79.255c.966 0 1.75.784 1.75 1.75v14.988a1.75 1.75 0 01-1.75 1.75z"
      fill="#fff"
      stroke="#e4e5e6"
      strokeWidth={0.5}
    />
    <path
      d="M216.778 113.436c3.52 0 6.36 2.597 6.36 5.804 0 3.206-2.84 5.799-6.36 5.799-3.519 0-6.36-2.593-6.36-5.799 0-3.207 2.841-5.804 6.36-5.804zm-.354 2.098v.519a1.95 1.95 0 00-1.116.629c-.315.359-.475.758-.475 1.107 0 .51.288.921.624 1.204.586.494 1.414.607 2.023.992.329.212.535.452.535.704 0 .134-.105.46-.32.704a1.17 1.17 0 01-.918.423c-.265 0-.559-.097-.795-.246-.237-.149-.404-.354-.458-.504-.06-.161-.274-.257-.451-.203-.176.054-.283.249-.224.41.122.333.396.612.735.826.246.155.532.278.84.332v.513h.707v-.518c.479-.087.86-.339 1.116-.629.315-.359.475-.759.475-1.108 0-.553-.413-.962-.834-1.233-.6-.414-1.431-.504-1.957-.937-.238-.201-.392-.435-.392-.73 0-.134.105-.459.32-.704s.505-.423.918-.423c.265 0 .559.093.795.242.237.149.404.353.459.503.059.161.273.258.45.204.176-.054.284-.25.224-.41-.121-.334-.396-.612-.735-.826a2.31 2.31 0 00-.839-.327v-.514z"
      fill="#1a69fe"
    />
    <path
      d="M227.763 114.49h52.034v3.165h-52.034zM227.763 121.017h52.034v3.165h-52.034z"
      fill="#e4e7ea"
    />
    <path
      clipRule="evenodd"
      d="M347.675 198.876l6.121 13.072h2.776l-3.683-13.713zM316.408 198.876l6.121 13.072h2.775l-3.682-13.713zM269.705 211.948h2.776l6.121-13.072-5.214-.641zM300.972 211.948h2.776l6.121-13.072-5.214-.641z"
      fill="#aad5ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M273.119 168.284v31.652l36.196.07 5.953-31.31zm2.756 2.532l36.125.353-5.005 26.327-31.12-.06z"
      fill="#aad5ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M320.617 148.828l-9.729 51.178h42.446l9.73-51.178z"
      fill="#c3e4ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M309.204 148.828l-9.73 51.178h41.931l9.73-51.178z"
      fill="#d6eeff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M298.807 200.006h49.913c2.548 0 4.614-1.877 4.614-4.193v-9.664c0-2.316-2.066-4.193-4.614-4.193h-49.913c-2.548 0-4.614 1.877-4.614 4.193v9.664c0 2.316 2.066 4.193 4.614 4.193z"
      fill="#c3e4ff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M272.931 200.006h44.206v-18.05h-44.206z"
      fill="#c3e4ff"
      fillRule="evenodd"
    />
    <path
      d="M324.021 130.765c6.115.777 3.553 7.186 1.834 10.646-2.017 4.062-.25 6.785-.25 6.785l9.452-3.054c-3.469-4.015.362-6.25-.581-11.037-.846-4.294-5.438-6.232-8.34-6.14-1.448.045-2.633.385-3.572.82a1.23 1.23 0 00-.102.053c-1.714-1.602-4.086-2.559-6.829-2.559-5.639 0-10.705 4.049-10.705 9.425s4.571 9.735 10.21 9.735c5.64 0 10.211-4.359 10.211-9.735 0-1.838-.476-3.52-1.328-4.939z"
      fill="#0b096f"
    />
    <path
      clipRule="evenodd"
      d="M314.141 145.696s2.097.056 2.99-.388l-2.879 1.645z"
      fill="#cf3e83"
      fillRule="evenodd"
    />
    <path
      d="M306.324 138.503c.314 2.215 1.13 5.334 3.144 6.553 1.068.647 3.014.726 4.673.639l.38 4.287 5.413-2.027s-.521-4.732-.746-6.545c1.088-.898 2.941-1.314 2.945-2.838 0-.529-.339-1.442-1.591-1.445-1.68-.002-1.44 1.831-1.44 1.831a.944.944 0 01-.625.002c-1.664-.063-6.291-.697-9.437-5.589-.367 1.647-1.13 3.699-2.716 5.132z"
      fill="#fcbda4"
    />
    <path
      clipRule="evenodd"
      d="M309.583 138.954l-1.2 2.648 1.392-.026z"
      fill="#f49e7c"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M310.86 139.069c.03.529.372.941.764.921.391-.021.685-.466.655-.995s-.372-.941-.764-.921c-.391.021-.685.466-.655.995zM307.181 138.985c.03.529.372.941.764.92.391-.02.685-.466.655-.995s-.372-.941-.763-.92c-.392.02-.686.466-.656.995z"
      fill="#0d0b6d"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M310.182 142.658l-.066.187-.066.186h.001v.001h.002l.004.001a.104.104 0 00.012.003l.04.01c.034.006.08.015.138.021.116.012.277.017.47-.01.388-.056.888-.241 1.376-.735l-.321-.262c-.421.426-.83.567-1.122.609a1.434 1.434 0 01-.445-.006l-.021-.005-.003-.001z"
      fill="#0b096f"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M314.144 145.696s2.097.056 2.991-.388l-2.879 1.645z"
      fill="#000"
      fillRule="evenodd"
      opacity={0.403}
    />
    <path
      clipRule="evenodd"
      d="M322.57 169.768l1.12-.015-.003-.05c-.386.025-.759.045-1.117.065z"
      fill="#c83820"
      fillRule="evenodd"
    />
    <path
      d="M299.737 180.818l3.7.422 5.957-8.48.218-2.818 9.076-.123 3.874-.051h.008c.358-.02.731-.04 1.117-.065 4.88-.31 11.64-1.174 12.104-3.564.842-4.33-5.525-12.733-6.277-13.707a10.096 10.096 0 01-.071-.093c-1.067-1.387-2.141-2.466-3.143-3.04-1.949-1.117-3.777-1.362-5.514-1.358l-.465-3.068-7.085.89.386 2.55c-2.081.182-3.496.552-4.821 1.659-4.254 3.553-4.567 16.863-4.567 16.863z"
      fill="#ffb027"
    />
    <path
      clipRule="evenodd"
      d="M318.688 169.819v.059s1.625.003 3.874-.11z"
      fill="#c83820"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M308.031 164.778c.565 1.441 1.163 2.966 1.353 5.155l.716-.051c-.197-2.284-.839-3.92-1.411-5.378a30.687 30.687 0 01-.534-1.431c-.62-1.849-.871-3.781.4-7.085l-.679-.216c-1.323 3.44-1.071 5.515-.409 7.491.171.512.366 1.008.564 1.515z"
      fill="#000"
      fillRule="evenodd"
      opacity={0.201}
    />
    <path
      d="M308.978 168.899s-.583.135-.594.468c0 .095 3.548.705 3.548.705l-.772.042-.803-.035s-.417.07-.441.386c-.008.125.731.258 1.591.361 1.336.16 2.297.019 3.237-.215l.229-.055c.967-.231 1.399-.274 2.643-.891a11.849 11.849 0 001.706-1.092l-.897-3.02-1.237-.385s-.574-.204-1.543-.367c-.609-.102-1.203-.062-1.831-.124l.357 1.19c.04.027.086.116.121.15-.027.02-.053.043-.083.068-.257.214-.742.592-2.83 1.066-.419.095-1.824.113-1.824.113-.414.054-1.281.127-1.341.57-.015.11.136.228.136.228s-.545.035-.578.484c-.014.176 1.206.353 1.206.353z"
      fill="#fcbda4"
    />
    <path
      clipRule="evenodd"
      d="M324.17 156.904l-.615.338 3.196 4.815-8.097 2.915.265.609 8.886-3.2z"
      fill="#000"
      fillRule="evenodd"
      opacity={0.2}
    />
    <path
      d="M307.94 167.63c.876-.597 1.953-1.246 2.406-1.177.554.083-.364 1.453-1.027 2.344 1.198.358 2.791.905 3.071 1.356.194.313-.467.458-1.295.521.786.288 1.477.608 1.653.891.225.362-.698.499-1.697.545.908.314 1.763.681 1.962 1.001.334.54-1.876.58-3.095.562l.402 1.226c.113.347.483.571.879.532l4.302-.426c.512-.05.848-.511.701-.96l-3.92-11.975c-.114-.347-.484-.57-.88-.531l-4.302.425c-.512.051-.848.511-.701.96z"
      fill="#20262d"
    />
    <path
      clipRule="evenodd"
      d="M308.784 167.085c.637-.384 1.249-.68 1.562-.632.549.082-.348 1.429-1.009 2.32l.01.032c1.195.358 2.765.9 3.043 1.348.194.313-.467.458-1.295.521.786.288 1.477.608 1.653.891.225.362-.698.499-1.697.545.908.314 1.763.681 1.962 1.001.252.408-.946.53-2.073.558l.37 1.132c.114.347.484.571.88.532l4.302-.426c.512-.05.848-.511.701-.96l-3.921-11.975c-.113-.347-.483-.57-.879-.531l-4.302.425c-.512.051-.848.511-.701.96z"
      fill="#585c62"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M311.242 163.165c.129.392.407.66.622.598.214-.063.284-.432.155-.824-.129-.393-.407-.66-.622-.598-.214.063-.284.432-.155.824zM311.893 165.197c.129.392.408.66.622.597.215-.062.284-.431.155-.823-.129-.393-.407-.66-.621-.598-.215.063-.284.431-.156.824z"
      fill="#20262d"
      fillRule="evenodd"
    />
    <path
      d="M291.608 209.873l.418 3.325s-7.787 1.858-10.714 4.621l14.516.023-.543-9.522zM274.899 194.42l-1.672 2.995s-7.65-2.368-11.782-1.529l12.005 7.25 5.473-8.164z"
      fill="#0b096f"
    />
    <path
      d="M295.461 209.156l-3.857 1.396.033 2.759 3.942-.131zM278.554 195.62l-4.078-.775-1.652 2.327 3.382 1.876z"
      fill="#ffb027"
    />
    <path
      d="M284.504 190.84l1.997 19.72h9.246l-.41-28.632 17.648.111c5.035 0 9.905-3.935 9.556-8.508l-.549-4.656-26.952-7.247c-4.99-1.026-6.72.33-9.955 5.57l-14.282 26.644 7.846 4.771z"
      fill="#20262d"
    />
    <path
      clipRule="evenodd"
      d="M317.137 168.284v31.652l36.197.07 5.953-31.31zm2.757 2.532l36.125.353-5.006 26.327-31.119-.06z"
      fill="#aad5ff"
      fillRule="evenodd"
    />
  </React.Fragment>
);
