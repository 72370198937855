/** @jsx jsx */

import { CSSProperties, ReactNode, memo, useMemo } from 'react';
import { buildDataAttributes, jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { isDefined } from '@balance-web/utils';

import { useTableCellContext } from '../cellContext';
import { siftAriaAttributes } from '../utils';
import { CellAlign } from '../types';

import { CellContent } from './CellContent';

// Main
// ------------------------------

export type TableCellProps = UseCellStylesProps & {
  /** The contents of the cell. */
  children: ReactNode;
  /** Optionally provide an ID. */
  id?: string;
  /**
   * The role of the cell.
   * @default 'gridcell'
   */
  role?: 'gridcell' | 'rowheader' | 'columnheader';
  /**
   * When only a subset of columns are present, use the aria-colindex attribute
   * to let assistive technologies know what portions of the content are being
   * displayed, and that all the table's content is not present.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-colindex
   */
  'aria-colindex'?: number;
  /**
   * The aria-colspan attribute defines the number of columns spanned by an
   * individual cell. The value should be a positive integer.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-colspan
   */
  'aria-colspan'?: number;
  /**
   * The aria-rowspan attribute defines the number of rows spanned by an
   * individual cell. The value should be a positive integer.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-rowspan
   */
  'aria-rowspan'?: number;
};

function UnmemoizedTableCell({
  children,
  id,
  role = 'gridcell',
  ...props
}: TableCellProps) {
  const { dataAttributes, css, style } = useCellStyles(props);
  const ariaAttributes = siftAriaAttributes(props);

  return (
    <div
      id={id}
      role={role}
      css={css}
      style={style}
      {...ariaAttributes}
      {...dataAttributes}
      {...props}
    >
      <CellContent>{children}</CellContent>
    </div>
  );
}

// NOTE: only effective when `children` is a string or number.
// Consumers must memoize non-primitive children for best performance.
export const TableCell = memo(UnmemoizedTableCell);

// Styles
// ------------------------------

export type UseCellStylesProps = {
  /**
   * Sets the horizontal alignment of cell contents.
   * @default 'left'
   */
  align?: CellAlign;
  /** Sets the maximum width of the cell. */
  maxWidth?: number | string;
  /** Sets the minimum width of the cell. */
  minWidth?: number | string;
  /** Sets the width of the cell. */
  width?: number | string;
  /**
   * Applies "sticky" positioning to the cell, offset relative to its nearest
   * scrolling ancestor.
   *
   * - Positive values e.g. `0, 240` will offset the cell to the **left**.
   * - Negative values e.g. `-0, -240` will offset the cell to the **right**.
   */
  stickyOffset?: number;
  /**
   * Whether the cell should render a divider between it and the next cell.
   * @default stickyOffset !== undefined
   */
  showDivider?: boolean;
  /**
   * Sets the inline [style](https://reactjs.org/docs/dom-elements.html#style) for
   * the element. Only use as a **last resort**.
   */
  UNSAFE_style?: CSSProperties;
};

// TODO: review memoization perf. needs to be measured
export function useCellStyles(props: UseCellStylesProps) {
  const { borderWidth, palette, spacing } = useTheme();
  const { alignY, density } = useTableCellContext();
  const {
    align = 'start',
    maxWidth,
    minWidth,
    width,
    showDivider: showDividerPreference,
    stickyOffset,
    UNSAFE_style,
  } = props;
  const isSticky = isDefined(stickyOffset);
  const showDivider = showDividerPreference ?? isSticky;
  const stickyEdge = isNegative(stickyOffset) ? 'right' : 'left';

  // dynamic properties: would drastically fork class names so we pass straight
  // through to the style prop
  const style = useMemo(() => {
    let baseStyles = {
      ...UNSAFE_style,
      maxWidth,
      minWidth,
      width,
    };

    if (isSticky && typeof stickyOffset === 'number') {
      return { ...baseStyles, [stickyEdge]: Math.abs(stickyOffset) };
    }

    return baseStyles;
  }, [
    isSticky,
    stickyEdge,
    stickyOffset,
    maxWidth,
    minWidth,
    width,
    UNSAFE_style,
  ]);

  // static-ish properties: use data attributes to target prop-specific styles,
  // which limits the number of class names generated by emotion
  const sticky = isSticky ? stickyEdge : undefined;
  const dataAttributes = useMemo(
    () =>
      // reduce noise in the markup by omitting the default values
      buildDataAttributes({
        align: align === 'left' ? undefined : align,
        'align-y': alignY === 'center' ? undefined : alignY,
        density: density === 'regular' ? undefined : density,
        layout: width ? 'fixed' : undefined,
        'show-divider': showDivider,
        sticky,
      }),
    [align, alignY, density, showDivider, sticky, width]
  );

  const defaultBorder = `${borderWidth.standard} solid ${palette.border.standard}`;

  const css = useMemo(
    () => [
      {
        backgroundColor: palette.background.base,
        borderBottom: defaultBorder,
        boxSizing: 'border-box' as const,
        display: 'flex',
        minWidth: 0, // resolve flex issues with text trucation
        paddingInline: spacing.medium,

        // defaults
        alignItems: 'center', // alignY
        justifyContent: 'flex-start', // align
        paddingBlock: spacing.medium, // density
        // layout
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
      },

      // GROUP
      {
        '[data-rowgroup-type="head"] &': {
          userSelect: 'none' as const,
        },
        // Hide border bottom on last row of group
        '[data-rowgroup-type="body"] [role="row"]:last-of-type &, [data-rowgroup-type="foot"] &': {
          borderBottom: 0,
        },
        /**
         *
         * Above css hides border on all rows when dragging is enabled because each row is wrapped in a drag wrapper which makes it :last-of-type.
         * The next 2 selectors undo above css and reapplies it targetting the drag wrapper as :last-of-type.
         * */
        '[data-rowgroup-type="body"] [data-rbd-draggable-id] [role="row"] &, [data-rowgroup-type="foot"] &': {
          borderBottom: defaultBorder,
        },
        '[data-rowgroup-type="body"] [data-rbd-draggable-id] [role="row"][data-row-disabled="true"] &': {
          // this color is hard coded as it's reverse-engineered from the standard border based on opacity
          borderBottomColor: palette.table.cellBorderDisabled,
        },
        '[data-rowgroup-type="body"] [data-rbd-draggable-id]:last-of-type [role="row"] &, [data-rowgroup-type="foot"] &': {
          borderBottom: 0,
        },
        '[data-rowgroup-type="foot"] &': {
          borderTop: `${borderWidth.standard} solid ${palette.border.standard}`,
        },
      },

      // ROW

      // tone
      {
        '[data-row-tone="cautious"] &': {
          backgroundColor: palette.table.rowBackgroundCautious,
        },
        '[data-row-tone="critical"] &': {
          backgroundColor: palette.table.rowBackgroundCritical,
        },
        '[data-row-tone="positive"] &': {
          backgroundColor: palette.table.rowBackgroundPositive,
        },
        '[data-row-disabled="true"] &': {
          backgroundColor: palette.table.rowBackgroundDisabled,
          borderBottomColor: palette.table.cellBorderDisabled,
          opacity: 0.25,
        },
      },
      // interaction
      {
        '&[data-interaction]': {
          flex: '0 0 auto',
          position: 'relative' as const,
          width: 40,
        },
      },
      // interaction: selection
      {
        '[role="row"][aria-selected="true"] &': {
          backgroundColor: palette.background.selectableSelected,
        },
      },
      // interaction: drag
      {
        '&[data-interaction="drag"]': {
          color: palette.text.dim,

          ':hover, :focus': {
            color: palette.text.base,
          },
        },
      },

      // CELL

      // sorting
      {
        '[role="columnheader"][tabindex]&': {
          cursor: 'pointer',
        },
      },

      // sticky cells display a divider
      {
        '&[data-sticky]': {
          position: 'sticky' as const,
        },
        '&:not([data-sticky="right"])[data-show-divider="true"]': {
          borderRightColor: palette.border.standard,
          borderRightStyle: 'solid' as const,
          borderRightWidth: borderWidth.standard,
        },
        '&[data-sticky="right"][data-show-divider="true"]': {
          borderLeftColor: palette.border.standard,
          borderLeftStyle: 'solid' as const,
          borderLeftWidth: borderWidth.standard,
        },
      },
      // layout rules
      {
        // '&[data-layout="fluid"]': { flex: '1 1 0%' },
        '&[data-layout="fixed"]': { flex: '0 0 auto' },
      },
      // align rules
      {
        // '&[data-align="left"]': { justifyContent: 'flex-start' },
        '&[data-align="center"]': { justifyContent: 'center' },
        '&[data-align="right"]': { justifyContent: 'flex-end' },
      },
      {
        '&[data-align-y="top"]': { alignItems: 'flex-start' },
        // '&[data-align-y="center"]': { alignItems: 'center' },
        '&[data-align-y="bottom"]': { alignItems: 'flex-end' },
      },
      // density rules
      {
        '&[data-density="compact"]': { paddingBlock: spacing.small },
        // '&[data-density="regular"]': { paddingBlock: spacing.medium },
        '&[data-density="spacious"]': { paddingBlock: spacing.large },
      },
    ],
    [borderWidth, palette, spacing]
  );

  return { dataAttributes, css, style };
}

function isNegative(value?: number) {
  if (typeof value === 'undefined') {
    return false;
  }

  const isNegativeZero = 1 / value === -Infinity;
  return isNegativeZero || value < 0;
}
