import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonInvoicesLogoName = 'ReckonInvoicesLogo';
export const ReckonInvoicesAppName = 'Invoices';
export const ReckonInvoicesAppLongName = 'Reckon Invoices';
export const ReckonInvoicesLogoDescription = 'Reckon Invoices Application Logo';

export const ReckonInvoicesLogo = createIllustration(
  {
    name: ReckonInvoicesLogoName,
    description: ReckonInvoicesLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#a)"
      />
    </mask>
    <mask
      id="d"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#c)"
      />
    </mask>
    <g mask="url(#d)">
      <path fill="url(#e)" d="M0 0h60v60H0z" />
      <path
        d="M44.756 11.162H14.472c-.798 0-1.472.647-1.472 1.413V47.36c0 .685.378 1.332 1.009 1.655.63.323 1.388.282 1.976-.08l2.65-1.695a1.73 1.73 0 0 1 1.767 0l3.111 1.977c.674.403 1.515.403 2.146 0l3.112-1.977a1.73 1.73 0 0 1 1.766 0l3.112 1.977c.337.201.715.323 1.094.323.378 0 .758-.122 1.052-.323l3.111-1.977a1.73 1.73 0 0 1 1.767 0l2.65 1.694a2.028 2.028 0 0 0 1.976.08 1.856 1.856 0 0 0 1.01-1.654l.002-34.785c-.083-.766-.757-1.413-1.556-1.413ZM38.53 39.29H20.698c-.589 0-1.095-.445-1.095-1.05 0-.564.464-1.05 1.095-1.05h17.875c.588 0 1.094.445 1.094 1.05a1.162 1.162 0 0 1-1.137 1.05Zm0-5.973H20.698c-.589 0-1.095-.445-1.095-1.05 0-.564.464-1.05 1.095-1.05h17.875c.588 0 1.094.445 1.094 1.05a1.162 1.162 0 0 1-1.137 1.05Zm0-6.012H20.698c-.589 0-1.095-.445-1.095-1.05 0-.564.464-1.05 1.095-1.05h17.875c.588 0 1.094.445 1.094 1.05a1.131 1.131 0 0 1-1.137 1.05Zm0-5.973H20.698c-.589 0-1.095-.444-1.095-1.05 0-.564.464-1.05 1.095-1.05h17.875c.588 0 1.094.445 1.094 1.05a1.13 1.13 0 0 1-1.137 1.05Z"
        fill="#fff"
      />
      <g filter="url(#f)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.374 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.374 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BF676" />
        <stop offset={1} stopColor="#05B723" />
      </linearGradient>
      <filter
        id="f"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_14050_1132"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_14050_1132"
          result="shape"
        />
      </filter>
    </defs>
  </React.Fragment>
);
