import React, { Fragment } from 'react';
import type { PropsWithChildren } from 'react';

import { UnsupportedBrowserPage } from './UnsupportedBrowserPage';
import { useUnsupportedBrowser } from './useUnsupportedBrowser';

export function UnsupportedBrowserGate({
  children,
  pattern,
}: PropsWithChildren<{
  pattern?: RegExp;
}>) {
  const supported = useUnsupportedBrowser({
    pattern,
  });

  if (supported.loading) {
    return null;
  }

  if (!supported.supported) {
    return <UnsupportedBrowserPage />;
  }

  return <Fragment>{children}</Fragment>;
}
