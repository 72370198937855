import React, { ReactNode, useContext } from 'react';
import { Transition } from 'react-transition-group';
import { useScrollLock } from '@balance-web/utils';

import { ProductMenuControllerContextType, TransitionState } from './types';

type ProductMenuControllerProps = {
  isOpen?: boolean;
  onClose: () => void;
  children: ReactNode;
};

const ProductMenuControllerContext = React.createContext<null | ProductMenuControllerContextType>(
  {
    onClose: () => {},
    transitionState: 'exited',
  }
);

export const ProductMenuControllerContextProvider =
  ProductMenuControllerContext.Provider;

export const useProductMenuControllerContext = () => {
  let context = useContext(ProductMenuControllerContext);
  if (!context) {
    throw new Error(
      'ProductMenu must be wrapped in a <ProductMenuController>. You should generally do this outside of the component that renders the <ProductMenu>. See https://balance.reckon.com/package/side-drawer#productMenucontroller for how to use it and why.'
    );
  }

  return context;
};

export const ProductMenuController = ({
  isOpen,
  onClose,
  children,
}: ProductMenuControllerProps) => {
  useScrollLock({ listenWhen: !!isOpen });

  return (
    <Transition appear mountOnEnter unmountOnExit in={isOpen} timeout={150}>
      {(transitionState: TransitionState) => (
        <ProductMenuControllerContextProvider
          value={{
            onClose,
            transitionState,
          }}
        >
          {children}
        </ProductMenuControllerContextProvider>
      )}
    </Transition>
  );
};
