import { TRANSITION_CURVE } from './constants';

export const useAnimatedExpandCollapseStyles = () => {
  return {
    wrapperCollapsed: {
      display: 'grid',
      gridTemplateRows: '0fr',
      transition: `grid-template-rows 300ms ${TRANSITION_CURVE}, opacity 300ms ${TRANSITION_CURVE}`,
      opacity: 0,
    },
    innerWrapper: {
      overflow: 'hidden',
    },
    wrapperExpanded: {
      gridTemplateRows: '1fr',
      opacity: 1,
    },
  };
};
