/** @jsx jsx */

import { jsx } from '@balance-web/core';
import { Inline } from '@balance-web/inline';
import { useTheme } from '@balance-web/theme';
import { Flex } from '@balance-web/flex';
import { Box } from '@balance-web/box';

import { AppSwitcher } from './AppSwitcher';
import { AppInfo } from './AppInfo';
import { UserMenu } from './UserMenu';
import type { AppHeaderConfig } from './types';
import { Navigation } from './Navigation';
import { UtilityActionsRenderer } from './UtilityActionsRenderer';

export type AppHeaderProps = AppHeaderConfig;
export const AppHeader = ({
  appName,
  appVersion,
  appIconUrl,
  appSwitcher,
  pages,
  selectedPage,
  bookInfo,
  userMenu,
  utilityActions,
  globalActions,
  isLoading,
}: AppHeaderProps) => {
  const { borderWidth, palette } = useTheme();

  return (
    <Box>
      {isLoading ? (
        <Box height={APP_HEADER_HEIGHT} />
      ) : (
        <Inline
          width="100%"
          height={APP_HEADER_HEIGHT}
          paddingX="large"
          css={{
            alignItems: 'stretch',
            borderBottom: `${borderWidth.standard} solid ${palette.global.border}`,
          }}
        >
          {appSwitcher && <AppSwitcher {...appSwitcher} />}
          <Flex paddingLeft="small" alignItems="center">
            <AppInfo
              appIconUrl={appIconUrl}
              appName={appName}
              bookName={bookInfo?.bookName}
              onClick={bookInfo?.onClick}
            />
          </Flex>

          {selectedPage && pages?.length ? (
            <Navigation
              pages={pages}
              selectedPage={selectedPage}
              globalActions={globalActions}
            />
          ) : (
            <div css={{ flex: 1 }} />
          )}

          <Inline gap="large" alignY="center">
            <UtilityActionsRenderer utilityActions={utilityActions} />
            {userMenu && <UserMenu appVersion={appVersion} {...userMenu} />}
          </Inline>
        </Inline>
      )}
    </Box>
  );
};

const APP_HEADER_HEIGHT = 64;
