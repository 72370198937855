/** @jsx jsx */
import React, { ComponentType, ReactNode, forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { IconProps } from '@balance-web/icon';
import { Text } from '@balance-web/text';
import { LinkComponentProps, useLinkComponent } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import { Inline } from '@balance-web/inline';
import { Box } from '@balance-web/box';

import { SidebarNavigationItemLayout } from './SidebarNavigationItemLayout';
import { useSidebarNavigationContext } from './sidebarNavigationContext';

export type SidebarNavigationItemProps = Omit<
  LinkComponentProps,
  'children'
> & {
  /** Unique key that represents a route.
   *  This will be used to select the current route. */
  navigationKey: string;
  /** Label for the route that user sees. */
  label: string;
  /** Optional icon to the left of the label. */
  icon?: ComponentType<IconProps>;
  /** Optional custom element to the right of the label. */
  endElement?: ReactNode;
};

/** A navigation link to used inside SidebarNavigation or SidebarNavigationGroup. */
export const SidebarNavigationItem = forwardRef<
  HTMLAnchorElement,
  SidebarNavigationItemProps
>(
  (
    { navigationKey, label, icon: Icon, endElement, ...linkComponentProps },
    ref
  ) => {
    const LinkComponent = useLinkComponent();
    const { selectedNavigationKey } = useSidebarNavigationContext();

    return (
      <LinkComponent {...linkComponentProps}>
        <SidebarNavigationItemLayout
          selected={selectedNavigationKey === navigationKey}
          hasIcon={!!Icon}
        >
          <Inline gap="medium" alignY="center">
            {Icon && (
              <Box padding="xsmall">
                <Icon color="base" />
              </Box>
            )}
            <Text weight="semibold" size="small">
              {label}
            </Text>
            {endElement && (
              <Flex
                direction="row"
                flex={1}
                justifyContent="flex-end"
                paddingRight="medium"
              >
                {endElement}
              </Flex>
            )}
          </Inline>
        </SidebarNavigationItemLayout>
      </LinkComponent>
    );
  }
);
