import * as React from 'react';

import { LoadGoogleTagManagerService } from './LoadGoogleTagManagerService';
import type { LoadGoogleTagManagerServiceProps } from './LoadGoogleTagManagerService';

export function GoogleTagManagerService({
  apiKey,
}: LoadGoogleTagManagerServiceProps) {
  LoadGoogleTagManagerService({ apiKey });
  return <></>;
}
