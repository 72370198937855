import React from 'react';
import { Flex } from '@balance-web/flex';

import type { BaseBook } from './types';
import { BookSelectorList } from './BookSelectorList';
import { BookSwitcherEmptyState } from './BookSwitcherEmptyState';

export type BookSwitcherResultsProps = {
  books: BaseBook[];
  isLoading?: boolean;
  userHasFinancePermission?: boolean;

  onCompleteSetupBookClick: (bookid: string) => void;
  onOpenSelectedBookClick: (bookId: string) => void;
};

export const BookSwitcherResults = ({
  books,
  isLoading,
  userHasFinancePermission,

  onCompleteSetupBookClick,
  onOpenSelectedBookClick,
}: BookSwitcherResultsProps) => {
  return (
    <>
      {!isLoading && !books.length && <BookSwitcherEmptyState />}

      {!!books.length && (
        <Flex direction="column" width="100%" overflowY="auto">
          <BookSelectorList
            books={books}
            userHasFinancePermission={userHasFinancePermission}
            onCompleteSetupBookClick={onCompleteSetupBookClick}
            onOpenSelectedBookClick={onOpenSelectedBookClick}
          />
        </Flex>
      )}
    </>
  );
};
