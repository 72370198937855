/** @jsx jsx */
import { jsx } from '@balance-web/core';
import { Box } from '@balance-web/box';
import { Stack } from '@balance-web/stack';
import { GrabIcon } from '@balance-web/icon';
import { Inline } from '@balance-web/inline';
import { Text } from '@balance-web/text';

import { useNestingLeftOffset } from '../useNestingLeftOffset';

export const TableRowDragBox = ({
  primaryText,
  secondaryText,
  rowNestingIndex,
}: {
  primaryText: string;
  secondaryText?: string;
  rowNestingIndex: number;
}) => {
  const nestedLeftOffset = useNestingLeftOffset();

  return (
    <Box
      width={400}
      cursor="grabbing"
      css={{
        marginLeft: nestedLeftOffset({
          nestingIndex: rowNestingIndex,
          cellIndex: 1,
        }),
      }}
    >
      <Inline
        borderRadius="small"
        paddingY="medium"
        paddingX="small"
        background="active"
        gap="medium"
        alignY="center"
        boxShadow="medium"
      >
        <Stack>
          <GrabIcon size="small" color="base" />
          {secondaryText ? <GrabIcon size="small" color="base" /> : null}
        </Stack>
        <Stack gap="xsmall">
          <Text size="small" weight="medium" leading="tighter">
            {primaryText}
          </Text>
          {secondaryText?.length ? (
            <Text size="xsmall" leading="tighter">
              {secondaryText}
            </Text>
          ) : null}
        </Stack>
      </Inline>
    </Box>
  );
};
