import * as React from 'react';
export type AuthContextState = {
  mode: 'cookie' | 'localstorage' | null;
  /** Do we have credentials stored for the user? */
  isAuthenticated: boolean;
  /** Are we still waiting for a response from the server. */
  isLoading?: boolean;
  /** The resolved credentials. */
  credentials?: HydratableCredentials;
  /** Empty the stored credentials from localstorage */
  removeCredentials: () => void;
  /** Store new/updated credentials in localstorage */
  setCredentials: React.Dispatch<
    React.SetStateAction<HydratableCredentials | undefined>
  >;
  /** Request headers to use on any restful api or gql requests */
  requestHeaders?: Record<string, string>;
  /** update the status to indicate that we are still loading or not. */
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Indicate how credentials are stored.
   */
  setStorageMode: React.Dispatch<
    React.SetStateAction<'cookie' | 'localstorage'>
  >;
  /** logic to run when we want to logout. This runs after we run removeCredentials. */
  logout: (url?: string) => void;
};
export type HydratableCredentials = {
  accessToken?: string;
  refreshToken?: string;
} & {
  [key in string]?: string;
};

export const AuthContext = React.createContext<AuthContextState>({
  mode: null,
  isAuthenticated: false,
  isLoading: false,
  logout: () => {},
  setCredentials: () => {},
  setIsLoading: () => {},
  setStorageMode: () => {},
  removeCredentials: () => {},
});
