import { createEventEmitter } from '@reckon-web/events';

import { QueueTask } from './types';

export function createTaskEvents<
  /** a task output */
  TItem,
  /** a task */
  TTask extends QueueTask<TItem> = () => Promise<TItem>
>() {
  return createEventEmitter<{
    /** Fired when a new task is added to the queue. */
    newTask: () => void;
    /** Fired when a task successfully completes. */
    taskComplete: (item: TItem) => void;
    /** Fired when the last task is completed. */
    queueEmpty: () => void;
    /**
     * Fired when a task throws an error.
     **/
    queueError: (
      item: () => Promise<TItem>,
      reason: string,
      task: TTask
    ) => void;
  }>();
}
