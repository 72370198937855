import React, { ReactElement } from 'react';
import { Stack } from '@balance-web/stack';
import { flattenElements } from '@balance-web/utils';

import { TileStackContextProvider } from './context';
import { TileProps } from './Tile';

export type TileStackProps = {
  children: ReactElement<TileProps> | (null | ReactElement<TileProps>)[];
};

export const TileStack = ({ children }: TileStackProps) => {
  const childArray = flattenElements(children);

  return (
    <Stack dividers border="standard" borderRadius="medium">
      {childArray.map((child, index) => (
        <TileStackContextProvider
          key={child.key || index}
          value={{
            tilePosition:
              index === 0
                ? 'first'
                : index === childArray.length - 1
                ? 'last'
                : 'other',
          }}
        >
          {child}
        </TileStackContextProvider>
      ))}
    </Stack>
  );
};
