import { useContext } from 'react';

import type { BaseBook } from '../types';

import type { BookSwitcherContext } from './createBookSwitcherContext';

export function createBookSwitcherHook<TBook extends BaseBook>(
  ldContext: BookSwitcherContext<TBook>
) {
  return () => {
    const context = useContext(ldContext);
    if (!context) {
      throw new Error(
        'useBookSwitcher must be used within a BookSwitcherProvider'
      );
    }
    return context;
  };
}
