/** @jsx jsx */

import type { InputHTMLAttributes } from 'react';
import { forwardRef, useMemo } from 'react';
import { jsx } from '@balance-web/core';
import { useFieldContext } from '@balance-web/field';
import { Text } from '@balance-web/text';
import { useRawTheme, useTheme } from '@balance-web/theme';
import type { InputBaseProps } from '@balance-web/text-input';
import {
  Adornment,
  AdornmentWrapper,
  useInputStyles,
} from '@balance-web/text-input';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { AlertCircleIcon } from '@balance-web/icon';

import { useFormattedInput } from './utils';

export type CurrencyInputProps = WithDataAttributeProp<
  {
    /** When true, a dollar symbol ($) will be displayed beside the input. */
    showAdornment?: boolean;
    onChange: (value: string | number) => void;
    value: string | number;
    maximumFractionDigits?: 0 | 2 | 4 | 5;
    minimumFractionDigits?: 2 | 4 | 5;
    align?: 'left' | 'right';
  } & Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange' | 'size'
  > &
    Partial<Omit<InputBaseProps, 'shape'>>
>;

/**
 * Should accept the following strings:
 * 1, 1.0, -1, -1.0, 0, 0.1, .1, -.1, -.21, 21, 1.21, -1.21
 */
let validCurrencyInput = /^\s*\$?-?[\d,]*(\.\d+)?\s*$/;

function normaliseValue(value: string) {
  let normalisedValue = value.trim().replace(/^\$/, '').replace(/,/g, '');
  return normalisedValue;
}

function isValidCurrencyInput(value: string) {
  return (
    validCurrencyInput.test(value) && !isNaN(parseFloat(normaliseValue(value)))
  );
}

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      maximumFractionDigits = 2,
      minimumFractionDigits = 2,
      showAdornment = true,
      size = 'medium',
      align = 'left',
      data,
      ...consumerProps
    },
    ref
  ) => {
    const { invalid, ...a11yProps } = useFieldContext();

    if (Number.isNaN(consumerProps.value)) {
      throw new Error('NaN cannot be provided to CurrencyInput');
    }

    let formatter = Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      currencyDisplay: 'symbol',
      maximumFractionDigits,
      minimumFractionDigits:
        maximumFractionDigits === 0
          ? 0
          : Math.min(maximumFractionDigits, minimumFractionDigits),
    });

    let format = (num: number) => {
      return formatter
        .formatToParts(num)
        .filter((x) => {
          return x.type !== 'currency';
        })
        .map((x) => {
          return x.value;
        })
        .join('');
    };

    const formattedProps = useFormattedInput(
      {
        determineType: (value: string | number) => {
          if (typeof value === 'string') {
            return 'raw';
          }
          return 'parsed';
        },
        format,
        parse: (value) => {
          // we're doing normalise -> parse -> format -> normalise -> parse here
          // because just doing normalise -> parse will mean that the parsed number will be more precise than the formatted input
          // i suppose we could change the regex to only allow 0-2 and 0-4 decimal places instead
          // slightly afraid that that could confuse users because they'd be like "wtf, this is valid. why are saying it isn't" for "1.00000" or "1.00001"
          // i suppose we could make the error messages more specific
          // that would mean you'd have to provide the maximumFractionDigits in two places
          // + we'd have to share logic from here to the forms package or the other way around.
          // the right thing is probably export form validate things + the scalar fields from the respective field packages
          // there's some naming bike shedding that you'd have to do though + what happens if something works for multiple fields and etc.
          let normalisedValue = normaliseValue(value);
          let formatted = format(parseFloat(normalisedValue));
          return parseFloat(normaliseValue(formatted));
        },
        validate: (value) => {
          return isValidCurrencyInput(value);
        },
      },
      consumerProps
    );

    const inputStyles = useInputStyles({
      shape: 'square',
      size,
    });

    const { typography } = useTheme();
    const rawTheme = useRawTheme();

    const adornedStyles = useMemo(() => {
      if (!showAdornment) {
        if (invalid) {
          return {
            paddingRight:
              rawTheme.spacing[size === 'small' ? 'medium' : 'large'] * 2.25,
          };
        }
        return undefined;
      }

      return {
        paddingLeft:
          rawTheme.spacing[size === 'small' ? 'medium' : 'large'] * 2,
        paddingRight:
          rawTheme.spacing[size === 'small' ? 'medium' : 'large'] *
          (invalid ? 2.25 : 1),
        '&[readonly]': {
          ...inputStyles['&[readonly]'],
          paddingLeft: `calc((${
            rawTheme.spacing[size === 'small' ? 'medium' : 'large']
          }px * 2) - 15px)`,
          paddingRight:
            rawTheme.spacing[size === 'small' ? 'medium' : 'large'] *
            (invalid ? 2.25 : 1),
        },
      };
    }, [inputStyles, invalid, rawTheme.spacing, showAdornment, size]);

    const dataAttributes = buildDataAttributes(data);

    return (
      <AdornmentWrapper size={size} shape="square">
        {showAdornment && (
          <Adornment
            align="left"
            css={{
              fontSize: typography.fontSize[size],
              pointerEvents: 'none',
              paddingLeft: 0,
              width: consumerProps.readOnly ? 10 : undefined,
              marginTop: consumerProps.readOnly ? 1 : undefined,
            }}
          >
            <Text
              size={size}
              css={{
                color: consumerProps.disabled
                  ? inputStyles[':disabled'].color
                  : inputStyles.color,
              }}
            >
              $
            </Text>
          </Adornment>
        )}
        <input
          aria-invalid={invalid}
          css={{
            ...inputStyles,
            ...adornedStyles,
            fontVariantNumeric: 'tabular-nums', // ensure the numbers line up when stacked
            textAlign: align,
          }}
          ref={ref}
          {...dataAttributes}
          {...consumerProps}
          {...a11yProps}
          {...formattedProps}
        />
        {invalid && (
          <Adornment
            align="right"
            css={{
              fontSize: typography.fontSize[size],
              pointerEvents: 'none',
            }}
          >
            <AlertCircleIcon size={size} color="critical" />
          </Adornment>
        )}
      </AdornmentWrapper>
    );
  }
);
