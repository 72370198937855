import type { NameNode } from 'graphql';
import { ApolloLink } from '@apollo/client';

type PrefixLinkFactoryProps = {
  name: string;
};

const identifier = (...strings: (string | null | undefined)[]) => {
  return strings.filter(Boolean).join('_');
};

export function createPrefixLink({ name }: PrefixLinkFactoryProps) {
  const renameNode = <T>(node: T, operationname?: string) => {
    const nodeName: NameNode = {
      kind: 'Name',
      value: identifier(name, operationname),
    };

    return {
      ...node,
      name: nodeName,
    };
  };

  return new ApolloLink((operation, next) => {
    operation.operationName = identifier(name, operation.operationName);
    operation.query = {
      ...operation.query,
      definitions: operation.query.definitions.map((node) => {
        if (node.kind === 'OperationDefinition') {
          return renameNode(node, node.name?.value);
        }

        return node;
      }),
    };
    return next(operation);
  });
}
