import { v4 as uuid4 } from 'uuid';

import type { FetchClientOptions, QueryParams, RequestOptions } from './types';

const recordToParams = (record: QueryParams) => {
  return Object.entries(record)
    .map((item) => item.join('='))
    .join('&');
};

export class FetchClient<T> {
  public headers: FetchClientOptions<T>['headers'];

  constructor(
    public baseUrl: string,
    public options: Partial<FetchClientOptions<T>>
  ) {
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  setBaseUrl(url: string) {
    this.baseUrl = url;
  }

  async requestHeaders(forUrl: string, options?: RequestOptions & T) {
    const { headers } = options || {};

    return {
      // RWR-1783: allow tracing through reckons ecosystem
      'x-rkn-correlation-id': uuid4(),
      ...this.headers,
      ...headers,
      ...((typeof this.options?.headers === 'function' &&
        this.options.headers(forUrl, options)) ||
        {}),
    };
  }

  /**
   *
   * @param url
   * @param params
   * @param options
   *
   * @example
   *    const client = new FetchClient('//fake.rest.server')
   *    client.get<ResponseDataShape>(
   *      'some/path',
   *      { pageNo: 2 },
   *      { isUnAuthenticated: true }
   *    )
   */
  async get<TResponse>(
    url: string,
    props?: {
      params?: QueryParams;
      options?: RequestOptions & T;
    }
  ): Promise<TResponse> {
    const { params, options } = props || {};
    const headers = await this.requestHeaders(url, options);
    const response = await fetch(
      [
        `${this.baseUrl}/${url}`,
        (params && recordToParams(params)) || undefined,
      ].join('?'),
      {
        method: 'get',
        headers,
        cache: 'no-cache',
      }
    );
    return await response.json();
  }

  /**
   * @example
   *    const client = new FetchClient('//fake.rest.server')
   *    client.post<ResponseDataShape>(
   *      'some/path',
   *      { pageNo: 2 },
   *      { isUnAuthenticated: true,
   *        headers: {
   *          SomeOtherHeader: 'lol'
   *        }
   *      },
   *      { ...SomeFormData }
   *    )
   */
  async post<TResponse, TBody>(
    url: string,
    props?: {
      params?: QueryParams;
      options?: RequestOptions & T;
      body?: TBody;
    }
  ): Promise<TResponse> {
    const { params, options, body } = props || {};
    const headers = await this.requestHeaders(url, options);
    const response = await fetch(
      [
        `${this.baseUrl}/${url}`,
        (params && recordToParams(params)) || undefined,
      ].join('?'),
      {
        method: 'post',
        headers,
        cache: 'no-cache',
        body: JSON.stringify(body || {}),
      }
    );
    return await response.json();
  }
}
