import { LoadScript } from '@reckon-web/load-script';
import { LoadStyle } from '@reckon-web/load-style';

import { configureIntercomIo } from './IntercomIoEvents';

export type LoadIntercomIoServiceProps = {
  /**
   * Intercom AppId
   */
  appId?: string;

  /**
   * The css zIndex of the intercom chat window, defaults to 399
   */
  zIndex?: number;
} & Intercom_.IntercomSettings;

export async function LoadIntercomIoService({
  appId,
  zIndex = 399,
  ...props
}: LoadIntercomIoServiceProps) {
  if (!appId) {
    console.warn('⚠️ Warning: skipping loading intercom.io: no appId provided');
    return;
  }

  configureIntercomIo(props);
  window.IntercomIOReadyQueue = window.IntercomIOReadyQueue || [];
  window.onIntercomReady = function (callback) {
    if (!!callback && Array.isArray(window.IntercomIOReadyQueue)) {
      window.IntercomIOReadyQueue.push(callback);
    }
    const hasQueue =
      Array.isArray(window.IntercomIOReadyQueue) &&
      window.IntercomIOReadyQueue.length > 0;

    if (hasQueue) {
      while (window.IntercomIOReadyQueue.length) {
        const cb = window.IntercomIOReadyQueue.shift();
        if (typeof cb === 'function') {
          cb(window.Intercom);
        }
      }
    }
  };
  await LoadStyle({
    id: 'intercomio-service-style',
    content: `#intercom-container, .intercom-lightweight-app { position: relative; z-index: ${zIndex} !important; }`,
    parent: 'head',
  });

  const event = await LoadScript({
    id: 'intercomio-service-js',
    src: `https://widget.intercom.io/widget/${appId}`,
    parent: 'head',
    defer: true,
  });
  const exists = typeof window.Intercom == 'function';
  if (exists) {
    window.onIntercomReady();
  }

  return event;
}
