export function getAssetPrefixEnvVarName() {
  return 'APPLICATION_ASSETPREFIX' as const;
}

/**
 * A function that returns the asset prefix for the application.
 *
 * the variable access declaration must not be composed as webpack will replace it with the actual value
 */
export function getAssetPrefix() {
  const prefix = process.env.APPLICATION_ASSETPREFIX || '';
  return prefix;
}

/**
 * A function that returns the url with the asset prefix
 */
export function urlWithPublicPrefix(url: string) {
  const prefix = getAssetPrefix();
  const toRelativeUrl = url.replace(/^\//, '');
  return [prefix, toRelativeUrl].join('/');
}
