import type { ReactElement } from 'react';
import React from 'react';
import { ChevronRightIcon } from '@balance-web/icon';
import { Flex } from '@balance-web/flex';
import { Inline } from '@balance-web/inline';
import { Text } from '@balance-web/text';
import { flattenElements } from '@balance-web/utils';

import { BreadCrumbLink } from './BreadCrumbLink';
import type { BreadCrumbLinkProps } from './BreadCrumbLink';
import { BreadCrumbButton } from './BreadCrumbButton';
import type { BreadCrumbButtonProps } from './BreadCrumbButton';

export type BreadCrumbsProps = {
  children:
    | ReactElement<BreadCrumbLinkProps>
    | (null | ReactElement<BreadCrumbLinkProps>)[]
    | ReactElement<BreadCrumbButtonProps>
    | (null | ReactElement<BreadCrumbButtonProps>)[];
};

export const BreadCrumbs = ({ children }: BreadCrumbsProps) => {
  const childArray = flattenElements(children);

  return (
    <nav aria-label="Breadcrumb">
      <Inline as="ol">
        {childArray.map((child, index) => {
          return (
            <Inline key={child.props.href} as="li">
              {index ? (
                <Flex paddingX="xsmall">
                  <ChevronRightIcon size="small" color="dim" />
                </Flex>
              ) : null}

              {index < childArray.length - 1 ? (
                child
              ) : (
                <Text size="xsmall">{child.props.children}</Text>
              )}
            </Inline>
          );
        })}
      </Inline>
    </nav>
  );
};

BreadCrumbs.Item = BreadCrumbLink;
BreadCrumbs.Button = BreadCrumbButton;
