import { useCallback, useEffect } from 'react';

import { gtmEvent } from '@reckon-web/google-tag-manager';

import type { BaseBook, BaseUser } from '../types';
import { getBookMetaData } from '../BookSwitcherMeta//getBookMetaData';

import type { BookSwitcherProps } from './BookSwitcher';
type UseSelectFirstAndOnlyBookProps = Pick<
  BookSwitcherProps<BaseBook>,
  'books' | 'onOpenBookClick' | 'onCompleteSetupBookClick'
> & {
  user?: null | BaseUser;
  bookId?: string;
  isSettingUpBook?: boolean;
  isLoading?: boolean;
};

/**
 * @deprecated probably not required anymore
 */
export function useSelectFirstAndOnlyBook({
  isLoading,
  books,
  user,
  bookId,
  isSettingUpBook,
  onOpenBookClick,
  onCompleteSetupBookClick,
}: UseSelectFirstAndOnlyBookProps) {
  const {
    firstBookId,
    hasBooks,
    hasMultipleBooks,
    hasOneBook,
    hasBook,
    isSubscriptionABook,
    isIncompleteBook,
  } = getBookMetaData({ user, books });

  const handleBookSelection = useCallback(async () => {
    const isBookExists = isSubscriptionABook(bookId);
    const isBookSetupComplete = !isIncompleteBook(bookId);
    const canAccessBook = hasBook(bookId);
    const isBookFullySetup = isBookExists && isBookSetupComplete;

    // SELECTED BOOK
    /** we're trying to setup a completed bookid */
    if (isSettingUpBook && bookId && hasBook(bookId) && isBookFullySetup) {
      gtmEvent('app.bookswitcher.autoSelectBook', {
        bookId,
        reason: 'ATTEMPTING_TO_SETUP_COMPLETED_BOOKID',
      });
      onOpenBookClick(bookId);
      return;
    }

    /** we're trying to use the app with an incomplete bookid */
    if (
      !isSettingUpBook &&
      bookId &&
      canAccessBook &&
      (!isBookExists || !isBookSetupComplete)
    ) {
      gtmEvent('app.bookswitcher.autoSelectBook', {
        bookId,
        reason: 'ATTEMPTING_TO_USE_APP_WITH_INCOMPLETE_BOOKID',
      });
      onCompleteSetupBookClick(bookId);
      return;
    }

    /** user is setting a book up */
    if (isSettingUpBook) {
      gtmEvent('app.bookswitcher.autoSelectBook', {
        bookId,
        reason: 'USER_IS_SETTING_UP_BOOK',
      });
      return;
    }

    /** we have book selected and it's complete */
    if (bookId && canAccessBook && isBookFullySetup) {
      gtmEvent('app.bookswitcher.autoSelectBook', {
        bookId,
        reason: 'SELECTED_BOOK_IS_COMPLETE',
      });
      return;
    }

    /** NO SELECTED BOOK */
    /** no book selected, user only has a subscription waiting to be a book */
    if (!bookId && hasOneBook && !firstBookId) {
      gtmEvent('app.bookswitcher.autoSelectBook', {
        bookId,
        reason: 'NO_SELECTED_BOOK_USER_HAS_SUBSCRIPTION_WAITING_TO_BE_A_BOOK',
      });
      return;
    }

    /** no book selected, only access to one book, and it is not complete. */
    if (
      !bookId &&
      hasOneBook &&
      firstBookId &&
      (!isBookExists || !isBookSetupComplete)
    ) {
      gtmEvent('app.bookswitcher.autoSelectBook', {
        bookId,
        reason: 'NO_SELECTED_BOOK_USER_HAS_ONE_BOOK_AND_IT_IS_NOT_COMPLETE',
      });
      onCompleteSetupBookClick(firstBookId);
      return;
    }

    /** no book selected, only access to one book, and it's complete */
    if (!bookId && hasOneBook && firstBookId && isBookFullySetup) {
      gtmEvent('app.bookswitcher.autoSelectBook', {
        bookId,
        reason: 'NO_SELECTED_BOOK_USER_HAS_ONE_BOOK_AND_IT_IS_COMPLETE',
      });
      onOpenBookClick(firstBookId);
      return;
    }
    /** no book selected, user has access to more than one book. they need to select one. */
    if (!bookId && hasMultipleBooks) {
      gtmEvent('app.bookswitcher.autoSelectBook', {
        bookId,
        reason: 'NO_SELECTED_BOOK_USER_HAS_ACCESS_TO_MORE_THAN_ONE_BOOK',
      });
      return;
    }
    /** user doesn't have a book. they need to create one. */
    if (!bookId && !hasBooks) {
      gtmEvent('app.bookswitcher.autoSelectBook', {
        bookId,
        reason: 'NO_SELECTED_BOOK_USER_DOES_NOT_HAVE_A_BOOK',
      });
      return;
    }

    gtmEvent('app.bookswitcher.autoSelectBook', {
      bookId,
      reason: 'UNKNOWN_REASON',
    });
    // something else
    return;
  }, [
    bookId,
    isSettingUpBook,
    hasOneBook,
    firstBookId,
    hasMultipleBooks,
    hasBooks,
    isSubscriptionABook,
    isIncompleteBook,
    hasBook,
    onOpenBookClick,
    onCompleteSetupBookClick,
  ]);

  /**
   * Automatically do something with:
   * - the selected book
   * - the first incomplete and only book
   * - direct selected incomplete books to setup
   * - direct selected complete books to launch if they're on a setup url
   */
  useEffect(() => {
    /** other queries are loading bail out and come back when we have more info */
    if (isLoading) {
      return;
    }

    handleBookSelection();
  }, [handleBookSelection, isLoading]);
}
