import React, { ReactNode } from 'react';

import { WizardType } from './types';
import { WizardContext } from './context';

export type WizardProviderProps<
  TSteps,
  TAdditionalState extends object | void = void
> = {
  wizard: WizardType<TSteps, TAdditionalState>;
  children?: ReactNode;
};

export function WizardProvider<
  TSteps,
  TAdditionalState extends object | void = void
>(props: WizardProviderProps<TSteps, TAdditionalState>) {
  const { wizard, children } = props;

  return (
    <WizardContext.Provider value={{ ...wizard }}>
      {children}
    </WizardContext.Provider>
  );
}
