import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonMateLogoName = 'ReckonMateLogo';
export const ReckonMateAppName = 'Mate';
export const ReckonMateAppLongName = 'Reckon Mate';
export const ReckonMateLogoDescription = 'Reckon Mate Application Logo';

export const ReckonMateLogo = createIllustration(
  {
    name: ReckonMateLogoName,
    description: ReckonMateLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#a)"
      />
    </mask>
    <g mask="url(#b)">
      <path fill="url(#c)" d="M0 0h60v60H0z" />
      <path
        d="M30 12c-8.836 0-16 7.164-16 16 0 8.834 7.164 16 16 16 8.834 0 16-7.166 16-16 0-8.836-7.166-16-16-16Zm-8 12.571a2.285 2.285 0 1 1 4.57 0 2.285 2.285 0 0 1-4.57 0Zm14.466 9.893A9.107 9.107 0 0 1 30 37.14a9.11 9.11 0 0 1-6.466-2.675 1.14 1.14 0 0 1 0-1.615 1.14 1.14 0 0 1 1.616 0 6.863 6.863 0 0 0 9.697 0 1.14 1.14 0 0 1 1.616 0 1.14 1.14 0 0 1 .003 1.615Zm-.752-7.607a2.285 2.285 0 1 1-.001-4.57 2.285 2.285 0 0 1 .001 4.57Z"
        fill="#fff"
      />
      <g filter="url(#d)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.375 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.375 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <mask
      id="f"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#e)"
      />
    </mask>
    <g mask="url(#f)">
      <path fill="url(#g)" d="M0 0h60v60H0z" />
      <path
        d="M30 11.162c-11.045 0-20 8.592-20 19.189 0 10.595 8.955 19.189 20 19.189 11.043 0 20-8.594 20-19.189 0-10.597-8.957-19.189-20-19.189ZM20 26.24c0-1.514 1.279-2.741 2.857-2.741 1.579 0 2.857 1.227 2.857 2.741 0 1.515-1.278 2.741-2.857 2.741-1.578 0-2.857-1.226-2.857-2.74Zm18.082 11.865C35.854 40.244 32.93 41.31 30 41.31c-2.927 0-5.854-1.067-8.082-3.207a1.33 1.33 0 0 1 0-1.938 1.469 1.469 0 0 1 2.02 0c3.342 3.207 8.78 3.207 12.121 0a1.469 1.469 0 0 1 2.02 0c.56.535.56 1.403.003 1.938Zm-.939-9.124c-1.579 0-2.857-1.226-2.857-2.74 0-1.515 1.278-2.742 2.857-2.742 1.579 0 2.857 1.227 2.857 2.741 0 1.515-1.278 2.741-2.857 2.741Z"
        fill="#fff"
      />
      <g filter="url(#h)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.375 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.375 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={37.259}
        y2={34.646}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E30613" />
        <stop offset={1} stopColor="#F84184" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={0}
        y1={0}
        x2={37.259}
        y2={34.646}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E30613" />
        <stop offset={1} stopColor="#F84184" />
      </linearGradient>
      <filter
        id="d"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_14050_1135"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_14050_1135"
          result="shape"
        />
      </filter>
      <filter
        id="h"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_14050_1135"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_14050_1135"
          result="shape"
        />
      </filter>
    </defs>
  </React.Fragment>
);
