/** @jsx jsx */
import { ReactNode, memo, useMemo, useState } from 'react';
import { CSSObject, jsx } from '@balance-web/core';
import { Box } from '@balance-web/box';
import { useTheme } from '@balance-web/theme';

import { TileContextProvider } from './context';
import { useTileStackContext } from './context';
import { Density, TilePosition } from './types';

type TileBaseProps = {
  density: Density;
  children: ReactNode;
  interactive: boolean;
};

export const TileBase = memo(
  ({ density, interactive, children }: TileBaseProps) => {
    const tileStackContext = useTileStackContext();
    const tileStyles = useTileStyles({
      density,
      interactive,
      tilePosition: tileStackContext?.tilePosition || undefined,
    });
    const [hover, setHover] = useState(false);

    return (
      <Box
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        css={{
          ...tileStyles,
        }}
      >
        <TileContextProvider value={{ hover }}>{children}</TileContextProvider>
      </Box>
    );
  }
);

const DENSITY_PADDING = {
  compact: 'small',
  regular: 'large',
  spacious: 'xlarge',
} as const;

const useTileStyles = ({
  density,
  interactive,
  tilePosition,
}: {
  density: Density;
  interactive: boolean;
  tilePosition?: TilePosition;
}): CSSObject => {
  const { spacing, palette, radii, borderWidth } = useTheme();

  /** When tile is in a stack the borders and dividers are rendered by the TileStack component*/
  const isInTileStack = useTileStackContext();

  const borderStyles: CSSObject = useMemo(
    () => ({
      border: isInTileStack
        ? undefined
        : `${borderWidth.standard} solid ${palette.global.border}`,
      borderRadius: radii.medium,
      ...(tilePosition ? { ...stackBorderRadii[tilePosition] } : {}),
    }),
    [
      borderWidth.standard,
      isInTileStack,
      palette.global.border,
      radii.medium,
      tilePosition,
    ]
  );
  const interactionStyles = useMemo(() => {
    return {
      cursor: 'pointer',
      '&:not([aria-disabled=true])': {
        ':hover': {
          background: palette.background.dim,
        },
        ':active': {
          background: palette.background.shade,
        },
      },
    };
  }, [palette.background.dim, palette.background.shade]);

  return {
    ...borderStyles,
    padding: spacing[DENSITY_PADDING[density]],
    background: palette.background.muted,
    ...(interactive ? interactionStyles : {}),
  };
};

const stackBorderRadii = {
  first: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  last: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  other: {
    borderRadius: 0,
  },
};
