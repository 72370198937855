import { useMemo } from 'react';
import { useRawTheme } from '@balance-web/theme';

import { ComboboxInputSize } from './types';

type UseComboboxStateProps = {
  disabled: boolean;
  isFocused: boolean;
  isOpen: boolean;
  itemHeight?: number;
  size: ComboboxInputSize;
};
/** Track common values for use in styled components. */
export function useComboboxState({
  disabled,
  isFocused,
  isOpen,
  itemHeight: consumerItemHeight,
  size,
}: UseComboboxStateProps) {
  const itemHeight = useItemHeight(consumerItemHeight, size);
  const state = useMemo(() => {
    return { disabled, isFocused, isOpen, itemHeight, size };
  }, [disabled, isFocused, isOpen, itemHeight, size]);

  return state;
}

const comboboxSizeToThemeKey = { small: 'small', medium: 'base' } as const;

/** Default the item height, when none is provided by the consumer. */
export function useItemHeight(
  override: number | undefined,
  size: ComboboxInputSize
) {
  const { borderWidth, sizing } = useRawTheme();
  // using the input height creates a nice relationship between the otherwise disconnected elements
  const inputHeight = sizing[comboboxSizeToThemeKey[size]];

  return override ?? inputHeight - borderWidth.standard * 2;
}
