import { createContext } from 'react';

export type OpenCollegesMetaContextState = {
  isNonStpPosting: boolean;
  setIsNonStpPosting: (value: boolean) => void;
};

export const OpenCollegesMetaContext = createContext<
  OpenCollegesMetaContextState
>({
  isNonStpPosting: false,
  setIsNonStpPosting: () => {},
});
