import React, { useMemo, useState } from 'react';

import type { AppLeftNavigatorItem } from '@reckon-web/app-navigation';
import { AppLeftNavigator } from '@reckon-web/app-navigation';

import { PieChartIcon } from './icons/PieChartIcon';
import { CalendarIcon } from './icons/CalendarIcon';
import { DollarSignIcon } from './icons/DollarSignIcon';
import { BriefcaseIcon } from './icons/BriefcaseIcon';
import { TrendingUpIcon } from './icons/TrendingUpIcon';
import { PhoneIcon } from './icons/PhoneIcon';
import { ArchiveIcon } from './icons/ArchiveIcon';
import { LightBulbIcon } from './icons/LightBulbIcon';

export const LeftNavigatorApp = () => {
  const apiResponse = {
    leftNav: {
      items: [
        {
          displayName: 'Dashboard',
          routeId: 'dashboard',
          url: 'https://app.reckonone.com/dashboard',
          type: 'root',
          origin: 'kendo',
          attributes: { 'some-attribute': '123' },
        },
        {
          displayName: 'Day to day',
          routeId: 'day-to-day',
          type: 'root',
          origin: 'kendo',
          items: [
            {
              type: 'child',
              displayName: 'Money in',
              routeId: 'money-in',
              origin: 'kendo',
              items: [
                {
                  type: 'child',
                  displayName: 'Invoices',
                  url: 'https://app.reckonone.com/money-in/invoices',
                  routeId: 'money-in-invoices',
                  origin: 'kendo',
                },
                {
                  type: 'child',
                  displayName: 'Customer adjustment notes',
                  url:
                    'https://app.reckonone.com/money-in/customer-adjustment-notes',
                  routeId: 'money-in-customer-adjustment-notes',
                  origin: 'kendo',
                },
                {
                  type: 'child',
                  displayName: 'Receive money',
                  url: 'https://app.reckonone.com/money-in/receive-money',
                  routeId: 'money-in-receive-money',
                  origin: 'kendo',
                },
              ],
            },
            {
              type: 'child',
              displayName: 'Money out',
              routeId: 'money-out',
              origin: 'kendo',
              items: [
                {
                  type: 'child',
                  displayName: 'Supplier adjustment notes',
                  url:
                    'https://app.reckonone.com/money-out/supplier-adjustment-notes',
                  routeId: 'money-out-supplier-adjustment-notes',
                  origin: 'kendo',
                },
                {
                  type: 'child',
                  displayName: 'Make payment',
                  url: 'https://app.reckonone.com/money-out/make-payment',
                  routeId: 'money-out-make-payment',
                  origin: 'kendo',
                },
              ],
            },
          ],
        },
        {
          displayName: 'Payroll',
          routeId: 'payroll',
          type: 'root',
          origin: 'kendo',
          items: [
            {
              displayName: 'Pay runs',
              routeId: 'payroll-pay-runs',
              url: 'payroll/pay-runs',
              origin: 'platform',
            },
            {
              displayName: 'STP',
              routeId: 'payroll-stp',
              url: 'payroll/stp',
              origin: 'platform',
            },
          ],
        },
        {
          displayName: 'Banking',
          routeId: 'banking',
          type: 'root',
          origin: 'kendo',
          items: [
            {
              type: 'child',
              displayName: 'Banking accounts',
              url: 'https://app.reckonone.com/banking/banking-accounts',
              routeId: 'banking-banking-accounts',
              origin: 'kendo',
            },
            {
              type: 'child',
              displayName: 'Banking transactions',
              url: 'https://app.reckonone.com/banking/banking-transactions',
              routeId: 'banking-banking-transactions',
              origin: 'kendo',
            },
            {
              type: 'child',
              displayName: 'Reckon One transactions',
              url: 'https://app.reckonone.com/banking/reckon-one-transactions',
              routeId: 'banking-reckon-one-transactions',
              origin: 'kendo',
            },
          ],
        },
        {
          displayName: 'Reporting',
          routeId: 'reporting',
          url: 'https://app.reckonone.com/reporting',
          type: 'root',
          origin: 'kendo',
        },
        {
          displayName: 'Contacts center',
          routeId: 'contacts-center',
          url: 'https://app.reckonone.com/contacts-center',
          type: 'root',
          origin: 'kendo',
        },
        {
          displayName: 'Tax',
          routeId: 'tax',
          url: 'https://app.reckonone.com/tax',
          type: 'root',
          origin: 'kendo',
        },
        {
          displayName: 'Insights',
          routeId: 'insights',
          url: 'https://www.reckon.com/au/financial-reporting-software/',
          type: 'root',
          origin: 'kendo',
          showSeparatorAbove: true,
          target: '_blank',
        },
      ],
    },
  };

  const rootItemIconMap = new Map<string, React.FC<any>>([
    ['dashboard', PieChartIcon],
    ['day-to-day', CalendarIcon],
    ['payroll', DollarSignIcon],
    ['banking', BriefcaseIcon],
    ['reporting', TrendingUpIcon],
    ['contacts-center', PhoneIcon],
    ['tax', ArchiveIcon],
    ['insights', LightBulbIcon],
  ]);

  const apiLeftNavToAppLeftNav = (
    leftNavRootItems: typeof apiResponse['leftNav']['items']
  ): Array<AppLeftNavigatorItem> => {
    const leftNav: Array<AppLeftNavigatorItem> = leftNavRootItems.map(
      (item) => {
        const rootItemIcon = rootItemIconMap.get(item.routeId);

        if (!rootItemIcon) {
          throw Error('Could not find root item icon');
        }

        return {
          type: 'root',
          routeId: item.routeId,
          origin: item.origin,
          url: item.url,
          target: item.target,
          label: item.displayName,
          icon: rootItemIcon,
          attributes: item.attributes,
          showSeparatorAbove: item.showSeparatorAbove,
          // @ts-ignore Type won't error when coming from gql
          items: mapAPIChildItemsToAppChildItems(item.items),
        };
      }
    );

    return leftNav;
  };

  const mapAPIChildItemsToAppChildItems = (
    leftNavChildItems?: typeof apiResponse['leftNav']['items']
  ) => {
    if (!leftNavChildItems?.length) {
      return [];
    }

    const childItems: Array<AppLeftNavigatorItem> = leftNavChildItems.map(
      (item) => {
        return {
          type: 'child',
          routeId: item.routeId,
          origin: item.origin as AppLeftNavigatorItem['origin'],
          url: item.url,
          target: item.target,
          label: item.displayName,
          attributes: item.attributes,
          // @ts-ignore Type won't error when coming from gql
          items: mapAPIChildItemsToAppChildItems(item.items),
        };
      }
    );

    return childItems;
  };

  const appLeftNav = useMemo(() => {
    return apiLeftNavToAppLeftNav(apiResponse.leftNav.items);
  }, []);

  const [activeRouteId] = useState('money-out-make-payment');

  return <AppLeftNavigator items={appLeftNav} activeRouteId={activeRouteId} />;
};
