import { UseRequestProps, useRequest } from './useRequest';
import { RequestResult } from './types';
import { isErrorResponse } from './isErrorResponse';

export function useBlobRequest({ url, headers }: UseRequestProps) {
  const { request, ...output } = useRequest({ url, headers });

  const handleRequest = async (
    props: Parameters<typeof request>[0] = {}
  ): Promise<RequestResult<Blob>> => {
    const response = await request(props);

    if (!isErrorResponse(response) && response.ok) {
      return response.blob();
    }

    if (isErrorResponse(response)) {
      return { error: response.error };
    }

    return { error: 'Something went wrong requesting the information' };
  };

  return {
    ...output,
    request: handleRequest,
  };
}
