import { ApsPlusAppName, ApsPlusLogo } from './ApsPlusLogo';
import { RAHAppLongName, RAHAppName, RAHLogo } from './RAHLogo';
import {
  ReckonAccessAppLongName,
  ReckonAccessAppname,
  ReckonAccessLogo,
} from './ReckonAccessLogo';
import {
  ReckonBankingAppLongName,
  ReckonBankingAppName,
  ReckonBankingLogo,
} from './ReckonBankingLogo';
import {
  ReckonBillingAppLongName,
  ReckonBillingAppName,
  ReckonBillingLogo,
} from './ReckonBillingLogo';
import {
  ReckonExpensesAppLongName,
  ReckonExpensesAppName,
  ReckonExpensesLogo,
} from './ReckonExpensesLogo';
import {
  ReckonIdentityAppLongName,
  ReckonIdentityAppName,
  ReckonIdentityLogo,
} from './ReckonIdentityLogo';
import {
  ReckonInsightsAppLongName,
  ReckonInsightsAppName,
  ReckonInsightsLogo,
} from './ReckonInsightsLogo';
import {
  ReckonInvoicesAppLongName,
  ReckonInvoicesAppName,
  ReckonInvoicesLogo,
} from './ReckonInvoicesLogo';
import { ReckonAppLongName, ReckonAppName, ReckonLogo } from './ReckonLogo';
import {
  ReckonMateAppLongName,
  ReckonMateAppName,
  ReckonMateLogo,
} from './ReckonMateLogo';
import {
  ReckonOneAppLongName,
  ReckonOneAppName,
  ReckonOneLogo,
} from './ReckonOneLogo';
import {
  ReckonSTPAppLongName,
  ReckonSTPAppName,
  ReckonSTPLogo,
} from './ReckonStpLogo';
import {
  ReckonPayrollAppLongName,
  ReckonPayrollAppName,
  ReckonPayrollLogo,
} from './ReckonPayrollLogo';
import {
  ReckonTimesheetsAppLongName,
  ReckonTimesheetsAppName,
  ReckonTimesheetsLogo,
} from './ReckonTimesheetsLogo';
import {
  ReckonGovConnectAppLongName,
  ReckonGovConnectAppName,
  ReckonGovConnectLogo,
} from './ReckonGovConnectLogo';
export const Logos = {
  access: ReckonAccessLogo,
  apsplus: ApsPlusLogo,
  banking: ReckonBankingLogo,
  billing: ReckonBillingLogo,
  expenses: ReckonExpensesLogo,
  identity: ReckonIdentityLogo,
  insights: ReckonInsightsLogo,
  invoices: ReckonInvoicesLogo,
  mate: ReckonMateLogo,
  one: ReckonOneLogo,
  rah: RAHLogo,
  reckon: ReckonLogo,
  stp: ReckonSTPLogo,
  timesheets: ReckonTimesheetsLogo,
  payroll: ReckonPayrollLogo,
  govconnect: ReckonGovConnectLogo,
} as const;

export const Names = {
  access: ReckonAccessAppname,
  apsplus: ApsPlusAppName,
  banking: ReckonBankingAppName,
  billing: ReckonBillingAppName,
  expenses: ReckonExpensesAppName,
  identity: ReckonIdentityAppName,
  insights: ReckonInsightsAppName,
  invoices: ReckonInvoicesAppName,
  mate: ReckonMateAppName,
  one: ReckonOneAppName,
  rah: RAHAppName,
  reckon: ReckonAppName,
  stp: ReckonSTPAppName,
  timesheets: ReckonTimesheetsAppName,
  payroll: ReckonPayrollAppName,
  govconnect: ReckonGovConnectAppName,
};
export const LongNames = {
  access: ReckonAccessAppLongName,
  apsplus: ApsPlusAppName,
  banking: ReckonBankingAppLongName,
  billing: ReckonBillingAppLongName,
  expenses: ReckonExpensesAppLongName,
  identity: ReckonIdentityAppLongName,
  insights: ReckonInsightsAppLongName,
  invoices: ReckonInvoicesAppLongName,
  mate: ReckonMateAppLongName,
  one: ReckonOneAppLongName,
  rah: RAHAppLongName,
  reckon: ReckonAppLongName,
  stp: ReckonSTPAppLongName,
  timesheets: ReckonTimesheetsAppLongName,
  payroll: ReckonPayrollAppLongName,
  govconnect: ReckonGovConnectAppLongName,
};

export const isReckonAppCode = (code: any): code is keyof typeof Logos =>
  code in Logos && code in Names;
export const isReckonAppLongName = (code: any): code is keyof typeof Logos =>
  code in Logos && code in LongNames;

export type ReckonAppCode = keyof typeof Names;
export type ReckonAppLongNames = keyof typeof LongNames;
