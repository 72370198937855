/** @jsx jsx */
import type { ReactNode } from 'react';
import { buildDataAttributes, jsx } from '@balance-web/core';
import { flattenElements } from '@balance-web/utils';
import type { WithDataAttributeProp } from '@balance-web/core';

import { ListItem } from '../ListItem';
import { useListSeparatorStyles } from '../styles';
import { useHideItemBorderOnHover } from '../useHideItemBorderOnHover';

export type ListProps = WithDataAttributeProp<{
  children: ReactNode;
  separators?: boolean;
}>;

export const List = (props: ListProps) => {
  const childArray = flattenElements(props.children);

  const childRefs = useHideItemBorderOnHover({ children: props.children });
  const listSeparatorStyles = useListSeparatorStyles();

  const dataAttributes = buildDataAttributes(props.data);

  return (
    <ul {...dataAttributes}>
      {childArray.map((child, index) => {
        const key = index;
        const isThisItemSelected = !!child.props['selected'];
        const isNextItemSelected =
          index < childArray.length - 1 &&
          !!childArray[index + 1].props['selected'];

        const nonSelectedListItemSeparatorStyles =
          isThisItemSelected || isNextItemSelected ? {} : listSeparatorStyles;

        return (
          <li
            key={key}
            ref={(element) => {
              return (childRefs.current[index] = element);
            }}
            css={
              props.separators ? nonSelectedListItemSeparatorStyles : undefined
            }
          >
            {child}
          </li>
        );
      })}
    </ul>
  );
};

List.Item = ListItem;
