import React, { useCallback, useMemo, useState } from 'react';
import { Stack } from '@balance-web/stack';
import { Flex } from '@balance-web/flex';
import { LoadingDots } from '@balance-web/loading';

import type {
  BookSortEnumValueType,
  BookStatusEnumValueType,
} from '../constants';
import { CreateBookEmptyState } from '../CreateBookEmptyState';
import type { BaseBook } from '../types';
import type { BookSwitcherSearchProps } from '../BookSwitcherSearch';
import { BookSwitcherSearch } from '../BookSwitcherSearch';
import { useSortAndFilterBooks } from '../useSortAndFilterBooks';
import { useBookSwitcherMeta } from '../BookSwitcherMeta/useBookSwitcherMeta';

export type BookSwitcherProps<TBook extends BaseBook> = Pick<
  BookSwitcherSearchProps<TBook>,
  | 'onOpenBookClick'
  | 'onCompleteSetupBookClick'
  | 'missingBookHelpURL'
  | 'manageSubscriptionsURL'
> & {
  books: TBook[];
  error?: string;
  isLoading?: boolean;
  isRedirectingUserToReckonOne?: boolean;
  userHasFinancePermission?: boolean;
  appName?: string;
  onOpenBookClick?: (bookId: string) => void;
  onCreateNewBookClick?: () => void;
};

export function BookSwitcher<TBook extends BaseBook>({
  appName,
  books,
  error,
  isLoading,
  manageSubscriptionsURL,
  missingBookHelpURL,
  userHasFinancePermission,
  onCompleteSetupBookClick,
  onCreateNewBookClick,
  onOpenBookClick,
}: BookSwitcherProps<TBook>) {
  const bookMeta = useBookSwitcherMeta();

  const [bookSearchFilter, setBookSearchFilter] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<BookSortEnumValueType>(
    'ASC'
  );
  const [selectedBookStatus, setSelectedBookStatus] = useState<
    BookStatusEnumValueType
  >('ACTIVE');

  /**
   * Find the first demo book (should only be one)
   */
  const demoBook = useMemo(() => {
    return books.find((book) => {
      // TODO: add more discerning logic here to ensure we don't capture the OpenColleges type demo books
      // POR-1681: https://reckon.atlassian.net/browse/POR-1681
      return book.isDemo;
    });
  }, [books]);

  const sortedAndFilteredBooks = useSortAndFilterBooks({
    books,
    bookSearchFilter,
    selectedBookStatus,
    sortDirection,
  });

  const handleSortFilteredBooksClick = () => {
    setSortDirection((sortDirection) => {
      if (sortDirection === 'ASC') {
        return 'DESC';
      }
      return 'ASC';
    });
  };

  const handleSelectBookStatusClick = () => {
    setSelectedBookStatus((selectedBookStatus) => {
      if (selectedBookStatus === 'ACTIVE') {
        return 'CANCELLED';
      }
      return 'ACTIVE';
    });
  };

  const handleOpenDemoBook = useCallback(() => {
    if (!demoBook || typeof onOpenBookClick !== 'function') {
      return;
    }
    onOpenBookClick(demoBook.bookId);
  }, [demoBook, onOpenBookClick]);

  return (
    <>
      {isLoading && !bookMeta.hasBooks && (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          padding="xxlarge"
          margin="xxlarge"
        >
          <Stack gap="xlarge" align="center">
            <LoadingDots label="Loading books" />
          </Stack>
        </Flex>
      )}

      {!isLoading && !bookMeta.hasBooks && (
        <CreateBookEmptyState
          onCreateNewBookClick={onCreateNewBookClick}
          missingBookHelpURL={missingBookHelpURL}
          appName={appName}
          onOpenDemoBookClick={(!!demoBook && handleOpenDemoBook) || undefined}
        />
      )}

      {!!bookMeta.hasBooks && (
        <BookSwitcherSearch
          books={sortedAndFilteredBooks}
          bookSearchFilter={bookSearchFilter}
          selectedBookStatus={selectedBookStatus}
          sortDirection={sortDirection}
          isLoading={isLoading}
          userHasFinancePermission={userHasFinancePermission}
          onOpenBookClick={onOpenBookClick}
          manageSubscriptionsURL={manageSubscriptionsURL}
          missingBookHelpURL={missingBookHelpURL}
          onBookSearchFilterChange={setBookSearchFilter}
          onBookSortToggleClick={handleSortFilteredBooksClick}
          onBookStatusFilterChange={handleSelectBookStatusClick}
          onCompleteSetupBookClick={onCompleteSetupBookClick}
        />
      )}
    </>
  );
}
