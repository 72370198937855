import { ReactTagManager } from 'react-gtm-ts';

/**
 * 👉️ https://developers.google.com/tag-platform/gtagjs
 */
export const gtag = ReactTagManager.action;

/**
 * 👉️ https://developers.google.com/tag-platform/gtagjs
 */
export function gtmEvent(eventName: string, data: object) {
  ReactTagManager.action({
    event: eventName,
    ...data,
  });
}
