/** @jsx jsx */
import { useState } from 'react';
import type { DragUpdate } from 'react-beautiful-dnd';
import { Stack } from '@balance-web/stack';
import { Box } from '@balance-web/box';
import { useTheme } from '@balance-web/theme';
import { jsx } from '@balance-web/core';

type PlaceholderStyles = {
  height: number;
  width: number;
  y: number;
  x: number;
};

/**
 *
 * This hook is based on react-beautiful-dnd library and should not be used with other drag n drop libraries.
 */
export const useReactBeautifulDnDDropPlaceholder = () => {
  const theme = useTheme();

  const [placeholderStyles, setPlaceholderStyles] = useState<
    PlaceholderStyles | undefined
  >();

  const onDragUpdate = (update: DragUpdate) => {
    if (!update.destination) {
      return;
    }

    const { source, destination } = update;

    const draggableId = update.draggableId;
    const destinationIndex = destination.index;

    const domQuery = `[data-rbd-draggable-id='${draggableId}']`;
    const draggedElement = document.querySelector(domQuery);
    const droppableContainer = draggedElement?.parentElement;

    if (!draggedElement || !droppableContainer) {
      return;
    }

    const { clientHeight, clientWidth } = draggedElement;
    const computedContainerStyle = window.getComputedStyle(droppableContainer);
    const childrenArray = [...droppableContainer.children];
    const movedItem = childrenArray[source.index];
    childrenArray.splice(source.index, 1);
    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY =
      parseFloat(computedContainerStyle.paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr, index) => {
        const computedChildStyle = window.getComputedStyle(curr);
        /**
         *
         * Use bounding rectange instead of computedStyle.clientHeight because bounding rect is more precise when height is in decimals.
         * The cummuluative errors clientHeight results in the placeholder being misaligned for rows near the end.
         */
        const boundingRect = curr.getBoundingClientRect();
        const marginBottom = parseFloat(computedChildStyle.marginBottom);
        return total + boundingRect.height + marginBottom;
      }, 0);

    setPlaceholderStyles({
      height: clientHeight,
      width: clientWidth,
      y: clientY,
      x: parseFloat(window.getComputedStyle(droppableContainer).paddingLeft),
    });
  };

  const onDragEnd = () => {
    setPlaceholderStyles(undefined);
  };

  const PlaceHolder = ({ dropStatus }: { dropStatus: 'valid' | 'invalid' }) => {
    if (!placeholderStyles) {
      return null;
    }

    return (
      <Stack
        paddingY="xsmall"
        css={{
          position: 'absolute',
          top: placeholderStyles.y,
          left: placeholderStyles.x,
          height: placeholderStyles.height,
          width: placeholderStyles.width,
          borderBottom: `1px solid ${theme.palette.border.standard}`,
        }}
      >
        <Box
          borderRadius="medium"
          width="100%"
          height="100%"
          css={{
            backgroundColor:
              dropStatus === 'valid'
                ? theme.palette.table.rowDropBackgroundValid
                : theme.palette.table.rowDropBackgroundInvalid,
          }}
        />
      </Stack>
    );
  };

  return {
    PlaceHolder,
    onDragUpdate,
    onDragEnd,
  };
};
