/**
 * @jsx jsx
 */

import { jsx } from '@balance-web/core';
import { Heading } from '@balance-web/heading';
import { Text } from '@balance-web/text';
import { TextLink } from '@balance-web/text-link';

import { urlWithPublicPrefix } from '@reckon-web/next-config/client';

const styles = {
  browserLogosWrapper: {
    display: 'inline-block',
    textAlign: 'center',
    width: '25%',
  },
  browserLogo: {
    display: 'block',
    margin: '0 auto',
  },
  spacing: {
    regular: {
      paddingBottom: '16px',
    },
    large: {
      paddingBottom: '40px',
    },
  },
} as const;

export function UnsupportedBrowserPage() {
  return (
    <div css={{ marginTop: '160px' }}>
      <div css={{ width: '520px', margin: '0 auto', textAlign: 'center' }}>
        <div
          css={{
            margin: '0 auto',
            display: 'inline-block',
            ...styles.spacing.large,
          }}
        >
          <img
            src={urlWithPublicPrefix('/public/Reckon-Payroll.png')}
            alt="Reckon Payroll Mobile"
            css={{ verticalAlign: ' middle' }}
          />

          <div css={{ display: 'inline-block', paddingLeft: '12px' }}>
            <Text
              size="xlarge"
              weight="heavy"
              css={{ display: 'inline-block' }}
            >
              Payroll
            </Text>
          </div>
        </div>

        <Heading level="4" css={styles.spacing.regular}>
          Please upgrade your browser to use Reckon Payroll
        </Heading>

        <Text
          size="medium"
          weight="medium"
          color="dim"
          css={styles.spacing.large}
        >
          It looks like you may be using a web browser that we don’t support.
          Try updating your browser, or try using one of these supported
          browsers to use Reckon Payroll.
        </Text>

        <div>
          <div css={styles.browserLogosWrapper}>
            <img
              height={60}
              src={urlWithPublicPrefix('/public/google-chrome-logo.svg')}
              alt="Google Chrome logo"
              css={{ ...styles.browserLogo, ...styles.spacing.regular }}
            />

            <TextLink href="https://www.google.com.au/chrome/" target="_blank">
              Google Chrome
            </TextLink>
          </div>

          <div css={styles.browserLogosWrapper}>
            <img
              height={60}
              src={urlWithPublicPrefix('/public/firefox-logo.svg')}
              alt="Firefox logo"
              css={{ ...styles.browserLogo, ...styles.spacing.regular }}
            />

            <TextLink
              href="https://www.mozilla.org/en-US/firefox/new/"
              target="_blank"
            >
              Firefox
            </TextLink>
          </div>

          <div css={styles.browserLogosWrapper}>
            <img
              height={60}
              src={urlWithPublicPrefix('/public/safari-logo.svg')}
              alt="Safari logo"
              css={{ ...styles.browserLogo, ...styles.spacing.regular }}
            />

            <TextLink
              href="https://support.apple.com/en_AU/downloads/safari"
              target="_blank"
            >
              Safari
            </TextLink>
          </div>

          <div css={styles.browserLogosWrapper}>
            <img
              height={60}
              src={urlWithPublicPrefix('/public/edge-logo.svg')}
              alt="Microsoft Edge logo"
              css={{ ...styles.browserLogo, ...styles.spacing.regular }}
            />

            <TextLink
              href="https://www.microsoft.com/en-us/edge"
              target="_blank"
            >
              Microsoft Edge
            </TextLink>
          </div>
        </div>
      </div>
    </div>
  );
}
