import { z } from 'zod';

/**
 * A zod schema for a humanised boolean-like value.
 *
 * if incoming value is a boolean, then it's returned as is.
 * if the incoming value is a string, then it's condidered under the following conditions:
 * - 'true' or '1' will be converted to true
 * - 'false' or '0' will be converted to false
 */
export const HumanisedBooleanLike = z
  .union([
    z.literal('true'),
    z.literal('false'),
    z.literal('1'),
    z.literal('0'),
  ])
  .or(z.boolean())
  .transform((val) => {
    if (typeof val === 'boolean') {
      return val;
    }

    if (typeof val !== 'string' && typeof val !== 'number') {
      return false;
    }

    const lowerVal = val.toString().toLowerCase();

    if (lowerVal === 'true' || lowerVal === '1') {
      return true;
    }

    if (lowerVal === 'false' || lowerVal === '0') {
      return false;
    }

    // if it's not a recognised value, then return false
    return false;
  });
