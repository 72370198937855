import { format, isSameMonth, isSameYear } from 'date-fns';

export type DateRangeFormat = 'different-years' | 'same-year' | 'same-month';

/**
 * Formats DateRanges according to Balance Design Lanaguage content guidelines
 *
 * @see https://balance.reckon.com/content/date-time#date-ranges
 */
export function dateRange(dates: [Date, Date], mode?: DateRangeFormat): string {
  const style = !!mode ? mode : getDateRangeFormatMode(dates, mode);

  const [dateFrom, dateTo] = sortDates(...dates);

  // Different years
  if (style === 'different-years') {
    return [
      format(dateFrom, 'd MMM, yyyy'),
      format(dateTo, 'd MMM, yyyy'),
    ].join(' - ');
  }

  // Same year
  if (style === 'same-year') {
    return [format(dateFrom, 'd MMM'), format(dateTo, 'd MMM, yyyy')].join(
      ' - '
    );
  }

  // Same month
  return [format(dateFrom, 'd'), format(dateTo, 'd MMM, yyyy')].join(' - ');
}

/**
 * Given a collection of dates, determine the format mode required
 */
export function getDateRangeFormatMode(
  dates: [Date, Date],
  format?: DateRangeFormat
): DateRangeFormat {
  const [dateFrom, dateTo] = dates;
  // automode

  if (isSameMonth(dateFrom, dateTo)) return 'same-month';

  if (isSameYear(dateFrom, dateTo)) return 'same-year';

  return 'different-years';
}

/**
 * Given a collection of dates, return them sorted from the past into the future.
 */
export function sortDates(...dates: Date[]): Date[] {
  return dates.sort((here, there) => here.getTime() - there.getTime());
}
