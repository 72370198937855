/** @jsx jsx */

import {
  CSSProperties,
  HTMLAttributes,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { FixedSizeList, FixedSizeListProps } from 'react-window';
import { jsx } from '@balance-web/core';
import { BalanceTheme, useTheme } from '@balance-web/theme';

import { useResizeObserver } from './utils';

// A **very thin** wrapper around react-window

type VBodyProps = { width?: number } & Omit<
  FixedSizeListProps,
  | 'className'
  | 'direction'
  | 'innerElementType'
  | 'innerRef'
  | 'innerTagName'
  | 'layout'
  | 'outerElementType'
  | 'outerRef'
  | 'outerTagName'
  | 'width'
>;
export const VBody = ({ width, ...props }: VBodyProps) => {
  const { tableWidth } = useVTableContext();

  return (
    <FixedSizeList
      {...props}
      outerElementType={VRowGroup}
      width={width || tableWidth!}
    />
  );
};

// Context Setup
// ------------------------------

export type GapType = keyof BalanceTheme['spacing'];
type ColumnWidth = number | string | undefined;
type ColumnAlign = 'start' | 'end' | 'center';
type Column = {
  /**How to align children, horizontally, within the column. */
  align?: ColumnAlign;
  /** Specifies the width of the columns's content area, including the cell gutter. */
  width?: ColumnWidth;
};

type BaseProps = HTMLAttributes<HTMLDivElement>;

const VTableContext = createContext<{
  cellGutter: GapType;
  cellJustification: { [target: string]: CSSProperties };
  rowTemplateColumns: string;
  tableWidth?: number;
}>({
  cellGutter: 'small',
  cellJustification: {},
  rowTemplateColumns: '',
  tableWidth: 0,
});
const useVTableContext = () => {
  const ctx = useContext(VTableContext);

  if (!ctx) {
    throw Error('Virtual data table elements must be inside VTable');
  }

  return ctx;
};

type VTableProps = {
  /** The spacing key to provide a horizontal gap between each column. */
  cellGutter: GapType;
  /** Specify the width and alignment for each column, a width of `null` will allow the column to stretch. */
  columns: Column[];
} & BaseProps;
export const VTable = ({
  cellGutter = 'small',
  columns: unpopulatedColumns,
  ...props
}: VTableProps) => {
  const { ref, width } = useResizeObserver(300);
  const columns = useMemo(() => populateColumns(unpopulatedColumns), [
    unpopulatedColumns,
  ]);
  const rowTemplateColumns = useMemo(
    () => pluck(columns, 'width').map(applyUnit).join(' '),
    [columns]
  );
  const cellJustification = useMemo(() => justifyCells(columns), [columns]);
  const context = {
    cellGutter,
    cellJustification,
    rowTemplateColumns,
    tableWidth: width,
  };

  return (
    <VTableContext.Provider value={context}>
      <div role="table" ref={ref} {...props} />
    </VTableContext.Provider>
  );
};

// Styled Components
// ------------------------------

export const VRowGroup = (props: BaseProps) => (
  <div role="rowgroup" {...props} />
);

export const VHead = (props: BaseProps) => {
  const { palette } = useTheme();
  const borderBottom = `1px solid ${palette.global.border}`;
  return <div role="rowgroup" css={{ borderBottom }} {...props} />;
};

export const VFoot = (props: BaseProps) => {
  const { palette } = useTheme();
  const borderTop = `1px solid ${palette.global.border}`;
  return <div role="rowgroup" css={{ borderTop }} {...props} />;
};

type VRowProps = {
  /** When true, this row will have a darker background */
  striped?: boolean;
  /** Add tone on rows to highlight issues (cautious) or errors (critical) */
  tone?: 'critical' | 'cautious';
} & BaseProps;
export const VRow = ({ striped, tone, ...props }: VRowProps) => {
  const { cellGutter, rowTemplateColumns } = useVTableContext();
  const { palette, spacing } = useTheme();

  return (
    <div
      role="row"
      data-striped={striped}
      data-tone={tone}
      css={{
        display: 'grid',
        gap: spacing[cellGutter],
        gridTemplateColumns: rowTemplateColumns,

        '&[data-striped="true"]': {
          backgroundColor: palette.background.muted,

          '&[data-tone="cautious"]': {
            backgroundColor: palette.background.cautiousMuted,
          },

          '&[data-tone="critical"]': {
            backgroundColor: palette.background.criticalMuted,
          },

          '&:focus-within': {
            backgroundColor: palette.background.active,
          },
        },

        '&[data-tone="cautious"]': {
          backgroundColor: palette.background.cautious,
        },

        '&[data-tone="critical"]': {
          backgroundColor: palette.background.critical,
        },

        '&:focus-within': {
          backgroundColor: palette.background.active,
        },
      }}
      {...props}
    />
  );
};

export const VCell = (props: BaseProps) => {
  const { cellGutter, cellJustification } = useVTableContext();
  const { spacing } = useTheme();

  return (
    <div
      role="cell"
      css={{
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        ...cellJustification,

        // Circumvent for `:first-child` selector console warning of unsafe server-side rendering with `Emotion` library.
        // See: https://github.com/emotion-js/emotion/issues/1178
        ':first-of-type': {
          paddingLeft: spacing[cellGutter],
        },
        ':last-child': {
          paddingRight: spacing[cellGutter],
        },
      }}
      {...props}
    />
  );
};

// Sorting

type SortingType = {
  asc: boolean;
  active: boolean;
};
export const SortIndicator = ({ asc, active }: SortingType) => {
  return (
    <span
      data-order-indicator="true"
      css={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: 16,

        // show/hide using opacity rather than forked render or `display: none`
        // because we want the indicator to always take up the same amount of
        // space; avoids the columns jumping around
        opacity: active ? 1 : 0.6,
      }}
    >
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity={active && asc ? 1 : 0.4}
          d="M7.59983 7.66663H0.399874C0.248275 7.66663 0.109876 7.75223 0.041876 7.88782C-0.0253236 8.02342 -0.0109237 8.18542 0.0798758 8.30662L3.67986 13.1066C3.75506 13.2074 3.87386 13.2666 3.99985 13.2666C4.12585 13.2666 4.24425 13.2074 4.31985 13.1066L7.91983 8.30662C8.01103 8.18542 8.02543 8.02342 7.95783 7.88782C7.88983 7.75223 7.75143 7.66663 7.59983 7.66663Z"
        />
        <path
          opacity={active && !asc ? 1 : 0.4}
          d="M7.59983 6.33337H0.399874C0.248275 6.33337 0.109876 6.24777 0.041876 6.11218C-0.0253236 5.97658 -0.0109237 5.81458 0.0798758 5.69338L3.67986 0.893404C3.75506 0.792604 3.87386 0.733405 3.99985 0.733405C4.12585 0.733405 4.24425 0.792604 4.31985 0.893404L7.91983 5.69338C8.01103 5.81458 8.02543 5.97658 7.95783 6.11218C7.88983 6.24777 7.75143 6.33337 7.59983 6.33337Z"
        />
      </svg>
    </span>
  );
};

export const SortButton = ({
  asc,
  active,
  children,
  ...props
}: SortingType & HTMLAttributes<HTMLButtonElement>) => {
  const { palette, spacing, typography } = useTheme();

  return (
    <button
      css={{
        alignItems: 'center',
        background: 0,
        border: 0,
        color: palette.text.muted,
        cursor: 'pointer',
        display: 'inline-flex',
        fontSize: typography.fontSize.xsmall,
        padding: 0,
      }}
      {...props}
    >
      <div css={{ marginRight: spacing.xsmall }}>{children}</div>
      <SortIndicator asc={asc} active={active} />
    </button>
  );
};

// Utils
// ------------------------------

function applyUnit(value: ColumnWidth) {
  if (!value) return 'auto';
  if (typeof value === 'string') return value;
  return `${value}`;
}
function pluck<T, K extends keyof T>(arr: T[], key: K) {
  return arr.map((obj) => obj[key]);
}
function populateColumns<T>(arr: T[]) {
  return arr.map((obj) => {
    if (!obj) return { width: undefined, align: undefined };
    return obj;
  });
}
function justifyCells(columns: Column[]) {
  return pluck(columns, 'align')
    .map((v, i) => {
      if (!v) return {};

      // Circumvent for `:nth-child` selector console warning of unsafe server-side rendering with `Emotion` library.
      // See: https://github.com/emotion-js/emotion/issues/1178
      return { [`&:nth-of-type(${i + 1})`]: { justifySelf: v } };
    })
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});
}
