import React from 'react';
import type { PropsWithChildren } from 'react';

import { PageBlockContext, PageBlockContextShape } from './PageBlockContext';

type PageBlockProviderProps = PropsWithChildren<PageBlockContextShape>;

export const PageBlockProvider = ({
  children,
  size,
  fluid,
}: PageBlockProviderProps) => {
  return (
    <PageBlockContext.Provider value={{ size, fluid }}>
      {children}
    </PageBlockContext.Provider>
  );
};
