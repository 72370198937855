/** @jsx jsx */
import { MouseEventHandler, ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';

import { useGutters, useItemHighlightingStyles } from './styleHooks';
import { useIsInSidebarNavigationGroup } from './sidebarNavigationGroupContext';
export type SidebarNavigationItemLayoutProps = {
  as?: 'button';
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  hasIcon?: boolean;
  children?: ReactNode;
};

/** @private Common layout for `SidebarNavigationItem` and `SidebarNavigationItemButton` */
export const SidebarNavigationItemLayout = ({
  as,
  onClick,
  selected,
  hasIcon,
  children,
}: SidebarNavigationItemLayoutProps) => {
  const highlightingStyles = useItemHighlightingStyles();

  const isInsideNavigationGroup = useIsInSidebarNavigationGroup();
  const { innerGutter, outterGutter, navigationGroupGutter } = useGutters();

  return (
    <Flex
      width="100%"
      css={{
        paddingLeft: isInsideNavigationGroup ? navigationGroupGutter : 0,
      }}
    >
      <Flex
        css={{
          height: hasIcon ? 56 : 48,
          paddingLeft: outterGutter,
          paddingRight: outterGutter,
          flex: 1,
        }}
      >
        <Flex
          as={as}
          data-selected={selected}
          onClick={onClick}
          flex={1}
          css={{
            height: '100%',
            position: 'relative',
            paddingLeft: innerGutter,
            paddingRight: innerGutter,
            ...highlightingStyles,
          }}
        >
          <Flex flex={1}>{children}</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
