import React from 'react';

type PressedOverlayProps = {
  backgroundColor?: string;
  styles?: Record<string, any>;
};

export const PressedOverlay = (props: PressedOverlayProps) => {
  return (
    <div
      className="pressed-overlay"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: props.backgroundColor,
        ...props.styles,
      }}
    />
  );
};
