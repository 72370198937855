import { useContext } from 'react';

import { BookIdContext } from './BookIdContext';

export const useBookId = () => {
  const context = useContext(BookIdContext);
  if (!context) {
    throw new Error(
      'useBookId must only be used within children of <BookIdProvider/>'
    );
  }
  return context;
};
