type LoadStyleContentBaseProps = { content: string };
type LoadStyleUrlBaseProps = { src: string };

type LoadStyleBaseProps = {
  id: string;
  parent: 'head' | 'body';
};

type LoadStyleSrcProps = LoadStyleBaseProps & LoadStyleUrlBaseProps;
type LoadStyleContentProps = LoadStyleBaseProps & LoadStyleContentBaseProps;

/**
 * Loads a stylesheet from an url as `<link/>` and will resolve with the load event or throw
 */
function LoadStyle(props: LoadStyleSrcProps): Promise<Event>;
/**
 * Injects stylesheet content into the parent as a `<style/>` tag and resolves when done.
 */
function LoadStyle(props: LoadStyleContentProps): Promise<void>;

function LoadStyle({
  id,
  parent = 'head',
  ...props
}: LoadStyleSrcProps | LoadStyleContentProps) {
  return new Promise((resolve, reject) => {
    if (typeof window === 'undefined' || typeof document === 'undefined')
      return Promise.resolve();

    if (!!document.querySelector(`script[id="${id}"]`))
      return Promise.resolve();

    const s = document.createElement(('content' in props && 'style') || 'link');
    s.setAttribute('id', id);

    if ('src' in props) {
      s.setAttribute('src', props.src);
      s.addEventListener('load', (event) => resolve(event));
      s.addEventListener('error', reject);
    }

    if (parent === 'head' && document.head) document.head.appendChild(s);
    if (parent === 'body' && document.body) document.body.appendChild(s);

    if ('content' in props) {
      s.innerText = props.content;
      resolve(void 0);
    }
  });
}

export { LoadStyle };
