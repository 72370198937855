import isObject from 'lodash/isObject';
import cloneDeep from 'lodash/cloneDeep';
import { typedKeys } from '@balance-web/utils';

import { WizardType } from './types';
import { isForm } from './isForm';

export const serializeWizardState = <
  T extends object,
  A extends object | void = void
>({
  wizard,
}: {
  wizard: WizardType<T, A>;
}) => {
  return JSON.stringify(
    {
      stepsState: sanitiseStepsState(wizard.stepsState),
      additionalState: wizard.additionalState,
    },
    null,
    2
  );
};

const traverseStepsState = (state: object) => {
  if (isObject(state)) {
    if (!isForm(state)) {
      typedKeys(state).forEach((k) => traverseStepsState(state[k]));
    } else {
      typedKeys(state).forEach((k) => {
        if (k !== 'value') delete state[k];
      });
    }
  }
};

const sanitiseStepsState = <T extends object>(state: T) => {
  let clonedState: any = cloneDeep(state);
  traverseStepsState(clonedState);
  return clonedState;
};
