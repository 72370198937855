import React, { Fragment, ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { Columns } from '@balance-web/columns';
import { Text } from '@balance-web/text';

import { useDataGroupContext } from './context';

export type DataRowProps = {
  children: ReactNode;
  label: string;
};

export const DataRow = ({ children, label }: DataRowProps) => {
  const { align, template } = useDataGroupContext();

  // FIXME: `Value` wrapper should be removed and implemented by consumer.
  return (
    <Columns gap="medium" template={template}>
      <Box as="dt">
        <Text color="muted">{label}</Text>
      </Box>
      <Box
        as="dd"
        minWidth={0} // fix flex overflow bug that prevents text truncation
      >
        <Text align={align}>
          <Value>{children}</Value>
        </Text>
      </Box>
    </Columns>
  );
};

// Utils
// ------------------------------

// catch empty values and return a symbol so the user's eye doesn't drift
const Value = ({ children }: { children: ReactNode }) => {
  if (children == null || children === false) {
    return <Fragment>&mdash;</Fragment>;
  }

  return <Fragment>{children}</Fragment>;
};
