/** @jsx jsx */
import { MutableRefObject, ReactNode, forwardRef, useEffect } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { RadioPrimitive, useRadioGroupItem } from '@balance-web/radio';
import { Stack } from '@balance-web/stack';

import { DENSITY_PADDING } from './constants';
import { useRadioGroupContext } from './context';
import { Density } from './types';
export type CardProps = {
  density?: Density;
  children?: ReactNode;
  value?: string;
  name?: string;
  disabled?: boolean;
  label: ReactNode;
  onSelectFocusRef?: MutableRefObject<HTMLInputElement | null>;
};

export const RadioCard = forwardRef<HTMLInputElement, CardProps>(
  ({ density = 'regular', children, onSelectFocusRef, ...radioProps }, ref) => {
    const radioGroupContext = useRadioGroupContext();
    const inputProps = useRadioGroupItem({ ...radioProps }, radioGroupContext);

    useEffect(() => {
      if (
        onSelectFocusRef?.current &&
        radioGroupContext.value === radioProps.value
      ) {
        onSelectFocusRef.current.focus();
      }
    }, [onSelectFocusRef, radioGroupContext.value, radioProps.value]);

    return (
      <Stack
        width="100%"
        height="100%"
        background="base"
        padding={DENSITY_PADDING[density]}
        gap="large"
        borderRadius="large"
      >
        <label css={{ display: 'inline-flex', alignItems: 'flex-start' }}>
          <RadioPrimitive ref={ref} {...inputProps} />
          <Box paddingX="large">{radioProps?.label}</Box>
        </label>

        {children}
      </Stack>
    );
  }
);
