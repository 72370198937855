/** @jsx jsx */

import { useEffect, useState } from 'react';
import copy from 'clipboard-copy';
import { createUrl } from 'playroom/utils';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardIcon,
  Edit3Icon,
  ToggleLeftIcon,
  ToggleRightIcon,
} from '@balance-web/icon';
import { Tooltip } from '@balance-web/tooltip';
import { Button, IconButton } from '@balance-web/button';

type ControlsProps = {
  code: string;
  codeElementId?: string;
  codeVisible: boolean;
  codeVisibleToggle: () => void;
  importsVisible: boolean;
  importsVisibleToggle: () => void;
  exampleType: 'jsx' | 'function';
  globals: string[];
};

export function Controls({
  code,
  codeElementId,
  codeVisible,
  importsVisible,
  importsVisibleToggle,
  exampleType,
  globals,
  codeVisibleToggle,
}: ControlsProps) {
  const [copied, setCopied] = useState(false);

  const playroomURL = createUrl({
    baseUrl:
      process.env.NODE_ENV !== 'production'
        ? 'http://localhost:9000'
        : '/playroom',
    code:
      exampleType === 'jsx'
        ? code
        : `<Render>\n  {() => {\n    ${code
            .split('\n')
            .join('\n    ')}\n  }}\n</Render>`,
    widths: [1024],
  });

  useEffect(() => {
    if (copied) {
      copy(code);
      const timer = setTimeout(setCopied, 1000, false);
      return () => {
        return clearTimeout(timer);
      };
    }
  }, [code, copied]);

  return (
    <Flex
      background="muted"
      paddingX="large"
      paddingY="small"
      alignItems="center"
      justifyContent="flex-end"
      gap="xsmall"
    >
      <Button
        colorScheme="tertiary"
        variant="text"
        iconBefore={codeVisible ? ChevronUpIcon : ChevronDownIcon}
        onClick={codeVisibleToggle}
        label={codeVisible ? 'Hide code' : 'Show code'}
        size="small"
        aria-label="Example code"
        aria-expanded={codeVisible}
        aria-controls={codeElementId}
      />
      {codeVisible && (
        <Tooltip content={copied ? 'Copied!' : 'Copy to clipboard'}>
          <IconButton
            variant="text"
            colorScheme="tertiary"
            size="small"
            icon={copied ? CheckIcon : ClipboardIcon}
            onClick={() => {
              return setCopied(true);
            }}
            label="Copy"
          />
        </Tooltip>
      )}
      {codeVisible && (
        <Tooltip content={importsVisible ? 'Hide imports' : 'Show imports'}>
          <IconButton
            variant="text"
            colorScheme="tertiary"
            size="small"
            icon={importsVisible ? ToggleRightIcon : ToggleLeftIcon}
            label="Show imports"
            onClick={importsVisibleToggle}
          />
        </Tooltip>
      )}
      <Flex flex={1} />
      <Button
        href={playroomURL}
        as="a"
        iconBefore={Edit3Icon}
        size="small"
        label="Edit in Playroom"
        colorScheme="tertiary"
        variant="text"
      />
    </Flex>
  );
}
