export type ForceTypeName<
  T extends { __typename: string } | null | undefined,
  TypeName extends string
> = T extends { __typename: string } ? T & { __typename: TypeName } : T;

export function assertTypeName<
  T extends { __typename: string } | null | undefined,
  TypeName extends NonNullable<T>['__typename']
>(
  maybeNode: T,
  typename: TypeName,
  skip?: boolean
): asserts maybeNode is NonNullable<ForceTypeName<T, TypeName>> {
  if (skip || isNodeType(maybeNode, typename)) {
    return;
  }

  const message = `expected “${typename}” but received ${maybeNode?.__typename}`;
  console.error(`[UNEXPECTED TYPENAME]: ${message}`);
  throw new Error(message);
}

export function isNodeType<
  T extends { __typename: string } | null | undefined,
  TypeName extends NonNullable<T>['__typename']
>(maybeNode: T, typename: TypeName): maybeNode is ForceTypeName<T, TypeName> {
  return maybeNode?.__typename === typename;
}
