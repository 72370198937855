import React from 'react';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
import { SpotCaution } from '@balance-web/illustration';
import { Button } from '@balance-web/button';
import { Text } from '@balance-web/text';
import { Heading } from '@balance-web/heading';

// A generic fallback component in case of error with the ability to reload the app
export const GenericFallback = ({
  resetError,
}: {
  resetError?: () => void;
}) => {
  return (
    <Flex alignItems="center" justifyContent="center" flex={1}>
      <Stack gap="xlarge" align="center">
        <SpotCaution />
        <Stack gap="xsmall">
          <Heading level="6" align="center">
            Something went wrong
          </Heading>
          <Text color="dim" align="center">
            We can't complete your request. Reload the page or log out if the
            issue persists.
          </Text>
        </Stack>
        <Button
          label="Try again "
          onClick={resetError || window.location.reload}
          block
        />
      </Stack>
    </Flex>
  );
};
