
/** Design system packages **/  
import * as a0 from '../../../packages/a11y'
import * as a1 from '../../../packages/accordion'
import * as a2 from '../../../packages/app-layout'
import * as a3 from '../../../packages/array-field'
import * as a4 from '../../../packages/aspect-ratio'
import * as a5 from '../../../packages/autocomplete'
import * as a6 from '../../../packages/avatar'
import * as a7 from '../../../packages/badge'
import * as a8 from '../../../packages/banner'
import * as a9 from '../../../packages/blanket'
import * as a10 from '../../../packages/bottom-drawer'
import * as a11 from '../../../packages/box'
import * as a12 from '../../../packages/breadcrumbs'
import * as a13 from '../../../packages/button'
import * as a14 from '../../../packages/button-deprecated'
import * as a15 from '../../../packages/card'
import * as a16 from '../../../packages/checkbox'
import * as a17 from '../../../packages/chip'
import * as a18 from '../../../packages/collapsable'
import * as a19 from '../../../packages/columns'
import * as a20 from '../../../packages/combobox'
import * as a21 from '../../../packages/config-menu'
import * as a22 from '../../../packages/container'
import * as a23 from '../../../packages/core'
import * as a24 from '../../../packages/currency-input'
import * as a25 from '../../../packages/data-group'
import * as a26 from '../../../packages/data-table'
import * as a27 from '../../../packages/date-input'
import * as a28 from '../../../packages/date-picker'
import * as a29 from '../../../packages/divider'
import * as a30 from '../../../packages/drawer'
import * as a31 from '../../../packages/dropdown'
import * as a32 from '../../../packages/dropdown-v2'
import * as a33 from '../../../packages/duration-input'
import * as a34 from '../../../packages/elevate'
import * as a35 from '../../../packages/field'
import * as a36 from '../../../packages/field-stack'
import * as a37 from '../../../packages/fieldset'
import * as a38 from '../../../packages/file-upload'
import * as a39 from '../../../packages/flex'
import * as a40 from '../../../packages/forms'
import * as a41 from '../../../packages/grid'
import * as a42 from '../../../packages/heading'
import * as a43 from '../../../packages/icon'
import * as a44 from '../../../packages/illustration'
import * as a45 from '../../../packages/inline'
import * as a46 from '../../../packages/link'
import * as a47 from '../../../packages/list'
import * as a48 from '../../../packages/loading'
import * as a49 from '../../../packages/media-query'
import * as a50 from '../../../packages/modal-dialog'
import * as a51 from '../../../packages/next-link'
import * as a52 from '../../../packages/notice'
import * as a53 from '../../../packages/pagination'
import * as a54 from '../../../packages/popover'
import * as a55 from '../../../packages/portal'
import * as a56 from '../../../packages/product-menu'
import * as a57 from '../../../packages/radio'
import * as a58 from '../../../packages/radio-button-group'
import * as a59 from '../../../packages/scroll-mask'
import * as a60 from '../../../packages/search-input'
import * as a61 from '../../../packages/segmented-control'
import * as a62 from '../../../packages/select-input'
import * as a63 from '../../../packages/select-menu'
import * as a64 from '../../../packages/side-drawer'
import * as a65 from '../../../packages/sidebar-navigation'
import * as a66 from '../../../packages/stack'
import * as a67 from '../../../packages/strong'
import * as a68 from '../../../packages/table'
import * as a69 from '../../../packages/tabs'
import * as a70 from '../../../packages/tag'
import * as a71 from '../../../packages/text'
import * as a72 from '../../../packages/text-input'
import * as a73 from '../../../packages/text-link'
import * as a74 from '../../../packages/text-list'
import * as a75 from '../../../packages/textarea'
import * as a76 from '../../../packages/theme'
import * as a77 from '../../../packages/tile'
import * as a78 from '../../../packages/toast'
import * as a79 from '../../../packages/toggle'
import * as a80 from '../../../packages/tooltip'
import * as a81 from '../../../packages/tray'
import * as a82 from '../../../packages/tree-view'
import * as a83 from '../../../packages/utils'
import * as a84 from '../../../packages/virtualized-data-table'
import * as a85 from '../../../packages/wizard'

/** Monorepo packages **/
import * as a86 from '../../../../packages/ui/app-header'
import * as a87 from '../../../../packages/ui/appcues'
import * as a88 from '../../../../packages/ui/application-logos'
import * as a89 from '../../../../packages/ui/auth-store'
import * as a90 from '../../../../packages/ui/branchio'
import * as a91 from '../../../../packages/ui/error-boundary'
import * as a92 from '../../../../packages/ui/events'
import * as a93 from '../../../../packages/ui/formschema-enums'
import * as a94 from '../../../../packages/ui/google-tag-manager'
import * as a95 from '../../../../packages/ui/gql-api-client'
import * as a96 from '../../../../packages/ui/heapio'
import * as a97 from '../../../../packages/ui/intercomio'
import * as a98 from '../../../../packages/ui/load-script'
import * as a99 from '../../../../packages/ui/load-style'
import * as a100 from '../../../../packages/ui/loader'
import * as a101 from '../../../../packages/ui/next-is-ssr'
import * as a102 from '../../../../packages/ui/next-meta-tags'
import * as a103 from '../../../../packages/ui/next-unsupported-browsers'
import * as a104 from '../../../../packages/ui/open-colleges'
import * as a105 from '../../../../packages/ui/paginated-scrolling-list'
import * as a106 from '../../../../packages/ui/r1-left-navigator'
import * as a107 from '../../../../packages/ui/react-beautiful-dnd'
import * as a108 from '../../../../packages/ui/reckon-balance-core'
import * as a109 from '../../../../packages/ui/reckon-balance-theme'
import * as a110 from '../../../../packages/ui/rest-api-client'
import * as a111 from '../../../../packages/ui/routing-tools'
import * as a112 from '../../../../packages/ui/sidebar'
import * as a113 from '../../../../packages/ui/site-layout'
import * as a114 from '../../../../packages/ui/task-queue'
import * as a115 from '../../../../packages/ui/testing-library'
import * as a116 from '../../../../packages/ui/use-blobs'
import * as a117 from '../../../../packages/ui/use-books'
import * as a118 from '../../../../packages/ui/use-dates'
import * as a119 from '../../../../packages/ui/use-isomorphic-layout-effect'
import * as a120 from '../../../../packages/ui/use-next-before-unload'
import * as a121 from '../../../../packages/ui/use-openstate'
import * as a122 from '../../../../packages/ui/use-session-acknowledgements'
import * as a123 from '../../../../packages/ui/use-window-size'
import * as a124 from '../../../../packages/ui/video-player'
import * as a125 from '../../../../packages/ui/wizard-hooks'

/** Additional packages **/
import * as a126 from 'lodash'
import * as a127 from '@faker-js/faker'
import * as a128 from 'use-resize-observer'
import * as a129 from 'react-hook-form'

export const mods = {

/** Design system packages **/  
'@balance-web/a11y': a0,
'@balance-web/accordion': a1,
'@balance-web/app-layout': a2,
'@balance-web/array-field': a3,
'@balance-web/aspect-ratio': a4,
'@balance-web/autocomplete': a5,
'@balance-web/avatar': a6,
'@balance-web/badge': a7,
'@balance-web/banner': a8,
'@balance-web/blanket': a9,
'@balance-web/bottom-drawer': a10,
'@balance-web/box': a11,
'@balance-web/breadcrumbs': a12,
'@balance-web/button': a13,
'@balance-web/button-deprecated': a14,
'@balance-web/card': a15,
'@balance-web/checkbox': a16,
'@balance-web/chip': a17,
'@balance-web/collapsable': a18,
'@balance-web/columns': a19,
'@balance-web/combobox': a20,
'@balance-web/config-menu': a21,
'@balance-web/container': a22,
'@balance-web/core': a23,
'@balance-web/currency-input': a24,
'@balance-web/data-group': a25,
'@balance-web/data-table': a26,
'@balance-web/date-input': a27,
'@balance-web/date-picker': a28,
'@balance-web/divider': a29,
'@balance-web/drawer': a30,
'@balance-web/dropdown': a31,
'@balance-web/dropdown-v2': a32,
'@balance-web/duration-input': a33,
'@balance-web/elevate': a34,
'@balance-web/field': a35,
'@balance-web/field-stack': a36,
'@balance-web/fieldset': a37,
'@balance-web/file-upload': a38,
'@balance-web/flex': a39,
'@balance-web/forms': a40,
'@balance-web/grid': a41,
'@balance-web/heading': a42,
'@balance-web/icon': a43,
'@balance-web/illustration': a44,
'@balance-web/inline': a45,
'@balance-web/link': a46,
'@balance-web/list': a47,
'@balance-web/loading': a48,
'@balance-web/media-query': a49,
'@balance-web/modal-dialog': a50,
'@balance-web/next-link': a51,
'@balance-web/notice': a52,
'@balance-web/pagination': a53,
'@balance-web/popover': a54,
'@balance-web/portal': a55,
'@balance-web/product-menu': a56,
'@balance-web/radio': a57,
'@balance-web/radio-button-group': a58,
'@balance-web/scroll-mask': a59,
'@balance-web/search-input': a60,
'@balance-web/segmented-control': a61,
'@balance-web/select-input': a62,
'@balance-web/select-menu': a63,
'@balance-web/side-drawer': a64,
'@balance-web/sidebar-navigation': a65,
'@balance-web/stack': a66,
'@balance-web/strong': a67,
'@balance-web/table': a68,
'@balance-web/tabs': a69,
'@balance-web/tag': a70,
'@balance-web/text': a71,
'@balance-web/text-input': a72,
'@balance-web/text-link': a73,
'@balance-web/text-list': a74,
'@balance-web/textarea': a75,
'@balance-web/theme': a76,
'@balance-web/tile': a77,
'@balance-web/toast': a78,
'@balance-web/toggle': a79,
'@balance-web/tooltip': a80,
'@balance-web/tray': a81,
'@balance-web/tree-view': a82,
'@balance-web/utils': a83,
'@balance-web/virtualized-data-table': a84,
'@balance-web/wizard': a85,

/** Monorepo packages **/
'@reckon-web/app-header': a86,
'@reckon-web/appcues': a87,
'@reckon-web/application-logos': a88,
'@reckon-web/auth-store': a89,
'@reckon-web/branchio': a90,
'@reckon-web/error-boundary': a91,
'@reckon-web/events': a92,
'@reckon-web/formschema-enums': a93,
'@reckon-web/google-tag-manager': a94,
'@reckon-web/gql-api-client': a95,
'@reckon-web/heapio': a96,
'@reckon-web/intercomio': a97,
'@reckon-web/load-script': a98,
'@reckon-web/load-style': a99,
'@reckon-web/loader': a100,
'@reckon-web/next-is-ssr': a101,
'@reckon-web/next-meta-tags': a102,
'@reckon-web/next-unsupported-browsers': a103,
'@reckon-web/open-colleges': a104,
'@reckon-web/paginated-scrolling-list': a105,
'@reckon-web/r1-left-navigator': a106,
'@reckon-web/react-beautiful-dnd': a107,
'@reckon-web/reckon-balance-core': a108,
'@reckon-web/reckon-balance-theme': a109,
'@reckon-web/rest-api-client': a110,
'@reckon-web/routing-tools': a111,
'@reckon-web/sidebar': a112,
'@reckon-web/site-layout': a113,
'@reckon-web/task-queue': a114,
'@reckon-web/testing-library': a115,
'@reckon-web/use-blobs': a116,
'@reckon-web/use-books': a117,
'@reckon-web/use-dates': a118,
'@reckon-web/use-isomorphic-layout-effect': a119,
'@reckon-web/use-next-before-unload': a120,
'@reckon-web/use-openstate': a121,
'@reckon-web/use-session-acknowledgements': a122,
'@reckon-web/use-window-size': a123,
'@reckon-web/video-player': a124,
'@reckon-web/wizard-hooks': a125,

/** Additional packages **/
'lodash': a126,
'@faker-js/faker': a127,
'use-resize-observer': a128,
'react-hook-form': a129,
}
