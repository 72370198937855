import React from 'react';

import { RadioGroupProps } from './types';
import { RadioGroupContext } from './context';
import { useRadioGroupState } from './useRadioGroupState';

export const RadioGroup = <Value extends string>({
  children,
  ...props
}: RadioGroupProps<Value>) => {
  const context = useRadioGroupState(props);

  return (
    <RadioGroupContext.Provider value={context}>
      <div role="radiogroup" style={{ display: 'contents' }}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
};
