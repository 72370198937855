import React from 'react';

import { ErrorBoundaryContext } from './context';
import { ErrorBoundary } from './ErrorBoundary';
import { GenericFallback } from './GenericFallback';
export const ErrorBoundaryProvider: React.FC<{
  onError?: ({ error, appZone }: { error?: Error; appZone: string }) => void;
}> = ({ onError, children }) => {
  return (
    <ErrorBoundaryContext.Provider value={{ onError }}>
      <ErrorBoundary
        appZone="error boundary provider"
        Fallback={GenericFallback}
      >
        {children}
      </ErrorBoundary>
    </ErrorBoundaryContext.Provider>
  );
};
