export const toggleHTMLAttribute = (
  element: Element | null,
  attribute: string,
  onValue: string,
  offValue: string
) => {
  element?.setAttribute(
    attribute,
    element?.getAttribute(attribute) === onValue ? offValue : onValue
  );
};

export const toggleHTMLAttributeOrRemove = (
  element: Element | null,
  attribute: string,
  value: string
) => {
  if (element?.getAttribute(attribute) === value) {
    element.removeAttribute(attribute);
  } else {
    element?.setAttribute(attribute, value);
  }
};

export const isLeftNavigatorOpen = () => {
  return (
    document
      .getElementById('app-navigation-leftnav')
      ?.getAttribute('data-open') === '1'
  );
};

export type DataAttributeMap = Record<
  string,
  string | number | boolean | undefined
>;

export type WithDataAttributeProp<T> = T & {
  data?: DataAttributeMap;
};

export const buildDataAttributes = (
  data: DataAttributeMap = {},
  prefix?: string
): DataAttributeMap => {
  const keys = Object.keys(data).filter((key) => {
    return data[key] !== undefined;
  });
  const dataAttributes: DataAttributeMap = {};
  const prefixString = prefix ? `data-${prefix}-` : 'data-';

  for (const key of keys) {
    dataAttributes[`${prefixString}${key}`] = data[key];
  }

  return dataAttributes;
};
