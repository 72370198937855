/** @jsx jsx */
import { jsx } from '@balance-web/core';

import { useTheme } from '../../utils/node_modules/@balance-web/theme/src';

export const InteractionOverlay = () => {
  const theme = useTheme();
  return (
    <div
      className="interactionOverlay"
      css={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        borderRadius: theme.components.chip.borderRadius,
      }}
    />
  );
};
