import { format, formatISO, parse, parseISO } from 'date-fns';

import { ISODate } from './types';

const PRESENTATION = 'dd/MM/yyyy';
const SHORT = 'dd/MM/yy';

export function formatDateObj(date: Date) {
  // using this over toLocaleDateString because it formats 0020-01-01 as 1/1/20
  // which people might think means 2020-01-01 which could be confusing.
  // this formats 0020-01-01 as 01/01/0020
  return format(date, PRESENTATION);
}

// input helpers

export function getExternalValue(str: string) {
  let parsed = parseDate(str);
  if (Number.isNaN(parsed.valueOf())) {
    return undefined;
  }
  return formatISO(parsed, { representation: 'date' }) as ISODate;
}

export function parseDate(str: string, pattern = PRESENTATION) {
  return parse(str, pattern, new Date());
}

// NOTE: `parseISO` used to avoid timezone issues
export function formatValue(value: ISODate | undefined) {
  return value === undefined ? '' : formatDateObj(parseISO(value));
}

/**
 * Leverage date-fn RangeError to support two-digit year
 * @see https://date-fns.org/v2.27.0/docs/parse#exceptions
 * @example
 * validateShorthand('24/02') // "24/02" (incomplete)
 * validateShorthand('24/02/2021') // "24/02/2021" (complete)
 * validateShorthand('24/02/21') // "24/02/2021" (formatted)
 */
export function validateShorthand(str: string) {
  try {
    let parsed = parseDate(str, SHORT);
    return formatDateObj(parsed);
  } catch (error) {
    return str;
  }
}
