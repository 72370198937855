/** @jsx jsx */

import { Fragment, useReducer } from 'react';
import { useLiveCode } from '@untitled-docs/live-code';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { Divider } from '@balance-web/divider';
import { useTheme } from '@balance-web/theme';
import { useId } from '@balance-web/utils';
import { Flex } from '@balance-web/flex';

import { Highlight } from '../Highlight';
import { packagesByExportName } from '../scope';

import { Controls } from './Controls';
import { Pre } from './Pre';

export type CodeResolutionProps = {
  /** The untransformed contents of the code block. */
  originalCode: string;
  /** The transformed contents, if typescript, of the code block. */
  code: string;
  /** Only render the result of execution, not the code itself. */
  demo?: boolean;
  /** Render the code **and** the result of execution. */
  example?: boolean;
  /** The language of the code snippet. */
  language: string;
  /**
   * Highlight specific lines of code.
   * @see https://prismjs.com/plugins/line-highlight/#how-to-use
   */
  metastring?: string;
  /** @private Populate external resources, making them available for execution. */
  scope: Record<string, any>;
  /** Render a demo example at the top of package docs for a quick look. Only applicable when demo=true.*/
  intro?: boolean;
  /** Set to true if there's typescript in the code block. We don't assume it to always be true for performance
   *  reasons because ts code needs to be transpiled.*/
  typescript?: boolean;
};

/**
 * A thin component wrapper around the `useLiveCode` hook, so we can render
 * conditionally.
 */
export const CodeResolution = ({
  originalCode,
  code,
  demo,
  intro,
  language,
  metastring,
  scope,
}: CodeResolutionProps) => {
  const { spacing } = useTheme();
  const codeElementId = useId();
  const [codeVisible, codeVisibleToggle] = useReducer((v) => !v, false);
  const [importsVisible, importsVisibleToggle] = useReducer((v) => !v, false);

  const options = { code, scope, initialCompiledResult: null };
  const {
    element,
    error,
    latestSuccessfulCompiledResult: result,
  } = useLiveCode(options);

  // Early return on error
  if (error) {
    console.error(error);
    return <Pre tone="critical">{error}</Pre>;
  }

  // Early return on "demo", only render the result of execution.

  if (demo && intro) {
    return (
      <Flex
        background="shade"
        alignItems="center"
        justifyContent="center"
        css={{
          overflow: 'auto',
          padding: spacing.large,
        }}
      >
        {element}
      </Flex>
    );
  }

  if (demo) {
    return (
      <div
        css={{
          overflow: 'auto',
          padding: spacing.large,
        }}
      >
        {element}
      </div>
    );
  }

  let imports = result
    ? getImportsByPackageName([...result.globals, 'jsx'])
    : null;
  let importsStr = imports ? getImportString(imports) : null;

  return (
    <Fragment>
      <div css={{ overflow: 'auto', padding: spacing.large }}>{element}</div>
      <Controls
        globals={result?.globals}
        exampleType={result?.exampleType}
        code={code}
        codeElementId={codeElementId}
        codeVisibleToggle={codeVisibleToggle}
        codeVisible={codeVisible}
        importsVisibleToggle={importsVisibleToggle}
        importsVisible={importsVisible}
      />
      <div id={codeElementId} role="region">
        {/* imports */}
        {importsStr && (
          <div
            style={{
              display: codeVisible && importsVisible ? 'block' : 'none',
            }}
          >
            <Pre>
              <Highlight
                code={importsStr}
                language={language}
                metastring={metastring}
              />
            </Pre>
            <Box paddingX="large">
              <Divider />
            </Box>
          </div>
        )}
        {/* snippet */}
        <Pre style={{ display: codeVisible ? 'block' : 'none' }}>
          <Highlight
            code={originalCode}
            language={language}
            metastring={metastring}
          />
        </Pre>
      </div>
    </Fragment>
  );
};

// Utils
// ------------------------------

function getImportsByPackageName(globals: string[]) {
  let imports: Record<string, string[]> = {};
  globals.forEach((name) => {
    let pkgName = packagesByExportName[name];
    if (pkgName) {
      if (!imports[pkgName]) {
        imports[pkgName] = [];
      }
      imports[pkgName].push(name);
    }
  });
  return imports;
}

function getImportString(imports: Record<string, string[]>) {
  return Object.keys(imports)
    .sort(compareImports)
    .map((x) => `import { ${imports[x].join(', ')} } from '${x}';`)
    .join('\n');
}

function compareImports(a: string, b: string) {
  // pull non-org imports to the top
  if (!a.includes('@')) return -1;
  if (!b.includes('@')) return 1;

  // standard sort
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}
