/** @jsx jsx */

import { Fragment, forwardRef } from 'react';
import { VisuallyHidden } from '@balance-web/a11y';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

import type { RadioPrimitiveProps, RadioSize } from './types';

export const RadioPrimitive = forwardRef<HTMLInputElement, RadioPrimitiveProps>(
  ({ size, ...inputProps }, ref) => {
    return (
      <Fragment>
        <VisuallyHidden
          ref={ref}
          as="input"
          type="radio"
          aria-checked={inputProps.checked}
          {...inputProps}
        />
        <Indicator size={size}>
          <DotIcon size={size} />
        </Indicator>
      </Fragment>
    );
  }
);

const sizeToScaleKey = {
  small: 'xxsmall',
  medium: 'xsmall',
} as const;

const Indicator = ({
  size = 'medium',
  ...props
}: {
  children?: React.ReactNode;
  size?: RadioSize;
}) => {
  const { palette, sizing } = useTheme();
  const resolvedSize = sizing[sizeToScaleKey[size]];

  return (
    <div
      css={{
        alignItems: 'center',
        backgroundColor: palette.formControl.background,
        border: `2px solid ${palette.formControl.border}`,
        borderRadius: '50%',
        boxSizing: 'border-box',
        color: palette.formControl.background,
        cursor: 'pointer',
        display: 'flex',
        flexShrink: 0,
        height: resolvedSize,
        justifyContent: 'center',
        width: resolvedSize,
        position: 'relative',

        // For the outter circle on hover
        '::after': {
          content: '""',
          display: 'block',
          width: '180%',
          height: '180%',
          borderRadius: 9999,
          background: '#efefef',
          opacity: 0,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          zIndex: -1,
        },

        'input:hover + &': {
          backgroundColor: '#efefef',
          '::after': {
            opacity: 0.7,
          },
        },

        'input + & svg': {
          opacity: 0,
        },
        'input:checked + & svg': {
          opacity: 1,
        },

        'input:checked + &': {
          backgroundColor: palette.formControl.background,
          borderColor: palette.formControl.interaction,
          color: palette.formControl.interaction,
        },
        'input:disabled + &': {
          backgroundColor: palette.formControl.border,
          borderColor: palette.formControl.border,
          color: palette.formControl.border,
          cursor: 'default',
        },
        'input:checked:disabled + &': {
          color: palette.formControl.foregroundDisabled,
        },
      }}
      {...props}
    />
  );
};

// "Dot" SVG
// ------------------------------

const sizeMap = {
  small: 16,
  medium: 24,
  large: 32,
};
const DotIcon = ({ size = 'medium' }: { size?: keyof typeof sizeMap }) => {
  let resolvedSize = sizeMap[size];
  return (
    <svg
      viewBox="0 0 24 24"
      width={resolvedSize}
      height={resolvedSize}
      fill="currentColor"
    >
      <circle cx="12" cy="12" r="8" />
    </svg>
  );
};
