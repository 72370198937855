/** @jsx jsx */

import type { KeyboardEvent, ReactNode } from 'react';
import { Fragment } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { Blanket } from '@balance-web/blanket';
import { jsx, keyframes } from '@balance-web/core';
import { Portal } from '@balance-web/portal';
import { useTheme } from '@balance-web/theme';
import { forwardRefWithAs } from '@balance-web/utils';
import { Elevate } from '@balance-web/elevate';

type TrayBaseProps = {
  children: ReactNode;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  width: number | string;
};

const slideInAnim = keyframes({
  from: {
    transform: 'translateY(20%)',
    opacity: 0,
  },
});
const easing = 'cubic-bezier(0.2, 0, 0, 1)';

export const TrayBase = forwardRefWithAs<'div', TrayBaseProps>(
  ({ as: Tag = 'div', isOpen, onClose, width, children, ...props }, ref) => {
    const theme = useTheme();
    const gutter = theme.spacing.small;

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && !event.defaultPrevented) {
        event.preventDefault(); // Avoid potential drawer close
        onClose();
      }
    };

    return isOpen ? (
      <Portal>
        <Fragment>
          <Blanket animate onClick={onClose} />
          <FocusLock
            autoFocus
            returnFocus
            lockProps={{ style: { display: 'contents' } }}
          >
            <RemoveScroll enabled forwardProps>
              <Tag
                aria-modal="true"
                role="dialog"
                tabIndex={-1}
                onKeyDown={onKeyDown}
                ref={ref}
                css={{
                  animation: `${slideInAnim} 320ms ${easing}`,
                  backgroundColor: theme.palette.background.base,
                  borderTopLeftRadius: theme.radii.large,
                  borderTopRightRadius: theme.radii.large,
                  bottom: 0,
                  boxShadow: theme.shadow.large,
                  left: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxHeight: `calc(100vh - ${gutter} * 2)`,
                  position: 'fixed',
                  right: 0,
                  transition: `transform 150ms ${easing}`,
                  maxWidth: width,
                  zIndex: theme.elevation.modal,
                }}
                {...props}
              >
                <Elevate>{children}</Elevate>
              </Tag>
            </RemoveScroll>
          </FocusLock>
        </Fragment>
      </Portal>
    ) : null;
  }
);
