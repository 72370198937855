/** @jsx jsx */

import type { ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { flattenElements } from '@balance-web/utils';
import { useRadioGroupState } from '@balance-web/radio';
import type { RadioGroupProps } from '@balance-web/radio';

import { RadioItem } from './RadioItem';
import { RadioListContextProvider } from './radioListContext';
import { useListSeparatorStyles } from './styles';
import { useHideItemBorderOnHover } from './useHideItemBorderOnHover';

export type RadioListProps<TValue extends string> = RadioGroupProps<TValue> & {
  children: ReactNode;
  value?: string;
};

export const RadioList = <TValue extends string>({
  children,
  ...props
}: RadioListProps<TValue>) => {
  const childArray = flattenElements(children);
  const context = useRadioGroupState(props);

  const childRefs = useHideItemBorderOnHover({ children });
  const listSeparatorStyles = useListSeparatorStyles();

  return (
    <RadioListContextProvider value={context}>
      <Box paddingY="medium">
        <ul>
          {childArray.map((child, index) => {
            return (
              <li
                key={child.props['label'] || child['key'] || index}
                ref={(element) => (childRefs.current[index] = element)}
                css={listSeparatorStyles}
              >
                {child}
              </li>
            );
          })}
        </ul>
      </Box>
    </RadioListContextProvider>
  );
};
RadioList.Item = RadioItem;
