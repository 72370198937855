export const TRANSITION_CURVE = 'cubic-bezier(0.2, 0, 0, 1) 0s';

export const HOVER_TARGET_HEIGHT = 40;

// Move these into theme after figuring out a system for interaction overlays
export const HOVER_BG = '#5E5E6214';
export const PRESSED_BG = '#5E5E6229';
export const ACTIVE_HOVER_BG = '#14005D14';
export const ACTIVE_PRESSED_BG = '#14005D29';

export const APP_HEADER_HEIGHT = 72;
