import { createContext, useContext } from 'react';

type CollapsableContext = {
  isOpen: boolean;
};

const collapsableContext = createContext<CollapsableContext | null>(null);

export const CollapsableContextProvider = collapsableContext.Provider;

export const useCollapsableContext = () => {
  const context = useContext(collapsableContext);
  if (!context) {
    throw Error('useCollapsableContext can only be used inside Collapsable');
  }
  return context;
};
