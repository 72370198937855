import { RequestOptions } from './types';

export abstract class BaseAuthenticationStrategy {
  constructor(public options = {}) {
    this.options = options;
  }
  public makeHeaders(options?: RequestOptions): Record<string, string> {
    return options?.headers || {};
  }
}

/**
 * example:
 *      const authStrategy = new HeaderAuthenticationStrategy({
 *          key: 'authorizationtoken'
 *      });
 *      const client = new FetchClient({
 *          authStrategy
 *      })
 *      // .... a few moments later
 *      authStrategy.setToken('Yay!');
 *      ....
 */
export type BearerMfaAuthenticationStrategyOptions = {
  bearerTokenKey?: string;
  mfaTokenKey?: string;
};

export type BearerMfaAuthenticationStrategyRequestOptions = RequestOptions & {
  requiresAuthentication?: boolean;
  requiresMfa?: boolean;
};
export class BearerMfaAuthenticationStrategy extends BaseAuthenticationStrategy {
  public bearerToken?: string;
  public mfaToken?: string;

  constructor(
    public options: BearerMfaAuthenticationStrategyOptions = {
      bearerTokenKey: 'authorization',
      mfaTokenKey: 'x-mfa-token',
    }
  ) {
    super(options);
    this.bearerToken = undefined;
    this.mfaToken = undefined;
  }

  setBearerToken(token?: string) {
    this.bearerToken = token;
  }

  setMfaToken(token?: string) {
    this.mfaToken = token;
  }

  makeHeaders(options?: BearerMfaAuthenticationStrategyRequestOptions) {
    const headers = super.makeHeaders(options);
    const { requiresAuthentication, requiresMfa } = options || {};
    /**
     * If this endpoint requires Authentication then fail if there's no defined key for it or no provided/stored token to use
     */
    if (!!requiresAuthentication && !this.options?.bearerTokenKey)
      throw Error(
        'bearerTokenKey missing on BearerMfaAuthenticationStrategy. a bearerTokenKey is required for the name of a header to which a bearer token will be set.'
      );
    if (!!requiresAuthentication && !this.bearerToken)
      throw Error('Missing auth token');

    /**
     * If this endpoint requires MFA Authentication then fail if there's no defined key for it or no provided/stored token to use
     */
    if (!!requiresMfa && !this.options?.mfaTokenKey)
      throw Error(
        'mfaTokenKeyMissing on BearerMfaAuthenticationStrategy. mfaTokenKeyMissing is required for the name of a header to which a mfa token will be set.'
      );
    if (!!requiresMfa && !this.mfaToken) throw Error('Missing MFA token');

    const output = { ...headers };

    if (
      !!requiresAuthentication &&
      !!this.options?.bearerTokenKey &&
      this.bearerToken
    )
      output[this.options.bearerTokenKey] = this.bearerToken;

    if (!!requiresMfa && !!this.options?.mfaTokenKey && this.mfaToken)
      output[this.options.mfaTokenKey] = this.mfaToken;

    return output;
  }
}
