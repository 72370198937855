import React from 'react';
import { LoadingDots } from '@balance-web/loading';
import { Flex } from '@balance-web/flex';
import type { FlexProps } from '@balance-web/flex';

export const ItemLoadingIndicator = (props: FlexProps) => {
  return (
    <Flex {...props}>
      <LoadingDots label="loading more" color="dim" />
    </Flex>
  );
};
