import React, { forwardRef } from 'react';

import { TableFoot, TableFootProps } from './TableFoot';
import { TableRow } from './TableRow';

export type TableFooterProps = TableFootProps;

export const TableFooter = forwardRef<HTMLDivElement, TableFooterProps>(
  ({ children, ...props }, ref) => {
    return (
      <TableFoot ref={ref} {...props}>
        <TableRow>{children}</TableRow>
      </TableFoot>
    );
  }
);
