import React, { useMemo } from 'react';
import { Dropdown } from '@balance-web/dropdown-v2';

import type { NestedPage } from './types';
import { isPageSelected } from './isPageSelected';

export const NavigationItemAsDropdownMenu = ({
  page,
  selectedPage,
  testid,
}: {
  page: NestedPage;
  selectedPage: string;
  testid?: string;
}) => {
  const isSelected = useMemo(() => isPageSelected(page, selectedPage), [
    page,
    selectedPage,
  ]);

  if (page.options) {
    const submenuDataAttribtues = page.testid
      ? { testid: `${page.testid}-navigation-dropdown` }
      : {};

    return (
      <Dropdown.SubMenu data={submenuDataAttribtues}>
        <Dropdown.SubMenuTrigger
          selectedLabel={page.label}
          selected={isSelected}
        >
          {page.label}
        </Dropdown.SubMenuTrigger>
        {page.options.map((option) => {
          const optionsDataAttributes = option.testid
            ? { testid: option.testid }
            : {};
          return (
            <Dropdown.Item
              key={`${page.label}-${option.label}`}
              href={option.href}
              selected={isPageSelected(option, selectedPage)}
              selectedLabel={option.label}
              data={optionsDataAttributes}
            >
              {option.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.SubMenu>
    );
  }

  return (
    <Dropdown.Item
      href={page.href}
      selectedLabel={page.label}
      selected={isSelected}
    >
      {page.label}
    </Dropdown.Item>
  );
};
