/* @jsx jsx */
import { HTMLAttributes } from 'react';
import { useTheme } from '@balance-web/theme';
import { jsx } from '@balance-web/core';

export const TableEmptyState = (props: HTMLAttributes<HTMLDivElement>) => {
  const { palette } = useTheme();
  return (
    <div
      css={{
        alignItems: 'center',
        backgroundColor: palette.background.dim,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 240,
        textAlign: 'center',
      }}
      {...props}
    />
  );
};
