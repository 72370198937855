import React from 'react';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
import { Heading } from '@balance-web/heading';
import { Text } from '@balance-web/text';
import { ArrowDownIcon, ArrowUpIcon } from '@balance-web/icon';
import { Divider } from '@balance-web/divider';
import { SegmentedControl } from '@balance-web/segmented-control';
import { Field } from '@balance-web/field';
import { SearchInput } from '@balance-web/search-input';

import { BookSwitcherSearchFooter } from '../BookSwitcherSearchFooter';
import type { BaseBook } from '../types';
import { BookStatusEnum } from '../constants';
import type {
  BookSortEnumValueType,
  BookStatusEnumValueType,
} from '../constants';
import { BookSwitcherResults } from '../BookSwitcherResults';

export type BookSwitcherSearchProps<TBook extends BaseBook> = {
  bookSearchFilter?: string;
  books: TBook[];
  sortDirection: BookSortEnumValueType;
  selectedBookStatus: BookStatusEnumValueType;
  isLoading?: boolean;
  missingBookHelpURL: string;
  manageSubscriptionsURL: string;
  userHasFinancePermission?: boolean;

  onOpenBookClick: (bookId: string) => void;
  onBookSortToggleClick: () => void;
  onBookSearchFilterChange: (search: string) => void;
  onBookStatusFilterChange: (status: BookStatusEnumValueType) => void;
  onCompleteSetupBookClick: (bookId: string) => void;
};

export function BookSwitcherSearch<TBook extends BaseBook>({
  books,
  isLoading,
  bookSearchFilter,
  sortDirection,
  selectedBookStatus,
  manageSubscriptionsURL,
  missingBookHelpURL,
  userHasFinancePermission,

  onBookSortToggleClick,
  onBookSearchFilterChange,
  onBookStatusFilterChange,
  onCompleteSetupBookClick,
  onOpenBookClick,
}: BookSwitcherSearchProps<TBook>) {
  const bookStatusOptions = BookStatusEnum.selectOptions.filter((option) => {
    return ['ACTIVE', 'CANCELLED'].includes(option.value);
  });

  return (
    <>
      <Flex direction="column" paddingTop="small">
        <Heading level="6" align="center">
          Select a book
        </Heading>

        <Flex
          paddingTop="medium"
          gap="large"
          alignItems="center"
          marginX="large"
        >
          <Flex flexGrow={1} direction="column">
            <Field label="Filter book or organisation" labelVisible={false}>
              <SearchInput
                placeholder="Filter by book or organisation ..."
                size="small"
                value={bookSearchFilter || ''}
                onClear={() => {
                  return onBookSearchFilterChange('');
                }}
                onChange={(event) => {
                  onBookSearchFilterChange(event.target.value);
                }}
              />
            </Field>
          </Flex>
          <SegmentedControl
            options={bookStatusOptions}
            value={selectedBookStatus}
            onChange={onBookStatusFilterChange}
          />
        </Flex>

        <Stack gap="medium">
          <Flex
            as="button"
            alignItems="center"
            gap="small"
            marginLeft="large"
            marginTop="large"
            onClick={onBookSortToggleClick}
            cursor="pointer"
          >
            <Text size="small" color="dim" weight="medium">
              BOOK
            </Text>
            {sortDirection === 'ASC' && (
              <ArrowDownIcon color="dim" size="small" />
            )}
            {sortDirection === 'DESC' && (
              <ArrowUpIcon color="dim" size="small" />
            )}
          </Flex>
          <Divider />
        </Stack>
      </Flex>

      <BookSwitcherResults
        books={books}
        isLoading={isLoading}
        userHasFinancePermission={userHasFinancePermission}
        onCompleteSetupBookClick={onCompleteSetupBookClick}
        onOpenSelectedBookClick={onOpenBookClick}
      />

      {books.length > 0 && (
        <BookSwitcherSearchFooter
          manageSubscriptionsURL={manageSubscriptionsURL}
          missingBookHelpURL={missingBookHelpURL}
        />
      )}
    </>
  );
}
