import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonAccessLogoName = 'ReckonAccessLogo';
export const ReckonAccessAppname = 'Access';
export const ReckonAccessAppLongName = 'Reckon Access';
export const ReckonAccessLogoDescription = 'Reckon Access Application Logo';

export const ReckonAccessLogo = createIllustration(
  {
    name: ReckonAccessLogoName,
    description: ReckonAccessLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#a)"
      />
    </mask>
    <mask
      id="d"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#c)"
      />
    </mask>
    <g mask="url(#d)">
      <path fill="url(#e)" d="M0 0h60v60H0z" />
      <g clipPath="url(#f)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.04 16.695a7.432 7.432 0 0 1 5.142-2.044c1.929 0 3.779.735 5.142 2.044 1.364 1.308 2.13 3.083 2.13 4.934v5.233H22.91V21.63c0-1.85.766-3.626 2.13-4.934Zm-5.767 10.167V21.63c0-2.776 1.15-5.438 3.195-7.401 2.046-1.963 4.82-3.066 7.714-3.066 2.893 0 5.668 1.103 7.714 3.066 2.046 1.963 3.195 4.625 3.195 7.4v5.234h1.818c3.013 0 5.455 2.343 5.455 5.233v12.212c0 2.89-2.442 5.233-5.455 5.233H17.455c-3.013 0-5.455-2.343-5.455-5.233V32.094c0-2.89 2.442-5.233 5.454-5.233h1.819Zm20 3.489H17.454c-1.004 0-1.818.781-1.818 1.744v12.212c0 .963.814 1.744 1.819 1.744h25.454c1.004 0 1.818-.781 1.818-1.745v-12.21c0-.964-.814-1.745-1.818-1.745h-3.636Z"
          fill="#fff"
        />
      </g>
      <g filter="url(#g)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.375 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.375 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={37.259}
        y2={34.646}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E30613" />
        <stop offset={1} stopColor="#F84184" />
      </linearGradient>
      <clipPath id="f">
        <path
          fill="#fff"
          transform="translate(10 11.162)"
          d="M0 0h40v38.377H0z"
        />
      </clipPath>
      <filter
        id="g"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_14351_1174"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_14351_1174"
          result="shape"
        />
      </filter>
    </defs>
  </React.Fragment>
);
