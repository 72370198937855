import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonExpensesLogoName = 'ReckonExpensesLogo';
export const ReckonExpensesAppName = 'Expenses';
export const ReckonExpensesAppLongName = 'Reckon Expenses';
export const ReckonExpensesLogoDescription = 'Reckon Expenses Application Logo';

export const ReckonExpensesLogo = createIllustration(
  {
    name: ReckonExpensesLogoName,
    description: ReckonExpensesLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#a)"
      />
    </mask>
    <g mask="url(#b)">
      <path fill="url(#c)" d="M0 0h60v60H0z" />
      <path
        d="M31.415 18.625a1.081 1.081 0 0 0-1.76-.35 1.08 1.08 0 0 0 1.528 1.527 1.08 1.08 0 0 0 .232-1.177ZM36.415 26.115c-.436 0-.83-.263-.996-.667a1.077 1.077 0 0 1 1.41-1.41 1.077 1.077 0 0 1 .35 1.76 1.083 1.083 0 0 1-.764.317Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.606 43.24c-.329.334-.826.43-1.25.24a1.203 1.203 0 0 1-.665-1.16V15.238c0-1.759 1.25-3.239 2.97-3.239H42.74c1.72 0 2.97 1.48 2.97 3.239v27.08c.032.49-.232.95-.664 1.16-.425.19-.922.096-1.251-.24l-2.62-2.478-3.205 2.92a1.232 1.232 0 0 1-1.602 0L33.2 40.76l-3.167 2.92a1.25 1.25 0 0 1-.78.319 1.251 1.251 0 0 1-.822-.32v-.001l-3.206-2.92-2.619 2.48Zm10.288-23.177a2.678 2.678 0 1 1-4.949-2.05 2.678 2.678 0 0 1 4.949 2.05Zm-6.713 11.93h7.395a.8.8 0 1 0 0-1.6h-7.395a.8.8 0 1 0 0 1.6Zm0 3.598h14.473a.8.8 0 1 0 0-1.6H26.18a.8.8 0 1 0 0 1.6Zm10.234-13.235a2.678 2.678 0 1 0 0 5.357 2.678 2.678 0 0 0 0-5.357Zm-7.156 4.637a.798.798 0 0 1-.559-.238.839.839 0 0 1 0-1.16l8.556-8.517a.801.801 0 0 1 1.045.074c.28.281.313.727.074 1.045l-8.556 8.558a.722.722 0 0 1-.56.239Z"
        fill="#fff"
      />
      <g filter="url(#d)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.374 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.374 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <mask
      id="f"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#e)"
      />
    </mask>
    <g mask="url(#f)">
      <path fill="url(#g)" d="M0 0h60v60H0z" />
      <path
        d="M27.409 19.107a1.348 1.348 0 0 0-1.245-.799 1.38 1.38 0 0 0-.955.379 1.27 1.27 0 0 0-.394.916c0 .523.327.994.832 1.196.503.2 1.085.088 1.471-.28.385-.371.502-.927.291-1.412ZM33.66 28.09a1.35 1.35 0 0 1-1.246-.8 1.254 1.254 0 0 1 .29-1.41c.387-.37.968-.482 1.472-.28.505.2.832.671.832 1.195 0 .343-.142.673-.394.916-.253.242-.597.38-.955.38Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.398 48.627a1.435 1.435 0 0 1-1.564.289 1.433 1.433 0 0 1-.83-1.392V15.046c0-2.109 1.563-3.884 3.712-3.884h23.847c2.15 0 3.713 1.775 3.713 3.884v32.478c.04.588-.29 1.138-.83 1.392a1.435 1.435 0 0 1-1.564-.288l-3.274-2.972-4.006 3.5a1.59 1.59 0 0 1-2.004 0l-3.958-3.5-3.958 3.5a1.598 1.598 0 0 1-.977.384 1.602 1.602 0 0 1-1.027-.384v-.002l-4.007-3.5-3.273 2.973Zm12.86-27.795c-.52 1.2-1.74 1.983-3.095 1.983a3.418 3.418 0 0 1-2.368-.942 3.146 3.146 0 0 1-.98-2.27c0-1.3.815-2.471 2.067-2.969 1.25-.497 2.691-.222 3.648.696.959.92 1.246 2.302.727 3.503ZM20.865 35.14h9.244c.553 0 1-.43 1-.96 0-.528-.447-.958-1-.958h-9.244c-.553 0-1.001.43-1.001.958 0 .53.448.96 1 .96Zm0 4.316h18.091c.553 0 1-.43 1-.96s-.447-.959-1-.959H20.866c-.553 0-1.001.43-1.001.959 0 .53.448.96 1 .96Zm12.793-15.873c-1.355 0-2.575.782-3.094 1.983-.518 1.2-.231 2.582.727 3.502.957.918 2.398 1.193 3.649.696 1.251-.498 2.066-1.669 2.066-2.968 0-.852-.352-1.669-.98-2.271a3.418 3.418 0 0 0-2.368-.942Zm-8.946 5.562a1.018 1.018 0 0 1-.698-.286.977.977 0 0 1 0-1.392L34.71 17.253a1.033 1.033 0 0 1 1.306.088c.35.338.39.872.092 1.254L25.413 28.858a.92.92 0 0 1-.7.286Z"
        fill="#fff"
      />
      <g filter="url(#h)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.374 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.374 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00BFFF" />
        <stop offset={1} stopColor="#007AFF" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00BFFF" />
        <stop offset={1} stopColor="#007AFF" />
      </linearGradient>
      <filter
        id="d"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_14050_1133"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_14050_1133"
          result="shape"
        />
      </filter>
      <filter
        id="h"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_14050_1133"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_14050_1133"
          result="shape"
        />
      </filter>
    </defs>
  </React.Fragment>
);
