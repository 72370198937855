/** @jsx jsx */

import { ReactNode, forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

import { Legend, LegendAppearance } from './Legend';

export type FieldsetProps = {
  /** The form fields that comprise the set. */
  children: ReactNode;
  /**
   * Provide a caption that describes the set of form fields.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/legend
   */
  legend: string;
  /** Adjust the appearance of the `legend` without affecting semantics. */
  legendAppearance?: LegendAppearance;
};

/**
 * Whenever a caption is needed to provide information about a related set of
 * controls consider the `Fieldset` component. It can be used to group any
 * thematically related controls in a form.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset
 */
export const Fieldset = forwardRef<HTMLFieldSetElement, FieldsetProps>(
  ({ children, legend, legendAppearance }, ref) => {
    const spacer = legendAppearance === 'hidden' ? null : <Spacer />;

    return (
      <fieldset ref={ref}>
        <Legend appearance={legendAppearance}>{legend}</Legend>
        {spacer}
        {children}
      </fieldset>
    );
  }
);

/**
 * Unfortunately we can't use `Stack` as the root. This little hack circumvents
 * a bug affecting Chromium-based browsers, where display properties (e.g.
 * 'flex') are ignored on `<fieldset/>` elements.
 * @see https://www.chromestatus.com/feature/5962796351094784
 */
const Spacer = () => {
  const { spacing } = useTheme();
  return <div css={{ height: spacing.small, width: 1 }} />;
};
