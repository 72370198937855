import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonSTPLogoName = 'ReckonSTPLogo';
export const ReckonSTPAppName = 'Single Touch Payroll';
export const ReckonSTPAppLongName = 'Reckon Single Touch Payroll';
export const ReckonSTPLogoDescription =
  'Reckon Single Touch Payroll Application Logo';

export const ReckonSTPLogo = createIllustration(
  {
    name: ReckonSTPLogoName,
    description: ReckonSTPLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#a)"
      />
    </mask>
    <mask
      id="d"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#c)"
      />
    </mask>
    <g mask="url(#d)">
      <path fill="url(#e)" d="M0 0h60v60H0z" />
      <path
        d="M41.193 28.78c-1.074 0-1.97.6-2.418 1.46v-.945c0-1.46-1.209-2.622-2.733-2.622-1.522 0-2.733 1.16-2.733 2.622v-2.622c0-1.46-1.209-2.622-2.732-2.622-1.075 0-1.97.6-2.418 1.46l.001-5.668c0-1.46-1.209-2.622-2.733-2.622-1.522 0-2.732 1.16-2.732 2.622V37.16l-1.747-2.32c-.895-1.16-2.598-1.46-3.807-.6-1.209.859-1.522 2.492-.626 3.652l7.48 10.013c.045.086.269.3.269.3l.224.216a5.102 5.102 0 0 0 3.224 1.118H38.91c2.777 0 5.016-2.149 5.016-4.813V31.402c0-1.418-1.209-2.622-2.733-2.622Zm-21.499-9.152c.85 0 1.522-.645 1.522-1.46 0-2.235 1.926-4.083 4.255-4.083 2.329 0 4.255 1.848 4.255 4.082 0 .816.672 1.46 1.522 1.46s1.523-.644 1.523-1.46c0-3.867-3.27-7.005-7.302-7.005-4.03 0-7.3 3.138-7.3 7.005.003.816.675 1.46 1.525 1.46Z"
        fill="#fff"
      />
      <g filter="url(#f)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.374 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.374 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00DAAA" />
        <stop offset={1} stopColor="#00BFFF" />
      </linearGradient>
      <filter
        id="f"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_14050_1134"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_14050_1134"
          result="shape"
        />
      </filter>
    </defs>
  </React.Fragment>
);
