/** @jsx jsx */
import type { ComponentType, HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { Flex } from '@balance-web/flex';
import { buildDataAttributes, jsx } from '@balance-web/core';
import type { IconProps } from '@balance-web/icon';
import { ChevronDownIcon, ChevronUpIcon, XCircleIcon } from '@balance-web/icon';
import { useTheme } from '@balance-web/theme';
import type { WithDataAttributeProp } from '@balance-web/core';
import { NotificationBadge } from '@balance-web/badge';
import { Inline } from '@balance-web/inline';

import { usePreventableClickHandler } from './utils';
import { useChipVariantStyles } from './shared-styles';
import { InteractionOverlay } from './InteractionOverlay';
import { buttonResetStyles } from './shared-styles';

export type MenuChipProps = WithDataAttributeProp<{
  label: string;
  hasValue: boolean;
  'aria-expanded': boolean;
  variant?: 'filled' | 'outline';
  hasChevron?: boolean;
  iconBefore?: ComponentType<IconProps>;
  size?: 'small' | 'medium';
  disabled?: boolean;
  onClear?: () => void;
  selectionCount?: number;
}> &
  HTMLAttributes<HTMLButtonElement>;

export const MenuChip = forwardRef<HTMLButtonElement, MenuChipProps>(
  (
    {
      variant = 'filled',
      size = 'medium',
      label,
      hasValue,
      hasChevron,
      disabled = false,
      iconBefore: IconBefore,
      onClear,
      selectionCount,
      data,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const dataAttributes = buildDataAttributes(data);
    const handleClick = usePreventableClickHandler(props, disabled);

    const ChevronIcon = props['aria-expanded']
      ? ChevronUpIcon
      : ChevronDownIcon;

    const variantStyles = useChipVariantStyles({
      variant,
      size,
      active: hasValue,
      disabled,
    });

    return (
      <div
        style={{
          position: 'relative',
          display: 'inline-flex',
        }}
      >
        <button
          ref={ref}
          type="button"
          aria-disabled={disabled}
          css={{
            /** Reset button styles start */
            ...buttonResetStyles,
            /** Reset button styles end */

            /** Immutable styles start */
            position: 'relative',
            fontWeight: theme.typography.fontWeight.semibold,
            borderRadius: theme.components.chip.borderRadius,

            '&:not([aria-disabled=true]).focus-visible': {
              boxShadow: `0 0 0 2px ${theme.palette.global.focusRing}`,
            },
            /** Immutable styles end */

            /** Variant styles start */
            ...variantStyles,
            /** Variant styles end */

            paddingRight:
              hasValue && onClear
                ? `calc(${theme.spacing.large} * 2.5)`
                : variantStyles.paddingInline,
          }}
          {...dataAttributes}
          {...props}
          onClick={handleClick}
        >
          <InteractionOverlay />
          <Flex
            gap="small"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            {IconBefore ? <IconBefore size="small" /> : null}
            <span>{label}</span>
            {selectionCount !== undefined && selectionCount > 0 ? (
              <NotificationBadge
                tone={disabled ? 'neutral' : 'accent'}
                value={selectionCount}
              />
            ) : null}
            {/* <div style={{ height: 16, width: 16 }} /> */}
          </Flex>
        </button>
        <Inline
          position="absolute"
          right={variantStyles.paddingInline}
          height="100%"
          alignY="center"
          css={{
            pointerEvents: onClear && hasValue ? undefined : 'none',
          }}
        >
          {onClear && hasValue ? (
            <button
              aria-disabled={disabled}
              disabled={disabled}
              css={{
                ...buttonResetStyles,
                '&:not([aria-disabled=true]).focus-visible, &:not([aria-disabled=true]):hover': {
                  boxShadow: `0 0 0 2px ${theme.palette.global.focusRing}`,
                },
              }}
              onClick={onClear}
            >
              <XCircleIcon
                size="small"
                color="active"
                css={{
                  stroke: disabled
                    ? theme.components.chip.disabled.iconColor
                    : hasValue
                    ? theme.components.chip.filled.active.iconColor
                    : theme.components.chip.filled.iconColor,
                }}
              />
            </button>
          ) : hasChevron ? (
            <ChevronIcon
              size="small"
              color="active"
              css={{
                stroke: disabled
                  ? theme.components.chip.disabled.iconColor
                  : hasValue
                  ? theme.components.chip.filled.active.iconColor
                  : theme.components.chip.filled.iconColor,
              }}
            />
          ) : null}
        </Inline>
      </div>
    );
  }
);
