import React from 'react';
import { Text } from '@balance-web/text';
import { SpotWave } from '@balance-web/illustration';
import { Button } from '@balance-web/button';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
import { Heading } from '@balance-web/heading';

type AppListEmptyStateProps = {
  onPrimaryActionClick: () => void;
};

export const AppListEmptyState = ({
  onPrimaryActionClick,
}: AppListEmptyStateProps) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      flexGrow={1}
      padding="xlarge"
      gap="xlarge"
    >
      <SpotWave />
      <Stack align="center" gap="small">
        <Heading level="5">No products were found</Heading>
        <Text align="center" color="muted">
          There are currently no Reckon products associated with your account
        </Text>
        <Stack paddingTop="large">
          <Button
            label="Add products to your account"
            variant="outline"
            onClick={onPrimaryActionClick}
          />
        </Stack>
      </Stack>
    </Flex>
  );
};
