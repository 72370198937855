/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesIdentify = (
  /** pass in any possible user identifier. If it's falsey then we dont ping appcues and wont get charged for an unessecary (MAU) Monthly Active User. */
  identifier?: string,
  properties?: Record<string, any>
) => {
  if (!identifier) return;
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;
  try {
    window.onAppCuesReady((appcues) => {
      appcues.identify(identifier, properties);
    });
  } catch (error) {
    console.error(`[APPCUES ERROR] identify ${identifier} error: `, error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesGroup = (
  groupId: string,
  properties: Record<string, any>
) => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;
  try {
    window.onAppCuesReady((appcues) => {
      appcues.group(groupId, properties);
    });
  } catch (error) {
    console.error(`[APPCUES ERROR] group ${groupId} error: `, error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesPage = () => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;
  try {
    window.onAppCuesReady((appcues) => {
      appcues.page();
    });
  } catch (error) {
    console.error('[APPCUES ERROR] page error: ', error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesAnonymous = (
  /** Really sit down and evaluate your life choices when turning this on. */
  yesReallyGetChargedForEverySingleAnonymousUserAndSoYouBetterHaveADocumentedReasonForTurningThisOnLoL = false
) => {
  if (
    !yesReallyGetChargedForEverySingleAnonymousUserAndSoYouBetterHaveADocumentedReasonForTurningThisOnLoL
  )
    return;
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;
  try {
    window.onAppCuesReady((appcues) => {
      appcues.anonymous();
    });
  } catch (error) {
    console.error('[APPCUES ERROR] anonymous error: ', error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesTrack = (
  eventName: AppcuesEventName,
  properties?: Record<string, any>
) => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;
  try {
    window.onAppCuesReady((appcues) => {
      appcues.track(eventName, properties);
    });
  } catch (error) {
    console.error(`[APPCUES ERROR] track ${eventName} error: `, error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesOnAll = (
  callbackFn: (
    name: AppcuesEventName,
    event: AppcuesFlowEvent | AppcuesStepEvent | AppcuesStepInteractedEvent
  ) => void
) => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;

  try {
    window.onAppCuesReady((appcues) => {
      appcues.on('all', callbackFn);
    });
  } catch (error) {
    console.error('[APPCUES ERROR] onAllEvent error: ', error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesOn = (
  event: Exclude<AppcuesEventName, 'all'>,
  callbackFn: (
    event: AppcuesFlowEvent | AppcuesStepEvent | AppcuesStepInteractedEvent
  ) => void
) => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;

  try {
    window.onAppCuesReady((appcues) => {
      appcues.on(event, callbackFn);
    });
  } catch (error) {
    console.error(`[APPCUES ERROR] on event ${event} error: `, error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesOnce = (
  event: Exclude<AppcuesEventName, 'all'>,
  callbackFn: (
    event: AppcuesFlowEvent | AppcuesStepEvent | AppcuesStepInteractedEvent
  ) => void
) => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;

  try {
    window.onAppCuesReady((appcues) => {
      appcues.on(event, callbackFn);
    });
  } catch (error) {
    console.error(`[APPCUES ERROR] eventOnce ${event} error: `, error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesOnceAll = (
  callbackFn: (
    name: AppcuesEventName,
    event: AppcuesFlowEvent | AppcuesStepEvent | AppcuesStepInteractedEvent
  ) => void
) => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;

  try {
    window.onAppCuesReady((appcues) => {
      appcues.on('all', callbackFn);
    });
  } catch (error) {
    console.error('[APPCUES ERROR] onceAll error: ', error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesOff = (
  eventName: AppcuesEventName,
  handler?: CallableFunction
) => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;

  try {
    window.onAppCuesReady((appcues) => {
      appcues.off(eventName, handler);
    });
  } catch (error) {
    console.error(`[APPCUES ERROR] eventOff ${eventName} error: `, error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesReset = () => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;

  try {
    window.onAppCuesReady((appcues) => {
      appcues.reset();
    });
  } catch (error) {
    console.error('[APPCUES ERROR] reset error: ', error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesDebug = (yesno?: boolean) => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;

  try {
    window.onAppCuesReady((appcues) => {
      appcues.debug(!!yesno);
    });
  } catch (error) {
    console.error('[APPCUES ERROR] debug error: ', error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesShow = (contentId: string) => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;

  try {
    window.onAppCuesReady((appcues) => {
      appcues.show(contentId);
    });
  } catch (error) {
    console.error(`[APPCUES ERROR] show ${contentId} error: `, error);
  }
};

/**
 * 👉️ https://docs.appcues.com/article/161-javascript-api
 */
export const appCuesClearShow = () => {
  if (typeof window === 'undefined') return;
  if (!('onAppCuesReady' in window)) return;

  try {
    window.onAppCuesReady((appcues) => {
      appcues.clearShow();
    });
  } catch (error) {
    console.error('[APPCUES ERROR] clearShow error: ', error);
  }
};
