/** @jsx jsx */
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { Box } from '@balance-web/box';
import { CSSObject, jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

export const Divider = () => {
  const { palette } = useTheme();

  const styles: CSSObject = {
    height: 1,
    backgroundColor: palette.border.standard,
  };

  return (
    <Box paddingY="small">
      <DropdownMenuPrimitive.Separator css={styles} />
    </Box>
  );
};
