import { format, isValid, parse, parseISO } from 'date-fns';

import type { ISODate } from './types';

export const isoDateToDate = (date: ISODate) => {
  return parseISO(date);
};

export const dateToString = (date: Date | ISODate) => {
  if (typeof date === 'string') {
    return format(isoDateToDate(date), 'dd/MM/yyyy');
  }

  return format(date as Date, 'dd/MM/yyyy');
};

/**
 * Accepts the following dates:
 * 01/01/2023
 * 01-01-2023
 * 01012023
 */
export const stringToDate = (value: string) => {
  let date = parse(value, 'dd/MM/yyyy', new Date());
  if (!isValid(date)) {
    date = parse(value, 'dd-MM-yyyy', new Date());
  }
  if (!isValid(date)) {
    date = parse(value, 'ddMMyyyy', new Date());
  }

  return date;
};
