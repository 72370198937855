import React, { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

import type { ArrayFieldVarint } from './types';

type ArrayFieldContextType = {
  /* Share the label with items for accessibility. */
  label: string;
  /** For ArrayField items to know when an item is added so all the existing items can collapse. */
  subscribeToItemAdded: (callback: (index: number) => void) => () => void;
  /** Event that lets items know that a new item has been added. */
  broadcastItemAdded: (index: number) => void;
  variant: ArrayFieldVarint;
};

const ArrayFieldContext = createContext<ArrayFieldContextType | null>(null);

export const ArrayFieldProvider = ({
  label,
  subscribeToItemAdded,
  broadcastItemAdded,
  variant,
  children,
}: ArrayFieldContextType & { children?: ReactNode }) => {
  return (
    <ArrayFieldContext.Provider
      value={{
        label,
        subscribeToItemAdded,
        broadcastItemAdded,
        variant,
      }}
    >
      {children}
    </ArrayFieldContext.Provider>
  );
};

export const useArrayField = () => {
  const context = useContext(ArrayFieldContext);
  if (!context) {
    throw Error('useArrayField can only be used inside a <ArrayField />');
  }
  return context;
};
