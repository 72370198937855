export function keysOf<T>(object: T) {
  return Object.keys(object).map((k) => k as keyof T);
}

export function validateStepsSchema<T>(object: T) {
  if (
    typeof object !== 'object' ||
    !!Object.keys(object).find((key) => isNaN(Number(key)) || Number(key) === 0)
  )
    throw Error('Step keys must be numbers greater than 0 ');

  return object;
}
