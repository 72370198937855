import React, { forwardRef } from 'react';
import type { ReactNode } from 'react';
import { TextLinkButton } from '@balance-web/text-link';
import { Text } from '@balance-web/text';

export type BreadCrumbButtonProps = {
  onClick: () => void;
  children: ReactNode;
};

/**
 * @description This component is a temporary fix for app related navigation issues. Do not use it unless necessary.
 */
export const BreadCrumbButton = forwardRef<
  HTMLButtonElement,
  BreadCrumbButtonProps
>(({ onClick, children }, ref) => {
  return (
    <TextLinkButton ref={ref} onClick={onClick} color="base">
      <Text size="xsmall" weight="medium">
        {children}
      </Text>
    </TextLinkButton>
  );
});
