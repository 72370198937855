/** @jsx jsx */
import type { InputHTMLAttributes, ReactNode } from 'react';
import React, { forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { VisuallyHidden } from '@balance-web/a11y';
import { useRawTheme, useTheme } from '@balance-web/theme';
import { hexToRgb } from '@balance-web/utils';
import { useFieldContext } from '@balance-web/field';

import { useButtonBorderRadius } from './styles';

type RadioButtonProps = {
  children: ReactNode;
  checked: boolean;
  index: number;
  optionCount: number;
} & InputHTMLAttributes<HTMLInputElement>;

export const RadioButton = forwardRef<HTMLLabelElement, RadioButtonProps>(
  ({ children, ...props }, ref) => {
    const { palette, spacing, typography } = useTheme();
    const rawTheme = useRawTheme();

    const indexToBorderRadius = useButtonBorderRadius({
      index: props.index,
      optionCount: props.optionCount,
    });

    const { invalid } = useFieldContext();

    return (
      <label
        ref={ref}
        data-checked={props.checked}
        css={{
          alignItems: 'center',
          justifyContent: 'center',
          appearance: 'none',
          backgroundClip: 'padding-box',
          borderRadius: indexToBorderRadius,
          boxSizing: 'border-box',
          color: palette.text.base,
          cursor: 'pointer',
          display: 'inline-flex',
          whiteSpace: 'nowrap',
          flex: 1,
          fontSize: typography.fontSize.medium,
          outline: 0,
          paddingBottom: 0,
          paddingLeft: spacing.medium,
          paddingRight: spacing.medium,
          paddingTop: 0,
          position: 'relative',
          userSelect: 'none',
          zIndex: 2,
          transitionDuration: '250ms',
          transitionProperty: 'color',
          transitionTimingFunction: 'cubic-bezier(0.2, 0, 0, 1)',
          '&:hover': {
            background: hexToRgb(
              rawTheme.colors[invalid ? 'danger' : 'accent'],
              0.2
            ),
          },
          '&[data-checked="true"]': {
            color: palette.background.base,
          },
        }}
      >
        <VisuallyHidden as="input" type="radio" {...props} />
        {children}
      </label>
    );
  }
);
RadioButton.displayName = 'RadioButtonGroupSegment';
