import React, { cloneElement, useLayoutEffect } from 'react';
import type { ReactNode } from 'react';
import { Stack } from '@balance-web/stack';
import { flattenElements, usePrevious } from '@balance-web/utils';

import { ArrayFieldItem } from './ArrayFieldItem';
import { useArrayField } from './context';

type ArrayFieldItemsProps = {
  children?: ReactNode;
};

export const ArrayFieldItems = ({ children }: ArrayFieldItemsProps) => {
  const childArray = flattenElements(children);

  const previousItemCount = usePrevious(
    childArray.length || 0,
    childArray.length || 0
  );
  const { broadcastItemAdded } = useArrayField();
  /**
   * Tell all existing items to collapse when a new item is added.
   * */
  useLayoutEffect(() => {
    const itemCount = childArray.length;
    if (itemCount > previousItemCount) {
      const child = childArray[itemCount - 1]; // last child = newly added item
      broadcastItemAdded(child.props['index']);
    }
  }, [broadcastItemAdded, childArray, previousItemCount]);

  if (!childArray.length) return null;

  return (
    <Stack as="ul" gap="small">
      {childArray.map((child) => {
        /**
         * This constraint allows us to black box auto-collapse
         *  on item added and scroll to new item behaviour.
         *
         * Removing this restriction will require consumer-side
         *  wiring for these behaviours to work.
         */
        if (child.type !== ArrayFieldItem) {
          throw Error(
            'ArrayField.Items can only have ArrayField.Item as children.'
          );
        }

        return cloneElement(child, {
          /** Sets the default expanded state of a new item tells it to scroll to it on mount. */
          _newItem:
            child.props['index'] === childArray.length &&
            /**
             * This conditional prevents opening the first item when a pre-populated list first loads in.
             *
             * previousItemCount === childArray.length only on first render.
             */
            previousItemCount !== childArray.length,
        });
      })}
    </Stack>
  );
};
