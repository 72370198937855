import React, { ReactElement } from 'react';

import { ErrorBoundary, Props } from './ErrorBoundary';

type withErrorBoundaryProps<T> = Omit<Props, 'children'> & {
  Component: (componentProps: T) => ReactElement | null;
};

export function withErrorBoundary<T>({
  appZone,
  Fallback,
  onError,
  preventRootOnError,
  Component,
}: withErrorBoundaryProps<T>) {
  return (componentProps: T) => (
    <ErrorBoundary
      appZone={appZone}
      Fallback={Fallback}
      onError={onError}
      preventRootOnError={preventRootOnError}
    >
      <Component {...componentProps} />
    </ErrorBoundary>
  );
}
