import { createContext } from 'react';

import { getBookMetaData } from './getBookMetaData';

export const BookSwitcherMetaContext = createContext<
  ReturnType<typeof getBookMetaData>
>(
  getBookMetaData({
    user: null,
    books: [],
  })
);
