/** @jsx jsx */

import { TextareaHTMLAttributes, forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { useFieldContext } from '@balance-web/field';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { InputSizeType, useInputStyles } from '@balance-web/text-input';

type NativeElementProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export type TextareaProps = WithDataAttributeProp<
  {
    size?: InputSizeType;
    onChange: NonNullable<NativeElementProps['onChange']>;
    value: NonNullable<NativeElementProps['value']>;
  } & Omit<NativeElementProps, 'onChange' | 'type' | 'size' | 'value'>
>;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ size = 'medium', data, ...textareaProps }, ref) => {
    const styles = useInputStyles({ size, shape: 'square' });
    const { invalid, ...a11yProps } = useFieldContext();

    const dataAttributes = buildDataAttributes(data);

    return (
      <textarea
        aria-invalid={invalid}
        ref={ref}
        css={styles}
        rows={4}
        {...dataAttributes}
        {...a11yProps}
        {...textareaProps}
      />
    );
  }
);
