/** @jsx jsx */

import {
  HTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { jsx, keyframes } from '@balance-web/core';
import { ArrowRightIcon } from '@balance-web/icon/icons/ArrowRightIcon';
import { useTheme } from '@balance-web/theme';
import { getContrastText } from '@balance-web/utils';

type ScrollMaskProps = {
  /** The element, or elements, that are expected to overflow. */
  children: ReactElement;
  /** When true, a disclosure indicator will be displayed to indicate scroll availability. */
  showScrollIndicator?: boolean;
};

type ScrollPosition = 'start' | 'between' | 'end' | 'none';

export const ScrollMask = ({
  children,
  showScrollIndicator: hasIndicator = true,
}: ScrollMaskProps) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>('none');
  const scrollRef = useRef<HTMLDivElement>(null);
  const resizeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    const resizeElement = resizeRef.current;

    if (scrollElement) {
      const handleScroll = () => {
        const { clientWidth, scrollWidth, scrollLeft } = scrollElement;

        // don't show masks if there's no scroll available
        if (clientWidth === scrollWidth) {
          setHasScrolled(false);
          setScrollPosition('none');
          return;
        }

        // we don't really care about the scroll value, just these three states
        if (scrollLeft === 0) {
          setScrollPosition('start');
        } else if (scrollLeft === scrollWidth - clientWidth) {
          setScrollPosition('end');
        } else {
          setHasScrolled(true);
          setScrollPosition('between');
        }
      };

      // watch for changes in width of children or descendents
      // @ts-ignore not yet typed
      const resizeObserver = new ResizeObserver(() => {
        handleScroll();
      });
      if (resizeElement) {
        resizeObserver.observe(resizeElement);
      }

      // call once to intialise position
      handleScroll();

      scrollElement.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
        resizeObserver.disconnect();
      };
    }
  }, []);

  // determine element visibility
  const showScrollIndicator =
    hasIndicator && !hasScrolled && scrollPosition !== 'none';
  const showLeftMask = ['end', 'between'].includes(scrollPosition);
  const showRightMask = ['start', 'between'].includes(scrollPosition);

  return (
    <div
      css={{
        position: 'relative', // bind the masks to this container
        overflow: 'hidden', // prevent contents from "blowing out" grid and flex parents
      }}
    >
      <Mask align="left" visible={showLeftMask} />
      <div
        ref={scrollRef}
        css={{
          '@media screen': {
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
          },
        }}
      >
        <div ref={resizeRef} css={{ flexGrow: 1, flexShrink: 0 }}>
          {children}
        </div>
      </div>
      <Indicator visible={showScrollIndicator} />
      <Mask align="right" visible={showRightMask} />
    </div>
  );
};

// Styled Components
// ------------------------------

type MaskProps = { align: 'left' | 'right'; visible: boolean } & HTMLAttributes<
  HTMLDivElement
>;
const Mask = ({ align, visible, ...props }: MaskProps) => {
  const width = 24;

  return (
    <div
      css={{
        [align]: 0,
        bottom: 0,
        opacity: visible ? 1 : 0,
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        transition: 'opacity 200ms',
        width: width,
        zIndex: 2, // just enough to be on top of another stacking context

        // feathery shadow using gradient
        '&::before': {
          [align]: 0,
          background: `linear-gradient(to ${align}, transparent, rgba(0, 0, 0, 0.04) 66%, rgba(0, 0, 0, 0.1))`,
          bottom: 0,
          content: '" "',
          position: 'absolute',
          top: 0,
          width: width,
        },

        // hard line to confirm the edge
        '&::after': {
          [align]: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          bottom: 0,
          content: '" "',
          position: 'absolute',
          top: 0,
          width: 1,
        },
      }}
      {...props}
    />
  );
};

type IndicatorProps = { visible: boolean } & HTMLAttributes<HTMLDivElement>;
const Indicator = ({ visible, ...props }: IndicatorProps) => {
  const theme = useTheme();
  const height = theme.sizing.small;

  return (
    <div
      css={{
        alignItems: 'center',
        backgroundColor: theme.palette.global.accent,
        borderBottomLeftRadius: height,
        borderTopLeftRadius: height,
        color: getContrastText(theme.palette.global.accent),
        display: 'flex',
        height: height,
        justifyContent: 'center',
        lineHeight: 1,
        opacity: visible ? 1 : 0,
        overflow: 'hidden',
        paddingLeft: theme.spacing.medium,
        paddingRight: theme.spacing.small,
        pointerEvents: 'none',
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        transition: 'opacity 200ms',
        zIndex: 2, // just enough to be on top of another stacking context

        // hide when printing
        '@media print': {
          display: 'none',
        },
      }}
      {...props}
    >
      <div
        css={{
          fontSize: theme.typography.fontSize.xsmall,
          fontWeight: theme.typography.fontWeight.medium,
          marginRight: theme.spacing.xsmall,
        }}
      >
        Scroll
      </div>
      <div
        css={{
          animation: `${bounce} 4s infinite`,
          animationDelay: '3s',
        }}
      >
        <ArrowRightIcon size="small" />
      </div>
    </div>
  );
};

// Keyframes
// ------------------------------

// this is a 1s animation with a 3s pause between loops. each value has been
// divided by 4 to polyfill the missing API for pausing between loops
const bounce = keyframes({
  '0%, 5%, 13.25%, 25%, 100%': {
    animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    transform: 'translate3d(0, 0, 0)',
  },

  '10%, 10.75%': {
    animationTimingFunction: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
    transform: 'translate3d(-16px, 0, 0) scaleX(1.1)',
  },

  '17.5%': {
    animationTimingFunction: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
    transform: 'translate3d(-8px, 0, 0) scaleX(1.05)',
  },

  '20%': {
    transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    transform: 'translate3d(0, 0, 0) scaleX(0.95)',
  },

  '22.5%': {
    transform: 'translate3d(-2px, 0, 0) scaleX(1.02)',
  },
});
