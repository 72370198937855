/** Filters out non 'aria-*' properties and returns a new object. */
export function siftAriaAttributes<P>(props: P) {
  const ariaAttributes: Partial<P> = {};

  for (const key in props) {
    if (key.startsWith('aria-')) {
      ariaAttributes[key] = props[key];
    }
  }

  return ariaAttributes;
}

/** Pick "safe" props from all props. */
export function pickSafeProps<T, K extends keyof T>(
  allProps: T,
  ...safeKeys: K[]
): Pick<T, K> {
  const safeProps: any = {};

  for (const key of safeKeys) {
    safeProps[key] = allProps[key];
  }

  return safeProps;
}
