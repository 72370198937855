import type { ChangeEvent, FocusEvent } from 'react';
import { useState } from 'react';

// this should probably live in @balance-web/utils but I'm not totally sure about all the behaviour
// so I'm gonna leave it here for now.

type Config<ParsedValue> = {
  validate: (value: string) => boolean;
  parse: (value: string) => ParsedValue;
  format: (value: ParsedValue) => string;
  determineType: (value: string | ParsedValue) => 'raw' | 'parsed';
};

export function useFormattedInput<ParsedValue>(
  config: Config<ParsedValue>,
  {
    value,
    onChange,
    onBlur,
    onFocus,
  }: {
    value: string | ParsedValue;
    onChange: (value: ParsedValue | string) => void;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  }
) {
  let valueType = config.determineType(value);
  if (valueType === 'raw' && config.validate(value as string)) {
    throw new Error(
      `Valid values must be passed in as a parsed value, not a raw value. The value you passed was \`${JSON.stringify(
        value
      )}\`, you should pass \`${JSON.stringify(
        config.parse(value as string)
      )}\` instead`
    );
  }
  let [internalValueState, setInternalValueState] = useState(() => {
    return valueType === 'raw'
      ? (value as string)
      : config.format(value as ParsedValue);
  });
  const [isFocused, setIsFocused] = useState(false);
  if (valueType === 'raw' && value !== internalValueState) {
    setInternalValueState(value as string);
  }
  if (
    valueType === 'parsed' &&
    !isFocused &&
    config.format(value as ParsedValue) !== internalValueState
  ) {
    setInternalValueState(config.format(value as ParsedValue));
  }
  return {
    value: internalValueState,
    onChange(event: ChangeEvent<HTMLInputElement>) {
      const value = event.target.value;
      if (config.validate(value)) {
        onChange(config.parse(value));
        setInternalValueState(value);
      } else {
        onChange(value);
        setInternalValueState(value);
      }
    },
    onFocus(event: FocusEvent<HTMLInputElement>) {
      onFocus?.(event);
      setIsFocused(true);
    },
    onBlur(event: FocusEvent<HTMLInputElement>) {
      onBlur?.(event);
      setIsFocused(false);
      // this isn't strictly necessary since we already do this in render
      // this just saves another rerender after setIsFocused(false)
      if (valueType === 'parsed') {
        setInternalValueState(config.format(value as ParsedValue));
      }
    },
  };
}
