/** @jsx jsx */
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import type { DayModifiers } from 'react-day-picker';
import { jsx } from '@balance-web/core';
import formatISO from 'date-fns/formatISO';
import { PopoverDialog, usePopover } from '@balance-web/popover';
import type { InputSizeType } from '@balance-web/text-input';
import { Adornment } from '@balance-web/text-input';
import { useInputStyles } from '@balance-web/text-input';
import { TextInput } from '@balance-web/text-input';
import { isValid, parse } from 'date-fns';
import { Box } from '@balance-web/box';
import { CalendarIcon } from '@balance-web/icon';
import { useTheme } from '@balance-web/theme';
import { useFieldContext } from '@balance-web/field';
import { useForkedRef } from '@balance-web/utils';

import { ClearButton } from './styled';
import type { CalendarProps } from './Calendar';
import { Calendar } from './Calendar';
import type { ISODate } from './types';
import { dateToString } from './utils';

export type DatePickerProps = {
  /** When true, the trigger will be disabled and the clear button is removed. */
  disabled?: boolean;
  /** Indicate that the value does not conform to the format expected by the application. */
  invalid?: boolean;
  /** Called when the value changes. */
  onChange: (value: ISODate | undefined) => void;
  /** Called when the value is cleared. */
  onClear: () => void;
  /** The value of the calendar, displayed in the trigger. */
  value: ISODate | undefined;
  size?: InputSizeType;
} & Pick<
  CalendarProps,
  'disabledDays' | 'fromMonth' | 'initialMonth' | 'modifiers' | 'toMonth'
>;

/**
 * We want all the changes in the textInput and calendar to be temporary.
 * The changes are committed to the consumer onChange only when user clicks a calendar date, hits Enter or Tab in the text input.
 * All other interactions (escape, blur etc) are considered a bailout and will cause the text input and calendar to revert to the consumer value.
 */
export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      onChange,
      onClear,
      value,
      // calendar props
      disabledDays,
      fromMonth,
      initialMonth,
      modifiers,
      toMonth,
      size = 'medium',
      ...buttonProps
    },
    ref
  ) => {
    const theme = useTheme();
    const { invalid } = useFieldContext();
    const textInputRef = useRef<HTMLInputElement>(null);

    const textInputStyles = useInputStyles({ size, shape: 'square' });

    // NOTE: calendarDate doesn't always have to be the same as the incoming value.
    const [calendarDate, setCalendarDate] = useState<Date>(
      value ? new Date(value) : new Date()
    );
    const [textValue, setTextValue] = useState<string>(
      value ? dateToString((value as unknown) as Date) : ''
    );

    const adornmentPaddingRight =
      size === 'medium' ? theme.spacing.small : theme.spacing.xsmall;

    const { isOpen, setOpen, dialog, trigger } = usePopover({
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });

    const composedTextInputRef = useForkedRef(textInputRef, trigger.ref, ref);

    const calendarProps = {
      disabledDays,
      fromMonth,
      initialMonth,
      modifiers,
      toMonth,
    };

    const onDateChange = useCallback(
      (date: Date) => {
        onChange(formatISO(date, { representation: 'date' }) as ISODate);
      },
      [onChange]
    );

    const handleDayClick = useCallback(
      (day: Date, { disabled }: DayModifiers) => {
        if (disabled) {
          return;
        }

        onDateChange(day);

        // wait a moment so the user has time to see the day become selected
        setTimeout(() => {
          setOpen(false);
        }, 300);
      },
      [onDateChange, setOpen]
    );

    const selectedDay = new Date(value as string);

    // We always want to bind isOpen and value in this effect so that we always sync to the correct value on open/close. Separating isOpen out would mean a lot of rework.
    useEffect(
      function syncToValue() {
        if (!value) {
          setTextValue('');
          setCalendarDate(new Date());
        } else {
          setTextValue(dateToString((value as unknown) as Date));
          const selectedDay = new Date(value as string);
          setCalendarDate(selectedDay);
        }

        if (isOpen) {
          textInputRef.current?.select();
        }
      },
      [isOpen, value]
    );

    const internalOnClear = () => {
      setTextValue('');
      onClear();
      setOpen(true);
    };

    const commitTextValueIfValid = () => {
      let textValueAsDate = parse(textValue, 'dd/MM/yyyy', new Date());
      if (!isValid(textValueAsDate)) {
        textValueAsDate = parse(textValue, 'dd-MM-yyyy', new Date());
      }

      if (isValid(textValueAsDate) && textValueAsDate.getFullYear() >= 1000) {
        onDateChange(textValueAsDate);
      }
    };

    const onTextValueChange = (textValue: string) => {
      setTextValue(textValue);

      if (!textValue.length) {
        return;
      }

      const isHyphenated = textValue.indexOf('-') > -1;
      const isSlashed = textValue.indexOf('/') > -1;

      if (isHyphenated && isSlashed) {
        return;
      }

      const internalDateArray = dateToString(calendarDate).split('/');

      const [_day, _month, _year] = isSlashed
        ? textValue.split('/')
        : textValue.split('-');

      const day = _day?.length ? _day.padStart(2, '0') : undefined;
      const month = _month?.length ? _month.padStart(2, '0') : undefined;
      const year = _year?.length < 4 ? undefined : _year;

      if (day) {
        internalDateArray[0] = day;
      }
      if (month) {
        internalDateArray[1] = month;
      }
      if (year) {
        internalDateArray[2] = year;
      }

      const newInternalDate = parse(
        internalDateArray.join('/'),
        'dd/MM/yyyy',
        new Date()
      );

      if (isValid(newInternalDate)) {
        setCalendarDate(newInternalDate);
      }
    };

    return (
      <Box position="relative">
        <TextInput
          ref={composedTextInputRef}
          autoComplete="off"
          {...trigger.props}
          size={size}
          value={textValue}
          onChange={(e) => {
            onTextValueChange(e.target.value);
          }}
          placeholder="dd/mm/yyyy"
          onFocus={() => {
            setOpen(true);
          }}
          // Don't do anything onBlur because it's difficult to understand the difference between blur to enter and blur to escape.
          onBlur={undefined}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setOpen(false);
              textInputRef.current?.blur(); // Don't tab to next input
              return;
            }

            if (e.key === 'Enter' || e.key === 'Tab') {
              commitTextValueIfValid();
              setOpen(false);
              // Don't tab to next input on Enter
              if (e.key === 'Enter') {
                textInputRef.current?.blur();
              }
              return;
            }

            // Shortcut to select todays date
            if (e.key === 't') {
              e.preventDefault();
              onDateChange(new Date());
            }
          }}
          disabled={buttonProps.disabled}
          hideInvalidAdornment
          css={{ ...textInputStyles }}
        />

        {(value?.length || textValue.length) && !buttonProps.disabled ? (
          <ClearButton
            size={size}
            onClick={internalOnClear}
            css={{ position: 'absolute', right: 40, zIndex: 3 }}
          />
        ) : null}

        <Adornment
          align="right"
          css={{
            paddingRight: adornmentPaddingRight,
            pointerEvents: 'none',
            position: 'absolute',
            zIndex: 3,
          }}
        >
          <CalendarIcon
            size={size}
            color={
              buttonProps.disabled
                ? 'inputDisabled'
                : invalid || buttonProps.invalid
                ? 'critical'
                : 'dim'
            }
          />
        </Adornment>

        <PopoverDialog isVisible={isOpen} ref={dialog.ref} {...dialog.props}>
          <Calendar
            onDayClick={handleDayClick}
            selectedDays={selectedDay}
            month={calendarDate}
            {...calendarProps}
          />
        </PopoverDialog>
      </Box>
    );
  }
);
