import * as React from 'react';

import { createIllustration } from './Illustration';

function describeWithOptions(name: string, description: string) {
  return {
    name,
    description,
    height: 348,
    width: 400,
  };
}

// ------------------------------
// Waving
// ------------------------------
export const SceneWaving = createIllustration(
  describeWithOptions(
    'SceneWaving',
    'Illustration of a man waving, while holding a bag full of coins.'
  ),
  <React.Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M333.778 253.861c0 45.398-29.615 65.771-66.002 65.771-36.383 0-65.998-20.373-65.998-65.771 0-84.567 36.019-163.54 65.998-163.54 29.983 0 66.002 78.973 66.002 163.54z"
      fill="#E8F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M273 173.778v169.971h-11V173.778h11zM302.449 256.812l-20.135 20.253-4.95-4.978 20.136-20.253 4.949 4.978zM302.449 183.511l-20.135 20.253-4.95-4.978 20.136-20.253 4.949 4.978zM239.828 251.642l20.338 20.456-4.95 4.978-20.338-20.456 4.95-4.978zM238.749 215.464l20.337 20.456-4.949 4.978-20.338-20.456 4.95-4.978z"
      fill="#C3E4FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.14 323.42c.252.337 7.855 10.54 8.808 19.937h13.365c-6.309-12.612-21.825-19.781-22.172-19.937zM124.749 343.357h-14.16c-.083-10.23-5.303-23.549-5.303-23.549s15.536 12.76 19.463 23.549zM129.003 343.357c3.422-7.285 3.628-13.153 3.628-13.153s6.716 7.341 9.577 13.153h-13.205z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.298 165.999c-2.505-7.537-16.235-6.261-16.236-27.278-.004-31.645 43.362-28.033 45.919-51.876 2.557-23.843-25.309-23.273-26.172-43.384C80.947 23.351 104.076.79 129.426.79c25.349 0 13.179 23.049 33.638 37.367 20.458 14.319 39.857-13.99 51.422 8.525 15.929 31.015-24.804 68.263-20.785 92.039 4.019 23.777 27.897 18.155 28.628 44.279.731 26.125-11.111 21.615-7.843 44.016 2.06 14.121 11.968 14.012 13.119 41.857.975 23.575-85.247 57.249-141.903 9.274-56.656-47.973 2.156-83.381-7.404-112.148z"
      fill="#C3E4FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146 105.387v237.357h-10V105.387h10zM184.698 245.131l-21.126 21.248-5.94-5.974 21.126-21.248 5.94 5.974zM180.068 163.012l-21.125 21.248-5.94-5.974 21.125-21.248 5.94 5.974zM103.914 115.979l21.125 21.248-5.94 5.973-21.125-21.248 5.94-5.973z"
      fill="#E8F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M167.474 292.92l2.569 1.833c10.694 7.633 19.43 19.318 25.431 28.89a166.98 166.98 0 017.007 12.325 133.37 133.37 0 011.853 3.747 87.378 87.378 0 01.607 1.31l.034.074.009.021.004.009c0 .001.001.001-2.647 1.186l2.648-1.185 1.28 2.895-5.756 2.576-1.28-2.894-.001-.002-.005-.012-.026-.057-.109-.24a73.522 73.522 0 00-.445-.955c-.396-.836-.989-2.056-1.765-3.569a160.46 160.46 0 00-6.737-11.849c-5.85-9.332-14.059-20.187-23.748-27.102l-2.569-1.834 3.646-5.167z"
      fill="#AAD5FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.841 280.193l-2.217 2.25c-3.681 3.735-6.479 9.267-8.548 15.752-2.055 6.447-3.313 13.588-4.067 20.3-.752 6.7-.994 12.893-1.047 17.416a161.73 161.73 0 00.022 5.36c.013.632.028 1.122.04 1.451l.014.369.004.09.001.02v.004l.151 3.164-6.292.305-.152-3.164 2.891-.14-2.891.138v-.012l-.002-.03-.004-.109-.016-.41c-.013-.357-.029-.876-.043-1.54-.028-1.327-.05-3.233-.023-5.57.055-4.67.304-11.085 1.087-18.053.781-6.956 2.102-14.542 4.328-21.524 2.215-6.943 5.403-13.54 10.072-18.278l2.218-2.25 4.474 4.461z"
      fill="#AAD5FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M210.767 280.212c0 5.727 4.615 10.369 10.309 10.369 5.693 0 10.308-4.642 10.308-10.369 0-5.726-4.615-10.368-10.308-10.368-5.694 0-10.309 4.642-10.309 10.368zM161.785 301.746c0 5.726 4.616 10.368 10.309 10.368s10.309-4.642 10.309-10.368c0-5.727-4.616-10.369-10.309-10.369s-10.309 4.642-10.309 10.369z"
      fill="#AAD5FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.435 291.691c-.327.435-21.359 28.215-21.897 49.983h-16.985c2.274-7.087 12.136-33.015 38.882-49.983zM196.406 341.693h-13.533c-2.676-10.231-10.779-16.61-10.779-16.61s16.091 3.168 24.312 16.61z"
      fill="#C3E4FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.635 246.61c0 37.172-15.319 70.934-50 70.934-34.682 0-50-33.762-50-70.934 0-43.607 26.208-86.968 50-86.968s50 43.361 50 86.968z"
      fill="#E8F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82 230.1v112.644H71V230.1h11zM109.603 236.072l-18.18 20.531-5.228-4.682 18.181-20.532 5.227 4.683zM48.831 262.774l18.18 20.531-5.227 4.683-18.18-20.532 5.227-4.682zM48.831 232.017l18.18 20.531-5.227 4.683-18.18-20.532 5.227-4.682z"
      fill="#D6EEFF"
    />
    <path
      d="M400 350.79H0c.485-5.118 3.404-9.052 6.932-9.052h386.136c3.528 0 6.447 3.934 6.932 9.052z"
      fill="#D6EEFF"
    />
    <path
      d="M215.645 106.7c7.566-4.605 17.369-2.154 21.934 5.458 4.565 7.613 2.15 17.484-5.416 22.089-7.565 4.605-17.362 2.166-21.927-5.447-4.564-7.612-2.157-17.495 5.409-22.1zm2.227 5.446l.738 1.23c-.908.834-1.368 1.929-1.504 2.954-.167 1.265.059 2.422.556 3.251.726 1.211 1.929 1.807 3.054 2.04 1.963.407 3.904-.41 5.76-.292 1.011.072 1.795.373 2.155.972.19.318.427 1.23.313 2.092-.114.862-.483 1.664-1.37 2.203-.571.348-1.341.501-2.061.456-.721-.045-1.371-.311-1.702-.595-.357-.304-.955-.254-1.259.106-.302.359-.253.963.103 1.266.736.633 1.722.935 2.755 1 .75.047 1.54-.037 2.278-.311l.731 1.219 1.52-.925-.738-1.231c.907-.833 1.368-1.929 1.504-2.954.166-1.265-.059-2.422-.556-3.25-.787-1.313-2.256-1.744-3.549-1.837-1.878-.199-3.791.676-5.539.336-.798-.164-1.462-.518-1.882-1.219-.191-.318-.427-1.229-.314-2.092.114-.862.483-1.663 1.37-2.203.571-.347 1.334-.512 2.054-.468.721.045 1.371.311 1.702.595.357.305.955.254 1.259-.105.302-.359.254-.964-.103-1.267-.736-.632-1.722-.935-2.755-.999a5.496 5.496 0 00-2.271.322l-.73-1.218-1.519.924z"
      fill="#0078C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.777 129.053c-8.562 2.129-17.218-3.137-19.327-11.763-2.117-8.63 3.113-17.347 11.675-19.476 8.561-2.13 17.218 3.14 19.331 11.766 2.113 8.626-3.117 17.344-11.679 19.473z"
      fill="#fff"
    />
    <path
      d="M195.952 97.341c8.84 0 15.973 7.205 15.973 16.099s-7.134 16.085-15.973 16.085-15.973-7.191-15.973-16.085c0-8.894 7.134-16.099 15.973-16.099zm-.889 5.822v1.437c-1.205.241-2.161.939-2.803 1.745-.792.996-1.193 2.104-1.193 3.072 0 1.415.724 2.552 1.568 3.337 1.47 1.372 3.551 1.684 5.079 2.752.828.588 1.346 1.254 1.346 1.954 0 .371-.266 1.275-.806 1.953-.54.679-1.267 1.173-2.304 1.173-.666 0-1.404-.271-1.998-.684-.593-.413-1.013-.98-1.151-1.396-.149-.446-.687-.715-1.132-.565-.442.15-.711.693-.562 1.138.306.924.995 1.697 1.845 2.29.618.431 1.337.771 2.11.921v1.424h1.776v-1.438c1.204-.24 2.161-.938 2.803-1.744.792-.996 1.193-2.104 1.193-3.072 0-1.534-1.037-2.668-2.096-3.421-1.505-1.148-3.591-1.396-4.913-2.598-.598-.557-.986-1.206-.986-2.024 0-.371.266-1.275.806-1.954.54-.678 1.267-1.172 2.304-1.172.666 0 1.404.256 1.998.67.593.413 1.013.98 1.151 1.395.149.447.687.716 1.131.566.443-.15.712-.693.563-1.138-.306-.924-.995-1.697-1.845-2.29a5.52 5.52 0 00-2.11-.908v-1.423h-1.774z"
      fill="#0078C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.362 105.621a.892.892 0 00-.589-.139c-.965.116-3.877.534-5.442 1.526-5.613 3.56-17.353 12.799-22.655 14.406-5.426 1.645-17.452 2.406-21.632 2.123-1.099-.074-1.331 1.025-1.197 1.736.695 3.707 4.055 7.855 4.055 7.855l.398 5.718-15.512 50.451c-1.447 5.031-.881 10.559 1.539 15.044l6.614 12.253c1.877 3.478 4.734 6.073 8.072 7.334l.072.024 42.812 12.969c.164.05.339.051.504.003l35.735-10.41c.43-.125.717-.556.684-1.028l-7.206-102.431a.998.998 0 00-.445-.772l-25.807-16.662z"
      fill="#0091EA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M266.075 324.442l-.09 7.829s17.35 2.764 23.232 9.467H254.65l1.448-22.38 9.977 5.084zM222.754 323.783l-.09 8.793s7.308.965 10.283 9.162h-23.659l2.366-9.648.926-8.796 10.174.489z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M332.798 111.2c-1.028 1.225-4.22 3.276-6.729 5.806-1.947 1.964-2.902 3.826-5.621 6.031-1.149.936-2.174 1.424-3.151 1.942l-16.075 28.162c-2.901 4.597-7.149 5.918-10.374 4.41l-28.418-15.926s.243-23.741 4.28-21.067c7.856 5.212 25.734 20.623 25.734 20.623l17.544-20.745-.16-2.79s.003-1.942.252-4.038 1.685-4.575.166-8.275c-1.519-3.697 2.113-1.144 2.918.614.67 1.466.316 4.549 1.139 5.677.696-.382 1.159-.88 1.159-.88l7.83-10.23a.85.85 0 011.092-.244l.421.231a.86.86 0 01.3 1.235c-1.529 2.26-6.266 8.889-6.138 8.956l.207.254 9.197-9.766a.849.849 0 011.123-.136l.552.386c.403.28.488.839.189 1.225-1.83 2.379-8.692 9.931-8.562 10.005l.275.266 8.561-7.615a.85.85 0 011.171-.094l.419.344a.86.86 0 01.121 1.209l-8.086 7.658.234.369 7.019-4.966a.85.85 0 011.133-.004l.195.177a.863.863 0 01.083 1.196z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M276.33 76.843s9.121 11.972-3.263 15.15l3.263-15.15z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M214.795 133.197c-16.369 1.518-31.04-.069-31.04-.069l.398 5.718s21.606 1.867 30.642-.639c9.036-2.506 0-5.01 0-5.01z"
      fill="#0078C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M256.097 321.409l-.804 10.375 10.691.487.09-7.829-9.977-3.033zM212.776 320.75l-1.123 11.34 11.01.486.09-8.793-9.977-3.033z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M273.019 174.083l-1.481 154.282-18.712.275-3.179-155.531 23.372.974z"
      fill="#364150"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M258.115 173.497l-31.089 155.464h-17.951l21.776-156.907 27.264 1.443z"
      fill="#364150"
    />
    <path
      d="M229.055 142.329c-6.493 1.88-17.422 4.983-22.222 5.984-2.585.538-7.343.017-9.54-4.54-3.639-7.549 1.869-12.047 1.869-12.047l28.057-10.468c3.391-1.786 7.547-3.37 12.182-3.882 4.765-.526 19.172-.007 19.172-.007 2.02.37 5.949 1.416 5.952 1.417.061.037.222.099.464.194.943.368 3.112 1.214 5.329 2.812 3.055 2.027 6.903 4.753 10.626 7.39 3.457 2.449 6.807 4.822 9.315 6.488a5.635 5.635 0 012.48 5.247l-5.365 15.23c-1.472 1.999-3.645 1.34-5.23.238l-6.853-5.913 3.811 19.586c-.097 2.368-2.087 4.205-4.438 4.097l-45.826-1.914c-2.179-.101-3.804-2.058-3.517-4.235l3.734-25.677z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.931 92.42c-.906-2.69-9.146-6.06-11.124-.028-2.551 7.78 12.151 11.869 12.151 11.869l-.643 12.902-1.633.001s2.934 6.384 11.808 6.91c8.873.527 5.807-6.022 5.807-6.022l-1.501-.411-.765-10.2s13.054 1.022 15.779-.404c0 0 2.07-12.827.101-21.921l-10.718-9.748-17.299 1.283-1.963 15.77z"
      fill="#FCBDA4"
    />
    <path
      d="M239.017 75.962c-8.938 9.755-.853 13.068-.853 13.068 5.225.181 6.767 3.39 6.767 3.39 8.84-1.526 5.522-9.044 5.522-9.044 38.585 13.284 38.509-13.975 35.729-15.148-2.779-1.173-7.666 7.034-7.666 7.034s-1.497-15.091-11.195-15.133c-6.807-.03-9.266 10.358-9.266 10.358s-6.152-3.732-13.919 1.114l-.808-4.53-2.948.535 1.013 5.677-.537.26-3.93-4.033-2.137 2.114 4.228 4.338z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M268.252 102.075c-.203 1.305-2.122 2.086-4.287 1.744-2.164-.341-3.687-2.165-3.484-3.47.203-1.305 2.563-.172 4.727.17 2.165.341 3.247.251 3.044 1.556z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.031 107.441l-8.587-1.855 8.546 5.815.041-3.96z"
      fill="#987263"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M275.291 150.471l-2.127-8.512 5.744 11.633-3.617-3.121z"
      fill="#FFC100"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M266.278 91.558l2.967 7.307-3.556.046.589-7.353z"
      fill="#F49E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M262.731 92.268a1.809 1.809 0 01-1.449 2.103 1.799 1.799 0 01-2.089-1.459 1.809 1.809 0 011.449-2.103 1.799 1.799 0 012.089 1.459zM272.065 92.268a1.809 1.809 0 01-1.449 2.103 1.799 1.799 0 01-2.089-1.459 1.809 1.809 0 011.449-2.103 1.799 1.799 0 012.089 1.459z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M250.504 211.013l4.039-18.113-3.039 54.913-1-36.8z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M230.674 120.66l-4.452 4.568-7.757-5.889 6.488-5.798s2.12-8.73 3.581-9.746c1.757-1.221 4.156-1.866 5.605-1.885 1.448-.019 2.919 1.849 2.719 2.688 0 0 2.798 1.197 2.84 3.843 0 0 1.417 1.494.759 3.444 0 0 1.056 1.873-.488 3.248-1.545 1.374-5.885 3.716-9.295 5.527z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M207.2 145.98c-3.388 1.385-8.029 1.385-10.224-2.529-4.099-7.307 2.186-11.724 2.186-11.724l25.792-18.186 5.543 7.3s-16.171 22.227-23.297 25.139z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M235.782 117.671l-3.967 24.225 13.341 1.048-5.774-27.438s-.486-.467-1.852.211c-1.239.613-1.748 1.954-1.748 1.954z"
      fill="#0091EA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M235.783 117.671l-4.606 27.118 6.671.524v-2.943l-1.427-.102 1.109-26.551s-.685.34-1.037.757c-.556.659-.71 1.197-.71 1.197z"
      fill="#0078C2"
    />
  </React.Fragment>
);

// ------------------------------
// Thumbs Up
// ------------------------------
export const SceneThumbsUp = createIllustration(
  describeWithOptions(
    'SceneThumbsUp',
    'Illustration of a woman inside a phone, presenting a thumbs up gesture.'
  ),
  <React.Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M356.766 210.68c0 46.305-29.968 72.138-69 72.138s-69-25.833-69-72.138c0-97.494 44.04-209.89 69-209.89s69 112.396 69 209.89z"
      fill="#C3E4FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M292.83 124.78v225.102h-10.5V124.78h10.5zM326.62 224.958l-20.164 20.457-4.95-5.021 20.164-20.457 4.95 5.021zM326.62 137.942l-20.164 20.457-4.95-5.021 20.164-20.457 4.95 5.021zM265.944 174.866c0 53.228-42.533 96.377-95 96.377s-95-43.149-95-96.377c0-53.227 42.533-96.377 95-96.377s95 43.15 95 96.377z"
      fill="#E8F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.339 151.022v198.814h-10.5V151.022h10.5zM214.803 225.305l-21.129 21.436-5.965-5.948 21.128-21.436 5.966 5.948zM214.803 156.097l-21.129 21.435-5.965-5.948 21.128-21.435 5.966 5.948zM133.315 186.28l21.154 21.462-5.94 6.025-21.154-21.461 5.94-6.026zM133.315 151.676l21.154 21.461-5.94 6.026-21.154-21.462 5.94-6.025z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M256.982 343.642l-171.554.914c-9.792-12.622-32.594-44.229-23.415-53.567 6.038-6.143 24.137 13.627 29.023 7.695 4.886-5.928-27.202-42.886-19.055-48.095 14.36-9.178 60.563 56.697 66.527 51.395 6.27-5.575-23.195-44.313-12.813-48.416 12.088-4.777 44.093 54.462 53.339 48.416 4.844-3.17-4.56-22.484 2.431-25.784 12.167-5.751 32.471 50.031 41.103 45.061 4.083-2.354-.509-10.414 5.611-12.419 7.481-2.447 22.549 23.355 28.803 34.8zM338.723 322.359c-.252.341-7.869 10.652-8.824 20.148h-13.391c6.322-12.745 21.867-19.99 22.215-20.148z"
      fill="#C3E4FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.203 323.332c.252.34 7.868 10.652 8.824 20.148h13.39c-6.321-12.746-21.866-19.99-22.214-20.148zM382.768 330.658c-.176.244-4.035 5.46-6.621 11.849h-9.696c7.085-7.549 16.053-11.731 16.317-11.849zM292.535 283.036c-.2.625-11.271 31.611-12.341 58.465h-17.879c1.066-12.127-4.265-26.771-4.265-26.771s9.514 7.405 11.974 17.973c0 0 7.382-33.687 22.511-49.667zM308.56 317.231s-10.459 14.084-13.254 25.276h-9.728c3.319-6.908 11.283-20.915 22.982-25.276z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M392.662 275.811c0 23.405-11.029 44.662-36 44.662s-36-21.257-36-44.662c0-27.456 18.87-54.758 36-54.758s36 27.302 36 54.758z"
      fill="#E8F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M362.081 268.728v81.154h-10.5v-81.154h10.5zM384.154 271.635l-14.867 14.83-4.417-4.557 14.867-14.83 4.417 4.557zM332.601 276.642l17.346 15.891-4.222 4.743-17.346-15.89 4.222-4.744z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.424 293.23l-1.359 2.882c-5.655 11.994-8.102 23.12-9.135 31.237-.516 4.056-.678 7.351-.712 9.613a54.64 54.64 0 00.017 2.582 32.455 32.455 0 00.035.793l.002.029v.004l.236 3.182-6.283.478-.235-3.186 3.141-.239c-3.141.239-3.141.238-3.142.237v-.01l-.002-.02-.004-.062a36.24 36.24 0 01-.045-.999 61.852 61.852 0 01-.02-2.886c.038-2.475.214-6.013.764-10.334 1.1-8.639 3.698-20.448 9.7-33.177l1.359-2.882 5.683 2.758zM57.321 272.273l2.43 2.033c13.526 11.313 22.733 28.38 28.532 42.338a185.132 185.132 0 016.374 17.928c.683 2.29 1.185 4.147 1.517 5.437a97.712 97.712 0 01.469 1.894l.025.107.006.03.003.012c0 .001 0 .002-3.07.713l3.07-.711.702 3.115-6.142 1.423-.701-3.115-.005-.022-.02-.084a86.96 86.96 0 00-.432-1.745c-.314-1.22-.794-2.998-1.452-5.204a178.751 178.751 0 00-6.15-17.296c-5.661-13.628-14.383-29.558-26.733-39.888l-2.43-2.033 4.007-4.932z"
      fill="#AAD5FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.581 272.191c1.014 5.695-2.715 11.146-8.328 12.174-5.613 1.029-10.985-2.754-12-8.449-1.013-5.694 2.715-11.145 8.329-12.174 5.613-1.028 10.985 2.754 12 8.449zM123.209 285.097c1.013 5.695-2.715 11.145-8.328 12.174-5.614 1.028-10.986-2.754-12-8.449-1.014-5.695 2.715-11.145 8.328-12.174 5.613-1.029 10.986 2.754 12 8.449z"
      fill="#AAD5FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.781 314.247c.296.38 6.643 15.192 5.888 29.232h22.35c.426-7.674 5.224-18.658 5.224-18.658s-9.842 4.042-16.494 15.589c0 0-5.035-20.831-16.968-26.163zM115.748 320.886c-.266.521-5.645 9.978-7.062 21.034l-21.307 2.218c-1.45-9.829-4.584-15.301-4.584-15.301s8.947 2.64 13.723 10.358c0 0 5.535-11.875 19.23-18.309z"
      fill="#D6EEFF"
    />
    <path
      d="M400 350.79H0c.485-5.118 3.404-9.052 6.932-9.052h386.136c3.528 0 6.447 3.934 6.932 9.052z"
      fill="#D6EEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.116 342.25h102.808c5.756 0 10.58-4.375 11.169-10.13l23.219-226.854c.682-6.654-4.513-12.44-11.168-12.44H194.34c-5.757 0-10.58 4.375-11.169 10.13L159.948 329.81c-.681 6.654 4.513 12.44 11.168 12.44z"
      fill="#20262D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M295.391 105.264l-23.222 226.855c-.589 5.756-5.413 10.13-11.17 10.13H158.194c-6.656 0-11.852-5.786-11.17-12.44l23.225-226.855c.586-5.756 5.41-10.13 11.167-10.13h102.805c6.656 0 11.849 5.786 11.17 12.44z"
      fill="#585C62"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.25 332.939h93.588c3.912 0 7.189-2.973 7.589-6.884l22.13-216.206c.463-4.521-3.066-8.453-7.589-8.453h-93.584c-3.912 0-7.19 2.973-7.59 6.883l-22.133 216.206c-.463 4.522 3.067 8.454 7.589 8.454z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M298.549 128.988h13.803l-1.824 17.808h-13.8l1.821-17.808zm2.913 3.245l-1.157 11.319h7.31l1.159-11.319h-7.312zM296.372 153.642h13.802l-1.823 17.809H294.55l1.822-17.809zm2.913 3.245l-1.158 11.319h7.311l1.159-11.319h-7.312zM264.315 94.086l-1.228 12.009c-.409 3.996-3.758 7.035-7.752 7.035h-44.621c-4.617 0-8.223-4.018-7.751-8.636l1.066-10.408h60.286z"
      fill="#585C62"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M241.177 105.2h-19.941a1.512 1.512 0 010-3.023h19.941a1.512 1.512 0 010 3.023zM250.743 103.688a2.513 2.513 0 01-2.506 2.519 2.513 2.513 0 01-2.506-2.519 2.512 2.512 0 012.506-2.518 2.512 2.512 0 012.506 2.518z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.25 332.939h93.588c3.912 0 7.189-2.973 7.589-6.884l22.13-216.206c.463-4.521-3.066-8.453-7.589-8.453h-93.584c-3.912 0-7.19 2.973-7.59 6.883l-22.133 216.206c-.463 4.522 3.067 8.454 7.589 8.454z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M274.554 141.608l-6.755 65.99c-.31 3.023-2.844 5.322-5.868 5.322H182.66c-3.497 0-6.225-3.04-5.867-6.535l6.755-65.968c.309-3.023 2.842-5.321 5.865-5.321l79.272-.023c3.496-.001 6.227 3.039 5.869 6.535zM237.839 227.526l-6.755 65.99c-.31 3.023-2.843 5.322-5.867 5.322h-50.784c-3.496 0-6.225-3.04-5.867-6.536l6.755-65.967c.31-3.023 2.843-5.321 5.865-5.321l50.784-.023c3.497-.001 6.227 3.039 5.869 6.535z"
      fill="#E8F6FF"
    />
    <path
      d="M266.12 309.519a5.941 5.941 0 00-.95-.076l-92.942.022c-3.023.001-5.556 2.299-5.866 5.322l-1.858 18.152h92.334c3.912 0 7.189-2.973 7.589-6.883l1.693-16.537zM275.18 221.005l-20.055.009c-3.022 0-5.555 2.298-5.865 5.321l-6.755 65.967c-.358 3.496 2.371 6.535 5.867 6.535h18.841l7.967-77.832z"
      fill="#E8F6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.271 107.71a3.014 3.014 0 01-3.007 3.022 3.014 3.014 0 01-3.007-3.022 3.014 3.014 0 013.007-3.022 3.014 3.014 0 013.007 3.022zM197.554 107.71a3.014 3.014 0 01-3.007 3.022 3.014 3.014 0 01-3.007-3.022 3.014 3.014 0 013.007-3.022 3.014 3.014 0 013.007 3.022zM281.435 107.71a3.014 3.014 0 01-3.007 3.022 3.014 3.014 0 01-3.007-3.022 3.014 3.014 0 013.007-3.022 3.014 3.014 0 013.007 3.022z"
      fill="#E8F6FF"
    />
    <path
      d="M190.201 227.987c4.666-.061 8.181 3.676 7.873 8.349-.308 4.673-4.322 8.502-8.988 8.563-4.666.062-8.182-3.668-7.874-8.341.308-4.674 4.323-8.509 8.989-8.571zm-.671 3.066l-.05.755c-.644.135-1.173.508-1.54.936-.453.529-.703 1.114-.736 1.623-.049.743.293 1.336.712 1.743.728.71 1.816.86 2.586 1.41.416.303.666.65.642 1.017-.013.196-.184.672-.493 1.033-.308.36-.709.625-1.256.632-.352.004-.732-.133-1.031-.346-.299-.213-.502-.508-.56-.725-.063-.234-.338-.371-.577-.289-.239.082-.4.369-.337.601.13.484.467.886.895 1.191.311.222.679.396 1.081.47l-.049.748.937-.013.05-.755a2.816 2.816 0 001.541-.936c.452-.529.702-1.114.735-1.623.054-.806-.454-1.394-.987-1.783-.755-.593-1.848-.708-2.503-1.331-.297-.288-.479-.626-.451-1.056.013-.196.185-.672.493-1.033.309-.36.71-.625 1.257-.632.352-.004.732.125 1.031.338.299.214.501.509.559.726.064.233.338.371.578.289.239-.082.4-.369.337-.602-.13-.483-.467-.885-.895-1.191a2.682 2.682 0 00-1.082-.462l.049-.748-.936.013z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M239.98 123.622c0 7.306-5.893 13.229-13.162 13.229-7.269 0-13.163-5.923-13.163-13.229 0-7.307 5.894-13.23 13.163-13.23s13.162 5.923 13.162 13.23z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M240.959 152.362c0 .156 0 .308-.004.464-.247 14.177-11.753 25.59-25.913 25.59-11.469 0-21.194-7.48-24.612-17.855a26.348 26.348 0 01-1.306-7.735c-.003-.156-.003-.308-.003-.464a26.024 26.024 0 017.799-18.625c4.673-4.596 11.063-7.424 18.122-7.424 14.311 0 25.917 11.664 25.917 26.049z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.296 193.274l58.032-2.715 19.817-18.124-80.06 12.344s-.693-1.872-1.691-3.805c-.998-1.932-3.357-3.8-3.093-7.923.263-4.123-2.59-.336-2.801 1.653-.211 1.989 1.93 5.695.302 6.532-1.627.837-6.174.761-7.183 1.866-1.009 1.105-.594 3.521.182 3.942 0 0-1.008 2.979.982 4.825 0 0 .166 2.118 2.105 2.946 0 0 .703 2.099 2.811 1.848 2.109-.251 6.875-1.997 10.597-3.389z"
      fill="#FCBDA4"
    />
    <path
      d="M117.106 183.553c-.052-.148-.207-.236-.345-.195-.139.041-.21.195-.158.344.131.374-.04.759-.33 1.101a3.06 3.06 0 01-.548.498l-.015.011-5.966 1.191.145.549 5.985-1.195.025.001c.033.003.084.007.147.017.126.02.296.06.477.138.355.153.743.451.924 1.062.147.497-.064.93-.374 1.271a2.754 2.754 0 01-.595.493l-.042.024-.005.004-5.792 2.418.237.521 5.767-2.408H116.664a2.561 2.561 0 01.763.117c.437.138.879.421 1.067 1.017.226.714-.056 1.224-.438 1.583a2.73 2.73 0 01-.76.499l-.054.023-.01.003-.003.002-.003.001-4.585 1.674.216.529 4.578-1.671.007-.002.053-.013a2.512 2.512 0 01.835-.047c.236.028.463.098.648.232.178.129.337.33.428.66.086.316.052.55-.032.731a1.13 1.13 0 01-.421.453c-.18.117-.375.194-.527.241-.076.024-.14.04-.184.05l-.05.01-.011.003h-.003c-.143.024-.231.166-.196.318.035.153.181.257.325.234l-.064-.276.064.276h.003l.005-.001.019-.004c.016-.003.038-.007.065-.014a2.656 2.656 0 00.866-.356c.227-.147.455-.357.595-.654.142-.302.182-.675.061-1.119-.126-.461-.366-.787-.665-1.002a1.9 1.9 0 00-.846-.328l.024-.023c.46-.432.885-1.142.568-2.144-.271-.857-.921-1.247-1.461-1.418a2.964 2.964 0 00-.23-.063c.055-.053.112-.11.168-.171.354-.391.711-1.011.48-1.793-.245-.829-.793-1.25-1.269-1.455a2.2 2.2 0 00-.214-.081c.07-.068.142-.143.212-.225.314-.369.656-.958.418-1.641z"
      fill="#F49E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.121 187.152h51.838v-34.327h-51.838v34.327z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M263.555 179.427l-9.359 22.355s-1.162 7.423-8.677 11.137h-22.1c-17.521-5.867-24.708-21.972-24.708-21.972l-24.93 12.011v-27.266s40.488-5.787 40.227-5.939c-.362-.207 24.869-1.616 29.862-1.052 4.994.563 19.685 10.726 19.685 10.726z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M202.395 171.575s-2.028 1.262-3.252 3.974c-1.602 3.546-1.643 8.57-1.249 11.899.249 2.095.817 3.499.817 3.499s-23.082 11.192-24.93 12.011v-27.266c2.692-.393 28.614-4.117 28.614-4.117z"
      fill="#DD036C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M269.028 195.577l-1.228 12.02c-.31 3.024-2.843 5.322-5.867 5.322h-26.692l-4.025 2.641s.076 3.592.416 6.159c.176 1.324-1.454 2.15-1.454 2.15s-.417 1.256-1.475 1.519c-.874.219-1.602-1.391-1.815-1.965 1.151 3.105-.693 3.904-1.25 3.83-.567-.078-.796-.912-.796-.912s-1.133.493-2.891-1.331c-1.759-1.827-2.478-7.454-2.412-9.4.022-.678.52-1.664 1.158-2.691.055-.089.111-.181.169-.27 1.17-1.835.795-1.606 2.692-4.163l30.634-6.705 6.487-15.486 8.349 9.282zM221.137 163.348c-1.408 2.147-.826 4.971-.018 6.62.273.567.575.997.833 1.212 1.004.834-5.428 6.995-5.428 6.995l-5.66-6.257c-3.555.2-7.251-.052-9.544-1.497-6.834-4.307-7.262-19.274-7.151-20.023l5.716-6.097s8.628-3.803 9.945-2.195c2.408 2.947 8.053 16.138 12.122 14.626 0 0-.52-4.103 3.083-4.103 2.685 0 3.415 2.046 3.415 3.229 0 3.992-5.421 4.599-7.313 7.49z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M222.654 215.732c.164 0 .298.134.298.299 0 1.148.122 3.049.491 5.008.37 1.967.981 3.951 1.935 5.294a.3.3 0 01-.069.418.297.297 0 01-.416-.07c-1.031-1.45-1.661-3.539-2.035-5.531-.376-1.998-.502-3.938-.502-5.119 0-.165.134-.299.298-.299zM224.937 213.737c.165 0 .298.134.298.299 0 1.147.123 3.049.491 5.008.37 1.966.981 3.951 1.935 5.294a.298.298 0 11-.485.348c-1.03-1.451-1.661-3.54-2.035-5.531-.376-1.999-.502-3.938-.502-5.119 0-.165.134-.299.298-.299zM228.659 213.741a.299.299 0 01.245.344c-.493 2.971-.478 6.561 1.437 9.255a.3.3 0 01-.069.418.296.296 0 01-.415-.07c-2.056-2.892-2.041-6.685-1.541-9.701a.299.299 0 01.343-.246zM202.334 158.932l-2.563 5.933 2.985-.061-.422-5.872z"
      fill="#F49E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M205.074 158.884c.066 1.184.8 2.106 1.64 2.059.84-.047 1.467-1.046 1.401-2.23-.066-1.185-.8-2.107-1.64-2.059-.84.047-1.467 1.045-1.401 2.23zM197.186 158.706c.066 1.184.8 2.106 1.64 2.059.84-.047 1.467-1.046 1.401-2.23-.066-1.185-.8-2.107-1.64-2.06-.84.047-1.467 1.046-1.401 2.231zM201.84 144.23a13.75 13.75 0 01-.815 4.674c-.07.182-.14.371-.222.552a13.743 13.743 0 01-2.006 3.369 13.493 13.493 0 01-9.116 4.945 9.582 9.582 0 01-.56.041v-4.986h.003c-.003-.155-.003-.307-.003-.463a26.024 26.024 0 017.799-18.625 13.636 13.636 0 014.92 10.493z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M221.952 143.913c0 3.561-3.39 6.563-8.02 7.483-.367.075-.74.135-1.123.18-.614.079-1.247.116-1.89.116-6.094 0-11.034-3.482-11.034-7.779 0-.413.045-.819.135-1.213.827-3.723 5.39-6.57 10.899-6.57 6.036 0 10.936 3.419 11.029 7.659.004.041.004.083.004.124z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M202.755 167.111s3.462.324 3.462-2.09l-3.462 2.09z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M223.061 170.801c1.945 1.707 2.072 4.76.285 6.818-1.788 2.057-5.234 2.437-6.822.556-1.671-1.98-2.011-4.681-.222-6.739 1.787-2.058 4.814-2.342 6.759-.635z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M229.914 188.59H220.3v-22.643h20.659v11.542c0 6.131-4.945 11.101-11.045 11.101z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M210.863 171.918s3.782-.57 5.697-1.568l-3.598 3.888-2.099-2.32zM173.781 177.117v13.936l-1.759.082.73-13.859 1.029-.159zM254.192 201.781l3.348-.876 3.139-14.61-6.487 15.486z"
      fill="#987263"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M240.596 188.59l10.056 13.966-5.266 1.153-4.79-15.119z"
      fill="#DD036C"
    />
  </React.Fragment>
);

// ------------------------------
// Coin
// ------------------------------
export const SceneCoin = createIllustration(
  describeWithOptions(
    'SceneCoin',
    'Illustration of a woman walking, while holding a coin.'
  ),
  <React.Fragment>
    <g opacity={0.7}>
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={72}
        width={400}
        height={279}
      >
        <path fill="#C4C4C4" d="M0 72.79h400v277.778H0z" />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.776 262.835h28.253v-58.674l-28.253-23.449v82.123z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.718 352.419h-31.442V250.567h31.442v101.852z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.726 284.863h-31.442V182.606h31.442v102.257z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M298.059 352.419h-76.291V246.864h76.291v105.555z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M353.109 352.419h-76.291V246.864h76.291v105.555z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M402.567 352.419h-76.291v-35.185h76.291v35.185zM227.849 352.419h-76.291V293.16h76.291v59.259z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.323 149.369V125.56h-1.078v23.809h1.078z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.688 180.565v171.854h66.466v-220.37l-26.108 19.057v28.019h-22.67v-11.471l-17.688 12.911z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.212 209.048v-23.5h-1.079v23.5h1.08z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.055 352.032H35.898V200.567h57.157v151.465z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.898 352.419H-.335V200.567h36.233v151.852z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.055 219.706H79.467v-7.05h13.588v7.05zM79.467 226.685H65.879v-7.05h13.588v7.05z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.88 219.706H52.293v-7.05H65.88v7.05z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.055 226.685H79.467v-7.05h13.588v7.05zM79.467 274.268H65.879v-7.05h13.588v7.05zM31.241 226.685H20.714v-7.05h10.527v7.05zM52.292 309.828H38.705v-7.05h13.587v7.05z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.292 233.735H38.705v-7.05h13.587v7.05zM79.467 267.218H65.879v-7.05h13.588v7.05zM52.292 274.268H38.705v-7.05h13.587v7.05zM31.238 219.706H20.714v-7.05h10.524v7.05zM10.19 226.756H-.335v-7.05H10.19v7.05zM31.24 253.966H20.714v-7.049h10.524v7.049zM65.88 309.828H52.293v-7.05H65.88v7.05zM93.055 327.911H79.467v-7.05h13.588v7.05zM79.467 334.961H65.879v-7.05h13.588v7.05zM93.055 233.735H79.467v-7.05h13.588v7.05z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M206.414 231.678h-5.549v-9.904h5.549v9.904zM206.414 211.87h-5.549v-9.903h5.549v9.903z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211.872 351.546h-47.757V96.802h47.757v254.744z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M164.116 352.419h-30.275V96.864h30.275v255.555z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211.872 90.927h-47.757V79.279h47.757v11.648z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.216 86.289h-19.268V74.64h19.268V86.29zM159.452 86.289h-19.267V74.64h19.267V86.29z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M164.116 90.927h-30.275V79.279h30.275v11.648z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M216.28 96.802h-53.153v-5.875h53.153v5.875z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.127 96.802h-33.694v-5.875h33.694v5.875zM206.414 351.546h-5.549V102.574h5.549v248.972zM175.405 351.546h-5.549V102.574h5.549v248.972zM185.741 351.546h-5.548V102.574h5.548v248.972zM196.077 351.546h-5.548V102.574h5.548v248.972z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M196.077 221.773h-5.548v-9.903h5.548v9.903zM185.741 313.005h-5.548v-9.903h5.548v9.903zM185.741 303.102h-5.548v-9.903h5.548v9.903z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M175.405 273.365h-5.549v-9.904h5.549v9.904zM206.414 283.415h-5.549v-9.904h5.549v9.904zM175.405 191.525h-5.549v-9.903h5.549v9.903zM206.414 175.961h-5.549v-9.903h5.549v9.903zM185.741 283.295h-5.548v-9.903h5.548v9.903z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M206.414 324.386h-5.549v-9.903h5.549v9.903zM185.741 211.87h-5.548v-9.903h5.548v9.903zM206.414 241.581h-5.549v-9.904h5.549v9.904zM175.405 154.322h-5.549v-9.904h5.549v9.904zM206.414 138.929h-5.549v-9.904h5.549v9.904zM206.414 148.832h-5.549v-9.903h5.549v9.903zM185.741 201.966h-5.548v-9.903h5.548v9.903z"
          fill="#E8F6FF"
        />
        <path d="M257.528 204.1v19.554" stroke="#C3E4FF" strokeWidth={1.165} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M317.918 231.677h-16.901v-14.43h16.901v14.43z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M334.2 352.419h-60.473V224.641H334.2v127.778z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M328.017 351.546h-7.008V229.972h7.008v121.574zM314.964 351.546h-7.008V229.972h7.008v121.574zM301.909 351.546h-7.007V229.972h7.007v121.574zM288.856 352.697h-7.007V231.123h7.007v121.574z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M267.337 231.677h-16.901v-14.43h16.901v14.43z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M273.727 352.533h-38.336V224.641h38.336v127.892z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M260.818 352.013h-7.007v-85.705h7.007v85.705zM247.765 352.013h-7.008v-85.705h7.008v85.705zM380.574 352.419h-52.349v-72.222h52.349v72.222z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M328.225 352.419h-33.186v-72.222h33.186v72.222z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M380.574 343.147h-75.229v-6.207h75.229v6.207zM380.574 332.442h-75.229v-6.208h75.229v6.208zM380.574 321.738h-75.229v-6.208h75.229v6.208zM380.574 311.033h-75.229v-6.208h75.229v6.208zM380.574 300.328h-75.229v-6.207h75.229v6.207zM380.574 289.623h-75.229v-6.207h75.229v6.207z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M348.332 343.146h-11.079v-6.208h11.079v6.208zM348.332 332.442h-11.079v-6.208h11.079v6.208z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M359.41 343.146h-11.079v-6.208h11.079v6.208zM348.499 311.032h-11.078v-6.208h11.078v6.208zM375.498 300.327H364.42v-6.207h11.078v6.207z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M364.421 300.327h-11.078v-6.207h11.078v6.207zM12.673 352.419h-60.458v-75.926h60.458v75.926z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.673 287.209h-114.576v-3.793H12.673v3.793z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.673 294.058h-114.576v-3.794H12.673v3.794z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.673 330.036h-114.576v-3.794H12.673v3.794z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.673 336.885h-114.576v-3.793H12.673v3.793z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8 283.414h-76.291v-15.305H23.8v15.305zM23.8 326.242h-76.291v-25.841H23.8v25.841z"
          fill="#D6EEFF"
        />
      </g>
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M243.911 133.215c-7.507 0-13.592 6.086-13.592 13.593v11.193c0 6.809 5.006 12.449 11.539 13.439l.01.167h17.519c2.979-2.89-.388-22.488-2.488-28.983-.341-1.057-.67-1.772-.994-2.217-2.289-4.28-6.801-7.192-11.994-7.192z"
      fill="#191847"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M244.174 162.145c3.912 2.341 7.756 3.472 9.561 3.01 4.524-1.157 4.94-17.587 1.439-24.064-3.5-6.477-21.497-9.3-22.404 3.533-.315 4.454 1.569 8.428 4.353 11.672l-4.992 23.094h14.499l-2.456-17.245z"
      fill="#B28B67"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M230.851 144.699c.421-.413.828-.813 1.232-1.171l-.019-.466s4.55-9.247 11.546-9.247c6.989 0 9.2 3.225 11.079 5.967l.005.007c-.983 3.348-3.945 4.702-7.295 6.234-1.442.659-2.956 1.351-4.415 2.25a3.322 3.322 0 00-2.093-.737c-1.835 0-3.323 1.476-3.323 3.298 0 1.821 1.488 3.298 3.323 3.298.05 0 .101-.001.151-.004.668 7.123.4 15.257-1.591 17.188h-17.519c-.503-8.674 1.758-11.148 4.067-13.676 2.174-2.379 4.391-4.806 4.391-12.49.156-.151.309-.302.461-.451z"
      fill="#191847"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.737 145.11c.859 7.216 5.036 11.262 5.915 17.99 1.13 8.654-3.94 8.115-3.013 15.216.481 3.681-31.865 15.108-42.069 4.195-6.548-7.003-.694-8.382-2.01-18.457-1.303-9.973 5.815-12.025 5.128-17.283-.914-6.997 4.029-16.787 13.237-17.979 11.215-1.45 21.514 5.413 22.812 16.318z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M245.746 163.381s-3.29.525-4.799-.06l4.907 2.252-.108-2.192z"
      fill="#CF3E83"
    />
    <path
      d="M237.691 157.785c1.254 1.517 1.114 3.748.689 5.096-.142.463-.96 4.293-1.128 4.484-.652.738 8.868 3.49 8.868 3.49l-.369-7.471c2.631-.187 5.677-.72 7.214-2.053 4.579-3.972 3.366-15.54 3.208-16.106l-4.819-4.147s-6.723-2.102-7.526-.736c-1.468 2.499-4.267 13.2-7.41 12.425 0 0-.037-3.209-2.682-2.864-1.972.257-2.299 1.903-2.178 2.814.407 3.074 4.449 3.022 6.133 5.068z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M251.471 151.27l2.487 4.323-2.198.239-.289-4.562z"
      fill="#F49E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M249.484 151.73c.072.919-.373 1.699-.995 1.743-.622.045-1.184-.664-1.256-1.582-.073-.919.373-1.699.995-1.743.621-.045 1.184.664 1.256 1.582zM255.262 150.834c.072.919-.373 1.699-.995 1.743-.622.045-1.184-.664-1.257-1.582-.072-.919.374-1.699.995-1.744.622-.044 1.184.665 1.257 1.583z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M251.588 133.442c.2-.261.414-.511.642-.75.584.427 1.142.888 1.668 1.383 2.832 2.658 4.787 6.244 5.33 10.352.014.104.027.205.039.309h.002l.441 3.329a9.433 9.433 0 01-7.083-2.46 9.246 9.246 0 01-1.675-2.07c-.071-.114-.136-.234-.201-.348a9.046 9.046 0 01-.76-1.96c-7.75 12.895-13.572 11.547-13.572 11.547l.332-6.461-6.858-6.31s17.466-9.149 21.695-6.561z"
      fill="#19141E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M251.588 133.442c.2-.261.414-.511.642-.75.584.427 1.142.888 1.668 1.383 2.832 2.658 4.787 6.244 5.33 10.352.014.104.027.205.039.309h.002l.441 3.329a9.433 9.433 0 01-7.083-2.46 9.246 9.246 0 01-1.675-2.07c-.071-.114-.136-.234-.201-.348a9.046 9.046 0 01-.76-1.96c-7.75 12.895-13.572 11.547-13.572 11.547l.332-6.461-6.858-6.31s17.466-9.149 21.695-6.561zM251.335 157.727l.171.362.171.362h-.001l-.001.001-.003.001-.006.003-.02.009-.064.025a2.192 2.192 0 01-.218.066 2.828 2.828 0 01-.757.08c-.638-.017-1.479-.239-2.37-1.001l.521-.607c.749.64 1.412.796 1.869.808.232.006.418-.025.542-.056a1.78 1.78 0 00.137-.041l.029-.012.004-.001h-.001l-.001.001h-.002z"
      fill="#0B096F"
    />
    <path
      opacity={0.397}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M245.741 163.382s-3.291.524-4.799-.06l4.907 2.252-.108-2.192z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.407 224.238l39.638 65.779 29.716 55.611h6.401l-49.791-121.39h-25.964z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M299.256 342.849c-1.09-.414-2.362.051-2.725 1.16-.301.92-.578 1.945-.578 2.601 0 1.432.48 4.18.48 4.18h30.087s1.173-3.849-1.354-4.156c-2.527-.306-4.198-.554-4.198-.554l-13.55-5.995a1 1 0 00-1.328.531l-.715 1.723s-2.339 1.083-3.802 1.083c-.63 0-1.514-.268-2.317-.573z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M228.802 224.238c-3.741 23.115-10.956 52.647-11.637 53.559-.454.607-16.797 6.043-49.028 16.308l1.382 5.602c38.436-5.504 58.689-9.775 60.758-12.812 3.103-4.556 18.599-40.543 24.601-62.657h-26.076z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M179.467 289.262l2.435 9.023c28.748 2.503 46.363.695 52.844-5.425 6.481-6.12 13.867-28.994 22.159-68.622h-33.244c-5.253 32.45-8.19 49.356-8.811 50.719-.621 1.362-12.415 6.13-35.383 14.305z"
      fill="#151B21"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M292.509 336.723l12.343-3.707s-8.112-24.072-19.885-52.982c-11.773-28.909-25.288-55.796-25.288-55.796h-34.862s15.025 34.011 31.857 61.807c16.832 27.795 35.835 50.678 35.835 50.678z"
      fill="#151B21"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M170.336 294.122c.222-1.153-.464-2.337-1.628-2.498-.962-.134-2.022-.226-2.67-.112-1.421.248-4.063 1.196-4.063 1.196l5.224 29.411s4.022.478 3.888-2.045c-.135-2.524-.18-4.2-.18-4.2l3.592-14.272a1.002 1.002 0 00-.758-1.222l-1.829-.399s-1.481-2.099-1.735-3.528c-.108-.608 0-1.503.159-2.331z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M252.609 187.87l21.64-8.939c3.829-3.646 7.513-6.402 11.051-8.268 1.053-.31 2.844-.375.884 2.354-1.959 2.73-3.897 5.689-3.152 6.674.745.985 2.974-.043 3.818 1.551.563 1.063-3.185 2.248-11.243 3.556l-16.303 11.92-6.695-8.848z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M248.155 186.438c6.01-.063 14.025-1.323 22.577-6.711l3.358 7.434c-5.899 5.811-14.193 10.585-21.445 10.383-5.716-.159-8.636-7.021-4.49-11.106z"
      fill="#DD036C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M217.87 236.562c8.934-1.746 35.506 7.124 40.044-2.454 8.975-18.945-7.682-45.163-9.359-66.545l-13.717-.001c-1.504 0-11.727 19.454-11.737 32.092-.008 11.541-10.826 18.871-5.231 36.908z"
      fill="#FC1C88"
    />
    <ellipse cx={289.262} cy={172.481} rx={20.599} ry={20.596} fill="#fff" />
    <path
      d="M289.262 151.885c11.4 0 20.598 9.222 20.598 20.605 0 11.383-9.199 20.587-20.598 20.587-11.4 0-20.599-9.204-20.599-20.587s9.199-20.605 20.599-20.605zm-1.147 7.451v1.84c-1.553.308-2.787 1.201-3.615 2.232-1.021 1.275-1.538 2.693-1.538 3.933 0 1.81.933 3.266 2.021 4.271 1.897 1.755 4.581 2.155 6.551 3.521 1.068.753 1.735 1.606 1.735 2.501 0 .476-.342 1.632-1.039 2.501-.696.868-1.634 1.5-2.97 1.5-.86 0-1.812-.346-2.577-.875-.766-.529-1.308-1.255-1.485-1.786-.192-.572-.886-.916-1.459-.724-.571.192-.918.887-.726 1.456.394 1.184 1.283 2.173 2.38 2.932.797.551 1.724.987 2.72 1.179v1.822h2.291v-1.84c1.553-.308 2.787-1.201 3.615-2.233 1.021-1.275 1.538-2.693 1.538-3.932 0-1.963-1.336-3.414-2.702-4.379-1.941-1.469-4.632-1.786-6.336-3.324-.772-.713-1.271-1.543-1.271-2.59 0-.476.342-1.633 1.038-2.501.697-.869 1.635-1.501 2.971-1.501.86 0 1.811.329 2.577.858.766.529 1.307 1.255 1.484 1.786.193.572.887.916 1.46.723.571-.192.918-.886.725-1.455-.394-1.184-1.283-2.173-2.38-2.932a7.115 7.115 0 00-2.72-1.161v-1.822h-2.288z"
      fill="#FFB027"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M283.386 196.388l7.538-4.111s1.586-5.992 3.792-6.029c1.13-.522-.838 4.526 1.304 4.088 2.142-.438 7.37-4.297 8.271-3.514 1.333 1.16.145 4.719-1.256 6.49-2.616 3.31-4.783 4.442-10.138 5.924-4.2 1.162-9.081 4.66-9.081 4.66l-.43-7.508z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M247.19 167.562s-1.373 27.297 7.945 30.474c9.318 3.177 33.212-5.263 33.212-5.263s5.589 11.476 5.167 16.362c-53.07 27.518-74.9-7.774-58.244-41.573h11.92z"
      fill="#FC1C88"
    />
  </React.Fragment>
);

// ------------------------------
// Jetpack
// ------------------------------
export const SceneJetpack = createIllustration(
  describeWithOptions('SceneJetpack', 'Illustration of a man using a jetpack.'),
  <React.Fragment>
    <g opacity={0.7}>
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={72}
        width={400}
        height={279}
      >
        <path
          transform="matrix(-1 0 0 1 400 72.79)"
          fill="#C4C4C4"
          d="M0 0h400v277.778H0z"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.224 262.835H90.971v-58.674l28.253-23.449v82.123z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.282 352.419h31.442V250.567h-31.442v101.852z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.274 284.863h31.442V182.606h-31.442v102.257z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101.941 352.419h76.291V246.864h-76.291v105.555z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.891 352.419h76.291V246.864H46.891v105.555z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.567 352.419h76.291v-35.185h-76.29v35.185zM172.151 352.419h76.291V293.16h-76.291v59.259z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M298.677 149.369V125.56h1.078v23.809h-1.078z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M354.312 180.565v171.854h-66.466v-220.37l26.108 19.057v28.019h22.671v-11.471l17.687 12.911z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M386.788 209.048v-23.5h1.079v23.5h-1.079z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M306.945 352.032h57.157V200.567h-57.157v151.465z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M364.102 352.419h36.233V200.567h-36.233v151.852z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M306.945 219.706h13.588v-7.05h-13.588v7.05zM320.533 226.685h13.588v-7.05h-13.588v7.05z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M334.12 219.706h13.588v-7.05H334.12v7.05z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M306.945 226.685h13.588v-7.05h-13.588v7.05zM320.533 274.268h13.588v-7.05h-13.588v7.05zM368.759 226.685h10.527v-7.05h-10.527v7.05zM347.708 309.828h13.587v-7.05h-13.587v7.05z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M347.708 233.735h13.587v-7.05h-13.587v7.05zM320.533 267.218h13.588v-7.05h-13.588v7.05zM347.708 274.268h13.587v-7.05h-13.587v7.05zM368.762 219.706h10.524v-7.05h-10.524v7.05zM389.81 226.756h10.525v-7.05H389.81v7.05zM368.761 253.966h10.524v-7.049h-10.524v7.049zM334.12 309.828h13.588v-7.05H334.12v7.05zM306.945 327.911h13.588v-7.05h-13.588v7.05zM320.533 334.961h13.588v-7.05h-13.588v7.05zM306.945 233.735h13.588v-7.05h-13.588v7.05z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.586 231.678h5.549v-9.904h-5.549v9.904zM193.586 211.87h5.549v-9.903h-5.549v9.903z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188.128 351.546h47.757V96.802h-47.757v254.744z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.884 352.419h30.275V96.864h-30.275v255.555z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188.128 90.927h47.757V79.279h-47.757v11.648z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M196.784 86.289h19.268V74.64h-19.268V86.29zM240.548 86.289h19.267V74.64h-19.267V86.29z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.884 90.927h30.275V79.279h-30.275v11.648z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.72 96.802h53.153v-5.875H183.72v5.875z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M236.873 96.802h33.694v-5.875h-33.694v5.875zM193.586 351.546h5.549V102.574h-5.549v248.972zM224.595 351.546h5.549V102.574h-5.549v248.972zM214.259 351.546h5.548V102.574h-5.548v248.972zM203.923 351.546h5.548V102.574h-5.548v248.972z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.923 221.773h5.548v-9.903h-5.548v9.903zM214.259 313.005h5.548v-9.903h-5.548v9.903zM214.259 303.102h5.548v-9.903h-5.548v9.903z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M224.595 273.365h5.549v-9.904h-5.549v9.904zM193.586 283.415h5.549v-9.904h-5.549v9.904zM224.595 191.525h5.549v-9.903h-5.549v9.903zM193.586 175.961h5.549v-9.903h-5.549v9.903zM214.259 283.295h5.548v-9.903h-5.548v9.903z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.586 324.386h5.549v-9.903h-5.549v9.903zM214.259 211.87h5.548v-9.903h-5.548v9.903zM193.586 241.581h5.549v-9.904h-5.549v9.904zM224.595 154.322h5.549v-9.904h-5.549v9.904zM193.586 138.929h5.549v-9.904h-5.549v9.904zM193.586 148.832h5.549v-9.903h-5.549v9.903zM214.259 201.966h5.548v-9.903h-5.548v9.903z"
          fill="#E8F6FF"
        />
        <path d="M142.472 204.1v19.554" stroke="#C3E4FF" strokeWidth={1.165} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.082 231.677h16.901v-14.43h-16.9v14.43z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.8 352.419h60.473V224.641H65.8v127.778z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.983 351.546h7.008V229.972h-7.008v121.574zM85.036 351.546h7.008V229.972h-7.008v121.574zM98.09 351.546h7.008V229.972h-7.007v121.574zM111.144 352.697h7.007V231.123h-7.007v121.574z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M132.663 231.677h16.901v-14.43h-16.901v14.43z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.273 352.533h38.336V224.641h-38.336v127.892z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M139.182 352.013h7.007v-85.705h-7.007v85.705zM152.235 352.013h7.008v-85.705h-7.008v85.705zM19.426 352.419h52.349v-72.222h-52.35v72.222z"
          fill="#D6EEFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.775 352.419h33.186v-72.222H71.775v72.222z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.426 343.147h75.229v-6.207h-75.23v6.207zM19.426 332.442h75.229v-6.208h-75.23v6.208zM19.426 321.738h75.229v-6.208h-75.23v6.208zM19.426 311.033h75.229v-6.208h-75.23v6.208zM19.426 300.328h75.229v-6.207h-75.23v6.207zM19.426 289.623h75.229v-6.207h-75.23v6.207z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.669 343.146h11.078v-6.208H51.669v6.208zM51.669 332.442h11.078v-6.208H51.669v6.208zM40.59 343.146h11.078v-6.208H40.59v6.208zM51.501 311.032h11.078v-6.208H51.501v6.208zM24.502 300.327H35.58v-6.207H24.502v6.207zM35.58 300.327h11.077v-6.207H35.58v6.207zM387.327 352.419h60.458v-75.926h-60.458v75.926z"
          fill="#E8F6FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.327 287.209h114.576v-3.793H387.327v3.793z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.327 294.058h114.576v-3.794H387.327v3.794z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.327 330.036h114.576v-3.794H387.327v3.794z"
          fill="#AAD5FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.327 336.885h114.576v-3.793H387.327v3.793z"
          fill="#C3E4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M376.2 283.414h76.291v-15.305H376.2v15.305zM376.2 326.242h76.291v-25.841H376.2v25.841z"
          fill="#D6EEFF"
        />
      </g>
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M375.307 350.787H121.644c0 .141.821-5.275 0 0 0-7.457 0-16.669 9.085-22.279 13.922-8.598 28.619 5.289 28.386-11.862-.243-17.821-8.264-110.915-8.264-110.915l2.38-7.065s18.611 74.232 23.389 99.548c5.112 27.088 10.7-.702 28.763-1.523 17.05-.774 24.992 8.843 28.247 20.677 9.865-2.885 18.96-4.408 25.841 2.2 9.378-23.598 24.832-3.242 23.039-25.348-2.109-25.998-10.34-106.121-10.34-106.121l3.499-4.432s17.649 73.835 24.901 106.568c5.137 23.185 18.344-1.638 41.053 10.043 17.798 9.155 13.35 30.474 13.35 30.474s18.652-2.343 20.334 20.035z"
      fill="#B5BABD"
    />
    <path
      d="M240.062 350.789l.001-.028c.003-.163.006-.329.006-.491 0-11.009-8.088-20.123-18.628-21.679a21.876 21.876 0 00-6.557.021c-2.59-5.186-7.15-9.209-12.694-11.079a21.926 21.926 0 00-7.035-1.151c-12.181 0-22.055 9.912-22.055 22.136a23 23 0 00.12 2.324 11.722 11.722 0 00-6.863-2.202c-1.024 0-2.016.131-2.963.374a11.871 11.871 0 00-8.859 11.776h22.262l63.265-.001z"
      fill="#D7DBDE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M360.472 350.516c0 .091-.002.183-.004.274h-23.589a12.515 12.515 0 01-.003-.274c0-5.365 3.756-9.876 8.842-11.159a12.221 12.221 0 014.689-.241c5.695.819 10.065 5.611 10.065 11.4z"
      fill="#D7DBDE"
    />
    <path
      d="M329.065 350.789a25.777 25.777 0 002.701-11.531 25.824 25.824 0 00-16.935-24.279 25.488 25.488 0 00-8.778-1.546c-13.049 0-23.829 9.762-25.494 22.41a16.69 16.69 0 00-6.15-2.2 16.892 16.892 0 00-6.699.353c-7.265 1.885-12.632 8.511-12.632 16.392 0 .133.002.269.005.402h33.701c0-.001 0 0 0 0l40.281-.001z"
      fill="#D7DBDE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.077 318.897a3.54 3.54 0 01-3.542 3.538 3.54 3.54 0 01-3.543-3.538 3.54 3.54 0 013.543-3.538 3.54 3.54 0 013.542 3.538zM116.079 339.698a2.038 2.038 0 01-2.04 2.037 2.038 2.038 0 110-4.074c1.127 0 2.04.912 2.04 2.037zM274.835 298.177a5.07 5.07 0 01-5.072 5.067 5.07 5.07 0 01-5.072-5.067 5.069 5.069 0 015.072-5.066 5.069 5.069 0 015.072 5.066zM311.384 293.594c0 1.154-.937 2.09-2.093 2.09a2.092 2.092 0 110-4.181c1.156 0 2.093.936 2.093 2.091zM384 331.549a4.828 4.828 0 01-4.83 4.825 4.828 4.828 0 01-4.831-4.825 4.829 4.829 0 014.831-4.825 4.828 4.828 0 014.83 4.825z"
      fill="#D7DBDE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.547 207.253l-15.243 2.039-6.471-48.254c-1.427-10.642 5.317-18.208 5.317-18.208s8.499 5.527 9.926 16.169l6.471 48.254z"
      fill="#49A2FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.186 197.105l-15.243 2.04-4.539-33.851 15.242-2.04 4.54 33.851zM159.676 212.093l-12.256 1.64-.622-4.641 12.256-1.64.622 4.641zM138.229 212.371l7.076-3.079-1.191-8.883-6.233 9.364.348 2.598zM168.185 208.364l-7.638-1.111-1.191-8.882 8.48 7.395.349 2.598z"
      fill="#285FD7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M270.199 179.58l-117.326 15.698-3.613-26.943 54.097-41.895 63.228 26.197 3.614 26.943z"
      fill="#49A2FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M240.284 148.93l4.565 34.042-66.626 8.914-4.565-34.041a13.339 13.339 0 015.056-12.319l24.645-19.085 28.802 11.934a13.347 13.347 0 018.123 10.555z"
      fill="#285FD7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M282.37 189.528l-15.243 2.039-6.471-48.253c-1.427-10.643 5.317-18.209 5.317-18.209s8.499 5.526 9.926 16.169l6.471 48.254z"
      fill="#49A2FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M281.009 179.379l-15.243 2.04-4.539-33.851 15.242-2.039 4.54 33.85zM281.499 194.369l-12.256 1.64-.623-4.641 12.256-1.64.623 4.641zM255.718 179.419l7.076-3.079-1.191-8.882-6.233 9.363.348 2.598zM290.007 190.639l-7.637-1.111-1.191-8.882 8.48 7.395.348 2.598z"
      fill="#285FD7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.218 256.099l4.38 4.743s-10.131 9.298-10.999 16.373l21.8-14.229-10.702-14.748-4.479 7.861z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M195.61 249.634l4.937 6.854-5.949 4.354-4.38-4.743 5.392-6.465z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M235.879 277.733l4.653 4.477s-4.331 14.844-4.779 21.958l15.165-21.502-11.032-13.045-4.007 8.112z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M240.88 270.961l4.81 5.505-5.157 5.744-4.653-4.477 5-6.772z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.152 200.104s24.797-.488 26.252-4.024c2.862-6.954-.544-30.07-.544-30.07l-14.58 2.52 2.506 20.982-16.859 3.654"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M192.151 190.382l-3.598-15.337-11.917 2.245s-.246-18.782 2.809-23.444c2.629-4.012 7.076-9.232 18.267-11.517 16.639-3.397 20.677-1.654 26.248 2.596 4.814 3.672 11.436 15.108 11.436 15.108l-12.472 6.806 4.195 14.392-34.968 9.151z"
      fill="#FC1C88"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M175.091 121.22s-4.545 10.591 5.295 10.59l-5.295-10.59z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M194.613 147.067l-1.623-6.23s-8.761 2.974-11.063 2.437c0 0-3.967-9.098-4.216-16.202l6.157-9.249 13.149-2.337 4.442 11.296c.168-2.162 5.68-6.222 8.295-2.134 3.372 5.273-6.302 10.829-6.302 10.829l2.487 8.138"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M194.613 143.009l-2.864 1.038s-1.563 8.639 7.099 6.847c6.999-1.449 8.179-10.071 8.179-10.071l-3.868.437"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M211.657 140.536s.821-.502 5.533.711c0 0-3.613 13.069 7.014 37.3l-1.765 1.662s-13.243-16.618-10.782-39.673zM183.375 149.093s3.017-3.098 4.987-3.535c0 0-4.767 10.58 1.913 36.829l1.488 6.344s-11.401-17.046-8.388-39.638z"
      fill="#1E202D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M187.304 139.211c.302.962 2.625 1.278 3.974.268 2.989-2.238 2.071-5.202.232-5.153-2.294.06-1.751 2.158-2.817 2.866-1.221.81-1.611 1.315-1.389 2.019z"
      fill="#fff"
    />
    <path
      opacity={0.398}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M193.006 140.873l6.841-3.265-6.063 6.188-.778-2.923z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.658 132.032l-.834 5.971 2.661-.642-1.827-5.329z"
      fill="#F49E7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.802 131.973c.137.977.833 1.687 1.554 1.586.721-.101 1.194-.975 1.057-1.952-.137-.976-.833-1.686-1.554-1.585-.721.101-1.194.974-1.057 1.951zM180.439 133.379c.137.977.832 1.687 1.553 1.586.721-.101 1.194-.975 1.057-1.951-.137-.977-.832-1.687-1.553-1.586-.721.101-1.194.975-1.057 1.951z"
      fill="#0B096F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M192.151 190.382s-31.809 16.915-35.102 26.375c-3.542 10.175 32.135 43.665 32.135 43.665l11.021-7.961-20.173-31.603 37.684-20.755-8.098-14.292-17.467 4.571z"
      fill="#2B2B3B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M207.011 186.493s-2.323 46.521 0 56.064c1.245 5.116 29.611 40.743 29.611 40.743l9.124-11.043-19.469-36.777 2.402-55.003-21.668 6.016z"
      fill="#2B2B3B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M213.875 185.168s23.136 3.729 26.285.756c5.043-4.759-6.507-24.94-6.507-24.94l-11.253 6.16 4.426 9.267-13.663.114"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M166.45 203.732l-11.861-15.761 2.29-1.719 11.861 15.762-2.29 1.718zM207.384 168.817l4.187 19.717-2.522.534-4.186-19.717 2.521-.534z"
      fill="#1C2127"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.199 195.651c2.387 2.434 3.245 5.616 1.915 7.109-1.329 1.493-4.341.73-6.728-1.704-2.387-2.433-3.245-5.616-1.915-7.108 1.329-1.493 4.342-.73 6.728 1.703z"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162.755 193.641c-.121 1.985-2.245 2.111-2.245 2.111l-.317-4.985 6.59 1.563-.37 3.422-3.658-2.111zM215.114 177.509s-6.38-4.316-7.936-4.196c-1.874.144-4.607 1.517-5.591 2.247-.983.729-.201 2.635.409 3.051 0 0-1.152 1.632.322 3.291 0 0-.103 1.65 1.446 2.52 0 0 .354 1.699 2.171 1.758 1.817.059 6.061-.713 9.378-1.339"
      fill="#FCBDA4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.647 146.964l-30.628 40.363-.57-.432 30.627-40.363.571.432zM205.779 168.987l8.064-28.383.689.196-8.064 28.382-.689-.195z"
      fill="#1C2127"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M206.257 206.33l.274-5.289-13.89 12.741 13.616-7.452z"
      fill="#151B21"
    />
    <path
      opacity={0.398}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M222.399 167.144l11.254-6.16-9.449 9.938-1.805-3.778zM178.289 176.979l.497 4.16 7.935-2.966-.889-2.616-7.543 1.422z"
      fill="#000"
    />
    <path
      d="M203.601 113.249c8.365 5.675 2.882 9.531 2.882 9.531-3.881 1.04-4.484 3.684-4.484 3.684-6.88.403-5.687-5.74-5.687-5.74-26.605 16.531-31.226-3.671-29.347-5.022 1.879-1.351 6.947 3.88 6.947 3.88s-1.47-11.436 5.783-13.15c5.091-1.202 8.715 6.065 8.715 6.065s3.964-3.831 10.61-1.589l-.172-3.495 2.298-.116.216 4.38.447.099 2.25-3.668 1.963 1.195-2.421 3.946z"
      fill="#0B096F"
    />
  </React.Fragment>
);
