import React, { ReactNode } from 'react';
import { Transition } from 'react-transition-group';

import { BottomDrawerControllerContextProvider } from './context';
import { TransitionState } from './types';

type BottomDrawerControllerProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

export const BottomDrawerController = ({
  isOpen,
  onClose,
  children,
}: BottomDrawerControllerProps) => {
  return (
    <Transition appear mountOnEnter unmountOnExit in={isOpen} timeout={150}>
      {(transitionState: TransitionState) => (
        <BottomDrawerControllerContextProvider
          value={{
            onClose,
            transitionState,
          }}
        >
          {children}
        </BottomDrawerControllerContextProvider>
      )}
    </Transition>
  );
};
