/** @jsx jsx */

import { useRef } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { DayModifiers } from 'react-day-picker';
import {
  formatISO,
  isAfter,
  isBefore,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
} from 'date-fns';
import { jsx } from '@balance-web/core';
import { PopoverDialog, usePopover } from '@balance-web/popover';
import type { InputSizeType } from '@balance-web/text-input';
import { CalendarIcon } from '@balance-web/icon';
import { MenuChip } from '@balance-web/chip';

import type { CalendarProps } from './Calendar';
import { Calendar } from './Calendar';
import type { ISODate, ISODateRange } from './types';

type InternalDateState = {
  start?: Date;
  end?: Date;
};

type SelectedInput = 'start' | 'end' | 'none';

export type CalendarRangePickerProps = {
  /** Label of the trigger */
  label?: string;
  /** When true, the triggers will be disabled. */
  disabled?: boolean;
  /** Indicate that the value does not conform to the format expected by the application. */
  invalid?: boolean;
  /** Called when the value changes. */
  onChange: (value: ISODateRange | undefined) => void;
  /** Called when the value is cleared. */
  onClear?: () => void;
  /** The value of the calendar, displayed in the trigger. */
  value: ISODateRange | undefined;
  size?: InputSizeType;
} & Pick<
  CalendarProps,
  'disabledDays' | 'fromMonth' | 'initialMonth' | 'modifiers' | 'toMonth'
>;

export const CalendarRangePicker = ({
  disabled = false,
  invalid: invalidProp = false,
  onChange,
  value,
  // calendar props
  disabledDays,
  fromMonth,
  initialMonth,
  modifiers: consumerModifiers,
  toMonth,
  size = 'medium',
  onClear,
  ...inputProps
}: CalendarRangePickerProps) => {
  const startTextInputRef = useRef<HTMLInputElement>(null);
  const endTextInputRef = useRef<HTMLInputElement>(null);

  const [selectedInput, setSelectedInput] = useState<SelectedInput>('none');

  /**
   * We need dervied state to support temporary valid/invalid text entry
   * and date selection within calendar.
   *
   * Don't use effects to sync internal/external state. Use events.
   */
  const [internalDateState, _setInternalDateState] = useState<
    InternalDateState
  >({
    start: value?.start ? new Date(value?.start) : undefined,
    end: value?.end ? new Date(value?.end) : undefined,
  });

  /**
   * Function that accepts partial internal state inputs and appends them to current internal state.
   * Don't use _setInternalDate directly, it will cause confusion.
   * */
  function setInternalDate(
    update:
      | ((
          prevInternalState: InternalDateState
        ) => {
          start?: Date | ISODate | null;
          end?: Date | ISODate | null;
          textStart?: string;
          textEnd?: string;
        })
      | {
          start?: Date | ISODate | null;
          end?: Date | ISODate | null;
          textStart?: string;
          textEnd?: string;
        }
      | null
  ) {
    _setInternalDateState((prevState) => {
      if (update === null) {
        return {
          start: undefined,
          end: undefined,
          textStart: '',
          textEnd: '',
        };
      }

      const stateUpdate =
        typeof update === 'function' ? update(prevState) : update;

      const newState = {
        ...prevState,
      };

      if (stateUpdate.start === null) {
        newState.start = undefined;
      } else if (stateUpdate?.start) {
        newState.start = new Date(stateUpdate.start);
      }

      if (stateUpdate.end === null) {
        newState.end = undefined;
      } else if (stateUpdate?.end) {
        newState.end = new Date(stateUpdate.end);
      }

      return newState;
    });
  }

  // sync state with the popover dialog and the "focused input"
  const { isOpen, setOpen, dialog, trigger } = usePopover({
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const handleOpen = (selectedInput?: SelectedInput) => {
    return () => {
      const _selectedInput: SelectedInput =
        selectedInput ||
        (value?.start && value?.end
          ? 'start'
          : !value?.start?.length
          ? 'start'
          : 'end');
      setSelectedInput(_selectedInput);
      setOpen(true);

      if (!isOpen) {
        if (_selectedInput === 'start' || _selectedInput === 'none') {
          startTextInputRef.current?.select();
        } else if (_selectedInput === 'end') {
          endTextInputRef.current?.select();
        }
      }
    };
  };

  const onDateRangeChange = useCallback(
    (args: { startDate?: Date; endDate?: Date }) => {
      onChange({
        start: args.startDate
          ? (formatISO(args.startDate, { representation: 'date' }) as ISODate)
          : internalDateState.start
          ? (formatISO(internalDateState.start, {
              representation: 'date',
            }) as ISODate)
          : undefined,
        end: args.endDate
          ? (formatISO(args.endDate, { representation: 'date' }) as ISODate)
          : internalDateState.end
          ? (formatISO(internalDateState.end, {
              representation: 'date',
            }) as ISODate)
          : undefined,
      });
    },
    [internalDateState.end, internalDateState.start, onChange]
  );

  // We always want to bind isOpen and value in this effect so that we always sync to the correct value on open/close. Separating isOpen out would mean a lot of rework.
  useEffect(
    function syncToValue() {
      if (!isOpen) {
        setSelectedInput('none');
      }

      setInternalDate(value ? value : null);
    },
    [isOpen, value]
  );

  const handleCalendarDayClick = useCallback(
    (day: Date, { disabled }: DayModifiers) => {
      if (disabled) {
        return;
      }

      setInternalDate((prevInternalState) => {
        let resolvedDate: {
          start?: Date | null;
          end?: Date | null;
        };

        if (selectedInput === 'start') {
          resolvedDate = {
            start: day,
            end:
              prevInternalState.end && day > prevInternalState.end
                ? null
                : prevInternalState.end,
          };

          // we're done with the start date, move "focus" to the end date
          setSelectedInput('end');
          endTextInputRef.current?.select();
        } else {
          // NOTE: invert behaviour (day is outside the valid range):
          // selected end day is before the start date
          if (
            !prevInternalState.start ||
            (prevInternalState.start && day < prevInternalState.start)
          ) {
            resolvedDate = {
              start: day,
              end: undefined,
            };

            // selectedInput === "end" behaviour
          } else {
            resolvedDate = {
              start: prevInternalState.start,
              end: day,
            };
            onDateRangeChange({
              startDate: resolvedDate.start || undefined,
              endDate: resolvedDate.end || undefined,
            });
            setTimeout(() => {
              setOpen(false);
            }, 300);
          }
        }

        return {
          start: resolvedDate.start,
          end: resolvedDate.end,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedInput, setOpen]
  );

  // without an end date we only want the calendar to know about the start date
  // so the appropriate modifiers are applied
  const selectedDays = !internalDateState.end
    ? internalDateState.start
    : {
        from: internalDateState.start as Date,
        to: internalDateState.end as Date,
      };

  // modifiers allow us to style the calendar for range input
  const modifiers = {
    ...consumerModifiers,
    lastOfMonth: (day: Date) => {
      return isLastDayOfMonth(day);
    },
    firstOfMonth: (day: Date) => {
      return isFirstDayOfMonth(day);
    },
    rangeStart: (day: Date) => {
      return Boolean(
        internalDateState.start && isSameDay(day, internalDateState.start)
      );
    },
    rangeEnd: (day: Date) => {
      return Boolean(
        internalDateState.end && isSameDay(day, internalDateState.end)
      );
    },
    rangeBetween: (day: Date) => {
      if (!internalDateState.start || !internalDateState.end) {
        return false;
      }
      if (
        isSameDay(day, internalDateState.start) ||
        isSameDay(day, internalDateState.end)
      ) {
        return false;
      }

      return (
        isAfter(day, internalDateState.start) &&
        isBefore(day, internalDateState.end)
      );
    },
  };
  const calendarProps = {
    disabledDays,
    fromMonth,
    initialMonth,
    modifiers,
    toMonth,
  };

  return (
    <div style={{ display: 'inline-flex' }}>
      <MenuChip
        ref={trigger.ref}
        label="Date range"
        iconBefore={CalendarIcon}
        hasValue={!!value?.start}
        disabled={disabled}
        onClick={handleOpen()}
        size={size}
        onClear={onClear}
        {...inputProps}
        {...trigger.props}
      />

      <PopoverDialog
        isVisible={isOpen}
        ref={dialog.ref}
        key="range-dialog"
        {...dialog.props}
      >
        <Calendar
          onDayClick={handleCalendarDayClick}
          selectedDays={selectedDays}
          month={internalDateState.start || internalDateState.end}
          numberOfMonths={2}
          enableOutsideDaysClick
          {...calendarProps}
        />
      </PopoverDialog>
    </div>
  );
};
