/** @jsx jsx */

import { ReactNode, useMemo } from 'react';
import { jsx } from '@balance-web/core';
import { assert } from '@balance-web/utils';

import { TableCellContext, TableCellContextType } from '../cellContext';
import { LabelProps } from '../types';
import { siftAriaAttributes } from '../utils';

export type TableProps = TableCellContextType & {
  /** The elements that make up the table. */
  children: ReactNode;
  /**
   * Provide the ID of an element that describes the table.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby
   */
  'aria-describedby'?: string;
  /**
   * When only a subset of rows are loaded, for virtualized or paginated
   * results, use the aria-rowcount attribute to let assistive technologies know
   * how many rows the table would have if all rows were present.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-rowcount
   */
  'aria-rowcount'?: number;
  /**
   * When only a subset of columns are present, use the aria-colcount attribute to
   * let assistive technologies know how many columns the table would have if
   * all columns were present.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-colcount
   */
  'aria-colcount'?: number;
} & LabelProps;

export function Table(props: TableProps) {
  validateProps(props);
  const ariaAttributes = siftAriaAttributes(props);

  // default props
  const {
    children,
    alignY = 'center',
    density = 'regular',
    overflowStrategy = 'truncate',
  } = props;

  // init context
  const cellContext = useMemo(() => ({ alignY, density, overflowStrategy }), [
    alignY,
    density,
    overflowStrategy,
  ]);

  return (
    <TableCellContext.Provider value={cellContext}>
      <div
        role="grid"
        css={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
          minWidth: 0,
        }}
        {...ariaAttributes}
      >
        {children}
      </div>
    </TableCellContext.Provider>
  );
}

// Utils
// ------------------------------

function validateProps(props: TableProps) {
  assert(
    'aria-label' in props || 'aria-labelledby' in props,
    'You must provide either `aria-label` or `aria-labelledby`.'
  );
}
