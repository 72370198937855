import React, { useState } from 'react';
import type { PropsWithChildren } from 'react';

import { BookIdContext } from './BookIdContext';

type BookIdProviderProps = PropsWithChildren<{
  bookId?: string;
}>;

export function BookIdProvider({
  children,
  bookId: defaultBookId = '',
}: BookIdProviderProps) {
  const [bookId, setBookId] = useState(defaultBookId);
  return (
    <BookIdContext.Provider
      value={{
        bookId,
        setBookId,
      }}
    >
      {children}
    </BookIdContext.Provider>
  );
}
