/** @jsx jsx */

import { CSSProperties, ReactNode, forwardRef, useMemo } from 'react';
import { buildDataAttributes, jsx } from '@balance-web/core';
import { DefaultTextPropsProvider } from '@balance-web/text';

// Component
// ------------------------------

export type TableFootProps = UseFootStylesProps & {
  /** The elements that make up the table footer. */
  children: ReactNode;
  /**
   * Sets inline [style](https://reactjs.org/docs/dom-elements.html#style) for
   * the element. Only use as a **last resort**. Use style props instead.
   */
  UNSAFE_style?: CSSProperties;
};

export const TableFoot = forwardRef<HTMLDivElement, TableFootProps>(
  ({ children, ...props }, ref) => {
    const { dataAttributes, css, style } = useFootStyles(props);
    return (
      <DefaultTextPropsProvider size="small">
        <div
          ref={ref}
          role="rowgroup"
          css={css}
          style={style}
          {...dataAttributes}
        >
          {children}
        </div>
      </DefaultTextPropsProvider>
    );
  }
);

// Styles
// ------------------------------

type UseFootStylesProps = {
  stickyOffset?: number;
};

export function useFootStyles(props: UseFootStylesProps) {
  const { stickyOffset } = props;

  // dynamic properties
  const style = useMemo(() => ({ bottom: stickyOffset }), [stickyOffset]);

  // static-ish properties
  const sticky = typeof stickyOffset !== 'undefined';
  const dataAttributes = useMemo(
    () => buildDataAttributes({ sticky, type: 'foot' }, 'rowgroup'),
    [sticky]
  );

  // declarations
  const css = useMemo(
    () => [
      {
        // display: 'flex',
        // flexDirection: 'column',
      } as const,
      {
        [`&[data-rowgroup-sticky="true"]`]: {
          position: 'sticky',
          zIndex: 1,
        } as const,
      },
    ],
    []
  );

  return { dataAttributes, css, style };
}
