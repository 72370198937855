import type { ComponentType } from 'react';
import React, { useRef } from 'react';

import { CaretRightIcon } from '../icons/CaretRightIcon';
import {
  buildDataAttributes,
  toggleHTMLAttribute,
  toggleHTMLAttributeOrRemove,
} from '../common';
import {
  ACTIVE_HOVER_BG,
  ACTIVE_PRESSED_BG,
  HOVER_BG,
  HOVER_TARGET_HEIGHT,
  PRESSED_BG,
  TRANSITION_CURVE,
} from '../constants';
import { HoverOverlay } from '../HoverOverlay';
import { PressedOverlay } from '../PressedOverlay';
import { useAnimatedExpandCollapseStyles } from '../useAnimatedExpandCollapseStyles';
import type { AppLeftNavigatorItem } from '../types';

type ChildNavigationItemProps = {
  item: Omit<AppLeftNavigatorItem, 'type' | 'icon'>;
  activeRouteId?: string;
  defaultExpanded?: boolean;
  linkComponent?: ComponentType<any>;
};

export const ChildNavigationItem = (props: ChildNavigationItemProps) => {
  const animatedExpandCollapseStyles = useAnimatedExpandCollapseStyles();
  const itemRef = useRef<HTMLDivElement | null>(null);

  const isActiveRoute = props.activeRouteId === props.item.routeId;

  const dataAttributes = buildDataAttributes({
    routeid: props.item.routeId,
    open: props.defaultExpanded ? '1' : '0',
    activeroute: isActiveRoute ? '1' : '0',
    haschildren: props.item.items?.length ? '1' : '0',
  });

  // Types probably un-reconcilable
  const WrapperTag = props.item.url
    ? props.linkComponent || 'a'
    : ('button' as any);

  return (
    <div
      key={props.item.routeId}
      ref={itemRef}
      className="leftnav-child-item"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      {...dataAttributes}
      {...props.item.attributes}
    >
      <div
        className="child-item-header-wrapper"
        style={{
          height: HOVER_TARGET_HEIGHT,
        }}
      >
        <WrapperTag
          {...(props.item.url
            ? { href: props.item.url, target: props.item.target }
            : {})}
          className="child-item-header interactive-overlay"
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
            paddingLeft: props.item.items?.length ? 'none' : 'medium',
            gap: 2,
            height: '100%',
            borderRadius: 999,
            transition: `opacity 300ms ${TRANSITION_CURVE}, background 300ms ${TRANSITION_CURVE}`,
            zIndex: 999999,
          }}
          onClick={() => {
            toggleHTMLAttribute(itemRef.current, 'data-open', '1', '0');
            // Toggle direct a/button descendants tabbing
            itemRef.current
              ?.querySelectorAll(
                ':scope > .child-item-container > .child-item-item .leftnav-child-item .child-item-header-wrapper a, :scope > .child-item-container > .child-item-item .leftnav-child-item .child-item-header-wrapper a'
              )
              ?.forEach((item) => {
                toggleHTMLAttributeOrRemove(item, 'tabIndex', '-1');
              });
          }}
        >
          <HoverOverlay
            backgroundColor={isActiveRoute ? ACTIVE_HOVER_BG : HOVER_BG}
            styles={{
              borderRadius: 999,
            }}
          />
          <PressedOverlay
            backgroundColor={isActiveRoute ? ACTIVE_PRESSED_BG : PRESSED_BG}
            styles={{
              borderRadius: 999,
            }}
          />

          {props.item.items?.length ? (
            <div
              className="child-item-icon"
              style={{
                transition: `transform 300ms ${TRANSITION_CURVE}`,
                transformOrigin: 'center',
              }}
            >
              <CaretRightIcon />
            </div>
          ) : null}
          <div
            style={{
              userSelect: 'none',
              paddingLeft: props.item.items?.length ? 0 : 12,
              fontSize: '0.875rem',
              color: '#181A20',
              fontWeight: 400,
              lineHeight: 1.4,
            }}
          >
            {props.item.label}
          </div>
        </WrapperTag>
      </div>
      <div className="child-item-header-separator" style={{ height: 4 }} />
      {props.item.items?.length ? (
        <div className="child-item-container" style={{ paddingLeft: 16 }}>
          {props.item.items.map((item, i) => {
            return (
              <div key={item.routeId} className="child-item-item">
                <div style={animatedExpandCollapseStyles.innerWrapper}>
                  <ChildNavigationItem
                    item={item}
                    activeRouteId={props.activeRouteId}
                    defaultExpanded={props.defaultExpanded}
                    linkComponent={props.linkComponent}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
