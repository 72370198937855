/* @jsx jsx */
import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import { jsx, useLinkComponent } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { Box } from '@balance-web/box';
import { ternary } from '@balance-web/utils';
import { buildDataAttributes } from '@balance-web/core';

import { createTestId } from './utils';
import type { PageType } from './types';

type NavigationItemProps = {
  /** The label, and supporting content, of the tab. */
  children: ReactNode;
  /** When true, the tab will have a selected appearance. */
  isSelected?: boolean;
  /** Render as a phantom element if overflowing. */
  visible?: boolean;
  testid?: string;
} & Pick<PageType, 'href'>;

export const NavigationItem = forwardRef<any, NavigationItemProps>(
  (
    { isSelected = false, href, visible = true, children, testid, ...props },
    ref
  ) => {
    const LinkComponent = useLinkComponent();
    const { palette, typography, spacing } = useTheme();

    const dataAttribtues = buildDataAttributes({
      testid: createTestId('navigation-item', testid),
    });

    return (
      <Box
        ref={ref}
        css={{
          alignItems: 'center',
          alignSelf: 'stretch',
          cursor: 'pointer',
          display: 'flex',
          flexShrink: 0,
          fontWeight: 600,
          lineHeight: typography.leading.base,
          position: 'relative',

          // selected indicator
          ':after': isSelected
            ? {
                background: palette.global.accent,
                borderRadius: 4,
                bottom: 0,
                content: '" "',
                height: 4,
                right: spacing.small,
                left: spacing.small,
                position: 'absolute',
              }
            : undefined,
        }}
        {...props}
      >
        <Box
          as={LinkComponent}
          href={href}
          height={40}
          paddingY="small"
          paddingX="small"
          borderRadius="small"
          css={{
            background: 0,
            border: 0,
            cursor: 'pointer',
            color: isSelected ? palette.text.base : palette.text.muted,
            fontFamily: typography.fontFamily.body,
            fontSize: typography.fontSize.medium,
            fontWeight: 600,
            lineHeight: typography.leading.base,
            position: 'relative',
            textDecoration: 'none',

            ':hover, :focus': {
              background: palette.actionButton.backgroundFocused,
            },

            ':active': {
              background: palette.actionButton.backgroundPressed,
            },
            ...ternary(!visible, { pointerEvents: 'none' }, undefined),
          }}
          tabIndex={visible ? undefined : -1}
          {...dataAttribtues}
        >
          {children}
        </Box>
      </Box>
    );
  }
);
