/* @jsx jsx */
import type { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import {
  forwardRefWithAs,
  useDefaultInteractiveStyles,
} from '@balance-web/utils';
import type { BoxProps } from '@balance-web/box';
import { Box } from '@balance-web/box';

type ItemWrapperProps = {
  children: ReactNode;
};
export const ItemWrapper = forwardRefWithAs<'div', ItemWrapperProps & BoxProps>(
  (props, ref) => {
    const defaultInteractiveStyles = useDefaultInteractiveStyles();
    return (
      <Box
        ref={ref}
        padding="small"
        borderRadius="small"
        css={defaultInteractiveStyles}
        {...props}
      />
    );
  }
);
