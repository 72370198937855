import type { ComponentType } from 'react';
import React, { useLayoutEffect } from 'react';

import { buildDataAttributes } from '../common';
import type { AppLeftNavigatorItem } from '../types';

import { PopoverMenu } from './PopoverMenu';
import { RootNavigationItem } from './RootNavigationItem';

export type AppNavigationProps = {
  name: string;
};

type AppLeftNavigatorProps = {
  items: Array<AppLeftNavigatorItem>;
  activeRouteId?: string;
  linkComponent?: ComponentType<any>;
};

export function AppLeftNavigator(props: AppLeftNavigatorProps) {
  const dataAttributes = buildDataAttributes({
    testid: 'app-navigation-leftnav',
  });

  useLayoutEffect(
    function autoExpandAndHighlightActiveRoute() {
      const activeRouteEls = document.querySelectorAll(
        "[data-activeroute='1']"
      );

      if (!activeRouteEls.length) {
        return;
      }

      // Remove all active/open attributes from current active route path
      document.querySelectorAll('.leftnav-root-item').forEach((item) => {
        item.setAttribute('data-open', '0');
        item.setAttribute('data-childactive', '0');
        item.setAttribute('data-rootiteminteractive', '0');
        item
          .querySelector('.root-item-header')
          ?.setAttribute('aria-expanded', 'false');
      });
      document.querySelectorAll('.leftnav-child-item').forEach((item) => {
        item.setAttribute('data-open', '0');
        item.setAttribute('data-childactive', '0');

        item.querySelectorAll('a, button').forEach((item) => {
          item.setAttribute('tabIndex', '-1');
        });
      });
      document
        .querySelectorAll(
          ".root-item-popover-menu .leftnav-child-item[data-haschildren='1']"
        )
        .forEach((item) => {
          item.setAttribute('data-open', '1');
        });

      for (const activeRouteEl of activeRouteEls.values()) {
        // Traverse up child items and add active/open attributes
        let childItem = activeRouteEl.closest('.leftnav-child-item');
        while (childItem !== null) {
          childItem.setAttribute('data-open', '1');
          childItem.querySelectorAll('a').forEach((item) => {
            item.removeAttribute('tabIndex');
          });
          childItem.querySelectorAll('button').forEach((item) => {
            item.removeAttribute('tabIndex');
          });
          if (childItem.getAttribute('data-haschildren') === '1') {
            childItem.setAttribute('data-childactive', '1');
          }
          childItem =
            childItem?.parentElement?.closest('.leftnav-child-item') || null;

          // Make sibling headers tabbable
          childItem
            ?.closest('.root-item-children-container')
            ?.querySelectorAll(
              ':scope > li.root-item-children > .animation-inner-wrapper > .leftnav-child-item > .child-item-header-wrapper a'
            )
            .forEach((item) => {
              item.removeAttribute('tabIndex');
            });
          childItem
            ?.closest('.root-item-children-container')
            ?.querySelectorAll(
              ':scope > li.root-item-children > .animation-inner-wrapper > .leftnav-child-item > .child-item-header-wrapper button'
            )
            .forEach((item) => {
              item.removeAttribute('tabIndex');
            });
        }

        // Root item can only be found for the active element in the left nav, there's no root items in the popover menu
        const rootItem = activeRouteEl.closest('.leftnav-root-item');

        // Add active,open attributes to parent root item
        rootItem?.setAttribute('data-open', '1');
        if (rootItem?.getAttribute('data-haschildren') === '1') {
          rootItem.setAttribute('data-childactive', '1');
        }
        rootItem?.setAttribute('data-rootiteminteractive', '1');
        rootItem
          ?.querySelector('.root-item-header')
          ?.setAttribute('aria-expanded', 'true');
      }
    },
    [props.activeRouteId]
  );

  return (
    <nav
      // Left this in as reference in case there's any bugs with the css port. Will remove later.
      // css={{
      //   display: 'flex',
      //   flexDirection: 'column',
      //   alignItems: 'stretch',
      //   paddingLeft: 12,
      //   maxHeight: `calc(100% - ${APP_HEADER_HEIGHT}px)`,
      //   width: NAV_COLLAPSED_WIDTH,
      //   transition: `width 300ms ${TRANSITION_CURVE}`,
      //   position: 'relative',

      //   /**
      //    * We control all child styling from root because we need to apply state based overrides in the correct order.
      //    *
      //    * Didn't use flex gap as it cannot be animated and cause failures in collision detection.
      //    *  Use item height or good old spacer divs.
      //    * */

      //   '&#app-navigation-leftnav': {
      //     /** Leftnav closed start */
      //     '.root-item-popover-menu': {
      //       opacity: 0,
      //       zIndex: -1,

      //       '.leftnav-child-item': {
      //         '.child-item-header': {
      //           opacity: 1,
      //           pointerEvents: 'all',
      //         },
      //         "&[data-open='1']": {
      //           '> .child-item-container > .child-item-item': {
      //             ...animatedExpandCollapseStyles.wrapperExpanded,
      //           },
      //           '> .child-item-header-wrapper > .child-item-header > .child-item-icon': {
      //             transform: 'rotate(90deg)',
      //           },
      //         },
      //         "&[data-activeroute='1']": {
      //           '> .child-item-header-wrapper > .child-item-header': {
      //             background: '#E1DAFF',
      //           },
      //         },
      //       },

      //       "&[data-openfromroot='1'], &[data-openfrommenu='1']": {
      //         opacity: 1,
      //         paddingTop: 30,
      //         zIndex: 9999,
      //       },
      //     },

      //     '.leftnav-root-item': {
      //       '.root-item-header-separator': {
      //         height: 8,
      //       },
      //       '.root-item-header': {
      //         cursor: 'pointer',
      //         '.root-item-icon': {
      //           pointerEvents: 'none',
      //           '.hover-overlay': {
      //             backgroundColor: HOVER_BG,
      //           },
      //           '.pressed-overlay': {
      //             backgroundColor: PRESSED_BG,
      //           },
      //         },
      //         ':hover': {
      //           '.root-item-icon > .hover-overlay': {
      //             opacity: 1,
      //           },
      //         },
      //         ':active': {
      //           '.root-item-icon > .hover-overlay': { opacity: 0 },
      //           '.root-item-icon > .pressed-overlay': {
      //             opacity: 1,
      //           },
      //         },
      //       },

      //       '.root-item-children': {
      //         ...animatedExpandCollapseStyles.wrapperCollapsed,
      //       },

      //       "&[data-rootiteminteractive='1']": {
      //         '.root-item-icon': {
      //           background: '#E1DAFF',

      //           '.hover-overlay': {
      //             backgroundColor: ACTIVE_HOVER_BG,
      //           },
      //           '.pressed-overlay': {
      //             backgroundColor: ACTIVE_PRESSED_BG,
      //           },
      //         },
      //       },
      //     },

      //     '.leftnav-child-item .child-item-item': {
      //       ...animatedExpandCollapseStyles.wrapperCollapsed,
      //     },
      //     /** Leftnav closed end */

      //     /** Leftnav open start */
      //     "&[data-open='1']": {
      //       width: NAV_EXPANDED_WIDTH,

      //       '.root-item-popover-menu': {
      //         opacity: 0,
      //         zIndex: -1,
      //       },

      //       '.leftnav-root-item': {
      //         /** Leftnav open + root item collapsed start */
      //         '.root-item-header': {
      //           '.root-item-chevron-container': {
      //             width: 16,
      //             opacity: 1,
      //           },

      //           '.root-item-icon': {
      //             background: 'none',
      //             '> .hover-overlay, > .pressed-overlay': {
      //               display: 'none',
      //             },
      //           },

      //           '.root-item-text': {
      //             opacity: 1,
      //             pointerEvents: 'all',
      //           },

      //           ':hover': {
      //             '.root-item-text': {
      //               cursor: 'pointer',
      //               '> .hover-overlay': {
      //                 opacity: 1,
      //               },
      //             },
      //           },
      //           ':active': {
      //             '.root-item-text': {
      //               cursor: 'pointer',
      //               '> .pressed-overlay': {
      //                 opacity: 1,
      //               },
      //             },
      //           },
      //         },
      //         /** Leftnav open + root item collapsed end */

      //         /** Leftnav open + root item expanded start */
      //         "&[data-open='1']": {
      //           '.root-item-children': {
      //             ...animatedExpandCollapseStyles.wrapperExpanded,
      //           },

      //           '.root-item-chevron': {
      //             transform: 'rotate(90deg)',
      //           },
      //         },
      //         /** Leftnav open + root item expanded end */

      //         "&[data-childactive='1']": {
      //           '> .root-item-header > .root-item-text': {
      //             background: '#f4f3f7',
      //           },
      //         },
      //         "&[data-activeroute='1']": {
      //           '> .root-item-header > .root-item-text': {
      //             background: '#E1DAFF',
      //           },
      //         },
      //       },

      //       '.leftnav-child-item': {
      //         /** Leftnav open + child item collapsed start */
      //         '.child-item-header': {
      //           opacity: 1,
      //           pointerEvents: 'all',
      //         },
      //         /** Leftnav open + child item collapsed end */

      //         /** Leftnav open + child item expanded start */
      //         "&[data-open='1']": {
      //           '> .child-item-container > .child-item-item': {
      //             ...animatedExpandCollapseStyles.wrapperExpanded,
      //           },
      //           '> .child-item-header-wrapper > .child-item-header > .child-item-icon': {
      //             transform: 'rotate(90deg)',
      //           },
      //         },
      //         "&[data-childactive='1']": {
      //           '> .child-item-header-wrapper > .child-item-header': {
      //             background: '#f4f3f7',
      //           },
      //         },
      //         "&[data-activeroute='1']": {
      //           '> .child-item-header-wrapper > .child-item-header': {
      //             background: '#E1DAFF',
      //           },
      //         },
      //         /** Leftnav open + child item expanded end */
      //       },
      //     },
      //     /** Leftnav open end */
      //   },
      // }}
      id="app-navigation-leftnav"
      data-open="0"
      {...dataAttributes}
    >
      <div
        style={{
          paddingRight: 16,
          paddingBlock: 16,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <ul
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            minWidth: MIN_ITEM_WIDTH,
          }}
        >
          {props.items.map((item) => {
            return (
              <RootNavigationItem
                key={item.routeId}
                item={item}
                activeRouteId={props.activeRouteId}
                linkComponent={props.linkComponent}
              />
            );
          })}
        </ul>
      </div>
      {props.items.map((item, i) => {
        return (
          <PopoverMenu
            key={item.routeId}
            item={item}
            index={i}
            activeRouteId={props.activeRouteId}
            linkComponent={props.linkComponent}
          />
        );
      })}
    </nav>
  );
}

export const toggleLeftNavigator = () => {
  document
    .getElementById('app-navigation-leftnav')
    ?.setAttribute(
      'data-open',
      document
        .getElementById('app-navigation-leftnav')
        ?.getAttribute('data-open') === '1'
        ? '0'
        : '1'
    );
};

const MIN_ITEM_WIDTH = 286;
