import type { BaseBook } from '../types';

export type BookMetaData<TBook extends BaseBook> = {
  firstBook: TBook | null;
  firstBookId: string | undefined;
  hasBooks: boolean;
  hasInaccessibleBook: boolean;
  hasMultipleBooks: boolean;
  hasOneBook: boolean;
  getBook: (bookId?: string) => TBook | undefined;
  isBookVisibleToUser: (bookId?: string) => boolean;
  isBookSharedWithUser: (bookId?: string) => boolean;
  hasAccessToBook: (bookId?: string) => boolean;
  hasBook: (bookId?: string) => boolean;
  isIncompleteBook: (bookId?: string) => boolean;
  isSubscriptionABook: (bookId?: string) => boolean;
  isAllowedToSetupBook: (bookId?: string) => boolean;
  isUserInSubscriptionCompany: (bookId?: string) => boolean;
  isUserTheSubscriptionCreator: (bookId?: string) => boolean;
};

export function getBookMetaData<TBook extends BaseBook>({
  user,
  books,
}: {
  user?: null | {
    id: string;
    portalCompany: null | {
      id: string;
    };
  };
  books: TBook[];
}): BookMetaData<TBook> {
  const hasMultipleBooks = books.length > 1;
  const hasOneBook = books.length === 1;
  const hasBooks = books.length > 0;

  /**
   * Does the user have access to any books that would be inaccesible?
   */
  const hasInaccessibleBook = !books.some((book) => {
    return !book.userHasAccess;
  });

  /**
   * Get the book by id
   *
   * @param bookId
   * @returns
   */
  const getBook = (bookId?: string) => {
    return books.find((book) => {
      return book.bookId === bookId;
    });
  };

  /**
   * The first book
   */
  const firstBook = hasBooks ? books[0] : null;
  const firstBookId = firstBook?.bookId;

  /**
   * Is the bookId in the list of subscriptions?
   * @param bookId
   * @returns
   */
  const hasBook = (bookId?: string) => {
    return books.some((book) => {
      return book.bookId === bookId;
    });
  };

  /**
   * Does the user have access to the book?
   * - user has been given a role in r1 book
   *
   * Do not assume this means a particular role.
   *
   * @param bookId
   * @returns
   */
  const hasAccessToBook = (bookId?: string) => {
    return !!getBook(bookId)?.userHasAccess;
  };

  /**
   * Is the subcription backed by a book
   * @param bookId
   * @returns
   */
  const isSubscriptionABook = (bookId?: string) => {
    return !!getBook(bookId)?.hasBookInR1;
  };

  /**
   * is the book setup complete?
   * @param bookId
   * @returns
   */
  const isIncompleteBook = (bookId?: string) => {
    return getBook(bookId)?.setupStatus === 'INCOMPLETE';
  };

  const isBookSharedWithUser = (bookId?: string) => {
    return !!getBook(bookId)?.isSharedWithMe;
  };

  const isBookVisibleToUser = (bookId?: string) => {
    return !!getBook(bookId)?.isVisible;
  };

  const userId = user?.id;
  const isUserTheSubscriptionCreator = (bookId?: string) => {
    const subscription = getBook(bookId);
    return subscription?.createdbyuserId === userId;
  };

  const userCompanyId = user?.portalCompany?.id;
  const isUserInSubscriptionCompany = (bookId?: string) => {
    const subscription = getBook(bookId);
    return subscription?.companyId === userCompanyId;
  };

  const isAllowedToSetupBook = (bookId?: string) => {
    const book = getBook(bookId);

    // no book means they have no access
    if (!book) {
      return false;
    }

    /**
     * Book Creation
     *  - its not created in R1 yet, and;
     * ALLOWED
     *  - They created the subscription
     * DENIED
     *  - They did not creation the subscription
     */
    if (!book?.hasBookInR1) {
      return isUserTheSubscriptionCreator(bookId);
    }

    /**
     * DENIED.
     * Book doesn't need setup
     */
    if (!isIncompleteBook(bookId)) {
      return false;
    }

    /**
     * DENIED.
     * Shared users are not allowed to setup books
     * because we don't want to risk the auxillary
     * subscriptions (govconnect at the moment) ending up
     * in accounts different from this subscriptions account.
     */
    if (book.isSharedWithMe) {
      return false;
    }

    return hasAccessToBook(bookId);
  };

  return {
    firstBook,
    firstBookId,
    hasBooks,
    hasInaccessibleBook,
    hasMultipleBooks,
    hasOneBook,
    getBook,
    hasAccessToBook,
    hasBook,
    isBookVisibleToUser,
    isBookSharedWithUser,
    isIncompleteBook,
    isSubscriptionABook,
    isAllowedToSetupBook,
    isUserInSubscriptionCompany,
    isUserTheSubscriptionCreator,
  };
}
