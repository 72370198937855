/** @jsx jsx */
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { useMemo } from 'react';
import { Box } from '@balance-web/box';
import { Text } from '@balance-web/text';

import { LARGE_ROPE_MARKER_DIMENSIONS, useColors } from './common';

type GroupItemProps = {
  title: string;
  active: boolean;
  complete: boolean;
};

export const GroupItem = (props: GroupItemProps) => {
  const theme = useTheme();
  const colors = useColors();
  const stateColor =
    props.active || props.complete ? colors.complete : colors.inactive;

  const itemTextStyles = useMemo(() => {
    const fontSize = theme.typography.fontSize.small;
    const fontWeight = props.active ? 'bold' : 'regular';

    return {
      color: stateColor,
      fontSize,
      fontWeight,
      transition: 'color 500ms ease-out',
    };
  }, [stateColor, props.active, theme.typography.fontSize.small]);

  return (
    <Box position="relative">
      <Text css={itemTextStyles}>{props.title}</Text>

      {/* Progress marker for group */}
      <div
        style={{
          boxShadow: `inset 0px 0px 0px 3px ${
            props.active || props.complete ? colors.active : colors.light
          }`,
          width: LARGE_ROPE_MARKER_DIMENSIONS,
          height: LARGE_ROPE_MARKER_DIMENSIONS,
          borderRadius: '100%',
          position: 'absolute',
          right: -11,
          top: 0,
          zIndex: 5,
          background: '#ffffff',
        }}
      />
    </Box>
  );
};
