import type { GraphQLErrors } from '@apollo/client/errors';
import { useMemo } from 'react';

export function useErrorCodes<T>(
  errors?: GraphQLErrors,
  predicate?: (code: unknown) => code is T
): Map<T, string> {
  const codes = useMemo(() => {
    return getErrorCodes(errors, predicate);
  }, [errors, predicate]);

  return codes;
}

export function getErrorCodes<T>(
  errors?: GraphQLErrors,
  predicate?: (code: unknown) => code is T
): Map<T, string> {
  const codes = new Map<T, string>();
  if (!errors || !Array.isArray(errors)) {
    return codes;
  }

  errors.forEach((error) => {
    const code = error.extensions?.code;
    const shouldInclude =
      typeof predicate === 'function' ? predicate(code) : true;
    if (shouldInclude) {
      codes.set(code, error.message);
    }
  });

  return codes;
}
