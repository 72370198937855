import React, { useCallback } from 'react';
import { Dropdown } from '@balance-web/dropdown-v2';
import { UserAvatar } from '@balance-web/avatar';
import { Text } from '@balance-web/text';
import { Inline } from '@balance-web/inline';
import { Stack } from '@balance-web/stack';
import { Box } from '@balance-web/box';
import { flattenElements, truncateText } from '@balance-web/utils';
import { Tooltip } from '@balance-web/tooltip';

import { createUserMenuDropdownTrigger } from './UserMenuDropdownTrigger';
import type { UserMenuProps } from './types';
import { createTestId } from './utils';

export const UserMenu = ({
  userFullName,
  userEmail,
  appVersion,
  items,
}: UserMenuProps) => {
  const childArray = flattenElements(items);

  const UserFullName = useCallback(() => {
    const truncatedUserFullName = truncateText(userFullName, 25);

    const userFullNameText = (
      <Text
        size="small"
        weight="medium"
        data={{
          testid: createTestId('user-menu-dropdown', 'user-full-name'),
        }}
      >
        {truncatedUserFullName}
      </Text>
    );

    if (truncatedUserFullName === userFullName) {
      return userFullNameText;
    }

    return <Tooltip content={userFullName}>{userFullNameText}</Tooltip>;
  }, [userFullName]);

  const UserEmail = useCallback(() => {
    const truncatedUserEmail = truncateText(userEmail, 30);

    const userEmailText = (
      <Text
        size="xsmall"
        color="muted"
        data={{ testid: createTestId('user-menu-dropdown', 'user-email') }}
      >
        {truncatedUserEmail}
      </Text>
    );

    if (truncatedUserEmail === userEmail) {
      return userEmailText;
    }

    return <Tooltip content={userEmail}>{userEmailText}</Tooltip>;
  }, [userEmail]);

  return (
    <Dropdown data={{ testid: createTestId('user-menu-dropdown') }}>
      <Dropdown.Trigger>
        {createUserMenuDropdownTrigger({ userName: userFullName })}
      </Dropdown.Trigger>
      <Inline gap="small" alignY="center">
        <UserAvatar name={userFullName} size="small" />
        <Stack>
          <UserFullName />
          <UserEmail />
        </Stack>
      </Inline>
      <Dropdown.Divider />
      {childArray}
      <Box paddingY="small" paddingLeft="medium">
        <Text
          size="xsmall"
          color="muted"
          data={{ testid: createTestId('user-menu-dropdown', 'app-version') }}
        >
          App version: {appVersion}
        </Text>
      </Box>
    </Dropdown>
  );
};
