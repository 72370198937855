import data from './MOCK_DATA.json';

export function getData(rows: number = 10) {
  return data.slice(0, rows) as DataRow[];
}

export type DataRow = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  income: number;
  super: number;
  tax: number;
  createdDate: string;
  dueDate: string;
  duration: number;
  fakeCompany: string;
  company: string;
  status: 'Active' | 'Danger' | 'Draft' | 'Pending' | 'Success' | 'Warning';
  notes: string;
};
