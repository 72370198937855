/** @jsx jsx */
import PrismHighlight, { Prism, PrismTheme } from 'prism-react-renderer';
// @ts-ignore
import rangeParser from 'parse-numeric-range';
import { jsx } from '@balance-web/core';

type Props = { code: string; language: string; metastring?: string };

['js', 'jsx', 'ts', 'tsx'].forEach((lang) => {
  // @ts-ignore
  Prism.languages.insertBefore(lang, 'template-string', {
    'gql-template-string': {
      pattern: /gql`[^`]*`/,
      inside: Prism.languages.graphql,
    },
  });
});

const getShouldHighlightLine = (meta: string = '') => {
  const pattern = /{([\d,-]+)}/;
  let result = pattern.exec(meta);
  if (result) {
    const lineNumbers = rangeParser(result[1]);
    return (index: number) => lineNumbers.includes(index + 1);
  } else {
    return () => false;
  }
};

export const Highlight = ({ code, language, metastring }: Props) => {
  let shouldHighlightLine = getShouldHighlightLine(metastring);
  return (
    <PrismHighlight
      Prism={Prism}
      theme={theme}
      code={code}
      language={language as any}
    >
      {({ tokens, getLineProps, getTokenProps }) => (
        <code
          css={{
            // we don't want ligatures because they look strange in lots of cases
            // and mess up the editor
            fontFeatureSettings: 'initial',
            MozFontFeatureSettings: 'initial',
            minWidth: '100%',
          }}
        >
          {tokens.map((line, i) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div
                {...getLineProps({ line, key: i })}
                css={{
                  backgroundColor: shouldHighlightLine(i)
                    ? 'rgba(0,0,0,0.05)'
                    : undefined,
                }}
              >
                {line.map((token, key) => (
                  // eslint-disable-next-line react/jsx-key
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            );
          })}
        </code>
      )}
    </PrismHighlight>
  );
};

const theme: PrismTheme = {
  plain: {},
  styles: [
    {
      types: ['cdata', 'comment', 'doctype', 'prolog'],
      style: {
        color: '#959daa',
      },
    },
    {
      types: ['punctuation'],
      style: {
        color: '#5a6270',
      },
    },
    {
      types: [
        'boolean',
        'constant',
        'deleted',
        'number',
        'property',
        'symbol',
        'tag',
      ],
      style: {
        color: '#f25cc1',
      },
    },
    {
      types: ['attr-name', 'builtin', 'char', 'inserted', 'selector', 'string'],
      style: {
        color: '#26a29d',
      },
    },
    {
      types: ['atrule', 'attr-value', 'keyword'],
      style: {
        color: '#6554C0',
      },
    },
    {
      types: ['class-name', 'function'],
      style: {
        color: '#f25cc1',
      },
    },
    {
      types: ['important', 'regex', 'variable'],
      style: {
        color: '#f4d03f',
      },
    },
    {
      types: ['bold', 'important'],
      style: {
        fontWeight: 'bold',
      },
    },
    {
      types: ['italic'],
      style: {
        fontStyle: 'italic',
      },
    },
    {
      types: ['entity'],
      style: {
        cursor: 'help',
      },
    },
    {
      types: ['namespace'],
      style: {
        opacity: 0.7,
      },
    },
  ],
};
