import React, { ReactNode } from 'react';
import { Stack } from '@balance-web/stack';

import { AppHeader } from './AppHeader';

export type AppLayoutProps = {
  children: ReactNode;
};

export const AppLayout = ({ children }: AppLayoutProps) => {
  return (
    <Stack background="base" minHeight="100vh">
      {children}
    </Stack>
  );
};

AppLayout.Header = AppHeader;
