import * as React from 'react';

import { SidebarContext } from './SidebarContext';

export const useSidebar = () => {
  const sidebar = React.useContext(SidebarContext);
  if (!sidebar) {
    throw new Error('Component must be used within the `SidebarProvider`.');
  }

  return sidebar;
};
