import * as React from 'react';

import { AuthProvider } from './AuthProvider';
import type { AuthProviderProps } from './AuthProvider';
import { UrlFragmentAuthManager } from './UrlFragmentAuthManager';
import { AuthProviderIsLoading } from './AuthProviderIsLoading';

export const UrlFragmentAuthProvider = ({
  children,
  ...props
}: AuthProviderProps) => (
  <AuthProvider {...props}>
    <UrlFragmentAuthManager>{children}</UrlFragmentAuthManager>
  </AuthProvider>
);

UrlFragmentAuthProvider.IsLoading = AuthProviderIsLoading;
