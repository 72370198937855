import React from 'react';
import { useEffect } from 'react';

import { LoadIntercomIoService } from './LoadIntercomIoService';
import type { LoadIntercomIoServiceProps } from './LoadIntercomIoService';

export type IntercomIoServiceProps = LoadIntercomIoServiceProps;

export const IntercomIoService = (props: IntercomIoServiceProps) => {
  useEffect(() => {
    LoadIntercomIoService(props);
  }, [props]);

  return <></>;
};
