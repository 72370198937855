import React, { Fragment, ReactNode } from 'react';
import { Divider } from '@balance-web/divider';
import { Flex } from '@balance-web/flex';

export const Header = ({ children }: { children: ReactNode }) => {
  return (
    <Fragment>
      <Flex
        width="100%"
        paddingX="xlarge"
        paddingY="large"
        justifyContent="space-between"
        alignItems="center"
      >
        {children}
      </Flex>
      <Divider />
    </Fragment>
  );
};
