import { every, includes, some } from 'lodash';

import type { NestedPage } from './types';

export const isPageSelected = (
  page: NestedPage,
  selectedPage: string
): boolean => {
  // TODO: RWR-4348
  if (!page.options) {
    return (
      some(page.match, (m) => includes(selectedPage, m)) &&
      every(page.exclude, (e) => !includes(selectedPage, e))
    );
  }

  return !!page.options.reduce((acc, page) => {
    return acc || isPageSelected(page, selectedPage);
  }, false);
};
