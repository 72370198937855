import type { Field, Form, FormValue } from '@magical-forms/react-next';

import { areFieldsEqual } from './areFieldsEqual';

export function isFormValueEqualToValue<TField extends Field>(
  form: Form<TField>,
  value: FormValue<TField>
) {
  return areFieldsEqual(form._field, form.value, value);
}
