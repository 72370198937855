import { Field } from '@magical-forms/react-next';
import isEqual from 'lodash/isEqual';
export function areFieldsEqual(
  field: Field,
  valueA: any,
  valueB: any
): boolean {
  return isEqual(valueA, valueB);
  // TODO: remove if isEqual is working
  // if (field.kind === 'scalar') {
  //   return valueA === valueB;
  // }

  // if (field.kind === 'array') {
  //   return (
  //     Array.isArray(valueA) &&
  //     Array.isArray(valueB) &&
  //     valueA.length === valueB.length &&
  //     (valueA as any[]).every((value, index) =>
  //       areFieldsEqual(field.element, value, valueB[index])
  //     )
  //   );
  // }

  // return (
  //   isPlainObject(valueA) &&
  //   isPlainObject(valueB) &&
  //   Object.keys(field.fields).every((key) =>
  //     areFieldsEqual(field.fields[key], valueA[key], valueB[key])
  //   )
  // );
}
