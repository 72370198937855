import { createContext, useContext } from 'react';

export type SidebarNavigationContextType = {
  selectedNavigationKey?: string;
  defaultExpandedGroupKey?: string;
};

export const SidebarNavigationContext = createContext<
  SidebarNavigationContextType | undefined
>(undefined);

export const useSidebarNavigationContext = () => {
  const context = useContext(SidebarNavigationContext);

  if (!context)
    throw Error(
      'SidebarNavigationItem and SidebarNavigationGroup must be used inside SidebarNavigation.'
    );

  return context;
};
