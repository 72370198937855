import { useTheme } from '@balance-web/theme';

export const LARGE_ROPE_MARKER_DIMENSIONS = 20;
export const SMALL_ROPE_MARKER_DIMENSIONS = 8;

export const ROPE_ITEM_HEIGHT = 19.6;
export const CHILD_ROPE_ITEM_HEIGHT = 16.8;

export const PROGRESS_ROPE_ZINDEX = 2;
export const PROGRESS_ROPE_MARKER_ZINDEX = PROGRESS_ROPE_ZINDEX + 1;

export const useColors = () => {
  const theme = useTheme();

  return {
    active: theme.colors.primary,
    inactive: theme.primitives.colour.neutral.k40,
    complete: theme.colors.text,
    light: theme.primitives.colour.neutral.k90,
  };
};
