/** @jsx jsx */

import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

import { Base } from './Base';
import { SizeType } from './types';
import { getKeyFromText, useInitials } from './utils';

export type UserAvatarProps = {
  /** Does not support css. */
  css?: never;
  /** @deprecated use "inactive" prop. */
  disabled?: boolean;
  /** Signal to the user that this avatar is in some way not available. */
  inactive?: boolean;
  /** Signal to the user that this avatar is less significant e.g "guest user"  */
  guest?: boolean;
  /** The name of the person represented by the avatar. */
  name: string;
  /** The size of the avatar. */
  size?: SizeType;
};

export const UserAvatar = ({
  name,
  size = 'medium',
  inactive,
  disabled,
  ...props
}: UserAvatarProps) => {
  const { palette } = useTheme();
  const color = getKeyFromText(palette.decorative, name);
  const initials = useInitials({ name, max: 2, size });

  /** Support the deprecated 'disabled' prop */
  const backwardsCompatibleInactive = inactive || disabled;

  return (
    <Base
      color={color}
      shape="round"
      size={size}
      {...props}
      inactive={backwardsCompatibleInactive}
    >
      {initials}
    </Base>
  );
};
