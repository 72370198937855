import { useEffect, useRef } from 'react';
import type { ReactNode } from 'react';
import { flattenElements } from '@balance-web/utils';

import { CheckItem } from './CheckItem';
import { RadioItem } from './RadioItem';

type useHideItemBorderOnHoverProps = {
  children: ReactNode;
};

/**
 * This hook adds/removes the data-no-border attribute on interactive elements to sync hover states between separators and items.
 */
export const useHideItemBorderOnHover = ({
  children,
}: useHideItemBorderOnHoverProps) => {
  const childArray = flattenElements(children);
  const childRefs = useRef<Array<HTMLElement | null>>([]);

  /* Hides border of the list item and the item above on hover */
  useEffect(() => {
    const localChildRefs = [...childRefs.current];

    const mouseEnterEventListeners = localChildRefs.map(
      (child, index) => () => {
        child?.addEventListener('mouseenter', () => {
          const interactive =
            !!childArray[index].props['href'] ||
            !!childArray[index].props['onClick'] ||
            childArray[index].type === CheckItem ||
            childArray[index].type === RadioItem;

          const isItemDisabled = childArray[index].props['disabled'];

          if (!interactive || isItemDisabled) return;

          localChildRefs[index]?.setAttribute('data-no-border', '1');
          localChildRefs[Math.max(index - 1, 0)]?.setAttribute(
            'data-no-border',
            '1'
          );
        });
      }
    );

    const mouseLeaveEventListeners = localChildRefs.map(
      (child, index) => () => {
        child?.addEventListener('mouseleave', () => {
          const interactive =
            !!childArray[index].props['href'] ||
            !!childArray[index].props['onClick'] ||
            childArray[index].props['selected'] !== 'undefined';

          if (!interactive) return;

          localChildRefs.forEach((allChild) => {
            allChild?.removeAttribute('data-no-border');
          });
        });
      }
    );

    localChildRefs.forEach((_, index) => {
      mouseEnterEventListeners[index]();
      mouseLeaveEventListeners[index]();
    });

    return () => {
      localChildRefs.forEach((child, index) => {
        child?.removeEventListener(
          'mouseenter',
          mouseEnterEventListeners[index]
        );
        child?.removeEventListener(
          'mouseleave',
          mouseLeaveEventListeners[index]
        );
      });
    };
  }, [childArray]);

  return childRefs;
};
