import React from 'react';
import { Trigger } from '@radix-ui/react-collapsible';
import { IconButton } from '@balance-web/button';
import { ChevronDownIcon, ChevronUpIcon } from '@balance-web/icon';

type CollapsableTriggerProps = {
  open: boolean;
};

export const CollapsableTrigger = ({ open }: CollapsableTriggerProps) => {
  const ChevronIcon = open ? ChevronUpIcon : ChevronDownIcon;

  return (
    <Trigger asChild>
      <IconButton
        label="Toggle"
        icon={ChevronIcon}
        size="small"
        variant="text"
      />
    </Trigger>
  );
};
