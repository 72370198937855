import { createContext } from 'react';
import type { Dispatch } from 'react';

type BookIdContextShape = {
  bookId: string;
  setBookId: Dispatch<string>;
};
export const BookIdContext = createContext<BookIdContextShape>({
  bookId: '',
  setBookId: () => {},
});
