type OpenCollegesBook<T> = T & {
  isStpNonLodging: boolean;
};

export function isBookOpenColleges<T extends Record<string, unknown>>(
  book?: T
): book is OpenCollegesBook<T> {
  if (!book) {
    return false;
  }

  return (
    book &&
    'isStpNonLodging' in book &&
    typeof book.isStpNonLodging === 'boolean'
  );
}
