/** @jsx jsx */
import React, { HTMLAttributes, forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { Box } from '@balance-web/box';
import { useTheme } from '@balance-web/theme';

import { GUTTER } from './constants';

type SegmentedControlWrapperProps = { block: boolean } & HTMLAttributes<
  HTMLDivElement
>;
export const Wrapper = forwardRef<HTMLDivElement, SegmentedControlWrapperProps>(
  ({ block, ...props }, ref) => {
    const { palette, typography, radii, sizing } = useTheme();

    return (
      <Box
        ref={ref}
        css={{
          background: palette.segmentedControl.track,
          backgroundClip: 'padding-box',
          borderRadius: radii.small,
          boxSizing: 'border-box',
          cursor: 'pointer',
          display: block ? 'flex' : 'inline-flex',
          flex: block ? 1 : undefined,
          fontSize: typography.fontSize.small,
          fontWeight: typography.fontWeight.medium,
          height: sizing.small,
          outline: 0,
          padding: GUTTER,
          position: 'relative',
        }}
        {...props}
      />
    );
  }
);
Wrapper.displayName = 'SegmentedControlWrapper';
