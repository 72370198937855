import { Observable } from '@apollo/client';

export function promiseToObservable<Value>(promise: Promise<Value>) {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return;
        }

        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });
}
