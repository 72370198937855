import uniqueId from 'lodash/uniqueId';

type LoadScriptProps = {
  id?: string;
  parent: 'head' | 'body';
  defer?: boolean;
  async?: boolean;
} & ({ src: string } | { content: string });

function createScript({
  src,
  contents,
  defer,
  async,
}: {
  src?: string;
  contents?: string;
  defer?: boolean;
  async?: boolean;
}) {
  if (src) {
    const script = document.createElement('script');
    script.setAttribute('src', src);

    if (defer) {
      script.setAttribute('defer', 'true');
    }

    if (async) {
      script.setAttribute('async', 'true');
    }

    return script;
  }

  const script = document
    .createRange()
    .createContextualFragment(`<script>${contents}</script>`)
    .querySelector('script');

  if (!script) {
    throw new Error('Failed to create script element');
  }

  return script;
}

export function LoadScript({
  id,
  parent = 'head',
  defer,
  async,
  ...props
}: LoadScriptProps) {
  return new Promise<Event>((resolve, reject) => {
    const identifier = id || uniqueId('script');

    if (typeof window === 'undefined') {
      return Promise.resolve();
    }

    if (typeof document === 'undefined') {
      return Promise.resolve();
    }

    if (document.querySelector(`script[id="${identifier}"]`)) {
      return Promise.resolve();
    }

    try {
      const s = createScript({
        src: 'src' in props ? props.src : '',
        contents: 'content' in props ? props.content : '',
        defer,
        async,
      });

      s.setAttribute('id', identifier);

      s.addEventListener('load', (event) => {
        return resolve(event);
      });
      s.addEventListener('error', reject);

      if (parent === 'head' && document.head) {
        document.head.appendChild(s);
      }
      if (parent === 'body' && document.body) {
        document.body.appendChild(s);
      }
    } catch (error) {
      reject(error);
    }
  });
}
