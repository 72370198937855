/** @jsx jsx */

import {
  CheckboxPrimitive,
  CheckboxPrimitiveProps,
} from '@balance-web/checkbox';
import { jsx } from '@balance-web/core';
import { useEffect, useRef } from 'react';

import { pickSafeProps } from '../utils';

import { TableCellProps, useCellStyles } from './TableCell';

const validCheckboxKeys = ['checked', 'disabled', 'name', 'onChange'] as const;

// TODO: pretty handy, move elsewhere?
type Keys<T extends ReadonlyArray<string>> = T[number];

export type TableSelectionCellProps = { 'aria-label': string } & Pick<
  TableCellProps,
  'aria-colindex' | 'id' | 'role' | 'stickyOffset'
> &
  Pick<CheckboxPrimitiveProps, Keys<typeof validCheckboxKeys>>;

export function TableSelectionCell({
  id,
  role = 'gridcell',
  ...props
}: TableSelectionCellProps) {
  const { dataAttributes, css, style } = useCellStyles(props);
  const checkboxProps = pickSafeProps(props, ...validCheckboxKeys);
  const { 'aria-colindex': ariaColIndex, 'aria-label': ariaLabel } = props;
  const cellRootRef = useRef<HTMLDivElement | null>(null);

  /**
   * Add/remove aria-selected to row based on user input to correctly style the row.
   */
  useEffect(() => {
    if (!cellRootRef.current) {
      return;
    }
    cellRootRef.current
      .closest(`[role="row"]`)
      ?.setAttribute('aria-selected', checkboxProps.checked ? 'true' : 'false');
  }, [checkboxProps.checked]);

  return (
    <div
      ref={cellRootRef}
      id={id}
      role={role}
      css={css}
      style={style}
      aria-colindex={ariaColIndex}
      // NOTE: special data-attribute avoids confusing consumer props on `TableCell`
      data-interaction="select"
      {...dataAttributes}
    >
      <label
        css={{
          ':before': {
            content: '""',
            cursor: checkboxProps.disabled ? 'auto' : 'pointer',
            position: 'absolute',
            inset: 0,
          },
        }}
      >
        <CheckboxPrimitive
          aria-label={ariaLabel}
          size="small"
          {...checkboxProps}
        />
      </label>
    </div>
  );
}
