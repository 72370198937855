import { createContext, useContext } from 'react';

import { TileStackContextType } from './types';

export const TileStackContext = createContext<TileStackContextType | null>(
  null
);

export const TileStackContextProvider = TileStackContext.Provider;

export const useTileStackContext = () => {
  return useContext(TileStackContext);
};

const tileContextDefaultValue = {
  hover: false,
};

export const TileContext = createContext(tileContextDefaultValue);

export const TileContextProvider = TileContext.Provider;

export const useTileContext = () => {
  const context = useContext(TileContext);

  if (!context) throw Error('Cannot find Tile context');

  return context;
};
