import { isSSR } from '@reckon-web/next-is-ssr';

export function hideIntercom() {
  if (typeof window === 'undefined') {
    return;
  }
  if (!('onIntercomReady' in window)) {
    return;
  }
  try {
    window.onIntercomReady((intercom) => {
      intercom('hide');
    });
  } catch (error) {
    console.error('[INTERCOM ERROR] problem hiding');
  }
}

export function showIntercom() {
  if (typeof window === 'undefined') {
    return;
  }
  if (!('onIntercomReady' in window)) {
    return;
  }
  try {
    window.onIntercomReady((intercom) => {
      intercom('show');
    });
  } catch (error) {
    console.error('[INTERCOM ERROR] problem showing');
  }
}

export function showIntercomMessages() {
  if (typeof window === 'undefined') {
    return;
  }
  if (!('onIntercomReady' in window)) {
    return;
  }
  try {
    window.onIntercomReady((intercom) => {
      intercom('showMessages');
    });
  } catch (error) {
    console.error('[INTERCOM ERROR] problem showing message');
  }
}

export function updateIntercom(config: Intercom_.IntercomSettings) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!('onIntercomReady' in window)) {
    return;
  }
  try {
    window.onIntercomReady((intercom) => {
      intercom('update', config);
    });
  } catch (error) {
    console.error('[INTERCOM ERROR] problem updating intercom', config);
  }
}

export function bootIntercom(config: Intercom_.IntercomSettings) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!('onIntercomReady' in window)) {
    return;
  }
  try {
    window.onIntercomReady((intercom) => {
      intercom('boot', config);
    });
  } catch (error) {
    console.error('[INTERCOM ERROR] problem booting intercom', config);
  }
}

export function trackIntercomEvent(tag: string, metadata: object) {
  if (typeof window === 'undefined') {
    return;
  }
  if (!('onIntercomReady' in window)) {
    return;
  }
  try {
    window.onIntercomReady((intercom) => {
      intercom('trackEvent', tag, metadata);
    });
  } catch (error) {
    console.error(
      '[INTERCOM ERROR] problem tracking intercom event',
      tag,
      metadata
    );
  }
}

export function shutdownIntercom() {
  if (typeof window === 'undefined') {
    return;
  }
  if (!('onIntercomReady' in window)) {
    return;
  }
  try {
    window.onIntercomReady((intercom) => {
      intercom('shutdown');
    });
  } catch (error) {
    console.error('[INTERCOM ERROR] problem shutting intercom down');
  }
}

export function configureIntercomIo(settings: Intercom_.IntercomSettings) {
  if (isSSR()) {
    return;
  }
  window.intercomSettings = settings;
}
