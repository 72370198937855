import { useState } from 'react';
import debounce from 'lodash/debounce';
import useWindowEventListener from '@rooks/use-window-event-listener';

import { useIsomorphicLayoutEffect } from '@reckon-web/use-isomorphic-layout-effect';

const initialWindowSizeState = {
  width: 0,
  height: 0,
  previousWidth: 0,
  previousHeight: 0,
};

export type UseWindowSizeState = typeof initialWindowSizeState;

export const useWindowSize = (debounceMs = 150) => {
  const [windowSize, setWindowSize] = useState<UseWindowSizeState>(
    initialWindowSizeState
  );

  const handleResize = debounce(() => {
    setWindowSize((size) => ({
      width: window.innerWidth,
      height: window.innerHeight,
      previousWidth: size.width,
      previousHeight: size.height,
    }));
  }, debounceMs);

  useWindowEventListener('resize', handleResize);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleResize();
  }, []);

  return windowSize;
};
