import isDateAfter from 'date-fns/isAfter';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  formatDistanceToNow,
} from 'date-fns';

import type { ISODate } from '@reckon-web/use-dates';

import { UseDate } from '../core/Date';
import { EXPIRY_WARNING_WINDOW_DAYS } from '../constants';

type UseBookSubscriptionExpiryDatesProps = {
  expiry: string | ISODate | undefined | Date;
  comparedTo?: Date;
};

export function getBookSubscriptionExpiryDates({
  expiry,
  comparedTo = new Date(),
}: UseBookSubscriptionExpiryDatesProps) {
  const { parsedISODate } = UseDate();

  const expiryDate =
    expiry instanceof Date
      ? expiry
      : (expiry && parsedISODate(expiry)) || undefined;

  const hasExpiryDate = !!expiryDate;

  const daysTillExpiry =
    (!!expiryDate && differenceInDays(expiryDate, comparedTo)) || 0;
  const daysTillExpirySeconds =
    (!!expiryDate && differenceInSeconds(expiryDate, comparedTo)) || 0;
  const daysTillExpiryMinutes =
    (!!expiryDate && differenceInMinutes(expiryDate, comparedTo)) || 0;
  const daysTillExpiryHours =
    (!!expiryDate && differenceInHours(expiryDate, comparedTo)) || 0;

  const isAboutToExpire =
    daysTillExpiry > 0 && daysTillExpiry <= EXPIRY_WARNING_WINDOW_DAYS;

  const hasExpired =
    !!expiryDate &&
    isDateAfter(comparedTo, expiryDate) &&
    differenceInHours(expiryDate, comparedTo) <= 0;

  const humanisedExpirationDistance =
    (isAboutToExpire && expiryDate && formatDistanceToNow(expiryDate)) ||
    undefined;

  return {
    expiryDate,
    hasExpiryDate,
    hasExpired,
    isAboutToExpire,
    daysTillExpiry,
    daysTillExpiryMinutes,
    daysTillExpirySeconds,
    daysTillExpiryHours,
    humanisedExpirationDistance,
  };
}
