/* eslint-disable jsx-a11y/accessible-emoji */
/** @jsx jsx */

import type { ComponentType, ReactNode } from 'react';
import { useMemo } from 'react';
import { TextLinkButton } from '@balance-web/text-link';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import type { IconProps } from '@balance-web/icon';
import { AlertOctagonIcon } from '@balance-web/icon/icons/AlertOctagonIcon';
import { AlertTriangleIcon } from '@balance-web/icon/icons/AlertTriangleIcon';
import { CheckCircleIcon } from '@balance-web/icon/icons/CheckCircleIcon';
import { ExternalLinkIcon } from '@balance-web/icon/icons/ExternalLinkIcon';
import { InfoIcon } from '@balance-web/icon/icons/InfoIcon';
import { Stack } from '@balance-web/stack';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';

type Action = { action: () => void; label: string; externalLink?: boolean };

type Tones = 'informative' | 'cautious' | 'positive' | 'critical';

export type NoticeProps = {
  actions?: {
    primary?: Action;
    secondary?: Action;
  };
  children: ReactNode;
  size?: keyof typeof sizeToPadding;
  tone?: Tones;
  title?: string;
  /**
   * Replace the default icon.
   *
   * The default icons are appropriate for the "tone" and provide context for
   * users that aren't able to discern intent from colour alone. For continuity
   * within our products we recommend that this is rarely used.
   */
  icon?: ComponentType<IconProps>;
};

export const Notice = ({
  actions,
  children,
  size = 'medium',
  tone = 'informative',
  title,
  icon,
}: NoticeProps) => {
  const theme = useTheme();

  const Icon = icon || toneToIcon[tone];
  const padding = sizeToPadding[size];
  const iconWrapperHeightHack =
    size === 'small'
      ? `calc(${theme.typography.fontSize.small} * ${theme.typography.leading.base})`
      : undefined;

  const toneToBackgroundMap = useMemo(() => {
    return {
      informative: {
        background: theme.primitives.colour.reserved.info.k90,
        color: theme.primitives.colour.reserved.info.k10,
      },
      critical: {
        background: theme.primitives.colour.reserved.critical.k90,
        color: theme.primitives.colour.reserved.critical.k10,
      },
      positive: {
        background: theme.primitives.colour.reserved.success.k90,
        color: theme.primitives.colour.reserved.success.k10,
      },
      cautious: {
        background: theme.primitives.colour.reserved.warning.k90,
        color: theme.primitives.colour.reserved.warning.k10,
      },
    } as const;
  }, [theme.primitives]);

  return (
    <Flex
      role="alert"
      aria-live="polite"
      // styles
      alignItems="flex-start"
      border={tone}
      borderRadius={size}
      gap={size}
      paddingX={padding.x}
      paddingY={padding.y}
      css={{
        background: toneToBackgroundMap[tone].background,
      }}
    >
      <Flex height={iconWrapperHeightHack} alignItems="center">
        <Icon
          size={size}
          css={{
            stroke: toneToBackgroundMap[tone].color,
          }}
        />
      </Flex>

      {/*
       * NOTE: `alignSelf="center"` is a little trick to center-align single
       * lines of text, while allowing text that wraps to be top-aligned,
       * relative to the icon.
       */}
      <Stack gap="small" flex={1} alignSelf="center">
        {title && <Text weight="bold">{title}</Text>}

        <Stack gap="medium">
          <Text size="small">{children}</Text>

          {actions && (
            <Flex gap="large">
              {actions.primary && (
                <Text size="small" weight="bold">
                  <TextLinkButton onClick={actions.primary.action} color="base">
                    {actions.primary.label}
                    {actions.primary.externalLink ? (
                      <ExternalLinkIcon size="xsmall" />
                    ) : null}
                  </TextLinkButton>
                </Text>
              )}
              {actions.secondary && (
                <Text size="small" weight="bold">
                  <TextLinkButton
                    onClick={actions.secondary.action}
                    color="base"
                  >
                    {actions.secondary.label}
                    {actions.secondary.externalLink ? (
                      <ExternalLinkIcon size="xsmall" />
                    ) : null}
                  </TextLinkButton>
                </Text>
              )}
            </Flex>
          )}
        </Stack>
      </Stack>
    </Flex>
  );
};

// Maps
// ------------------------------

const toneToIcon = {
  informative: InfoIcon,
  critical: AlertOctagonIcon,
  positive: CheckCircleIcon,
  cautious: AlertTriangleIcon,
};

const sizeToPadding = {
  small: { x: 'medium', y: 'small' },
  medium: { x: 'large', y: 'large' },
} as const;
