import React from 'react';

export const DollarSignIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      style={{
        verticalAlign: 'text-bottom', // removes whitespace inside buttons
        stroke: '#52535C',
        fill: 'none',
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
        strokeWidth: 2,
        height: 24,
        width: 24,
      }}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <React.Fragment>
        <line x1={12} y1={1} x2={12} y2={23} />
        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
      </React.Fragment>
    </svg>
  );
};
