import { useContext } from 'react';

import type { BaseBook } from '../types';

import { BookSwitcherMetaContext } from './BookSwitcherMetaContext';
import type { BookMetaData } from './getBookMetaData';

export function useBookSwitcherMeta<T extends BaseBook>() {
  const context = useContext(BookSwitcherMetaContext);
  if (!context) {
    throw new Error(
      'useBookSwitcherMeta must be used within a BookSwitcherMetaProvider'
    );
  }
  return context as BookMetaData<T>;
}
