import { isSSR } from '@reckon-web/next-is-ssr';

export const resetHeapIOIdentity = () => {
  if (isSSR() || !window.heap) return;

  try {
    window.heap.resetIdentity();
  } catch (error) {
    console.error('[HEAPO.IO ERROR] reset identity error: ', error);
  }
};

export function setHeapIOUserProperties(payload: Record<string, any>) {
  if (isSSR() || !window.heap) return;
  try {
    window.heap.addUserProperties(payload);
  } catch (error) {
    console.error('[HEAPO.IO ERROR] set user properties error: ', error);
  }
}

export function setHeapIOIdentity(userId: string) {
  if (isSSR() || !window.heap) return;
  try {
    resetHeapIOIdentity();
    window.heap.identify(userId);
  } catch (error) {
    console.error('[HEAPO.IO ERROR] set identity error: ', error);
  }
}
