import { ApolloLink, Observable } from '@apollo/client';

export const OperationsListLink = (args: {
  onOperationsChange?: (operations: string[]) => void;
  onOperationStart?: (operation: string) => void;
  onOperationFinish?: (operations: string) => void;
}) => {
  return new ApolloLink((operation, forward) => {
    if (args.onOperationStart) {
      args.onOperationStart(operation.operationName);
    }

    return new Observable((observer) => {
      const subscription = forward(operation).subscribe({
        next: (result) => {
          if (args.onOperationFinish) {
            args.onOperationFinish(operation.operationName);
          }

          observer.next(result);
        },
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  });
};
