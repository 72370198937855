/** @jsx jsx */

import { ReactElement } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { forwardRefWithAs } from '@balance-web/utils';

export type AspectRatioProps = {
  /** The element  */
  children: ReactElement;
  /** The aspect ratio of the box. Common values are: `1`, `16/9`, `4/3` */
  ratio: number;
};

/**
 * The aspect ratio primitive is used to embed responsive media, like images and
 * videos.
 */
export const AspectRatio = forwardRefWithAs<'div', AspectRatioProps>(
  ({ ratio, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        css={{
          position: 'relative',

          ':before': {
            content: '""',
            height: 0,
            display: 'block',
            paddingTop: `${(1 / ratio) * 100}%`,
          },

          '& > *': {
            bottom: 0,
            height: '100%',
            left: 0,
            overflow: 'hidden',
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100%',
          },

          '& > img, & > video': {
            objectFit: 'cover',
          },
        }}
        {...props}
      />
    );
  }
);
