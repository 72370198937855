import React from 'react';
import { VisuallyHidden } from '@balance-web/a11y';
import { makeId, useId } from '@balance-web/utils';

import { CheckboxGroupProps } from './types';
import { CheckboxGroupContext } from './context';
import { useCheckboxGroupState } from './useCheckboxGroupState';
export const CheckboxGroup = ({
  legend,
  children,
  ...props
}: CheckboxGroupProps) => {
  const context = useCheckboxGroupState(props);
  const instanceId = useId();
  const legendId = makeId(instanceId, 'checkbox-group-legend');

  return (
    <CheckboxGroupContext.Provider value={context}>
      <fieldset aria-labelledby={legendId} style={{ display: 'contents' }}>
        {children}
      </fieldset>
      <VisuallyHidden id={legendId}>{legend}</VisuallyHidden>
    </CheckboxGroupContext.Provider>
  );
};
