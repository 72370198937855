/** @jsx jsx */

import { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { forwardRefWithAs, useId } from '@balance-web/utils';

import { TrayBase } from './TrayBase';

const SIZES = {
  small: 480,
  medium: 620,
  large: 1140,
  full: '100%',
} as const;

type TrayProps = {
  children: ReactNode;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  size: keyof typeof SIZES;
};

/** @deprecated The Tray package has been deprecated. Use the BottomDrawer package instead. */
export const Tray = forwardRefWithAs<'div', TrayProps>(
  ({ id, size = 'full', ...props }, ref) => {
    const instanceId = useId(id);
    const width = SIZES[size];

    return <TrayBase id={instanceId} width={width} ref={ref} {...props} />;
  }
);
