import { FormEvent, useEffect, useState } from 'react';
import { Field, Form, ValidatedFormValue } from '@magical-forms/react-next';
type FieldFromFieldInstance<TForm extends Form<Field>> = TForm['_field'];

function getTouchedState(field: Field, state: any): any {
  if (field.kind === 'object') {
    let obj: any = {};
    for (let key in field.fields) {
      obj[key] = getTouchedState(field.fields[key], state[key]);
    }
    return obj;
  }
  if (field.kind === 'array') {
    return (state as any[]).map((element) =>
      getTouchedState(field.element, element)
    );
  }
  if (state.touched) {
    return state;
  }
  return { ...state, touched: true };
}

export function useSubmit<TForm extends Form<Field>>(
  form: TForm,
  onSubmit: (form: ValidatedFormValue<FieldFromFieldInstance<TForm>>) => void,
  options?: {
    disableScrollToFirstError?: boolean;
  }
) {
  const [submission, setSubmission] = useState<
    { target?: HTMLElement } | undefined
  >();

  useEffect(() => {
    const domNode = submission?.target
      ?.closest('form')
      ?.querySelector(`[aria-invalid=true]`);

    if (typeof domNode?.scrollIntoView === 'function') {
      domNode.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }, [submission]);

  return (event?: FormEvent<HTMLFormElement>) => {
    if (event) {
      if (event.defaultPrevented) {
        return;
      }
      event.preventDefault();
    }
    if (form.validity === 'valid') {
      onSubmit(form.value);
    } else {
      form.setState(getTouchedState(form._field, form.state));
      if (!options?.disableScrollToFirstError) {
        setSubmission({ target: event?.currentTarget });
      }
    }
  };
}
