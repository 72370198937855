/* @jsx jsx */
import { jsx } from '@balance-web/core';
import type { CustomTriggerProps } from '@balance-web/dropdown-v2';
import { ChevronDownIcon, ChevronUpIcon } from '@balance-web/icon';
import { useTheme } from '@balance-web/theme';
import { ternary, useDefaultInteractiveStyles } from '@balance-web/utils';
import { Text } from '@balance-web/text';
import { Flex } from '@balance-web/flex';

export type CreateDropdownNavigationTriggerProps = {
  label: string;
  isSelected: boolean;
  visible?: boolean;
};
export const createDropdownNavigationTrigger = ({
  label,
  isSelected,
  visible = true,
}: CreateDropdownNavigationTriggerProps) => {
  return ({ isOpen, triggerProps }: CustomTriggerProps) => {
    const { palette, typography, spacing } = useTheme();
    const defaultInteractiveStyles = useDefaultInteractiveStyles();
    const CheveronIcon = isOpen ? ChevronUpIcon : ChevronDownIcon;

    return (
      <Flex
        height="100%"
        alignSelf="center"
        alignItems="center"
        id={'menu-id-' + label}
        css={{
          position: 'relative',
          lineHeight: typography.leading.base,
          ':after': ternary(
            isSelected,
            {
              background: palette.global.accent,
              borderRadius: 4,
              bottom: 0,
              content: '" "',
              height: 4,
              right: spacing.small,
              left: spacing.small,
              position: 'absolute',
            },
            undefined
          ),
        }}
        {...triggerProps}
      >
        <Flex
          as="button"
          gap="small"
          alignItems="center"
          borderRadius="medium"
          paddingY="medium"
          paddingX="small"
          css={{
            ...defaultInteractiveStyles,
            ...ternary(
              isOpen,
              {
                background: palette.interactive.base.active,
              },
              undefined
            ),
            ...ternary(!visible, { pointerEvents: 'none' }, undefined),
          }}
          tabIndex={visible ? undefined : -1}
        >
          <Text weight="semibold" color={isSelected ? 'base' : 'muted'}>
            {label}
          </Text>
          <Flex alignSelf="center">
            <CheveronIcon color={isSelected ? 'base' : 'muted'} size="small" />
          </Flex>
        </Flex>
      </Flex>
    );
  };
};
