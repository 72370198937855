import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { RequestResult } from './types';
import { urlBuilderFactory } from './urlBuilderFactory';

export type UseRequestProps = {
  /** lodash.template compatiable url string which ends up using the params */
  url: string;
  /** headers to add to all requests */
  headers?: Record<string, string>;
};

/**
 *
 * Given a templateable string, and some standard headers, provides a function that can
 * run fetch for that url:
 *  import { useAuth } from '@reckon-web/auth-store';
 *
 *  const [companies, setCompanies ] = useState<Company[]>([])
 *  const { requestHeaders} = useAtuth();
 *  const { request, status, error } = useRequest({
 *      url: "https://portal.reckon.com/api/companies/?abn={query}",
 *      headers: {
 *          ...requestHeaders
 *      }
 *  })
 *
 *  const onSubmit = useForm(searchForm, (formData) => {
 *    const responseOrError = request({ params: { query: formData.query } })
 *    if (!isRequestError(responseOrError)) {
 *      setCompanies(() => response.json() )
 *    }
 *  })
 *
 */
export function useRequest({ url, headers }: UseRequestProps) {
  const [error, setError] = useState<string>();
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [requestHeaders, setRequestHeaders] = useState(() => headers || {});
  const urlBuilder = useMemo(() => urlBuilderFactory(url), [url]);

  const request = useCallback(
    async ({
      params,
      headers,
    }: {
      params?: Record<string, string | number | boolean>;
      headers?: Record<string, string>;
    } = {}): Promise<RequestResult<Response>> => {
      setStatus('loading');
      try {
        const requestUrl = urlBuilder(params || {});
        const response = await fetch(requestUrl, {
          headers: {
            // RWR-1783: allow tracing through reckons ecosystem
            'x-rkn-correlation-id': uuid4(),
            ...requestHeaders,
            ...headers,
          },
        });
        setStatus('idle');
        return response;
      } catch (error) {
        setStatus('error');
        const errorMessage = error instanceof Error ? error.message : 'woops';
        setError(errorMessage);
        return { error: errorMessage };
      }
    },
    [requestHeaders, urlBuilder]
  );

  return {
    error,
    status,
    request,
    setRequestHeaders,
  };
}
