import { useRawTheme } from '@balance-web/theme';

export const useNestingLeftOffset = () => {
  const theme = useRawTheme();

  return ({
    nestingIndex,
    cellIndex,
  }: {
    /**
     *
     * The level of nesting for the current item starting from 0.
     * For example parent > parent > currentItem would have a nesting index of 2.
     * */
    nestingIndex: number;
    /**
     *
     * The index of the cell being renderred.
     * */
    cellIndex: number;
  }) => {
    return (
      theme.spacing.large * nestingIndex * (cellIndex === 1 ? 1 : cellIndex - 1)
    );
  };
};
