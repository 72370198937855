import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { Flex } from '@balance-web/flex';

import { PageColumn } from './PageColumn';
import { PageSidebar } from './PageSidebar';
import { PageLayoutContext } from './context';

export type PageLayoutProps = {
  children?: ReactNode;
};

export const PageLayout = ({ children }: PageLayoutProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <PageLayoutContext.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen,
      }}
    >
      <Flex flex="1">{children}</Flex>
    </PageLayoutContext.Provider>
  );
};

PageLayout.Column = PageColumn;
PageLayout.Sidebar = PageSidebar;
