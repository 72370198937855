/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';
import { ProductMenuController } from '@balance-web/product-menu';

import { AppList } from './AppList';
import { SwitcherIcon } from './SwitcherIcon';
import { ItemWrapper } from './ItemWrapper';
import type { Apps } from './types';
import { createTestId } from './utils';
import { appSwitcherEventEmitter } from './appSwitcherEvents';

export type AppSwitcherProps = {
  apps: Apps;
  isLoading?: boolean;
  onEmptyStatePrimaryActionClick: () => void;
};
export const AppSwitcher = ({
  apps,
  isLoading,
  onEmptyStatePrimaryActionClick,
}: AppSwitcherProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(
    () =>
      appSwitcherEventEmitter.subscribe('onClose', () => {
        setIsOpen(false);
      }),
    []
  );

  return (
    <Fragment>
      <Flex alignItems="center">
        <ItemWrapper
          as="button"
          onClick={() => setIsOpen(true)}
          data={{ testid: createTestId('app-switcher-trigger') }}
        >
          <div css={{ lineHeight: 0 }}>
            <SwitcherIcon />
          </div>
        </ItemWrapper>
      </Flex>
      <ProductMenuController isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <AppList
          apps={apps}
          isLoading={isLoading}
          onClose={() => setIsOpen(false)}
          onEmptyStatePrimaryActionClick={onEmptyStatePrimaryActionClick}
        />
      </ProductMenuController>
    </Fragment>
  );
};

export const closeAppSwitcher = () => {
  appSwitcherEventEmitter.broadcast('onClose', []);
};
