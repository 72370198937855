import { useMemo, useState } from 'react';
import _useResizeObserver from 'use-resize-observer';
import debounce from 'lodash/debounce';

import { SortType, TableDataRow } from './types';

// debounced resize observer
type ObservedSize = { height?: number; width?: number };
export const useResizeObserver = (wait: number) => {
  const [size, setSize] = useState<ObservedSize>({});
  const onResize = useMemo(() => debounce(setSize, wait, { leading: true }), [
    wait,
  ]);
  const { ref } = _useResizeObserver({ onResize });

  return { ref, ...size };
};

// helper for sorting data
export const sortData = (data: TableDataRow[], sort: SortType) => {
  return [...data].sort((a, b) => {
    let sortA = Number(a[sort.key]) ? Number(a[sort.key]) : a[sort.key];
    let sortB = Number(b[sort.key]) ? Number(b[sort.key]) : b[sort.key];

    if (sort.asc) {
      if (sortA < sortB) return -1;
      if (sortA > sortB) return 1;
      return 0;
    }
    if (sortA > sortB) return -1;
    if (sortA < sortB) return 1;
    return 0;
  });
};
