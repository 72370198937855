export function minutesToDurationString(
  minutes?: number,
  display?: 'time' | 'duration'
) {
  if (minutes == null || minutes === undefined) {
    return '';
  }

  if (minutes === 0) {
    return display === 'duration' ? '0h 0m' : '0:00';
  }

  const hours = minutes / 60;

  if (Number.isInteger(hours)) {
    return display === 'duration' ? `${hours}h 0m` : `${hours}:00`;
  }

  const h = Math.floor(hours);
  const m = Math.round((hours % 1) * 60);

  const paddedMinutes = String(m).padStart(2, '0');

  return display === 'duration' ? `${h}h ${m}m` : `${h}:${paddedMinutes}`;
}

// NOTE: input must contain at least one digit before attempting `inputToMinutes`
export function isValidInput(input: string) {
  return /\d/.test(input);
}

export function durationStringToMinutes(input: string) {
  const parsedInput = input
    // exclude invalid chars
    .replace(/[^\d.:hm]/g, '')
    // de-dupe repeating periods
    .replace(/\.+/g, '.')
    // de-dupe repeating colons
    .replace(/:+/g, ':');

  // When the input contains digits followed by 'h' and/or 'm', process it differently
  if (parsedInput.match(/\d+h/) || parsedInput.match(/\d+m/)) {
    return convertInputWithHourAndMinuteToMinutes(parsedInput);
  }

  // is "time" format e.g. `1:23`
  if (parsedInput.includes(':')) {
    const [h, m] = parsedInput.split(':').map((str) => {
      const maybeInt = parseInt(str, 10);
      // NOTE: pad nullish input e.g. `:23`
      return Number.isNaN(maybeInt) ? 0 : maybeInt;
    });

    return h * 60 + m;
  }

  // is integer e.g. `1`
  if (Number.isInteger(Number(parsedInput))) {
    return parseInt(parsedInput, 10) * 60;
  }

  // is float e.g. `1.23`
  if (!Number.isNaN(parseFloat(parsedInput))) {
    return Math.round(parseFloat(parsedInput) * 60);
  }

  // NOTE: *should* be unreachable — fail as gracefully as possible
  return Number.isNaN(Number(parsedInput)) ? 0 : parseInt(parsedInput, 10) * 60;
}

// This can handle input formats like `5h 30m`, `30m`, `30m5h`, `5.5h` etc. and convert them to minutes
function convertInputWithHourAndMinuteToMinutes(timeString: string) {
  const regex = /(\d+(\.\d+)?)(h|m)/g;
  const matches = timeString.match(regex);

  if (!matches) {
    return 0;
  }

  let totalMinutes = 0;

  for (const match of matches) {
    const value = parseFloat(match);
    const unit = match.slice(-1);

    if (unit === 'h') {
      totalMinutes += value * 60; // 1 hour = 60 minutes
    } else if (unit === 'm') {
      totalMinutes += value; // 1 minute = 1 minute
    }
  }

  return totalMinutes;
}
