import React, { forwardRef } from 'react';
import { Item } from '@radix-ui/react-accordion';
import { Stack } from '@balance-web/stack';
import type { AccordionItemProps } from '@radix-ui/react-accordion';

export type AccordionItemContainerProps = Pick<
  AccordionItemProps,
  'value' | 'children'
>;

export const AccordianItem = forwardRef<
  HTMLDivElement,
  AccordionItemContainerProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <Item {...props} ref={forwardedRef}>
      <Stack background="base">{children}</Stack>
    </Item>
  );
});
