/** @jsx jsx */
import { jsx } from '@balance-web/core';
import React, { ComponentType } from 'react';

export type CreateOptions = {
  /** The name of the illustration. */
  name: string;
  /** Describe the elements of the illustration. */
  description: string;
  /** The height of the illustration. */
  height: number;
  /** The width of the illustration. */
  width: number;
};
export type IllustrationProps = {
  /** The height and width of the illustration. */
  size?: number | string;
};

export const createIllustration = (
  { description, name, height, width }: CreateOptions,
  children: React.ReactNode
) => {
  let Illustration: ComponentType<IllustrationProps> = ({
    size,
  }: IllustrationProps) => (
    <svg
      role="img"
      fill="none"
      focusable="false"
      viewBox={`0 0 ${width} ${height}`}
      css={{
        height: size ? `(calc(${height} / ${width}) * ${size})` : height,
        width: size || width,
      }}
    >
      <title>{description}</title>
      {children}
    </svg>
  );

  Illustration.displayName = name;

  return Illustration;
};
