import React, { ReactNode, memo } from 'react';
import { Flex } from '@balance-web/flex';
import { CSSObject } from '@balance-web/core';

export type PageColumnProps = {
  children: ReactNode;
  /**
   * Role of the content that fills a column.
   * Note: You should only have one column as main.
   */
  role: 'main' | 'aside';
  /** Use the width property to set a fixed width for a column. */
  width?: CSSObject['width'];
};

const PageColumnUnmemoised = ({ children, width, role }: PageColumnProps) => {
  return (
    <Flex as={role} direction="column" flexBasis={width}>
      {children}
    </Flex>
  );
};

export const PageColumn = memo(PageColumnUnmemoised);
