import React from 'react';

export const CaretRightIcon = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      style={{
        verticalAlign: 'text-bottom', // removes whitespace inside buttons
        stroke: '#52535C',
        fill: '#52535C',
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
        strokeWidth: 2,
        height: 16,
        width: 16,
      }}
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <React.Fragment>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M16.1729 12.7049C16.3824 12.5176 16.5 12.2642 16.5 12C16.5 11.7358 16.3824 11.4824 16.1729 11.2951L9.42357 5.29577C9.26691 5.15537 9.06692 5.05941 8.84891 5.02003C8.63089 4.98065 8.40463 4.99961 8.19874 5.07453C7.99285 5.14945 7.81657 5.27695 7.6922 5.44091C7.56783 5.60488 7.50095 5.79794 7.50002 5.99569L7.50002 17.9943C7.49872 18.1929 7.56399 18.3873 7.6875 18.5528C7.81101 18.7183 7.98718 18.8474 8.19353 18.9236C8.39988 18.9997 8.62708 19.0195 8.84615 18.9805C9.06522 18.9414 9.26623 18.8452 9.42357 18.7042L16.1729 12.7049Z"
        />
      </React.Fragment>
    </svg>
  );
};
