import type { Context } from 'react';
import { createContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import type { BaseBook } from '../types';
export type SelectionState =
  | 'IS_OPENING_BOOK'
  | 'AUTOSELECTING_BOOK'
  | 'NEEDS_TO_SELECT_BOOK'
  | 'HAS_NO_BOOKS'
  | 'IS_CREATING_BOOK'
  | 'IS_AUTHORISED_TO_VIEW'
  | 'IS_NOT_AUTHORISED_TO_VIEW'
  | 'ATTEMPTING_TO_SETUP_COMPLETED_BOOK'
  | 'ATTEMPTING_TO_USE_APP_WITH_INCOMPLETE_BOOK';

export type BookSwitcherContextShape<TBook extends BaseBook> = {
  isLoading?: boolean;
  books: TBook[];
  isOpen?: boolean;
  hasBooks: boolean;
  hasMultipleBooks: boolean;
  currentBook?: TBook;
  selectionState: SelectionState;
  getBook: (bookId: string) => TBook | undefined;
  hasBook: (bookId: string) => boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  toggleIsOpen: Dispatch<SetStateAction<boolean>>;
};

export type BookSwitcherContext<TBook extends BaseBook> = Context<
  BookSwitcherContextShape<TBook>
>;

export function createBookSwitcherContext<
  TBook extends BaseBook
>(): BookSwitcherContext<TBook> {
  return createContext<BookSwitcherContextShape<TBook>>({
    books: [],
    hasBooks: false,
    hasMultipleBooks: false,
    selectionState: 'NEEDS_TO_SELECT_BOOK',
    getBook: (bookId: string) => {
      return undefined;
    },
    hasBook: () => {
      return false;
    },
    setIsOpen: () => {},
    toggleIsOpen: () => {},
  });
}
