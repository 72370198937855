import { useRef } from 'react';
import { Field, Form } from '@magical-forms/react-next';

import { areFieldsEqual } from './areFieldsEqual';

export let initialRefValue = {};

export function useIsFormEqualToInitialValue(form: Form<Field>) {
  let initialValueRef = useRef(initialRefValue);
  if (initialValueRef.current === initialRefValue) {
    initialValueRef.current = form.value;
  }
  return areFieldsEqual(form._field, initialValueRef.current, form.value);
}
