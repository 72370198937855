const TRUNCATE_LENGTH = 27;
export const truncateFileName = (name: string) => {
  if (name.length <= TRUNCATE_LENGTH) return { name, truncated: false };

  return {
    name: `${name.slice(0, TRUNCATE_LENGTH / 2)}...${name.slice(
      Math.floor(name.length - TRUNCATE_LENGTH / 2),
      name.length
    )}`,
    truncated: true,
  };
};

export const fileSizeInKiloBytes = (kiloBytes: number) => 1024 * kiloBytes;
export const fileSizeInMegaBytes = (megaBytes: number) =>
  fileSizeInKiloBytes(megaBytes) * 1024;
export const fileSizeInGigaBytes = (gigaBytes: number) =>
  fileSizeInMegaBytes(gigaBytes) * 1024;
