import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonIdentityLogoName = 'ReckonIdentityLogo';
export const ReckonIdentityAppName = 'Identity';
export const ReckonIdentityAppLongName = 'Reckon Identity';
export const ReckonIdentityLogoDescription = 'Reckon Identity Application Logo';

export const ReckonIdentityLogo = createIllustration(
  {
    name: ReckonIdentityLogoName,
    description: ReckonIdentityLogoDescription,
    width: 60,
    height: 60,
  },
  <React.Fragment>
    <mask
      id="b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#a)"
      />
    </mask>
    <mask
      id="d"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={60}
      height={60}
    >
      <path
        d="M0 14C0 6.268 6.268 0 14 0h32c7.732 0 14 6.268 14 14v32c0 7.732-6.268 14-14 14H14C6.268 60 0 53.732 0 46V14Z"
        fill="url(#c)"
      />
    </mask>
    <g mask="url(#d)">
      <path fill="url(#e)" d="M0 0h60v60H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.667 22.675c0-2.543 2.387-4.605 5.333-4.605s5.333 2.062 5.333 4.605c0 2.544-2.387 4.606-5.333 4.606-2.945 0-5.333-2.062-5.333-4.605ZM30 15c-4.91 0-8.889 3.436-8.889 7.675 0 4.24 3.98 7.676 8.889 7.676 4.91 0 8.889-3.436 8.889-7.675 0-4.24-3.98-7.676-8.889-7.676Zm-7.111 18.421c-2.358 0-4.619.809-6.285 2.248C14.937 37.11 14 39.061 14 41.097v3.07c0 .848.796 1.535 1.778 1.535s1.778-.687 1.778-1.535v-3.07c0-1.222.561-2.393 1.562-3.257 1-.863 2.356-1.349 3.77-1.349h14.223c1.415 0 2.771.486 3.771 1.35 1 .863 1.562 2.034 1.562 3.256v3.07c0 .848.796 1.535 1.778 1.535S46 45.015 46 44.167v-3.07c0-2.036-.937-3.988-2.603-5.428-1.667-1.44-3.928-2.248-6.286-2.248H22.89Z"
        fill="#fff"
      />
      <g filter="url(#f)">
        <path d="M60 19 19 60h41V19Z" fill="#fff" fillOpacity={0.2} />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.361 43.815v9.735H51.1a2.377 2.377 0 0 0 2.374-2.373v-7.362a2.377 2.377 0 0 0-2.374-2.373h-7.364a2.377 2.377 0 0 0-2.375 2.373Zm-1 10.735H51.1a3.377 3.377 0 0 0 3.374-3.373v-7.362a3.377 3.377 0 0 0-3.374-3.373h-7.364a3.377 3.377 0 0 0-3.375 3.373V54.55Z"
        fill="#fff"
      />
      <path
        d="M44.566 43.721h3.36c.951 0 1.688.281 2.17.75.402.415.63.963.63 1.62v.026c0 1.232-.737 1.981-1.782 2.276l2.036 2.85H49.4l-1.848-2.623h-1.66v2.624h-1.326V43.72Zm3.267 3.735c.95 0 1.553-.496 1.553-1.259v-.026c0-.803-.576-1.245-1.566-1.245h-1.928v2.53h1.941Z"
        fill="#fff"
      />
    </g>
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88B00" />
        <stop offset={1} stopColor="#FFB027" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0}
        y1={0}
        x2={62.099}
        y2={57.743}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F900FE" />
        <stop offset={1} stopColor="#58008E" />
      </linearGradient>
      <filter
        id="f"
        x={11}
        y={11}
        width={57}
        height={57}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation={4} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_13767_1095"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_13767_1095"
          result="shape"
        />
      </filter>
    </defs>
  </React.Fragment>
);
