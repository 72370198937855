/** @jsx jsx */

import { HTMLAttributes } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

export const Container = (props: HTMLAttributes<HTMLDivElement>) => {
  const { spacing } = useTheme();

  return (
    <div
      css={{
        margin: '0 auto',
        minWidth: 680,
        maxWidth: 1020,
        paddingLeft: spacing.xlarge,
        paddingRight: spacing.xlarge,
      }}
      {...props}
    />
  );
};
