import React, { ReactNode } from 'react';
import { Flex } from '@balance-web/flex';
import { Divider } from '@balance-web/divider';
import { useTheme } from '@balance-web/theme';

import { PageBlock } from '../PageBlock';

export type SiteHeaderProps = {
  appName: ReactNode;
  navigation: ReactNode;
  sessionMenu: ReactNode;
};
export const SiteHeader = ({
  appName,
  navigation,
  sessionMenu,
}: SiteHeaderProps) => {
  const { spacing } = useTheme();

  return (
    <Flex
      direction="column"
      justifyContent="end"
      alignItems="center"
      background="base"
    >
      <PageBlock direction="row">
        <Flex
          alignItems="center"
          marginRight="xxlarge"
          gap="small"
          cursor="pointer"
          minHeight={spacing.xxlarge}
          paddingY="medium"
          data-testid="siteheader-appname"
        >
          {appName}
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" flexGrow={1}>
          {navigation}
          {!!sessionMenu && (
            <Flex
              justifyContent="flex-end"
              flexGrow={1}
              data-testid="siteheader-session-menu"
            >
              {sessionMenu}
            </Flex>
          )}
        </Flex>
      </PageBlock>
      <Divider />
    </Flex>
  );
};
