import { AnyFunction, FunctionArgumentsAsArray } from './types';

export const createEventEmitter = <TEvents extends Record<string, any>>(
  /** Throws if you try to subscribe more than once to the same event. */
  singleSubscriber?: boolean
) => {
  type SubscriberMap = Map<
    AnyFunction,
    { fn: AnyFunction; subscriberKey?: string }
  >;

  const eventMap = new Map<keyof TEvents, SubscriberMap>();

  function subscribe<TKey extends keyof TEvents>(
    event: TKey,
    fn: TEvents[TKey],
    subscriberKey?: string
  ) {
    let subscriptionMap = eventMap.get(event);

    if (!subscriptionMap) {
      subscriptionMap = new Map<
        AnyFunction,
        { fn: AnyFunction; subscriberKey?: string }
      >();
      eventMap.set(event, subscriptionMap);
    }

    if (singleSubscriber && subscriptionMap.size > 0) {
      throw Error('This event can only have 1 subscriber at a time');
    }

    subscriptionMap.set(fn, { fn, subscriberKey });

    return () => {
      unsubscribe(event, fn);
    };
  }

  function unsubscribe<TEventKey extends keyof TEvents>(
    event: TEventKey,
    key: AnyFunction | string
  ) {
    const subscriptionMap = eventMap.get(event);

    if (!subscriptionMap) return;

    if (typeof key === 'function') subscriptionMap.delete(key);
    else if (typeof key === 'string') {
      let mapKey: AnyFunction | undefined = undefined;
      subscriptionMap.forEach((value) => {
        if (value.subscriberKey === key) mapKey = value.fn;
      });
      if (mapKey) subscriptionMap.delete(mapKey);
    }
  }

  function broadcast<TEventKey extends keyof TEvents>(
    event: TEventKey,
    payload: FunctionArgumentsAsArray<TEvents[TEventKey]>
  ) {
    const subscriptionMap = eventMap.get(event);

    if (!subscriptionMap) return;

    subscriptionMap.forEach((subscription) => {
      subscription.fn.apply(null, payload);
    });
  }

  return {
    broadcast,
    subscribe,
    unsubscribe,
  };
};
