/** @jsx jsx */

import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import { jsx } from '@balance-web/core';
import { useId } from '@balance-web/utils';

import { Wrapper } from './Wrapper';
import { Divider } from './Divider';
import { Handle } from './Handle';
import { Segment } from './Segment';
import { RectType } from './types';

export type SegmentedControlProps<Value extends string | number> = {
  /** When true, the control will fill the width of its container. */
  block?: boolean;
  /** The name of the radio group. */
  name?: string;
  /** Function called when the value changes. */
  onChange: (value: Value) => void;
  /** The options for the radios of the segmented control. */
  options: Readonly<{ label: string; value: Value }[]>;
  /** The current value of the segmented control. */
  value: Readonly<Value>;
};

export const SegmentedControl = <Value extends string | number>({
  block = false,
  name: consumerName,
  onChange,
  options,
  value,
  ...props
}: SegmentedControlProps<Value>) => {
  const [selectedRect, setSelectedRect] = useState<RectType>();
  const name = useId(consumerName);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLLabelElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let stringValue = event.target.value;
    let parsedValue = Number(stringValue);
    let resolvedValue = (Number.isNaN(parsedValue)
      ? stringValue
      : parsedValue) as Value;

    onChange(resolvedValue);
  };

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    const targetElement = selectedRef.current;

    if (wrapperElement && targetElement) {
      // @ts-ignore not yet typed
      const resizeObserver = new ResizeObserver(() => {
        let wrapperRect = wrapperElement.getBoundingClientRect();
        let targetRect = targetElement.getBoundingClientRect();

        // uses relative positioning within the Wrapper
        const simpleRect = {
          left: targetRect.x - wrapperRect.x,
          top: targetRect.y - wrapperRect.y,
          height: targetRect.height,
          width: targetRect.width,
        };

        setSelectedRect(simpleRect);
      });

      resizeObserver.observe(wrapperElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [value]);

  return (
    <Wrapper block={block} ref={wrapperRef} {...props}>
      {options.map((opt, index) => {
        const checked = opt.value === value;

        return (
          <Fragment key={opt.value}>
            {index ? <Divider /> : null}

            <Segment
              checked={checked}
              name={name}
              onChange={handleChange}
              ref={checked ? selectedRef : null}
              value={opt.value}
            >
              {opt.label}
            </Segment>
          </Fragment>
        );
      })}

      <Handle rect={selectedRect} />
    </Wrapper>
  );
};
