/** @jsx jsx */
import React, { ReactElement, useCallback } from 'react';
import { VirtualItem } from 'react-virtual';
import { jsx } from '@balance-web/core';
import { Flex } from '@balance-web/flex';

import { ItemLoadingIndicator } from './ItemLoadingIndicator';

type UseItemMapProps<T> = {
  /** used to compute a unique key for the item */
  itemIdentifier: (item: T) => string;

  /** called upon to render the item */
  itemRenderer: (item: T) => ReactElement;

  /** list of items to render */
  items: T[];
};

export function useItemMap<Item>({
  itemIdentifier,
  itemRenderer,
  items,
}: UseItemMapProps<Item>) {
  return useCallback(
    (virtualRow: VirtualItem) => {
      // virtualized styles

      const css = {
        height: virtualRow.size,
        inset: 0,
        position: 'absolute' as const,
        top: 0,
        left: 0,
        width: '100%',
        transform: `translateY(${virtualRow.start})`,
      };

      // paginated results render a spinner as the last item
      const isLoadingRow = virtualRow.index > items.length - 1;
      if (isLoadingRow) {
        return <ItemLoadingIndicator key="loading-indicator" css={css} />;
      }

      // transform consumer data
      const item = items[virtualRow.index];
      const id = itemIdentifier(item);

      return (
        <Flex
          // Prefer unique key to `virtualRow.index` — avoids issues with
          // React's reconciliation during search.
          key={id}
          css={css}
        >
          {itemRenderer(item)}
        </Flex>
      );
    },
    [itemIdentifier, itemRenderer, items]
  );
}
