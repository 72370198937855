import { useCallback } from 'react';
import { useRawTheme } from '@balance-web/theme';

export function useVirtualTheme(itemHeight: number) {
  const { spacing } = useRawTheme();

  return {
    estimateSize: useCallback(() => itemHeight, [itemHeight]),
    paddingEnd: spacing.xsmall,
    paddingStart: spacing.xsmall,
    overscan: 5,
  };
}
