/** @jsx jsx */

import { Fragment, forwardRef, useEffect, useRef } from 'react';
import { VisuallyHidden } from '@balance-web/a11y';
import { jsx } from '@balance-web/core';
import { CheckIcon } from '@balance-web/icon/icons/CheckIcon';
import { MinusIcon } from '@balance-web/icon/icons/MinusIcon';
import { useForkedRef } from '@balance-web/utils';

import { CheckboxIndicator } from './CheckboxIndicator';
import type { CheckboxPrimitiveProps } from './types';

export const CheckboxPrimitive = forwardRef<
  HTMLInputElement,
  CheckboxPrimitiveProps
>(({ checked: ariaChecked, size, ...inputProps }, forwardedRef) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const composedRef = useForkedRef(inputRef, forwardedRef);

  const inputChecked = ariaChecked === 'mixed' ? undefined : ariaChecked;
  const Icon = ariaChecked === 'mixed' ? MinusIcon : CheckIcon;

  /**
   * The indeterminate property can only be set via javascript
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#attr-indeterminate
   */
  useEffect(() => {
    const element = inputRef.current;
    if (element) {
      element.indeterminate = ariaChecked === 'mixed';
    }
  }, [ariaChecked]);

  return (
    <Fragment>
      <VisuallyHidden
        aria-checked={ariaChecked}
        ref={composedRef}
        as="input"
        type="checkbox"
        checked={inputChecked}
        {...inputProps}
      />
      <CheckboxIndicator size={size}>
        <Icon size={size} />
      </CheckboxIndicator>
    </Fragment>
  );
});
