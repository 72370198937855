/* @jsx jsx */
import { jsx } from '@balance-web/core';
import type { CustomTriggerProps } from '@balance-web/dropdown-v2';
import { ChevronDownIcon, ChevronUpIcon } from '@balance-web/icon';
import { useTheme } from '@balance-web/theme';
import { ternary } from '@balance-web/utils';
import { Inline } from '@balance-web/inline';
import { UserAvatar } from '@balance-web/avatar';

import { ItemWrapper } from './ItemWrapper';

export type CreateUserMenuDropdownTrigger = {
  userName: string;
};
export const createUserMenuDropdownTrigger = ({
  userName,
}: CreateUserMenuDropdownTrigger) => {
  return ({ isOpen, triggerProps }: CustomTriggerProps) => {
    const { palette } = useTheme();

    const CheveronIcon = isOpen ? ChevronUpIcon : ChevronDownIcon;

    return (
      <ItemWrapper
        paddingY="xsmall"
        {...triggerProps}
        css={{
          ...ternary(
            isOpen,
            {
              background: palette.actionButton.backgroundPressed,
            },
            undefined
          ),
        }}
      >
        <Inline gap="small" alignY="center">
          <UserAvatar name={userName} size="small" />
          <CheveronIcon color="muted" size="small" />
        </Inline>
      </ItemWrapper>
    );
  };
};
