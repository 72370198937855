/** @jsx jsx */
import type { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef, useMemo, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@balance-web/button';
import { jsx } from '@balance-web/core';
import { buildDataAttributes } from '@balance-web/core';
import type { CSSObject, WithDataAttributeProp } from '@balance-web/core';
import { UploadIcon } from '@balance-web/icon/icons/UploadIcon';
import { Stack } from '@balance-web/stack';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';
import { useForkedRef } from '@balance-web/utils';

type FileInputProps = InputHTMLAttributes<HTMLInputElement>;

export type FileUploadProps = WithDataAttributeProp<
  {
    title?: string;
    description?: string;
    dropZone?: boolean;
    children?: ReactNode;
    onFilesSelected: (files: File[]) => void;
  } & FileInputProps
>;

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  (
    {
      title,
      description,
      dropZone,
      disabled,
      onFilesSelected,
      children,
      multiple = true,
      data,
      ...inputProps
    },
    forwardedRef
  ) => {
    const { palette, radii } = useTheme();
    const internalRef = useRef<HTMLInputElement>(null);
    const forkedRef = useForkedRef(internalRef, forwardedRef);

    const formattedAcceptAttribute = (inputProps.accept || '')
      .split(',')
      .reduce((prev, curr) => {
        return { ...prev, ...{ [curr]: [] } };
      }, {});

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      // @ts-ignore Merging HTMLInput props with react-dropzone is causing type collision
      onDrop: (acceptedFiles) => {
        onFilesSelected(acceptedFiles);
      },
      ...inputProps,
      disabled,
      multiple,
      accept: formattedAcceptAttribute,
    });

    const dropZoneStyles: CSSObject = useMemo(() => {
      return {
        width: '100%',
        height: '92px',
        background: palette.background.muted,
        border: `1px ${isDragActive ? 'solid' : 'dashed'} ${
          isDragActive && !disabled
            ? palette.text.active
            : palette.border.standard
        }`,
        borderRadius: radii.medium,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        ':hover': {
          borderColor: disabled ? palette.border.standard : palette.text.active,
        },
        ':active': {
          borderStyle: disabled ? undefined : 'solid',
        },
      };
    }, [
      disabled,
      isDragActive,
      palette.background.muted,
      palette.border.standard,
      palette.text.active,
      radii.medium,
    ]);

    const dropZoneProps = dropZone
      ? {
          ...getRootProps(),
          css: dropZoneStyles,
        }
      : undefined;

    const internalInputProps = dropZone
      ? { ...getInputProps() }
      : {
          ...inputProps,
          multiple,
          onChange: (event: ChangeEvent<HTMLInputElement>) => {
            onFilesSelected(Array.from(event.target.files || []));
          },
        };

    const handleClick = () => {
      internalRef.current?.click();
    };

    const dataAttributes = buildDataAttributes(data);

    return (
      <Stack gap="large">
        <Stack gap="xsmall">
          {title && <Text weight="semibold">{title}</Text>}
          {description && (
            <Text size="small" color="muted">
              {description}
            </Text>
          )}
        </Stack>
        <div {...dropZoneProps}>
          {dropZone ? (
            <Stack flex="1" gap="medium" align="center">
              <UploadIcon color={disabled ? 'dim' : 'active'} />
              <Text size="xsmall" color={disabled ? 'dim' : 'active'}>
                Drag and drop files here or click to upload
              </Text>
            </Stack>
          ) : (
            <Button
              label="Upload file"
              iconBefore={UploadIcon}
              size="small"
              variant="outline"
              onClick={handleClick}
            />
          )}
          <input
            type="file"
            ref={forkedRef}
            css={{ display: 'none' }}
            {...dataAttributes}
            {...internalInputProps}
          />
        </div>

        {children}
      </Stack>
    );
  }
);
