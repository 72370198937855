/** @jsx jsx */

import {
  HTMLAttributes,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
  forwardRef,
} from 'react';
import { jsx } from '@balance-web/core';
import { MenuIcon } from '@balance-web/icon/icons/MenuIcon';
import { useRawTheme, useTheme } from '@balance-web/theme';
import { makeId } from '@balance-web/utils';

const SIZE_KEY = 'small';

type CommonProps = HTMLAttributes<HTMLDivElement>;

// Menu
// ------------------------------

export const Menu = forwardRef<HTMLDivElement, CommonProps>((props, ref) => {
  const { spacing } = useTheme();
  const rawTheme = useRawTheme();

  return (
    <div
      ref={ref}
      css={{
        maxHeight: rawTheme.sizing[SIZE_KEY] * 12,
        minWidth: 220,
        maxWidth: 360,
        outline: 0,
        overflowY: 'auto',
        paddingBottom: spacing.xsmall,
        paddingTop: spacing.xsmall,
        WebkitOverflowScrolling: 'touch',
      }}
      {...props}
    />
  );
});

// Group
// ------------------------------

type GroupProps = {
  /** The title for the group */
  title: string;
} & CommonProps;

export const Group = ({ children, id, title }: GroupProps) => {
  const { palette, spacing, typography } = useTheme();
  const headingId = makeId(id, 'heading');

  return (
    <div
      role="group"
      aria-labelledby={headingId}
      css={{
        '& + & ': {
          borderTop: `1px solid ${palette.global.border}`,
          marginTop: spacing.small,
          paddingTop: spacing.small,
        },
      }}
    >
      <div
        id={headingId}
        css={{
          color: palette.text.muted,
          fontSize: typography.fontSize.xsmall,
          fontWeight: typography.fontWeight.medium,
          padding: `${spacing.small} ${spacing.medium}`,
        }}
      >
        {title}
      </div>

      {children}
    </div>
  );
};

// Item
// ------------------------------

type MenuItemProps = {
  /** Element after the label */
  afterElement: ReactElement;
  /** Element before the label */
  beforeElement?: ReactElement;
  /** When disabled the text will take on a dimmed appearance */
  disabled?: boolean;
  /** Describe the item with a label */
  children: ReactNode;
} & CommonProps;

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  ({ afterElement, beforeElement, children, disabled, ...props }, ref) => {
    const { palette, sizing, spacing, typography } = useTheme();

    return (
      <div
        ref={ref}
        css={{
          alignItems: 'center',
          background: 0,
          border: 0,
          color: disabled
            ? palette.menuItem.textDisabled
            : palette.menuItem.text,
          display: 'flex',
          fontSize: typography.fontSize.small,
          fontWeight: typography.fontWeight.medium,
          height: sizing[SIZE_KEY],
          outline: 0, // clicked items will be "real focused". we don't want to show the outline
          paddingBottom: 0,
          paddingLeft: spacing.medium,
          paddingRight: spacing.medium,
          paddingTop: 0,
          textAlign: 'left',
        }}
        {...props}
      >
        {beforeElement}
        <div
          css={{
            flex: 1,
            minWidth: 0,
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {children}
        </div>
        <div css={{ marginLeft: spacing.small }}>{afterElement}</div>
      </div>
    );
  }
);

// Item drag handle
// ------------------------------

type DragHandleProps = {
  /** When disabled the icon will take on a dimmed appearance */
  disabled?: boolean;
} & CommonProps;

// avoid page scroll on "space" press
const disabledKeyDownHandler: KeyboardEventHandler = (e) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};

export const DragHandle = ({ disabled, ...props }: DragHandleProps) => {
  const { palette, spacing } = useTheme();

  // prevent drag-n-drop props when disabled
  const dndProps = disabled
    ? { onKeyDown: disabledKeyDownHandler, 'aria-label': props['aria-label'] }
    : props;

  return (
    <div
      aria-disabled={disabled}
      role="button"
      tabIndex={0}
      css={{
        color: disabled ? palette.text.dim : palette.text.base,
        marginRight: spacing.small,
      }}
      {...dndProps}
    >
      <MenuIcon size="small" />
    </div>
  );
};
