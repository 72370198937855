import { uniqueId } from 'lodash';

import { createEventEmitter } from '@reckon-web/events';

import { QueueOptions, QueueTask } from './types';

export function createItemQueue<
  TItem,
  TTask extends QueueTask<TItem> = () => Promise<TItem>
>({
  events,
  concurrency = 3,
  abortOnFailure,
}: QueueOptions & { events: ReturnType<typeof createEventEmitter> }) {
  const queueId = uniqueId('queue');
  const workers: Promise<void>[] = [];
  const tasks: TTask[] = [];

  for (let i = 0; i < concurrency; i++) {
    workers[i] = Promise.resolve();
  }

  const getSize = () => {
    return concurrency - workers.length + tasks.length;
  };

  const isAvailable = () => {
    return workers.length > 0;
  };

  const isEmpty = () => {
    return tasks.length === 0 && workers.length === concurrency;
  };

  const schedule = (task: TTask): void => {
    const worker = workers.pop();

    if (!worker) return;

    worker
      .then(() => {
        return task();
      })
      .then((result) => {
        workers.push(worker);
        events.broadcast('taskComplete', [result]);

        // run the next task
        const next = tasks.shift();
        if (next) {
          schedule(next);
          return;
        }

        if (isEmpty()) {
          events.broadcast('queueEmpty', []);
          return;
        }
      })
      .catch((reason) => {
        events.broadcast('queueError', [reason, task]);
        workers.push(worker);

        if (!abortOnFailure) {
          // run the next task
          const next = tasks.shift();
          if (next) {
            schedule(next);
            return;
          }
        }
      });
  };

  const add = (task: TTask) => {
    events.broadcast('newTask', []);
    if (isAvailable()) {
      schedule(task);
    } else {
      tasks.push(task);
    }
  };

  return {
    queueId,
    getSize,
    tasks,
    events,
    add,
    schedule,
    isAvailable,
    isEmpty,
  };
}
