import React from 'react';
import type { PropsWithChildren } from 'react';

import type { BaseBook, BaseUser } from '../types';

import { getBookMetaData } from './getBookMetaData';
import { BookSwitcherMetaContext } from './BookSwitcherMetaContext';

export function BookSwitcherMetaProvider({
  books,
  user,
  children,
}: PropsWithChildren<{
  books: BaseBook[];
  user?: BaseUser | null;
}>) {
  return (
    <BookSwitcherMetaContext.Provider
      value={getBookMetaData({
        user,
        books,
      })}
    >
      {children}
    </BookSwitcherMetaContext.Provider>
  );
}
