import type { OperationData, OperationVariables } from '@ts-gql/tag';
import type { TSGQLApolloCache } from '@ts-gql/apollo';
import type { FetchResult } from '@ts-gql/apollo';

import type {
  HasRequiredVariables,
  QueryHookOptions,
  QueryHookOptionsWithRequiredVariables,
  TypedMutation,
  TypedQuery,
} from './types';

export type QueryHavingItsCacheUpdated<
  TQuery extends TypedQuery
> = HasRequiredVariables<
  TQuery,
  [TQuery, QueryHookOptionsWithRequiredVariables<TQuery>],
  | [
      TQuery,
      OperationVariables<TQuery> extends undefined
        ? QueryHookOptions<TQuery>
        : QueryHookOptions<TQuery> & {
            variables?: OperationVariables<TQuery>;
          }
    ]
  | [TQuery]
>[0];

export type useWriteCreateMutationResponseToCacheProps<
  TQuery extends TypedQuery,
  TMutation extends TypedMutation
> = {
  /**
   * - The mutation used the create the item.
   * - Must request body of the query intended to be the cache write target. You can make sure the data
   * requested correctly lines up with the query by spreading fragments of the query into the mutation body.
   */
  mutation: TMutation;
  /**
   * - The query to which to append the response of the above mutation to.
   * - The query must request only the data being used in the above mutation.
   * */
  query: QueryHavingItsCacheUpdated<TQuery>;
};

/**
 * This hook returns a function that can be passed to the 'update' prop when firing a create mutation.
 * The function allows consumers to easily append the response of a create mutation to a cache list without needing to interact with the apollo caching api.
 *
 * It's usage is as follows:
 *
 * const [executeSomeMutation] = useMutation(SomeMutation)
 * const writeSomeItemToCache = useWriteCreateMutationResponseToCache({ query: SomeQuery, mutation: SomeMutation })
 * await executeSomeMutation({
 *  variables: { ... },
 *  writeQuery: writeSomeItemToCache(
 *    { ...queryVariables },
 *    data: (queryData, mutationResponse) => {
 *      if(!mutationResponse.someListItem) throw Error(`Mutation didn't return someListItem`)
 *
 *      return {
 *        __typename: queryData.__typename,
 *        someList: [
 *          ...(queryData.someList || []),
 *          mutationResponse.someListItem,
 *        ]
 *      }
 *  })
 * })
 *
 * @deprecated
 */

const createCacheResponseWriter = <
  TQuery extends TypedQuery,
  TMutation extends TypedMutation
>({
  query,
  mutation,
}: useWriteCreateMutationResponseToCacheProps<TQuery, TMutation>) => {
  return (
    queryVariables: OperationVariables<TQuery> | undefined,
    writeQuery: (
      /**
       * Query data read from the cache.
       * The newly created item is appended to this list.
       */
      queryData: OperationData<TQuery>,
      /**
       * Data returned from the mutation.
       * The newly craeted item is extracted from this by the consumer and appended to the query.
       */
      mutationResponse: NonNullable<
        FetchResult<OperationData<TMutation>>['data']
      >
    ) => OperationData<TQuery>
  ) => {
    return (
      /**
       * Return the signature of the update prop when firing a mutation.
       * No need to pass anything manually.
       */
      cache: TSGQLApolloCache<OperationData<TMutation>>,
      response: FetchResult<OperationData<TMutation>>
    ) => {
      const currentItemsCache = cache.readQuery({
        query: query as any, // fix typing later
        variables: queryVariables,
      });

      /** We return instead of appending to an empty query because when the component with this query mounts it will do a full query anyway. */
      if (!currentItemsCache) {
        return;
      }

      if (!response.data) {
        throw Error('Mutation did not return data');
      }

      const updatedQueryData = writeQuery(currentItemsCache, response.data);

      cache.writeQuery({
        query: query as any, // fix typing later
        variables: queryVariables,
        data: updatedQueryData,
      });
    };
  };
};

/**
 * This form is for legacy use, where ownership of the query has not been enfocrced to within the query hook.
 * @deprecated
 * @example
 *    // useSomeQuery.ts
 *    export const useSomeQuery = ({ bookId, skip }: UseSomeQueryProps) => { ... }
 *
 *    // useSomeMutation.ts
 *    const writeSomeItemToCache = useWriteCreateMutationResponseToCache({
 *      query: SomeQuery,
 *      mutation: SomeMutation
 *    })
 *    export const useSomeMutation = () => {
 *       const [executeSomeMutation] = useMutation(SomeMutation)
 *       return (variables: SomeMutationVariables) => {
 *        await executeSomeMutation({
 *         variables,
 *         update: writeSomeItemToCache(( stuff ) => .... )
 *       })
 *    }
 */
export const useWriteCreateMutationResponseToCache = <
  TQuery extends TypedQuery,
  TMutation extends TypedMutation
>({
  query,
  mutation,
}: useWriteCreateMutationResponseToCacheProps<TQuery, TMutation>) => {
  return createCacheResponseWriter({ query, mutation });
};

/**
 * This form is for use with the useQuery hook, where ownership of the query has been enforced to within the query hook.
 *
 * @example
 *
 *    // useSomeQuery.ts
 *    export const useSomeQuery = ({ bookId, skip }: UseSomeQueryProps) => { ... }
 *    export const useUpdateSomeCache = useCreateResponseCacheWriter({ query: SomeQuery })
 *
 *    // useSomeMutation.ts
 *    import { useUpdateSomeCache } from './useSomeQuery'
 *    export const useSomeMutation = () => {
 *       const [executeSomeMutation] = useMutation(SomeMutation)
 *       return (variables: SomeMutationVariables) => {
 *        await executeSomeMutation({
 *         variables,
 *         update: writeSomeItemToCache(( stuff ) => .... )
 *       })
 *    }
 */
export function createMutationResponseCacheWriter<TQuery extends TypedQuery>({
  query,
}: {
  query: QueryHavingItsCacheUpdated<TQuery>;
}) {
  return <TMutation extends TypedMutation>({
    mutation,
  }: {
    mutation: TMutation;
  }) => {
    return createCacheResponseWriter({ query, mutation });
  };
}
