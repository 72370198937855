import { useCombobox } from 'downshift';

// Balance specific
// ------------------------------

export { Combobox } from './Combobox';
export type {
  ComboboxGetMessageText,
  ComboboxItemsStatus,
  ComboboxLoadingState,
  ComboboxProps,
} from './types';

// Downshift specific
// ------------------------------

// NOTE: unfortunately downshift implements Enum rather than string literals...
export const stateChangeTypesEnum = useCombobox.stateChangeTypes;

export type {
  UseComboboxState,
  UseComboboxStateChange,
  UseComboboxStateChangeOptions,
  UseComboboxStateChangeTypes,
} from 'downshift';
