import React, { ReactNode } from 'react';
import { MenuItem } from '@balance-web/dropdown';
import { Flex } from '@balance-web/flex';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';

import type { ReckonAppCode } from '@reckon-web/application-logos';
import { Logos, Names, isReckonAppCode } from '@reckon-web/application-logos';

import { SiteHeader } from '../SiteHeader';
import { UserMenu } from '../UserMenu';
import { ActionMenu, ActionMenuProps } from '../ActionMenu';

type MenuItemProps = Parameters<typeof MenuItem>[0];

type MenuDefinitionBase = {
  id: string;
  items: MenuItemProps[];
};
type MenuDefinitionWithLabel = MenuDefinitionBase & {
  label: string;
};
type MenuDefinitionWithTrigger = MenuDefinitionBase & {
  trigger: ActionMenuProps['trigger'];
};
type MenuDefinition = MenuDefinitionWithTrigger | MenuDefinitionWithLabel;

function isMenuWithLabel(
  menu: MenuDefinition
): menu is MenuDefinitionWithLabel & { label: string } {
  return 'label' in menu && !!menu.label;
}
function isMenuWithTrigger(
  menu: MenuDefinition
): menu is MenuDefinitionWithTrigger {
  return 'trigger' in menu && !!menu.trigger;
}

type BaseStandardSiteHeaderProps = {
  appVersion: string;
  navigation: ReactNode;
  actionMenus: MenuDefinition[];
  username: string;
  userMenu: MenuDefinition['items'];
  onAppnameClick: () => void;
};

export type StandardSiteHeaderProps =
  | (BaseStandardSiteHeaderProps & {
      code: ReckonAppCode;
      name?: string;
    })
  | (BaseStandardSiteHeaderProps & {
      header: ReactNode;
    });

export const StandardSiteHeader = ({
  appVersion,
  navigation,
  actionMenus,
  username,
  userMenu,
  onAppnameClick,
  ...props
}: StandardSiteHeaderProps) => {
  const { sizing } = useTheme();
  const handleAppnameClick = () => {
    if (typeof onAppnameClick !== 'function') return;
    onAppnameClick();
  };
  const code =
    ('code' in props && isReckonAppCode(props.code) && props.code) || null;
  const name = ('name' in props && props.code) || null;
  const header = ('header' in props && props.header) || null;
  const ApplicationLogo =
    ('code' in props && isReckonAppCode(props.code) && Logos[props.code]) ||
    React.Fragment;

  return (
    <>
      <SiteHeader
        appName={
          (code && (
            <Flex alignItems="center" onClick={handleAppnameClick} gap="medium">
              <ApplicationLogo size={sizing.small} />
              <Text weight="bold" size="large">
                {name || Names[code]}
              </Text>
            </Flex>
          )) ||
          header
        }
        navigation={navigation}
        sessionMenu={
          <>
            {actionMenus &&
              actionMenus.map((menu) => (
                <>
                  {isMenuWithLabel(menu) && (
                    <ActionMenu
                      data-testid={`siteheader-sessionmenu-${menu.id}`}
                      paddingRight="large"
                      key={menu.id}
                      trigger={menu.label}
                      menuItems={menu.items}
                    />
                  )}
                  {isMenuWithTrigger(menu) && (
                    <ActionMenu
                      data-testid={`siteheader-sessionmenu-${menu.id}`}
                      paddingRight="large"
                      key={menu.id}
                      trigger={menu.trigger}
                      menuItems={menu.items}
                    />
                  )}
                </>
              ))}
            {username && (
              <UserMenu
                data-testid={`siteheader-usermenu`}
                username={username}
                version={appVersion}
                menuItems={userMenu}
              />
            )}
          </>
        }
      />
    </>
  );
};
