import { InMemoryCache, PossibleTypesMap } from '@apollo/client';
import { Array, Record, Static, String } from 'runtypes';

const ObjectType = Record({ name: String });

const IntrospectionSuperType = Record({
  name: String,
  possibleTypes: Array(ObjectType),
});

export const IntrospectionResultSchema = Record({
  __schema: Record({
    types: Array(IntrospectionSuperType),
  }),
});

export type ResultSchema = Static<typeof IntrospectionResultSchema>;

export function mutatePossibleTypeMetaData(introspection: ResultSchema) {
  const possibleTypes: PossibleTypesMap = {};

  for (const supertype of introspection.__schema.types) {
    if (!supertype.possibleTypes) continue;

    possibleTypes[supertype.name] = supertype.possibleTypes.map(
      (subtype) => subtype.name
    );
  }
  return possibleTypes;
}

export function createCache<T extends ResultSchema>({ schema }: { schema: T }) {
  IntrospectionResultSchema.guard(schema);
  return new InMemoryCache({
    possibleTypes: mutatePossibleTypeMetaData(schema),
  });
}
