/** @jsx jsx */
import { MutableRefObject, forwardRef, useEffect } from 'react';
import type { ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { CheckboxPrimitive } from '@balance-web/checkbox';
import { Inline } from '@balance-web/inline';
import { Flex } from '@balance-web/flex';
import type { CheckboxProps } from '@balance-web/checkbox';

import { ListItemLayout } from './ListItemLayout';

export type CheckItemProps = CheckboxProps & {
  children: ReactNode;
  disabled?: boolean;
  onSelectFocusRef?: MutableRefObject<HTMLInputElement | null>;
  startElement?: ReactNode;
};

export const CheckItem = forwardRef<HTMLInputElement, CheckItemProps>(
  ({ children, startElement, onSelectFocusRef, ...checkboxProps }, ref) => {
    useEffect(() => {
      if (onSelectFocusRef?.current && checkboxProps.checked) {
        onSelectFocusRef.current.focus();
      }
    }, [onSelectFocusRef, checkboxProps.checked]);

    return (
      <label>
        <ListItemLayout listType="checkbox" selected={!!checkboxProps.checked}>
          <Inline flex="1" alignY="center">
            {startElement}
            <Box paddingX="large">{children}</Box>
            <Flex flex="1" justifyContent="end">
              <CheckboxPrimitive ref={ref} {...checkboxProps} />
            </Flex>
          </Inline>
        </ListItemLayout>
      </label>
    );
  }
);
