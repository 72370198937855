/** @jsx jsx */
import type { HTMLAttributes } from 'react';
import React from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { useFieldContext } from '@balance-web/field';

import type { RectType } from './types';
import { useButtonBorderRadius } from './styles';

type HandleProps = {
  rect?: RectType;
  optionCount: number;
} & HTMLAttributes<HTMLDivElement>;
export const Handle = ({ rect, optionCount, ...props }: HandleProps) => {
  const { colors } = useTheme();
  const { invalid } = useFieldContext();
  const easing = 'cubic-bezier(0.2, 0, 0, 1)';

  const indexToBorderRadius = useButtonBorderRadius({
    index: rect?.index,
    optionCount,
  });

  // NOTE: `transform: translate` would be more performant than animating the
  // `left` property, however it has a tendency to fall on "half pixels", which
  // will not cover our dividers properly
  return (
    <div
      css={{
        backgroundColor: colors[invalid ? 'danger' : 'accent'],
        borderRadius: indexToBorderRadius,
        pointerEvents: 'none',
        position: 'absolute',
        transitionDuration: '250ms',
        transitionProperty: 'left, width',
        transitionTimingFunction: easing,
        zIndex: 1,
      }}
      style={rect}
      {...props}
    />
  );
};
Handle.displayName = 'RadioButtonGroupHandle';
