/** @jsx jsx */
import { ReactNode, useMemo } from 'react';
import { CSSObject, jsx } from '@balance-web/core';
import { Stack } from '@balance-web/stack';
import { useTheme } from '@balance-web/theme';

import { DENSITY_PADDING } from './constants';
import { Density } from './types';
export type CardProps = {
  density?: Density;
  children?: ReactNode;
};

export const Card = ({ density = 'regular', children }: CardProps) => {
  const { palette, radii, borderWidth } = useTheme();

  const borderStyles: CSSObject = useMemo(
    () => ({
      border: `${borderWidth.standard} solid ${palette.global.border}`,
      borderRadius: radii.large,
    }),

    [borderWidth.standard, palette.global.border, radii.large]
  );
  return (
    <Stack
      width="100%"
      height="100%"
      background="base"
      padding={DENSITY_PADDING[density]}
      gap="large"
      css={{ ...borderStyles }}
    >
      {children}
    </Stack>
  );
};
