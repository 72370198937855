import React from 'react';
import { SpotNoResults } from '@balance-web/illustration';
import { Text } from '@balance-web/text';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
import { Heading } from '@balance-web/heading';

export const BookSwitcherEmptyState = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      padding="large"
    >
      <Stack gap="large" align="center">
        <SpotNoResults />

        <Stack align="center" gap="xxlarge">
          <Flex direction="column" gap="large" alignItems="center">
            <Heading level="6">No books found</Heading>
            <Text size="xsmall" color="muted">
              There’s no book matching your filter. Please try again.
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Flex>
  );
};
