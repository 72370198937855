/** @jsx jsx */

import { useEffect } from 'react';
import { jsx } from '@balance-web/core';
import { Divider } from '@balance-web/divider';
import { Flex } from '@balance-web/flex';

import { keysOf, validateStepsSchema } from './utils';
import type { WizardProgressItemProps } from './WizardProgressItem';
import { WizardProgressItem } from './WizardProgressItem';

export type WizardProgressStep = Omit<
  WizardProgressItemProps,
  'active' | 'number'
>;

export type WizardProgressSteps = Record<number, WizardProgressStep>;

export type WizardProgressProps = {
  steps: WizardProgressSteps;
  currentStep: number;
};

export function WizardProgress(props: WizardProgressProps) {
  const { steps, currentStep } = props;

  useEffect(() => {
    validateStepsSchema(steps);
  }, [steps]);

  return (
    <Flex
      aria-label="Progress"
      as="ol"
      // style
      alignItems="center"
      gap="small"
      justifyContent="center"
      padding="medium"
    >
      {keysOf(steps).map((key, i) => (
        <Flex key={key} as="li" minWidth={0}>
          {i ? <ItemSeparator /> : null}

          <WizardProgressItem
            {...steps[key]}
            number={i + 1}
            active={Number(key) === Number(currentStep)}
          />
        </Flex>
      ))}
    </Flex>
  );
}

// Styled components
// ------------------------------

const ItemSeparator = () => (
  <Flex
    alignItems="center"
    direction="column"
    justifyContent="center"
    marginX="large"
    width="2vw"
  >
    <Divider />
  </Flex>
);
