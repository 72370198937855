/** @jsx jsx */

import type { ChangeEvent } from 'react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { jsx } from '@balance-web/core';
import { useId } from '@balance-web/utils';

import { Wrapper } from './Wrapper';
import { Divider } from './Divider';
import { Handle } from './Handle';
import { RadioButton } from './RadioButton';
import type { RectType } from './types';

export type RadioButtonGroupProps<Value extends string | number | undefined> = {
  /** The name of the radio group. */
  name?: string;
  /** Function called when the value changes. */
  onChange: (value: Value) => void;
  /** The options for the radios of the radio button group. */
  options: Readonly<{ label: string; value: Value }[]>;
  /** The current value of the radio button group. */
  value?: Readonly<Value>;
};

export const RadioButtonGroup = <Value extends string | number | undefined>({
  name: consumerName,
  onChange,
  options,
  value,
  ...props
}: RadioButtonGroupProps<Value>) => {
  const [selectedRect, setSelectedRect] = useState<RectType>();
  const name = useId(consumerName);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<{
    element: HTMLLabelElement | null;
    index: number;
  } | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let stringValue = event.target.value;
    let parsedValue = Number(stringValue);
    let resolvedValue = (Number.isNaN(parsedValue)
      ? stringValue
      : parsedValue) as Value;

    onChange(resolvedValue);
  };

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    const targetElement = selectedRef.current;

    if (wrapperElement && targetElement) {
      // @ts-ignore not yet typed
      const resizeObserver = new ResizeObserver(() => {
        if (!targetElement.element) {
          return;
        }

        let wrapperRect = wrapperElement.getBoundingClientRect();
        let targetRect = targetElement.element.getBoundingClientRect();

        // uses relative positioning within the Wrapper
        const simpleRect = {
          left: targetRect.x - wrapperRect.x,
          top: targetRect.y - wrapperRect.y,
          height: targetRect.height,
          width: targetRect.width,
        };

        setSelectedRect({ ...simpleRect, index: targetElement.index });
      });

      resizeObserver.observe(wrapperElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [value]);

  return (
    <Wrapper ref={wrapperRef} optionCount={options.length} {...props}>
      {options.map((opt, index) => {
        const checked = opt.value === value;

        return (
          <Fragment key={opt.value}>
            {index ? <Divider /> : null}

            <RadioButton
              index={index}
              optionCount={options.length}
              checked={checked}
              name={name}
              onChange={handleChange}
              ref={(ref) => {
                if (checked) {
                  selectedRef.current = {
                    element: ref,
                    index,
                  };
                }
              }}
              value={opt.value}
            >
              {opt.label}
            </RadioButton>
          </Fragment>
        );
      })}

      <Handle rect={selectedRect} optionCount={options.length} />
    </Wrapper>
  );
};
