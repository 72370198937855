/** @jsx jsx */
import type { ReactNode } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { MenuContentProps } from '@radix-ui/react-dropdown-menu';
import type { CSSObject, WithDataAttributeProp } from '@balance-web/core';
import { buildDataAttributes, jsx } from '@balance-web/core';
import { useRawTheme } from '@balance-web/theme';

import { useDropdownContext } from '../context';
import { DEFAULT_VIEWPORT_ITEM_COUNT } from '../constants';

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from './shared-styles';

export type ContentProps = WithDataAttributeProp<
  { children: ReactNode } & Pick<
    MenuContentProps,
    'alignOffset' | 'sideOffset' | 'align'
  > & { viewportItemCount?: number }
>;

export const Content = ({
  sideOffset,
  alignOffset,
  align,
  data,
  viewportItemCount,
  children,
}: ContentProps) => {
  const { spacing, radii, elevation } = useRawTheme();
  const dropdownContext = useDropdownContext();

  const contentStyles: CSSObject = {
    zIndex: elevation.dropdown,
    minWidth: 220,
    backgroundColor: 'white',
    borderRadius: radii.medium,
    padding: spacing.small,
    maxHeight:
      (viewportItemCount || DEFAULT_VIEWPORT_ITEM_COUNT) *
      dropdownContext.itemHeight,
    overflowY: 'auto',
    boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.2);`,
    '@media (prefers-reduced-motion: no-preference)': {
      animationDuration: '400ms',
      animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
      willChange: 'transform, opacity',
      '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
      },
    },
  };

  const dataAttributes = buildDataAttributes(data);

  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        css={contentStyles}
        sideOffset={sideOffset || spacing.xsmall}
        alignOffset={alignOffset}
        align={align}
        {...dataAttributes}
      >
        {children}
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  );
};
