/** @jsx jsx */

import { Fragment, ReactNode, forwardRef } from 'react';
import { jsx } from '@balance-web/core';
import { DefaultTextPropsProvider, Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';

import { RadioPrimitive } from './RadioPrimitive';
import { useRadioGroupContext } from './context';
import { useRadioGroupItem } from './useRadioGroupState';
import { RadioProps } from './types';

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ children, ...consumerProps }, ref) => {
    const { spacing } = useTheme();
    const groupState = useRadioGroupContext();
    const inputProps = groupState
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useRadioGroupItem(consumerProps, groupState)
      : consumerProps;
    const isSmall = inputProps.size === 'small';

    return (
      <label css={{ alignItems: 'flex-start', display: 'inline-flex' }}>
        <RadioPrimitive ref={ref} {...inputProps} />
        <DefaultTextPropsProvider
          color={inputProps.disabled ? 'dim' : undefined}
          size={isSmall ? 'xsmall' : 'small'}
          weight="medium"
          leading={isSmall ? 'tight' : undefined}
        >
          <div
            css={{
              marginLeft: spacing.small,
              paddingTop: spacing.xxsmall,
              userSelect: 'none',
            }}
          >
            <Content>{children}</Content>
          </div>
        </DefaultTextPropsProvider>
      </label>
    );
  }
);

const Content = ({ children }: { children: ReactNode }) => {
  // Support "naked" strings as children
  if (typeof children === 'string' || typeof children === 'number') {
    return <Text>{children}</Text>;
  }

  // NOTE: fragment resolves TS issue with component return types
  return <Fragment>{children}</Fragment>;
};
