/** @jsx jsx */
import { createContext, useContext, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import useResizeObserver from 'use-resize-observer';
import type { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { useForkedRef } from '@balance-web/utils';
import { Dropdown } from '@balance-web/dropdown-v2';
import { Flex } from '@balance-web/flex';

import type { NavigationManagerContextType, NestedPage } from './types';
import { createDropdownNavigationTrigger } from './NavigationItemDropdownTrigger';
import { NavigationItemWrapper } from './NavigationItemWrapper';
import { NavigationItemAsDropdownMenu } from './NavigationItemAsDropdownMenu';
import { isPageSelected } from './isPageSelected';
import { createTestId } from './utils';

export type NavigationProps = {
  selectedPage: string;
  pages: Array<NestedPage>;
  globalActions?: ReactNode;
};

/**
 * Responsible for collapsiing overflowing navigation items into a "More" dropdown menu.
 * */
export const Navigation = ({
  pages,
  selectedPage,
  globalActions,
}: NavigationProps) => {
  const navigationItemsContainerRef = useRef<Element | null>(null);
  const actionButtonsContainerRef = useRef<HTMLDivElement | null>(null);
  const navigationItemRefs = useRef<Record<string, Element>>(
    pages.reduce((acc, page) => ({ ...acc, [page.label]: null }), {})
  );

  const [overFlowingPages, setOverflowingPages] = useState<Array<NestedPage>>(
    []
  );

  const isMoreSelected = useMemo(
    () =>
      overFlowingPages.length
        ? isPageSelected(
            {
              label: 'More',
              href: '',
              options: overFlowingPages,
            },
            selectedPage
          )
        : false,
    [overFlowingPages, selectedPage]
  );

  const setNavigationItemRef = (label: string, el: Element) => {
    navigationItemRefs.current[label] = el;
  };

  const calculateOverflow = () => {
    if (
      Object.keys(navigationItemRefs.current).filter(
        (key) => !!navigationItemRefs.current[key]
      ).length === pages.length &&
      navigationItemsContainerRef.current
    ) {
      const containerWidth =
        navigationItemsContainerRef.current.clientWidth -
        (actionButtonsContainerRef.current?.clientWidth || 0) -
        100;
      let combinedWidths = 0;
      const overflowingPageKeys = Object.keys(
        navigationItemRefs.current
      ).filter((key) => {
        const el = navigationItemRefs.current[key];
        if (!el) return true;
        combinedWidths += el.clientWidth;
        return combinedWidths > containerWidth;
      });

      setOverflowingPages(
        pages.filter((page) => overflowingPageKeys.includes(page.label))
      );
    }
  };

  const debouncedCalculateOverflow = debounce(calculateOverflow, 500, {
    leading: true,
  });

  const { ref: resizeObserverRef } = useResizeObserver({
    ref: navigationItemsContainerRef,
    onResize: debouncedCalculateOverflow,
  });

  const composedRef = useForkedRef(
    resizeObserverRef,
    navigationItemsContainerRef
  );

  return (
    <NavigationContext.Provider
      value={{
        overFlowingPages,
        setNavigationItemRef,
      }}
    >
      <Flex
        flex="1"
        ref={composedRef}
        marginLeft="xlarge"
        overflow="auto"
        gap="xsmall"
      >
        <Flex>
          {pages.map((page) => {
            return overFlowingPages.find(
              (overflowingPage) => overflowingPage.label === page.label
            ) ? (
              <div
                key={page.label}
                css={{
                  position: 'absolute',
                  opacity: 0,
                  pointerEvents: 'none',
                }}
              >
                <NavigationItemWrapper
                  page={page}
                  pathFragment={selectedPage}
                  visible={false}
                />
              </div>
            ) : (
              <NavigationItemWrapper
                key={page.label}
                page={page}
                pathFragment={selectedPage}
              />
            );
          })}
          {overFlowingPages.length ? (
            <Dropdown
              verticalOffset={-5}
              data={{
                testid: createTestId('overflowing-navigation-dropdown'),
              }}
            >
              <Dropdown.Trigger>
                {createDropdownNavigationTrigger({
                  label: 'More',
                  isSelected: isMoreSelected,
                })}
              </Dropdown.Trigger>
              {overFlowingPages.map((page) => (
                <NavigationItemAsDropdownMenu
                  key={page.label}
                  page={page}
                  selectedPage={selectedPage}
                />
              ))}
            </Dropdown>
          ) : null}
        </Flex>
        {globalActions && (
          <Flex
            ref={actionButtonsContainerRef}
            justifyContent="center"
            alignItems="center"
            paddingLeft="xsmall"
          >
            {globalActions}
          </Flex>
        )}
      </Flex>
    </NavigationContext.Provider>
  );
};

const NavigationContext = createContext<NavigationManagerContextType | null>(
  null
);

export const useNavigationContext = () => {
  const navigationContext = useContext(NavigationContext);
  if (!navigationContext)
    throw Error('useNavigationContext can only be used inside <Navigation />');

  return navigationContext;
};
