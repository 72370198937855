import React, { forwardRef } from 'react';
import type { ReactNode } from 'react';
import { Box } from '@balance-web/box';
import { buildDataAttributes, useLinkComponent } from '@balance-web/core';
import { Inline } from '@balance-web/inline';
import { Flex } from '@balance-web/flex';
import type {
  LinkComponentProps,
  WithDataAttributeProp,
} from '@balance-web/core';

import { ListItemLayout } from './ListItemLayout';

export type ListItemProps = WithDataAttributeProp<
  Partial<Omit<LinkComponentProps, 'children'>> & {
    children: ReactNode;
    selected?: boolean;
    startElement?: ReactNode;
    endElement?: ReactNode;
    disabled?: boolean;
    onClick?: () => void;
  }
>;
export const ListItem = forwardRef<HTMLAnchorElement, ListItemProps>(
  (
    {
      children,
      startElement,
      endElement,
      selected,
      disabled,
      onClick,
      href,
      data,
      ...linkComponentProps
    },
    ref
  ) => {
    const LinkComponent = useLinkComponent();

    const dataAttributes = buildDataAttributes(data);

    const content = (
      <Inline gap="small" alignY="center" flex="1" paddingRight="medium">
        {startElement}
        <Flex flex="1" {...dataAttributes}>
          {children}
        </Flex>
        {endElement}
      </Inline>
    );

    const item = (
      <ListItemLayout
        selected={selected}
        listType="other"
        href={href}
        onClick={onClick}
      >
        {content}
      </ListItemLayout>
    );

    if (disabled) {
      return (
        <Box width="100%" {...dataAttributes}>
          <ListItemLayout listType="other">{content}</ListItemLayout>
        </Box>
      );
    }

    if (onClick) {
      return (
        <Box as="button" width="100%" onClick={onClick} {...dataAttributes}>
          {item}
        </Box>
      );
    }

    if (href) {
      return (
        <LinkComponent
          ref={ref}
          {...linkComponentProps}
          href={href}
          {...dataAttributes}
        >
          {item}
        </LinkComponent>
      );
    }

    return item;
  }
);
