/** @jsx jsx */
import { forwardRef } from 'react';
import { jsx, keyframes } from '@balance-web/core';
import * as Accordion from '@radix-ui/react-accordion';
import { useTheme } from '@balance-web/theme';

export type AccordionProps = {};

export const AccordionItemContent = forwardRef<HTMLDivElement, any>(
  ({ children, ...props }, forwardedRef) => {
    const theme = useTheme();

    return (
      <Accordion.Content
        css={{
          overflow: 'hidden',
          animationDuration: '300ms',
          animationTimingFunction: 'ease-out',
          paddingLeft: theme.spacing.large,
          paddingTop: theme.spacing.large,
          paddingRight: theme.spacing.xlarge,
          paddingBottom: theme.spacing.xlarge,

          // fix animation post release
          // '&[data-state=open]': {
          //   animationName: slideDown,
          // },
          // '&[data-state=closed]': {
          //   animationName: slideUp,
          // },
        }}
        {...props}
        ref={forwardedRef}
      >
        <div>{children}</div>
      </Accordion.Content>
    );
  }
);

export const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

export const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});
