import { LoadScript } from '@reckon-web/load-script';

export const BRANCHIO_FEATURES = [
  'addListener',
  'applyCode',
  'autoAppIndex',
  'banner',
  'closeBanner',
  'closeJourney',
  'creditHistory',
  'credits',
  'data',
  'deepview',
  'deepviewCta',
  'first',
  'getCode',
  'init',
  'link',
  'logout',
  'redeem',
  'referrals',
  'removeListener',
  'sendSMS',
  'setBranchViewData',
  'setIdentity',
  'track',
  'validateCode',
  'trackCommerceEvent',
  'logEvent',
  'disableTracking',
] as const;

type Feature = typeof BRANCHIO_FEATURES[number];

export type LoadBranchIoServiceProps = {
  apiKey: string;
  features?: readonly Feature[];
  libraryUrl?: string;
};

export async function LoadBranchIoService({
  apiKey,
  features = BRANCHIO_FEATURES,
  libraryUrl = 'https://cdn.branch.io/branch-latest.min.js',
}: LoadBranchIoServiceProps) {
  if (!apiKey) {
    console.warn('⚠️ Warning: skipping loading branch.io: no api key provided');
    return;
  }

  const featuresString = JSON.stringify(features || []);
  return await LoadScript({
    id: 'branchio-service',
    content: `function load(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="${libraryUrl}";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}}; load( window, document, "script", "branch", function(b,r){b[r]=function(){b._q.push([r,arguments])}}, {_q:[],_v:1}, ${featuresString}, 0);branch.init("${apiKey}");`,
    parent: 'head',
  });
}
