/** @jsx jsx */
import React, { useEffect } from 'react';
import type { ReactElement } from 'react';
import { useVirtual } from 'react-virtual';
import { jsx } from '@balance-web/core';

import { useVirtualTheme } from './useVirtualTheme';
import { useItemMap } from './useItemMap';

type PaginatedScrollingListProps<T> = {
  items: T[];
  itemSize: number;
  canLoadMore: boolean;
  loadingState: 'loading' | 'loadingMore' | 'error' | 'idle';
  itemIdentifier: (item: T) => string;
  itemRenderer: (item: T) => ReactElement;
  onLoadMore: () => void;
};

export function PaginatedScrollingList<T>({
  canLoadMore,
  itemSize,
  loadingState,
  items,
  itemIdentifier,
  itemRenderer,
  onLoadMore,
}: PaginatedScrollingListProps<T>) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const itemCount = items.length;
  const theme = useVirtualTheme(itemSize);

  const rowVirtualizer = useVirtual({
    size: canLoadMore ? itemCount + 1 : itemCount,
    parentRef,
    ...theme,
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.virtualItems].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= itemCount - 1 &&
      canLoadMore &&
      typeof onLoadMore === 'function' &&
      loadingState !== 'loadingMore'
    ) {
      onLoadMore();
    }
  }, [
    canLoadMore,
    onLoadMore,
    itemCount,
    loadingState,
    rowVirtualizer.virtualItems,
  ]);

  const itemMap = useItemMap({
    itemIdentifier,
    itemRenderer,
    items,
  });

  return (
    <div
      ref={parentRef}
      css={{
        width: '100%',
        flex: '1',
        overflow: 'auto',
      }}
    >
      <div
        css={{
          height: `${rowVirtualizer.totalSize}`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.virtualItems.map(itemMap)}
      </div>
    </div>
  );
}
