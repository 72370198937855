import React, { ReactNode } from 'react';
import { CSSObject } from '@balance-web/core';
import { Flex } from '@balance-web/flex';

import { APP_HEADER_HEIGHT } from './constants';

export type AppHeaderProps = {
  children: ReactNode;
  /** Height of the header */
  height?: CSSObject['height'];
};

export const AppHeader = ({ children, height }: AppHeaderProps) => {
  return <Flex height={height || APP_HEADER_HEIGHT}>{children}</Flex>;
};
