/** @jsx jsx */

import { ReactNode, forwardRef, useMemo } from 'react';
import { buildDataAttributes, jsx } from '@balance-web/core';
import { DefaultTextPropsProvider } from '@balance-web/text';

// Component
// ------------------------------

export type TableHeadProps = UseHeadStylesProps & {
  /** The elements that make up the table header. */
  children: ReactNode;
};

export const TableHead = forwardRef<HTMLDivElement, TableHeadProps>(
  ({ children, ...props }, ref) => {
    const { dataAttributes, css, style } = useHeadStyles(props);
    return (
      <DefaultTextPropsProvider size="xsmall">
        <div
          ref={ref}
          role="rowgroup"
          css={css}
          style={style}
          {...dataAttributes}
        >
          {children}
        </div>
      </DefaultTextPropsProvider>
    );
  }
);

// Styles
// ------------------------------

type UseHeadStylesProps = {
  stickyOffset?: number;
};

export function useHeadStyles(props: UseHeadStylesProps) {
  const { stickyOffset } = props;

  // dynamic properties
  const style = useMemo(() => ({ top: stickyOffset }), [stickyOffset]);

  // static-ish properties
  const sticky = typeof stickyOffset !== 'undefined';
  const dataAttributes = useMemo(
    () => buildDataAttributes({ sticky, type: 'head' }, 'rowgroup'),
    [sticky]
  );

  // declarations
  const css = useMemo(
    () => [
      {
        display: 'flex',
        flexDirection: 'column',
      } as const,
      {
        [`&[data-rowgroup-sticky="true"]`]: {
          position: 'sticky',
          zIndex: 1,
        } as const,
      },
    ],
    []
  );

  return { dataAttributes, css, style };
}
