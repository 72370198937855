import React, { useCallback, useMemo } from 'react';
import type { ReactElement, ReactNode } from 'react';
import { Stack } from '@balance-web/stack';
import { Text } from '@balance-web/text';

import { createEventEmitter } from '@reckon-web/events';

import type { ArrayFieldEventsType } from './events';
import { ArrayFieldProvider } from './context';
import { ArrayFieldItem } from './ArrayFieldItem';
import { ArrayFieldItems } from './ArrayFieldItems';
import { ArrayFieldAddButton } from './ArrayFieldAddButton';
import type { ArrayFieldVarint } from './types';

type ArrayFieldProps = {
  /* Name of the entity you're trying to add.  */
  label: string;
  /** Supporting description for the entity. */
  description?: ReactElement | string;
  variant: ArrayFieldVarint;
  children?: ReactNode;
};

export const ArrayField = ({
  label,
  description,
  variant,
  children,
}: ArrayFieldProps) => {
  /**
   * Used to let items know when an item has been added for
   *  behaviour such as collapse-on-add and scroll-to-new-item.
   */
  const eventEmitter = useMemo(() => {
    return createEventEmitter<ArrayFieldEventsType>();
  }, []);

  const subscribeToItemAdded = useCallback(
    (callback: (index: number) => void) => {
      return eventEmitter.subscribe('itemAdded', callback);
    },
    [eventEmitter]
  );

  const broadcastItemAdded = useCallback(
    (index: number) => {
      eventEmitter.broadcast('itemAdded', [index]);
    },
    [eventEmitter]
  );

  return (
    <ArrayFieldProvider
      variant={variant}
      label={label}
      subscribeToItemAdded={subscribeToItemAdded}
      broadcastItemAdded={broadcastItemAdded}
    >
      <Stack gap="small">
        <Text size="small" weight="medium">
          {label}
        </Text>
        {description && (
          <Text size="small" color="muted">
            {description}
          </Text>
        )}
        <Stack gap="large">{children}</Stack>
      </Stack>
    </ArrayFieldProvider>
  );
};

ArrayField.Items = ArrayFieldItems;
ArrayField.Item = ArrayFieldItem;
ArrayField.AddButton = ArrayFieldAddButton;
