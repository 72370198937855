import { useEffect, useRef, useState } from 'react';

import { createItemQueue } from './createItemQueue';
import { QueueOptions, QueueTask } from './types';
import { createTaskEvents } from './createTaskEvents';

export function createItemQueueHook<
  /** a task output */
  TItem,
  /** a task */
  TTask extends QueueTask<TItem> = () => Promise<TItem>
>() {
  const events = createTaskEvents<TItem, TTask>();
  return (options: QueueOptions) => {
    const [total, setTotal] = useState(0);
    const queue = useRef(
      createItemQueue<TItem, TTask>({ events, ...options })
    );
    // this effect resets the count when it's actually empty
    useEffect(() => {
      const onEmpty = events.subscribe('queueEmpty', () => {
        setTotal(() => 0);
      });

      const onNewTask = events.subscribe('newTask', () => {
        setTotal((total) => total + 1);
      });

      return () => {
        onEmpty();
        onNewTask();
      };
    }, []); // intentionally only running once

    return {
      events,
      total,
      id: queue.current.queueId,
      tasks: queue.current.tasks,
      isEmpty: queue.current.isEmpty(),
      isAvailable: queue.current.isAvailable(),
      addTask: queue.current.add,
      subscribe: events.subscribe,
    };
  };
}
