/** @jsx jsx */
import { ReactNode } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { buildDataAttributes } from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';
import { CSSObject, jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from './shared-styles';

type SubMenuContentProps = WithDataAttributeProp<{ children: ReactNode }>;

export const SubMenuContent = ({ data, children }: SubMenuContentProps) => {
  const { spacing, radii, elevation } = useTheme();

  const subContentStyles: CSSObject = {
    minWidth: 220,
    backgroundColor: 'white',
    borderRadius: radii.medium,
    padding: spacing.small,
    boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.2)`,
    '@media (prefers-reduced-motion: no-preference)': {
      animationDuration: '400ms',
      animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
      willChange: 'transform, opacity',
      '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
      },
    },
    zIndex: elevation.dropdown + 1, // keeps the elevation scheme consistent and only makes submenu appear over menu
  };

  const dataAttributes = buildDataAttributes(data);

  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.SubContent
        css={subContentStyles}
        sideOffset={0}
        alignOffset={-5}
        {...dataAttributes}
      >
        {children}
      </DropdownMenuPrimitive.SubContent>
    </DropdownMenuPrimitive.Portal>
  );
};
