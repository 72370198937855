import * as React from 'react';
import { createIllustration } from '@balance-web/illustration';

export const ReckonGovConnectLogoName = 'ReckonGovConnectLogo';
export const ReckonGovConnectAppName = 'Reckon GovConnect';
export const ReckonGovConnectAppLongName = 'Reckon GovConnect';
export const ReckonGovConnectLogoDescription = 'Reckon GovConnect Logo';

export const ReckonGovConnectLogo = createIllustration(
  {
    name: ReckonGovConnectLogoName,
    description: ReckonGovConnectLogoDescription,
    width: 32,
    height: 32,
  },
  <React.Fragment>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.771484 31.0395V8.13827C0.771484 4.16911 3.99928 0.942383 7.96976 0.942383H23.6803C27.6508 0.942383 30.8786 4.16911 30.8786 8.13827V23.8436C30.8786 27.8127 27.6508 31.0395 23.6803 31.0395H0.771484Z"
      fill="#FF005D"
    />
    <path
      d="M9.74023 7.93835H16.9099C18.938 7.93835 20.5091 8.53801 21.5374 9.53744C22.3944 10.4226 22.88 11.5934 22.88 12.9926V13.0497C22.88 15.6768 21.3089 17.2759 19.0809 17.9041L23.4227 23.9863H20.0521L16.1101 18.3895H12.5681V23.9863H9.74023V7.93835ZM16.71 15.9052C18.7381 15.9052 20.0235 14.8487 20.0235 13.221V13.1639C20.0235 11.4506 18.7952 10.5083 16.6814 10.5083H12.5681V15.9052H16.71Z"
      fill="white"
    />
  </React.Fragment>
);
