import { useCallback, useMemo, useReducer } from 'react';

import { OpenStateActions } from './constants';
import type { OpenState, OpenStateAction, UseOpenStateResult } from './types';

// a factory that creates a hook
// the factory accepts an array of strings representing the names of things to track open state of
// the hook uses `useReducer` to manage this

/**
 * A hook that creates state that tracks open/closed/on/off state for provided topics
 *
 * @example
 *
 *   With implied initial state of `false`:
 *
 *   ```
 *     const { open, close, toggle } = useOpenState(['one', 'dog', 'blue']);
 *   ```
 *
 *   or set an initial value with:
 *
 *   ```
 *     const { open, close, toggle } = useOpenState({
 *       one: false,
 *       dog: true,
 *       blue: false,
 *     });
 *   ```
 *
 *   and then
 *
 *   ```
 *     const handleCloseOne = () => close('one')
 *     const handleToggleOne = () => toggle('one')
 *     const handleOpenOne = () => open('one')
 *   ```
 *
 */
export function useOpenState<TName extends string>(
  names: OpenState<TName> | TName[]
): UseOpenStateResult<TName> {
  // create initial state of the names set to closed
  const initialObject = useMemo((): OpenState<TName> => {
    if (Array.isArray(names)) {
      return names.reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {} as OpenState<TName>);
    }
    return names;
  }, [names]);

  const openStateReducer = useCallback(
    (state: OpenState<TName>, action: OpenStateAction<TName>) => {
      const { type, payload } = action;

      // payload will always be an array of strings
      // so if one of the items in the array is not a string, we can ignore the action
      if (!Array.isArray(payload)) {
        return state;
      }

      switch (type) {
        case 'SET_STATE_CLOSE': {
          const newState = payload.reduce<OpenState<TName>>((results, name) =>
            // items must be strings and exist in the original list of names
            {
              return !!name &&
                typeof name === 'string' &&
                state.hasOwnProperty(name)
                ? {
                    ...results,
                    [name]: false,
                  }
                : results;
            }, {} as OpenState<TName>);
          return { ...state, ...newState };
        }

        case 'SET_STATE_OPEN': {
          const newState = payload.reduce<OpenState<TName>>((results, name) =>
            // items must be strings and exist in the original list of names
            {
              return !!name &&
                typeof name === 'string' &&
                state.hasOwnProperty(name)
                ? {
                    ...results,
                    [name]: true,
                  }
                : results;
            }, {} as OpenState<TName>);
          return { ...state, ...newState };
        }

        case 'SET_STATE_TOGGLE': {
          const newState = payload.reduce<OpenState<TName>>((results, name) =>
            // items must be strings and exist in the original list of names
            {
              return !!name &&
                typeof name === 'string' &&
                state.hasOwnProperty(name)
                ? {
                    ...results,
                    [name]: !state[name],
                  }
                : results;
            }, {} as OpenState<TName>);
          return { ...state, ...newState };
        }

        case 'SET_STATE_CLOSE_ALL': {
          const newState: OpenState<TName> = Object.assign(state);
          for (const key in newState) {
            newState[key] = false;
          }
          return newState;
        }

        default: {
          return state;
        }
      }
    },
    []
  );

  const [state, dispatch] = useReducer(openStateReducer, initialObject);

  return {
    isOpen: state,
    open: useCallback((...payload: TName[]) => {
      return dispatch({ type: OpenStateActions.open, payload });
    }, []),
    close: useCallback((...payload: TName[]) => {
      return dispatch({ type: OpenStateActions.close, payload });
    }, []),
    toggle: useCallback((...payload: TName[]) => {
      return dispatch({ type: OpenStateActions.toggle, payload });
    }, []),
    closeAll: useCallback(() => {
      return dispatch({ type: OpenStateActions.closeall, payload: [] });
    }, []),
  };
}
