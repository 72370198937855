import { useTheme } from '@balance-web/theme';
import type { CSSObject } from '@balance-web/core';
import { ternary } from '@balance-web/utils';

export const useListSeparatorStyles = () => {
  const theme = useTheme();

  const styles: CSSObject = {
    '&:not([data-no-border])': {
      '&:not(:last-child)': {
        position: 'relative',
        '::after': {
          background: theme.palette.global.border,
          content: '" "',
          display: 'block',
          height: theme.borderWidth.standard,
          width: '100%',
          position: 'absolute',
          left: 0,
          bottom: 0,
        },
      },
    },
  };

  return styles;
};

export const useItemHighlightingStyles = (selected?: boolean) => {
  const { radii } = useTheme();

  const styles: CSSObject = {
    borderRadius: radii.small,
    textDecoration: 'none',
    cursor: 'pointer',
    ...ternary(
      !!selected,
      {
        backgroundColor: '#eeecff', // update to use theme when there's time to implement interactions as overlays
        position: 'relative',
      },
      {
        ':hover, &.focus-visible': {
          backgroundColor: '#f4f3ff', // update to use theme when there's time to implement interactions as overlays
          position: 'relative',
        },
        ':active': {
          backgroundColor: '#eeecff', // update to use theme when there's time to implement interactions as overlays
          position: 'relative',
        },
      }
    ),
  };

  return styles;
};

export const useGutters = () => {
  const { spacing } = useTheme();

  const outterGutter = spacing.medium;
  const innerGutter = spacing.medium;

  return {
    outterGutter,
    innerGutter,
  };
};
