/** @jsx jsx */
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { MenuRadioItemProps } from '@radix-ui/react-dropdown-menu';
import type { WithDataAttributeProp } from '@balance-web/core';
import {
  buildDataAttributes,
  getTestIdFromDataAttributes,
  jsx,
} from '@balance-web/core';

import { useTestId } from '../context';

import { useItemStyles } from './shared-styles';

type RadioItemContainerProps = WithDataAttributeProp<MenuRadioItemProps>;

export const RadioItemContainer = ({
  data,
  ...props
}: RadioItemContainerProps) => {
  const itemStyles = useItemStyles();

  const parentTestId = useTestId();
  const dataAttributes = buildDataAttributes(data);

  const radioItemTestId = getTestIdFromDataAttributes(data);
  const prefixedItemTestId =
    parentTestId && radioItemTestId
      ? `${parentTestId}-${radioItemTestId}`
      : radioItemTestId;

  const triggerTestIdProp = prefixedItemTestId
    ? { 'data-testid': prefixedItemTestId }
    : {};

  return (
    <DropdownMenuPrimitive.RadioItem
      css={itemStyles}
      {...props}
      {...dataAttributes}
      {...triggerTestIdProp}
    />
  );
};
