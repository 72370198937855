/** @jsx jsx */
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Stack } from '@balance-web/stack';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MinusCircleIcon,
} from '@balance-web/icon';
import { Flex } from '@balance-web/flex';
import { Text } from '@balance-web/text';
import { Box } from '@balance-web/box';
import { Button, IconButton } from '@balance-web/button';
import type { ReactNode } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { makeId, useForkedRef, useId } from '@balance-web/utils';
import { Fieldset } from '@balance-web/fieldset';

import { useArrayField } from './context';

type ArrayFieldItemProps = {
  /**
   * Index of the item in the list to help the user keep
   *  track of items.
   * */
  index: number;
  /**
   * Label for the fieldset.
   */
  legend: string;
  /** Summary title for when the item is collapsed. */
  collapsedTitle: string;
  /** Render slot for displaying important context when collapsed. */
  collapsedSummary?: ReactNode;
  /** Click handler for the remove button. */
  onRemove?: () => void;
  /** List of errors for the item. Make sure to de-duplicate! */
  errors?: Array<string>;
  children?: ReactNode;
  /**
   * DONOT USE THIS PROP.
   *
   * Internal prop for expand/collapse on add/validate functionality.
   * */
  _newItem?: boolean;
};

export const ArrayFieldItem = forwardRef<HTMLLIElement, ArrayFieldItemProps>(
  (
    {
      index,
      legend,
      collapsedTitle,
      onRemove,
      collapsedSummary,
      errors,
      children,
      _newItem,
    }: ArrayFieldItemProps,
    forwardedRef
  ) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(_newItem);

    const ChevronIcon = isExpanded ? ChevronUpIcon : ChevronDownIcon;

    const instanceId = useId();
    const errorsId = makeId(instanceId, 'errors');

    const { variant, label, subscribeToItemAdded } = useArrayField();

    const rootRef = useRef<HTMLElement | null>(null);
    const composedRef = useForkedRef(rootRef, forwardedRef);

    /** Scroll to item if it's newly added. */
    useEffect(() => {
      if (_newItem) {
        rootRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
    }, [_newItem]);

    /**
     * Collapse if a newly item added is not this item.
     *
     * The index passed in the event is the index passed to the item, not the array index.
     */
    useEffect(() => {
      return subscribeToItemAdded((newIndex) => {
        setIsExpanded(newIndex === index);
      });
    }, [index, subscribeToItemAdded]);

    /**
     * Focus the first interactive element when the item is expanded.
     *
     * Uncomment this when onBlur form validation has been removed.
     * */
    useEffect(() => {
      if (isExpanded) {
        const focusable:
          | HTMLInputElement
          | null
          | undefined = rootRef.current?.querySelector(
          'fieldset > button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        focusable?.focus();
      }
    }, [isExpanded]);

    return (
      <Stack
        ref={composedRef}
        as="li"
        gap="small"
        aria-invalid={!isExpanded && errors?.length ? 'true' : 'false'}
        aria-describedby={!isExpanded && errors?.length ? errorsId : undefined}
      >
        <Stack
          gap="xlarge"
          paddingX="large"
          paddingTop="large"
          paddingBottom="xxlarge"
          borderRadius="large"
          css={{
            border: `${theme.borderWidth.standard} solid ${
              isExpanded
                ? theme.palette.border.standard
                : errors?.length
                ? theme.palette.text.critical
                : theme.palette.border.standard
            }`,
          }}
        >
          <Stack gap="small">
            <Flex gap="large" alignItems="center">
              {variant === 'ordered' && (
                <Text
                  size="small"
                  weight="semibold"
                  color={errors?.length ? 'critical' : 'base'}
                >
                  {index}
                </Text>
              )}
              <Box
                flex="1"
                paddingLeft={variant === 'ordered' ? 'none' : 'xlarge'}
              >
                {!isExpanded ? (
                  <Text size="small" weight="semibold">
                    {collapsedTitle}
                  </Text>
                ) : null}
              </Box>
              {isExpanded && onRemove ? (
                <Button
                  size="small"
                  label="Remove item"
                  variant="text"
                  colorScheme="tertiary"
                  iconBefore={MinusCircleIcon}
                  onClick={onRemove}
                />
              ) : null}
              <IconButton
                label={`Toggle ${label} ${index}`}
                icon={ChevronIcon}
                size="small"
                variant="text"
                colorScheme={errors?.length ? 'critical' : 'primary'}
                onClick={() => {
                  return setIsExpanded((isExpanded) => {
                    return !isExpanded;
                  });
                }}
                aria-expanded={isExpanded}
                aria-controls={instanceId}
              />
            </Flex>
            {!isExpanded ? (
              <Box paddingLeft="xlarge" paddingRight="xxlarge">
                {collapsedSummary}
              </Box>
            ) : null}
          </Stack>
          {isExpanded ? (
            <Box id={instanceId} paddingX="large">
              <Fieldset legend={legend} legendAppearance="hidden">
                {children}
              </Fieldset>
            </Box>
          ) : null}
        </Stack>
        {!isExpanded && errors?.length ? (
          <Stack id={errorsId} role="alert">
            {errors.map((error) => {
              return (
                <Text key={error} color="critical">
                  {error}
                </Text>
              );
            })}
          </Stack>
        ) : null}
      </Stack>
    );
  }
);
