import React from 'react';
import { Flex } from '@balance-web/flex';
import { Box } from '@balance-web/box';
import type { ReactNode } from 'react';

import { CollapsableTrigger } from './radix-styled-components/CollapsibleTrigger';
import { useCollapsableContext } from './context';

type CollapsableHeaderProps = {
  children: ReactNode;
};

export const CollapsableHeader = ({ children }: CollapsableHeaderProps) => {
  const context = useCollapsableContext();

  return (
    <Flex gap="large" alignItems="center">
      <Box flex="1">{children}</Box>
      <CollapsableTrigger open={context.isOpen} />
    </Flex>
  );
};
