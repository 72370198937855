import { useContext } from 'react';

import type { OpenStateContext } from './types';

/**
 * Creates a hook that can be used to access the state created by a `createOpenStateProvider`
 *
 *  @example
 *    const MyThingsToTrack = ['a', 'b', 'c'] as const;
 *    export const MyOpenStateContext = createOpenStateContext(MyThingsToTrack);
 *    export const MyOpenStateProvider = createOpenStateProvider(MyThingsToTrack, MyOpenStateContext);
 *    export const useMyOpenStateHook = createOpenStateHook(MyOpenStateContext);
 *
 * @param osContext
 * @returns
 */

export function createOpenStateHook<TName extends string>(
  osContext: OpenStateContext<TName[]>
) {
  return () => {
    const context = useContext(osContext);
    if (!context) {
      throw new Error(
        'a useOpenState can only be used underneath its Provider.'
      );
    }
    return context;
  };
}
