import { useMemo } from 'react';
import useHashParam from 'use-hash-param';

export function useBookIdHashParam(defaultBookId: string = '') {
  const [storedBookId, setStoredBookId] = useHashParam('bookId', defaultBookId);

  const bookId = useMemo(() => {
    return storedBookId || defaultBookId;
  }, [defaultBookId, storedBookId]);

  return {
    bookId,
    setBookId: setStoredBookId,
  };
}
