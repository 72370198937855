import React from 'react';

import { ItemContainer, ItemContainerProps } from './radix-styled-components';
import { ItemBase, ItemBaseProps } from './ItemBase';

export type ItemProps = ItemBaseProps & ItemContainerProps;

export const Item = ({
  tone,
  startElement,
  endElement,
  children,
  data,
  ...props
}: ItemProps) => {
  return (
    <ItemContainer {...props} data={data}>
      <ItemBase
        tone={tone}
        startElement={startElement}
        endElement={endElement}
        {...props}
      >
        {children}
      </ItemBase>
    </ItemContainer>
  );
};
