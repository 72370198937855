import * as React from 'react';
import useHashParam from 'use-hash-param';

import { useAuth } from './useAuth';

export type UrlFragmentAuthManagerProps = React.PropsWithChildren<{
  topkenParam?: string;
}>;

export const UrlFragmentAuthManager = ({
  topkenParam = 'token',
  children,
}: UrlFragmentAuthManagerProps) => {
  const [accessToken, setAccessToken] = useHashParam(topkenParam, '');
  const {
    setIsLoading,
    isLoading,
    isAuthenticated,
    setCredentials,
    credentials,
  } = useAuth();

  React.useEffect(() => {
    // if we find the token on the url, wipe it from the url ASAP. (it's still here for the life of this function)
    if (!!accessToken) {
      setAccessToken('');
    }

    if (!!isLoading && !!accessToken) {
      // If we're still loading and we have a token
      // then store it in localstorage, and
      // stop loading.
      setCredentials({ ...credentials, accessToken });
      setIsLoading(false);
    } else if (!!isAuthenticated) {
      // Otherwise if we're not authenticated, stop loading.
      setIsLoading(false);
    }
  }, [
    accessToken,
    credentials,
    isAuthenticated,
    isLoading,
    setAccessToken,
    setCredentials,
    setIsLoading,
  ]);

  return <>{children}</>;
};
