import React from 'react';

import {
  AccordianItem,
  AccordioRoot,
  AccordionItemContent,
  AccordionItemHeader,
} from './radix-styled-components';
import type { AccordionRootProps } from './radix-styled-components';

export type AccordionProps = AccordionRootProps;

export const Accordion = (props: AccordionProps) => {
  return <AccordioRoot {...props} />;
};

Accordion.Item = AccordianItem;
Accordion.ItemHeader = AccordionItemHeader;
Accordion.ItemContent = AccordionItemContent;
