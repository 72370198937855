import React, { forwardRef } from 'react';
import type { ReactNode } from 'react';
import { TextLink } from '@balance-web/text-link';
import { Text } from '@balance-web/text';

export type BreadCrumbLinkProps = {
  href: string;
  children: ReactNode;
};

export const BreadCrumbLink = forwardRef<
  HTMLAnchorElement,
  BreadCrumbLinkProps
>(({ href, children }, ref) => {
  return (
    <TextLink ref={ref} href={href} color="base">
      <Text size="xsmall" weight="medium">
        {children}
      </Text>
    </TextLink>
  );
});
