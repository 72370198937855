/** @jsx jsx */
import type { ReactNode } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { ChevronRightIcon } from '@balance-web/icon';

import { useTestId } from '../context';
import { ItemBase, ItemBaseProps, ItemSelectionProps } from '../ItemBase';

import { useItemStyles } from './shared-styles';

type SubMenuTriggerProps = {
  children: ReactNode;
} & ItemSelectionProps &
  Omit<ItemBaseProps, 'href' | 'target' | 'endElement'>;

export const SubMenuTrigger = ({ children, ...props }: SubMenuTriggerProps) => {
  const { palette } = useTheme();
  const itemStyles = useItemStyles();

  const triggerStyles = {
    '&[data-state="open"]': {
      backgroundColor: palette.interactive.base.hover,
    },
  };

  const parentTestId = useTestId();
  const triggerTestIdProp = parentTestId
    ? { 'data-testid': `${parentTestId}-trigger` }
    : {};

  return (
    <DropdownMenuPrimitive.SubTrigger
      css={{ ...triggerStyles, ...itemStyles }}
      disabled={props.disabled}
      {...triggerTestIdProp}
    >
      <ItemBase
        {...props}
        endElement={
          <ChevronRightIcon
            size="small"
            color={props.disabled ? 'dim' : 'base'}
          />
        }
      >
        {children}
      </ItemBase>
    </DropdownMenuPrimitive.SubTrigger>
  );
};
