import type { ReactElement } from 'react';
import React, { useCallback } from 'react';
import type { UseComboboxPropGetters } from 'downshift';
import type { VirtualItem } from 'react-virtual';

import { MenuItem, MenuItemLoadingIndicator } from './styled-components';

type UseItemMapProps<Item> = {
  getItemProps: UseComboboxPropGetters<Item>['getItemProps'];
  itemRenderer: (item: Item) => ReactElement;
  itemToDisabled: (item: Item) => boolean;
  itemToValue: (item: Item) => string | number;
  items: Item[];
  selectedItem: Item | Item[] | null;
};

export function useItemMap<Item>({
  getItemProps,
  itemRenderer,
  itemToDisabled,
  itemToValue,
  items,
  selectedItem,
}: UseItemMapProps<Item>) {
  return useCallback(
    (virtualRow: VirtualItem) => {
      // virtualized styles
      const style = {
        height: virtualRow.size,
        inset: 0,
        position: 'absolute' as const,
        transform: `translateY(${virtualRow.start}px)`,
      };

      // paginated results render a spinner as the last item
      const isLoadingRow = virtualRow.index > items.length - 1;
      if (isLoadingRow) {
        return (
          <MenuItemLoadingIndicator key="loading-indicator" style={style} />
        );
      }

      // transform consumer data
      const item = items[virtualRow.index];
      const disabled = itemToDisabled(item);
      const itemValue = itemToValue(item);
      const isSelected = Array.isArray(selectedItem)
        ? !!selectedItem.find((item) => {
            return itemToValue(item) === itemValue;
          })
        : !!selectedItem && itemToValue(selectedItem) === itemValue;

      return (
        <MenuItem
          // Prefer unique key to `virtualRow.index` — avoids issues with
          // React's reconciliation during search.
          key={itemValue}
          // NOTE: the `disabled` prop only affects mouse users :(
          // @see https://github.com/downshift-js/downshift/issues/1295
          {...getItemProps({
            disabled,
            index: virtualRow.index,
            isSelected,
            item,
            style,
            role: Array.isArray(items) ? 'menuitemcheckbox' : 'menuitemradio',
          })}
        >
          {itemRenderer(item)}
        </MenuItem>
      );
    },
    [
      itemRenderer,
      getItemProps,
      itemToDisabled,
      itemToValue,
      items,
      selectedItem,
    ]
  );
}
