/** @jsx jsx */

import type { HTMLAttributes } from 'react';
import { Box } from '@balance-web/box';
import { IconButton } from '@balance-web/button';
import { jsx } from '@balance-web/core';
import { Field } from '@balance-web/field';
import { Flex } from '@balance-web/flex';
import { ChevronLeftIcon } from '@balance-web/icon/icons/ChevronLeftIcon';
import { ChevronRightIcon } from '@balance-web/icon/icons/ChevronRightIcon';
import { Text } from '@balance-web/text';
import { useTheme } from '@balance-web/theme';
import { Tooltip } from '@balance-web/tooltip';
import { makeId, useId } from '@balance-web/utils';
import { SelectInput } from '@balance-web/select-input';

type PaginationProps = {
  /** The description for the button that decrements the page. */
  buttonPreviousText?: string;
  /** The description for the button that increments the page. */
  buttonNextText?: string;
  /** Optional ID for testing purposes. */
  id?: string;
  /** The text indicating the number of items per page. */
  itemsPerPageText?: string;
  /** The function returning text showing how many items are visible, contextually. */
  itemRangeText?: (min: number, max: number, total: number) => string;
  /** A function returning text showing where the current page is. */
  pageRangeText?: (current: number, total: number) => string;
  /** The callback function called when the current page changes. */
  onPageChange: (page: number) => void;
  /** The callback function called when the size changes. */
  onSizeChange: (size: number) => void;
  /** The current page. */
  page: number;
  /** The number dictating how many items a page contains. */
  pageSize?: number;
  /** The choices for `pageSize`. */
  pageSizes?: number[];
  /** The total number of items. */
  totalItems: number;
};

export const Pagination = (props: PaginationProps) => {
  const {
    buttonPreviousText = 'Previous page',
    buttonNextText = 'Next page',
    itemsPerPageText = 'Items per page:',
    itemRangeText = (min, max, total) => {
      return `${min}–${max} of ${total} items`;
    },
    pageRangeText = (current, total) => {
      return `of ${total} pages`;
    },
    id,
    onPageChange,
    onSizeChange,
    page,
    pageSize = 10,
    pageSizes = [10, 20, 50],
    totalItems,
    ...rest
  } = props;
  const { sizing } = useTheme();

  // A11Y
  const rootId = useId(id);
  const pageSizesId = makeId('page-sizes-select', rootId);
  const pagesId = makeId('pages-select', rootId);

  // Handlers
  const decrementPage = () => {
    onPageChange(page - 1);
  };
  const incrementPage = () => {
    onPageChange(page + 1);
  };
  const handleSizeChange = normalizeHandler(onSizeChange);
  const handlePageChange = normalizeHandler(onPageChange);

  // Misc prep
  const maxPages = Math.max(Math.ceil(totalItems / pageSize), 1);
  const prevDisabled = page === 1;
  const nextDisabled = page === maxPages;
  const pageData = Array.from({ length: maxPages }, (_, i) => {
    return i + 1;
  });
  const pageOptions = pageData.map(normalizeOptions);
  const pageSizeOptions = pageSizes.map(normalizeOptions);

  // Tweak the styles of ActionButton
  const navBtnStyles = { padding: 0, width: sizing.small };

  return (
    <Flex alignItems="center" justifyContent="space-between" {...rest}>
      <Flex alignItems="center" gap="small">
        <Text size="small" as="label" htmlFor={pageSizesId}>
          {itemsPerPageText}{' '}
        </Text>
        <Box width="4.4em">
          <Field label="Select page size" labelVisible={false}>
            <SelectInput
              id={pageSizesId}
              size="small"
              placeholder="Size"
              onChange={handleSizeChange}
              options={pageSizeOptions}
              value={pageSize.toString()}
            />
          </Field>
        </Box>
        <Text size="small" color="muted">
          {itemRangeText(
            Math.min(pageSize * (page - 1) + 1, totalItems),
            Math.min(page * pageSize, totalItems),
            totalItems
          )}
        </Text>
      </Flex>

      <Flex alignItems="center">
        <SelectWrapper>
          <Field label="Select page" labelVisible={false}>
            <SelectInput
              id={pagesId}
              size="small"
              placeholder="Page"
              onChange={handlePageChange}
              options={pageOptions}
              value={page.toString()}
            />
          </Field>
        </SelectWrapper>
        <Box marginRight="small">
          <Text size="small">{pageRangeText(page, maxPages)}</Text>
        </Box>

        <Tooltip content={buttonPreviousText}>
          <IconButton
            label="Previous page"
            disabled={prevDisabled}
            onClick={decrementPage}
            colorScheme="tertiary"
            variant="outline"
            icon={ChevronLeftIcon}
            css={navBtnStyles}
            size="small"
          />
        </Tooltip>
        <Tooltip content={buttonNextText}>
          <IconButton
            label="Next page"
            disabled={nextDisabled}
            onClick={incrementPage}
            colorScheme="tertiary"
            variant="outline"
            icon={ChevronRightIcon}
            css={navBtnStyles}
            size="small"
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};

// Utils
// ------------------------------

const normalizeOptions = (s: number) => {
  return {
    value: s.toString(),
    label: s.toString(),
  };
};
const normalizeHandler = (handler: (arg: number) => void) => {
  return (val: string | undefined) => {
    if (val) {
      return handler(parseInt(val, 10));
    }
  };
};

// Styled Components
// ------------------------------

const SelectWrapper = (props: HTMLAttributes<HTMLDivElement>) => {
  const { spacing } = useTheme();
  return (
    <div
      css={{
        marginLeft: spacing.small,
        marginRight: spacing.small,
        width: '4.4em',
      }}
      {...props}
    />
  );
};
