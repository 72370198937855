import React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { DropdownMenuRadioGroupProps } from '@radix-ui/react-dropdown-menu';
import { Box } from '@balance-web/box';
import { Text } from '@balance-web/text';
import {
  buildDataAttributes,
  getTestIdFromDataAttributes,
} from '@balance-web/core';
import type { WithDataAttributeProp } from '@balance-web/core';

import {
  RadioGroupContextProvider,
  TestIdContextProvider,
  useTestId,
} from './context';

export type RadioGroupProps = WithDataAttributeProp<
  {
    title: string;
  } & DropdownMenuRadioGroupProps
>;

export const RadioGroup = ({ title, data, ...props }: RadioGroupProps) => {
  const parentTestId = useTestId();
  const dataAttributes = buildDataAttributes(data);

  const radioGroupTestId = getTestIdFromDataAttributes(data);
  const prefixedTestId =
    parentTestId && radioGroupTestId
      ? `${parentTestId}-${radioGroupTestId}`
      : radioGroupTestId;
  const testIdProp = prefixedTestId ? { 'data-testid': prefixedTestId } : {};

  return (
    <TestIdContextProvider value={prefixedTestId}>
      <RadioGroupContextProvider value={true}>
        <DropdownMenuPrimitive.Label>
          <Box paddingX="small" paddingBottom="xsmall">
            <Text
              as="h6"
              color="dim"
              size="xsmall"
              weight="medium"
              transform="uppercase"
            >
              {title}
            </Text>
          </Box>
        </DropdownMenuPrimitive.Label>
        <DropdownMenuPrimitive.RadioGroup
          {...props}
          {...dataAttributes}
          {...testIdProp}
        />
      </RadioGroupContextProvider>
    </TestIdContextProvider>
  );
};
