import { useMemo } from 'react';

import type { BaseBook } from './types';
import type {
  BookSortEnumValueType,
  BookStatusEnumValueType,
} from './constants';

type UseSortAndFilterBooksProps = {
  books: BaseBook[];
  bookSearchFilter: string;
  sortDirection: BookSortEnumValueType;
  selectedBookStatus: BookStatusEnumValueType;
};

export const useSortAndFilterBooks = ({
  books,
  bookSearchFilter,
  sortDirection,
  selectedBookStatus,
}: UseSortAndFilterBooksProps): BaseBook[] => {
  // Book is being sorted and filtered by bookName and companyName
  const sortedAndFilteredBooks = useMemo(() => {
    const searchString = bookSearchFilter.toLocaleLowerCase();

    const filteredBooks = books
      .filter((book) => book.bookStatus === selectedBookStatus)
      .filter((book) => book.isVisible)
      .filter((book) => {
        const { bookName, companyName } = book || {};
        return [bookName, companyName]
          .filter((part): part is string => !!part)
          .map((part) => part.toLocaleLowerCase())
          .join(' ')
          .includes(searchString);
      });

    return filteredBooks.sort((firstBook, secondBook) => {
      if (sortDirection === 'ASC') {
        return `${firstBook?.bookName}` > `${secondBook?.bookName}` ? -1 : 1;
      }
      return `${firstBook?.bookName}` > `${secondBook?.bookName}` ? 1 : -1;
    });
  }, [bookSearchFilter, books, selectedBookStatus, sortDirection]);

  return sortedAndFilteredBooks;
};
