import React from 'react';
import { SpotCaution } from '@balance-web/illustration';
import { Text } from '@balance-web/text';
import { Flex } from '@balance-web/flex';
import { Stack } from '@balance-web/stack';
import { Heading } from '@balance-web/heading';
import { Button } from '@balance-web/button';
import { TextLink } from '@balance-web/text-link';

export type UnauthorisedAccessStateProps = {
  noAccessBookHelpURL?: string;
  openOtherBookLabel?: string;
  onSignOutClick?: () => void;
  onOpenOtherBookClick?: () => void;
};

export const UnauthorisedAccessState = ({
  openOtherBookLabel,
  noAccessBookHelpURL,
  onSignOutClick,
  onOpenOtherBookClick,
}: UnauthorisedAccessStateProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      padding="large"
    >
      <Stack gap="large" align="center">
        <SpotCaution />

        <Stack align="center" gap="xlarge">
          <Flex direction="column" gap="medium">
            <Heading level="4">You don't have access to this book</Heading>
            <Text color="muted">
              Please check the account you signed in with.
            </Text>
          </Flex>

          {onSignOutClick && (
            <Button variant="text" label="Sign out" onClick={onSignOutClick} />
          )}

          {onOpenOtherBookClick && (
            <Button
              variant="text"
              label={openOtherBookLabel || 'Try another book'}
              onClick={onOpenOtherBookClick}
            />
          )}

          {noAccessBookHelpURL && (
            <Text size="small">
              <TextLink href={noAccessBookHelpURL} target="_blank">
                Still can't access your book?
              </TextLink>
            </Text>
          )}
        </Stack>
      </Stack>
    </Flex>
  );
};
