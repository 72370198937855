import React from 'react';
import {
  UseComboboxState,
  UseComboboxStateChangeOptions,
  useCombobox,
} from 'downshift';
import { Text } from '@balance-web/text';

import { ComboboxItemsStatus } from './types';

// Message text
// ------------------------------

export const defaultGetMessageText = (status: ComboboxItemsStatus): string => {
  switch (status) {
    case 'error':
      return 'Something went wrong';

    case 'noMatches':
    default:
      return 'No matching results';
  }
};

// Transformers
// ------------------------------

// @ts-ignore
export const defaultItemToDisabled = (item) => Boolean(item.disabled);
// @ts-ignore
export const defaultItemToLabel = (item) => item.label;
// @ts-ignore
export const defaultItemToValue = (item) => item.value;

// Item renderer
// ------------------------------

export function makeDefaultItemRenderer<Item>(
  itemToLabel: (item: Item) => string,
  itemToDisabled: (item: Item) => boolean
) {
  return (item: Item) => {
    const color = itemToDisabled(item) ? 'dim' : undefined;
    return (
      <Text overflowStrategy="truncate" color={color}>
        {itemToLabel(item)}
      </Text>
    );
  };
}

// State reducer
// ------------------------------

export function makeDefaultStateReducer<Item>(
  itemToLabel: (item: Item) => string
) {
  const stateReducer = (
    state: UseComboboxState<Item>,
    actionAndChanges: UseComboboxStateChangeOptions<Item>
  ) => {
    const { type, changes } = actionAndChanges;

    switch (type) {
      // Reset the input value in cases where the user left it invalid
      case useCombobox.stateChangeTypes.InputBlur:
        const inputValue = changes.selectedItem
          ? itemToLabel(changes.selectedItem)
          : changes.inputValue;

        return { ...changes, inputValue };

      // Maintain the highlighted item when the user mouses out of the menu
      case useCombobox.stateChangeTypes.MenuMouseLeave:
        return { ...changes, highlightedIndex: state.highlightedIndex };

      // Business as usual
      default:
        return changes;
    }
  };

  return stateReducer;
}
